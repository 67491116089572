import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getEnvConfig } from '@synop-react/config'
import { prepareAuthHeader } from './headers/auth'

const synopAPIUrl = getEnvConfig().NX_SYNOP_API_URL
const baseQuery = fetchBaseQuery({
  baseUrl: synopAPIUrl,
  prepareHeaders: prepareAuthHeader,
})

export type BaseQuery = typeof baseQuery
export const emptyRootAPI = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
})
