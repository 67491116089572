import { createSelector } from '@reduxjs/toolkit'

import { ChartArea, TimelineWindow } from '../useTimelineReducer'
import { selectEventsWithIdle } from './eventsWithIdle'
import { TimelineSelector } from '../../useTimeline'

export const selectWindow: TimelineSelector<TimelineWindow> = (state) =>
  state.window

const selectLeftAxis: TimelineSelector<ChartArea> = (state) => state.leftAxis
const selectBottomAxis: TimelineSelector<ChartArea> = (state) =>
  state.bottomAxis

export const selectDisplayYAxis: TimelineSelector<boolean> = (state) =>
  state.displayYAxis

export const selectLeftAxisArea: TimelineSelector<ChartArea> = createSelector(
  selectWindow,
  selectBottomAxis,
  selectLeftAxis,
  selectEventsWithIdle,
  selectDisplayYAxis,
  (
    { height: windowHeight },
    { height: bottomHeight = 0 },
    { width: leftWidth = 0 },
    groupedEvents,
    displayYAxis
  ) => {
    const hasMultiple = Object.keys(groupedEvents).length > 1
    const shouldDisplayYAxis = hasMultiple || displayYAxis
    return {
      height: windowHeight - bottomHeight,
      width: shouldDisplayYAxis ? leftWidth : 0,
    }
  }
)

export const selectBottomAxisArea: TimelineSelector<ChartArea> = createSelector(
  selectWindow,
  selectLeftAxisArea,
  selectBottomAxis,
  (
    { width: windowWidth },
    { width: leftWidth = 0 },
    { height: bottomHeight = 0 }
  ) => {
    return { width: windowWidth - leftWidth, height: bottomHeight }
  }
)

export const selectChartArea: TimelineSelector<ChartArea> = createSelector(
  selectWindow,
  selectLeftAxisArea,
  selectBottomAxisArea,
  (
    { width: windowWidth, height: windowHeight },
    { width: leftWidth },
    { height: bottomHeight }
  ) => {
    return {
      width: windowWidth - leftWidth,
      height: windowHeight - bottomHeight,
    }
  }
)

export const selectBackgroundArea: TimelineSelector<ChartArea> = (state) =>
  state.backgroundArea

export const selectRowArea: TimelineSelector<ChartArea> = (state) =>
  state.rowArea
