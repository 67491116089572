import { Box } from '@mui/material'
import { Feature, featureCollection, Point, Polygon } from '@turf/helpers'
import { ReactNode } from 'react'
import { Source } from 'react-map-gl'

import {
  DepotLocationProperties,
  GeofenceProperties,
  isDefined,
} from '@synop-react/types'
import {
  DepotMapLayerProps,
  DepotMapLocationLayers,
  getDepotFeatures,
} from '../LocationLayers'
import { MapProps, SynopMap } from '@synop-react/common'

type DepotMapProps = {
  depotId: string
  latitude?: number
  longitude?: number
  geofence?: string | Feature<Polygon, GeofenceProperties>
  children?: ReactNode
} & Omit<MapProps, 'id' | 'boundedData'> &
  DepotMapLayerProps

export function DepotMap({
  depotId,
  children,
  geofence,
  latitude,
  longitude,
  defaultMaxZoom = 16,
  defaultMinZoom = 10,
  maxMarkerZoom,
  minGeofenceZoom,
  ...rest
}: DepotMapProps) {
  const depotFeatures = getDepotFeatures({
    depotId,
    latitude,
    longitude,
    geofence,
  })

  const depotCollection = featureCollection<
    Point | Polygon,
    DepotLocationProperties | GeofenceProperties
  >([depotFeatures.depotMarker, depotFeatures.depotGeofence].filter(isDefined))

  return (
    <Box sx={{ width: '100%', height: '368px' }}>
      <SynopMap
        boundedData={depotCollection}
        defaultMaxZoom={defaultMaxZoom}
        defaultMinZoom={defaultMinZoom}
        id="depotTileMap"
        {...rest}
      >
        <Source
          data={depotCollection}
          id="depotLocation"
          lineMetrics={true}
          type="geojson"
        >
          <DepotMapLocationLayers
            mapId="depotTileMap"
            maxMarkerZoom={maxMarkerZoom}
            minGeofenceZoom={minGeofenceZoom}
            sourceId="depotLocation"
          />
        </Source>
        {children}
      </SynopMap>
    </Box>
  )
}
