import {
  BillingCycleType,
  billingCycleTypes,
  RootAPI,
  useCurrentOrgId,
  useUserPrefs,
} from '@synop-react/api'
import { DatePicker } from '@mui/x-date-pickers'
import { Dropdown } from '@synop-react/common'
import { MenuItem, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

const { useGetReimbursementSummaryQuery, useGetWorkplaceSummaryQuery } = RootAPI

interface BillingCycleSelectorProps {
  handleRangeChange: (range: [Dayjs | null, Dayjs | null]) => void
  optionsToHide?: BillingCycleType[]
  useSummaryQuery?:
    | typeof useGetReimbursementSummaryQuery
    | typeof useGetWorkplaceSummaryQuery
}
export const BillingCycleSelector: React.FC<BillingCycleSelectorProps> = ({
  handleRangeChange,
  optionsToHide = [],
  useSummaryQuery = useGetReimbursementSummaryQuery,
}) => {
  const [selectedCycleOption, setSelectedCycleOption] =
    useState<BillingCycleType>('Current Cycle')
  const { tzDayjs } = useUserPrefs()

  const orgId = useCurrentOrgId()
  const monthStart = tzDayjs().startOf('month').format('YYYY-MM-DD')
  const { data: reimbursementSummary } = useSummaryQuery(
    {
      billingMonth: monthStart,
      organizationId: orgId,
    },
    {
      skip: !orgId,
    }
  )

  const isCustomCycle = selectedCycleOption === 'Custom Cycle'
  const isYearCycle = selectedCycleOption === 'View By Year'

  const handleDropDownChange = (e: SelectChangeEvent) => {
    const value = e.target.value as BillingCycleType
    setSelectedCycleOption(value ?? 'Current Cycle')
  }

  useEffect(() => {
    if (selectedCycleOption === 'Current Cycle') {
      handleRangeChange([
        dayjs(reimbursementSummary?.periodStart),
        dayjs(reimbursementSummary?.periodEnd),
      ])
    } else if (selectedCycleOption === 'Previous Cycle') {
      handleRangeChange([
        dayjs(reimbursementSummary?.periodStart).subtract(1, 'month'),
        dayjs(reimbursementSummary?.periodStart).subtract(1, 'day'),
      ])
    }
  }, [
    handleRangeChange,
    reimbursementSummary?.periodEnd,
    reimbursementSummary?.periodStart,
    selectedCycleOption,
  ])

  const handleChange = (value: Dayjs | null) => {
    if (value === null) return
    if (isCustomCycle) {
      handleRangeChange([value.startOf('month'), value.endOf('month')])
    } else if (isYearCycle) {
      handleRangeChange([value.startOf('year'), value.endOf('year')])
    }
  }

  return (
    <>
      <Dropdown
        onChange={handleDropDownChange}
        sx={{ marginRight: 1 }}
        value={selectedCycleOption}
      >
        {billingCycleTypes
          .filter((type) => !optionsToHide.includes(type))
          .map((cycleType) => (
            <MenuItem key={cycleType} value={cycleType}>
              {cycleType}
            </MenuItem>
          ))}
      </Dropdown>
      {isCustomCycle && (
        <DatePicker
          label="MMMM/YYYY"
          onChange={handleChange}
          slotProps={{ textField: { size: 'small' } }}
          views={['month', 'year']}
        />
      )}
      {isYearCycle && (
        <DatePicker
          label="YYYY"
          onChange={handleChange}
          slotProps={{ textField: { size: 'small' } }}
          views={['year']}
        />
      )}
    </>
  )
}
