import {
  Box,
  CardActions,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

import { Icons } from '../../../Icons'
import { styled } from '@mui/system'
import { TEntityListItemDetail } from './Detail/EntityListItemDetail'
import { useEntityBrowser } from '../../Browser/useEntityBrowser'
import EntityListItemDetail from './Detail/EntityListItemDetail'

export type StringOr<T> = string | T
export type StringOrReactNode = StringOr<ReactNode>

export type TEntityListItem = {
  id: string
  icon?: StringOrReactNode
  titleText: string
  titleIcon?: ReactNode
  descriptionText: StringOrReactNode
  details?: TEntityListItemDetail[]
  detailsUrl: string
}

export function EntityListItem({
  id,
  titleText,
  titleIcon,
  descriptionText,
  details = [],
  detailsUrl,
}: TEntityListItem) {
  const { selectedEntityId, toggleSelectedEntity } = useEntityBrowser()

  const isSelected = id === selectedEntityId
  const SelectableListItem = isSelected ? SelectedListItem : UnselectedListItem

  const filteredDetails = details.filter(({ value }) => value) // Filter out null values
  const lastIndex = filteredDetails.length - 1
  const detailItems = filteredDetails.flatMap((detail, i) => {
    const item = <EntityListItemDetail key={i} {...detail} />
    return i === lastIndex
      ? [item]
      : [item, <EntityListItemDetail key={`separator${i}`} value="•" />]
  })

  const TitleIcon = titleIcon ? (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>{titleIcon}</Box>
  ) : null

  return (
    <SelectableListItem
      onClick={() => {
        toggleSelectedEntity(id)
      }}
    >
      <Grid container>
        <Grid container item sx={{ pb: 1 }} xs={11}>
          {TitleIcon}
          <ListItemText
            primary={
              <Typography noWrap variant="h6">
                {titleText}
              </Typography>
            }
          />
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              {detailItems}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ color: ({ palette }) => palette.text['30p'] }}
              variant="caption"
            >
              {descriptionText}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          xs={1}
        >
          <CardActions>
            <IconButton href={detailsUrl}>
              <Icons.ChevronRight />
            </IconButton>
          </CardActions>
        </Grid>
      </Grid>
    </SelectableListItem>
  )
}

export default EntityListItem

const SELECTED_BAR_WIDTH = 4

const UnselectedListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  paddingLeft: 8,
  borderLeft: `${SELECTED_BAR_WIDTH}px solid`,
  borderColor: '#ffffff',
  backgroundColor: '#ffffff',
  '&:hover': {
    cursor: 'pointer',
    borderLeft: `${SELECTED_BAR_WIDTH}px solid`,
    borderColor: theme.palette['secondary'].light,
  },
}))

const SelectedListItem = styled(UnselectedListItem)(({ theme }) => ({
  borderLeft: `${SELECTED_BAR_WIDTH}px solid`,
  borderColor: theme.palette['primary'].main,
  '&:hover': {
    cursor: 'pointer',
    borderLeft: `${SELECTED_BAR_WIDTH}px solid`,
    borderColor: theme.palette['primary'].light,
  },
}))
