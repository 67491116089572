import { Grid, Typography } from '@mui/material'
import { ProgressPill, ProgressPillColor } from '../../../ProgressPill'
export interface StatusCaskItemProps {
  label: string
  value: string
  progress?: CaskItemProgress
  hideProgress?: boolean
}
export type CaskItemProgress = {
  value: number
  color?: ProgressPillColor
  lowerBound?: number
  upperBound?: number
}

const defaultProgress = {
  value: 0,
  color: 'secondary' as ProgressPillColor,
  lowerBound: 0,
  upperBound: 100,
}

export function StatusCaskItem({
  label,
  value,
  progress = defaultProgress,
  hideProgress = false,
}: StatusCaskItemProps) {
  const {
    value: progressValue = defaultProgress.value,
    color = defaultProgress.color,
    lowerBound = defaultProgress.lowerBound,
    upperBound = defaultProgress.upperBound,
  } = progress

  const normalizedProgress =
    progressValue >= 0 && !hideProgress
      ? ((progressValue - lowerBound) * 100) / (upperBound - lowerBound)
      : undefined

  const ProgressBar =
    normalizedProgress !== undefined ? (
      <ProgressPill color={color} value={normalizedProgress} />
    ) : null

  return (
    <Grid item py={2} sx={{ maxWidth: '126px' }} xs={3}>
      {ProgressBar}
      <Typography variant="h6">{value}</Typography>
      <Typography
        sx={{
          color: 'text.disabled',
          textTransform: 'uppercase',
        }}
        variant="body2"
      >
        {label}
      </Typography>
    </Grid>
  )
}

export default StatusCaskItem
