import { RootAPI } from '@synop-react/api'

export const extractRecurrenceName = (
  eventRecurrence: RootAPI.EventRecurrenceModel | undefined
) => {
  if (eventRecurrence?.recurrenceName) {
    // Shorten if necessary
    const recurrenceName =
      eventRecurrence.recurrenceName.length > 25
        ? eventRecurrence.recurrenceName.slice(0, 25) + '...'
        : eventRecurrence.recurrenceName
    return {
      label: 'Name',
      detail: recurrenceName,
    }
  }
  return { label: '', detail: '' }
}

export const extractFrequency = (
  eventRecurrence: RootAPI.EventRecurrenceModel | undefined
) => {
  return {
    label: 'Frequency',
    detail: eventRecurrence?.recurrenceType ?? 'Single Event',
  }
}
