import { createContext, useContext } from 'react'
import { noop } from 'lodash'

type OverlayContextValues = {
  cancel: () => void
  closeOverlay: () => void
}

export const OverlayContext = createContext<OverlayContextValues>({
  cancel: noop,
  closeOverlay: noop,
})

export const useOverlayContext = () => useContext(OverlayContext)
