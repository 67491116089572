import { ProgressPillColor } from '@synop-react/common'

export const SUCCESS_THRESHOLD = 80
export const MAIN_THRESHOLD = 20
export const WARNING_THRESHOLD = 10
export const ERROR_THRESHOLD = 5

export default function calculateSocColor(soc: number) {
  let color: ProgressPillColor = 'primary'

  if (soc >= SUCCESS_THRESHOLD) {
    color = 'success'
  } else if (soc > MAIN_THRESHOLD) {
    color = 'secondary'
  } else if (soc >= WARNING_THRESHOLD) {
    color = 'warning'
  } else if (soc <= ERROR_THRESHOLD) {
    color = 'error'
  }

  return color
}
