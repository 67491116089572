import {
  DASHBOARD_MAX_DATE_RANGE,
  DashboardOverviewTile,
  emDash,
  formatEnergyUnitsAbbr,
  formatNumber,
  reduceTime,
  reduceWattHours,
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
  useTimeRange,
  useValidate,
} from '@synop-react/common'
import { Grid } from '@mui/material'
import { RootAPI } from '@synop-react/api'

const { useGetSummaryDataQuery } = RootAPI.synopRootAPI

const chargerOverviewTileData = ({
  totalChargers,
  totalTransactions,
  totalUniqueTags,
  totalFaults,
  ...data
}: RootAPI.ChargersTimespanSummaryModel) => {
  const totalEnergyDispensed =
    data.totalEnergyDispensedWh || data.totalEnergyDispensedWh === 0
      ? reduceWattHours(data.totalEnergyDispensedWh)
      : { num: emDash, unit: undefined }
  const totalChargeTime =
    data.totalChargeTime || data.totalChargeTime === 0
      ? reduceTime(data.totalChargeTime)
      : { value: emDash, suffix: '' }
  const averageTransactionDuration =
    data.averageTransactionLength || data.averageTransactionLength === 0
      ? reduceTime(data.averageTransactionLength)
      : { value: emDash, suffix: '' }
  const chargerUptime =
    data.chargerUptimePercentage || data.chargerUptimePercentage === 0
      ? { value: data.chargerUptimePercentage, suffix: '%' }
      : { value: emDash, suffix: '' }
  return [
    [
      {
        label: 'TOTAL CHARGERS',
        value: formatNumber(totalChargers),
        tooltip:
          'Number of chargers configured for the selected organization and site.',
      },
      {
        label: 'TOTAL TRANSACTIONS',
        value: formatNumber(totalTransactions),
        tooltip: 'Number of charge transactions for the selected date range.',
      },
      {
        label: 'TOTAL UNIQUE TAGS',
        value: formatNumber(totalUniqueTags),
        tooltip:
          'Number of unique authentication tags used across charge transactions for the selected date range.',
      },
      {
        label: 'TOTAL FAULTS & WARNINGS',
        value: totalFaults ?? 0,
        tooltip:
          'Number of charger faults and warnings for the selected date range.',
      },
    ],
    [
      {
        label: 'TOTAL ENERGY IMPORTED',
        units: totalEnergyDispensed.unit
          ? formatEnergyUnitsAbbr(totalEnergyDispensed.unit)
          : '',
        value: totalEnergyDispensed.num,
        tooltip: 'Sum of energy imported across transactions.',
      },
      {
        label: 'TOTAL CHARGE TIME',
        units: totalChargeTime.suffix,
        value: totalChargeTime.value,
        tooltip: 'Cumulative amount of charge time.',
      },
      {
        label: 'AVERAGE TRANSACTION DURATION',
        units: averageTransactionDuration.suffix,
        value: averageTransactionDuration.value,
        tooltip: 'Average duration of charge transactions.',
      },
      {
        label: 'CHARGER UPTIME',
        units: chargerUptime.suffix,
        value: chargerUptime.value,
        tooltip:
          'Cumulative uptime percentage across chargers for the selected date range.',
      },
    ],
  ]
}

const ChargerOverviewTile = () => {
  const orgId = useSelectedOrgIdOrCurrent()
  const { selected: site } = useSiteSelector()
  const { from, to } = useTimeRange()
  const { validateTimeRange } = useValidate()

  const isValidTimeRange = validateTimeRange(DASHBOARD_MAX_DATE_RANGE).isValid
  const { data, isLoading, isFetching } = useGetSummaryDataQuery(
    {
      organizationId: orgId,
      siteIds: site ? [site.id] : undefined,
      fromTs: from.toISOString(),
      toTs: to.toISOString(),
    },
    {
      skip: !orgId || !isValidTimeRange,
    }
  )

  return (
    <Grid container item sx={{ mt: 0 }} xs={12}>
      <DashboardOverviewTile
        isLoading={isLoading || isFetching}
        items={chargerOverviewTileData(data || {})}
      />
    </Grid>
  )
}

export default ChargerOverviewTile
