import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import {
  emDash,
  Icons,
  reduceWattHours,
  TabCaskView,
  useFormat,
  useOverlay,
} from '@synop-react/common'
import { HomeChargingBillingCycleOverlay } from './HomeChargingBillingCycleOverlay'
import { RootAPI, useCurrentOrgId, useUserPrefs } from '@synop-react/api'
import { useMemo } from 'react'
import { usePalette } from '@synop-react/theme'
import dayjs from 'dayjs'
import HomeChargingSettlements from './HomeChargingSettlements'
import HomeChargingUsage from './HomeChargingUsage'
import HomeChargingUsers from './HomeChargingUsers'

const {
  useGetReimbursementConfigurationQuery,
  useGetReimbursementSummaryQuery,
} = RootAPI

const TrendArrow = ({ value = 0 }: { value?: number }) => {
  const { palette } = usePalette()
  const color = value >= 0 ? palette.success.main : palette.error.main

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 1,
      }}
    >
      {value > 0 && <Icons.ArrowUp color={color} size={16} />}
      {value < 0 && <Icons.ArrowDown color={color} size={16} />}
      <Typography color={color} variant="caption">
        {value}%
      </Typography>
    </Box>
  )
}

interface ChargingInfoCardProps {
  title: string
  value: string
  amountChange?: number
  descriptionLabel?: string
  descriptionContent?: string
}

export const ChargingInfoCard = ({
  title,
  value,
  amountChange,
  descriptionLabel,
  descriptionContent,
}: ChargingInfoCardProps) => (
  <Box sx={{ pt: 2, px: 1 }}>
    <Typography variant="caption">{title}</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography variant="h6">{value}</Typography>
      {amountChange !== undefined && <TrendArrow value={amountChange} />}
    </Box>
    {descriptionLabel !== undefined && (
      <Typography component="p" variant="caption">
        {descriptionLabel}
      </Typography>
    )}
    {descriptionContent !== undefined && (
      <Typography variant="caption">{descriptionContent}</Typography>
    )}
  </Box>
)

export function HomeChargingSettings() {
  const { breakpoints, palette } = useTheme()
  const { tzDayjs } = useUserPrefs()
  const { formatDateTime } = useFormat()
  const monthStart = tzDayjs().startOf('month').format('YYYY-MM-DD')
  const orgId = useCurrentOrgId()
  const { data: reimbursementSummary } = useGetReimbursementSummaryQuery(
    {
      billingMonth: monthStart,
      organizationId: orgId,
    },
    {
      skip: !orgId,
    }
  )

  const { data: reimbursementConfiguration } =
    useGetReimbursementConfigurationQuery(
      {
        organizationId: orgId,
      },
      { skip: !orgId }
    )

  const billingCycleOverlay = useOverlay()

  const lastCapturedAt = useMemo(() => {
    return formatDateTime(reimbursementConfiguration?.updated).timeOnDate
  }, [reimbursementConfiguration?.updated, formatDateTime])

  const periodStart = useMemo(() => {
    return formatDateTime(reimbursementSummary?.periodStart).toLongFormDate
  }, [formatDateTime, reimbursementSummary?.periodStart])

  const periodEnd = useMemo(() => {
    return formatDateTime(reimbursementSummary?.periodEnd).toLongFormDate
  }, [formatDateTime, reimbursementSummary?.periodEnd])

  const nextPeriodStart = useMemo(() => {
    return formatDateTime(dayjs(reimbursementSummary?.periodEnd).add(1, 'day'))
      .toLongFormDate
  }, [formatDateTime, reimbursementSummary?.periodEnd])

  const daysRemaining = useMemo(() => {
    const nextPeriodStart = dayjs(reimbursementSummary?.periodEnd).add(1, 'day')
    const now = dayjs().startOf('day')
    return nextPeriodStart.diff(now, 'days')
  }, [reimbursementSummary])

  const energyUseKwh = useMemo(() => {
    return reimbursementSummary?.energyUseKwh === undefined
      ? { num: emDash, unit: undefined }
      : reduceWattHours(reimbursementSummary.energyUseKwh, 'kwh')
  }, [reimbursementSummary?.energyUseKwh])

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          action={
            <Button
              onClick={billingCycleOverlay.openOverlay}
              variant="contained"
            >
              {reimbursementConfiguration?.billingAnchor
                ? 'EDIT BILLING CYCLE'
                : 'CREATE BILLING CYCLE'}
            </Button>
          }
          subheader={
            <Typography variant="caption">
              Track and manage your home charging reimbursements.
            </Typography>
          }
          sx={{
            borderBottom: `1px solid ${palette.text['8p']}`,
            '& .MuiCardHeader-action': { alignSelf: 'unset', margin: 0 },
          }}
          title="Home Charging"
        />
        <CardContent>
          <Grid container>
            <Grid container flexWrap={'nowrap'} gap={2} item md={6} xs={12}>
              <Grid item sm={4} xs={12}>
                <ChargingInfoCard
                  amountChange={
                    reimbursementSummary?.reimbursementAmountMoMChange ?? 0
                  }
                  descriptionContent={lastCapturedAt}
                  descriptionLabel="Last captured at:"
                  title="Total Reimbursements"
                  value={`$${reimbursementSummary?.reimbursementAmount ?? 0}`}
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <ChargingInfoCard
                  amountChange={reimbursementSummary?.energyUseMoMChange ?? 0}
                  title="Total Energy Use"
                  value={`${energyUseKwh?.num ?? 0} ${
                    energyUseKwh?.unit ?? ''
                  }`}
                />
              </Grid>
              <Grid
                item
                sm={3}
                sx={{
                  [breakpoints.up('md')]: {
                    borderRight: `1px solid ${palette.text['8p']}`,
                  },
                }}
                xs={12}
              >
                <ChargingInfoCard
                  title="Number of Payees"
                  value={`${reimbursementSummary?.reimbursementUsers ?? 0}`}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={true}
              sm={12}
              sx={{
                [breakpoints.up('md')]: {
                  ml: 6.5,
                },
              }}
            >
              <Grid item sm={6} xs={12}>
                <ChargingInfoCard
                  descriptionContent={`${periodStart} - ${periodEnd}`}
                  descriptionLabel="Current cycle:"
                  title="Billing Cycle"
                  value={`${daysRemaining} days remaining`}
                />
              </Grid>
              <Grid
                item
                sm={6}
                sx={{ backgroundColor: palette.primary['8p'] }}
                xs={12}
              >
                <ChargingInfoCard
                  descriptionContent={`$${
                    reimbursementSummary?.lastBillingPeriod
                      ?.reimbursementAmount ?? ''
                  }
                  `}
                  descriptionLabel="Last cycle total reimbursements:"
                  title="Next Reimbursement Date"
                  value={`${nextPeriodStart}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <TabCaskView
        tabItems={[
          {
            label: 'Reimbursements',
            content: <HomeChargingSettlements />,
          },
          {
            label: 'Usage',
            content: <HomeChargingUsage />,
          },
          {
            label: 'Payees',
            content: <HomeChargingUsers />,
          },
        ]}
      />
      <HomeChargingBillingCycleOverlay {...billingCycleOverlay} />
    </>
  )
}

export default HomeChargingSettings
