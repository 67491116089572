// AWS Cognito
type CognitoMfaType = 'SMS'
type CognitoSignupAttributes = 'EMAIL'
type CognitoUsernameAttributes = 'EMAIL' | 'PHONE_NUMBER'
type CognitoVerificationMechanisms = 'EMAIL'
type CognitoOauthResponseType = 'code'

interface AmplifyConfig {
  // General
  aws_project_region: string

  // AWS Cognito
  aws_cognito_mfa_configuration: 'OFF' | 'ON'
  aws_cognito_mfa_types: CognitoMfaType[]
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: number
    passwordPolicyCharacters: []
  }
  aws_cognito_region: string
  aws_cognito_signup_attributes: CognitoSignupAttributes[]
  aws_cognito_social_providers: []
  aws_cognito_username_attributes: CognitoUsernameAttributes[]
  aws_cognito_verification_mechanisms: CognitoVerificationMechanisms[]
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: {
    domain: string
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ]
    redirectSignIn: string
    redirectSignOut: string
    responseType: CognitoOauthResponseType
  }
}

interface ConfigRequest {
  awsCognitoRegion: string
  awsProjectRegion: string
  awsUserPoolsId: string
  awsUserPoolsWebClientId: string
  oauthDomain: string
}

const getAmplifyConfig = ({
  awsCognitoRegion,
  awsProjectRegion,
  awsUserPoolsId,
  awsUserPoolsWebClientId,
  oauthDomain,
}: ConfigRequest): AmplifyConfig => {
  return {
    aws_cognito_region: awsCognitoRegion,
    aws_project_region: awsProjectRegion,
    aws_user_pools_id: awsUserPoolsId,
    aws_user_pools_web_client_id: awsUserPoolsWebClientId,
    oauth: {
      domain: oauthDomain,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'http://localhost:3000',
      redirectSignOut: 'http://localhost:3000',
      responseType: 'code',
    },
    aws_cognito_username_attributes: ['EMAIL', 'PHONE_NUMBER'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
  }
}

export default getAmplifyConfig
