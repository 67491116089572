import * as yup from 'yup'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { DepotEventTypeAutocomplete } from '../../Autocomplete'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { FieldError, FormProvider, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'

import { DepotAutocomplete } from '../../Autocomplete'
import {
  EventScheduler,
  EventSchedulerFields,
  freqLookupToDropdownMap,
  freqRecurrenceTypeLookupMap,
  freqRRuleLookupMap,
  useEventSchedulerSchema,
} from '../../FormField/EventScheduler'
import {
  formatPower,
  FormField,
  Icons,
  OverlayDeprecated,
  useSnackbarControls,
} from '../..'
import { Maybe } from '@synop-react/types'
import { RootAPI, useCurrentOrgId, useUserPrefs } from '@synop-react/api'

const {
  useCancelDepotEventMutation,
  useGetDepotEventByIdQuery,
  useSaveDepotEventMutation,
  useUpdateDepotEventMutation,
} = RootAPI.synopRootAPI

const MINS_PER_HOUR = 60
const MINS_PER_DAY = 24 * MINS_PER_HOUR

type ValidatedRecurringEventData = ValidatedSingleEventData & {
  recurrenceRule: string
}

type ValidatedSingleEventData = Omit<DepotEventFormData, 'depot'> & {
  depot: RootAPI.DepotModel
}

type DepotEventFormData = EventSchedulerFields & {
  depot: RootAPI.DepotModel | null
  eventType: DepotEventTypeAutocomplete.DepotEventTypeOption
  importExportValue: number
}

export type CreateOrEditDepotEventOverlayProps = {
  eventId?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  defaultDepot?: RootAPI.DepotModel
  defaultScheduledStart?: string
  defaultScheduledEnd?: string
}

export const CreateOrEditDepotEventOverlay = ({
  defaultDepot,
  eventId,
  isOpen,
  setIsOpen,
  defaultScheduledStart: maybeDefaultScheduledStart,
  defaultScheduledEnd: maybeDefaultScheduledEnd,
}: CreateOrEditDepotEventOverlayProps) => {
  const theme = useTheme()
  const { tzDayjs } = useUserPrefs()
  const orgId = useCurrentOrgId()
  const [errorMessage, setErrorMessage] = useState('')
  const [optionsOverlayOpen, setOptionsOverlayOpen] = useState(true)
  const [editRecurrence, setEditRecurrence] = useState(false)
  const { openSnackbar } = useSnackbarControls()

  const { data: event, isLoading: isLoadingEvent } = useGetDepotEventByIdQuery(
    { eventId: eventId ?? '', depotId: defaultDepot?.depotId ?? '' },
    { skip: !eventId || !defaultDepot }
  )
  const editDepotEventSchema = useSchema(
    Boolean(eventId) && tzDayjs().isAfter(event?.eventStartDate)
  )

  const [saveDepotEvent, saveDepotEventResponse] = useSaveDepotEventMutation()
  const [updateDepotEvent, updateDepotEventResponse] =
    useUpdateDepotEventMutation()
  const [cancelDepotEvent, cancelDepotEventResponse] =
    useCancelDepotEventMutation()

  const { defaultScheduledEnd, defaultScheduledStart } = useMemo(() => {
    return {
      defaultScheduledStart: maybeDefaultScheduledStart ?? tzDayjs(),
      defaultScheduledEnd: maybeDefaultScheduledEnd ?? tzDayjs().add(1, 'hour'),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- tzDayjs changes on every render
  }, [maybeDefaultScheduledEnd, maybeDefaultScheduledStart, isOpen, setIsOpen])

  const formMethods = useForm<DepotEventFormData>({
    defaultValues: {
      depot: defaultDepot,
      seriesStart: tzDayjs(defaultScheduledStart).startOf('day'),
      seriesEnd: tzDayjs(defaultScheduledEnd).startOf('day'),
      singleEventStartDate: tzDayjs(defaultScheduledStart).startOf('day'),
      singleEventEndDate: tzDayjs(defaultScheduledEnd).startOf('day'),
      frequency: { id: 1, name: 'Single Event' },
      eventType: { id: 1, name: 'Max Power Import' },
      recurringEventStartTime: tzDayjs(),
      recurringEventEndTime: tzDayjs().add(1, 'hour'),
      recurringWeekdays: ['MO'],
    },
    resolver: yupResolver(editDepotEventSchema),
  })

  const {
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    clearErrors,
  } = formMethods

  const selectedRecurringEventStartTime = watch('recurringEventStartTime')
  const selectedRecurringEventEndTime = watch('recurringEventEndTime')
  const eventType = watch('eventType')

  // Determine if this is editing a recurring event and need to give option to users
  if (optionsOverlayOpen && !eventId) setOptionsOverlayOpen(false)
  if (optionsOverlayOpen && eventId && event && !event.eventRecurrence)
    setOptionsOverlayOpen(false)

  useEffect(() => {
    if (!isOpen) setErrorMessage('')
  }, [eventId, isOpen])

  // When the overlay opens, set the default values
  useEffect(() => {
    clearErrors()
    if (!eventId) {
      setValue('singleEventStartDate', tzDayjs(defaultScheduledStart))
      setValue('singleEventEndDate', tzDayjs(defaultScheduledEnd))
      setValue('seriesStart', tzDayjs(defaultScheduledStart).startOf('day'))
      setValue('seriesEnd', tzDayjs(defaultScheduledEnd).startOf('day'))
      setValue('recurringEventStartTime', tzDayjs())
      setValue('recurringEventEndTime', tzDayjs().add(1, 'hour'))
      setValue('importExportValue', 0)
      setValue('recurrenceName', '')
      setValue('recurringWeekdays', ['MO'])
      setValue('frequency', { id: 1, name: 'Single Event' })
      setValue('eventType', { id: 1, name: 'Max Power Import' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setValue, eventId])

  // When the event loads, reset the form with the loaded values
  useEffect(() => {
    setValue('depot', defaultDepot as RootAPI.DepotModel)

    if (event) {
      const { eventRecurrence, eventStartDate, eventEndDate, schedule } = event
      const { intervals } = schedule

      // TODO: need to make this work for multiple intervals once they exist
      const firstInterval: Maybe<RootAPI.DepotEventIntervalModel> = intervals[0]
      const startTime = firstInterval?.startDateTime ?? null
      const endTime = firstInterval?.endDateTime ?? null
      const curtailedLimit = firstInterval?.curtailedSiteLimit

      // Get recurring event type if it exists
      const frequencyType = freqLookupToDropdownMap.get(
        eventRecurrence?.recurrenceType ?? ''
      )

      // Get the series or event start depending on frequency type
      const seriesStart = eventRecurrence?.startDate ?? eventStartDate
      const seriesEnd = eventRecurrence?.validUntilDate ?? eventStartDate

      reset({
        depot: defaultDepot,
        singleEventStartDate: tzDayjs(eventStartDate),
        singleEventEndDate: tzDayjs(eventEndDate),
        recurringEventStartTime: tzDayjs(startTime),
        recurringEventEndTime: tzDayjs(endTime),
        seriesStart: tzDayjs(seriesStart).startOf('day'),
        seriesEnd: tzDayjs(seriesEnd).startOf('day'),
        recurrenceName: eventRecurrence?.recurrenceName,
        frequency: frequencyType
          ? { name: frequencyType }
          : { name: 'Single Event' },
        importExportValue: Math.abs(curtailedLimit ?? NaN),
        eventType:
          curtailedLimit !== undefined
            ? curtailedLimit >= 0
              ? { id: 1, name: 'Max Power Import' }
              : { id: 2, name: 'Max Power Export' }
            : { id: 1, name: 'Max Power Import' },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- tzDayjs changes on every render
  }, [event, defaultDepot, reset, setValue])

  useEffect(() => {
    if (
      saveDepotEventResponse.isSuccess ||
      cancelDepotEventResponse.isSuccess ||
      updateDepotEventResponse.isSuccess
    ) {
      setErrorMessage('')
      setIsOpen(false)

      if (saveDepotEventResponse.isSuccess) {
        openSnackbar('Site event saved successfully')
      } else if (cancelDepotEventResponse.isSuccess) {
        openSnackbar('Site event cancelled successfully')
      } else if (updateDepotEventResponse.isSuccess) {
        openSnackbar('Site event updated successfully')
      }

      // Need to reset these or else the responses will persist
      saveDepotEventResponse.reset()
      cancelDepotEventResponse.reset()
      updateDepotEventResponse.reset()
      return
    }

    // Handle API errors
    const getErrorMessage = (status: number) => {
      if (status === 409) {
        return 'There is already a site event scheduled during this timeframe.'
      } else if (status === 404) {
        return 'Depot event not found.'
      } else if (status === 400) {
        return 'Invalid parameters.'
      } else {
        return 'Unspecified backend error.'
      }
    }

    if (
      saveDepotEventResponse.isError &&
      'data' in saveDepotEventResponse.error
    ) {
      setErrorMessage(
        getErrorMessage(
          (saveDepotEventResponse.error.data as FetchBaseQueryError)
            .status as number
        )
      )
    } else if (
      updateDepotEventResponse.isError &&
      'data' in updateDepotEventResponse.error
    ) {
      setErrorMessage(
        getErrorMessage(
          (updateDepotEventResponse.error.data as FetchBaseQueryError)
            .status as number
        )
      )
    } else if (
      cancelDepotEventResponse.isError &&
      'data' in cancelDepotEventResponse.error
    ) {
      setErrorMessage(
        getErrorMessage(
          (cancelDepotEventResponse.error.data as FetchBaseQueryError)
            .status as number
        )
      )
    } else {
      // Clear if nothing is in error
      setErrorMessage('')
    }
  }, [
    cancelDepotEventResponse,
    saveDepotEventResponse,
    updateDepotEventResponse,
    errors,
    setIsOpen,
    openSnackbar,
  ])

  /** Returns true if the event is active at midnight */
  const isOvernightEvent = () => {
    return (
      selectedRecurringEventEndTime.get('hour') <
      selectedRecurringEventStartTime.get('hour')
    )
  }

  const getEventTime = (eventDate: Dayjs, eventTime: Dayjs) => {
    return tzDayjs(eventDate)
      .set('hour', tzDayjs(eventTime).get('hour'))
      .set('minute', tzDayjs(eventTime).get('minute'))
      .toISOString()
  }

  const saveNewRecurringEvent = async (data: ValidatedRecurringEventData) => {
    const overNightEvent = isOvernightEvent()
    await saveDepotEvent({
      depotId: data.depot.depotId,
      depotEventModel: {
        depotId: data.depot.depotId,
        eventType: data.eventType?.name,
        eventStartDate: tzDayjs(data.recurringEventStartTime).toISOString(),
        eventEndDate: overNightEvent
          ? selectedRecurringEventEndTime.add(1, 'day').toISOString()
          : selectedRecurringEventEndTime.toISOString(),
        schedule: {
          intervals: [
            {
              startDateTime: tzDayjs(
                data.recurringEventStartTime
              ).toISOString(),
              endDateTime: overNightEvent
                ? selectedRecurringEventEndTime.add(1, 'day').toISOString()
                : selectedRecurringEventEndTime.toISOString(),
              curtailedSiteLimit:
                data?.eventType.name === 'Max Power Import'
                  ? data.importExportValue
                  : -data.importExportValue,
            },
          ],
        },
        eventRecurrence: {
          recurrenceRule: data.recurrenceRule,
          recurrenceName: data.recurrenceName,
          recurrenceType: freqRecurrenceTypeLookupMap.get(data.frequency?.name),
          startDate:
            tzDayjs(data.seriesStart).year() === tzDayjs().year() &&
            tzDayjs(data.seriesStart).month() === tzDayjs().month() &&
            tzDayjs(data.seriesStart).date() === tzDayjs().date()
              ? tzDayjs().toDate().toISOString()
              : tzDayjs(data.seriesStart).startOf('day').toDate().toISOString(),
          validUntilDate: tzDayjs(data.seriesEnd)
            .endOf('day')
            .toDate()
            .toISOString(),
        },
      },
    })
  }

  const saveNewSingleEvent = async (data: ValidatedSingleEventData) => {
    await saveDepotEvent({
      depotId: data.depot.depotId,
      depotEventModel: {
        depotId: data.depot.depotId,
        eventType: data.eventType?.name,
        eventStartDate: getEventTime(
          data.singleEventStartDate,
          data.recurringEventStartTime
        ),
        eventEndDate: getEventTime(
          data.singleEventEndDate,
          data.recurringEventEndTime
        ),
        schedule: {
          intervals: [
            {
              startDateTime: getEventTime(
                data.singleEventStartDate,
                data.recurringEventStartTime
              ),
              endDateTime: getEventTime(
                data.singleEventEndDate,
                data.recurringEventEndTime
              ),
              curtailedSiteLimit:
                data.eventType?.name === 'Max Power Import'
                  ? data.importExportValue
                  : -data.importExportValue,
            },
          ],
        },
      },
    })
  }

  const updateRecurrence = async (data: ValidatedRecurringEventData) => {
    const overNightEvent = isOvernightEvent()
    await updateDepotEvent({
      isRecurrence: true,
      depotId: data.depot.depotId,
      depotEventModel: {
        eventId,
        depotId: data.depot.depotId,
        eventType: data.eventType?.name,
        eventStartDate: tzDayjs(data.recurringEventStartTime)
          .set('month', tzDayjs().month())
          .set('date', tzDayjs().date())
          .toISOString(),
        eventEndDate: overNightEvent
          ? selectedRecurringEventEndTime
              .set('month', tzDayjs().month())
              .set('date', tzDayjs().date())
              .add(1, 'day')
              .toISOString()
          : selectedRecurringEventEndTime
              .set('month', tzDayjs().month())
              .set('date', tzDayjs().date())
              .toISOString(),
        schedule: {
          intervals: [
            {
              startDateTime: tzDayjs(data.recurringEventStartTime)
                .set('month', tzDayjs().month())
                .set('date', tzDayjs().date())
                .toISOString(),
              endDateTime: overNightEvent
                ? selectedRecurringEventEndTime
                    .set('month', tzDayjs().month())
                    .set('date', tzDayjs().date())
                    .add(1, 'day')
                    .toISOString()
                : selectedRecurringEventEndTime
                    .set('month', tzDayjs().month())
                    .set('date', tzDayjs().date())
                    .toISOString(),
              curtailedSiteLimit:
                data.eventType?.name === 'Max Power Import'
                  ? data.importExportValue
                  : -data.importExportValue,
            },
          ],
        },
        eventRecurrence: {
          eventRecurrenceId: event?.eventRecurrence?.eventRecurrenceId,
          recurrenceRule: event?.eventRecurrence?.recurrenceRule,
          recurrenceName: event?.eventRecurrence?.recurrenceName,
          recurrenceType: event?.eventRecurrence?.recurrenceType,
          startDate: event?.eventRecurrence?.startDate,
          validUntilDate: event?.eventRecurrence?.validUntilDate,
        },
      },
    })
  }

  const updateSingleEventInRecurrence = async (
    data: ValidatedSingleEventData
  ) => {
    await updateDepotEvent({
      isRecurrence: false,
      depotId: data.depot.depotId,
      depotEventModel: {
        eventId,
        depotId: data.depot.depotId,
        eventType: data.eventType?.name,
        eventStartDate: data.recurringEventStartTime
          .set('month', tzDayjs(data.singleEventStartDate).month())
          .set('date', tzDayjs(data.singleEventStartDate).date())
          .toISOString(),
        eventEndDate: data.recurringEventEndTime
          .set('month', tzDayjs(data.singleEventEndDate).month())
          .set('date', tzDayjs(data.singleEventEndDate).date())
          .toISOString(),
        schedule: {
          intervals: [
            {
              startDateTime: data.recurringEventStartTime
                .set('month', tzDayjs(data.singleEventStartDate).month())
                .set('date', tzDayjs(data.singleEventStartDate).date())
                .toISOString(),
              endDateTime: data.recurringEventEndTime
                .set('month', tzDayjs(data.singleEventEndDate).month())
                .set('date', tzDayjs(data.singleEventEndDate).date())
                .toISOString(),
              curtailedSiteLimit:
                data.eventType?.name === 'Max Power Import'
                  ? data.importExportValue
                  : -data.importExportValue,
            },
          ],
        },
        eventRecurrence: {
          eventRecurrenceId: event?.eventRecurrence?.eventRecurrenceId,
          recurrenceRule: event?.eventRecurrence?.recurrenceRule,
          recurrenceName: event?.eventRecurrence?.recurrenceName,
          recurrenceType: event?.eventRecurrence?.recurrenceType,
          startDate: event?.eventRecurrence?.startDate,
          validUntilDate: event?.eventRecurrence?.validUntilDate,
        },
      },
    })
  }

  const updateSingleEvent = async (data: ValidatedSingleEventData) => {
    await updateDepotEvent({
      depotId: data.depot.depotId,
      depotEventModel: {
        eventId: eventId,
        depotId: data.depot.depotId,
        eventType: data.eventType?.name,
        eventStartDate: getEventTime(
          data.singleEventStartDate,
          data.recurringEventStartTime
        ),
        eventEndDate: getEventTime(
          data.singleEventEndDate,
          data.recurringEventEndTime
        ),
        schedule: {
          intervals: [
            {
              startDateTime: getEventTime(
                data.seriesStart,
                data.recurringEventStartTime
              ),
              endDateTime: getEventTime(
                data.seriesEnd,
                data.recurringEventEndTime
              ),
              curtailedSiteLimit:
                data.eventType?.name === 'Max Power Import'
                  ? data.importExportValue
                  : -data.importExportValue,
            },
          ],
        },
      },
    })
  }

  const onSaveSession = async ({ depot, ...rest }: DepotEventFormData) => {
    if (!depot) return

    // Get the actual weekdays via offset calculations (should be done on the backend but can't be yet)
    const weekdayOffsetLookupMap = new Map([
      ['SU', 'MO'],
      ['MO', 'TU'],
      ['TU', 'WE'],
      ['WE', 'TH'],
      ['TH', 'FR'],
      ['FR', 'SA'],
      ['SA', 'SU'],
    ])

    const actualWeekdays = rest.recurringWeekdays?.map((day) => {
      const startTime = tzDayjs(rest.recurringEventStartTime)

      const minsToHourStart = startTime.hour() * MINS_PER_HOUR
      const minsSinceHourStart = startTime.minute()
      const minsOffset = Math.abs(startTime.utcOffset())

      return minsToHourStart + minsSinceHourStart + minsOffset >= MINS_PER_DAY
        ? weekdayOffsetLookupMap.get(day)
        : day
    })

    // Build rrule if needed
    const FREQ = freqRRuleLookupMap.get(rest.frequency?.name)
    const BYDAY_STRING =
      FREQ && FREQ !== 'DAILY' ? `BYDAY=${actualWeekdays.toString()};` : ''
    const untilDate = tzDayjs(rest.seriesEnd).utc().format('YYYYMMDD')
    const UNTIL = `${untilDate}T235959Z`
    const recurrenceRule = `FREQ=${FREQ};${BYDAY_STRING}INTERVAL=1;UNTIL=${UNTIL}`

    if (!eventId) {
      if (FREQ) {
        await saveNewRecurringEvent({ ...rest, depot, recurrenceRule })
      } else {
        await saveNewSingleEvent({ ...rest, depot })
      }
    } else if (FREQ) {
      if (editRecurrence) {
        await updateRecurrence({ ...rest, depot, recurrenceRule })
      } else {
        await updateSingleEventInRecurrence({ ...rest, depot })
      }
    } else {
      await updateSingleEvent({ ...rest, depot })
    }
  }

  const onCancelSession = async () => {
    if (!defaultDepot || !eventId || !event) return

    // If the event has already started...
    if (tzDayjs().isAfter(event.eventStartDate)) {
      // ...then update the event to end now
      const now = tzDayjs().toISOString()
      await updateDepotEvent({
        depotId: defaultDepot.depotId,
        depotEventModel: {
          eventId: eventId,
          depotId: defaultDepot.depotId,
          eventType: event.eventType,
          eventStartDate: event.eventStartDate,
          eventEndDate: now,
          schedule: {
            intervals: [
              {
                startDateTime: event.eventStartDate,
                endDateTime: now,
                curtailedSiteLimit:
                  event.schedule.intervals[0]?.curtailedSiteLimit,
              },
            ],
          },
        },
      })

      // Cancel the recurrence if needed
      if (editRecurrence) {
        await cancelDepotEvent({
          depotId: defaultDepot.depotId,
          eventId: eventId,
          isRecurrence: true,
        })
      }
    } else {
      // Otherwise just cancel the event before it begins
      await cancelDepotEvent({
        depotId: defaultDepot.depotId,
        eventId: eventId,
        isRecurrence: editRecurrence,
      })
    }
  }

  dayjs.extend(minMax)
  dayjs.extend(isBetween)

  const saveButton = (
    <Button
      color="primary"
      onClick={handleSubmit(onSaveSession)}
      variant="contained"
    >
      {eventId ? 'SAVE' : 'CREATE'}
    </Button>
  )

  const cancelButton = (
    <Button color="error" onClick={onCancelSession} variant="outlined">
      Cancel site event
    </Button>
  )

  const editButton = (
    <Button
      color="primary"
      onClick={() => setOptionsOverlayOpen(false)}
      variant="contained"
    >
      EDIT
    </Button>
  )

  const overlayActions = eventId ? [saveButton, cancelButton] : [saveButton]
  if (eventId && isLoadingEvent) {
    return <></>
  } else if (optionsOverlayOpen) {
    return (
      <OverlayDeprecated
        isOpen={isOpen}
        OverlayActions={[editButton, cancelButton]}
        setIsOpen={setIsOpen}
        subtitle="Please select which events you wish to edit."
        title={'Edit Repeating Event'}
        TitleIcon={Icons.Edit3}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={!editRecurrence}
              onChange={() => {
                if (editRecurrence) {
                  setEditRecurrence(!editRecurrence)
                }
              }}
            />
          }
          label="This event"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editRecurrence}
              onChange={() => {
                if (!editRecurrence) {
                  setEditRecurrence(!editRecurrence)
                }
              }}
            />
          }
          label="This event and following events"
        />
      </OverlayDeprecated>
    )
  } else {
    return (
      <FormProvider {...formMethods}>
        <OverlayDeprecated
          isOpen={isOpen}
          OverlayActions={overlayActions}
          setIsOpen={setIsOpen}
          subtitle={
            eventId
              ? editRecurrence
                ? 'Adjust the repeating series below.'
                : 'Adjust the site event below.'
              : 'Provide the following information to schedule a site event.'
          }
          title={
            !eventId || !editRecurrence ? 'Site Event' : 'Repeating Site Event'
          }
          TitleIcon={
            eventId
              ? event?.eventRecurrence
                ? Icons.Repeat
                : Icons.Edit3
              : Icons.Calendar
          }
        >
          <form>
            {errorMessage && (
              <FormHelperText
                sx={{ color: theme.palette.error.main, paddingBottom: 2 }}
              >
                {errorMessage}
              </FormHelperText>
            )}
            <Grid container direction="row" item spacing={2}>
              <Grid item xs={12}>
                <DepotAutocomplete.Select
                  control={control}
                  disabled
                  error={errors.depot as FieldError}
                  fleetId={orgId}
                  id="depot"
                  touchedField={Boolean(touchedFields.depot)}
                />
              </Grid>
              <Grid item xs={6}>
                <DepotEventTypeAutocomplete.Select
                  disabled={
                    eventId ? true : defaultDepot?.v2gEnrolled ? false : true
                  }
                  error={errors.eventType as FieldError}
                  fullWidth
                  id="eventType"
                  label="Event Type"
                />
              </Grid>
              {eventId && event && tzDayjs().isAfter(event.eventStartDate) ? (
                <Grid item xs={6}>
                  <Stack>
                    <Typography color="secondary.main" variant="h6">
                      {formatPower(getValues()['importExportValue'])}{' '}
                      {/* TODO: usewatch for this instead @connor */}
                    </Typography>
                    <Typography color="secondary.main" variant="caption">
                      Site Limit
                    </Typography>
                  </Stack>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <FormField.PowerSlider
                    control={control}
                    error={errors.importExportValue as FieldError}
                    id="importExportValue"
                    label={
                      eventType?.name === 'Max Power Import'
                        ? 'Max Power Import'
                        : 'Max Power Export'
                    }
                    touched={Boolean(touchedFields.importExportValue)}
                  />
                </Grid>
              )}
              <EventScheduler
                activeEvent={tzDayjs(event?.eventStartDate) < tzDayjs()}
                editMode={Boolean(eventId)}
                editRecurrence={Boolean(eventId) && editRecurrence}
                readonlyRRuleForEdit={
                  eventId && event?.eventRecurrence?.recurrenceRule
                    ? event?.eventRecurrence?.recurrenceRule
                    : ''
                }
              />
            </Grid>
          </form>
        </OverlayDeprecated>
      </FormProvider>
    )
  }
}

function useSchema(isActiveEvent: boolean) {
  const eventSchedulerSchema = useEventSchedulerSchema(isActiveEvent)
  return yup
    .object({
      depot: DepotAutocomplete.Schema,
      eventType: DepotEventTypeAutocomplete.Schema,
      importExportValue: yup
        .number()
        .typeError('Max power is required')
        .required()
        .min(0, 'Must be zero or a positive number'),
    })
    .required()
    .concat(eventSchedulerSchema)
}
