import { ApexOptions } from 'apexcharts'
import { Cask, DataState, emDash } from '@synop-react/common'
import { MINIMUM_TRIP_DATA_POINTS } from '../tripUtils'
import { RootAPI, useUserPrefs } from '@synop-react/api'
import { usePalette } from '@synop-react/theme'
import Chart from 'react-apexcharts'

type MultiAreaDatum = {
  x: string | number | Date
  y: number
}

type TripOverviewCaskProps = {
  telematics: RootAPI.AssetTelematics[]
}

export function TripOverviewCask({ telematics = [] }: TripOverviewCaskProps) {
  const { tzDayjs } = useUserPrefs()
  const { charting, palette } = usePalette()
  const { preferredTimeFormat } = useUserPrefs()
  const timeFormat =
    preferredTimeFormat === 'hh:mm' ? 'hh:mm tt' : preferredTimeFormat

  const { speedSeries, socSeries } = telematics.reduce<{
    speedSeries: MultiAreaDatum[]
    socSeries: MultiAreaDatum[]
  }>(
    (acc, { telematicsTimestamp, speed = 0, stateOfCharge = 0 }) => {
      const timestamp = telematicsTimestamp
        ? tzDayjs(parseInt(telematicsTimestamp)).toDate()
        : null

      if (timestamp) {
        acc.socSeries.push({
          x: timestamp,
          y: stateOfCharge,
        })
        acc.speedSeries.push({
          x: timestamp,
          y: speed,
        })
      }
      return acc
    },
    { speedSeries: [], socSeries: [] }
  )

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: 'SOC',
        },
        max: 100,
        min: 0,
        labels: {
          formatter: function (val) {
            return val?.toFixed(0)
          },
        },
      },
      {
        title: {
          text: 'Speed',
        },
        labels: {
          formatter: function (val) {
            return val?.toFixed(0)
          },
        },
      },
    ],
    xaxis: {
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },

    stroke: {
      curve: 'straight',
      width: 1,
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        bottom: 0,
        left: 30,
      },
    },
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (val) {
            return val ? val.toFixed(0) + '%' : emDash
          },
        },
        {
          formatter: function (val) {
            return val?.toFixed(0) || emDash
          },
        },
      ],
      x: {
        format: 'dd MMM yyyy ' + timeFormat,
      },
    },
  }

  const speed = speedSeries.map((elem) => [new Date(elem.x).getTime(), elem.y])
  const soc = socSeries.map((elem) => [new Date(elem.x).getTime(), elem.y])

  const series = [
    {
      name: 'SoC',
      color: palette.success.main,
      type: 'area',
      data: soc,
    },
    {
      name: 'Speed',
      type: 'area',
      color: charting[1].main,
      data: speed,
    },
  ]

  const hasSufficientData = telematics.length >= MINIMUM_TRIP_DATA_POINTS
  const CaskContent = hasSufficientData ? (
    <Chart height={325} options={options} series={series} type="area" />
  ) : (
    <DataState
      hasData={hasSufficientData}
      noDataMessage="Trip too short to display"
    />
  )

  return <Cask title="Trip Overview">{CaskContent}</Cask>
}
