import { Dayjs } from 'dayjs'
import { EMPTY_ENTITY_MAP, parseToEntityMap } from '../api/utils'
import { EntityMap } from '@synop-react/types'
import { RootAPI, ScheduledEvent, useUserPrefs, useWindowFocus } from '..'
import { useEffect, useMemo, useState } from 'react'

const {
  useGetDepotQuery,
  useGetDepotUtilizationQuery,
  useGetDepotStatusQuery,
  useGetChargingTransactionsQuery,
  useGetScheduledChargerSessionsByDepotQuery,
  useGetDepotEventsByDepotIdQuery,
  useGetSmartPricesQuery,
  useGetScheduledDepotPowerQuery,
} = RootAPI.synopRootAPI

const POLLING_INTERVAL_IN_SECONDS = 60

interface useDepotDetailsFromPollProps {
  depotId?: string
  from?: Dayjs
  to?: Dayjs
  disablePolling?: boolean
  skipUtilization?: boolean
}

export const useDepotDetailsFromPoll = ({
  depotId,
  from,
  to,
  disablePolling = false,
  skipUtilization = true,
}: useDepotDetailsFromPollProps) => {
  const isWindowFocused = useWindowFocus()
  const [pollingInterval, setPollingInterval] = useState(
    isWindowFocused ? POLLING_INTERVAL_IN_SECONDS * 1000 : 0
  )

  useEffect(() => {
    setPollingInterval(isWindowFocused ? POLLING_INTERVAL_IN_SECONDS * 1000 : 0)
  }, [isWindowFocused])

  const { tzDayjs } = useUserPrefs()

  const validFrom = from?.isValid()
  const validTo = to?.isValid()
  const roundedFrom = (validFrom && from?.startOf('minute').toISOString()) || ''
  const roundedTo = (validTo && to?.startOf('minute').toISOString()) || ''

  const forecastTo = new Date()
  let roundedForecastTo = ''
  if (to && from) {
    forecastTo.setTime(
      to?.toDate().getTime() + Math.abs(from.diff(to, 'milliseconds'))
    )
    roundedForecastTo = tzDayjs(forecastTo).startOf('minute').toISOString()
  }
  const roundedForecastFrom = roundedTo

  const makeOptions = (requireDepot: boolean, requireTimeRange: boolean) => {
    return {
      pollingInterval: disablePolling ? 0 : pollingInterval,
      skip:
        (requireTimeRange && (!validFrom || !validTo)) ||
        (requireDepot && !depotId),
    }
  }

  const {
    data: getDepotData,
    isFetching: getDepotIsFetching,
    isLoading: getDepotIsLoading,
    isSuccess: getDepotIsSuccess,
    isError: getDepotIsError,
  } = useGetDepotQuery({ depotId: depotId || '' }, makeOptions(true, false))

  const {
    data: getDepotUtilizationData,
    isFetching: getDepotUtilizationIsFetching,
    isLoading: getDepotUtilizationIsLoading,
    isSuccess: getDepotUtilizationIsSuccess,
    isError: getDepotUtilizationIsError,
  } = useGetDepotUtilizationQuery(
    { depotId: depotId || '', from: roundedFrom, to: roundedTo },
    {
      pollingInterval: disablePolling ? 0 : pollingInterval,
      skip: skipUtilization || !validFrom || !validTo || !depotId,
    }
  )

  const {
    data: getDepotSmartPrices,
    isFetching: getDepotSmartPricesIsFetching,
    isLoading: getDepotSmartPricesIsLoading,
    isSuccess: getDepotSmartPricesIsSuccess,
    isError: getDepotSmartPricesIsError,
  } = useGetSmartPricesQuery(
    { depotId: depotId || '', from: roundedFrom, to: roundedForecastTo },
    makeOptions(true, true)
  )

  const {
    data: getDepotForecastData,
    isFetching: getDepotForecastIsFetching,
    isLoading: getDepotForecastIsLoading,
    isSuccess: getDepotForecastIsSuccess,
    isError: getDepotForecastIsError,
  } = useGetScheduledDepotPowerQuery(
    {
      depotId: depotId || '',
      from: roundedForecastFrom,
      to: roundedForecastTo,
    },
    makeOptions(true, true)
  )

  const {
    data: getDepotStatusData,
    isFetching: getDepotStatusIsFetching,
    isLoading: geetDepotStatusIsLoading,
    isSuccess: getDepotStatusIsSuccess,
    isError: getDepotStatusIsError,
  } = useGetDepotStatusQuery(
    { depotId: depotId || '' },
    makeOptions(true, false)
  )

  const {
    data: getScheduledChargerSessionsData,
    isFetching: getScheduledChargerSessionsIsFetching,
    isLoading: getScheduledChargerSessionsIsLoading,
    isSuccess: getScheduledChargerSessionsIsSuccess,
    isError: getScheduledChargerSessionsIsError,
  } = useGetScheduledChargerSessionsByDepotQuery(
    { depotId: depotId || '', to: roundedTo, from: roundedFrom },
    makeOptions(true, true)
  )

  const {
    data: getDepotEventsData,
    isFetching: getDepotEventsIsFetching,
    isLoading: getDepotEventsIsLoading,
    isSuccess: getDepotEventsIsSuccess,
    isError: getDepotEventsIsError,
  } = useGetDepotEventsByDepotIdQuery(
    { depotId: depotId || '', to: roundedForecastTo, from: roundedFrom },
    makeOptions(true, true)
  )
  const {
    data: getChargerTransactions,
    isFetching: isChargingTransactionsFetching,
    isLoading: isChargingTransacionsLoading,
    isSuccess: isChargingTransactionsSuccess,
    isError: isChargingTransactionsError,
  } = useGetChargingTransactionsQuery(
    {
      depotId,
      startTimeFrom: roundedFrom,
      startTimeTo: roundedTo,
      count: 1000,
    },
    makeOptions(false, false)
  )

  const getParsedScheduledChargerSessionsData = useMemo(() => {
    return getScheduledChargerSessionsIsSuccess
      ? parseToEntityMap<RootAPI.ChargerEventModel, ScheduledEvent>(
          getScheduledChargerSessionsData,
          'eventId'
        )
      : (EMPTY_ENTITY_MAP as EntityMap<ScheduledEvent>)
  }, [getScheduledChargerSessionsIsSuccess, getScheduledChargerSessionsData])

  return {
    getDepot: {
      data: getDepotData,
      isFetching: getDepotIsFetching,
      isLoading: getDepotIsLoading,
      isSuccess: getDepotIsSuccess,
      isError: getDepotIsError,
    },
    getDepotUtilization: {
      data: getDepotUtilizationData,
      isFetching: getDepotUtilizationIsFetching,
      isLoading: getDepotUtilizationIsLoading,
      isSuccess: getDepotUtilizationIsSuccess,
      isError: getDepotUtilizationIsError,
    },
    getDepotStatus: {
      data: getDepotStatusData,
      isFetching: getDepotStatusIsFetching,
      isLoading: geetDepotStatusIsLoading,
      isSuccess: getDepotStatusIsSuccess,
      isError: getDepotStatusIsError,
    },
    getScheduledChargerSessions: {
      data: getScheduledChargerSessionsData,
      parsedData: getParsedScheduledChargerSessionsData,
      isFetching: getScheduledChargerSessionsIsFetching,
      isLoading: getScheduledChargerSessionsIsLoading,
      isSuccess: getScheduledChargerSessionsIsSuccess,
      isError: getScheduledChargerSessionsIsError,
    },
    getDepotEvents: {
      data: getDepotEventsData,
      isFetching: getDepotEventsIsFetching,
      isLoading: getDepotEventsIsLoading,
      isSuccess: getDepotEventsIsSuccess,
      isError: getDepotEventsIsError,
    },
    getChargerTransactions: {
      data: getChargerTransactions,
      isFetching: isChargingTransactionsFetching,
      isLoading: isChargingTransacionsLoading,
      isSuccess: isChargingTransactionsSuccess,
      isError: isChargingTransactionsError,
    },
    getDepotSmartPrices: {
      data: getDepotSmartPrices,
      isFetching: getDepotSmartPricesIsFetching,
      isLoading: getDepotSmartPricesIsLoading,
      isSuccess: getDepotSmartPricesIsSuccess,
      isError: getDepotSmartPricesIsError,
    },
    getDepotForecast: {
      data: getDepotForecastData,
      isFetching: getDepotForecastIsFetching,
      isLoading: getDepotForecastIsLoading,
      isSuccess: getDepotForecastIsSuccess,
      isError: getDepotForecastIsError,
    },
  }
}
