import { Feature, point, Point, polygon, Position } from '@turf/helpers'
import { isGeofenceValid } from '@synop-react/common'
import { Maybe, PolygonFeature } from '@synop-react/types'

export type LocationProperties = {
  type: string
  locationNm?: string
}

type LocationFeatures = {
  locationMarker: Maybe<Feature<Point, LocationProperties>>
  locationGeofence: Maybe<PolygonFeature>
}

type GetLocationFeaturesInput = {
  locationId: string
  latitude?: number
  longitude?: number
  geofence?: string | PolygonFeature
  locationNm?: string
}

export function getLocationFeatures(location?: undefined): undefined
export function getLocationFeatures(
  location: GetLocationFeaturesInput
): LocationFeatures
export function getLocationFeatures(
  location: Maybe<GetLocationFeaturesInput>
): Maybe<LocationFeatures>

export function getLocationFeatures(
  location: GetLocationFeaturesInput | undefined
): Maybe<LocationFeatures> {
  if (!location) return

  const { locationId, latitude, longitude, geofence, locationNm } = location
  const locationMarker =
    latitude && longitude
      ? point([longitude, latitude], {
          type: 'POINT',
          id: locationId,
          locationNm,
        })
      : undefined

  if (typeof geofence === 'string') {
    const geoFencePositions: Position[] = JSON.parse(geofence)
    const locationGeofence = isGeofenceValid(geoFencePositions)
      ? polygon<LocationProperties>([geoFencePositions], {
          type: 'POLYGON',
        })
      : undefined
    return { locationMarker, locationGeofence }
  } else {
    return { locationMarker, locationGeofence: geofence }
  }
}
