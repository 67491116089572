import { CopyField, Icons } from '../../index'
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material'
import { getEnvConfig } from '@synop-react/config'
import { useState } from 'react'

const websocketUrl = getEnvConfig().OCPP_WEBSOCKET_URL

export type ChargerOcppConnectionInfoProps = {
  chargerId: string
}

const ChargerOcppConnectionInfo = ({
  chargerId,
}: ChargerOcppConnectionInfoProps) => {
  const theme = useTheme()

  const [wsUrlPrefix, setWsUrlPrefix] = useState<string>('ws://')

  return (
    <>
      <Icons.Activity color={theme.palette.primary.main} size={22} />
      <Typography variant="subtitle2">
        Enter these values into your OEM Portal to connect this charger
      </Typography>
      <Grid item>
        <CopyField
          key={'websocket-url'}
          label="Websocket URL"
          value={wsUrlPrefix + websocketUrl}
        />
      </Grid>

      <Grid item>
        <CopyField key={'charger-id'} label="ID" value={chargerId} />
      </Grid>

      <Grid item>
        <RadioGroup
          onChange={({ target: { value } }) => setWsUrlPrefix(value)}
          row
          sx={{ pt: 2 }}
          value={wsUrlPrefix}
        >
          <FormControlLabel
            control={<Radio />}
            label="WS URL"
            sx={{ pr: 2, pl: 1 }}
            value="ws://"
          />
          <FormControlLabel
            control={<Radio />}
            label="WSS URL"
            value="wss://"
          />
        </RadioGroup>
      </Grid>
    </>
  )
}

export default ChargerOcppConnectionInfo
