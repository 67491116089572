import { sum } from 'lodash'

import { ColorMap } from '@synop-react/common'
import { Maybe } from '@synop-react/types'

type StatusSummaryMap = Record<string, number>

export function parseStatusSummaries(
  statuses: Maybe<StatusSummaryMap>,
  colorMap: ColorMap<string>
) {
  if (!statuses) return []

  const total = sum(Object.values(statuses ?? []))
  return Object.entries(statuses).flatMap(([status, count]) =>
    count === 0
      ? []
      : [
          {
            text: status,
            percentage: (count / total) * 100,
            color: colorMap[status] ?? '#000000',
          },
        ]
  )
}
