import { AccessTimeOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, alpha, Typography } from '@mui/material'

type RateTimezoneNoticeProps = {
  title: string
  description: string
}

export const RateTimezoneNotice = ({
  title,
  description,
}: RateTimezoneNoticeProps) => {
  return (
    <Alert
      icon={
        <AccessTimeOutlined
          sx={{ color: 'primary.main', alignSelf: 'center' }}
        />
      }
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
      }}
    >
      <AlertTitle>
        <Typography color="text.primary" fontWeight={600} variant="body2">
          {title}
        </Typography>
      </AlertTitle>
      <Typography color="text.primary" variant="body2">
        {description}
      </Typography>
    </Alert>
  )
}
