import { Box, Stack, Typography, useTheme } from '@mui/material'
import { EntityIdMap } from '@synop-react/types'
import {
  formatSocFromSourceAttributes,
  formatVehicleName,
  Icons,
} from '@synop-react/common'
import { Marker } from 'react-map-gl'
import { orderBy } from 'lodash'
import { ReactNode } from 'react'
import { RootAPI, VehicleStatus } from '@synop-react/api'

export type VehicleLocationBadgeMarkersProps = {
  vehicles: EntityIdMap<RootAPI.VehicleModel>
}

export const VehicleLocationBadgeMarkers = ({
  vehicles,
}: VehicleLocationBadgeMarkersProps) => {
  const { palette } = useTheme()

  const Markers = orderBy(
    Object.values(vehicles),
    (vehicle) => vehicle.stateOfCharge?.value,
    ['desc']
  ).map((vehicle, i) => {
    const { id, longitude, latitude, stateOfCharge } = vehicle
    const soc = stateOfCharge?.value || 0

    const badgeColor =
      soc >= 80
        ? palette.secondary.main
        : soc >= 40
        ? palette.warning.main
        : palette.error.main

    const badgeOffset = -10
    const markerZIndex = 1000 + i

    return latitude && longitude ? (
      <>
        <Marker
          key={`${id}_badge`}
          anchor="right"
          latitude={latitude.value}
          longitude={longitude.value}
          offset={[badgeOffset, 0]}
          style={{ zIndex: markerZIndex }}
        >
          <VehicleMapBadge vehicle={vehicle} />
        </Marker>
        <Marker
          key={`${id}_dotline`}
          latitude={latitude.value}
          longitude={longitude.value}
          style={{ zIndex: markerZIndex }}
        >
          <DotLine color={badgeColor} />
        </Marker>
      </>
    ) : null
  })

  return <>{Markers}</>
}

type MapBadgeProps = {
  vehicle: RootAPI.VehicleModel
}

const VehicleMapBadge = ({ vehicle }: MapBadgeProps) => {
  const { palette } = useTheme()
  const { stateOfCharge, vehicleStatus } = vehicle

  const soc = stateOfCharge?.value || 0

  const badgeColor =
    soc >= 80
      ? palette.secondary.main
      : soc >= 40
      ? palette.warning.main
      : palette.error.main

  const iconSize = 12

  const statusIconMap: Record<VehicleStatus, ReactNode> = {
    Charging: <Icons.Zap color="white" size={iconSize} />,
    Idle: <Icons.Moon color="white" size={iconSize} />,
    'In Motion': <Icons.Navigation color="white" size={iconSize} />,
    Off: <Icons.Slash color="white" size={iconSize} />,
  }

  const StatusIcon = vehicleStatus?.value ? (
    statusIconMap[vehicleStatus?.value as VehicleStatus]
  ) : (
    <Icons.AlertOctagon color="white" size={iconSize} />
  )

  const vehicleName = formatVehicleName(vehicle)

  return (
    <Box
      sx={{ backgroundColor: badgeColor, borderRadius: 2, px: 1, py: 0.25 }}
      title={vehicleName}
    >
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {StatusIcon}
        <Typography color="white" maxWidth={85} noWrap variant="subtitle2">
          {vehicleName}
        </Typography>
        <Typography color="white" variant="body2">
          {formatSocFromSourceAttributes(stateOfCharge)}
        </Typography>
      </Stack>
    </Box>
  )
}

const DotLine = ({ color }: { color: string }) => {
  const dotSize = 10
  const lineThickness = 2

  return (
    <Stack alignItems="center" direction="row" spacing={0}>
      <Box
        sx={{
          height: lineThickness,
          width: 15,
          backgroundColor: color,
        }}
      />
      <Box
        sx={{
          borderRadius: '100%',
          backgroundColor: color,
          width: dotSize,
          height: dotSize,
        }}
      />
    </Stack>
  )
}
