import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'

import { BaseTableCask } from './BaseTableCask'
import { Table } from '../Table'
import { TableCaskProps } from './types'

const DEFAULT_PAGE_SIZE = 25

type ClientSideTableCaskProps<T extends GridValidRowModel> = TableCaskProps<T> &
  Omit<Table.ClientSideProps<T>, 'tableData'> & {
    getRowId: (row: T) => string
  }

function ClientSideTableCask<T extends GridValidRowModel>({
  columns,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  disableRowSelectionOnClick,
  initialSortColumn,
  initialSortOrder,
  filterModel,
  onFilterModelChange,
  getRowId,
  groupingColDef,
  tableData,
  noRowsMessage,
  checkboxSelection = false,
  initialState,
  onRowSelectionModelChange,
  getTreeDataPath,
  ...rest
}: ClientSideTableCaskProps<T>) {
  const [visibleData, setVisibleData] = useState<T[]>(tableData)
  const [visibleColumns, setVisibleColumns] = useState(columns)

  // Update the columns if they change
  useEffect(() => {
    setVisibleColumns(columns)
  }, [columns])
  // Update the visible data if new data is passed in
  useEffect(() => {
    setVisibleData(tableData)
  }, [tableData])

  return (
    <BaseTableCask
      {...rest}
      columns={visibleColumns}
      setVisibleColumns={setVisibleColumns}
      setVisibleData={setVisibleData}
      tableData={tableData}
      visibleColumns={visibleColumns}
      visibleData={visibleData}
    >
      <Table.ClientSide
        columns={visibleColumns.filter((col) => col.isDefaultVisible ?? true)}
        tableData={visibleData}
        {...{
          checkboxSelection,
          defaultPageSize,
          disableRowSelectionOnClick,
          filterModel,
          onFilterModelChange,
          getRowId,
          getTreeDataPath,
          groupingColDef,
          initialSortColumn,
          initialSortOrder,
          initialState,
          noRowsMessage,
          onRowSelectionModelChange,
        }}
      />
    </BaseTableCask>
  )
}

export {
  ClientSideTableCask as ClientSide,
  ClientSideTableCaskProps as ClientSideProps,
}
