import { useMemo } from 'react'

import { EMPTY_ENTITY_MAP, parseToEntityMap } from '../api/utils'
import { EntityMap } from '@synop-react/types'
import { RootAPI, useCurrentOrgId, useCurrentUser, VehicleModel } from '..'

const { useGetVehiclesForOrganizationQuery } = RootAPI.synopRootAPI

type UseOrgVehiclesOptions = {
  /**
   * The organization id to fetch vehicles for
   */
  orgId?: string
  /**
   * Flag for whether the vehicles should be polled
   * @default false
   */
  poll?: boolean
  /**
   * Polling interval in milliseconds if poll flag is true
   * @default 60000
   */
  pollingInterval?: number
}

export const useOrgVehicles = ({
  orgId,
  poll = false,
  pollingInterval = 60000,
}: UseOrgVehiclesOptions = {}) => {
  const currentOrgId = useCurrentOrgId()
  const { synopUser, isAdmin } = useCurrentUser()

  const { isSuccess, isLoading, data } = useGetVehiclesForOrganizationQuery(
    {
      id: orgId || currentOrgId,
    },
    {
      skip: !(orgId || currentOrgId),
      pollingInterval: poll ? pollingInterval : undefined,
      refetchOnFocus: true,
    }
  )

  const parsedVehicles = useMemo(() => {
    let vehicles = data
    if (
      data &&
      !isAdmin &&
      synopUser &&
      synopUser.sites &&
      synopUser.sites.length > 0
    ) {
      vehicles = data.filter(
        (item) =>
          synopUser &&
          synopUser.sites &&
          item.homeSiteId &&
          synopUser.sites.includes(item.homeSiteId)
      )
    }
    return isSuccess && vehicles?.length
      ? parseToEntityMap<RootAPI.VehicleModel, VehicleModel>(vehicles, 'id')
      : (EMPTY_ENTITY_MAP as EntityMap<VehicleModel>)
  }, [isSuccess, data, synopUser, isAdmin])

  const orgVehicles = parsedVehicles.entities
  return {
    orgVehicles,
    isLoading,
    isSuccess,
    lookup: (vehicleId?: string) =>
      vehicleId ? orgVehicles[vehicleId] : undefined,
  }
}
