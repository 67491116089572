import { Chart } from '@synop-react/common'
import { Dayjs } from 'dayjs'
import {
  TemperatureChartData,
  useChartData,
  useUserPrefs,
} from '@synop-react/api'
import { useMemo } from 'react'

export type UseVehicleAirTempProps = {
  vehicleId: string
  from: Dayjs
  to: Dayjs
}

const useVehicleAirTemp = ({ from, to, vehicleId }: UseVehicleAirTempProps) => {
  const { tzDayjs } = useUserPrefs()
  const { data: airTempData, units: aiTempUnits } = useChartData({
    vehicleId,
    from,
    to,
    attributes: ['ambientAirTemperature'],
  }) as unknown as TemperatureChartData

  const airTempOverTime = useMemo(() => {
    return airTempData.reduce<Chart.AreaDatum[]>(
      (acc, [timestamp, temperature]) => {
        const sampleTime = tzDayjs(Number(timestamp)).toISOString()
        const datapoint = { x: sampleTime, y: temperature }
        acc.push(datapoint)

        return acc
      },
      []
    )
  }, [airTempData, tzDayjs])

  return {
    airTempOverTime,
    airTempUnit: aiTempUnits[1],
  }
}

export default useVehicleAirTemp
