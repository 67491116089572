import { Control, FieldValues, Path } from 'react-hook-form'
import { useState } from 'react'

export type EntityType = 'VEHICLE' | 'CHARGER'
export type EntityTypeOption = { type: EntityType; label: string }
import { FormField } from '@synop-react/common'

type PowerTargetDropdownProps<FormData extends FieldValues> = {
  id: Path<FormData>
  label?: string
  control: Control<FormData, unknown>
} & FormField.AutocompleteFormControls<FormData, EntityTypeOption> &
  FormField.AutocompleteTextFieldProps

const EntityTypeDropdown = <FormData extends FieldValues>({
  id,
  control,
  label = 'Type',
  ...autocompleteProps
}: PowerTargetDropdownProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)

  // Entity types
  const entityOptions: EntityTypeOption[] = [
    {
      type: 'CHARGER',
      label: 'Chargers',
    },
    {
      type: 'VEHICLE',
      label: 'Vehicles',
    },
  ]

  return (
    <FormField.AutocompleteSelect<FormData, EntityTypeOption>
      {...autocompleteProps}
      control={control}
      disableClearable={true}
      getOptionLabel={(option: EntityTypeOption) => `${option.label}`}
      id={id}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: EntityTypeOption) => option.type}
      label={label}
      options={entityOptions}
      setIsOpen={setIsOpen}
    />
  )
}

export default EntityTypeDropdown
