import { Entity, EntityBooleanMap, EntityIdMap } from '@synop-react/types'

export { default as EntityListItemDetail } from './Entity/List/Item/Detail'
export { default as EntityBrowser } from './Entity/Browser'
export { default as EntityList } from './Entity/List'
export * from './Entity/Map'
export { default as VehicleLink } from './Entity/Link'
export { default as MapPin, createMapPinImg } from './Entity/Map/Pin'
export * from './Entity/Selector'

export * from './Map'

export { default as EntitySearchInput } from './Entity/Browser/Toolbar/EntitySearchInput'
export { default as EntitySortSelect } from './Entity/Browser/Toolbar/EntitySortSelect'

export { default as LoadingMessage } from './LoadingMessage'

export * from './Cask'

export { default as TimelineCask } from './Charts/Timeline/Cask'
export { Donut } from './Charts'

export * from './DetailPage/DetailPageHeader'
export { StatusCask } from './DetailPage/StatusCask'

export { default as StatusCaskItem } from './DetailPage/StatusCask/Item'

export * from './Icons' // Use this for direct imports
export * from './utils'

// Test & Mock Helpers
export { renderWithProviders } from './.test/TestProviders'
export { ResizeObserverStub } from './.test/ResizeObserverStub'

export * from '../.mocks/ScheduledCharges.mock'

// Types
export type EntityFilterer<T = unknown> = (
  filterValue: string,
  entities: EntityIdMap<Entity<T>>
) => EntityBooleanMap
export type EntitySorter<T = unknown> = {
  label: string
  comparator: EntitySortComparator<T>
  direction?: SortDirection
}

export type EntitySorterMap<T = unknown> = Record<string, EntitySorter<T>>
export type EntitySortComparator<T = unknown> = (
  entities: Array<Entity<T>>,
  direction?: SortDirection
) => Array<Entity<T>>
export enum SortDirections {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}
export type SortDirection =
  | SortDirections.ASCENDING
  | SortDirections.DESCENDING
  | undefined

export type { TEntityListItem } from './Entity/List/Item'
export type { TEntityListItemDetail } from './Entity/List/Item/Detail'
export * from './Entity/Browser'

export type { CaskItemProgress } from './DetailPage/StatusCask/Item'

export * as FormField from './FormField'
export type {
  AutocompleteFilterOptions,
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  TextFieldFormControls,
  TextFormFieldProps,
} from './FormField'
export * from './Autocomplete'
export * from './Dropdown'

export * from './Table'
export * from './TableCask'
export { TabCaskView } from './TabCaskView'
export * from './TimeRangeCask'
export * from './TimeRange'
export { DetailsCask } from './DetailPage/DetailsCask'
export { ProgressPill } from './ProgressPill'
export type { ProgressPillColor, ProgressPillColorFilter } from './ProgressPill'
export { Tile } from './Tile'
export { LineGraph } from './Charts/LineGraph'
export type { LineDatum, LineData } from './Charts/LineGraph'
export * from './Overlay'
export * as Chart from './Charts'

export {
  UtilizationAreaChart,
  TIME_RESOLUTIONS,
} from './Charts/UtilizationArea'
export type {
  Datum as UtilizationAreaChartDatum,
  TimeResolution,
} from './Charts/UtilizationArea'

export * from './UtilizationChartCask'

export { Menu } from './Menu'
export { ListItem } from './Menu/ListItem'
export { NestedListItem } from './Menu/NestedListItem'
export * from './Charts/Timeline'
export { ThemeTileRow } from './ThemeTileRow'
export { ImageTile } from './ImageTile'
export * from './IconTile'
export { Crate } from './Crate'
export { CopyField } from './CopyField'

export * from './AlertBar'
export * from './Center'
export * from './ColorDot'
export * from './Dashboard'
export * from './DataState'
export * from './ChargingSession'
export * from './DepotEvent'
export * from './Chargers'
export * from './Reports'
export * from './NoData'
export * from './OverlayTitlePill'
export * from './Tooltip'
export * from './store'
export * from './Snackbar'

export * from './Fallback'
