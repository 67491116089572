import { Stack } from '@mui/material'
import NotificationBell from './NotificationBell'
import UserMenu from './UserMenu'

const SettingsTray = () => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
      spacing={1}
    >
      <NotificationBell />
      <UserMenu />
    </Stack>
  )
}

export default SettingsTray
