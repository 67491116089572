import { CarbonSavedTile } from '../CarbonSavedTile'
import { DailyEnergyUse } from '../DailyEnergyUse'
import { Dayjs } from 'dayjs'
import {
  isSourceAttributeValue,
  TimeCaskChildrenProps,
  TimeRangeCask,
} from '@synop-react/common'
import { NetEnergyActivity } from '../NetEnergyActivity'
import { RootAPI, VehicleModel } from '@synop-react/api'
import { SocActivityGraph } from '../SocActivityGraph'
import { Stack } from '@mui/material'
import { useMemo } from 'react'
import { VehicleStatePieChart } from '../VehicleStatePieChart'

const { useGetVehicleQuery } = RootAPI.synopRootAPI

export type DailyVehicleSummaryCaskProps = {
  vehicleId: string
}

export const DailyVehicleSummaryCask = ({
  vehicleId,
}: DailyVehicleSummaryCaskProps) => {
  const { data: vehicle = {}, isLoading: isLoadingVehicle } =
    useGetVehicleQuery({
      id: vehicleId,
    })

  // TODO Remove this when the API starts returning telemetry providers @wslater
  const telemetryProviders = useMemo<string[]>(
    () =>
      Array.from(
        Object.values(vehicle as VehicleModel).reduce((acc, value) => {
          if (isSourceAttributeValue(value) && value.source) {
            acc.add(value.source)
          }
          return acc
        }, new Set<string>())
      ),
    [vehicle]
  )

  return (
    <TimeRangeCask
      defaultTimeResolution="CUSTOM"
      disableToFuture
      isLoading={isLoadingVehicle}
      rangeControl="CustomSingleDate"
      ranges={['CUSTOM']}
      title="Daily Summary"
    >
      {({ from, to }: TimeCaskChildrenProps) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <VehicleSummaryTiles
            {...{ telemetryProviders, from, to, vehicleId }}
          />
        </Stack>
      )}
    </TimeRangeCask>
  )
}

export default DailyVehicleSummaryCask

type TelemetryTilesProps = {
  telemetryProviders: string[]
} & SummaryTileProps

type SummaryTileProps = {
  vehicleId: string
  from: Dayjs
  to: Dayjs
}

const VehicleSummaryTiles = ({
  telemetryProviders,
  ...summaryProps
}: TelemetryTilesProps) =>
  telemetryProviders.includes('Lightning') ? (
    <LightningSummaryTiles {...summaryProps} />
  ) : (
    <DefaultSummaryTiles {...summaryProps} />
  )

const DefaultSummaryTiles = ({ vehicleId, from, to }: SummaryTileProps) => {
  return (
    <>
      <NetEnergyActivity from={from} vehicleId={vehicleId} />
      <SocActivityGraph from={from} to={to} vehicleId={vehicleId} />
      <CarbonSavedTile from={from} to={to} vehicleId={vehicleId} />
      <VehicleStatePieChart from={from} vehicleId={vehicleId} />
    </>
  )
}

const LightningSummaryTiles = ({ vehicleId, from, to }: SummaryTileProps) => {
  return (
    <>
      <NetEnergyActivity from={from} vehicleId={vehicleId} />
      <VehicleStatePieChart from={from} vehicleId={vehicleId} />
      <CarbonSavedTile from={from} to={to} vehicleId={vehicleId} />
      <DailyEnergyUse from={from} vehicleId={vehicleId} />
    </>
  )
}
