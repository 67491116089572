import { useTheme } from '@mui/material'

import {
  createMapPinImg,
  MapAssets,
  MapAssetsProps,
  MapMarkerLayer,
  MapPolygonLayer,
  MapPolygonLayerProps,
} from '@synop-react/common'

export type DepotMapLayerProps = {
  minGeofenceZoom?: number
  maxMarkerZoom?: number
}

export type DepotMapLocationLayersProps = Pick<
  MapPolygonLayerProps,
  'sourceId'
> &
  Pick<MapAssetsProps, 'mapId'> &
  DepotMapLayerProps

export const depotCapacityMarkerAssets = () => ({
  underCapacityPin: createMapPinImg('secondary'),
  overCapacityPin: createMapPinImg('error'),
})

export const depotLayerFilters = {
  defaultFilter: ['all', ['==', ['geometry-type'], 'Point']],
}

export const depotGeofenceFilter = ['==', ['geometry-type'], 'Polygon']

export function DepotMapLocationLayers({
  mapId,
  sourceId,
  minGeofenceZoom = 11,
}: DepotMapLocationLayersProps) {
  const { palette } = useTheme()

  const { defaultFilter } = depotLayerFilters
  const layerAssets = {
    locationPin: '/assets/images/map_pin_sdf.png',
  }

  return (
    <>
      <MapPolygonLayer
        fillColor={palette.info.main}
        layerId="depotGeofence"
        minZoom={minGeofenceZoom}
        sourceId={sourceId}
      />
      <MapAssets layerAssets={layerAssets} mapId={mapId}>
        <MapMarkerLayer
          assetName="locationPin"
          filter={defaultFilter}
          iconColor={palette.secondary.main}
          layerId="defaultDepots"
          sourceId={sourceId}
        />
      </MapAssets>
    </>
  )
}
