import { Narrow } from '@synop-react/types'
import { useMemo } from 'react'

/**
 * Sorts an array of entities by a given field name, ignoring case and intelligently
 * handling numeric values.
 */
export function useSort<E>(
  entities: E[],
  fieldName: Narrow.MaybeStringKeyOf<E>
) {
  return useMemo(
    () =>
      // Slice the array so the input array isn't mutated
      [...entities].sort((a, b) => {
        const aVal = a[fieldName]
        const bVal = b[fieldName]

        if (typeof aVal !== 'string') {
          return typeof bVal === 'string' ? 1 : 0
        } else if (typeof bVal !== 'string') {
          return typeof aVal === 'string' ? -1 : 0
        } else {
          return aVal.localeCompare(bVal, undefined, {
            ignorePunctuation: true,
            numeric: true,
          })
        }
      }),
    [entities, fieldName]
  )
}
