import { ChargerFaultsTable, TabCaskView } from '@synop-react/common'
import { DepotTimeline } from '@synop-react/depot'
import { SiteChargingTransactionsHistory } from '@synop-react/depot'
import { VehiclesTimeline } from '@synop-react/vehicle'

interface ChargingSessionTabeCaskViewProps {
  depotId: string
}

const ChargingSessionTabCaskView = ({
  depotId,
}: ChargingSessionTabeCaskViewProps) => {
  return (
    <TabCaskView
      tabItems={[
        {
          label: 'CHARGER',
          content: (
            <DepotTimeline
              depotId={depotId}
              title="Charger Schedule"
              tooltip="Schedule power limits or optimized charging on a charger by charger basis here."
            />
          ),
        },
        {
          label: 'VEHICLE',
          content: (
            <VehiclesTimeline
              depotId={depotId}
              title="Vehicle Schedule"
              tooltip="Visualize scheduled power limits or optimized charging on a vehicle by vehicle basis here"
            />
          ),
        },
        {
          label: 'Transaction History',
          content: <SiteChargingTransactionsHistory depotId={depotId} />,
        },
        {
          label: 'Faults & WARNINGS',
          content: <ChargerFaultsTable omitSite siteIds={[depotId]} />,
        },
      ]}
    />
  )
}

export default ChargingSessionTabCaskView
