import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Switch,
  Typography,
} from '@mui/material'

import {
  Bolt,
  CreditCardOutlined,
  ElectricCar,
  ExpandMore,
  WorkOutline,
} from '@mui/icons-material'
import { QueryStatus } from '@reduxjs/toolkit/dist/query/react'
import { ReactElement, SyntheticEvent, useState } from 'react'
import { RootAPI, useCurrentOrganization } from '@synop-react/api'
import { useSnackbarControls } from '@synop-react/common'

interface SynopFeature {
  title: string
  description: string
  icon?: ReactElement
  isEnabled: boolean
  isUpdating?: boolean
  features: SynopFeature[]
  handleChange?: () => void
}

const { useUpdateOrganizationMutation } = RootAPI
export function OrgServiceTiles() {
  const { openSnackbar } = useSnackbarControls()
  const selectedOrg = useCurrentOrganization().currentOrg
  const [updateCurrentOrg, updateCurrentOrgResponse] =
    useUpdateOrganizationMutation()

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const getNextBillingStatus = (
    currentStatus: RootAPI.Organization[
      | 'workplaceChargingStatus'
      | 'reimbursementsStatus'] = 'NEVER_ENABLED'
  ) =>
    currentStatus === 'NEVER_ENABLED' || currentStatus === 'DISABLED'
      ? 'ENABLED'
      : 'DISABLED'

  const synopFeatures: SynopFeature[] = [
    {
      title: 'Energy Management',
      description: 'Monitor and respond to grid events and pricing.',
      isEnabled: true,
      icon: <Bolt color={'primary'} />,
      features: [
        {
          title: 'Site Events',
          description: 'Monitor and schedule fleet activity.',
          isEnabled: selectedOrg?.hasEnergyManagement ?? false,
          icon: <Bolt color={'primary'} />,
          features: [],
          handleChange: () => {
            if (updateCurrentOrgResponse.status !== QueryStatus.pending) {
              updateCurrentOrg({
                organization: {
                  ...selectedOrg,
                  hasEnergyManagement: !selectedOrg?.hasEnergyManagement,
                },
              })
                .unwrap()
                .then((payload) =>
                  openSnackbar(
                    `You have successfully ${
                      payload?.hasEnergyManagement ?? false
                        ? 'enabled'
                        : 'disabled'
                    } Site Events.`,
                    {
                      title: `Site Events ${
                        payload?.hasEnergyManagement ?? false
                          ? 'Enabled'
                          : 'Disabled'
                      }`,
                    }
                  )
                )
                .catch(() =>
                  openSnackbar('Set up of Site Events was not successful.', {
                    severity: 'error',
                    title: 'Site Events Rejected',
                  })
                )
            }
          },
        },
      ],
    },
  ]
  if (selectedOrg?.hasReimbursements || selectedOrg?.hasWorkplaceCharging) {
    synopFeatures.push({
      title: 'Billing',
      description:
        'Collect revenue for use of charging assets, reimburse home usage and monitor invoices.',
      isEnabled: true,
      icon: <CreditCardOutlined color={'primary'} />,
      features: [],
    })
    if (selectedOrg?.hasReimbursements)
      synopFeatures.at(-1)?.features.push({
        title: 'Home Charging',
        description: 'Reimburse users for charging at home',
        isEnabled: selectedOrg?.reimbursementsStatus === 'ENABLED',
        handleChange: () => {
          if (updateCurrentOrgResponse.status !== QueryStatus.pending) {
            updateCurrentOrg({
              organization: {
                ...selectedOrg,
                reimbursementsStatus: getNextBillingStatus(
                  selectedOrg?.reimbursementsStatus
                ),
              },
            })
              .unwrap()
              .then(({ reimbursementsStatus }) => {
                const isEnabled = reimbursementsStatus === 'ENABLED'

                openSnackbar(
                  `You have successfully ${
                    isEnabled ? 'enabled' : 'disabled'
                  } Home Charging.`,
                  {
                    title: `Home Charging ${
                      isEnabled ? 'Enabled' : 'Disabled'
                    }`,
                  }
                )
              })
              .catch(() =>
                openSnackbar('Set up of Home Charging was not successful.', {
                  severity: 'error',
                  title: 'Home Charging Rejected',
                })
              )
          }
        },
        icon: <ElectricCar color={'primary'} />,
        features: [],
      })
    if (selectedOrg?.hasWorkplaceCharging) {
      synopFeatures.at(-1)?.features.push({
        title: 'Workplace Charging',
        description: 'Charge employees for use of workplace chargers',
        isEnabled: selectedOrg?.workplaceChargingStatus === 'ENABLED',
        handleChange: () => {
          if (updateCurrentOrgResponse.status !== QueryStatus.pending) {
            updateCurrentOrg({
              organization: {
                ...selectedOrg,
                workplaceChargingStatus: getNextBillingStatus(
                  selectedOrg?.workplaceChargingStatus
                ),
              },
            })
              .unwrap()
              .then(({ workplaceChargingStatus }) => {
                const isEnabled = workplaceChargingStatus === 'ENABLED'

                openSnackbar(
                  `You have successfully ${
                    isEnabled ? 'enabled' : 'disabled'
                  } Workplace Charging.`,
                  {
                    title: `Workplace Charging ${
                      isEnabled ? 'Enabled' : 'Disabled'
                    }`,
                  }
                )
              })
              .catch(() =>
                openSnackbar(
                  'Set up of Workplace Charging was not successful.',
                  {
                    severity: 'error',
                    title: 'Workplace Charging Rejected',
                  }
                )
              )
          }
        },
        icon: <WorkOutline color={'primary'} />,
        features: [],
      })
    }
  }

  return (
    <Grid item spacing={4} xs={12}>
      {synopFeatures.map((group) => {
        return (
          <Accordion
            key={group.title}
            expanded={expanded === group.title}
            id={group.title}
            onChange={handleChange(group.title)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 4, display: 'flex', justifyContent: 'center' }}>
                  {group.icon}
                </Box>
                <div>
                  <Typography sx={{ fontWeight: 500 }}>
                    {group.title}
                  </Typography>
                  <Typography sx={{ color: 'text.disabled' }} variant="body2">
                    {group.description}
                  </Typography>
                </div>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {group.features.map((subFeature) => {
                return (
                  <Box
                    key={subFeature.title}
                    sx={{
                      gap: 2,
                      ml: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      '&:not(:last-of-type)': { mb: 4 },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          mr: 4,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {subFeature.icon}
                      </Box>
                      <div>
                        <Typography sx={{ fontWeight: 500 }}>
                          {subFeature.title}
                        </Typography>
                        <Typography
                          sx={{ color: 'text.disabled' }}
                          variant="body2"
                        >
                          {subFeature.description}
                        </Typography>
                      </div>
                    </Box>
                    {subFeature?.isUpdating ?? false ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Switch
                        checked={subFeature.isEnabled}
                        onChange={subFeature.handleChange}
                      />
                    )}
                  </Box>
                )
              })}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Grid>
  )
}

export default OrgServiceTiles
