import { RootAPI } from '..'

export const locationOfInterestTypes = [
  'Port',
  'Maintenance',
  'Residence',
  'Other',
] as const
export type LocationOfInterestType = (typeof locationOfInterestTypes)[number]

export type LocationOfInterest = RootAPI.LocationOfInterestModel & {
  loiType: LocationOfInterestType
}

export type CurrentLocationType = LocationOfInterestType | 'Site'
