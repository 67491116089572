import { Group } from '@visx/group'
import { styled } from '@mui/material'
import { Text } from '@visx/text'
import { useMemo } from 'react'

import { GenericEvent, useUserPrefs } from '@synop-react/api'
import {
  selectXScale,
  selectYScale,
} from '../../useTimeline/useTimelineReducer/selectors/scale'
import { truncateSvgText } from '../../../../utils/svg/truncateSvgText'
import { useTimeline } from '../../useTimeline'
import { useTimelineSelector } from '../../useTimeline/useTimeline'

export type TitleFormatter = (event: GenericEvent) => string
export type CaptionTitle = string | TitleFormatter

export type CaptionFormatter = {
  title: CaptionTitle
  titleDescription?: CaptionTitle
}

export interface EventCaptionProps {
  scheduledEvent: GenericEvent
}

const defaultCaption: CaptionFormatter = {
  title: '',
  titleDescription: '',
}

const barSpacing = 4

export function TimelineEventCaption({ scheduledEvent }: EventCaptionProps) {
  const { tzDayjs } = useUserPrefs()
  const {
    displayedDate,
    eventOptions,
    groupingKey,
    isOverlayOpen,
    closeEventOverlay,
  } = useTimeline()
  const xScale = useTimelineSelector(selectXScale)
  const yScale = useTimelineSelector(selectYScale)

  const x = useMemo(() => {
    const { scheduledStart, scheduledEnd } = scheduledEvent

    const isBeforeDD = tzDayjs(scheduledStart).isBefore(displayedDate)
    const isAfterDB = tzDayjs(scheduledEnd).isAfter(displayedDate)
    const dateToScale =
      isBeforeDD && isAfterDB
        ? displayedDate.toDate()
        : new Date(scheduledEvent.scheduledStart)
    return xScale(dateToScale)
  }, [scheduledEvent, tzDayjs, displayedDate, xScale])

  const visibleBarWidth = useMemo(() => {
    const endDate = scheduledEvent.scheduledEnd
      ? new Date(scheduledEvent.scheduledEnd)
      : new Date()
    const xEnd = xScale(endDate)
    const width = xEnd - x - barSpacing

    return width
  }, [xScale, x, scheduledEvent])

  const groupingId =
    typeof groupingKey === 'function'
      ? groupingKey(scheduledEvent)
      : (scheduledEvent[groupingKey] as string)
  const y = yScale(groupingId)

  const { caption = defaultCaption, renderOverlay } =
    eventOptions[scheduledEvent.eventType]

  const { title, titleDescription } = caption
  const formattedTitle =
    typeof title === 'function' ? title(scheduledEvent) : title
  const formattedDescription =
    typeof titleDescription === 'function'
      ? titleDescription(scheduledEvent)
      : titleDescription

  const centeredTextYOffset = 12
  const centeredTitleYOffset = -28
  const centeredDescriptionYOffset = -12

  const [titleOffset, descriptionOffset] = formattedDescription
    ? [centeredTitleYOffset, centeredDescriptionYOffset]
    : [centeredTextYOffset]

  const captionTitle = truncateSvgText(formattedTitle, visibleBarWidth, {
    isBold: true,
  })
  const captionDescription = truncateSvgText(
    formattedDescription,
    visibleBarWidth
  )

  const canRenderOverlay = !!(
    renderOverlay &&
    renderOverlay({
      ...scheduledEvent,
      isOpen: isOverlayOpen,
      setIsOpen: closeEventOverlay,
    })
  )

  return (
    <Group>
      <title>{formattedTitle}</title>
      <CaptionText
        dy={titleOffset}
        isLink={canRenderOverlay}
        style={{ fontWeight: 'bold' }}
        x={x}
        y={y}
      >
        {captionTitle}
      </CaptionText>
      {formattedDescription && (
        <CaptionText dy={descriptionOffset} x={x} y={y}>
          {captionDescription}
        </CaptionText>
      )}
    </Group>
  )
}

export const CaptionText = styled(Text, {
  shouldForwardProp: (prop: string) => !['color', 'isLink'].includes(prop),
})<{ color?: string; isLink?: boolean }>(({ theme, isLink = false }) => {
  const captionProps = theme.typography.caption
  const textColor = isLink
    ? theme.palette.primary.main
    : theme.palette.text.primary
  return {
    ...captionProps,
    fill: textColor,
  }
})

export default TimelineEventCaption
