import {
  Card,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactNode } from 'react'

import { Chart } from '@synop-react/common'

export type StatusEntity = 'chargers' | 'connectors'
export type SummaryData = {
  isLoading: boolean
  summaryData: Record<StatusEntity, Chart.Datum[]>
}

type StatusChartCardProps = { Actions?: ReactNode; title: string }
type StatusSummaryChartsProps = {
  ChargerCardProps: StatusChartCardProps
  ConnectorCardProps: StatusChartCardProps
  data: SummaryData
  loadingSkeletons?: boolean
}

export const StatusSummaryCharts = ({
  ChargerCardProps,
  ConnectorCardProps,
  data,
  loadingSkeletons = false,
}: StatusSummaryChartsProps) => {
  const theme = useTheme()
  return (
    <Grid container item spacing={theme.spacing(2)} xs={12}>
      <StatusCard
        data={data}
        loadingSkeletons={loadingSkeletons}
        type="chargers"
        {...ChargerCardProps}
      />
      <StatusCard
        data={data}
        loadingSkeletons={loadingSkeletons}
        type="connectors"
        {...ConnectorCardProps}
      />
    </Grid>
  )
}

type StatusCardProps = StatusChartCardProps & {
  data: SummaryData
  type: StatusEntity
  loadingSkeletons?: boolean
}

const StatusCard = ({
  Actions,
  data,
  type,
  loadingSkeletons = false,
  title,
}: StatusCardProps) => {
  if (loadingSkeletons && data.isLoading) {
    return (
      <Grid item md={6} xs={12}>
        <Skeleton height="220px" variant="rectangular" width="100%" />
      </Grid>
    )
  }

  return (
    <Grid item md={6} xs={12}>
      <Card elevation={2} sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{title}</Typography>
            {Actions}
          </Stack>
          {data.isLoading ? (
            <Skeleton />
          ) : (
            <Chart.Donut
              centered
              data={data.summaryData[type]}
              diameter={170}
              donutFill={0.2}
              inactiveText={`No ${type} available`}
            />
          )}
        </Stack>
      </Card>
    </Grid>
  )
}
