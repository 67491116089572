import { DependencyList, EffectCallback, useEffect, useMemo } from 'react'
import { isObject, noop } from 'lodash'
import { usePrevious } from 'react-use'

type DependencyChange = {
  before: unknown
  after: unknown
}

type DebuggerOptions = {
  name?: string
  dependecyNames?: string[]
}

export function useEffectDebugger(
  effectCallback: EffectCallback,
  dependencies: DependencyList,
  options: DebuggerOptions | string = {}
) {
  useDependencyChangeDetector(dependencies, options)

  // This is complaining that the `effectCallback` isn't included...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, dependencies)
}

export function useMemoDebugger<T>(
  memoCallback: () => T,
  dependencies: DependencyList,
  options: DebuggerOptions | string = {}
) {
  useDependencyChangeDetector(dependencies, options)

  // This is complaining that the `memoCallback` isn't included...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(memoCallback, dependencies)
}

/**
 * Logs to the console when the given dependency array changes. Taken with modifications from:
 *   https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire
 */
function useDependencyChangeDetector(
  dependencies: DependencyList,
  options: DebuggerOptions | string = {}
) {
  const previousDeps = usePrevious(dependencies)
  const dependencyNames = (isObject(options) && options.dependecyNames) || []

  const changedDeps = dependencies.reduce<Record<string, DependencyChange>>(
    (acc, dependency, index) => {
      if (dependency === previousDeps?.[index]) return acc

      const keyName = dependencyNames[index] || index
      return {
        ...acc,
        [keyName]: {
          before: previousDeps?.[index],
          after: dependency,
        },
      }
    },
    {}
  )

  if (Object.keys(changedDeps).length) {
    const detectorName =
      typeof options === 'string'
        ? options
        : options.name ?? 'dependency-change-detector'

    console.log(`[${detectorName}]`, changedDeps)
  }
}

/** Provides methods for logging to the console. The methods are no-ops in production. */
type LogFnArgs = Parameters<typeof console.log>
export function useLogger(name: string) {
  return useMemo(() => {
    const isProd = process.env['NODE_ENV'] === 'production'
    const wrapLogFn =
      (logFn: (...args: LogFnArgs) => void) =>
      (...args: LogFnArgs) =>
        isProd ? noop() : logFn(`[${name}]`, ...args)

    return {
      log: wrapLogFn(console.log),
      info: wrapLogFn(console.info),
      warn: wrapLogFn(console.warn),
      error: wrapLogFn(console.error),
    }
  }, [name])
}
