import { Container, Grid, useTheme } from '@mui/material'
import { RootAPI } from '@synop-react/api'
import { TripDetailPageHeader } from '../trips/TripDetailPageHeader'
import { TripOverviewCask } from '../trips/TripOverviewCask'
import { TripStatusCask } from '../trips/TripStatusCask'
import TripMapTile from '../trips/TripMapTile/TripMapTile'
import TripNotesCask from '../trips/TripNotesCask/TripNotesCask'

export interface TripDetailsProps {
  tripDetails: RootAPI.Trip
  tripNotes: RootAPI.TripNote[]
  telematics: RootAPI.AssetTelematics[]
}

export function TripDetails({
  tripDetails,
  tripNotes,
  telematics,
}: TripDetailsProps) {
  const theme = useTheme()

  return (
    <Container>
      <Grid container rowSpacing={theme.spacing(4)}>
        <Grid item xs={12}>
          <TripDetailPageHeader tripDetails={tripDetails} />
        </Grid>
        <Grid container item spacing={theme.spacing(4)}>
          <Grid item sm={8} xs={12}>
            <TripStatusCask tripDetails={tripDetails} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TripMapTile telematics={telematics} />
          </Grid>
        </Grid>

        <Grid container item spacing={theme.spacing(4)}>
          <Grid item sm={8} xs={12}>
            <TripOverviewCask telematics={telematics} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TripNotesCask
              notes={tripNotes}
              tripId={tripDetails?.id as string}
            />
          </Grid>
        </Grid>
        <Grid />
      </Grid>
    </Container>
  )
}

export default TripDetails
