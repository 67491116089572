import { FormGroup } from '@mui/material'
import { MfaDisabledOverlay } from '../MfaDisabledOverlay'
import { MfaRequiredCheckbox } from '../MfaRequiredCheckbox'
import { MfaRequiredOverlay } from '../MfaRequiredOverlay'
import { MfaStatusSwitch } from '../MfaStatusSwitch'
import { OrgMfaStatus, RootAPI, useCurrentOrgId } from '@synop-react/api'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { useCallback, useEffect } from 'react'
import { useOverlay, useSnackbarControls } from '@synop-react/common'

const { useGetOrganizationQuery, useUpdateOrganizationMutation } = RootAPI

export const ManageOrgMfaStatus = () => {
  const [updateCurrentOrg, updateCurrentOrgResponse] =
    useUpdateOrganizationMutation()

  const currentOrgId = useCurrentOrgId()
  const { data: currentOrganization } = useGetOrganizationQuery(
    { id: currentOrgId },
    { skip: !currentOrgId }
  )

  const requireMfaOverlay = useOverlay()
  const disableMfaOverlay = useOverlay()

  const { openSnackbar } = useSnackbarControls()

  const updateMfaStatus = useCallback(
    (newMfaStatus: OrgMfaStatus) => {
      const mfaStatusInitialized = newMfaStatus !== null
      const hasUpdatedMfaStatus =
        newMfaStatus !== currentOrganization?.mfaStatus

      if (
        mfaStatusInitialized &&
        hasUpdatedMfaStatus &&
        updateCurrentOrgResponse.status !== QueryStatus.pending
      ) {
        updateCurrentOrg({
          organization: { ...currentOrganization, mfaStatus: newMfaStatus },
        })
      }
    },
    [currentOrganization, updateCurrentOrg, updateCurrentOrgResponse.status]
  )

  useEffect(() => {
    if (updateCurrentOrgResponse.status === QueryStatus.fulfilled) {
      openSnackbar('You have successfully updated MFA.', {
        title: 'MFA Update Succeeded.',
      })
    } else if (updateCurrentOrgResponse.status === QueryStatus.rejected) {
      openSnackbar('MFA update was not successful. ', {
        severity: 'error',
        title: 'MFA Update Failed.',
      })
    }
  }, [openSnackbar, updateCurrentOrgResponse.status])

  const { mfaStatus = 'OFF' } = currentOrganization ?? {}

  const isMfaRequired = mfaStatus === 'ON_REQUIRED'
  const isMfaEnabled = mfaStatus === 'ON_NOT_REQUIRED' || isMfaRequired

  return (
    <>
      <MfaRequiredOverlay
        onConfirm={() => {
          updateMfaStatus('ON_REQUIRED')
        }}
        orgId={currentOrgId}
        {...requireMfaOverlay}
      />
      <MfaDisabledOverlay
        onConfirm={() => updateMfaStatus('OFF')}
        {...disableMfaOverlay}
      />
      <FormGroup>
        <MfaStatusSwitch
          checked={isMfaEnabled}
          disabled={updateCurrentOrgResponse.status === QueryStatus.pending}
          onChange={(isChecked) =>
            isChecked
              ? updateMfaStatus('ON_NOT_REQUIRED')
              : disableMfaOverlay.openOverlay()
          }
        />
        <MfaRequiredCheckbox
          mfaStatus={mfaStatus}
          onChange={(isChecked) => {
            if (isChecked) requireMfaOverlay.openOverlay()
            else updateMfaStatus('ON_NOT_REQUIRED')
          }}
        />
      </FormGroup>
    </>
  )
}
