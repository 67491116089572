import { createSelector } from '@reduxjs/toolkit'
import { Dayjs } from 'dayjs'

import { selectEventsWithIdle } from './eventsWithIdle'
import { selectUserTimezone } from './prefs'
import { TimelineSelector } from '../../useTimeline'

type TimelineDisplayedDates = {
  displayedDate: Dayjs
  prevDisplayedDate: Dayjs
}

export const selectDisplayedDates: TimelineSelector<TimelineDisplayedDates> = ({
  displayedDate,
  prevDisplayedDate,
  tzDayjs,
}) => ({
  displayedDate: tzDayjs(displayedDate),
  prevDisplayedDate: tzDayjs(prevDisplayedDate),
})

export const selectEventDateRange: TimelineSelector<[Dayjs, Dayjs]> =
  createSelector(
    selectEventsWithIdle,
    selectUserTimezone,
    (scheduledEvents, tzDayjs) => {
      const defaultDates = [
        { scheduledStart: tzDayjs() },
        { scheduledEnd: tzDayjs() },
      ]
      const chargerEvents = Object.values(scheduledEvents)[0] ?? defaultDates
      const earliestDate = tzDayjs(chargerEvents[0]?.scheduledStart)
      const latestDate = tzDayjs(chargerEvents.at(-1)?.scheduledEnd)
      return [earliestDate, latestDate]
    }
  )

export const selectEventDates: TimelineSelector<Dayjs[]> = createSelector(
  selectEventDateRange,
  ([earliestDate, latestDate]) => {
    const eventDays = []
    for (let i = earliestDate; i.isBefore(latestDate); i = i.add(1, 'day')) {
      eventDays.push(i)
    }
    return eventDays
  }
)

export const selectIsCurrentDisplayedDay: TimelineSelector<boolean> =
  createSelector(
    selectDisplayedDates,
    selectUserTimezone,
    ({ displayedDate }, tzDayjs) => tzDayjs().isSame(displayedDate, 'day')
  )

export const selectHasPreviousDays: TimelineSelector<boolean> = createSelector(
  selectEventDateRange,
  selectDisplayedDates,
  ([earliestDate], { displayedDate }) =>
    displayedDate.isAfter(earliestDate) &&
    !displayedDate.isSame(earliestDate, 'day')
)

export const selectHasRemainingDays: TimelineSelector<boolean> = createSelector(
  selectEventDateRange,
  selectDisplayedDates,
  ([, latestDate], { displayedDate }) =>
    displayedDate.isBefore(latestDate) &&
    !displayedDate.isSame(latestDate, 'day')
)
