import {
  DASHBOARD_MAX_DATE_RANGE,
  emDash,
  formatEnergyUnitsAbbr,
  getFieldColumns,
  reduceTime,
  reduceWattHours,
  roundValueForFormatting,
  TableCask,
  useOrgSelector,
  useRouting,
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
  useTimeRange,
  useValidate,
} from '@synop-react/common'
import { isNumber } from 'lodash'
import { Link, Skeleton } from '@mui/material'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { useMemo } from 'react'
import { usePalette } from '@synop-react/theme'

const { useGetChargersTableDataQuery } = RootAPI.synopRootAPI

type SummaryRow = RootAPI.ChargersTimespanTableElementModel & {
  orgName: string
  siteName: string
  timezone?: string
  uptimeSecondsScaled?: number
}

const { tableColumns, csvColumns } = getFieldColumns<SummaryRow>(
  {
    csv: ['chargerName', 'charger_name'],
    column: {
      field: 'chargerName',
      headerName: 'Charger',
      minWidth: 230,
      flex: 1,
      tooltip: 'Charger name.',
      renderCell: ({ row }) => (
        <ChargerLink id={row.chargerId} name={row.chargerName} />
      ),
    },
  },
  { csv: ['orgName', 'organization'] },
  { csv: ['siteName', 'site'] },
  {
    csv: ['totalTransactions', 'total_transactions'],
    column: {
      field: 'totalTransactions',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Total Transactions',
      type: 'number',
      minWidth: 150,
      flex: 1,
      tooltip: 'The total number of charge transactions.',
    },
  },
  {
    csv: ['totalEnergyDispensedWh', 'total_energy_imported_wh'],
    column: {
      field: 'totalEnergyDispensedWh',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Total Energy Imported',
      type: 'number',
      minWidth: 180,
      flex: 1.5,
      tooltip: 'Sum of energy imported across transactions.',
      valueFormatter: (params) => {
        const energy = reduceWattHours(params.value)
        return `${energy.num} ${formatEnergyUnitsAbbr(energy.unit)}`
      },
    },
  },
  {
    csv: [
      'averageTransactionLength',
      'average_transaction_length_minutes',
      secondsToMinutes,
    ],
    column: {
      field: 'averageTransactionLength',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Avg. Transaction Duration',
      type: 'number',
      minWidth: 200,
      flex: 1.5,
      tooltip: 'Average duration of charge transactions.',
      valueFormatter: (params) => {
        const time = reduceTime(params.value)
        return `${time.value} ${time.suffix}`
      },
    },
  },
  {
    csv: ['totalFaults', 'total_faults'],
    column: {
      field: 'totalFaults',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Faults & Warnings',
      type: 'number',
      minWidth: 140,
      flex: 1.5,
      tooltip:
        'The number of faults and warnings associated with this charger for the given time period.',
      valueFormatter: ({ value }) => value ?? 0,
    },
  },
  {
    csv: ['uptimeSecondsScaled', 'uptime_minutes', secondsToMinutes],
    column: {
      field: 'uptimeSecondsScaled',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Avg. Uptime (per connector)',
      type: 'number',
      minWidth: 180,
      flex: 1.5,
      tooltip: 'Average uptime (per connector) for the selected date range.',
      valueFormatter: (params) => {
        if (!isNumber(params.value)) return emDash
        const time = reduceTime(params.value)
        return `${time.value} ${time.suffix}`
      },
    },
  },
  {
    csv: ['uptimePercentage', 'uptime_percentage'],
    column: {
      field: 'uptimePercentage',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Uptime (%)',
      type: 'number',
      minWidth: 80,
      flex: 0.75,
      tooltip:
        'Cumulative uptime percentage for the charger for the selected date range.',
      valueFormatter: ({ value }) => (isNumber(value) ? `${value}%` : emDash),
    },
  },
  { csv: ['timezone'] }
)

export const ChargerSummary = () => {
  const orgId = useSelectedOrgIdOrCurrent()
  const site = useSiteSelector().selected
  const { from, to } = useTimeRange()
  const { validateTimeRange } = useValidate()

  const isValidTimeRange = validateTimeRange(DASHBOARD_MAX_DATE_RANGE).isValid
  const { data, isLoading, isFetching } = useGetChargersTableDataQuery(
    {
      organizationId: orgId,
      siteIds: site ? [site.id] : undefined,
      fromTs: from.toISOString(),
      toTs: to.toISOString(),
    },
    {
      skip: !orgId || !isValidTimeRange,
    }
  )
  const { synopUser, isAdmin } = useCurrentUser()

  // Parse the data into the format expected by the table/CSV
  const orgLookup = useOrgSelector().lookup
  const siteLookup = useSiteSelector().lookup
  const parsed = useMemo<SummaryRow[]>(() => {
    return (data?.chargerSummary ?? [])
      .filter((row) => {
        if (
          data &&
          !isAdmin &&
          synopUser &&
          row.siteId &&
          synopUser.sites &&
          synopUser.sites.length > 0
        ) {
          return synopUser.sites.indexOf(row.siteId) > -1
        } else {
          return true
        }
      })
      .map((row) => {
        const { numConnectors, siteId, uptimeSeconds } = row
        const site = siteLookup(siteId)

        const scaledUptime = isNumber(uptimeSeconds)
          ? uptimeSeconds / Math.max(numConnectors, 1)
          : undefined

        return {
          orgName: orgLookup(site?.fleetId)?.organizationNm ?? '-',
          siteName: site?.depotNm ?? '-',
          timezone: site?.timezone,
          uptimeSecondsScaled: scaledUptime,
          ...row,
        }
      })
  }, [data, orgLookup, siteLookup, isAdmin, synopUser])

  const searchFilter: TableCask.EntitySearchFilter<SummaryRow> =
    (chargerSummaries, setVisibleSummaries) => (searchString) => {
      if (!searchString) {
        setVisibleSummaries(chargerSummaries)
        return
      }
      const formattedSearchString = searchString.toLowerCase()
      const matchingEntities = chargerSummaries.filter(
        ({ chargerName, chargerId }) => {
          return (
            chargerName?.toLowerCase().includes(formattedSearchString) ||
            chargerId?.toLowerCase().includes(formattedSearchString)
          )
        }
      )
      setVisibleSummaries(matchingEntities)
    }

  if (isLoading || isFetching)
    return <Skeleton height="700px" variant="rectangular" width="100%" />

  return (
    <TableCask.ClientSide
      columns={tableColumns}
      csvColumns={csvColumns}
      downloadable
      getRowId={(row) => row.chargerId}
      initialSortColumn="chargerName"
      searchable
      searchFilter={searchFilter}
      tableData={parsed}
      title="Charger Activity"
    />
  )
}

type ChargerLinkProps = {
  id: string
  name?: string
}

const ChargerLink = ({ id, name }: ChargerLinkProps) => {
  const { chargers } = useRouting().routes
  const { palette } = usePalette()
  return (
    <Link
      href={chargers.details(id)}
      sx={{
        color: palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
      }}
    >
      {name}
    </Link>
  )
}

function secondsToMinutes(seconds: unknown) {
  if (typeof seconds !== 'number') return null
  return roundValueForFormatting(seconds / 60)
}
