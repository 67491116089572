import { RootAPI } from '@synop-react/api'

const { synopRootAPI } = RootAPI

export const apiReducers = {
  [synopRootAPI.reducerPath]: synopRootAPI.reducer,
}

/* 
It is preferable to use the chainable .concat(...) and .prepend(...) methods 
of the returned MiddlewareArray instead of the array spread operator, as the 
latter can lose valuable type information under some circumstances.
*/

export const apiMiddleware = [synopRootAPI.middleware]
