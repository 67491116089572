import { EventOptions } from '../useTimeline/Provider'
import { UseEventOptionsProps } from '.'
import { usePalette } from '@synop-react/theme'

type UsePredictedEventProps = {
  depotId?: string
} & UseEventOptionsProps

const usePredictedEventOption = ({
  renderOverlay,
  subtitle,
}: UsePredictedEventProps): Pick<EventOptions, 'PREDICTED'> => {
  const { charting } = usePalette()

  return {
    PREDICTED: {
      barColor: charting[5].main,
      caption: {
        title: 'Predicted Event',
        titleDescription: subtitle,
      },
      tooltip: {
        title: 'Predicted Event',
        details: [],
      },
      renderOverlay,
    },
  }
}

export default usePredictedEventOption
