import {
  ChargingTransactionReportRow,
  useOrgSites,
  useOrgVehicles,
} from '@synop-react/api'
import {
  emDash,
  formatDurationTotal,
  formatEnergy,
  formatPower,
  formatSoC,
  ReportField,
  Table,
  useFormat,
} from '@synop-react/common'
import { Link, Typography } from '@mui/material'
import { useMemo } from 'react'
import dayjs from 'dayjs'

type ChargingTransactionReportField = ReportField<ChargingTransactionReportRow>

const formatCellString = (value?: string) => value ?? emDash
const formatCSVString = (value?: string) => value ?? ''

export const useChargingTransactionsReportFields = () => {
  const { orgSites } = useOrgSites()
  const { formatDateTime } = useFormat()

  const transactionId: ChargingTransactionReportField = {
    csv: ['id', 'transaction_id'],
    column: {
      field: 'id',
      headerName: 'Transaction ID',
      flex: 1,
      minWidth: 300,
      valueFormatter: ({ value }) => formatCellString(value),
    },
  }

  function transactionIdWithOverlayAction(
    onClick: (id: string) => void
  ): ChargingTransactionReportField {
    return {
      csv: [['id'], 'id'],
      column: {
        field: 'id',
        headerName: 'Transaction ID',
        flex: 1,
        minWidth: 300,
        valueFormatter: ({ value }) => formatCellString(value),
        renderCell: ({ row }) => {
          return (
            <Link
              href={`#`}
              onClick={(e) => {
                e.preventDefault()
                onClick(row.id)
              }}
              target="_blank"
              underline="none"
            >
              {row.id}
            </Link>
          )
        },
      },
    }
  }

  const siteName: ChargingTransactionReportField = {
    csv: [
      [
        'depotId',
        'site_name',
        (depotId: string) => formatCSVString(orgSites[depotId]?.depotNm),
      ],
      ['depotId', 'site_id', (depotId: string) => formatCSVString(depotId)],
    ],
    column: {
      field: 'depotId',
      headerName: 'Site',
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        const { depotId } = row
        const site = orgSites[depotId]
        return (
          <Link
            href={`/sites/${site?.depotId}`}
            target="_blank"
            underline="none"
          >
            {formatCellString(site?.depotNm)}
          </Link>
        )
      },
      valueGetter: (params) => orgSites[params.value]?.depotNm,
    },
  }

  const chargerName: ChargingTransactionReportField = {
    csv: [
      ['chargerName', 'charger_name'],
      ['chargerId', 'charger_id'],
    ],
    column: {
      field: 'chargerName',
      headerName: 'Charger',
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        const { chargerId, chargerName } = row
        return (
          <Link href={`/chargers/${chargerId}`} underline="none">
            {chargerName}
          </Link>
        )
      },
    },
  }

  const connectorId: ChargingTransactionReportField = {
    csv: ['connectorId', 'connector_id'],
    column: {
      field: 'connectorId',
      headerName: 'Connector',
      flex: 1,
      minWidth: 120,
      valueFormatter: ({ value }) => formatCellString(value),
    },
  }

  const ocppTag: ChargingTransactionReportField = {
    csv: ['ocppTag', 'auth_tag'],
    column: {
      field: 'ocppTag',
      headerName: 'Auth. Tag',
      flex: 1,
      minWidth: 180,
      valueFormatter: ({ value }) => formatCellString(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={
              'The authentication tag used to authorize this charge transaction.'
            }
          />
        )
      },
    },
  }

  const vin: ChargingTransactionReportField = {
    csv: ['vin', 'vin'],
    column: {
      field: 'vin',
      headerName: 'VIN',
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        return <VINCell vehicleOcppTag={row.ocppTag || ''} />
      },
      renderHeader: (params) => (
        <Table.HeaderCell
          title={params.colDef.headerName}
          tooltip={
            'VIN of the vehicle that was charged during this transaction. Note: this column will appear empty in cases where a vehicle does not have a mapped authentication tag. '
          }
        />
      ),
    },
  }

  const startTimestamp: ChargingTransactionReportField = {
    csv: [
      [
        'startTimestamp',
        'start_date',
        (value: string) => formatDateTime(value).dateDataExport,
      ],
      [
        'startTimestamp',
        'start_time',
        (value: string) => formatDateTime(value, { displaySeconds: true }).time,
      ],
    ],
    column: {
      field: 'startTimestamp',
      headerName: 'Start Date/Time',
      flex: 1,
      minWidth: 160,
      valueFormatter: ({ value }) => formatDateTime(value).dateDotTime,
      type: 'dateTime',
    },
  }

  const stopTimestamp: ChargingTransactionReportField = {
    csv: [
      [
        'stopTimestamp',
        'stop_date',
        (value: string) => formatDateTime(value).dateDataExport,
      ],
      [
        'stopTimestamp',
        'stop_time',
        (value: string) => formatDateTime(value, { displaySeconds: true }).time,
      ],
    ],
    column: {
      field: 'stopTimestamp',
      headerName: 'End Date/Time',
      flex: 1,
      minWidth: 180,
      valueFormatter: ({ value }) => formatDateTime(value).dateDotTime,
      type: 'dateTime',
    },
  }

  const duration: ChargingTransactionReportField = {
    csv: [
      [
        'durationSeconds',
        'duration_minutes',
        (value: string) =>
          isNaN(parseInt(value))
            ? null
            : Math.round((parseInt(value) / 60) * 100) / 100,
      ],
    ],
    column: {
      field: 'durationSeconds',
      headerName: 'Duration',
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) =>
        formatDurationTotal(dayjs.duration(row.durationSeconds, 'seconds')),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={'Length of the transaction.'}
          />
        )
      },
      valueGetter: ({ value }) => {
        const durationSeconds = value || 0
        return Math.round((parseInt(durationSeconds) / 60) * 100) / 100
      },
      type: 'number',
    },
  }

  const energyImported: ChargingTransactionReportField = {
    csv: [
      'totalEnergyImported',
      'total_energy_imported_kwh',
      (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value)),
    ],
    column: {
      field: 'totalEnergyImported',
      headerName: 'Energy Imported',
      flex: 1,
      minWidth: 190,
      renderCell: ({ value }) => formatEnergy(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={
              'Total energy added to the vehicle during this transaction.'
            }
          />
        )
      },
      type: 'number',
    },
  }

  const averagePower: ChargingTransactionReportField = {
    csv: [
      ['meanPowerImport'],
      'mean_power_import_kw',
      (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value)),
    ],
    column: {
      field: 'meanPowerImport',
      headerName: 'Avg. Power',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatPower(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={
              'The average power output for the duration of this charge transaction.'
            }
          />
        )
      },
      type: 'number',
    },
  }

  const maxPower: ChargingTransactionReportField = {
    csv: [
      ['maxPowerImport'],
      'max_power_import_kw',
      (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value)),
    ],
    column: {
      field: 'maxPowerImport',
      headerName: 'Max. Power',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatPower(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={
              'The maximum rate of power output for this charge transaction.'
            }
          />
        )
      },
      type: 'number',
    },
  }

  const startSoc: ChargingTransactionReportField = {
    csv: [
      'startingSoc',
      'starting_soc',
      (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value)),
    ],
    column: {
      field: 'startingSoc',
      headerName: 'Starting SoC',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatSoC(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={
              'Vehicle state of charge at the beginning of the transaction.'
            }
          />
        )
      },
      type: 'number',
    },
  }

  const endSoc: ChargingTransactionReportField = {
    csv: [
      ['latestSoc'],
      'latest_soc',
      (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value)),
    ],
    column: {
      field: 'latestSoc',
      headerName: 'Ending SoC',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatSoC(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip={'Vehicle state of charge at the end of the transaction.'}
          />
        )
      },
      type: 'number',
    },
  }

  const stopReason: ChargingTransactionReportField = {
    csv: ['stopReason', 'stop_reason'],
    column: {
      field: 'stopReason',
      headerName: 'Stop Reason',
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => formatCellString(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip="The reason for the end of the transaction."
          />
        )
      },
    },
  }

  const stopEventActor: ChargingTransactionReportField = {
    csv: ['stopEventActor', 'stop_event_actor'],
    column: {
      field: 'stopEventActor',
      headerName: 'Stop Actor',
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => formatCellString(value),
      renderHeader: (params) => {
        return (
          <Table.HeaderCell
            title={params.colDef.headerName}
            tooltip="The actor causing the end of the transaction"
          />
        )
      },
    },
  }

  return {
    transactionId,
    transactionIdWithOverlayAction,
    siteName,
    chargerName,
    connectorId,
    ocppTag,
    vin,
    startTimestamp,
    stopTimestamp,
    duration,
    energyImported,
    averagePower,
    maxPower,
    startSoc,
    endSoc,
    stopReason,
    stopEventActor,
  }
}

export type VINCellProps = {
  vehicleOcppTag: string
}

export const VINCell = ({ vehicleOcppTag }: VINCellProps) => {
  const { orgVehicles } = useOrgVehicles()

  const vehicleFound = useMemo(
    () =>
      Object.values(orgVehicles).find(
        (vehicle) => vehicle.macAddress === vehicleOcppTag
      ),
    [vehicleOcppTag, orgVehicles]
  )

  if (vehicleFound && orgVehicles) {
    return <Typography>{vehicleFound.vin}</Typography>
  } else if (orgVehicles) {
    return <Typography>-</Typography>
  } else {
    return <Typography>Loading</Typography>
  }
}
