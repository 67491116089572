import { Grid, InputLabel, Typography, useTheme } from '@mui/material'
import { Icons, useFormat } from '../../index'
import { RootAPI } from '@synop-react/api'

export type ChargerOcppConnectionDetailsProps = {
  charger: RootAPI.ChargerDetailModel
}

const ChargerOcppConnectionDetails = ({
  charger,
}: ChargerOcppConnectionDetailsProps) => {
  const theme = useTheme()
  const { formatDateTime } = useFormat()
  const lastHeard = charger.lastHeardTimestamp || charger.lastHeartbeatTimestamp

  return (
    <>
      <Icons.CheckCircle color={theme.palette.primary.main} size={22} />
      <Typography variant="subtitle2">Charger Connected</Typography>
      <Grid item>
        <InputLabel sx={{ fontSize: '12px' }}>Firmware</InputLabel>
        <Typography variant="body1">
          {charger.firmwareVersion || 'Unknown'}
        </Typography>
      </Grid>
      <Grid item>
        <InputLabel sx={{ fontSize: '12px' }}>OCPP Version</InputLabel>
        <Typography variant="body1">
          {charger.ocppProtocol || 'Unknown'}
        </Typography>
      </Grid>
      <Grid item>
        <InputLabel sx={{ fontSize: '12px' }}>Last Heard</InputLabel>
        <Typography variant="body1">
          {formatDateTime(lastHeard).atTimeOnDate}
        </Typography>
      </Grid>
    </>
  )
}

export default ChargerOcppConnectionDetails
