import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form'
import { InputProps, TextField, TextFieldProps } from '@mui/material'

export type TextFieldFormControls<FormData extends FieldValues> = {
  id: Path<FormData>
  control: Control<FormData, unknown>
  error?: FieldError
  touched?: boolean
  isSubmitted?: boolean
  helperText?: string
}

export type TextFormFieldProps<FormData extends FieldValues> =
  TextFieldFormControls<FormData> & {
    fullWidth?: boolean
    InputProps?: Partial<InputProps>
    rows?: number
  } & Omit<TextFieldProps, 'error' | 'value' | 'defaultValue'>

const TextFormField = function <FormData extends FieldValues>({
  control,
  error,
  id,
  isSubmitted = false,
  touched,
  fullWidth = false,
  variant = 'standard',
  InputProps = {},
  rows,
  helperText,
  ...textFieldProps
}: TextFormFieldProps<FormData>) {
  const shouldShowError = Boolean((isSubmitted || touched) && error?.message)
  const errorMessage = shouldShowError ? error?.message : undefined

  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { ref, value, ...rest } }) => (
        <TextField
          inputRef={ref} // needed to register form field in controller
          value={value ?? ''}
          {...rest}
          error={shouldShowError}
          fullWidth={fullWidth}
          helperText={errorMessage ?? helperText}
          id={id}
          InputProps={InputProps}
          rows={rows}
          variant={variant}
          {...textFieldProps}
        />
      )}
    />
  )
}

export default TextFormField
