import { Layer, LayerProps } from 'react-map-gl'
import { useTheme } from '@mui/material'

export interface MapMarkerLayerProps {
  /** A unique id for this layer */
  layerId: string
  /** The name of the asset passed into the Map that should be used for this marker */
  assetName: string
  /** The id for the Source layer that this layer should receive */
  sourceId: string
  /** An optional filter for selecting data from a Feature / FeatureCollection from the Source */
  filter?: unknown[]
  /** The min map zoom level that this layer will be displayed at */
  minZoom?: number
  /** The max map zoom level that this layer will be displayed at */
  maxZoom?: number
  /** The Mapbox expression for getting the text value label */
  labelExpression?: string | mapboxgl.StyleFunction | mapboxgl.Expression
  /**
   * Mapbox layout {@link https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-symbol-icon-color | icon-color}
   * The color of the icon. This can only be used with {@link https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-symbol-icon-color | SDF icons}
   */
  iconColor?: mapboxgl.SymbolPaint['icon-color']

  /**
   * Mapbox layout {@link https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layout-symbol-symbol-sort-key | symbol-sort-key}
   * Sorts features in ascending order based on this value. Features with lower sort keys are drawn and placed first.
   */
  symbolSortKey?: mapboxgl.SymbolLayout['symbol-sort-key']
}

export function MapMarkerLayer({
  layerId,
  sourceId,
  assetName,
  iconColor,
  labelExpression,
  minZoom,
  maxZoom,
  symbolSortKey,
  filter = ['!', ['has', 'point_count']],
}: MapMarkerLayerProps) {
  const { palette } = useTheme()
  const minzoom = minZoom !== undefined ? { minzoom: minZoom } : {}
  const maxzoom = maxZoom !== undefined ? { maxzoom: maxZoom } : {}

  const sortKey =
    symbolSortKey !== undefined ? { 'symbol-sort-key': symbolSortKey } : {}

  const labelProps = labelExpression
    ? {
        'text-field': labelExpression,
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
        'text-size': 15,
        'text-offset': [0, -0.2],
      }
    : {}

  const markerLayer: LayerProps = {
    id: layerId,
    type: 'symbol',
    source: sourceId,
    ...{ ...minzoom },
    ...{ ...maxzoom },
    ...{ filter },
    layout: {
      'icon-image': assetName,
      'icon-allow-overlap': true, // The icon will be visible even if it collides with other previously drawn symbols.
      'icon-ignore-placement': true, // Other symbols can be visible even if they collide with the icon.
      ...sortKey,
      'icon-size': 0.2,
    },
    paint: {
      'icon-color': iconColor,
      'icon-opacity': [
        // Hide the in map icon if it's selected to defer to the SelectedPin
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        0,
        1,
      ],
    },
  }

  const markerLabelLayer: LayerProps = {
    id: `${layerId}-label`,
    type: 'symbol',
    source: sourceId,
    ...{ filter },

    layout: {
      ...labelProps,
      ...sortKey,

      'text-optional': true, // Label will be hidden if it collides with other labels
    },
    paint: {
      'text-color': palette.common.white,
      'text-opacity': [
        // Hide the in map icon if it's selected to defer to the SelectedPin
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        0,
        1,
      ],
    },
  }

  return (
    <>
      <Layer {...markerLayer} />
      <Layer {...markerLabelLayer} />
    </>
  )
}

export default MapMarkerLayer
