import * as yup from 'yup'
import { Button, Grid, Typography } from '@mui/material'
import { FieldError, FormProvider, useForm } from 'react-hook-form'
import { RootAPI, useCurrentOrgId, useCurrentUser } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  createOverlay,
  FormField,
  Overlay,
  useOverlayContext,
  useSnackbarControls,
  WorkplacePayorAutocomplete,
} from '@synop-react/common'
import { isEmpty } from 'lodash'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useEffect } from 'react'

const { useSaveWorkplaceUserMutation } = RootAPI

export type CreateFromExistingWorkplacePayorForm = {
  user: RootAPI.UserModel
  rfidTags: string[]
  externalId: string
}

const createWorkplaceChargingPayor = yup
  .object({
    user: yup.object().nullable().required('User is required'),
    externalId: yup.string(),
  })
  .required()

export const CreatePayorFromExistingUsersOverlay = createOverlay(() => {
  const { closeOverlay } = useOverlayContext()
  const { openSnackbar } = useSnackbarControls()

  const orgId = useCurrentOrgId()
  const { synopUser } = useCurrentUser()

  const [configureWorkplaceUser, configureWorkplaceUserResponse] =
    useSaveWorkplaceUserMutation()

  const formMethods = useForm<CreateFromExistingWorkplacePayorForm>({
    mode: 'all',
    defaultValues: {
      rfidTags: [],
    },
    resolver: yupResolver(createWorkplaceChargingPayor),
  })

  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = formMethods

  const selectedUser = watch('user')

  useEffect(() => {
    if (
      configureWorkplaceUserResponse.status === QueryStatus.fulfilled ||
      configureWorkplaceUserResponse.status === QueryStatus.rejected
    ) {
      closeOverlay()
      if (configureWorkplaceUserResponse.status === QueryStatus.fulfilled) {
        openSnackbar('Workplace user has been created.')
      }
      reset()
    }
  }, [configureWorkplaceUserResponse, openSnackbar, closeOverlay, reset])

  useEffect(() => {
    if (selectedUser) {
      const rfidTagValues = selectedUser?.rfidTags
        ?.filter((tag) => tag.ocppTagUseCase === 'WORKPLACE_CHARGING')
        ?.map((tag) => tag?.ocppTagValue ?? '')
      setValue('rfidTags', rfidTagValues ?? [])
    }
  }, [selectedUser, setValue])

  const onSubmit = handleSubmit(({ user, externalId, rfidTags }) => {
    const userTags: RootAPI.UserTagModel[] = rfidTags.map((tag) => {
      const userTag = selectedUser?.rfidTags?.find(
        (t) =>
          t.ocppTagValue === tag && t.ocppTagUseCase === 'WORKPLACE_CHARGING'
      )
      if (userTag)
        return {
          ocppTagType: 'RFID',
          ocppTagValue: userTag.ocppTagValue,
          useCase: userTag.ocppTagUseCase,
          created: userTag.created,
          createdBy: synopUser?.id ?? '',
        }
      return {
        ocppTagType: 'RFID',
        ocppTagValue: tag,
        useCase: 'WORKPLACE_CHARGING',
        created: new Date().toISOString(),
        createdBy: synopUser?.id ?? '',
      }
    })

    configureWorkplaceUser({
      createWorkplaceChargingUser: {
        firstNm: user?.name?.split(' ')[0],
        lastNm: user?.name?.split(' ')[1],
        email: user.email,
        externalId,
        userId: user.id,
        organizationId: user.organizationId,
        ocppTags: userTags,
      },
    })
  })

  return (
    <Overlay>
      <Overlay.Header title="Select from Existing Users" />

      <Overlay.Content>
        <form onSubmit={onSubmit}>
          <FormProvider {...formMethods}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <WorkplacePayorAutocomplete.Select
                  control={control}
                  error={errors.user as FieldError}
                  id="user"
                  label="User"
                  orgId={orgId}
                  touchedField={Boolean(touchedFields.user)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField.TextFormField
                  control={control}
                  fullWidth
                  id="externalId"
                  label="External ID"
                  variant="standard"
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">Workplace RFID Tags</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormField.WrappedAutocompleteMultiselectFormField
                    freeSolo
                    id="rfidTags"
                    label="Choose an existing RFID tag"
                    loading={false}
                    options={[]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        </form>
      </Overlay.Content>

      <Overlay.Actions>
        <Button
          disabled={!isEmpty(errors)}
          onClick={onSubmit}
          variant="contained"
        >
          Add Payor
        </Button>
        <Button onClick={closeOverlay}>Cancel</Button>
      </Overlay.Actions>
    </Overlay>
  )
})
