import { EMPTY_ENTITY_MAP } from '../api/utils'
import { EntityMap } from '@synop-react/types'
import { parseToEntityMap } from '../api/utils'
import { RootAPI, useCurrentOrganization, useCurrentOrgId } from '..'
import { useMemo } from 'react'

const { useGetOrganizationCustomersQuery } = RootAPI.synopRootAPI

type UseOrgCustomersOptions = {
  includeParentOrg?: boolean
}

const defaultOptions: UseOrgCustomersOptions = {
  includeParentOrg: false,
}

export const useOrgCustomers = (options?: UseOrgCustomersOptions) => {
  const { includeParentOrg } = { ...defaultOptions, ...options }

  const currentOrgId = useCurrentOrgId()
  const { currentOrg } = useCurrentOrganization()

  const { data = [], ...queryResponse } = useGetOrganizationCustomersQuery(
    {
      id: currentOrgId,
    },
    {
      skip: !currentOrgId,
    }
  )

  const { entities: parsedCustomers } = useMemo(
    () =>
      queryResponse.isSuccess
        ? parseToEntityMap(data, 'id')
        : (EMPTY_ENTITY_MAP as EntityMap<RootAPI.OrganizationModel>),
    [data, queryResponse.isSuccess]
  )

  if (includeParentOrg) {
    parsedCustomers[currentOrgId] = currentOrg
  }

  return {
    customers: parsedCustomers,
    ...queryResponse,
  }
}
