import { Dayjs } from 'dayjs'
import { RootAPI, useWindowFocus } from '..'
import { useEffect, useState } from 'react'

const {
  useGetChargerQuery,
  useGetChargerUptimeQuery,
  useGetChargerUtilizationTimelineQuery,
  useGetChargingTransactionsQuery,
  useCmsTimespanGetHistoricalStatusesQuery,
} = RootAPI.synopRootAPI

const POLLING_INTERVAL_IN_SECONDS = 60

interface useChargerDetailsFromPollProps {
  chargerId: string
  from?: Dayjs
  to?: Dayjs
  disablePolling?: boolean
  pollingIntervalInSeconds?: number
}

export const useChargerDetailsFromPoll = ({
  chargerId,
  from,
  to,
  disablePolling = false,
  pollingIntervalInSeconds = POLLING_INTERVAL_IN_SECONDS,
}: useChargerDetailsFromPollProps) => {
  const isWindowFocused = useWindowFocus()
  const [pollingInterval, setPollingInterval] = useState(
    isWindowFocused ? pollingIntervalInSeconds * 1000 : 0
  )

  useEffect(() => {
    setPollingInterval(isWindowFocused ? pollingIntervalInSeconds * 1000 : 0)
  }, [isWindowFocused, pollingIntervalInSeconds])

  const validFrom = from?.isValid()
  const validTo = to?.isValid()
  const roundedFrom = (validFrom && from?.startOf('minute').toISOString()) || ''
  const roundedTo = (validTo && to?.startOf('minute').toISOString()) || ''
  const makeOptions = (requireTimeRange: boolean) => {
    return {
      pollingInterval: disablePolling ? 0 : pollingInterval,
      skip: requireTimeRange && (!validFrom || !validTo),
    }
  }

  const {
    data: getChargerData,
    isFetching: getChargerIsFetching,
    isLoading: getChargerIsLoading,
    isSuccess: getChargerIsSuccess,
    isError: getChargerIsError,
    refetch: refetchCharger,
  } = useGetChargerQuery({ chargerId }, makeOptions(false))

  const {
    data: getChargerUptimeData,
    isFetching: getChargerUptimeIsFetching,
    isLoading: getChargerUptimeIsLoading,
    isSuccess: getChargerUptimeIsSuccess,
    isError: getChargerUptimeIsError,
    refetch: refetchChargerUptime,
  } = useGetChargerUptimeQuery(
    { chargerId, from: roundedFrom, to: roundedTo },
    makeOptions(true)
  )

  const {
    data: getChargerUtilizationTimelineData,
    isFetching: getChargerUtilizationTimelineIsFetching,
    isLoading: getChargerUtilizationTimelineIsLoading,
    isSuccess: getChargerUtilizationTimelineIsSuccess,
    isError: getChargerUtilizationTimelineIsError,
    refetch: refetchChargerUtilizationTimeline,
  } = useGetChargerUtilizationTimelineQuery(
    { chargerId, from: roundedFrom, to: roundedTo },
    makeOptions(true)
  )

  const {
    data: getChargerTransactions,
    isFetching: isChargingTransactionsFetching,
    isLoading: isChargingTransacionsLoading,
    isSuccess: isChargingTransactionsSuccess,
    isError: isChargingTransactionsError,
    refetch: refetchChargerTransactions,
  } = useGetChargingTransactionsQuery(
    {
      chargerId,
      startTimeFrom: roundedFrom,
      startTimeTo: roundedTo,
      count: 1000,
    },
    makeOptions(false)
  )

  const {
    data: getTimespanHistoricalStatuses,
    isFetching: isTimespanHistoricalStatusesFetching,
    isLoading: isTimespanHistoricalStatusesLoading,
    isSuccess: isTimespanHistoricalStatusesSuccess,
    isError: isTimespanHistoricalStatusesError,
    refetch: refetchTimespanHistoricalStatuses,
  } = useCmsTimespanGetHistoricalStatusesQuery(
    {
      chargerIds: [chargerId],
      fromTs: roundedFrom,
      toTs: roundedTo,
    },
    makeOptions(true)
  )

  useEffect(() => {
    if (isWindowFocused) {
      if (getChargerData) refetchCharger()
      if (getChargerUptimeData) refetchChargerUptime()
      if (getChargerUtilizationTimelineData) refetchChargerUtilizationTimeline()
      if (getChargerTransactions) refetchChargerTransactions()
      if (getTimespanHistoricalStatuses) refetchTimespanHistoricalStatuses()
    }
    // Disable exhaustive-deps because while we only want to refetch
    // when there is already data to be updated, we don't want to refetch
    // every time the data itself changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWindowFocused])

  return {
    getCharger: {
      data: getChargerData,
      isFetching: getChargerIsFetching,
      isLoading: getChargerIsLoading,
      isSuccess: getChargerIsSuccess,
      isError: getChargerIsError,
    },
    getChargerUptime: {
      data: getChargerUptimeData,
      isFetching: getChargerUptimeIsFetching,
      isLoading: getChargerUptimeIsLoading,
      isSuccess: getChargerUptimeIsSuccess,
      isError: getChargerUptimeIsError,
    },
    getChargerUtilizationTimeline: {
      data: getChargerUtilizationTimelineData,
      isFetching: getChargerUtilizationTimelineIsFetching,
      isLoading: getChargerUtilizationTimelineIsLoading,
      isSuccess: getChargerUtilizationTimelineIsSuccess,
      isError: getChargerUtilizationTimelineIsError,
    },
    getChargerTransactions: {
      data: getChargerTransactions,
      isFetching: isChargingTransactionsFetching,
      isLoading: isChargingTransacionsLoading,
      isSuccess: isChargingTransactionsSuccess,
      isError: isChargingTransactionsError,
    },
    getTimespanHistoricalStatuses: {
      data: getTimespanHistoricalStatuses,
      isFetching: isTimespanHistoricalStatusesFetching,
      isLoading: isTimespanHistoricalStatusesLoading,
      isSuccess: isTimespanHistoricalStatusesSuccess,
      isError: isTimespanHistoricalStatusesError,
    },
  }
}
