import { Feature, featureCollection, point } from '@turf/helpers'

import {
  EntityCoordAccessor,
  FilterConfig,
  formatLocationFromSourceAttributes,
  getFloatFromSourceAttribute,
  MapAssets,
  MapAssetsProps,
  MapMarkerLayer,
  MapMarkerLayerProps,
} from '@synop-react/common'
import { useTheme } from '@mui/material'
import { VehicleModel } from '@synop-react/api'

export type VehicleSocMarkerProperties = {
  id: string
  soc?: number
  assetNm?: string
}
export type VehicleSocMarkerPoints = Feature<
  GeoJSON.Geometry,
  VehicleSocMarkerProperties
>[]

export const VEHICLE_MARKER_TYPE = 'VEHICLE_MARKER_TYPE'
export const getVehicleLocationCollection: EntityCoordAccessor<VehicleModel> = (
  vehicles
) => {
  const lastVehicleLocations: VehicleSocMarkerPoints = Object.values(vehicles)
    .sort(
      ({ stateOfCharge: socA = {} }, { stateOfCharge: socB = {} }) =>
        (socA?.value ?? 0) - (socB?.value ?? 0)
    )
    .flatMap(({ longitude, latitude, vehicleNm, stateOfCharge, id }) => {
      const location = formatLocationFromSourceAttributes(latitude, longitude)
      const soc = Math.floor(getFloatFromSourceAttribute(stateOfCharge))

      return location
        ? [
            point(
              location,
              {
                type: VEHICLE_MARKER_TYPE,
                id,
                vehicleNm,
                soc,
              },
              {
                id,
              }
            ),
          ]
        : []
    })
  return featureCollection(lastVehicleLocations)
}

type VehicleMapLayersProps = Pick<MapMarkerLayerProps, 'sourceId'> &
  Pick<MapAssetsProps, 'mapId'> & {
    layerFilters: FilterConfig
  }

export function VehicleMapSocLayers({
  mapId,
  sourceId,
  layerFilters,
}: VehicleMapLayersProps) {
  const { palette } = useTheme()

  const layerAssets = {
    chargePin: '/assets/images/map_pin_sdf.png',
  }

  const socColors = Object.values(layerFilters).reduce<unknown[]>(
    (acc, { filter, color }) => {
      acc.push(filter, color)
      return acc
    },
    []
  )

  const getSocLabel: mapboxgl.Expression = [
    'number-format',
    ['get', 'soc'],
    { 'min-fraction-digits': 0, 'max-fraction-digits': 0 },
  ]

  // Invert SoC so lower SoC will be rendered on top. TODO: Move this into specific layer
  const socSortOrder: mapboxgl.Expression = ['-', 100, ['get', 'soc']]

  const socIconColors: mapboxgl.Expression = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    palette.secondary.main,
    ...socColors,
    'red',
    // 'rgba(0, 0, 0, 0)',
  ]

  return (
    <MapAssets layerAssets={layerAssets} mapId={mapId}>
      <MapMarkerLayer
        assetName="chargePin"
        iconColor={socIconColors}
        labelExpression={getSocLabel}
        layerId="vehicles"
        sourceId={sourceId}
        symbolSortKey={socSortOrder}
      />
    </MapAssets>
  )
}
