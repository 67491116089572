import { CornerDownLeft, Info } from '../../Icons'
import { Fragment, ReactNode } from 'react'
import {
  Grid,
  Link as MuiLink,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'

export type DetailPageHeaderProps = {
  breadcrumbLink?: string
  breadcrumbTitle?: string
  title?: string | null
  subtitle?: string | string[]
  infoLink?: () => void
  isLoading?: boolean
  children?: ReactNode
  alignChildren?: 'flex-start' | 'flex-end' | 'center' | 'auto'
}

function DetailPageHeader({
  breadcrumbLink,
  breadcrumbTitle,
  title,
  subtitle,
  infoLink,
  isLoading = false,
  children,
  alignChildren = 'auto',
}: DetailPageHeaderProps) {
  // make sure subtitle is an array
  subtitle =
    subtitle && typeof subtitle === 'string'
      ? [subtitle]
      : (subtitle as string[])

  const defaultTitle = title || ''
  const hasActions = !!children
  return (
    <Grid alignItems="flex-start" container justifyContent="flex-start">
      {!isLoading ? (
        <>
          <BreadcrumbLink link={breadcrumbLink} title={breadcrumbTitle} />
          {/* If there are action elements to render, save half the width for them */}
          <Grid item xs={hasActions ? 6 : 12}>
            <Typography variant="h3">{defaultTitle}</Typography>
            {subtitle && (
              <Grid container>
                {subtitle.map((subtitleLine: string) => (
                  <Grid key={subtitleLine} item sx={{ lineHeight: 0 }} xs={12}>
                    <Typography
                      color="textSecondary"
                      lineHeight={2}
                      variant="overline"
                    >
                      {subtitleLine}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
            {infoLink && (
              <InfoContainer>
                <Info
                  onClick={infoLink}
                  sx={{
                    padding: 0.25,
                    width: 2,
                    height: 2,
                    color: 'text.secondary',
                  }}
                />
              </InfoContainer>
            )}
          </Grid>
          {hasActions && (
            <Grid
              alignSelf={alignChildren}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={6}
            >
              {children}
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Skeleton sx={{ height: '114px' }} />
        </Grid>
      )}
    </Grid>
  )
}

const BreadcrumbLink = ({ title, link }: { title?: string; link?: string }) => {
  return title && link ? (
    <Grid item xs={12}>
      <MuiLink href={link}>
        <CornerArrowContainer sx={{ pl: 1 }}>
          <CornerDownLeft sx={{ width: '20px', height: '20px' }} />
        </CornerArrowContainer>
        <Typography pl={1} sx={{ display: 'inline-block' }} variant="h6">
          {title}
        </Typography>
      </MuiLink>
    </Grid>
  ) : null
}

const CornerArrowContainer = styled('div')(() => ({
  display: 'inline-block',
  verticalAlign: 'middle',
}))

const InfoContainer = styled('div')(() => ({
  display: 'inline-block',
  verticalAlign: 'middle',
}))

export default DetailPageHeader
