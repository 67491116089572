import * as yup from 'yup'
import { Auth } from 'aws-amplify'
import { cloneElement, ReactElement, useState } from 'react'
import {
  FormField,
  Lock,
  OverlayDeprecated,
  useSnackbarControls,
} from '@synop-react/common'
import { FormHelperText, Grid, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

export interface ChangePasswordOverlayProps {
  defaultIsOpen?: boolean
  Trigger: ReactElement
}

const schema = yup
  .object({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup.string().required('New Password is required'),
  })
  .required()

type ChangePasswordFormData = {
  oldPassword: string
  newPassword: string
}

export function ChangePasswordOverlay({
  defaultIsOpen = false,
  Trigger,
}: ChangePasswordOverlayProps) {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const [errorMessage, setErrorMessage] = useState('')
  const { openSnackbar } = useSnackbarControls()

  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
    reset,
  } = useForm<ChangePasswordFormData>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async (formData: ChangePasswordFormData) => {
    setErrorMessage('')
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          formData.oldPassword,
          formData.newPassword
        )
      })
      .then((data) => {
        if (data === 'SUCCESS') {
          setIsOpen(false)
          openSnackbar('Successfully changed password.')
          reset({
            oldPassword: '',
            newPassword: '',
          })
        }
      })
      .catch((err) => {
        switch (err.code) {
          case 'LimitExceededException': {
            setErrorMessage(
              'You have attempted too many password change requests. Please try again later.'
            )
            break
          }
          case 'NotAuthorizedException': {
            setErrorMessage('The old password you entered is incorrect.')
            break
          }
          default: {
            // The invalid password error is clean enough to display directly, ie:
            // "Password did not conform with policy: Password not long enough"
            setErrorMessage(err.message)
            break
          }
        }
      })
  }

  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => {
      setIsOpen(true)
    },
  })

  return (
    <>
      {OverlayTrigger}
      <OverlayDeprecated
        isOpen={isOpen}
        OverlayActions={[
          <LoadingButton
            color="primary"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </LoadingButton>,
        ]}
        setIsOpen={setIsOpen}
        title={'Change Password'}
        TitleIcon={Lock}
      >
        <form>
          <Grid container direction="column" spacing={theme.spacing(2)}>
            <Grid container direction="row" item spacing={theme.spacing(1)}>
              <Grid item md={8}>
                <Typography
                  color={theme.palette.error.main}
                  variant="subtitle2"
                >
                  {errorMessage}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              item
              spacing={theme.spacing(2)}
            ></Grid>
            <Grid container direction="row" item spacing={theme.spacing(1)}>
              <Grid item md={8}>
                <Typography variant="body1">
                  Provide the following information to change your password.
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" item spacing={theme.spacing(1)}>
              <Grid item md={8}>
                <FormField.PasswordFormField
                  control={control}
                  error={errors.oldPassword}
                  fullWidth
                  id="oldPassword"
                  label="Old Password"
                  touched={Boolean(touchedFields.oldPassword)}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item spacing={theme.spacing(1)}>
              <Grid item md={8}>
                <FormField.PasswordFormField
                  control={control}
                  error={errors.newPassword}
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  touched={Boolean(touchedFields.newPassword)}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item spacing={theme.spacing(1)}>
              <Grid item md={8}>
                <FormHelperText>
                  Your password must be at least 8 characters, contain both
                  upper and lower case letters, have a number, and have a custom
                  character.
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </OverlayDeprecated>
    </>
  )
}

export default ChangePasswordOverlay
