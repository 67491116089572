import {
  AccountTreeOutlined,
  AdminPanelSettingsOutlined,
  ElectricCar,
  EvStationOutlined,
  LocalShippingOutlined,
  SettingsSuggestOutlined,
  TuneOutlined,
  WorkOutline,
} from '@mui/icons-material'
import {
  hasFeature,
  Icons,
  ListItem,
  Menu,
  NestedListItem,
  useRouting,
} from '@synop-react/common'
import {
  RootAPI,
  useCurrentOrganization,
  useCurrentUser,
} from '@synop-react/api'

type BillingStatus =
  | RootAPI.Organization['workplaceChargingStatus']
  | RootAPI.Organization['reimbursementsStatus']

export function SettingsMenu() {
  const routes = useRouting().routes.settings
  const { isAdmin } = useCurrentUser()
  const { currentOrg } = useCurrentOrganization()

  const hasHadBilling = (billingStatus: BillingStatus) =>
    billingStatus === 'ENABLED' || billingStatus === 'DISABLED'

  return (
    <Menu data-cy="settings-menu">
      <ListItem icon={<Icons.Settings />} linkTo={routes.root} text="General" />
      {hasFeature('customThemes') ? (
        <ListItem
          icon={<Icons.Sidebar />}
          linkTo={routes.themes}
          text="Themes"
        />
      ) : (
        <></>
      )}

      {isAdmin ? (
        <NestedListItem
          icon={<AdminPanelSettingsOutlined color={'primary'} />}
          text="Admin"
        >
          <ListItem
            icon={<SettingsSuggestOutlined color={'primary'} />}
            linkTo={routes.orgManagement}
            text="Settings"
          />
          <ListItem
            icon={<AccountTreeOutlined color={'primary'} />}
            linkTo={routes.orgs}
            text="Organizations"
          />
          <ListItem icon={<Icons.Users />} linkTo={routes.users} text="Users" />
        </NestedListItem>
      ) : (
        <></>
      )}
      <NestedListItem
        icon={<TuneOutlined color={'primary'} />}
        linkTo={undefined}
        text="Manage"
      >
        <ListItem
          icon={<Icons.Target />}
          linkTo={routes.locations}
          text="Locations of Interest"
        />
        <ListItem
          icon={<Icons.MapPinDefault />}
          linkTo={routes.sites}
          text="Sites"
        />
        <ListItem
          icon={<Icons.LifeBuoy />}
          linkTo={routes.fleets}
          text="Fleets"
        />
        <ListItem
          icon={<EvStationOutlined color={'primary'} />}
          linkTo={routes.chargers}
          text="Chargers"
        />
        <ListItem
          icon={<LocalShippingOutlined color={'primary'} />}
          linkTo={routes.vehicles}
          text="Vehicles"
        />
        {isAdmin ? (
          <ListItem
            icon={<Icons.Bell />}
            linkTo={routes.alerts}
            text="Alerts"
          />
        ) : (
          <></>
        )}
      </NestedListItem>
      {isAdmin &&
      (hasHadBilling(currentOrg?.reimbursementsStatus) ||
        hasHadBilling(currentOrg?.workplaceChargingStatus)) ? (
        <NestedListItem
          icon={<Icons.DollarSign />}
          linkTo={undefined}
          text="Billing"
        >
          {hasHadBilling(currentOrg?.reimbursementsStatus) ? (
            <ListItem
              icon={<ElectricCar color={'primary'} />}
              linkTo={routes.homeCharging}
              text="Home Charging"
            />
          ) : (
            <></>
          )}
          {hasHadBilling(currentOrg?.workplaceChargingStatus) ? (
            <ListItem
              icon={<WorkOutline color={'primary'} />}
              linkTo={routes.workplaceCharging}
              text="Workplace Charging"
            />
          ) : (
            <></>
          )}
        </NestedListItem>
      ) : (
        <></>
      )}
      {hasFeature('dataSources') ? (
        <ListItem
          icon={<Icons.Database />}
          linkTo={routes.dataSources}
          text="Data Sources"
        />
      ) : (
        <></>
      )}
      {hasFeature('apiKeys') ? (
        <ListItem
          icon={<Icons.Server />}
          linkTo={routes.apiKeys}
          text="API Keys"
        />
      ) : (
        <></>
      )}
    </Menu>
  )
}

export default SettingsMenu
