import { Grid, Switch, Typography, useTheme } from '@mui/material'

import { Cask, ImageTile, LoadingMessage } from '@synop-react/common'
import { RootAPI, useCurrentOrgId } from '@synop-react/api'

const { useGetIntegrationsByOrganizationIdQuery, useGetIntegrationsQuery } =
  RootAPI.synopRootAPI

export function DataSourceSettings() {
  const theme = useTheme()
  const orgId = useCurrentOrgId()
  const { data: dataSources, isLoading: isDataSourcesLoading } =
    useGetIntegrationsQuery()
  const { data: connectedDataSources, isLoading: isFleetDataSourcesLoading } =
    useGetIntegrationsByOrganizationIdQuery(
      {
        id: orgId,
      },
      {
        skip: !orgId,
      }
    )

  if (isDataSourcesLoading || isFleetDataSourcesLoading) {
    return <LoadingMessage />
  } else {
    return (
      <Cask title="Data Sources">
        <Typography variant="body1">
          Connect or disconnect your organization to the available data sources.
        </Typography>
        <Grid
          alignItems="flex-start"
          columnSpacing={theme.spacing(1)}
          container
          direction="row"
          justifyContent="space-between"
        >
          {dataSources &&
            connectedDataSources &&
            dataSources.map((ds) => {
              return (
                <Grid key={ds.integrationNm} item lg={4} md={4} sm={4} xs={12}>
                  <ImageTile
                    description="Provides real-time vehicle telematics and tracking."
                    imageUrl={ds.logoUrl || ''}
                  >
                    <Switch />
                  </ImageTile>
                </Grid>
              )
            })}
        </Grid>
      </Cask>
    )
  }
}

export default DataSourceSettings
