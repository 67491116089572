import {
  AdminFeature,
  DetailsCask,
  Edit2,
  emDash,
  formatDistance,
  formatEnergy,
} from '@synop-react/common'
import { CreateOrEditVehicleOverlay } from '../CreateOrEditVehicleOverlay'
import { IconButton } from '@mui/material'
import { RootAPI, useDepotDetailsFromPoll } from '@synop-react/api'

export interface VehicleDetailsCaskProps {
  vehicleId: string
}

export function VehicleDetailsCask({ vehicleId }: VehicleDetailsCaskProps) {
  const { data: vehicle, isLoading: isLoadingVehicle } =
    RootAPI.synopRootAPI.useGetVehicleQuery({
      id: vehicleId,
    })

  const {
    getDepot: { data: homeSite, isLoading: isLoadingSite },
  } = useDepotDetailsFromPoll({
    depotId: (vehicle?.homeSiteId as string) || '',
    disablePolling: true,
  })

  const {
    batterySize,
    estimatedRange,
    efficiency,
    macAddress,
    make,
    model,
    modelYear,
    vehicleNm,
    vin,
  } = vehicle || {}
  return (
    <DetailsCask
      EditOverlay={
        <AdminFeature>
          <CreateOrEditVehicleOverlay
            Trigger={
              <IconButton>
                <Edit2 />
              </IconButton>
            }
            vehicleId={vehicleId}
          />
        </AdminFeature>
      }
      isLoading={isLoadingSite || isLoadingVehicle}
      title={'Vehicle'}
    >
      <DetailsCask.Item label="Vehicle Name" value={vehicleNm} />
      <DetailsCask.Item copy={true} label="VIN" tooltip="Copy" value={vin} />
      <DetailsCask.Item label="Make" value={make} />
      <DetailsCask.Item
        info={true}
        label="OCPP Tag"
        tooltip="An OCPP tag is used as an identifier to either authorize a charging transaction or link a charging
        transaction to a vehicle. This tag may be provided by the charger during a transaction. You can then associate
        this tag with a vehicle by mapping the identifier here."
        value={macAddress}
      />
      <DetailsCask.Item label="Model" value={model} />
      <DetailsCask.Item
        label="Battery Capacity"
        value={formatEnergy(batterySize)}
      />
      <DetailsCask.Item label="Year" value={modelYear} />
      <DetailsCask.Item
        label="Estimated Range"
        value={formatDistance(estimatedRange)}
      />
      <DetailsCask.Item
        label="Home Site"
        value={(vehicle?.homeSiteId && homeSite?.depotNm) ?? emDash}
      />
      <DetailsCask.Item label="Vehicle Efficiency" value={efficiency} />
    </DetailsCask>
  )
}
export default VehicleDetailsCask
