import { Info } from '../Icons'
import { Tooltip, Typography } from '@mui/material'

type InfoTooltipProps = {
  title?: string
  description?: string
}

export const InfoTooltip = ({ title, description }: InfoTooltipProps) => {
  if (!title && !description) return null
  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <>
          <Typography variant="h6">{title}</Typography>
          {description}
        </>
      }
    >
      <Info size={16} sx={{ alignSelf: 'center' }} tabIndex={-1} />
    </Tooltip>
  )
}
