import { MouseEventHandler } from 'react'
import { styled, useTheme } from '@mui/material'
import { Text } from '@visx/text'
import { TickRendererProps } from '@visx/axis'
import { truncateSvgText } from '../../../utils/svg/truncateSvgText'

export type AxisLabelFormatter = (groupingId?: string) => {
  primary: string
  secondary?: string
  onClick?: MouseEventHandler<SVGTextElement>
}

type GroupedAxisLabelProps = {
  labelFormatter: AxisLabelFormatter
  maxWidth: number
} & TickRendererProps

export default function GroupedAxisLabel({
  x,
  y,
  formattedValue,
  labelFormatter,
  maxWidth,
}: GroupedAxisLabelProps) {
  const {
    primary,
    secondary,
    onClick: onLabelClick,
  } = labelFormatter(formattedValue)

  const mainLabelYOffset = secondary ? -25 : -12.5
  const secondaryYOffset = -5

  const truncatedPrimary = truncateSvgText(primary, maxWidth, { isBold: true })

  return (
    <>
      <MainAxisLabel
        dy={mainLabelYOffset}
        isLink={!!onLabelClick}
        onClick={onLabelClick}
        x={x}
        y={y}
      >
        {truncatedPrimary}
      </MainAxisLabel>
      {secondary && (
        <SecondaryAxisLabel dy={secondaryYOffset} x={x} y={y}>
          {secondary}
        </SecondaryAxisLabel>
      )}
    </>
  )
}

const MainAxisLabel = styled(Text, {
  shouldForwardProp: (prop: string) => !['isLink'].includes(prop),
})<{ isLink?: boolean }>(({ isLink = false }) => {
  const theme = useTheme()
  const captionProps = theme.typography.subtitle2
  return {
    ...captionProps,
    fill: isLink ? theme.palette.primary.main : theme.palette.text.primary,
    cursor: isLink ? 'pointer' : 'default',
  }
})

const SecondaryAxisLabel = styled(Text)(() => {
  const theme = useTheme()
  const captionProps = theme.typography.caption
  return {
    ...captionProps,
  }
})
