import { Checkbox, ListItemText, MenuItem, Stack } from '@mui/material'

import { ChargerStatuses, CurrentChargerStatus } from '@synop-react/api'
import { Dispatch, SetStateAction } from 'react'
import { Dropdown, TableSearch } from '@synop-react/common'

type TableActionsProps = SearchProps & StatusDropdownProps
type SearchProps = {
  setSearchString: Dispatch<SetStateAction<string | undefined>>
}
type StatusDropdownProps = {
  statuses: CurrentChargerStatus[]
  setStatuses(statuses: CurrentChargerStatus[]): void
}

export function TableActions({ setSearchString, ...props }: TableActionsProps) {
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <StatusDropdown {...props} />
      <TableSearch onChange={setSearchString} {...props} />
    </Stack>
  )
}

function StatusDropdown({ statuses, setStatuses }: StatusDropdownProps) {
  return (
    <Dropdown
      data-cy="chargers-status-dropdown"
      multiple
      onChange={(e) => setStatuses(e.target.value as CurrentChargerStatus[])}
      sx={{ maxWidth: 300, minWidth: 160 }}
      unselectedOption="Charger status"
      value={statuses}
    >
      {ChargerStatuses.map((status) => (
        <MenuItem key={status} value={status}>
          <Checkbox checked={statuses.includes(status)} />
          <ListItemText primary={status} />
        </MenuItem>
      ))}
    </Dropdown>
  )
}
