import { Group } from '@visx/group'
import { keyframes, styled } from '@mui/material'
import { Keyframes } from '@mui/styled-engine'
import { ReactNode } from 'react'
import { RectClipPath } from '@visx/clip-path'
import { uniqueId } from 'lodash'

import {
  selectChartArea,
  selectLeftAxisArea,
} from '../Timeline/useTimeline/useTimelineReducer/selectors'
import { useTimelineSelector } from '../Timeline/useTimeline/useTimeline'

export interface SlidingGroupProps {
  from: number
  to: number
  offset?: number
  children: ReactNode
}

export default function SlidingGroup({
  from,
  to,
  offset = 0,
  children,
}: SlidingGroupProps) {
  const chartArea = useTimelineSelector(selectChartArea)
  const { width: leftAxisOffset } = useTimelineSelector(selectLeftAxisArea)
  const getSlide = (from: number, to: number, offset: number) => {
    const parsedFrom = `-${from}`
    const parsedTo = `-${to}`

    return keyframes`
      from {
        transform: translate(${parsedFrom}px, ${offset}px);
      }

      to {
        transform: translate(${parsedTo}px, ${offset}px);
      }
    `
  }
  const id = `timelineClip-${uniqueId()}`

  return (
    <>
      <RectClipPath
        height={chartArea.height}
        id={id}
        width={chartArea.width}
        x={leftAxisOffset}
      />
      <Group clipPath={`url(#${id})`}>
        <AnimatedGroup slide={getSlide(from, to, offset)}>
          {children}
        </AnimatedGroup>
      </Group>
    </>
  )
}

const AnimatedGroup = styled(Group, {
  shouldForwardProp: (prop) => prop !== 'slide',
})<{ slide: Keyframes }>(({ slide }) => ({
  animation: `${slide} 1s ease forwards`,
}))
