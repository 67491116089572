import {
  DASHBOARD_MAX_DATE_RANGE,
  DashboardOverviewItem,
  DashboardOverviewTile,
  emDash,
  formatDistanceUnitsAbbr,
  formatEfficiencyUnitsAbbr,
  formatEmissionsUnitsAbbr,
  formatEnergyUnitsAbbr,
  formatFuelUnitsAbbr,
  useFleetSelector,
  useSelectedOrgIdOrCurrent,
  useTimeRange,
  useValidate,
} from '@synop-react/common'
import { RootAPI, VehicleMetrics } from '@synop-react/api'
import { Skeleton } from '@mui/material'

const { useGetMetricsForVehicles1Query } = RootAPI

export type VehicleDashboardOverviewProps = {}

export const VehicleDashboardOverviewTile = () => {
  const { from, to } = useTimeRange()
  const orgId = useSelectedOrgIdOrCurrent()
  const { selected: selectedFleet } = useFleetSelector()
  const { validateTimeRange } = useValidate()

  const isValidTimeRange = validateTimeRange(DASHBOARD_MAX_DATE_RANGE).isValid
  const {
    data: vehicleMetrics,
    isLoading,
    isFetching,
  } = useGetMetricsForVehicles1Query(
    {
      from: from.toISOString(),
      to: to.toISOString(),
      organizationId: orgId,
      fleetId: selectedFleet?.id || undefined,
    },
    {
      skip: !orgId || !isValidTimeRange,
    }
  )

  const {
    efficiency,
    energyUsed,
    carbonEmissionsSaved,
    distanceDriven,
    fuelSaved,
    numDrivingDays,
    numTrips,
    numVehicles,
  } = (vehicleMetrics || {}) as VehicleMetrics

  const maybeValue = (
    value: number | undefined | null,
    fn?: (value: number) => string | number
  ) => {
    if (value === null || value === undefined) return emDash
    return fn ? fn(value) : value
  }

  const formatNumber = (value: number) => Math.round(value).toLocaleString()

  const firstRow: DashboardOverviewItem[] = [
    { value: numVehicles, label: 'TOTAL VEHICLES' },
    {
      value: maybeValue(distanceDriven?.value, formatNumber),
      label: 'DISTANCE DRIVEN',
      units: distanceDriven?.units
        ? formatDistanceUnitsAbbr(distanceDriven.units)
        : '',
    },
    { value: maybeValue(numTrips, formatNumber), label: 'TOTAL TRIPS' },
    {
      value: maybeValue(numDrivingDays, formatNumber),
      label: 'TOTAL DRIVING DAYS',
    },
  ]
  const secondRow: DashboardOverviewItem[] = [
    {
      value: maybeValue(fuelSaved?.value, formatNumber),
      label: 'FUEL SAVED',
      units: fuelSaved?.units ? formatFuelUnitsAbbr(fuelSaved.units) : '',
    },
    {
      value: maybeValue(carbonEmissionsSaved?.value, formatNumber),
      label: 'CARBON SAVED',
      units: carbonEmissionsSaved?.units
        ? formatEmissionsUnitsAbbr(carbonEmissionsSaved.units)
        : '',
    },
    {
      value: maybeValue(energyUsed?.value, formatNumber),
      label: 'AVERAGE ENERGY USED',
      units: energyUsed?.units ? formatEnergyUnitsAbbr(energyUsed.units) : '',
    },
    {
      value: maybeValue(efficiency?.value, (efficiency: number) =>
        efficiency.toFixed(1)
      ),
      label: 'AVERAGE VEHICLE EFFICIENCY',
      units: efficiency?.units
        ? formatEfficiencyUnitsAbbr(efficiency.units)
        : '',
    },
  ]

  const vehicleItems: DashboardOverviewItem[][] = [firstRow, secondRow]

  const shouldBlock = isLoading || isFetching

  return shouldBlock ? (
    <Skeleton width="100%">
      <DashboardOverviewTile items={vehicleItems} />
    </Skeleton>
  ) : (
    <DashboardOverviewTile items={vehicleItems} />
  )
}

export default VehicleDashboardOverviewTile
