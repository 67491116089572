import { ChargerPanel } from '..'
import { ChargerRow } from './ChargerRow'
import { defaultEntityName, formatPower, useSort } from '@synop-react/common'
import {
  getDisplayedChargerStatus,
  numStatusesInUse,
  RootAPI,
  useDepotChargers,
  useDepotDetailsFromPoll,
  usePolling,
} from '@synop-react/api'
import { ListItem } from '@mui/material'

const { useGetLatestStatusSummaryQuery } = RootAPI

export type ChargersPanelProps = {
  depotId: string
  utilization?: RootAPI.UtilizationResponseModel
}

const ChargersPanel = ({ depotId, utilization }: ChargersPanelProps) => {
  const {
    getDepot: { data: depot },
    getDepotStatus: { data: depotStatus },
  } = useDepotDetailsFromPoll({ depotId, skipUtilization: true })

  const { data: latestStatuses } = usePolling(
    useGetLatestStatusSummaryQuery,
    {
      includeConnectorsByCharger: true,
      siteIds: [depotId],
    },
    { pollingIntervalSeconds: 60 }
  )

  const { isSuccess: isChargersSuccess, depotChargers } =
    useDepotChargers(depotId)

  const { totalPower = 0, powerUnit = 'kW' } = depotStatus ?? {}
  const { powerCeiling = 0 } = depot ?? {}
  const roundedPower = formatPower(totalPower, { omitUnits: true })
  const roundedCeiling = formatPower(powerCeiling, { omitUnits: true })

  const chargerStatuses = depotChargers.ids.map((chargerId) => {
    const charger = depotChargers.entities[chargerId]
    const { chargerName, maxPower } = charger ?? {}

    // Determine the number of connectors in use
    const connectorStatuses = latestStatuses?.connectorsByCharger?.[chargerId]

    return {
      depotId,
      chargerId,
      chargerName: chargerName ?? defaultEntityName('Charger', chargerId),
      numConnectors: connectorStatuses?.total ?? 0,
      numConnectorsInUse: numStatusesInUse(connectorStatuses),
      totalPower: utilization?.chargers?.[chargerId]?.livePowerImportKw,
      powerCeiling: maxPower ?? 0,
      powerUnit: 'kW',
      status: getDisplayedChargerStatus(charger),
      connectivityStatus: charger?.latestChargerStatus?.connectivityStatus,
      lastHeardTimestamp:
        charger?.lastHeardTimestamp ?? charger?.lastHeartbeatTimestamp,
    }
  })

  const sorted = useSort(chargerStatuses, 'chargerName')
  return (
    <>
      <ChargerPanel.Panel
        data={sorted}
        isSuccess={isChargersSuccess}
        rowContent={(index, chargerData) => {
          return (
            <ListItem key={`charger-row-${index}`} disableGutters>
              <ChargerRow {...chargerData} />
            </ListItem>
          )
        }}
        subtitle={`Using ${roundedPower} of ${roundedCeiling} ${powerUnit}`}
        title="Chargers"
      />
    </>
  )
}

export default ChargersPanel
