import {
  Entity,
  EntityIdMap,
  EntityMap,
  Id,
  IdObject,
} from '@synop-react/types'

export const EMPTY_ENTITY_MAP: EntityMap = { ids: [], entities: {} }

/*
  This will parse an array of entities and return an array of ids (defined by entityKey) and
  an entities object which is keyed off of entityKey for each entity. This is useful for subsequent
  selections of entities by key so you don't need to iterate over the entire array. This should only
  be used for arrays where the entityKey is UNIQUE so there isn't data that is lost.
*/

export const parseToEntityMap = <
  T extends Record<string, unknown>,
  R extends Entity<T> = Entity<T>
>(
  entitiesArr: T[],
  entityKey: keyof T
): EntityMap<R> => {
  const ids: Id[] = []
  const entities: EntityIdMap<R> = {}
  entitiesArr.filter(Boolean).forEach((entity) => {
    const id = entity[entityKey] as Id
    const isNewEntity = !entities[id]
    if (isNewEntity) {
      ids.push(id)
    }
    const entityWithId = Object.assign({}, entity, { id }) as R
    entities[id] = entityWithId
  })
  return { ids, entities }
}

/*
  This will parse an array of entities and return an array of ids (defined by entityKey) and
  an entities object which groups entities into an array which is keyed off of entityKey. This
  is useful for subsequent selections of entities by key (usually a FK) so you don't need to iterate
  over the entire array. This should be used for arrays where there are many entities for a given entityKey.
*/
export const parseToEntityMapList = <
  T extends Record<string, unknown>,
  R extends Entity<T> = Entity<T>
>(
  entitiesArr: T[],
  entityKey: keyof T
) => {
  const ids = [] as Id[]
  const entities = {} as Record<string, R[]>

  entitiesArr.forEach((entity) => {
    const id = entity[entityKey] as Id
    const isNewEntity = !entities[id]
    if (isNewEntity) {
      ids.push(id)
      entities[id] = []
    }
    entities[id]?.push(entity as unknown as R)
  })

  return { ids, entities }
}

export const parseParams = (params: Record<string, string>) => {
  const filteredParams = Object.keys(params).reduce((result, param) => {
    if (params[param]) result.push(`${param}=${params[param]}`)
    return result
  }, [] as string[])

  return filteredParams.length > 0 ? `?${filteredParams.join('&')}` : ''
}

export function mapEntities<T extends IdObject, U extends IdObject>(
  entities: EntityIdMap<T>,
  mapFn: (entity: T) => U
): EntityIdMap<U> {
  const mappedEntities: EntityIdMap<U> = {}

  Object.entries(entities).forEach(([id, entity]) => {
    mappedEntities[id] = mapFn(entity)
  })

  return mappedEntities
}
