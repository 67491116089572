import {
  chargerDotConnector,
  CreateChargingSessionOverlay,
  defaultEntityName,
  EditChargingSessionOverlay,
  EventOption,
  EventOptions,
  TimelineCask,
  useAvailableOption,
  useDisplayedDateWithTimeRange,
  usePredictedEventOption,
  useScheduledChargeOption,
  useUnscheduledChargeOption,
  ViewChargingSessionOverlay,
} from '@synop-react/common'
import {
  RootAPI,
  useDepotChargers,
  useDepotDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'

export interface VehicleTimelineProps {
  vehicleId: string
}

export function VehicleTimeline({ vehicleId }: VehicleTimelineProps) {
  const { tzDayjs } = useUserPrefs()
  const { data: vehicle, isLoading: isLoadingAsset } =
    RootAPI.useGetVehicleQuery({
      id: vehicleId,
    })

  const { displayedDate, setDisplayedDate, to, from } =
    useDisplayedDateWithTimeRange()

  const {
    getDepot: { data: depot, isLoading: isLoadingDepot },
    getScheduledChargerSessions: {
      parsedData: depotEvents,
      isLoading: isLoadingSessions,
    },
  } = useDepotDetailsFromPoll({
    depotId: vehicle?.homeSiteId || '',
    from,
    to,
    disablePolling: true,
  })

  const { depotChargers, isLoading: isLoadingChargers } = useDepotChargers(
    vehicle?.homeSiteId
  )

  const scheduledChargeOptions = useScheduledChargeOption({
    depotId: vehicle?.homeSiteId,
    subtitle: ({ chargerId, dispenserId }) => {
      const chargerName =
        depotChargers.entities[chargerId ?? '']?.chargerName ??
        defaultEntityName('Charger', chargerId)
      const connectorName = defaultEntityName('Connector', dispenserId)

      return chargerDotConnector(chargerName, connectorName)
    },
    renderOverlay: ({
      dispenserId,
      setIsOpen,
      isOpen,
      eventId,
      scheduledStart,
      scheduledEnd,
      chargerId = '',
      targetSoc,
      powerOffered,
    }) => {
      const eventHasEnded = tzDayjs().isAfter(scheduledEnd)
      const eventCharger = depotChargers.entities[chargerId]

      // Should never happen
      if (!eventCharger) return null

      return !eventHasEnded ? (
        <EditChargingSessionOverlay
          defaultCharger={eventCharger}
          defaultConnector={{ connectorId: Number(dispenserId) }}
          defaultDepot={depot}
          defaultScheduledEnd={scheduledEnd}
          defaultScheduledStart={scheduledStart}
          defaultSoc={targetSoc}
          defaultTargetPower={powerOffered}
          defaultVehicle={vehicle}
          eventId={eventId as string}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <ViewChargingSessionOverlay
          charger={eventCharger}
          eventId={eventId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          vehicle={vehicle as RootAPI.VehicleModel}
        />
      )
    },
  })
  const unscheduledChargeOption = useUnscheduledChargeOption({
    depotId: vehicle?.homeSiteId,
    subtitle: ({ chargerId, dispenserId }) => {
      const chargerName =
        depotChargers.entities[chargerId ?? '']?.chargerName ??
        defaultEntityName('Charger', chargerId)
      const connectorName = defaultEntityName('Connector', dispenserId)

      return chargerDotConnector(chargerName, connectorName)
    },
  })
  const availableChargeOption = useAvailableOption({
    renderOverlay: ({
      dispenserId,
      setIsOpen,
      isOpen,
      scheduledEnd,
      chargerId = '',
    }) => {
      return tzDayjs(scheduledEnd).isAfter(tzDayjs()) ? (
        <CreateChargingSessionOverlay
          defaultCharger={depotChargers.entities[chargerId]}
          defaultConnector={{ connectorId: Number(dispenserId) }}
          defaultDepot={depot}
          defaultVehicle={vehicle}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : null
    },
  })
  const predictedEventOption = usePredictedEventOption({
    subtitle: 'Predicted',
    renderOverlay: undefined,
  })

  const vehicleEventOptions: EventOptions = {
    ...scheduledChargeOptions,
    ...unscheduledChargeOption,
    ...availableChargeOption,
    ...predictedEventOption,
    SITE_LIMIT: {
      barColor: 'green',
      caption: { title: 'noop' },
      // tooltip: { title: 'noop', details: [{ data: 'hello' } ],
    } as EventOption,
  }

  const vehicleEvents = Object.values(depotEvents.entities).filter(
    ({ assetId }) => assetId === vehicleId
  )

  const isLoading =
    isLoadingAsset || isLoadingDepot || isLoadingChargers || isLoadingSessions

  return (
    <TimelineCask
      displayedDate={displayedDate}
      eventIds={[vehicleId]}
      eventOptions={vehicleEventOptions}
      groupingKey="assetId"
      isLoading={isLoading}
      scheduledEvents={vehicleEvents}
      setDisplayedDate={setDisplayedDate}
      title="Schedule"
    />
  )
}

export default VehicleTimeline
