import {
  Activity as FActivity,
  Airplay as FAirplay,
  AlertCircle as FAlertCircle,
  AlertOctagon as FAlertOctagon,
  AlertTriangle as FAlertTriangle,
  AlignCenter as FAlignCenter,
  AlignJustify as FAlignJustify,
  AlignLeft as FAlignLeft,
  AlignRight as FAlignRight,
  Anchor as FAnchor,
  Aperture as FAperture,
  Archive as FArchive,
  ArrowDown as FArrowDown,
  ArrowDownCircle as FArrowDownCircle,
  ArrowDownLeft as FArrowDownLeft,
  ArrowDownRight as FArrowDownRight,
  ArrowLeft as FArrowLeft,
  ArrowLeftCircle as FArrowLeftCircle,
  ArrowRight as FArrowRight,
  ArrowRightCircle as FArrowRightCircle,
  ArrowUp as FArrowUp,
  ArrowUpCircle as FArrowUpCircle,
  ArrowUpLeft as FArrowUpLeft,
  ArrowUpRight as FArrowUpRight,
  AtSign as FAtSign,
  Award as FAward,
  BarChart as FBarChart,
  BarChart2 as FBarChart2,
  Battery as FBattery,
  BatteryCharging as FBatteryCharging,
  Bell as FBell,
  BellOff as FBellOff,
  Bluetooth as FBluetooth,
  Bold as FBold,
  Book as FBook,
  Bookmark as FBookmark,
  BookOpen as FBookOpen,
  Box as FBox,
  Briefcase as FBriefcase,
  Calendar as FCalendar,
  Camera as FCamera,
  CameraOff as FCameraOff,
  Cast as FCast,
  Check as FCheck,
  CheckCircle as FCheckCircle,
  CheckSquare as FCheckSquare,
  ChevronDown as FChevronDown,
  ChevronLeft as FChevronLeft,
  ChevronRight as FChevronRight,
  ChevronsDown as FChevronsDown,
  ChevronsLeft as FChevronsLeft,
  ChevronsRight as FChevronsRight,
  ChevronsUp as FChevronsUp,
  ChevronUp as FChevronUp,
  Chrome as FChrome,
  Circle as FCircle,
  Clipboard as FClipboard,
  Clock as FClock,
  Cloud as FCloud,
  CloudDrizzle as FCloudDrizzle,
  CloudLightning as FCloudLightning,
  CloudOff as FCloudOff,
  CloudRain as FCloudRain,
  CloudSnow as FCloudSnow,
  Code as FCode,
  Codepen as FCodepen,
  Codesandbox as FCodesandbox,
  Coffee as FCoffee,
  Columns as FColumns,
  Command as FCommand,
  Compass as FCompass,
  Copy as FCopy,
  CornerDownLeft as FCornerDownLeft,
  CornerDownRight as FCornerDownRight,
  CornerLeftDown as FCornerLeftDown,
  CornerLeftUp as FCornerLeftUp,
  CornerRightDown as FCornerRightDown,
  CornerRightUp as FCornerRightUp,
  CornerUpLeft as FCornerUpLeft,
  CornerUpRight as FCornerUpRight,
  Cpu as FCpu,
  CreditCard as FCreditCard,
  Crop as FCrop,
  Crosshair as FCrosshair,
  Database as FDatabase,
  Delete as FDelete,
  Disc as FDisc,
  Divide as FDivide,
  DivideCircle as FDivideCircle,
  DivideSquare as FDivideSquare,
  DollarSign as FDollarSign,
  Download as FDownload,
  DownloadCloud as FDownloadCloud,
  Dribbble as FDribbble,
  Droplet as FDroplet,
  Edit as FEdit,
  Edit2 as FEdit2,
  Edit3 as FEdit3,
  ExternalLink as FExternalLink,
  Eye as FEye,
  EyeOff as FEyeOff,
  Facebook as FFacebook,
  FastForward as FFastForward,
  Feather as FFeather,
  Figma as FFigma,
  File as FFile,
  FileMinus as FFileMinus,
  FilePlus as FFilePlus,
  FileText as FFileText,
  Film as FFilm,
  Filter as FFilter,
  Flag as FFlag,
  Folder as FFolder,
  FolderMinus as FFolderMinus,
  FolderPlus as FFolderPlus,
  Framer as FFramer,
  Frown as FFrown,
  Gift as FGift,
  GitBranch as FGitBranch,
  GitCommit as FGitCommit,
  GitHub as FGitHub,
  Gitlab as FGitlab,
  GitMerge as FGitMerge,
  GitPullRequest as FGitPullRequest,
  Globe as FGlobe,
  Grid as FGrid,
  HardDrive as FHardDrive,
  Hash as FHash,
  Headphones as FHeadphones,
  Heart as FHeart,
  HelpCircle as FHelpCircle,
  Hexagon as FHexagon,
  Home as FHome,
  Image as FImage,
  Inbox as FInbox,
  Info as FInfo,
  Instagram as FInstagram,
  Italic as FItalic,
  Key as FKey,
  Layers as FLayers,
  Layout as FLayout,
  LifeBuoy as FLifeBuoy,
  Link as FLink,
  Link2 as FLink2,
  Linkedin as FLinkedin,
  List as FList,
  Loader as FLoader,
  Lock as FLock,
  LogIn as FLogIn,
  LogOut as FLogOut,
  Mail as FMail,
  Map as FMap,
  MapPin as FMapPin,
  Maximize as FMaximize,
  Maximize2 as FMaximize2,
  Meh as FMeh,
  Menu as FMenu,
  MessageCircle as FMessageCircle,
  MessageSquare as FMessageSquare,
  Mic as FMic,
  MicOff as FMicOff,
  Minimize as FMinimize,
  Minimize2 as FMinimize2,
  Minus as FMinus,
  MinusCircle as FMinusCircle,
  MinusSquare as FMinusSquare,
  Monitor as FMonitor,
  Moon as FMoon,
  MoreHorizontal as FMoreHorizontal,
  MoreVertical as FMoreVertical,
  MousePointer as FMousePointer,
  Move as FMove,
  Music as FMusic,
  Navigation as FNavigation,
  Navigation2 as FNavigation2,
  Octagon as FOctagon,
  Package as FPackage,
  Paperclip as FPaperclip,
  Pause as FPause,
  PauseCircle as FPauseCircle,
  PenTool as FPenTool,
  Percent as FPercent,
  Phone as FPhone,
  PhoneCall as FPhoneCall,
  PhoneForwarded as FPhoneForwarded,
  PhoneIncoming as FPhoneIncoming,
  PhoneMissed as FPhoneMissed,
  PhoneOff as FPhoneOff,
  PhoneOutgoing as FPhoneOutgoing,
  PieChart as FPieChart,
  Play as FPlay,
  PlayCircle as FPlayCircle,
  Plus as FPlus,
  PlusCircle as FPlusCircle,
  PlusSquare as FPlusSquare,
  Pocket as FPocket,
  Power as FPower,
  Printer as FPrinter,
  Radio as FRadio,
  RefreshCcw as FRefreshCcw,
  RefreshCw as FRefreshCw,
  Repeat as FRepeat,
  Rewind as FRewind,
  RotateCcw as FRotateCcw,
  RotateCw as FRotateCw,
  Rss as FRss,
  Save as FSave,
  Scissors as FScissors,
  Search as FSearch,
  Send as FSend,
  Server as FServer,
  Settings as FSettings,
  Share as FShare,
  Share2 as FShare2,
  Shield as FShield,
  ShieldOff as FShieldOff,
  ShoppingBag as FShoppingBag,
  ShoppingCart as FShoppingCart,
  Shuffle as FShuffle,
  Sidebar as FSidebar,
  SkipBack as FSkipBack,
  SkipForward as FSkipForward,
  Slack as FSlack,
  Slash as FSlash,
  Sliders as FSliders,
  Smartphone as FSmartphone,
  Smile as FSmile,
  Speaker as FSpeaker,
  Square as FSquare,
  Star as FStar,
  StopCircle as FStopCircle,
  Sun as FSun,
  Sunrise as FSunrise,
  Sunset as FSunset,
  Tablet as FTablet,
  Tag as FTag,
  Target as FTarget,
  Terminal as FTerminal,
  Thermometer as FThermometer,
  ThumbsDown as FThumbsDown,
  ThumbsUp as FThumbsUp,
  ToggleLeft as FToggleLeft,
  ToggleRight as FToggleRight,
  Tool as FTool,
  Trash as FTrash,
  Trash2 as FTrash2,
  Trello as FTrello,
  TrendingDown as FTrendingDown,
  TrendingUp as FTrendingUp,
  Triangle as FTriangle,
  Truck as FTruck,
  Tv as FTv,
  Twitch as FTwitch,
  Twitter as FTwitter,
  Type as FType,
  Umbrella as FUmbrella,
  Underline as FUnderline,
  Unlock as FUnlock,
  Upload as FUpload,
  UploadCloud as FUploadCloud,
  User as FUser,
  UserCheck as FUserCheck,
  UserMinus as FUserMinus,
  UserPlus as FUserPlus,
  Users as FUsers,
  UserX as FUserX,
  Video as FVideo,
  VideoOff as FVideoOff,
  Voicemail as FVoicemail,
  Volume as FVolume,
  Volume1 as FVolume1,
  Volume2 as FVolume2,
  VolumeX as FVolumeX,
  Watch as FWatch,
  Wifi as FWifi,
  WifiOff as FWifiOff,
  Wind as FWind,
  X as FX,
  XCircle as FXCircle,
  XOctagon as FXOctagon,
  XSquare as FXSquare,
  Youtube as FYoutube,
  Zap as FZap,
  ZapOff as FZapOff,
  ZoomIn as FZoomIn,
  ZoomOut as FZoomOut,
  Icon,
} from 'react-feather'
import { styled } from '@mui/material'

/**
 * Represent the component and props of both the mui-feather icon as well as MUI Theme props
 */
export type TIcon = typeof Activity

const StyledIcon = (icon: Icon) =>
  styled(icon)(({ theme }) => ({
    color: theme.palette['primary'].main,
    strokeWidth: 2,
  }))

export const Activity = StyledIcon(FActivity)
export const Airplay = StyledIcon(FAirplay)
export const AlertCircle = StyledIcon(FAlertCircle)
export const AlertOctagon = StyledIcon(FAlertOctagon)
export const AlertTriangle = StyledIcon(FAlertTriangle)
export const AlignCenter = StyledIcon(FAlignCenter)
export const AlignJustify = StyledIcon(FAlignJustify)
export const AlignLeft = StyledIcon(FAlignLeft)
export const AlignRight = StyledIcon(FAlignRight)
export const Anchor = StyledIcon(FAnchor)
export const Aperture = StyledIcon(FAperture)
export const Archive = StyledIcon(FArchive)
export const ArrowDownCircle = StyledIcon(FArrowDownCircle)
export const ArrowDownLeft = StyledIcon(FArrowDownLeft)
export const ArrowDownRight = StyledIcon(FArrowDownRight)
export const ArrowDown = StyledIcon(FArrowDown)
export const ArrowLeftCircle = StyledIcon(FArrowLeftCircle)
export const ArrowLeft = StyledIcon(FArrowLeft)
export const ArrowRightCircle = StyledIcon(FArrowRightCircle)
export const ArrowRight = StyledIcon(FArrowRight)
export const ArrowUpCircle = StyledIcon(FArrowUpCircle)
export const ArrowUpLeft = StyledIcon(FArrowUpLeft)
export const ArrowUpRight = StyledIcon(FArrowUpRight)
export const ArrowUp = StyledIcon(FArrowUp)
export const AtSign = StyledIcon(FAtSign)
export const Award = StyledIcon(FAward)
export const BarChart2 = StyledIcon(FBarChart2)
export const BarChart = StyledIcon(FBarChart)
export const BatteryCharging = StyledIcon(FBatteryCharging)
export const Battery = StyledIcon(FBattery)
export const BellOff = StyledIcon(FBellOff)
export const Bell = StyledIcon(FBell)
export const Bluetooth = StyledIcon(FBluetooth)
export const Bold = StyledIcon(FBold)
export const BookOpen = StyledIcon(FBookOpen)
export const Book = StyledIcon(FBook)
export const Bookmark = StyledIcon(FBookmark)
export const Box = StyledIcon(FBox)
export const Briefcase = StyledIcon(FBriefcase)
export const Calendar = StyledIcon(FCalendar)
export const CameraOff = StyledIcon(FCameraOff)
export const Camera = StyledIcon(FCamera)
export const Cast = StyledIcon(FCast)
export const CheckCircle = StyledIcon(FCheckCircle)
export const CheckSquare = StyledIcon(FCheckSquare)
export const Check = StyledIcon(FCheck)
export const ChevronDown = StyledIcon(FChevronDown)
export const ChevronLeft = StyledIcon(FChevronLeft)
export const ChevronRight = StyledIcon(FChevronRight)
export const ChevronUp = StyledIcon(FChevronUp)
export const ChevronsDown = StyledIcon(FChevronsDown)
export const ChevronsLeft = StyledIcon(FChevronsLeft)
export const ChevronsRight = StyledIcon(FChevronsRight)
export const ChevronsUp = StyledIcon(FChevronsUp)
export const Chrome = StyledIcon(FChrome)
export const Circle = StyledIcon(FCircle)
export const Clipboard = StyledIcon(FClipboard)
export const Clock = StyledIcon(FClock)
export const CloudDrizzle = StyledIcon(FCloudDrizzle)
export const CloudLightning = StyledIcon(FCloudLightning)
export const CloudOff = StyledIcon(FCloudOff)
export const CloudRain = StyledIcon(FCloudRain)
export const CloudSnow = StyledIcon(FCloudSnow)
export const Cloud = StyledIcon(FCloud)
export const Code = StyledIcon(FCode)
export const Codepen = StyledIcon(FCodepen)
export const Codesandbox = StyledIcon(FCodesandbox)
export const Coffee = StyledIcon(FCoffee)
export const Columns = StyledIcon(FColumns)
export const Command = StyledIcon(FCommand)
export const Compass = StyledIcon(FCompass)
export const Copy = StyledIcon(FCopy)
export const CornerDownLeft = StyledIcon(FCornerDownLeft)
export const CornerDownRight = StyledIcon(FCornerDownRight)
export const CornerLeftDown = StyledIcon(FCornerLeftDown)
export const CornerLeftUp = StyledIcon(FCornerLeftUp)
export const CornerRightDown = StyledIcon(FCornerRightDown)
export const CornerRightUp = StyledIcon(FCornerRightUp)
export const CornerUpLeft = StyledIcon(FCornerUpLeft)
export const CornerUpRight = StyledIcon(FCornerUpRight)
export const Cpu = StyledIcon(FCpu)
export const CreditCard = StyledIcon(FCreditCard)
export const Crop = StyledIcon(FCrop)
export const Crosshair = StyledIcon(FCrosshair)
export const Database = StyledIcon(FDatabase)
export const Delete = StyledIcon(FDelete)
export const Disc = StyledIcon(FDisc)
export const DivideCircle = StyledIcon(FDivideCircle)
export const DivideSquare = StyledIcon(FDivideSquare)
export const Divide = StyledIcon(FDivide)
export const DollarSign = StyledIcon(FDollarSign)
export const DownloadCloud = StyledIcon(FDownloadCloud)
export const Download = StyledIcon(FDownload)
export const Dribbble = StyledIcon(FDribbble)
export const Droplet = StyledIcon(FDroplet)
export const Edit2 = StyledIcon(FEdit2)
export const Edit3 = StyledIcon(FEdit3)
export const Edit = StyledIcon(FEdit)
export const ExternalLink = StyledIcon(FExternalLink)
export const EyeOff = StyledIcon(FEyeOff)
export const Eye = StyledIcon(FEye)
export const Facebook = StyledIcon(FFacebook)
export const FastForward = StyledIcon(FFastForward)
export const Feather = StyledIcon(FFeather)
export const Figma = StyledIcon(FFigma)
export const FileMinus = StyledIcon(FFileMinus)
export const FilePlus = StyledIcon(FFilePlus)
export const FileText = StyledIcon(FFileText)
export const File = StyledIcon(FFile)
export const Film = StyledIcon(FFilm)
export const Filter = StyledIcon(FFilter)
export const Flag = StyledIcon(FFlag)
export const FolderMinus = StyledIcon(FFolderMinus)
export const FolderPlus = StyledIcon(FFolderPlus)
export const Folder = StyledIcon(FFolder)
export const Framer = StyledIcon(FFramer)
export const Frown = StyledIcon(FFrown)
export const Gift = StyledIcon(FGift)
export const GitBranch = StyledIcon(FGitBranch)
export const GitCommit = StyledIcon(FGitCommit)
export const GitMerge = StyledIcon(FGitMerge)
export const GitPullRequest = StyledIcon(FGitPullRequest)
export const GitHub = StyledIcon(FGitHub)
export const Gitlab = StyledIcon(FGitlab)
export const Globe = StyledIcon(FGlobe)
export const Grid = StyledIcon(FGrid)
export const HardDrive = StyledIcon(FHardDrive)
export const Hash = StyledIcon(FHash)
export const Headphones = StyledIcon(FHeadphones)
export const Heart = StyledIcon(FHeart)
export const HelpCircle = StyledIcon(FHelpCircle)
export const Hexagon = StyledIcon(FHexagon)
export const Home = StyledIcon(FHome)
export const Image = StyledIcon(FImage)
export const Inbox = StyledIcon(FInbox)
export const Info = StyledIcon(FInfo)
export const Instagram = StyledIcon(FInstagram)
export const Italic = StyledIcon(FItalic)
export const Key = StyledIcon(FKey)
export const Layers = StyledIcon(FLayers)
export const Layout = StyledIcon(FLayout)
export const LifeBuoy = StyledIcon(FLifeBuoy)
export const Link2 = StyledIcon(FLink2)
export const Link = StyledIcon(FLink)
export const Linkedin = StyledIcon(FLinkedin)
export const List = StyledIcon(FList)
export const Loader = StyledIcon(FLoader)
export const Lock = StyledIcon(FLock)
export const LogIn = StyledIcon(FLogIn)
export const LogOut = StyledIcon(FLogOut)
export const Mail = StyledIcon(FMail)
export const MapPinDefault = StyledIcon(FMapPin)
export const Map = StyledIcon(FMap)
export const Maximize2 = StyledIcon(FMaximize2)
export const Maximize = StyledIcon(FMaximize)
export const Meh = StyledIcon(FMeh)
export const Menu = StyledIcon(FMenu)
export const MessageCircle = StyledIcon(FMessageCircle)
export const MessageSquare = StyledIcon(FMessageSquare)
export const MicOff = StyledIcon(FMicOff)
export const Mic = StyledIcon(FMic)
export const Minimize2 = StyledIcon(FMinimize2)
export const Minimize = StyledIcon(FMinimize)
export const MinusCircle = StyledIcon(FMinusCircle)
export const MinusSquare = StyledIcon(FMinusSquare)
export const Minus = StyledIcon(FMinus)
export const Monitor = StyledIcon(FMonitor)
export const Moon = StyledIcon(FMoon)
export const MoreHorizontal = StyledIcon(FMoreHorizontal)
export const MoreVertical = StyledIcon(FMoreVertical)
export const MousePointer = StyledIcon(FMousePointer)
export const Move = StyledIcon(FMove)
export const Music = StyledIcon(FMusic)
export const Navigation2 = StyledIcon(FNavigation2)
export const Navigation = StyledIcon(FNavigation)
export const Octagon = StyledIcon(FOctagon)
export const Package = StyledIcon(FPackage)
export const Paperclip = StyledIcon(FPaperclip)
export const PauseCircle = StyledIcon(FPauseCircle)
export const Pause = StyledIcon(FPause)
export const PenTool = StyledIcon(FPenTool)
export const Percent = StyledIcon(FPercent)
export const PhoneCall = StyledIcon(FPhoneCall)
export const PhoneForwarded = StyledIcon(FPhoneForwarded)
export const PhoneIncoming = StyledIcon(FPhoneIncoming)
export const PhoneMissed = StyledIcon(FPhoneMissed)
export const PhoneOff = StyledIcon(FPhoneOff)
export const PhoneOutgoing = StyledIcon(FPhoneOutgoing)
export const Phone = StyledIcon(FPhone)
export const PieChart = StyledIcon(FPieChart)
export const PlayCircle = StyledIcon(FPlayCircle)
export const Play = StyledIcon(FPlay)
export const PlusCircle = StyledIcon(FPlusCircle)
export const PlusSquare = StyledIcon(FPlusSquare)
export const Plus = StyledIcon(FPlus)
export const Pocket = StyledIcon(FPocket)
export const Power = StyledIcon(FPower)
export const Printer = StyledIcon(FPrinter)
export const Radio = StyledIcon(FRadio)
export const RefreshCcw = StyledIcon(FRefreshCcw)
export const RefreshCw = StyledIcon(FRefreshCw)
export const Repeat = StyledIcon(FRepeat)
export const Rewind = StyledIcon(FRewind)
export const RotateCcw = StyledIcon(FRotateCcw)
export const RotateCw = StyledIcon(FRotateCw)
export const Rss = StyledIcon(FRss)
export const Save = StyledIcon(FSave)
export const Scissors = StyledIcon(FScissors)
export const Search = StyledIcon(FSearch)
export const Send = StyledIcon(FSend)
export const Server = StyledIcon(FServer)
export const Settings = StyledIcon(FSettings)
export const Share2 = StyledIcon(FShare2)
export const Share = StyledIcon(FShare)
export const ShieldOff = StyledIcon(FShieldOff)
export const Shield = StyledIcon(FShield)
export const ShoppingBag = StyledIcon(FShoppingBag)
export const ShoppingCart = StyledIcon(FShoppingCart)
export const Shuffle = StyledIcon(FShuffle)
export const Sidebar = StyledIcon(FSidebar)
export const SkipBack = StyledIcon(FSkipBack)
export const SkipForward = StyledIcon(FSkipForward)
export const Slack = StyledIcon(FSlack)
export const Slash = StyledIcon(FSlash)
export const Sliders = StyledIcon(FSliders)
export const Smartphone = StyledIcon(FSmartphone)
export const Smile = StyledIcon(FSmile)
export const Speaker = StyledIcon(FSpeaker)
export const Square = StyledIcon(FSquare)
export const Star = StyledIcon(FStar)
export const StopCircle = StyledIcon(FStopCircle)
export const Sun = StyledIcon(FSun)
export const Sunrise = StyledIcon(FSunrise)
export const Sunset = StyledIcon(FSunset)
export const Tablet = StyledIcon(FTablet)
export const Tag = StyledIcon(FTag)
export const Target = StyledIcon(FTarget)
export const Terminal = StyledIcon(FTerminal)
export const Thermometer = StyledIcon(FThermometer)
export const ThumbsDown = StyledIcon(FThumbsDown)
export const ThumbsUp = StyledIcon(FThumbsUp)
export const ToggleLeft = StyledIcon(FToggleLeft)
export const ToggleRight = StyledIcon(FToggleRight)
export const Tool = StyledIcon(FTool)
export const Trash2 = StyledIcon(FTrash2)
export const Trash = StyledIcon(FTrash)
export const Trello = StyledIcon(FTrello)
export const TrendingDown = StyledIcon(FTrendingDown)
export const TrendingUp = StyledIcon(FTrendingUp)
export const Triangle = StyledIcon(FTriangle)
export const Truck = StyledIcon(FTruck)
export const Tv = StyledIcon(FTv)
export const Twitch = StyledIcon(FTwitch)
export const Twitter = StyledIcon(FTwitter)
export const Type = StyledIcon(FType)
export const Umbrella = StyledIcon(FUmbrella)
export const Underline = StyledIcon(FUnderline)
export const Unlock = StyledIcon(FUnlock)
export const UploadCloud = StyledIcon(FUploadCloud)
export const Upload = StyledIcon(FUpload)
export const UserCheck = StyledIcon(FUserCheck)
export const UserMinus = StyledIcon(FUserMinus)
export const UserPlus = StyledIcon(FUserPlus)
export const UserX = StyledIcon(FUserX)
export const User = StyledIcon(FUser)
export const Users = StyledIcon(FUsers)
export const VideoOff = StyledIcon(FVideoOff)
export const Video = StyledIcon(FVideo)
export const Voicemail = StyledIcon(FVoicemail)
export const Volume1 = StyledIcon(FVolume1)
export const Volume2 = StyledIcon(FVolume2)
export const VolumeX = StyledIcon(FVolumeX)
export const Volume = StyledIcon(FVolume)
export const Watch = StyledIcon(FWatch)
export const WifiOff = StyledIcon(FWifiOff)
export const Wifi = StyledIcon(FWifi)
export const Wind = StyledIcon(FWind)
export const XCircle = StyledIcon(FXCircle)
export const XOctagon = StyledIcon(FXOctagon)
export const XSquare = StyledIcon(FXSquare)
export const X = StyledIcon(FX)
export const Youtube = StyledIcon(FYoutube)
export const ZapOff = StyledIcon(FZapOff)
export const Zap = StyledIcon(FZap)
export const ZoomIn = StyledIcon(FZoomIn)
export const ZoomOut = StyledIcon(FZoomOut)
