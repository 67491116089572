import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from '@mui/material'
import { Icon } from 'react-feather'
import LoadingMessage from '../LoadingMessage'
import OverlayHeader from './Header'
import React, { ReactElement, ReactNode } from 'react'

export type OverlayDeprecatedProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => void
  subtitle?: string
  title: string
  TitleIcon?: Icon
  titlePill?: React.ReactNode
  OverlayActions?: ReactElement[]
  overlayPixelWidth?: number
  children: ReactNode
  isLoading?: boolean
} & Omit<DialogProps, 'open' | 'onClose'>

export function OverlayDeprecated({
  isOpen = false,
  setIsOpen,
  onClose = () => ({}),
  subtitle,
  title,
  TitleIcon,
  titlePill,
  OverlayActions = [],
  children,
  isLoading = false,
  overlayPixelWidth = 760,
  ...dialogProps
}: OverlayDeprecatedProps) {
  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }

  // Adds `key` props to each child
  const actionsWithKeys = React.Children.map(OverlayActions, (child) => child)
  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      {...dialogProps}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root[role="dialog"]': {
            minWidth: `${overlayPixelWidth}px`,
            width: '100%',
          },
        },
      }}
    >
      <OverlayHeader
        handleClose={handleClose}
        Icon={TitleIcon}
        pill={titlePill}
        title={title}
      />
      <DialogContent>
        <Stack spacing={2}>
          {subtitle && (
            <Box>
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ alignItems: 'center' }}
              >
                <Typography variant="body1">{subtitle}</Typography>
              </Stack>
            </Box>
          )}
          {isLoading ? (
            <Box height={100}>
              <LoadingMessage height="100px" />
            </Box>
          ) : (
            children
          )}
        </Stack>
      </DialogContent>
      <DialogActions>{actionsWithKeys}</DialogActions>
    </Dialog>
  )
}
