import { Grid, Switch, Typography } from '@mui/material'

import { DetailPageHeader, Table, TableCask } from '@synop-react/common'
import { EditNotificationOverlay } from '../EditNotificationOverlay'
import { RootAPI, useCurrentOrgId } from '@synop-react/api'

const { useGetAlarmsForFleetQuery, useUpdateAlarmMutation } = RootAPI

export type DefaultNotification = {
  id: string
  details: string
  notification: string
  isActive: boolean
}

//Will remove once endpoints are ingested
export type Alarm = {
  id: string
  alarmNm: string
  alarmDesc: string
  alarmMessage: string
}

export type AlarmNotification = {
  created: string
  updated: string
  logicalDeleteIn: boolean
  alarmId: string
  notificationType: string
  notificationValue: string
  active: boolean
}

const EditNotificationsPage = () => {
  const currentOrgId = useCurrentOrgId()
  const { data: alarms = [], isLoading: isLoadingAlarms } =
    useGetAlarmsForFleetQuery(
      {
        organizationId: currentOrgId,
      },
      { skip: !currentOrgId }
    )

  const defaultNotifColumns: Table.ColumnSpec<RootAPI.AlarmModel> = [
    {
      field: 'isActive',
      headerName: '',
      renderCell: (params) => (
        <NotificationEnabledSwitch alarmId={params.row.id} />
      ),
      width: 100,
    },
    {
      field: 'alarmNm',
      headerName: 'Notification',
      width: 275,
      sortable: true,
      renderCell: (params) => {
        const alarm = params.row
        return (
          <EditNotificationOverlay
            alarm={alarm}
            Trigger={
              <Typography color="primary" sx={{ cursor: 'pointer' }}>
                {alarm.alarmNm}
              </Typography>
            }
          />
        )
      },
    },
    { field: 'alarmDesc', headerName: 'Details', width: 450 },
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DetailPageHeader
          breadcrumbLink="/notifications"
          breadcrumbTitle="Notifications"
          title="Edit Notifications"
        />
      </Grid>

      <Grid item xs={12}>
        <TableCask.ClientSide
          columns={defaultNotifColumns}
          getRowId={(row) => row.id}
          isLoading={isLoadingAlarms}
          tableData={alarms}
          title=""
        />
      </Grid>
    </Grid>
  )
}

export default EditNotificationsPage

type NotificationEnabledSwitchProps = {
  alarmId: string
}

const NotificationEnabledSwitch = ({
  alarmId,
}: NotificationEnabledSwitchProps) => {
  const currentOrgId = useCurrentOrgId()

  const { data: alarms = [] } = useGetAlarmsForFleetQuery({
    organizationId: currentOrgId,
  })
  const [updateAlarm] = useUpdateAlarmMutation()

  const alarm =
    alarms.find(({ id }) => id === alarmId) ||
    ({ isEnabled: false } as RootAPI.AlarmModel)

  const toggleNotificationAlarm = () => {
    if (!alarm.id) return
    updateAlarm({
      id: alarm.id,
      alarmModel: Object.assign({}, alarm, { isEnabled: !alarm.isEnabled }),
    })
  }

  return (
    <Switch
      defaultChecked={alarm.isEnabled}
      onChange={toggleNotificationAlarm}
    />
  )
}
