import { DistanceUnit } from '@synop-react/api'
import { emDash } from './constants'
import { formatNumber } from './number'
import { formatUnits, formatUnitsAbbr, UnitsFormat } from './units'

const distanceFormats: UnitsFormat<DistanceUnit> = {
  mi: {
    long: 'Miles',
    short: 'mi',
  },
  km: {
    long: 'Kilometers',
    short: 'km',
  },
  m: {
    long: 'Meters',
    short: 'm',
  },
}

export const formatDistanceUnits = formatUnits(distanceFormats)
export const formatDistanceUnitsAbbr = formatUnitsAbbr(distanceFormats)

export type DistanceOptions = {
  longForm: boolean
  sigFigs: number
}
const defaultDistanceOptions: DistanceOptions = {
  longForm: false,
  sigFigs: 2,
}

export const formatDistance = (
  distance?: number | string,
  distanceUnits: DistanceUnit = 'mi',
  options: Partial<DistanceOptions> = defaultDistanceOptions
) => {
  const { longForm, sigFigs } = { ...defaultDistanceOptions, ...options }
  const units = longForm
    ? formatDistanceUnits(distanceUnits)
    : formatDistanceUnitsAbbr(distanceUnits)
  if (typeof distance !== 'string' && typeof distance !== 'number')
    return emDash

  const distanceValue =
    sigFigs > 0
      ? parseFloat(`${distance}`).toFixed(sigFigs)
      : parseInt(`${distance}`)

  return formatNumber(distanceValue, { suffix: units })
}
