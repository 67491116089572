import { Crate } from '../Crate'
import { Info } from '../Icons'
import { ReactNode } from 'react'
import { Stack, SxProps, Typography } from '@mui/material'

export type NoDataVariant = 'chart' | 'container'
export type NoDataVariantProps = {
  children?: ReactNode
  message?: string
  sx?: SxProps
  Icon?: ReactNode
}
export type NoDataProps = {
  variant?: NoDataVariant
} & NoDataVariantProps

export const NoData = ({
  message = 'No data found',
  variant = 'chart',
  ...props
}: NoDataProps) => {
  return variant === 'chart' ? (
    <NoChartData {...{ message, ...props }} />
  ) : (
    <NoContainerData {...{ message, ...props }} />
  )
}

export default NoData

const NoChartData = ({ message = 'No data found', sx }: NoDataVariantProps) => {
  return (
    <Stack
      alignItems={'center'}
      direction="column"
      display="flex"
      justifyContent="space-around"
      spacing={2}
      sx={{ height: '100%' }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
        variant="overline"
      >
        {message}
      </Typography>
    </Stack>
  )
}

const NoContainerData = ({
  message = 'No data found',
  Icon = <Info />,
}: NoDataVariantProps) => {
  return <Crate description={message} {...{ Icon }} />
}
