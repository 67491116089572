import { Typography } from '@mui/material'
import { useMemo } from 'react'

import { ChargerTableColDef, EntityProp } from '../common'
import { formatPower } from '@synop-react/common'

function Utilization({ entity }: EntityProp) {
  const { liveUtilization, isCharger } = entity
  const powerText = useMemo(() => {
    if (isCharger) {
      const opts = { omitUnits: true }
      const formattedUtilization = formatPower(liveUtilization ?? 0, opts)
      const formattedMaxPower = formatPower(entity.maxPower, opts)
      return `${formattedUtilization} / ${formattedMaxPower} kW`
    } else {
      return formatPower(liveUtilization)
    }
  }, [isCharger, liveUtilization, entity])

  return (
    <Typography fontWeight={600} variant="body2">
      {powerText}
    </Typography>
  )
}

export default {
  align: 'left',
  field: 'liveUtilization',
  headerAlign: 'left',
  headerName: 'Live Utilization / Max',
  width: 180,
  renderCell: ({ row }) => <Utilization entity={row} />,
  type: 'number',
} as ChargerTableColDef<'liveUtilization'>
