import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  FormField,
} from '../../index'
import { Control, FieldValues, Path } from 'react-hook-form'
import { useState } from 'react'

export type RoleAutocompleteSelectProps<FormData extends FieldValues> = {
  id: Path<FormData>
  label: string
  control: Control<FormData, unknown>
  isAdmin?: boolean
} & AutocompleteFormControls<FormData, RoleOption> &
  AutocompleteTextFieldProps

export type RoleOption = { id: number | string; name: string }

export const RoleAutocompleteSelect = <FormData extends FieldValues>({
  id,
  label,
  control,
  isAdmin = false,
}: RoleAutocompleteSelectProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)
  const roleOptions: RoleOption[] = [
    { id: 2, name: 'Fleet Operator' },
    { id: 3, name: 'Driver' },
  ]
  if (isAdmin) roleOptions.unshift({ id: 1, name: 'Admin' })

  return (
    <FormField.AutocompleteSelect<FormData, RoleOption>
      control={control}
      getOptionLabel={(option: RoleOption) => `${option.name}`}
      id={id}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: RoleOption) => option.id}
      label={label}
      options={roleOptions}
      setIsOpen={setIsOpen}
    />
  )
}

export { RoleAutocompleteSelect as Select }
