import {
  emDash,
  getFormattedValueFromSourceAttribute,
  ProgressPillColor,
  SourceAttributeFormatter,
} from '@synop-react/common'
import {
  VehicleStatus,
  vehicleStatuses,
  VehicleStatusSourceAttribute,
} from '@synop-react/api'

type StatusOptions = Record<
  VehicleStatus | 'Default',
  {
    label: string
    color: ProgressPillColor
  }
>

const statusOptions: StatusOptions = {
  'In Motion': {
    label: 'In Motion',
    color: 'secondary',
  },
  Idle: {
    label: 'Idle',
    color: 'secondary',
  },
  Charging: {
    label: 'Charging',
    color: 'secondary',
  },
  Off: {
    label: 'Off',
    color: 'secondary',
  },
  Default: {
    label: emDash,
    color: 'error',
  },
}

export const formatVehicleStatusLabel: SourceAttributeFormatter<
  VehicleStatusSourceAttribute,
  string
> = (status) =>
  vehicleStatuses.includes(status)
    ? statusOptions[status].label
    : statusOptions.Default.label

export const getColorForVehicleStatus: SourceAttributeFormatter<
  VehicleStatusSourceAttribute,
  ProgressPillColor
> = (status) =>
  vehicleStatuses.includes(status)
    ? statusOptions[status].color
    : statusOptions.Default.color

export const getStatusLabelFromSourceAttribute =
  getFormattedValueFromSourceAttribute<VehicleStatusSourceAttribute, string>(
    formatVehicleStatusLabel
  )

export const getStatusColorFromSourceAttribute =
  getFormattedValueFromSourceAttribute<
    VehicleStatusSourceAttribute,
    ProgressPillColor
  >(getColorForVehicleStatus)
