import {
  Chart,
  emDash,
  FileText,
  formatDurationTotal,
  formatEnergy,
  formatEnergyUnits,
  formatEnergyUnitsAbbr,
  formatPowerUnits,
  formatPowerUnitsAbbr,
  OverlayDeprecated,
  useFormat,
  useRouting,
} from '@synop-react/common'
import { Divider, Grid, Link, Typography, useTheme } from '@mui/material'
import { RootAPI, useChargerDetailsFromPoll } from '@synop-react/api'
import { useMemo, useState } from 'react'
import { useTransactionQueryValueData } from './useTransactionQueryValueData'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'

const { useGetVehicleQuery } = RootAPI

type ChargerTransactionOverlayProps = {
  transactionId: string
  chargerId: string
  connectorId: number
  vehicleId?: string
  totalEnergyImported?: number
  totalEnergyExported?: number
  startTimestamp?: string
  endTimestamp?: string
  startSoc?: number
  endSoc?: number
  onClose: () => void
}

export const TransactionOverlay = ({
  transactionId,
  chargerId,
  connectorId,
  vehicleId,
  totalEnergyImported,
  totalEnergyExported,
  startTimestamp,
  endTimestamp,
  startSoc,
  endSoc,
  onClose,
}: ChargerTransactionOverlayProps) => {
  const { charting } = useTheme().palette
  const { formatDateTime } = useFormat()
  const { chargers, vehicles } = useRouting().routes
  const [isOpen, setIsOpen] = useState(true)
  const { width } = useWindowSize()

  const {
    getCharger: { data: chargerData, isLoading: chargerIsLoading },
  } = useChargerDetailsFromPoll({
    chargerId,
    disablePolling: true,
  })

  const { data: vehicleData, isLoading: vehicleIsLoading } = useGetVehicleQuery(
    { id: vehicleId || '' },
    { skip: !vehicleId }
  )

  const {
    meterValueIsLoading,
    currentImport,
    currentImportUnit,
    voltage,
    voltageUnit,
    powerActiveImport,
    powerActiveImportUnit,
    powerActiveExport,
    powerActiveExportUnit,
    energyActiveImport,
    energyActiveImportUnit,
    energyActiveExport,
    energyActiveExportUnit,
    vehicleSoc,
    vehicleSocUnit,
  } = useTransactionQueryValueData(transactionId)

  const { totalChargeTime, startingTime, endingTime } = useMemo(() => {
    const startingTime = formatDateTime(startTimestamp).dateDotTime
    const endingTime = formatDateTime(endTimestamp).dateDotTime

    const totalChargeTime =
      !startTimestamp || !endTimestamp
        ? emDash
        : formatDurationTotal(
            dayjs.duration(
              dayjs(endTimestamp).diff(startTimestamp, 'second'),
              'seconds'
            )
          )
    return {
      totalChargeTime,
      startingTime,
      endingTime,
    }
  }, [endTimestamp, formatDateTime, startTimestamp])

  // Dont believe this stat is being properly aggregated on the back end yet
  // Will leave this in so that once we start getting data for it, it will be displayed
  const showTotalEnergyExported =
    totalEnergyExported !== undefined && totalEnergyExported > 0

  const entityInfoOnClick = () => {
    setIsOpen(false)
    onClose()
    window.scrollTo(0, 0)
  }

  return (
    <OverlayDeprecated
      isLoading={chargerIsLoading || vehicleIsLoading || meterValueIsLoading}
      isOpen={isOpen}
      onClose={onClose}
      overlayPixelWidth={width * 0.8}
      setIsOpen={setIsOpen}
      title="Charging Transaction Details"
      TitleIcon={FileText}
    >
      <Grid container spacing={2}>
        <Grid container item>
          <TopLevelStat
            caption="TOTAL ENERGY IMPORTED"
            stat={formatEnergy(totalEnergyImported)}
          />
          {showTotalEnergyExported && (
            <TopLevelStat
              caption="TOTAL ENERGY EXPORTED"
              stat={formatEnergy(totalEnergyExported)}
            />
          )}
          <TopLevelStat
            caption="TOTAL CHARGE TIME"
            stat={totalChargeTime !== 'n/a' ? totalChargeTime : '0h 0m'}
            xs={showTotalEnergyExported ? 6 : 12}
          />
        </Grid>

        <Grid container item md={vehicleId ? 8 : 4} spacing={2}>
          <EntityInfoBlock
            href={chargers.details(chargerData?.chargerId || '')}
            linkText={chargerData?.chargerName || ''}
            onClick={entityInfoOnClick}
            sm={vehicleId ? 6 : 12}
            subText={[
              chargerData?.chargerManufacturerModel?.name || '',
              chargerData?.chargerMakeModel?.chargerModel || '',
            ]}
            text={`Connector ${connectorId}`}
            title="Charger"
          />
          {vehicleId && (
            <EntityInfoBlock
              href={vehicles.details(vehicleData?.id || '')}
              linkText={vehicleData?.vehicleNm || ''}
              onClick={entityInfoOnClick}
              subText={[`Auth. Tag: ${vehicleData?.macAddress}`]}
              text={`VIN: ${vehicleData?.vin}`}
              title="Vehicle"
            />
          )}
        </Grid>

        <Grid item md={4}>
          <EntityInfoBlock text={transactionId} title="Transaction" />
        </Grid>

        <Grid container item md={12} spacing={2}>
          <Grid container item md={4}>
            <SmallStat caption="START DATE/TIME" stat={startingTime} />
            <SmallStat caption="END DATE/TIME" stat={endingTime} />
          </Grid>
          {startSoc && endSoc && (
            <Grid container item md={4}>
              <SmallStat caption="START SOC" stat={`${startSoc}%`} />
              <SmallStat caption="END SOC" stat={`${endSoc}%`} />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {currentImport?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart1.main}
            longUnit="Amps"
            seriesData={currentImport}
            shortUnit={currentImportUnit}
            title="Current Import"
          />
        )}
        {voltage?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart2.main}
            longUnit="Volts"
            seriesData={voltage}
            shortUnit={voltageUnit}
            title="Voltage"
          />
        )}
        {powerActiveImport?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart3.main}
            longUnit={formatPowerUnits(powerActiveImportUnit)}
            seriesData={powerActiveImport}
            shortUnit={formatPowerUnitsAbbr(powerActiveImportUnit)}
            title="Power Active Import"
          />
        )}
        {powerActiveExport?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart4.main}
            longUnit={formatPowerUnits(powerActiveExportUnit)}
            seriesData={powerActiveExport}
            shortUnit={formatPowerUnitsAbbr(powerActiveExportUnit)}
            title="Power Active Export"
          />
        )}
        {energyActiveImport?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart8.main}
            longUnit={formatEnergyUnits(energyActiveImportUnit)}
            seriesData={energyActiveImport}
            shortUnit={formatEnergyUnitsAbbr(energyActiveImportUnit)}
            title="Energy Active Import"
          />
        )}
        {energyActiveExport?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart5.main}
            longUnit={formatEnergyUnits(energyActiveExportUnit)}
            seriesData={energyActiveExport}
            shortUnit={formatEnergyUnitsAbbr(energyActiveExportUnit)}
            title="Energy Active Export"
          />
        )}
        {vehicleSoc?.length > 0 && (
          <TransactionChartGridWrapper
            color={charting.chart6.main}
            longUnit={vehicleSocUnit}
            seriesData={vehicleSoc}
            shortUnit="%"
            title="Vehicle SOC"
          />
        )}
      </Grid>
    </OverlayDeprecated>
  )
}

type StatBlockProps = {
  stat: string
  caption: string
  xs?: number
}

const TopLevelStat = ({ stat, caption, xs = 6 }: StatBlockProps) => {
  return (
    <Grid item md={4} xs={xs}>
      <Typography variant="h4">{stat}</Typography>
      <Typography variant="caption">{caption}</Typography>
    </Grid>
  )
}

type EntityInfoBlockProps = {
  title: string
  href?: string
  onClick?: () => void
  linkText?: string
  text: string
  subText?: string[]
  sm?: number
}

const EntityInfoBlock = ({
  title,
  href,
  onClick = () => undefined,
  linkText,
  text,
  subText,
  sm = 6,
}: EntityInfoBlockProps) => {
  return (
    <Grid item sm={sm}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography color="textSecondary" variant="caption">
        {linkText && (
          <>
            <Link
              href={href}
              onClick={onClick}
              sx={{ fontWeight: 600, textDecoration: 'none' }}
            >
              {linkText}
            </Link>
            {' • '}
          </>
        )}
        {text}
      </Typography>
      {subText && (
        <Typography
          color="textSecondary"
          sx={{ display: 'block' }}
          variant="caption"
        >
          {subText.join(' • ')}
        </Typography>
      )}
    </Grid>
  )
}

const SmallStat = ({ stat, caption }: StatBlockProps) => {
  return (
    <Grid item sx={{ alignSelf: 'flex-end' }} xs={6}>
      <Typography sx={{ whiteSpace: 'pre-line' }} variant="subtitle2">
        {stat}
      </Typography>
      <Typography color="textSecondary" variant="caption">
        {caption}
      </Typography>
    </Grid>
  )
}

const TransactionChartGridWrapper = (props: Chart.TransactionProps) => {
  return (
    <Grid item md={6} xs={12}>
      <Chart.Transaction {...props} />
    </Grid>
  )
}
