import { RootAPI } from '..'

// -- Constants for OCPP Connector Statuses --
// unoccupied:
export const CONNECTOR_STATUS_AVAILABLE = 'Available'
export const CONNECTOR_STATUS_RESERVED = 'Reserved'
// occupied and charging (or starting to charge)
export const CONNECTOR_STATUS_PREPARING = 'Preparing'
export const CONNECTOR_STATUS_CHARGING = 'Charging'
// done charging
export const CONNECTOR_STATUS_SUSPENDED_EV = 'SuspendedEV'
export const CONNECTOR_STATUS_SUSPENDED_EVSE = 'SuspendedEVSE'
export const CONNECTOR_STATUS_FINISHING = 'Finishing'
// error (inoperative) states
export const CONNECTOR_STATUS_FAULTED = 'Faulted'
export const CONNECTOR_STATUS_UNAVAILABLE = 'Unavailable'
// offline/unknown states
export const CHARGER_STATUS_OFFLINE = 'Offline'
export const CONNECTOR_STATUS_UNKNOWN = 'Unknown'
export const CONNECTOR_STATUS_OFFLINE = 'Offline'

// -- Constants for Operational Statuses --
export const OPERATIONAL_STATUS_OPERATIVE = 'Operative'
export const OPERATIONAL_STATUS_INOPERATIVE = 'Inoperative'

// -- Constants for Connectivity Statuses --
export const CONNECTIVITY_STATUS_ONLINE = 'Online'
export const CONNECTIVITY_STATUS_OFFLINE = 'Offline'

export const CurrentConnectorStatuses = [
  CONNECTOR_STATUS_AVAILABLE,
  CONNECTOR_STATUS_RESERVED,
  CONNECTOR_STATUS_PREPARING,
  CONNECTOR_STATUS_CHARGING,
  CONNECTOR_STATUS_SUSPENDED_EV,
  CONNECTOR_STATUS_SUSPENDED_EVSE,
  CONNECTOR_STATUS_FINISHING,
  CONNECTOR_STATUS_FAULTED,
  CONNECTOR_STATUS_UNAVAILABLE,
  CONNECTOR_STATUS_UNKNOWN,
  CONNECTOR_STATUS_OFFLINE,
] as const

export type CurrentConnectorStatus = (typeof CurrentConnectorStatuses)[number]
export type LastKnownConnectorStatus = Exclude<
  CurrentConnectorStatus,
  'Offline'
>

export const OperationalStatuses = [
  OPERATIONAL_STATUS_OPERATIVE,
  OPERATIONAL_STATUS_INOPERATIVE,
] as const
export type OperationalStatus = (typeof OperationalStatuses)[number]

export const ConnectivityStatuses = [
  CONNECTIVITY_STATUS_ONLINE,
  CONNECTIVITY_STATUS_OFFLINE,
] as const
export type ConnectivityStatus = (typeof ConnectivityStatuses)[number]

export const ChargerStatuses = [
  OPERATIONAL_STATUS_OPERATIVE,
  CONNECTOR_STATUS_FAULTED,
  CONNECTOR_STATUS_UNAVAILABLE,
  CHARGER_STATUS_OFFLINE,
  CONNECTOR_STATUS_UNKNOWN,
] as const

export type CurrentChargerStatus = (typeof ChargerStatuses)[number]
export type LastKnownChargerStatus = Exclude<CurrentChargerStatus, 'Offline'>

export const getDisplayedChargerStatus = (
  charger?: RootAPI.ChargerModel
): CurrentChargerStatus =>
  charger?.latestChargerStatus.currentCombinedStatus ?? CONNECTOR_STATUS_UNKNOWN

export const statusIsOperative = (status: CurrentConnectorStatus): boolean => {
  return ![
    CONNECTOR_STATUS_FAULTED,
    CONNECTOR_STATUS_UNAVAILABLE,
    CONNECTOR_STATUS_OFFLINE,
  ].includes(status)
}

export const statusIsPreparingOrCharging = (
  status: CurrentConnectorStatus
): boolean => {
  return [CONNECTOR_STATUS_PREPARING, CONNECTOR_STATUS_CHARGING].includes(
    status
  )
}

export const inUseStatuses: CurrentConnectorStatus[] = [
  'Preparing',
  'Charging',
  'SuspendedEVSE',
  'SuspendedEV',
  'Finishing',
]

export function statusIsInUse(status?: CurrentConnectorStatus): boolean {
  return inUseStatuses.includes(status ?? 'Unknown')
}

export function numStatusesInUse(
  statusSummary?: RootAPI.LatestConnectorStatusSummaryModel
) {
  const currentStatuses = statusSummary?.currentStatuses ?? {}
  return Object.entries(currentStatuses).reduce((acc, [status, count]) => {
    const isInUse = statusIsInUse(status as CurrentConnectorStatus)
    return isInUse ? acc + count : acc
  }, 0)
}
