import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  ValidatorOptions,
} from '../../FormField'
import { FormField } from '../../index'
import { isAutocompleteOption } from '../../FormField/AutocompleteSelect/AutocompleteSelect'
import { RootAPI, useCurrentUser } from '@synop-react/api'

export type VehicleAutocompleteSelectProps<FormData extends FieldValues> = {
  fleetId: string
} & AutocompleteFormControls<FormData, RootAPI.VehicleModel> &
  AutocompleteTextFieldProps

export const VehicleAutocompleteSelect = <FormData extends FieldValues>({
  fleetId,
  ...autocompleteProps
}: VehicleAutocompleteSelectProps<FormData>) => {
  const vehicleOptions = FormField.useOptions<
    RootAPI.VehicleModel,
    RootAPI.GetVehiclesForOrganizationApiArg,
    RootAPI.VehicleModel[]
  >({
    queryArgs: { id: fleetId },
    useQuery: RootAPI.useGetVehiclesForOrganizationQuery,
  })

  const { synopUser, isAdmin } = useCurrentUser()

  const vehicles = vehicleOptions
  if (
    vehicles &&
    !isAdmin &&
    synopUser &&
    synopUser.sites &&
    synopUser.sites.length > 0
  ) {
    vehicles.options = vehicleOptions.options.filter(
      (item) =>
        synopUser &&
        synopUser.sites &&
        item.homeSiteId &&
        synopUser.sites.includes(item.homeSiteId)
    )
  }

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.VehicleModel>
      label="Vehicle"
      {...vehicles}
      {...autocompleteProps}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) => {
          if (!isAutocompleteOption(option)) return false
          if (!option.macAddress) {
            return false
          }
          const { vehicleNm } = option
          const vehicleName = vehicleNm?.toLowerCase() || ''
          const searchTerm = inputValue.toLowerCase()
          return vehicleName.includes(searchTerm)
        })
      }
      getOptionLabel={(option) =>
        isAutocompleteOption(option) && option?.vehicleNm
          ? option.vehicleNm
          : ''
      }
      keyExtractor={(option) =>
        isAutocompleteOption(option) && option.id ? option?.id : ''
      }
    />
  )
}

const defaultVehicleValidatorOptions: ValidatorOptions = {
  isRequired: true,
  isRequiredMessage: 'Vehicle is required',
}

export const vehicleSelectSchema = (
  options: Partial<ValidatorOptions> = defaultVehicleValidatorOptions
) => {
  const { isRequired, isRequiredMessage } = Object.assign(
    {},
    defaultVehicleValidatorOptions,
    options
  )
  const vehicleObjectValidator = yup
    .object({
      id: yup.string().required(),
    })
    .nullable()

  return isRequired
    ? vehicleObjectValidator.required(isRequiredMessage)
    : vehicleObjectValidator
}

export { VehicleAutocompleteSelect as Select, vehicleSelectSchema as Schema }
