import { Grid, styled } from '@mui/material'
import { ReactNode } from 'react'

export interface StatusCaskRowProps {
  children: ReactNode
}

export function StatusCaskRow({ children }: StatusCaskRowProps) {
  return (
    <Grid item xs={12}>
      <StatusBackground sx={{ mt: 2 }}>
        <Grid container direction="row">
          <Grid container px={4} py={2} spacing={6}>
            {children}
          </Grid>
        </Grid>
      </StatusBackground>
    </Grid>
  )
}

export default StatusCaskRow

const StatusBackground = styled('div')(({ theme }) => ({
  height: '108px',
  backgroundColor: theme.palette.secondary['4p'],
}))
