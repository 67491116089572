import { Box, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import {
  Cask,
  CaskDatum,
  CaskDatumProps,
  formatEnergy,
  formatPower,
  Icons,
  useFormat,
} from '@synop-react/common'
import {
  CurrentChargerStatus,
  numStatusesInUse,
  RootAPI,
  useDepotChargers,
  usePolling,
  useUserPrefs,
} from '@synop-react/api'
import { EnergySavingsLeafOutlined } from '@mui/icons-material'
import { isNumber } from 'lodash'
import { Maybe } from '@synop-react/types'
import { useEffect, useMemo, useState } from 'react'
import { usePalette } from '@synop-react/theme'

const {
  useCmsUtilizationGetUtilizationQuery,
  useGetDepotQuery,
  useGetLatestStatusSummaryQuery,
} = RootAPI

type SiteStatusCaskProps = {
  site: Maybe<RootAPI.DepotModel>
  siteId: string
}

export const SiteStatusCask = ({ site, siteId }: SiteStatusCaskProps) => {
  const { tzDayjs } = useUserPrefs()
  const { formatDateTime } = useFormat()

  const { data: latestStatuses, isFetching } = usePolling(
    useGetLatestStatusSummaryQuery,
    { siteIds: [siteId] },
    { pollingIntervalSeconds: 60 }
  )

  // Keep track of the last time the data was fetched so that we can display it in the
  // cask subtitle. TODO: remove this entirely when we switch to 5s polling.
  const [lastUpdated, setLastUpdated] = useState(tzDayjs().toISOString())
  useEffect(() => {
    if (isFetching) return
    setLastUpdated(tzDayjs().toISOString())
  }, [tzDayjs, isFetching])

  return (
    <Cask
      Actions={<EnergyManagedIcon site={site} />}
      subtitle={formatDateTime(lastUpdated).asTimeOnDate}
      title="Site Status"
    >
      <Stack spacing={2.5}>
        <CaskData siteId={siteId} statuses={latestStatuses?.connectors} />
        <Stack alignItems="flex-end" direction="row" spacing={4}>
          <ChargerStatusIcons statuses={latestStatuses?.chargers} />
        </Stack>
      </Stack>
    </Cask>
  )
}

type EnergyManagedIconProps = {
  site: Maybe<RootAPI.DepotModel>
}

const EnergyManagedIcon = ({ site }: EnergyManagedIconProps) => {
  const color = usePalette().palette.success.main
  return !site?.isLoadBalanced ? null : (
    <Tooltip arrow title="Energy management is enabled for this site.">
      <EnergySavingsLeafOutlined sx={{ color }} />
    </Tooltip>
  )
}

type ChargerCountProps = {
  siteId: string
}

const ChargerCount = ({ siteId }: ChargerCountProps) => {
  const { depotChargers } = useDepotChargers(siteId)
  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <CaskDatum.Tooltip title="Number of chargers configured for this site.">
        <Stack>
          <Typography variant="h3">{depotChargers.ids.length}</Typography>
          <Typography variant="caption">Total Chargers</Typography>
        </Stack>
      </CaskDatum.Tooltip>
    </Grid>
  )
}

type ChargerStatusIconsProps = {
  statuses?: RootAPI.LatestChargerStatusSummaryModel
}

const ChargerStatusIcons = ({ statuses }: ChargerStatusIconsProps) => {
  const { currentStatuses } = statuses ?? {}
  if (!currentStatuses) return <Skeleton width="100px" />

  const order = ['Operative', 'Offline', 'Unavailable', 'Faulted', 'Unknown']
  const orderedStatuses = Object.entries(currentStatuses)
    .flatMap(([status, count]) => [{ count, status }])
    .sort((a, b) =>
      order.indexOf(a.status) > order.indexOf(b.status) ? 1 : -1
    )

  return (
    <Grid container spacing={2}>
      {orderedStatuses.map(({ status, count }) => (
        <ChargerStatusIcon
          key={status}
          count={count}
          status={status as CurrentChargerStatus}
        />
      ))}
    </Grid>
  )
}

type ChargerStatusIconProps = {
  count: number
  status: CurrentChargerStatus
}

const ChargerStatusIcon = ({ count, status }: ChargerStatusIconProps) => {
  const { palette } = usePalette()
  const size = 16

  const Icon = useMemo(() => {
    switch (status) {
      case 'Operative':
        return <Icons.CheckCircle color={palette.info.main} size={size} />
      case 'Faulted':
        return <Icons.AlertTriangle color={palette.error.main} size={size} />
      case 'Offline':
        return <Icons.Clock color={palette.warning.main} size={size} />
      case 'Unavailable':
        return <Icons.AlertCircle color={palette.error[700]} size={size} />
      case 'Unknown':
        return <Icons.MinusCircle color={palette.secondary[300]} size={size} />
      default:
        return null
    }
  }, [palette, status])

  return (
    <Grid item lg={2} md={4} sm={6} xs={12}>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {Icon}
        <Typography variant="caption">
          {count} {status}
        </Typography>
      </Stack>
    </Grid>
  )
}

const tooltipText = {
  chargerCount: 'Number of chargers configured for this site.',
  connectorsInUse:
    'Number of connectors in one of the following statuses: Preparing, Charging, Suspended EVSE, ' +
    'Suspended EV, or Finishing.',
  liveUtilization: 'Current power output for this site.',
  energyImportToday:
    "Sum of energy imported for this site's transactions over the last 24 hours.",
}

type CaskDataProps = {
  siteId: string
  statuses?: RootAPI.LatestConnectorStatusSummaryModel
}

const CaskData = ({ siteId, statuses }: CaskDataProps) => {
  const { data: utilization } = usePolling(
    useCmsUtilizationGetUtilizationQuery,
    { siteIds: [siteId] },
    { pollingIntervalSeconds: 60 }
  )

  const { data: site } = usePolling(
    useGetDepotQuery,
    { depotId: siteId, include: ['importKwhLast24h'] },
    { pollingIntervalSeconds: 60 }
  )

  const liveImport = utilization?.total?.livePowerImportKw
  const { importKwhLast24h } = site ?? {}
  const connectorsInUse = useMemo(() => {
    if (!statuses) return

    const numInUse = numStatusesInUse(statuses)
    return `${numInUse} / ${statuses.total}`
  }, [statuses])

  return (
    // Wrap the `Grid` in a `Box` so that the parent `Stack` does not overrule the `Grid`'s spacing
    <Box>
      <Grid alignItems="flex-end" container spacing={2}>
        <ChargerCount siteId={siteId} />

        <CaskDatumWrapper
          label="Connectors in Use"
          tooltip={tooltipText.connectorsInUse}
          value={connectorsInUse}
        />

        <CaskDatumWrapper
          label="Live Utilization"
          tooltip={tooltipText.liveUtilization}
          value={isNumber(liveImport) ? formatPower(liveImport) : undefined}
        />

        <CaskDatumWrapper
          label="Energy Import Today"
          tooltip={tooltipText.energyImportToday}
          value={
            isNumber(importKwhLast24h)
              ? formatEnergy(importKwhLast24h)
              : undefined
          }
        />
      </Grid>
    </Box>
  )
}

type CaskDatumWrapperProps = Omit<CaskDatumProps, 'value'> &
  Partial<Pick<CaskDatumProps, 'value'>>

const CaskDatumWrapper = ({ value, ...rest }: CaskDatumWrapperProps) => (
  <Grid item lg={3} md={4} sm={6} xs={12}>
    <CaskDatum value={value ?? <Skeleton width="100%" />} {...rest} />
  </Grid>
)
