import * as yup from 'yup'
import { FieldError, useForm } from 'react-hook-form'
import { Grid, Tooltip, Typography, useTheme } from '@mui/material'
import {
  Icons,
  OverlayDeprecated,
  VehicleAutocomplete,
} from '@synop-react/common'
import { LoadingButton } from '@mui/lab'
import { RootAPI, useCurrentOrgId, VehicleModel } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup'

const { useStartChargingTransactionMutation } = RootAPI.synopRootAPI

const schema = yup
  .object({
    vehicle: VehicleAutocomplete.Schema().shape({
      macAddress: yup
        .string()
        .nullable()
        .required('Vehicle must have OCPP Tag'),
    }),
  })
  .required()

interface StartChargingSessionOverlayProps {
  chargerId: string
  connectorId: number
  depotName: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type StartChargingSessionFormData = {
  vehicle: VehicleModel
}

const StartChargingSessionOverlay = ({
  chargerId,
  connectorId,
  depotName,
  isOpen,
  setIsOpen,
}: StartChargingSessionOverlayProps) => {
  const theme = useTheme()
  const orgId = useCurrentOrgId()

  const [startChargingSession, startChargingSessionResponse] =
    useStartChargingTransactionMutation()

  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm<StartChargingSessionFormData>({
    defaultValues: {
      vehicle: {} as VehicleModel,
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({
    vehicle: { macAddress },
  }: StartChargingSessionFormData) => {
    try {
      await startChargingSession({
        chargerId,
        chargingTransactionRequest: {
          connectorId,
          tagId: macAddress || '',
        },
      }).unwrap()

      setIsOpen(false)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <OverlayDeprecated
      isOpen={isOpen}
      OverlayActions={[
        <LoadingButton
          loading={startChargingSessionResponse.isLoading}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
        >
          Start Charging Transaction
        </LoadingButton>,
      ]}
      setIsOpen={setIsOpen}
      title="Start Charging Transaction"
      TitleIcon={Icons.PlusCircle}
    >
      <Grid
        container
        direction="column"
        rowSpacing={theme.spacing(2)}
        spacing={theme.spacing(2)}
      >
        <Grid item>
          <Typography variant="body1">
            Please select a vehicle for the charging transaction.
          </Typography>
        </Grid>
        <Grid container item md={6} xs={12}>
          <Grid item xs={11}>
            <VehicleAutocomplete.Select
              control={control}
              error={errors.vehicle as FieldError}
              fleetId={orgId}
              id="vehicle"
              touchedField={Boolean(touchedFields.vehicle)}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            xs
          >
            <Tooltip title="Only vehicles with a mapped OCPP tag are available for selection here.">
              <Icons.Info />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            You are about to begin a charging transaction in the following
            location.
          </Typography>
        </Grid>
        <Grid container item>
          <Grid
            container
            direction="column"
            item
            spacing={theme.spacing(2)}
            xs={6}
          >
            <Grid item>
              <Typography variant="caption">Charger Name</Typography>
              <Typography variant="body1">{chargerId}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Site Name</Typography>
              <Typography variant="body1">{depotName}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" item xs={6}>
            <Grid item>
              <Typography variant="caption">Connector ID</Typography>
              <Typography variant="body1">{connectorId}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </OverlayDeprecated>
  )
}

export default StartChargingSessionOverlay
