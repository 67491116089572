import { PropsWithChildren, useEffect, useMemo } from 'react'

import {
  FleetSelectorProvider,
  MultiOrgSelectorProvider,
  MultiSiteSelectorProvider,
  OrgSelectorProvider,
  SiteSelectorProvider,
  useFleetSelector,
  useMultiOrgSelector,
  useMultiSiteSelector,
  useOrgSelector,
  useSiteSelector,
} from './hooks'
import { Narrow } from '@synop-react/types'
import { SelectorState } from './EntitySelector'

type OrgSiteSelectorProps = PropsWithChildren<{
  syncToLocalStorage?: boolean
}>

export function OrgSelectorsProvider({
  children,
  syncToLocalStorage,
}: OrgSiteSelectorProps) {
  return (
    <OrgSelectorProvider syncToLocalStorage={syncToLocalStorage}>
      <SiteSelectorProvider syncToLocalStorage={syncToLocalStorage}>
        <FleetSelectorProvider syncToLocalStorage={syncToLocalStorage}>
          <OrgSiteSelectionLogic />
          <OrgFleetSelectionLogic />
          {children}
        </FleetSelectorProvider>
      </SiteSelectorProvider>
    </OrgSelectorProvider>
  )
}

// Support for the multiple variant of OrgSelectorsProvider
export function MultiOrgSelectorsProvider({
  children,
  syncToLocalStorage,
}: OrgSiteSelectorProps) {
  return (
    <MultiOrgSelectorProvider syncToLocalStorage={syncToLocalStorage}>
      <MultiSiteSelectorProvider syncToLocalStorage={syncToLocalStorage}>
        <MultiOrgSiteSelectionLogic />
        {children}
      </MultiSiteSelectorProvider>
    </MultiOrgSelectorProvider>
  )
}

/**
 * This factory function generates a component that is responsible for updating a non-org entity selector
 * when the org changes. It updates the selector in two ways:
 *
 *   1. It sets the entity filter function to only show entities that are members of the selected org.
 *   2. If the currently selected entity is not a member of the selected org, it clears the selection.
 */
function SelectionLogicComponentFactory<T>(
  useSelector: () => SelectorState<T>,
  orgIdKey: Narrow.MaybeStringKeyOf<T>
) {
  return function SelectionLogicComponent() {
    const orgId = useOrgSelector().selected?.id
    const { selected, setSelected, setOptionFilter } = useSelector()

    useEffect(() => {
      // Update the filter function
      if (orgId) {
        setOptionFilter((entity: T) => getOrgId(entity) === orgId)
      } else {
        setOptionFilter(null)
      }

      // When the org changes, check if the entity is a member of the new org. If not, clear
      // the selection.
      if (orgId && selected && getOrgId(selected) !== orgId) {
        setSelected(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, selected])

    return null
  }

  function getOrgId(entity: T) {
    return Narrow.getKeyOf(entity, orgIdKey)
  }
}

/**
 * This factory function generates a component that is responsible for updating a non-org entity selector
 * when the org changes. It updates the selector in two ways:
 *
 *   1. It sets the entity filter function to only show entities that are members of the selected org.
 *   2. If the currently selected entity is not a member of the selected org, it clears the selection.
 */
function MultiSelectionLogicComponentFactory<T>(
  useMultiSelector: () => SelectorState<T, T[]>,
  orgIdKey: Narrow.MaybeStringKeyOf<T>
) {
  return function MultiSelectionLogicComponent() {
    const { selected: selectedOrgs } = useMultiOrgSelector()
    const { selected, setSelected, setOptionFilter } = useMultiSelector()

    const selectedOrgIds = useMemo(
      () => selectedOrgs?.map(({ id }) => id),
      [selectedOrgs]
    )

    useEffect(() => {
      // Update the filter function
      if (selectedOrgIds?.length) {
        setOptionFilter((entity) => {
          const orgId = getOrgId(entity) ?? ''
          return selectedOrgIds.includes(orgId)
        })
      } else {
        setOptionFilter(null)
      }

      const hasAllMatchingOrgs = selected
        ?.map((entity) => selectedOrgIds?.includes(getOrgId(entity) ?? ''))
        .every(Boolean)

      // When the org changes, check if the entity is a member of the new org. If not, clear
      // the selection.
      if (selectedOrgIds && selected && !hasAllMatchingOrgs) {
        setSelected(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrgIds, selected])

    return null
  }

  function getOrgId(entity: T) {
    return Narrow.getKeyOf(entity, orgIdKey)
  }
}

const OrgSiteSelectionLogic = SelectionLogicComponentFactory(
  useSiteSelector,
  'fleetId'
)

const MultiOrgSiteSelectionLogic = MultiSelectionLogicComponentFactory(
  useMultiSiteSelector,
  'fleetId'
)

const OrgFleetSelectionLogic = SelectionLogicComponentFactory(
  useFleetSelector,
  'organizationId'
)
