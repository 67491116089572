// Should include shorter filters later in the list, so that more specific ones have a chance to match first
const customeAssets = [
  {
    filters: ['lightning', 'insights'],
    logoUrl:
      'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca0ae187e7e1f71017e7ecff1ea0000.png',
    favicon:
      'https://lightningemotors.com/wp-content/themes/lightningmotors-2022/dist/images/favicons/favicon-32x32.png',
    title: 'Lightning Insights',
  },
  {
    filters: ['prologis'],
    logoUrl:
      'https://synop-prod-assets.s3.amazonaws.com/logos/2ca0870f7f22014f017f4c6e12c80000.png',
    title: 'Prologis Mobility',
    favicon: 'https://authentication.prologis.com/favicon-32x32.png',
  },
  {
    filters: ['highland'],
    logoUrl:
      'https://synop-prod-assets.s3.amazonaws.com/logos/2ca0ae187e23d1cc017e24030bdb0001.png',
    title: 'Highland Dashboard',
  },
  {
    filters: ['electrada'],
    logoUrl:
      'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca01ebc8236efd60182370b208d0000.png',
  },
  {
    filters: ['revolv'],
    logoUrl:
      'https://synop-prod-assets.s3.amazonaws.com/logos/4028d60b7e551c60017e552a43d50000.png',
  },
  {
    filters: ['chubu'],
    logoUrl:
      'https://synop-prod-assets-v1.s3.amazonaws.com/logos/chubu-logo.png',
  },
  {
    filters: ['app', 'canary'],
    logoUrl:
      'https://synop-prod-assets.s3.amazonaws.com/logos/8ac5611d7c2d6a07017c2d6a2e1c0000.png',
  },
]

export const getCustomAsset = () => {
  const hostURL = location.host.toLocaleLowerCase()
  return customeAssets.find((customer) =>
    customer.filters.some((filter) => hostURL.includes(filter))
  )
}
