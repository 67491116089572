import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { Group } from '@visx/group'
import { map } from 'lodash'
import { Pie } from '@visx/shape'
import { Text } from '@visx/text'
import { useEffect, useMemo, useState } from 'react'

import { emDash } from '../../utils'

type ChartMargin = {
  top: number
  right: number
  bottom: number
  left: number
}

export type DonutChartProps = {
  // Whether to center the donut chart in its container (defaults to false)
  centered?: boolean

  // Collection of data to be used for donut chart
  data: Datum[]

  // Height/width of donut chart
  diameter?: number

  // Fill percentage of donut chart (0-1) -- 1 means no hole in the middle
  donutFill?: number

  // Number of digits to display after the decimal point
  fractionDigits?: number

  /**
   * Height of donut chart component (this doesn't affect the donut itself, but rather the
   * lateral space the component takes up)
   */
  height?: number

  // Whether to show the legend to the right of the donut chart
  hideLegend?: boolean

  // Text to be displayed when no data is active
  inactiveText?: string

  // Margin around the donut chart. Values are in pixels.
  margin?: Partial<ChartMargin>

  // Function to filter the data. By default, data with a percentage of 0 or NaN is filtered out.
  predicate?: (datum: Datum) => boolean

  // Function to select the default active datum By default, the largest percentage is selected.
  selectDefaultActiveDatum?: (data: Datum[]) => Datum | undefined

  // Function to sort the data. By default, the data is sorted by percentage (largest to smallest)
  sortFn?: (a: Datum, b: Datum) => number

  /**
   * Width of donut chart component (this doesn't affect the donut itself, but rather the
   * vertical space the component takes up)
   */
  width?: number
}

export type Datum = {
  color: string
  text: string
  percentage: number
}

const accessors = {
  colorAccessor: (d: Datum) => d.color,
  labelAccessor: (d: Datum) => d.text,
  percentAccessor: (d: Datum) => d.percentage,
}

const selectActiveDatumByLargestPercentage = (data: Datum[]) => {
  const percentages = map(data, 'percentage')
  const largestPercentage = Math.max(...percentages)
  return data.find((datum) => datum.percentage === largestPercentage)
}

const defaultSortFn = (a: Datum, b: Datum) => b.percentage - a.percentage
const defaultPredicate = (datum: Datum) =>
  datum && datum.percentage !== 0 && !isNaN(datum?.percentage)

const DEFAULT_SIZE = 150
const DEFAULT_MARGIN = { top: 20, right: 20, bottom: 20, left: 20 }

export function DonutChart({
  centered = false,
  data: dataProp,
  diameter = DEFAULT_SIZE,
  donutFill = 0.2,
  fractionDigits = 0,
  height = diameter,
  hideLegend = false,
  inactiveText = emDash,
  margin: marginProp,
  predicate = defaultPredicate,
  selectDefaultActiveDatum = selectActiveDatumByLargestPercentage,
  sortFn = defaultSortFn,
  width = diameter,
}: DonutChartProps) {
  const margin = { ...DEFAULT_MARGIN, ...marginProp }
  const { palette } = useTheme()

  const data = useMemo(
    () =>
      dataProp
        .filter(predicate)
        .map(({ percentage, ...rest }) => ({
          ...rest,
          percentage: Number(percentage.toFixed(fractionDigits)),
        }))
        .sort(sortFn),
    [dataProp, fractionDigits, predicate, sortFn]
  )

  const [activeDatum, setActiveDatum] = useState(selectDefaultActiveDatum(data))

  // `data` may be empty to begin with. Update the active datum when it becomes available
  useEffect(() => {
    if (!activeDatum) setActiveDatum(selectDefaultActiveDatum(data))
  }, [activeDatum, data, selectDefaultActiveDatum])

  const [isHovering, setIsHovering] = useState(false)

  // If the data changes, recompute the active datum
  useEffect(() => {
    if (!isHovering) {
      setActiveDatum(selectDefaultActiveDatum(data))
    } else if (activeDatum && !data.includes(activeDatum)) {
      // If the user was hovering over a datum that is no longer in the data, clear the
      // active datum.
      setActiveDatum(undefined)
    }
    // This should not be called when the active datum or hover state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectDefaultActiveDatum])

  //Donut spec variables
  const innerDiameter = diameter - margin.left - margin.right
  const radius = innerDiameter / 2
  const centerY = height / 2
  const centerX = width / 2
  const donutThickness = donutFill * radius
  const legendMargin = DEFAULT_MARGIN.right - margin.right

  const handleHoverEnter = (datum: Datum) => {
    setActiveDatum(datum)
    setIsHovering(true)
  }

  const handleHoverLeave = () => {
    setActiveDatum(
      selectDefaultActiveDatum ? selectDefaultActiveDatum(data) : undefined
    )
    setIsHovering(false)
  }

  const innerRadius = radius - donutThickness
  return (
    <Grid
      container
      spacing={2}
      sx={{
        alignItems: 'center',
        justifyContent: centered ? 'center' : 'flex-start',
      }}
    >
      <svg height={height} width={width}>
        <rect fill="#FFFFFF" height={height} rx={14} width={width} />
        <Group left={centerX} top={centerY}>
          <Pie
            cornerRadius={0}
            data={data}
            innerRadius={({ data }) =>
              data.text === activeDatum?.text && isHovering
                ? innerRadius * 0.98
                : innerRadius
            }
            outerRadius={({ data }) =>
              data.text === activeDatum?.text && isHovering
                ? radius * 1.01
                : radius
            }
            padAngle={0.0}
            pieValue={accessors.percentAccessor}
            x={centerX}
            y={centerY}
          >
            {(pie) =>
              pie.arcs.map((arc) => (
                <Group
                  key={arc.data.text}
                  onMouseEnter={() => handleHoverEnter(arc.data)}
                  onMouseLeave={handleHoverLeave}
                >
                  <path d={pie.path(arc) ?? ''} fill={arc.data.color} />
                </Group>
              ))
            }
          </Pie>
        </Group>
        <Group left={centerX} top={centerY}>
          <Text
            dy={-22}
            fill={palette.secondary.main}
            style={{ fontFamily: 'Open Sans', fontSize: 24 }}
            textAnchor="middle"
            verticalAnchor="start"
            width={width}
          >
            {activeDatum ? `${activeDatum.percentage}%` : inactiveText}
          </Text>
          <Text
            dy={12}
            fill={palette.secondary['600']}
            style={{ fontFamily: 'Open Sans', fontSize: 12 }}
            textAnchor="middle"
            verticalAnchor="start"
            width={width}
          >
            {activeDatum?.text}
          </Text>
        </Group>
      </svg>
      {!hideLegend && (
        <Box marginLeft={`${legendMargin}px`}>
          <DonutChartLegend data={data} />
        </Box>
      )}
    </Grid>
  )
}

type DonutChartLegendProps = Pick<DonutChartProps, 'data'>

const DonutChartLegend = ({ data }: DonutChartLegendProps) => {
  // If there are 5 or fewer data points, display them in a single column
  if (data.length <= 5) return <DonutChartLegendColumn data={data} />

  // Otherwise, display them in two columns, with the taller column on the left
  const halfwayIndex = Math.ceil(data.length / 2)
  return (
    <Stack direction="row" spacing={3}>
      <DonutChartLegendColumn data={data.slice(0, halfwayIndex)} />
      <DonutChartLegendColumn data={data.slice(halfwayIndex)} />
    </Stack>
  )
}

const DonutChartLegendColumn = ({ data }: DonutChartLegendProps) => {
  return (
    <Stack direction="column" sx={{ justifyContent: 'center' }}>
      {data.map(({ color, percentage, text }) => (
        <Grid
          key={text}
          container
          spacing={2}
          sx={{ justifyContent: 'left' }}
          wrap="nowrap"
        >
          <Grid item xs>
            <svg height={10} width={10}>
              <rect fill={color} height={10} width={10} />
            </svg>
          </Grid>
          <Grid item xs={8}>
            <Typography color="textSecondary" variant="caption">
              {text}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              color="textSecondary"
              sx={{ justifyContent: 'right', whiteSpace: 'nowrap' }}
              variant="caption"
            >
              {/**
               * By this point, a percentage of 0 means that the datum was passed in with a non-zero percentage
               * of less than 0.5 (data passed in with 0% are filtered out) but was rounded down to 0.
               */}
              {percentage === 0 ? '< 1' : percentage}%
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Stack>
  )
}
