import { ChargerUtilizationChart } from './ChargerUtilizationChart'
import { UtilizationChartCask } from '@synop-react/common'

type ChargerUtilizationChartPropsProps = {
  chargerId: string
  powerCeiling: number // TODO: need this?
}

const ChargerUtilizationChartCask = ({
  chargerId,
  powerCeiling,
}: ChargerUtilizationChartPropsProps) => {
  return (
    <UtilizationChartCask
      options={{ useEndOfCurrentDay: true }}
      title="Charger Utilization"
    >
      {({ from, to }) => (
        <ChargerUtilizationChart
          height={325}
          {...{ from, to, chargerId, powerCeiling }}
        />
      )}
    </UtilizationChartCask>
  )
}

export default ChargerUtilizationChartCask
