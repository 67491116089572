import {
  BrowserContextState,
  EntityProvider,
  EntityState,
  parseEntitiesToIds,
  parseEntityMapToBoolMap,
  resetBrowserDisplayedEntities,
  setBrowserDisplayedEntities,
  setBrowserEntities,
  setBrowserSortOrder,
  setCurrentEntitySorters,
  setEntitySorter,
  toggleBrowserSelectedEntity,
  useEntityReducer,
} from './useEntityBrowser'
import { ComponentType, ReactNode, useEffect } from 'react'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Entity, EntityIdMap } from '@synop-react/types'
import { FeatureCollection } from '@turf/helpers'
import { FullscreenEntityMap } from '../Map/Fullscreen/FullscreenEntityMap'
import { Grid, Paper } from '@mui/material'
import {
  GridColDef,
  GridRowModel,
  useFirstRender,
} from '@mui/x-data-grid-premium'
import { TEntityListItem } from '../List/Item'
import { useFullscreenControls, useIsFullscreen } from '../../store'
import { useLocalStorageState, useQuery } from '../../utils'
import { useLocation } from 'react-router'
import EntityBrowserToolbar from './Toolbar/EntityBrowserToolbar'
import EntityListMap from '../ListMap/EntityListMap'
import EntityTable from '../Table'

export type EntityListItemRenderer<T extends Entity = Entity> = (
  entity: T
) => TEntityListItem
export type EntityTableRowRenderer<T extends Entity = Entity> = (
  entity: T
) => GridRowModel
export type ToolbarControl = { key: string; Component: ComponentType }
export type ToolbarControls = ToolbarControl[]
export type BrowserSelector<R> = (state: EntityState<Entity>) => R
export type EntityMapLayerRenderer<T extends Entity = Entity> = (
  entities: EntityIdMap<T>
) => ReactNode
export type EntityMapLayerAssets = Record<string, HTMLImageElement>
export type EntityCoordAccessor<T extends Entity = Entity> = (
  entities: EntityIdMap<T>
) => FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>

export type MapLayersProps = { entities: EntityIdMap }
export type MapLayersComponent = (props: MapLayersProps) => EmotionJSX.Element
export type EntityMapConfig<T extends Entity = Entity> = {
  layerFilters?: FilterConfig
  OverviewMapLayers: JSX.Element
  FullscreenMapLayers?: ReactNode
  getCoords: EntityCoordAccessor<T>
  /*
   * Interactive Layer Ids that can be hovered / zoomed to
   */
  layerIds: string[]
  MapLegend?: ReactNode
}

export type FilterConfig = Record<string, LayerFilterOption>

export type LayerFilterOption = {
  filter: mapboxgl.Expression
  color: string
}

type EntityBrowserProps<T extends Entity> = {
  entities: EntityIdMap<T>
  mapConfig: EntityMapConfig<T>
  renderListItem: EntityListItemRenderer<T>
  renderTableRow: EntityTableRowRenderer<T>
  tableColumns: GridColDef[]
  toolbarControls?: ToolbarControls
}

export type BrowserView = keyof typeof BrowserViews
const BrowserViews = {
  map: EntityListMap,
  table: EntityTable,
  displaymode: FullscreenEntityMap,
}

export function EntityBrowser<T extends Entity>({
  entities,
  mapConfig,
  renderListItem,
  renderTableRow,
  tableColumns,
  toolbarControls = [] as ToolbarControls,
}: EntityBrowserProps<T>) {
  const query = useQuery()
  const { pathname } = useLocation()
  const { enterFullscreen } = useFullscreenControls()
  const [displayedView, setDisplayedView] = useLocalStorageState<BrowserView>(
    'view',
    { defaultValue: 'map' }
  )

  useFirstRender(() => {
    const view = query.get('view')
    if (view && view in BrowserViews) setDisplayedView(view as BrowserView)

    if (view === 'displaymode' && pathname !== '/sites') enterFullscreen()
  })

  useEffect(() => {
    const newUrl = new URL(window.location.href)
    newUrl.searchParams.set('view', displayedView)
    window.history.pushState({ path: newUrl.href }, '', newUrl.href)
  }, [displayedView])

  const [reducerState, dispatch] = useEntityReducer<T>({
    allEntities: entities,
    visibleEntityIds: parseEntityMapToBoolMap(entities),
    entityIdSortOrder: parseEntitiesToIds(Object.values(entities)),
  })
  const isFullscreen = useIsFullscreen()

  useEffect(() => {
    dispatch(setBrowserEntities(entities))

    const hasSameFeatures =
      Object.values(reducerState.allEntities).length ===
      Object.values(entities).length
    if (!hasSameFeatures) {
      dispatch(setBrowserDisplayedEntities(parseEntityMapToBoolMap(entities)))
      dispatch(setBrowserSortOrder(parseEntitiesToIds(Object.values(entities))))
    }
  }, [entities, dispatch, reducerState.allEntities])

  const CurrentView = BrowserViews[displayedView]

  const selectState = <R,>(selector: BrowserSelector<R>) =>
    selector(reducerState)

  const ContextState: BrowserContextState<T> = {
    ...reducerState,
    setDisplayedEntities: (map) => dispatch(setBrowserDisplayedEntities(map)),
    toggleSelectedEntity: (id) => dispatch(toggleBrowserSelectedEntity(id)),
    setSortedEntityIds: (ids) => dispatch(setBrowserSortOrder(ids)),
    setEntitySorter: (entitySorter) => dispatch(setEntitySorter(entitySorter)),
    setSorters: (sorters) => dispatch(setCurrentEntitySorters(sorters)),
    mapConfig,
    renderListItem,
    tableColumns,
    renderTableRow,
    selectState,
  }

  return (
    <EntityProvider value={ContextState}>
      <Grid container>
        <Grid item sx={{ alignContent: 'center' }} xs={12}>
          <EntityBrowserToolbar
            displayedView={displayedView}
            resetDisplayedEntities={() =>
              dispatch(resetBrowserDisplayedEntities())
            }
            setBrowserView={setDisplayedView}
            toolbarControls={toolbarControls}
          />
          <Grid
            component={Paper}
            container
            item
            sx={{
              flexWrap: 'nowrap',
              height: ({ mixins }) => {
                const containerSize = isFullscreen ? '100vh' : '85vh'
                return `calc(${containerSize} - ${mixins.toolbar.minHeight}px)`
              },
              elevation: 1,
            }}
            xs={12}
          >
            <CurrentView />
          </Grid>
        </Grid>
      </Grid>
    </EntityProvider>
  )
}

export default EntityBrowser
