import { Box } from '@mui/material'

type ColorDotProps = {
  color: string
}

export function ColorDot({ color }: ColorDotProps) {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: color,
        display: 'inline-block',
      }}
    />
  )
}
