import { Button, IconButton, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  Cask,
  Icons,
  LoadingMessage,
  Table,
  useRouting,
} from '@synop-react/common'
import { cloakAsOrganization, RootAPI, useOrgCustomers } from '@synop-react/api'

import { CreateOrEditOrganizationOverlay } from '../CreateOrEditOrgOverlay'

const columns: Table.ColumnSpec<RootAPI.OrganizationModel> = [
  {
    field: 'organizationNm',
    headerName: 'Name',
    groupable: false,
    width: 650,
  },
  {
    disableColumnMenu: true,
    disableReorder: true,
    resizable: false,
    sortable: false,
    filterable: false,
    field: ' ',
    headerName: ' ',
    width: 200,
    renderCell: ({ row }) => <CloakAsOrgButton org={row} />,
  },
  {
    field: 'edit',
    disableColumnMenu: true,
    disableReorder: true,
    resizable: false,
    sortable: false,
    filterable: false,
    headerName: '',
    width: 100,
    renderCell: ({ row }) => (
      <CreateOrEditOrganizationOverlay
        organizationId={row.id}
        Trigger={
          <IconButton>
            <Icons.Edit3 />
          </IconButton>
        }
      />
    ),
  },
]

export function OrganizationsSettings() {
  const { currentData: currentOrgCustomers = [], isLoading } = useOrgCustomers()

  return (
    <Cask
      Actions={[
        <CreateOrEditOrganizationOverlay
          key="addOrgButton"
          Trigger={
            <Button startIcon={<Icons.PlusCircle />} variant="outlined">
              New Organization
            </Button>
          }
        />,
      ]}
      title="Organizations"
    >
      <Typography variant="body1">
        Add or adjust child organizations.
      </Typography>
      {isLoading ? (
        <LoadingMessage />
      ) : (
        <Table.ClientSide
          columns={columns}
          height="450px"
          initialSortColumn="organizationNm"
          tableData={currentOrgCustomers}
        />
      )}
    </Cask>
  )
}

type CloakAsOrgButtonProps = {
  org: RootAPI.OrganizationModel
}

const CloakAsOrgButton = ({ org }: CloakAsOrgButtonProps) => {
  const dispatch = useDispatch()
  const { navigate } = useRouting()

  /**
   * When the user clicks the "Launch" button, we cloak as the organization.
   * This entails taking several actions:
   *
   *  - Pushing to the `cloakedOrgIds` array in the store
   *  - Navigating to the `/vehicles` page
   */
  const cloakAsOrg = useCallback(() => {
    dispatch(cloakAsOrganization(org))
    navigate('/vehicles', { replace: true })
  }, [dispatch, navigate, org])

  return (
    <Button onClick={cloakAsOrg} variant="outlined">
      Launch
    </Button>
  )
}
