import { ChevronDown } from '../Icons'
import { Children } from 'react'
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  useTheme,
} from '@mui/material'

export type DropdownProps<T> = {
  fullWidth?: boolean
  unselectedOption?: string
  menuMaxHeight?: string
  selectColor?: string
} & Omit<SelectProps<T>, 'IconComponent' | 'autoWidth'>

// The constant UNSELECTED_VALUE is provided as a value to the dropdown when the `unselectedOption`
// prop is provided and no value is selected. This is to support "All" or "None" options.
const UNSELECTED_VALUE = ''

/**
 * Custom dropdown component that uses the MUI `Select` component. This component is intended to be
 * used in place of the MUI `Select` component to provide a consistent look and feel across the
 * application. It supports single and multiple selection, and provides a default "unselected"
 * option that is displayed when no value is selected.
 *
 * Implementation note: The default "unselected" option is produced differently depending on whether
 * the dropdown supports multiple selection. For single selection, a `<MenuItem>` is rendered with
 * an empty string as the value. For multiple selection, the `renderValue` prop is used to display
 * the unselected option.
 */
export default function Dropdown<T extends string | string[]>({
  children,
  fullWidth = false,
  label,
  multiple,
  sx,
  unselectedOption,
  value: valueProp,
  menuMaxHeight,
  selectColor,
  ...selectProps
}: DropdownProps<T>) {
  const hasValue = Array.isArray(valueProp) ? valueProp.length > 0 : !!valueProp
  const defaultValue = multiple ? ([] as unknown as T) : UNSELECTED_VALUE
  const value = unselectedOption && !hasValue ? defaultValue : valueProp

  const hasOptions = Children.count(children) > 0
  const shouldDisplayUnselected = (multiple && !hasOptions) || !multiple

  return (
    <FormControl fullWidth={fullWidth} size="small">
      <Select
        autoWidth
        displayEmpty={!!unselectedOption}
        IconComponent={ChevronDown}
        multiple={multiple}
        renderValue={
          multiple
            ? (selected) => {
                if (!Array.isArray(selected)) {
                  // This should never happen--the `multiple` prop should only be set if the value
                  // is an array.
                  return null
                } else if (selected.length === 0) {
                  return unselectedOption
                } else {
                  return selected.join(', ')
                }
              }
            : // It's important that `renderValue` be undefined for single-select dropdowns, or the
              // component will render the value (e.g. ID) of the selected entity instead of the name.
              undefined
        }
        sx={{ ...useStyles(selectProps.variant, selectColor), ...sx }}
        value={value}
        {...selectProps}
        MenuProps={{
          PaperProps: {
            style: menuMaxHeight
              ? {
                  maxHeight: menuMaxHeight,
                }
              : {},
          },
        }}
      >
        {unselectedOption && shouldDisplayUnselected && (
          <MenuItem value="">{unselectedOption}</MenuItem>
        )}
        {children}
      </Select>
    </FormControl>
  )
}

function useStyles(variant: SelectProps['variant'], selectColor?: string) {
  const defaultColor = useTheme().palette.primary.main
  const color = selectColor ?? defaultColor
  const selectStyles = { fontWeight: 600, fontSize: 13 }
  return {
    '& .MuiSelect-select': {
      ...selectStyles,
      ...(variant !== 'standard' ? { textTransform: 'uppercase' } : {}),
    },
    '& .MuiSelect-select, .MuiSelect-icon ': { color, borderColor: color },
    '& .MuiSelect-icon': { fontSize: '1.5em' },
    '&.MuiOutlinedInput-root': {
      '& fieldset': { borderColor: color },
      '&:hover fieldset': { borderColor: color },
      '&.Mui-focused fieldset': { borderColor: color },
    },
  }
}
