import * as ChargerPanel from '../ChargerPanel'
import {
  CONNECTIVITY_STATUS_OFFLINE,
  CONNECTOR_STATUS_FAULTED,
  CONNECTOR_STATUS_UNAVAILABLE,
  CONNECTOR_STATUS_UNKNOWN,
  CurrentChargerStatus,
  OPERATIONAL_STATUS_OPERATIVE,
} from '@synop-react/api'
import { formatPower, useFormat, useRouting } from '@synop-react/common'
import { Grid, Typography } from '@mui/material'

type ChargerRowProps = {
  depotId: string
  chargerId: string
  chargerName: string
  numConnectors: number
  numConnectorsInUse: number
  totalPower?: number
  powerCeiling: number
  powerUnit: string
  status: CurrentChargerStatus
  connectivityStatus?: string
  lastHeardTimestamp?: string
}

export const ChargerRow = (props: ChargerRowProps) => {
  const { formatDateTime } = useFormat()
  const { routes } = useRouting()
  const {
    chargerId,
    chargerName,
    powerCeiling,
    totalPower,
    powerUnit,
    numConnectors,
    numConnectorsInUse,
    lastHeardTimestamp,
    status,
    connectivityStatus,
  } = props

  let startDetailText: string

  let offline = false
  if (!lastHeardTimestamp) {
    startDetailText = 'Pending OCPP Connection'
  } else if (connectivityStatus === 'Offline') {
    offline = true
    startDetailText = 'Last Heard'
  } else {
    startDetailText = `${numConnectorsInUse}/${numConnectors} connectors in use`
  }

  const inUsePower = formatPower(totalPower, { omitUnits: true })
  return (
    <ChargerPanel.Row
      EndDetails={
        offline ? (
          <Grid item>
            <Typography color="text.secondary" variant="overline">
              {formatDateTime(lastHeardTimestamp).dateDotTime}
            </Typography>
          </Grid>
        ) : (
          <Grid item>
            <Typography color="text.secondary" variant="button">
              {`${inUsePower}/${powerCeiling} ${powerUnit}`}
            </Typography>
          </Grid>
        )
      }
      entityUrl={routes.chargers.details(chargerId)}
      StartDetails={
        <Grid item>
          <Typography color="text.secondary" variant="overline">
            {startDetailText}
          </Typography>
        </Grid>
      }
      status={status}
      statusTooltip={getStatusTooltipText(status)}
      title={chargerName}
      titleColor="primary.main"
    />
  )
}

const getStatusTooltipText = (
  status: CurrentChargerStatus
): string | undefined => {
  if (status === OPERATIONAL_STATUS_OPERATIVE) {
    return 'This charger is cloud-connected and is functioning as expected.'
  }

  if (status === CONNECTOR_STATUS_FAULTED) {
    return 'A fault has occurred on this charger. Select the Charger and view Charger History for more details about the fault.'
  }

  if (status === CONNECTOR_STATUS_UNAVAILABLE) {
    return 'This charger can not currently be used. Check if the charger is currently updating firmware or rebooting to resolve this.'
  }

  if (status === CONNECTOR_STATUS_UNKNOWN) {
    return 'This charger has not yet sent a status message.'
  }

  if (status === CONNECTIVITY_STATUS_OFFLINE) {
    return 'This charger has not communicated with the platform in the last 15 minutes.'
  }

  return undefined
}
