import { PreferredTimeFormat, useCurrentUser } from '..'
import { TimeZone, timezones } from '../@types/timezone'
import { useCallback, useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)

export type TimeFormat = 'hh:mm' | 'HH:mm'
export type TzDayjs = (date: dayjs.ConfigType) => Dayjs

export function useUserPrefs() {
  const { synopUser } = useCurrentUser()

  const {
    preferredDateFormat: maybeDateFormat,
    preferredTimeFormat: maybeTimeFormat,
    preferredTimeZone,
  } = synopUser || {}

  const preferredDateFormat = maybeDateFormat || 'MM/DD/YY'
  const preferredTimeFormat = maybeTimeFormat || '12 Hour Clock (01:00 - 12:00)'
  const mappedTimeFormat = timeFormatMap[preferredTimeFormat]

  const timezone = useMemo(() => {
    return preferredTimeZone && timezones.includes(preferredTimeZone)
      ? preferredTimeZone
      : (dayjs.tz.guess() as TimeZone)
  }, [preferredTimeZone])

  /** Given a date, returns a dayjs object with the user's preferred timezone set */
  const tzDayjs = useCallback(
    (date?: dayjs.ConfigType) => dayjs(date).tz(timezone),
    [timezone]
  )

  return {
    preferredDateFormat,
    preferredHourFormat: mappedTimeFormat.slice(0, 1),
    preferredTimeFormat: mappedTimeFormat,
    preferredTimeZone: timezone,
    tzDayjs,
  }
}

const timeFormatMap: Record<PreferredTimeFormat, TimeFormat> = {
  '12 Hour Clock (01:00 - 12:00)': 'hh:mm',
  '24 Hour Clock (00:00 - 23:59)': 'HH:mm',
}
