import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactElement } from 'react'

export function ThemeTileRow() {
  const paletteOptions: string[] = ['primary', 'secondary', 'ice', 'seaweed']

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="row"
      justifyContent="space-between"
    >
      {paletteOptions.map((option) => {
        return (
          <Grid key={option} item>
            <ThemeTile color={option}>
              <Button variant="text">Select</Button>
            </ThemeTile>
          </Grid>
        )
      })}
    </Grid>
  )
}

export interface ThemeTileProps {
  color: string
  children: ReactElement
}

function ThemeTile({ children, color }: ThemeTileProps) {
  const { palette } = useTheme()
  return (
    <Card>
      <CardMedia
        component="img"
        height="100"
        sx={{ backgroundColor: palette.primary.main }}
        width="200"
      />
      <CardContent>
        <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
          {color}
        </Typography>
      </CardContent>
      <CardActions>{children}</CardActions>
    </Card>
  )
}

export default ThemeTileRow
