import * as yup from 'yup'
import {
  AdaAccessibleOptions,
  ConnectorType,
  DepotAutocomplete,
  InstallationType,
  NetworkConnectionMethod,
} from '../../Autocomplete'
import { BaseChargerFields } from './BaseChargerFields'
import {
  Button,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { ChargerMakeModelAutocomplete } from '../../Autocomplete/ChargerMakeModelAutocompleteSelect'
import { ChargerManufacturerAutocomplete } from '../../Autocomplete/ChargerManufacturerAutocompleteSelect'
import { cloneElement, ReactElement, useEffect, useMemo, useState } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Info, Plus, Settings } from '../../Icons'
import { MetadataFields } from './MetadataFields'
import { OverlayDeprecated } from '../../Overlay'
import { RequestStatusFlags } from '@reduxjs/toolkit/dist/query/core/apiState'
import {
  RootAPI,
  useChargerDetailsFromPoll,
  useCurrentOrgId,
} from '@synop-react/api'
import { SerializedError } from '@reduxjs/toolkit'
import { yupResolver } from '@hookform/resolvers/yup'
import ChargerAwaitingConnection from './ChargerAwaitingConnection'
import ChargerOcppConnectionDetails from './ChargerOcppConnectionDetails'
import ChargerOcppConnectionInfo from './ChargerOcppConnectionInfo'
import dayjs from 'dayjs'
import OemHelperAccordion from './OemHelperAccordion'
import SwitchFormField from '../../FormField/Switch'

const BEGINING_OF_TIME = '1970-01-01T00:00:00.000Z'

const wrappedSchema = (
  type: string | undefined
): {
  [key: string]: yup.SchemaOf<unknown>
} => {
  return {
    depot: DepotAutocomplete.Schema,
    manufacturer: ChargerManufacturerAutocomplete.Schema,
    makeModel: ChargerMakeModelAutocomplete.Schema,
    chargerName: yup.string().required('Name is required'),
    chargerId: yup.string().required('Id is required'),
    maxPower: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max Power is required'),
    maxCurrent:
      type === 'AC'
        ? yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required('Max Current is required')
        : yup.number().optional(),
    voltage:
      type === 'AC'
        ? yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required('Voltage is required')
        : yup.number().optional(),
    autoRegisterTags: yup.boolean().required(),
    networkConnectionMethod: yup.string().nullable().optional(),
    networkConnectionDetails: yup.string().nullable().optional(),
    externalId: yup.string().nullable().optional(),
    utilityLocationId: yup.string().nullable().optional(),
    commissioningDate: yup.string().nullable().optional(),
    evseInstallationType: yup.string().nullable().optional(),
    isAdaAccessible: yup.string().nullable().optional(),
    latitude: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .min(-90, 'Latitude must be between 90 and -90')
      .max(90, 'Latitude must be between 90 and -90')
      .test(
        'maxPrecision',
        'Latitude must have a maximum of 8 decimal places',
        (value) => {
          if (!value) return true
          const [, decimal] = value.toString().split('.')
          if (!decimal) return true
          return decimal?.length <= 8
        }
      )
      .optional(),
    longitude: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .min(-180, 'Longitude must be between 180 and -180')
      .max(180, 'Longitude must be between 180 and -180')
      .test(
        'maxPrecision',
        'Longitude must have a maximum of 8 decimal places',
        (value) => {
          if (!value) return true
          const [, decimal] = value.toString().split('.')
          if (!decimal) return true
          return decimal?.length <= 8
        }
      )
      .optional(),
  }
}

export type ConnectorKey = `connector${string}`

export type ConfigureChargerFormData = {
  depot: RootAPI.DepotModel | null
  manufacturer: RootAPI.ChargerManufacturerModel | null
  makeModel: RootAPI.ChargerMakeModelResponseModel | null
  chargerName: string
  chargerId: string
  maxPower?: number
  maxCurrent?: number
  voltage?: number
  autoRegisterTags: boolean
  networkConnectionMethod?: NetworkConnectionMethod | null
  networkConnectionDetails?: string
  externalId?: string
  utilityLocationId?: string
  commissioningDate?: string
  evseInstallationType?: InstallationType | null
  isAdaAccessible?: AdaAccessibleOptions | null
  latitude?: number
  longitude?: number
  [key: ConnectorKey]: ConnectorType | null
}

type Error = FetchBaseQueryError | SerializedError
type Response = RequestStatusFlags & { error?: Error; reset(): void }
type SaveFunction<T> = (data: T) => Promise<unknown>

type EditingProps = {
  editingExisting: true
  editMutation: readonly [SaveFunction<RootAPI.UpdateChargerApiArg>, Response]
  connectors?: {
    [key: string]: RootAPI.ConnectorModel
  }
}

type CreatingProps = {
  editingExisting: false
  createMutation: readonly [SaveFunction<RootAPI.CreateChargerApiArg>, Response]
}

type ConfigureChargerOverlayCommonProps = {
  showOemHelper?: boolean
  showConnectionStatus?: boolean
  formDefaults: ConfigureChargerFormData
} & (EditingProps | CreatingProps)

type ConfigureChargerOverlayContentProps =
  ConfigureChargerOverlayCommonProps & {
    closeOverlay: () => void
  }

type ConfigureChargerOverlayProps = ConfigureChargerOverlayCommonProps & {
  Trigger: ReactElement
}

// NOTE: this is a shared component which is used by EditChargerOverlay
// and CreateChargerOverlay. For usage, refer to those (this one is
// not intended to be externally exposed).
const ConfigureChargerOverlay = ({
  Trigger,
  ...props
}: ConfigureChargerOverlayProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => setIsOpen(true),
  })

  return (
    <>
      {OverlayTrigger}
      {isOpen && (
        <ConfigureChargerOverlayContent
          {...props}
          closeOverlay={() => setIsOpen(false)}
        />
      )}
    </>
  )
}

export default ConfigureChargerOverlay

const ConfigureChargerOverlayContent = (
  props: ConfigureChargerOverlayContentProps
) => {
  const {
    editingExisting,
    closeOverlay,
    showOemHelper = false,
    showConnectionStatus = false,
    formDefaults,
  } = props

  const theme = useTheme()
  const orgId = useCurrentOrgId()

  const [displayedError, setDisplayedError] = useState<Error>()
  const [currentType, setCurrentType] = useState<string>()

  const schema = useMemo(() => {
    return wrappedSchema(currentType)
  }, [currentType])

  if (editingExisting) {
    Object.keys(formDefaults).forEach((formField) => {
      if (formField.startsWith('connector')) {
        schema[formField] = yup.string().nullable().optional()
      }
    })
  }
  const methods = useForm<ConfigureChargerFormData>({
    defaultValues: formDefaults,
    mode: 'all',
    resolver: yupResolver(yup.object().shape(schema).required()),
    shouldFocusError: true,
  })
  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
    reset,
  } = methods

  const chargerId = useWatch({ control, name: 'chargerId' })
  const autoRegisterTags = useWatch({ control, name: 'autoRegisterTags' })

  const {
    getCharger: { data: charger, isLoading: isLoadingCharger },
  } = useChargerDetailsFromPoll({
    chargerId,
    disablePolling: true,
  })

  const isKnownCharger = Boolean(
    charger?.lastHeardTimestamp || charger?.lastHeartbeatTimestamp
  )

  const saveResponse = editingExisting
    ? props.editMutation[1]
    : props.createMutation[1]

  useEffect(() => {
    if (saveResponse.isSuccess) {
      if (!editingExisting) reset()
      closeOverlay()
      saveResponse.reset()
    } else if (saveResponse.isError) {
      setDisplayedError(saveResponse.error)
    }
  }, [closeOverlay, saveResponse, reset, editingExisting])

  const onSubmit = handleSubmit(
    async ({
      depot,
      makeModel,
      isAdaAccessible,
      networkConnectionMethod,
      commissioningDate,
      evseInstallationType,
      latitude,
      longitude,
      ...rest
    }) => {
      // It shouldn't be possible to submit the form without a depot, but just in case...
      if (!depot) return

      const connectors: Record<string, RootAPI.ConnectorUpdateModel> = {}
      Object.keys(rest).forEach((key) => {
        const typedKey = key as ConnectorKey
        if (key.startsWith('connector') && rest[typedKey]) {
          connectors[key.replace('connector', '')] = {
            connectorType: rest[typedKey] ?? undefined,
          }
          delete rest[typedKey]
        }
      })
      const model = {
        depotId: depot.depotId ?? '',
        // Existing chargers are saved in the org they were created in; new chargers are saved in the current org
        fleetId: charger?.fleetId ?? orgId,
        currentType: currentType ?? '',
        makeModelId: makeModel?.id ?? '',
        ...rest,

        // The `latitude` and `longitude` fields are required to be non-null by the API, but this is a preference more
        // than anything; no error is thrown if they are null. In the long-term, it would be preferable to either
        //   (a) make these fields optional in the API
        //   (b) make these fields non-nullable on the depot model
        //   (c) eliminate these fields from the charger model entirely, as they are currently redundant with depots
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        latitude: latitude ?? depot.latitude!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        longitude: longitude ?? depot.longitude!,
      }

      if (editingExisting) {
        await props.editMutation[0]({
          chargerUpdateModel: {
            ...model,
            ...{
              isAdaAccessible: isAdaAccessible ?? 'unset',
              networkConnectionMethod: networkConnectionMethod ?? 'unset',
              commissioningDate: commissioningDate
                ? dayjs(commissioningDate).toISOString()
                : BEGINING_OF_TIME,
              evseInstallationType: evseInstallationType ?? 'unset',
              connectors,
            },
          },
        })
      } else {
        await props.createMutation[0]({
          chargerCreationModel: {
            ...model,
            ...{
              isAdaAccessible:
                isAdaAccessible === 'Yes'
                  ? true
                  : isAdaAccessible === 'No'
                  ? false
                  : undefined,
              networkConnectionMethod: networkConnectionMethod ?? undefined,
              commissioningDate: commissioningDate
                ? dayjs(commissioningDate).toISOString()
                : undefined,
              evseInstallationType: evseInstallationType ?? undefined,
            },
          },
        })
      }
    }
  )

  const configureValues = useMemo(() => {
    if (editingExisting) {
      return {
        saveText: 'Save',
        subtitle: 'Adjust the charger information below.',
        title: 'Configure Charger',
        titleIcon: Settings,
      }
    } else {
      return {
        saveText: 'Create Charger',
        subtitle:
          'Provide the following information to add a new charger to your organization.',
        title: 'New Charger',
        titleIcon: Plus,
      }
    }
  }, [editingExisting])

  return (
    <OverlayDeprecated
      data-cy="configure-charger-overlay"
      isOpen
      OverlayActions={[
        <Button
          color="primary"
          disabled={!isValid || isSubmitting}
          onClick={onSubmit}
          variant="contained"
        >
          {configureValues.saveText}
        </Button>,
      ]}
      setIsOpen={closeOverlay}
      subtitle={configureValues.subtitle}
      title={configureValues.title}
      TitleIcon={configureValues.titleIcon}
    >
      <FormProvider {...methods}>
        <form>
          <Stack spacing={2} sx={{ marginY: 2 }}>
            <Stack direction="row" spacing={2}>
              <BaseChargerFields
                editingExisting={editingExisting}
                orgId={orgId}
                setCurrentType={setCurrentType}
              />

              <Grid
                container
                direction="row"
                item
                md={8}
                spacing={theme.spacing(2)}
                xs={12}
              >
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    backgroundColor: theme.palette.secondary['4p'],
                    p: 4,
                    width: '100%',
                  }}
                >
                  {showConnectionStatus && isKnownCharger && charger ? (
                    <ChargerOcppConnectionDetails charger={charger} />
                  ) : (
                    <ChargerOcppConnectionInfo chargerId={chargerId} />
                  )}
                </Stack>
              </Grid>
            </Stack>

            {showOemHelper && <OemHelperAccordion />}

            <Divider />
            <Stack direction="column">
              <Typography sx={{ pb: 2 }} variant="h6">
                Charger Options
              </Typography>
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Typography variant="body1">Free Vend Mode</Typography>
                <Tooltip
                  placement="right-start"
                  title="When activated, Free Vend Mode automatically authenticates all charging sessions."
                >
                  <Info />
                </Tooltip>
              </Stack>
              <SwitchFormField
                control={control}
                id="autoRegisterTags"
                value={autoRegisterTags}
              />
            </Stack>

            <MetadataFields
              editingExisting={editingExisting}
              formDefaults={formDefaults}
            />

            <ErrorMessageContent
              error={displayedError}
              title="Error: could not create charger."
            />
          </Stack>
        </form>
      </FormProvider>
      {showConnectionStatus && !isKnownCharger && (
        <>
          <ChargerAwaitingConnection isLoadingCharger={isLoadingCharger} />
          <Divider />
        </>
      )}
    </OverlayDeprecated>
  )
}

type ErrorMessageProps = {
  title: string
  error?: Error
}

const ErrorMessageContent = ({ title, error }: ErrorMessageProps) => {
  const theme = useTheme()

  if (!error) return null

  const isSerialized = isSerializedError(error)
  const errorCode = isSerialized ? error.code : error.status
  const errorDetails = isSerialized
    ? [error.message ?? '']
    : [...parseErrors(error)]

  return (
    <Stack>
      <Typography
        color={theme.palette.error.dark}
        sx={{ textDecoration: 'underline', pt: 2 }}
        variant="body2"
      >
        {title}{' '}
        {errorCode
          ? errorCode === 409
            ? 'Charger ID already exists'
            : `(error code ${errorCode})`
          : ''}
      </Typography>
      <ul style={{ color: theme.palette.error.dark }}>
        {errorDetails.map((detail) => (
          <li>
            <Typography color={theme.palette.error.dark} variant="body2">
              {detail}
            </Typography>
          </li>
        ))}
      </ul>
    </Stack>
  )
}

type CustomErrorObject = {
  errors: unknown
}

/** Helper functions for error management */
function isSerializedError(error: Error): error is SerializedError {
  return Object.hasOwn.call(null, error, 'name')
}

function isNonNullObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null
}

function parseErrors(error: FetchBaseQueryError): string[] {
  // Simple cases
  if (error.status === 'FETCH_ERROR' || error.status === 'PARSING_ERROR') {
    return [error.error]
  }

  // If the `data` field has an `unknown` type, it should consist of an object with an `errors` field. However, we will
  // still check for the existence of the `errors` field, just in case.
  const { data } = error
  const errors: string[] = []
  if (isNonNullObject(data) && 'errors' in data) {
    const errorsObj = (data as CustomErrorObject).errors
    if (isNonNullObject(errorsObj)) {
      Object.entries(errorsObj).forEach(([key, value]) => {
        errors.push(`${key}: ${value}`)
      })
    }
  }

  return errors
}
