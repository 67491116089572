import { AdminFeature, DetailPageHeader, Settings } from '@synop-react/common'
import { CreateOrEditVehicleOverlay } from '../CreateOrEditVehicleOverlay'
import { IconButton } from '@mui/material'
import { RootAPI } from '@synop-react/api'

export type VehicleDetailPageHeaderProps = {
  vehicleId: string
}

export function VehicleDetailPageHeader({
  vehicleId,
}: VehicleDetailPageHeaderProps) {
  const { data: vehicle, isLoading } = RootAPI.synopRootAPI.useGetVehicleQuery({
    id: vehicleId,
  })

  const { vehicleNm, modelYear, make, model, vin } = vehicle || {}

  const subtitle = [modelYear, make, model, vin].filter(Boolean).join(' • ')

  return (
    <DetailPageHeader
      breadcrumbLink="/vehicles"
      breadcrumbTitle="Vehicles"
      isLoading={isLoading}
      subtitle={subtitle}
      title={vehicleNm}
    >
      <AdminFeature>
        <CreateOrEditVehicleOverlay
          Trigger={
            <IconButton size="large">
              <Settings />
            </IconButton>
          }
          vehicleId={vehicleId}
        />
      </AdminFeature>
      {/* <Button variant="contained">Schedule Trip</Button> */}
    </DetailPageHeader>
  )
}

export default VehicleDetailPageHeader
