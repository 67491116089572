import { useCallback } from 'react'
import { useUserPrefs } from '@synop-react/api'
import dayjs from 'dayjs'

export function useUTCToLocalOffset() {
  const { tzDayjs } = useUserPrefs()
  return useCallback(
    (date: dayjs.ConfigType): number => {
      const msSinceEpoch = tzDayjs(date).unix() * 1000
      const msOffset = tzDayjs().utcOffset() * 60 * 1000
      return msSinceEpoch + msOffset
    },
    [tzDayjs]
  )
}
