import { Box, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { isEmpty } from 'lodash'

import { ChargerOrConnectorDatum, ChargerProp } from '../common'
import { ColorDot, useStatusColorMaps } from '@synop-react/common'
import { CurrentChargerStatus, CurrentConnectorStatus } from '@synop-react/api'

function ConnectorsInUse({ charger }: ChargerProp) {
  const { connectorStatus } = charger
  if (isEmpty(connectorStatus)) return null

  return (
    <Stack direction="row">
      {Object.entries(connectorStatus).map(([id, statusModel]) => (
        <ConnectorDot
          key={id}
          chargerStatus={charger.currentStatus}
          status={statusModel.status || 'Available'}
        />
      ))}
    </Stack>
  )
}

type ConnectorDotProps = {
  chargerStatus: CurrentChargerStatus
  status: CurrentConnectorStatus
}

function ConnectorDot({ chargerStatus, status }: ConnectorDotProps) {
  const colorMaps = useStatusColorMaps()
  const isOffline = chargerStatus === 'Offline'
  const backgroundColor = isOffline
    ? colorMaps.charger.Offline
    : colorMaps.connector[status]
  return (
    <Box
      sx={{
        // Add spacing between dots
        '&:not(:last-child)': {
          mr: '10px',
        },
      }}
    >
      <ColorDot color={backgroundColor} />
    </Box>
  )
}

export default {
  align: 'left',
  field: 'connectorStatus',
  headerAlign: 'left',
  headerName: 'Connectors',
  width: 150,
  sortingOrder: ['desc', 'asc'],
  renderCell: ({ row: entity }) =>
    entity.isCharger ? <ConnectorsInUse charger={entity} /> : '',
  valueGetter: ({ row }) => {
    return Object.keys(row.connectorStatus || {}).length
  },
  type: 'number',
} as GridColDef<ChargerOrConnectorDatum, number>
