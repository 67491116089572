import { Box, Stack } from '@mui/material'
import { getCustomAsset } from '../../utils'
import { useMemo } from 'react'

import SynopLogo from '../../assets/images/synop-logo-600-alpha.png'

type AuthPageLogoProps = {
  logoUrl?: string
}

export const AuthPageLogo = ({ logoUrl = SynopLogo }: AuthPageLogoProps) => {
  const customerLogoUrl = useMemo(() => getCustomAsset()?.logoUrl, [])

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
      <Box
        alt="Synop Logo"
        component="img"
        height="109px"
        src={customerLogoUrl ?? logoUrl}
        sx={{ my: 4 }}
      />
    </Stack>
  )
}
