const DEFAULT_FONT_SIZE = 12
const DEFAULT_LETTER_SPACING = 0.5
const DEFAULT_MIN_CHARS = 4
type TruncateSvgTextOptions = Partial<{
  fontSize: number
  letterSpacing: number
  minChars: number
  isBold: boolean
}>
export const truncateSvgText = (
  text = '',
  maxWidth: number,
  options: TruncateSvgTextOptions = {}
) => {
  if (!text.length) return ''

  const {
    fontSize = DEFAULT_FONT_SIZE,
    letterSpacing = DEFAULT_LETTER_SPACING,
    minChars = DEFAULT_MIN_CHARS,
    isBold = false,
  } = options

  const ellipse = '...'
  const boldSpacing = isBold ? 0.13 : 0
  const charSize = (letterSpacing + boldSpacing) * fontSize

  const textWidth = Math.ceil(charSize * text.length)
  if (textWidth <= maxWidth) return text

  const maxTextChars = Math.floor(maxWidth / charSize)
  if (maxTextChars <= minChars) return ''

  const truncatedChars = text.slice(0, maxTextChars - ellipse.length).trim()

  const elipseWidth = ellipse.length * charSize
  const truncatedTitle =
    maxWidth > elipseWidth && truncatedChars.length
      ? truncatedChars + ellipse
      : ''
  return text.length > maxTextChars ? truncatedTitle : text
}
