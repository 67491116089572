import { formatDateTime } from './formatDateTime'
import { useUserPrefs } from '@synop-react/api'

export default function useFormat() {
  const { preferredDateFormat, preferredTimeFormat, preferredTimeZone } =
    useUserPrefs()

  return {
    formatDateTime: formatDateTime({
      preferredDateFormat,
      preferredTimeFormat,
      preferredTimeZone,
    }),
  }
}
