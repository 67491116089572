import { MenuItem } from '@mui/material'
import Dropdown, { DropdownProps } from './Dropdown'

export type SimpleDropdownOption<T extends string> = {
  name: string
  id: T
}

type SimpleDropdownProps<T extends string> = Omit<
  DropdownProps<T>,
  'onChange' | 'value'
> & {
  onChange: (newValue?: T) => void
  value?: T
}

export function simpleDropdownFactory<
  T extends string,
  Option extends SimpleDropdownOption<T>
>(options: Option[]) {
  return function SimpleDropdown(props: SimpleDropdownProps<T>) {
    const { onChange, ...rest } = props
    return (
      <Dropdown
        onChange={({ target }) =>
          onChange(target ? (target.value as T) : undefined)
        }
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Dropdown>
    )
  }
}
