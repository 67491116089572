import {
  DataGridPremium,
  GridColumnVisibilityModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { isEqual } from 'lodash'
import { styled } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'

import { useCurrentUser } from '@synop-react/api'

import {
  selectAllOrderedEntities,
  useBrowserSelector,
  useEntityBrowser,
} from '../Browser/useEntityBrowser'
import { Table } from '../../Table'
import { useFirstRender, useLocalStorageState } from '../../utils'

export function EntityTable() {
  const { tableColumns, renderTableRow, visibleEntityIds } = useEntityBrowser()
  const sortedDisplayedEntities = useBrowserSelector(selectAllOrderedEntities)
  const apiRef = useGridApiRef()

  // Create a user-specific key for storing table state in LocalStorage. This allows
  // user-persisted items to not collide and remain separate when switching between accounts
  const username = useCurrentUser().user.username ?? 'unknownUser'
  const tableStateKey = [username, 'tableState'].join('-')
  const [persistedState, setPersistedState] =
    useLocalStorageState<GridInitialStatePremium>(tableStateKey)

  // Table columns need to be memoized so they will defer order to the table state and not revert order on polling
  const memoizedTableColumns = useMemo(
    () => tableColumns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const defaultHiddenColumns = useMemo(
    () =>
      (
        tableColumns as Table.ColumnSpec<GridValidRowModel>
      ).reduce<GridColumnVisibilityModel>((acc, column) => {
        const isDefaultVisible = column['isDefaultVisible'] ?? true

        if (!isDefaultVisible) {
          acc[column.field] = false
        }
        return acc
      }, {}),
    [tableColumns]
  )

  const persistTableState = useCallback(() => {
    const tableState = apiRef.current?.exportState() ?? null
    if (tableState && !isEqual(tableState, persistedState)) {
      setPersistedState(tableState)
    }
  }, [apiRef, persistedState, setPersistedState])

  // *** Subscribing to relevant Datagrid column changes to persist column state to local storage ***
  // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.

  // Persist column order when a user reorders columns
  useEffect(
    () => apiRef.current.subscribeEvent('columnOrderChange', persistTableState),
    [apiRef, persistTableState]
  )

  // Persist column visibility when a user shows / hides a column
  useEffect(
    () =>
      apiRef.current.subscribeEvent(
        'columnVisibilityModelChange',
        persistTableState
      ),
    [apiRef, persistTableState]
  )
  // *** End of Datagrid column subscriptions

  useFirstRender(() => {
    if (persistedState) apiRef.current.restoreState(persistedState)
  })

  const displayedEntities = sortedDisplayedEntities.filter((entity) => {
    return entity?.id in visibleEntityIds
  })
  const rows = displayedEntities?.map(renderTableRow)

  return (
    <StyledDataGrid
      apiRef={apiRef}
      columns={memoizedTableColumns}
      disableAggregation
      initialState={{
        columns: { columnVisibilityModel: defaultHiddenColumns },
      }}
      rows={rows}
    />
  )
}

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.primary[50],
  },
}))

export default EntityTable
