import {
  defaultEntityName,
  EventOptions,
  useUnscheduledChargeOption,
  ViewChargingSessionOverlay,
} from '@synop-react/common'
import { useDepotChargers, useOrgVehicles } from '@synop-react/api'

type UseScheduleChargeProps = {
  depotId: string
}

const useUnscheduleChargerOption = ({
  depotId,
}: UseScheduleChargeProps): Pick<EventOptions, 'AD_HOC_CHARGE'> => {
  const { orgVehicles } = useOrgVehicles()
  const { depotChargers } = useDepotChargers(depotId)

  return useUnscheduledChargeOption({
    depotId,
    subtitle: ({ assetId }) =>
      orgVehicles[assetId ?? '']?.vehicleNm ??
      defaultEntityName('Vehicle', assetId),
    renderOverlay: ({
      status,
      chargerId,
      assetId,
      eventId,
      isOpen,
      setIsOpen,
    }) => {
      const eventCharger = chargerId
        ? depotChargers.entities[chargerId]
        : undefined
      const eventVehicle = assetId ? orgVehicles[assetId] : undefined

      return status === 'COMPLETED' && eventCharger ? (
        <ViewChargingSessionOverlay
          charger={eventCharger}
          eventId={eventId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          vehicle={eventVehicle}
        />
      ) : null
    },
  })
}

export default useUnscheduleChargerOption
