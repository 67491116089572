import {
  AxisLabelFormatter,
  CreateChargingSessionOverlay,
  CreateChargingSessionOverlayProps,
  defaultEntityName,
  EventGroupingKey,
  EventOption,
  EventOptions,
  TimelineCask,
  useAvailableOption,
  useDisplayedDateWithTimeRange,
  usePredictedEventOption,
} from '@synop-react/common'
import {
  ScheduledEvent,
  useDepotChargers,
  useDepotDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useScheduledChargeOption from '../ChargingTimeline/useScheduledChargeOption'
import useUnscheduleChargerOption from '../ChargingTimeline/useUnscheduledChargerOption'

export interface DepotTimelineProps {
  depotId: string
  title?: string
  tooltip?: string
}

type CreateOverlayProps = Pick<
  CreateChargingSessionOverlayProps,
  'setIsOpen' | 'isOpen'
> &
  Partial<ScheduledEvent>

export function DepotTimeline({
  depotId,
  title = 'Site Schedule',
  tooltip = '',
}: DepotTimelineProps) {
  const navigate = useNavigate()
  const { tzDayjs } = useUserPrefs()

  const { displayedDate, setDisplayedDate, to, from } =
    useDisplayedDateWithTimeRange()

  const {
    getDepot: { data: depot },
    getScheduledChargerSessions: {
      isLoading: isLoadingSessions,
      isError: error,
      data: depotEvents = [],
    },
  } = useDepotDetailsFromPoll({
    depotId,
    from,
    to,
  })

  const { isLoading: isLoadingChargers, depotChargers } =
    useDepotChargers(depotId)

  const scheduledChargeOptions = useScheduledChargeOption({ depotId })
  const unscheduledChargeOption = useUnscheduleChargerOption({ depotId })
  const availableChargeOption = useAvailableOption({
    renderOverlay: ({
      dispenserId,
      setIsOpen,
      isOpen,
      scheduledEnd,
      chargerId = '',
    }: CreateOverlayProps) => {
      return tzDayjs(scheduledEnd).isAfter(tzDayjs()) ? (
        <CreateChargingSessionOverlay
          defaultCharger={depotChargers.entities[chargerId]}
          defaultConnector={
            dispenserId != undefined && dispenserId != null
              ? { connectorId: Number(dispenserId) }
              : undefined
          }
          defaultDepot={depot}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : null
    },
  })
  const predictedEventOption = usePredictedEventOption({
    subtitle: 'Predicted',
    renderOverlay: undefined,
  })

  const filteredEvents = useMemo(() => {
    return depotEvents.filter((event) => {
      return (
        event.dispenserId &&
        event.chargerId &&
        depotChargers.ids.includes(event.chargerId)
      )
    })
  }, [depotEvents, depotChargers])

  const isFirstSessionLoad = isLoadingSessions && error === undefined
  const isLoading = isFirstSessionLoad || isLoadingChargers

  const depotEventOptions: EventOptions = {
    ...availableChargeOption,
    ...scheduledChargeOptions,
    ...unscheduledChargeOption,
    ...predictedEventOption,
    SITE_LIMIT: {
      barColor: 'green',
      caption: { title: 'noop' },
      // tooltip: { title: 'noop', details: [{ data: 'hello' } ],
    } as EventOption,
  }

  const chargerConnectorDelimeter = '*'

  const formatChargerLabel: AxisLabelFormatter = (chargerConnectorId) => {
    const ids = chargerConnectorId?.split(chargerConnectorDelimeter)
    const chargerId = ids?.[0] ?? ''
    const connectorId = ids?.[1] ?? ''

    const chargerName =
      depotChargers.entities[chargerId]?.chargerName ||
      defaultEntityName('Charger', chargerId)
    return {
      primary: chargerName,
      secondary: `Connector ${connectorId}`,
      onClick: () => {
        navigate(`/chargers/${chargerId}`)
      },
    }
  }

  // TODO Update this with connectorId when the API types / mock data is updated @wslater
  const getConnectorKey: EventGroupingKey = (event) => {
    const { chargerId, dispenserId, id } = event
    if (chargerId && dispenserId)
      // Need to uppercase the chargerId as occasionally there is a charger id in the event that is a different case than the rest
      return `${chargerId.toUpperCase()}${chargerConnectorDelimeter}${dispenserId}`
    else return `${id}`
  }

  const scheduledConnectorIds = Object.values(depotChargers.entities).flatMap(
    ({ chargerId, connectorIds = [] }) => {
      return connectorIds.flatMap((connectorId) => {
        const ROOT_CONNECTOR = 0
        if (connectorId !== ROOT_CONNECTOR)
          // To uppercase here to match the getConnectorKey functionality
          return [
            `${chargerId.toUpperCase()}${chargerConnectorDelimeter}${connectorId}`,
          ]
        return []
      })
    }
  )

  return (
    <TimelineCask
      displayedDate={displayedDate}
      eventIds={scheduledConnectorIds}
      eventOptions={depotEventOptions}
      groupingKey={getConnectorKey}
      isLoading={isLoading}
      scheduledEvents={filteredEvents as ScheduledEvent[]}
      setDisplayedDate={setDisplayedDate}
      title={title}
      tooltip={tooltip}
      yAxisLabelFormatter={formatChargerLabel}
    />
  )
}

export default DepotTimeline
