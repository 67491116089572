import {
  attributeDurationToDayJsDuration,
  formatPower,
  useFormat,
} from '@synop-react/common'
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { ChargerPanel } from '@synop-react/depot'
import {
  CONNECTOR_STATUS_AVAILABLE,
  CONNECTOR_STATUS_CHARGING,
  CONNECTOR_STATUS_FAULTED,
  CONNECTOR_STATUS_FINISHING,
  CONNECTOR_STATUS_OFFLINE,
  CONNECTOR_STATUS_PREPARING,
  CONNECTOR_STATUS_RESERVED,
  CONNECTOR_STATUS_SUSPENDED_EV,
  CONNECTOR_STATUS_SUSPENDED_EVSE,
  CONNECTOR_STATUS_UNAVAILABLE,
  CONNECTOR_STATUS_UNKNOWN,
  CurrentConnectorStatus,
  LastKnownConnectorStatus,
  RootAPI,
  statusIsOperative,
  statusIsPreparingOrCharging,
  TimeUnits,
  useUserPrefs,
} from '@synop-react/api'
import { useMemo } from 'react'

type ConnectorRowProps = {
  connectorId: number
  currentStatus: CurrentConnectorStatus
  currentTransaction?: RootAPI.TransactionSummaryModel
  lastHeard?: Date
  lastKnownStatus?: LastKnownConnectorStatus
  isExporting?: boolean
  livePower?: number
  setSelectedConnectorId: (connectorId: number) => void
  setStartChargingSessionOverlayOpen: (open: boolean) => void
  setStopChargingSessionOverlayOpen: (open: boolean) => void
}

export const ConnectorRow = (props: ConnectorRowProps) => {
  const { palette } = useTheme()
  const { connectorId, currentStatus } = props

  const tooltipText = useMemo(() => {
    switch (currentStatus) {
      case CONNECTOR_STATUS_PREPARING:
        return 'This connector is preparing to initiate a new charging transaction.'
      case CONNECTOR_STATUS_CHARGING:
        return 'This connector is currently charging.'
      case CONNECTOR_STATUS_SUSPENDED_EV:
        return 'The charging transaction has stopped because the vehicle signaled it was not available to receive more energy.'
      case CONNECTOR_STATUS_SUSPENDED_EVSE:
        return "This connector's charging transaction has been stopped because of a restriction on the EVSE."
      case CONNECTOR_STATUS_FINISHING:
        return 'This connector is completing a transaction. It is not yet available for a new transaction.'
      case CONNECTOR_STATUS_AVAILABLE:
        return 'This connector is available for a new transaction.'
      case CONNECTOR_STATUS_RESERVED:
        return 'This connector is currently reserved. It is not available for a new transaction.'
      case CONNECTOR_STATUS_FAULTED:
        return 'This connector has faulted and is unavailable for operation.'
      case CONNECTOR_STATUS_UNAVAILABLE:
        return 'This connector is unavailable for operation.'
      case CONNECTOR_STATUS_UNKNOWN:
        return 'This connector is currently in an unknown state.'
      case CONNECTOR_STATUS_OFFLINE:
        return 'This connector is currently offline.'
    }
  }, [currentStatus])

  return (
    <ChargerPanel.Row
      EndDetails={<RightConnectorDetails {...props} />}
      StartDetails={<LeftConnectorDetails {...props} />}
      status={currentStatus}
      statusTooltip={tooltipText}
      title={`Connector ${connectorId}`}
      titleColor={palette.text.primary}
    />
  )
}

type OfflineStackProps = {
  align: 'left' | 'right'
  text: string
  title: string
}

const OfflineStack = ({ align, text, title }: OfflineStackProps) => (
  <Stack sx={{ textAlign: align }}>
    <Typography color="text.secondary" fontWeight={700} variant="caption">
      {title}:
    </Typography>
    <Typography color="text.secondary" variant="caption">
      {text}
    </Typography>
  </Stack>
)

const LeftConnectorDetails = ({
  connectorId,
  currentStatus,
  currentTransaction,
  lastHeard,
  livePower = 0,
  setSelectedConnectorId,
  setStartChargingSessionOverlayOpen,
  setStopChargingSessionOverlayOpen,
}: ConnectorRowProps) => {
  const { formatDateTime } = useFormat()
  const { tzDayjs } = useUserPrefs()

  const { data: remainingChargeTime } =
    RootAPI.useGetApiTransactionsTimeToSocQuery(
      {
        ocppTag: currentTransaction?.ocppTag as string,
        powerOffered: livePower * 1000,
        targetSoc: 95,
      },
      {
        skip: !currentTransaction?.ocppTag,
        pollingInterval: 60 * 1000,
      }
    )

  if (currentStatus === 'Offline')
    return (
      <OfflineStack
        align="left"
        text={formatDateTime(lastHeard).timeOnDate}
        title="Last Known"
      />
    )

  const shouldShowButton = statusIsOperative(currentStatus)
  const showStopButton =
    shouldShowButton && statusIsPreparingOrCharging(currentStatus)
  const actionText = showStopButton ? 'Stop' : 'Start'
  const buttonColor = showStopButton ? 'error' : 'primary'

  const handleClick = () => {
    setSelectedConnectorId(connectorId)

    if (!showStopButton) {
      setStartChargingSessionOverlayOpen(true)
      return
    }

    setStopChargingSessionOverlayOpen(true)
  }

  const { value: remainingTime, units: remainingTimeUnits } =
    remainingChargeTime || {}

  const endTime = formatDateTime(
    tzDayjs().add(
      remainingTime as number,
      attributeDurationToDayJsDuration[remainingTimeUnits as TimeUnits]
    )
  ).timeDotDate

  const remainingText = remainingTime && remainingTimeUnits ? endTime : null
  return (
    <Stack alignItems="start">
      <Typography color="text.secondary" variant="overline">
        {lastHeard ? formatDateTime(lastHeard).timeOnDate : ''}
      </Typography>

      {remainingText && (
        <Box color="text.secondary">
          <Typography variant="overline">Expected 95% SOC:</Typography>
          <Typography sx={{ fontWeight: 700, ml: '1ch' }} variant="overline">
            {remainingText}
          </Typography>
        </Box>
      )}

      {shouldShowButton && (
        <Button
          color={buttonColor}
          onClick={handleClick}
          size="small"
          variant="outlined"
        >
          {actionText}
        </Button>
      )}
    </Stack>
  )
}

const RightConnectorDetails = ({
  currentStatus,
  isExporting = false,
  lastKnownStatus,
  livePower = 0,
}: ConnectorRowProps) => {
  if (currentStatus === 'Offline')
    return (
      <OfflineStack
        align="right"
        text={lastKnownStatus ?? 'Unknown'}
        title="Last Status"
      />
    )

  let powerText = formatPower(livePower)
  if (isExporting) powerText = 'Exporting ' + powerText

  return (
    <Grid container direction="column" item>
      <Grid display="flex" item justifyContent="flex-end">
        <Typography align="right" color="text.secondary" variant="overline">
          {powerText}
        </Typography>
      </Grid>
    </Grid>
  )
}
