import { Box, Stack, Typography } from '@mui/material'
import {
  formatSocFromSourceAttributes,
  getIntFromSourceAttribute,
  ProgressPill,
} from '@synop-react/common'
import { VehicleModel } from '@synop-react/api'

import calculateSocColor from '../utils/calculators/calculateSocColor'

export type VehicleSocPillProps = Partial<Pick<VehicleModel, 'stateOfCharge'>>

export function VehicleSocPill({ stateOfCharge }: VehicleSocPillProps) {
  const soc = getIntFromSourceAttribute(stateOfCharge)
  const errorLabel =
    calculateSocColor(soc) === 'error' ? 'error.main' : 'black.main'

  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Box sx={{ minWidth: '130px' }}>
        <ProgressPill
          colorFilterFn={({ value }) => calculateSocColor(value)}
          lg
          upperBound={100}
          value={soc}
        />
      </Box>
      <Typography color={errorLabel}>
        {formatSocFromSourceAttributes(stateOfCharge)}
      </Typography>
    </Stack>
  )
}

export default VehicleSocPill
