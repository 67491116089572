import { useMemo } from 'react'

import {
  DASHBOARD_MAX_DATE_RANGE,
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
  useStatusColorMaps,
  useTimeRange,
  useValidate,
} from '@synop-react/common'
import { RootAPI } from '@synop-react/api'

import { parseStatusSummaries } from './parseStatusSummaries'
import { StatusSummaryCharts, SummaryData } from './StatusSummaryCharts'

const { useCmsTimespanGetHistoricalStatusesQuery } = RootAPI.synopRootAPI

export const HistoricalStatusSummaryCharts = () => {
  return (
    <StatusSummaryCharts
      ChargerCardProps={{ title: 'Charger Summary' }}
      ConnectorCardProps={{ title: 'Connector Summary' }}
      data={useHistoricalStatusSummaryData()}
      loadingSkeletons
    />
  )
}

function useHistoricalStatusSummaryData(): SummaryData {
  const siteId = useSiteSelector().selected?.id
  const orgId = useSelectedOrgIdOrCurrent()
  const { from, to } = useTimeRange()
  const { validateTimeRange } = useValidate()

  const isValidTimeRange = validateTimeRange(DASHBOARD_MAX_DATE_RANGE).isValid
  const { data, isLoading, isFetching } =
    useCmsTimespanGetHistoricalStatusesQuery(
      {
        organizationId: orgId,
        siteIds: siteId ? [siteId] : undefined,
        fromTs: from.toISOString(),
        toTs: to.toISOString(),
      },
      {
        skip: !orgId || !isValidTimeRange,
      }
    )

  const colorMaps = useStatusColorMaps()
  const chargers = useMemo(
    () =>
      parseStatusSummaries(
        data?.chargerStatusHistory?.statuses,
        colorMaps.charger
      ),
    [data?.chargerStatusHistory, colorMaps.charger]
  )

  const connectors = useMemo(
    () =>
      parseStatusSummaries(
        data?.connectorStatusHistory?.statuses,
        colorMaps.connector
      ),
    [data?.connectorStatusHistory, colorMaps.connector]
  )

  return {
    summaryData: { chargers, connectors },
    isLoading: isLoading || isFetching,
  }
}
