import { Button, Typography } from '@mui/material'
import { Icons, OverlayDeprecated } from '@synop-react/common'
import { LoadingButton } from '@mui/lab'
import { RootAPI } from '@synop-react/api'

const { useStopChargingTransactionMutation } = RootAPI.synopRootAPI

interface StopChargingSessionOverlayProps {
  assetId: string
  chargerId: string
  chargingSessionId: string
  connectorId: number
  depotName: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const StopChargingSessionOverlay = ({
  chargerId,
  chargingSessionId,
  isOpen,
  setIsOpen,
}: StopChargingSessionOverlayProps) => {
  const [stopChargingSession, stopChargingSessionResponse] =
    useStopChargingTransactionMutation()

  const onSubmit = async () => {
    try {
      await stopChargingSession({
        chargerId,
        chargingSessionId,
      }).unwrap()

      setIsOpen(false)
    } catch (err) {
      console.error(err)
    }
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <OverlayDeprecated
      isOpen={isOpen}
      OverlayActions={[
        <LoadingButton
          color="error"
          loading={stopChargingSessionResponse.isLoading}
          onClick={() => onSubmit()}
          variant="contained"
        >
          Stop Charging Transaction
        </LoadingButton>,
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>,
      ]}
      setIsOpen={setIsOpen}
      title="Stop Charging Transaction"
      TitleIcon={Icons.XCircle}
    >
      <Typography variant="body1">
        Are you sure you wish to stop this charging transaction?
      </Typography>
    </OverlayDeprecated>
  )
}

export default StopChargingSessionOverlay
