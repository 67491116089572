import * as yup from 'yup'
import {
  Button,
  FormHelperText,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  ChargerAutocomplete,
  ConnectorAutocomplete,
  DepotAutocomplete,
  VehicleAutocomplete,
} from '../../Autocomplete'
import {
  EventScheduler,
  EventSchedulerFields,
  freqRecurrenceTypeLookupMap,
  freqRRuleLookupMap,
  useEventSchedulerSchema,
} from '../../FormField/EventScheduler'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { FieldError, FormProvider, useForm, useWatch } from 'react-hook-form'
import { FormField, Icons, OverlayDeprecated } from '../../index'
import {
  PowerTargetDropdown,
  PowerTargetOption,
  PowerTargetType,
} from '../PowerTargetDropdown'
import { RootAPI, useCurrentOrgId, useUserPrefs } from '@synop-react/api'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs, { Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(minMax)

const MINS_PER_HOUR = 60
const MINS_PER_DAY = 24 * MINS_PER_HOUR

type ScheduleChargingSessionFormData = EventSchedulerFields & {
  vehicle: RootAPI.VehicleModel | null
  charger: RootAPI.ChargerModel | null
  connector: RootAPI.ConnectorModel | null
  depot: RootAPI.DepotModel | null
  targetSoc: number
  targetPower: number
  scheduledStart: Dayjs
  scheduledEnd: Dayjs
  powerTargetType: PowerTargetOption
}

const defaultPowerTargetOption: PowerTargetOption = {
  type: 'SOC' as const,
  label: 'SOC Target',
}

export type CreateChargingSessionOverlayProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  defaultDepot?: RootAPI.DepotModel | null
  defaultCharger?: RootAPI.ChargerModel | null
  defaultConnector?: RootAPI.ConnectorModel | null
  defaultVehicle?: RootAPI.VehicleModel | null
  defaultSoc?: number
  defaultTargetPower?: number
  defaultScheduledStart?: string
  defaultScheduledEnd?: string
}

const CreateChargingSessionOverlay = ({
  isOpen,
  setIsOpen,
  defaultDepot = null,
  defaultCharger = null,
  defaultConnector = null,
  defaultVehicle = null,
  defaultSoc = 100,
  defaultTargetPower = 0,
  defaultScheduledStart,
  defaultScheduledEnd,
}: CreateChargingSessionOverlayProps) => {
  const theme = useTheme()
  const { tzDayjs } = useUserPrefs()
  const orgId = useCurrentOrgId()
  const [errorMessage, setErrorMessage] = useState('')

  const createChargeSessionSchema = useSchema()

  const [scheduleChargingSession, scheduleChargingSessionResponse] =
    RootAPI.synopRootAPI.useCreateScheduledChargerSessionMutation()

  const start = defaultScheduledStart
    ? tzDayjs(defaultScheduledStart)
    : tzDayjs()
  const end = defaultScheduledEnd
    ? tzDayjs(defaultScheduledEnd)
    : tzDayjs().add(1, 'hour')

  const formMethods = useForm<ScheduleChargingSessionFormData>({
    defaultValues: {
      vehicle: defaultVehicle,
      charger: defaultCharger,
      connector: defaultConnector,
      depot: defaultDepot,
      scheduledStart: start,
      targetSoc: defaultSoc,
      targetPower: defaultTargetPower,
      powerTargetType: defaultPowerTargetOption,
      scheduledEnd: end,
      seriesStart: tzDayjs(defaultScheduledStart).startOf('day'),
      seriesEnd: tzDayjs(defaultScheduledEnd).startOf('day'),
      singleEventStartDate: tzDayjs(defaultScheduledStart).startOf('day'),
      singleEventEndDate: tzDayjs(defaultScheduledEnd).startOf('day'),
      frequency: { id: 1, name: 'Single Event' },
      recurringEventStartTime: tzDayjs(),
      recurringEventEndTime: tzDayjs().add(1, 'hour'),
      recurringWeekdays: ['MO'],
    },
    resolver: yupResolver(createChargeSessionSchema),
    mode: 'all',
  })
  const {
    control,
    formState: { errors, touchedFields },
    setValue,
    handleSubmit,
    clearErrors,
    watch,
  } = formMethods

  const {
    chargerId: selectedChargerId = '',
    depotId: selectedChargerDepotId = '',
  } =
    useWatch({
      control,
      name: 'charger',
    }) || {}
  const selectedRecurringEventStartTime = watch('recurringEventStartTime')
  const selectedRecurringEventEndTime = watch('recurringEventEndTime')

  const { depotId: selectedDepotId = '' } =
    useWatch({ control, name: 'depot' }) || {}

  const { chargerId: selectedConnectorChargerId = '' } =
    useWatch({
      control,
      name: 'connector',
    }) || {}

  const powerTargetType = useWatch({
    control,
    name: 'powerTargetType',
  })

  useEffect(() => {
    if (scheduleChargingSessionResponse.isSuccess) {
      setIsOpen(false)
    }
    // Handle API errors
    const getErrorMessage = (status: number) => {
      if (status === 409) {
        return 'There is already a charge event scheduled during this timeframe.'
      } else if (status === 404) {
        return 'Depot event not found.'
      } else if (status === 400) {
        return 'Invalid parameters.'
      } else {
        return 'Unspecified backend error.'
      }
    }

    if (
      scheduleChargingSessionResponse.isError &&
      'data' in scheduleChargingSessionResponse.error
    ) {
      setErrorMessage(
        getErrorMessage(
          (scheduleChargingSessionResponse.error.data as FetchBaseQueryError)
            .status as number
        )
      )
    } else {
      // Clear if nothing is in error
      setErrorMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleChargingSessionResponse, setErrorMessage])

  // Reset the Charger if the depot changes
  useEffect(() => {
    if (selectedChargerDepotId !== selectedDepotId) setValue('charger', null)
  }, [selectedDepotId, selectedChargerDepotId, setValue])

  // Reset the Connector if the charger changes
  useEffect(() => {
    if (selectedConnectorChargerId !== selectedChargerId)
      setValue('connector', null)
  }, [selectedChargerId, selectedConnectorChargerId, setValue])

  // Reset the Vehicle error if the powerTargetType changes to max power
  useEffect(() => {
    if (powerTargetType.type === 'MAX_POWER') clearErrors('vehicle')
  }, [powerTargetType, clearErrors])

  /** Returns true if the event is active at midnight */
  const isOvernightEvent = () => {
    return (
      selectedRecurringEventEndTime.get('hour') <
      selectedRecurringEventStartTime.get('hour')
    )
  }

  const onSubmit = async ({
    depot,
    charger,
    connector,
    powerTargetType: { type: selectedPowerTargetType },
    targetPower,
    targetSoc,
    vehicle,
    singleEventStartDate,
    singleEventEndDate,
    recurringEventStartTime,
    recurringEventEndTime,
    frequency,
    recurrenceName,
    recurringWeekdays,
    seriesStart,
    seriesEnd,
  }: ScheduleChargingSessionFormData) => {
    const { depotId = '' } = depot || {}
    const { chargerId = '' } = charger || {}
    const { connectorId = '' } = connector || {}
    const { id, macAddress } = vehicle || {}
    if (!connectorId) return

    const powerTargetMap: Record<PowerTargetType, Record<string, number>> = {
      MAX_POWER: { powerOffered: targetPower },
      SOC: { targetSoc },
    }

    const powerTarget = powerTargetMap[selectedPowerTargetType]

    const getEventTime = (eventDate: Dayjs, eventTime: Dayjs) => {
      return tzDayjs(eventDate)
        .set('hour', tzDayjs(eventTime).get('hour'))
        .set('minute', tzDayjs(eventTime).get('minute'))
        .toISOString()
    }

    // Get the actual weekdays via offset calculations (should be done on the backend but can't be yet)
    const weekdayOffsetLookupMap = new Map([
      ['SU', 'MO'],
      ['MO', 'TU'],
      ['TU', 'WE'],
      ['WE', 'TH'],
      ['TH', 'FR'],
      ['FR', 'SA'],
      ['SA', 'SU'],
    ])

    const actualWeekdays = recurringWeekdays?.map((day) => {
      const startTime = tzDayjs(recurringEventStartTime)

      const minsToHourStart = startTime.hour() * MINS_PER_HOUR
      const minsSinceHourStart = startTime.minute()
      const minsOffset = Math.abs(startTime.utcOffset())

      return minsToHourStart + minsSinceHourStart + minsOffset >= MINS_PER_DAY
        ? weekdayOffsetLookupMap.get(day)
        : day
    })

    // Build rrule if needed
    const FREQ = freqRRuleLookupMap.get(frequency?.name)
    const BYDAY_STRING =
      FREQ && FREQ !== 'DAILY' ? `BYDAY=${actualWeekdays.toString()};` : ''
    const untilDate = tzDayjs(seriesEnd).utc().format('YYYYMMDD')
    const UNTIL = `${untilDate}T235959Z`
    const recurrenceRule = `FREQ=${FREQ};${BYDAY_STRING}INTERVAL=1;UNTIL=${UNTIL}`

    if (FREQ) {
      const overNightEvent = isOvernightEvent()
      await scheduleChargingSession({
        chargerId,
        scheduleRequestModel: {
          assetId: id,
          depotId,
          chargerId,
          dispenserId: `${connectorId}`,
          fleetId: orgId,
          chargerTagId: macAddress,
          eventType: 'TIMED_CHARGE',
          scheduledStart: tzDayjs(recurringEventStartTime).toISOString(),
          scheduledEnd: overNightEvent
            ? recurringEventEndTime.add(1, 'day').toISOString()
            : recurringEventEndTime.toISOString(),
          eventRecurrence: {
            recurrenceRule,
            recurrenceName: recurrenceName || '',
            recurrenceType: freqRecurrenceTypeLookupMap.get(frequency?.name),
            startDate:
              tzDayjs(seriesStart).format('DD/MM/YYYY') ===
              tzDayjs().format('DD/MM/YYYY')
                ? tzDayjs().toDate().toISOString()
                : tzDayjs(seriesStart).startOf('day').toDate().toISOString(),
            validUntilDate: tzDayjs(seriesEnd)
              .endOf('day')
              .toDate()
              .toISOString(),
          },
          ...powerTarget,
        },
      })
    } else {
      await scheduleChargingSession({
        chargerId,
        scheduleRequestModel: {
          assetId: id,
          depotId,
          chargerId,
          dispenserId: `${connectorId}`,
          fleetId: orgId,
          chargerTagId: macAddress,
          eventType: 'TIMED_CHARGE',
          scheduledStart: getEventTime(
            singleEventStartDate,
            recurringEventStartTime
          ),
          scheduledEnd: getEventTime(singleEventEndDate, recurringEventEndTime),
          ...powerTarget,
        },
      })
    }
  }

  const { type: selectedPowerTargetType } = powerTargetType

  return (
    <FormProvider {...formMethods}>
      <OverlayDeprecated
        isOpen={isOpen}
        OverlayActions={[
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </Button>,
        ]}
        setIsOpen={setIsOpen}
        subtitle="Provide the following information to schedule a charging session."
        title="Schedule Charging Session"
        TitleIcon={Icons.Calendar}
      >
        <form>
          {errorMessage && (
            <FormHelperText
              sx={{ color: theme.palette.error.main, paddingBottom: 2 }}
            >
              {errorMessage}
            </FormHelperText>
          )}
          <Grid container item spacing={theme.spacing(2)}>
            <Grid container item md={6} xs={12}>
              <Grid item xs={11}>
                <VehicleAutocomplete.Select
                  control={control}
                  error={errors.vehicle as FieldError}
                  fleetId={orgId}
                  id="vehicle"
                  touchedField={Boolean(touchedFields.vehicle)}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 24,
                }}
                xs
              >
                <Tooltip title="Only vehicles with a mapped OCPP tag are available for selection here.">
                  <Icons.Info />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <DepotAutocomplete.Select
                control={control}
                error={errors.depot as FieldError}
                fleetId={orgId}
                id="depot"
                touchedField={Boolean(touchedFields.depot)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ChargerAutocomplete.Select
                control={control}
                depotId={selectedDepotId}
                error={errors.charger as FieldError}
                id="charger"
                touchedField={Boolean(touchedFields.charger)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ConnectorAutocomplete.Select
                chargerId={selectedChargerId}
                control={control}
                error={errors.connector as FieldError}
                id="connector"
                touchedField={Boolean(touchedFields.connector)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Charge Session Target</Typography>
            </Grid>

            <Grid item md={4} xs={12}>
              <PowerTargetDropdown control={control} id="powerTargetType" />
            </Grid>
            <Grid item md={4} xs={12}>
              {selectedPowerTargetType === 'SOC' && (
                <FormField.SocSlider
                  control={control}
                  error={errors.targetSoc as FieldError}
                  id="targetSoc"
                />
              )}
              {selectedPowerTargetType === 'MAX_POWER' && (
                <FormField.PowerSlider
                  control={control}
                  error={errors.targetPower as FieldError}
                  id="targetPower"
                />
              )}
            </Grid>
            <EventScheduler editMode={false} />
          </Grid>
        </form>
      </OverlayDeprecated>
    </FormProvider>
  )
}

function useSchema() {
  const eventSchedulerSchema = useEventSchedulerSchema(false)
  return yup
    .object({
      vehicle: yup.object().when('powerTargetType', {
        is: ({ type }: PowerTargetOption) => type === 'MAX_POWER',
        then: VehicleAutocomplete.Schema({ isRequired: false }),
        otherwise: VehicleAutocomplete.Schema(),
      }),
      charger: ChargerAutocomplete.Schema,
      depot: DepotAutocomplete.Schema,
      connector: ConnectorAutocomplete.Schema,
      targetSoc: yup.number().when('powerTargetType', {
        is: ({ type }: PowerTargetOption) => type === 'SOC',
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(true)
          .min(0, "Target State of Charge can't be less than 0")
          .max(100, "Target State of Charge can't be greater than 100")
          .required('Target State of Charge is required'),
      }),
      targetPower: yup.number().when('powerTargetType', {
        is: ({ type }: PowerTargetOption) => type === 'MAX_POWER',
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(true)
          .required('Max Power is required'),
      }),
      scheduledStart: yup.date().required(),
      scheduledEnd: yup
        .date()
        .min(yup.ref('scheduledStart'), "End time can't be before start time")
        .required(),
    })
    .required()
    .concat(eventSchedulerSchema)
}

export default CreateChargingSessionOverlay
