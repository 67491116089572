import { Button, ButtonGroup, MenuItem } from '@mui/material'
import { createContext, ReactNode, useContext } from 'react'
import {
  defaultRangeOptions,
  getTimeRange,
  GetTimeRangeOptions,
  RelativeTimeResolution,
  timeResolutionMap,
  timeResolutions,
} from './timeRanges'
import { Dropdown } from '../Dropdown'
import { useFirstRender, useLocalStorageState } from '../utils'
import { useState } from 'react'
import { useUserPrefs } from '@synop-react/api'
import dayjs, { Dayjs } from 'dayjs'

type TimeRangeState = {
  from: Dayjs
  to: Dayjs
  ranges: readonly RelativeTimeResolution[]
  selectedTimeResolution: RelativeTimeResolution
  setSelectedTimeResolution: (newResolution: RelativeTimeResolution) => void
  setCustomRange: ([from, to]: [from: Dayjs, to: Dayjs]) => void
  options: GetTimeRangeOptions
}

const defaultTimeRangeState: TimeRangeState = {
  from: dayjs().startOf('day'),
  to: dayjs(),
  ranges: timeResolutions,
  selectedTimeResolution: 'TODAY',
  setSelectedTimeResolution: () => ({}),
  setCustomRange: () => ({}),
  options: defaultRangeOptions,
}

const TimeRangeContext = createContext<TimeRangeState>(defaultTimeRangeState)
export const TimeRangeContextProvider = TimeRangeContext['Provider']

export type TimeRangeConfig =
  | {
      /**
       * Time Range options available in the dropdown
       */
      ranges?: readonly RelativeTimeResolution[]
      /**
       * Default Time Range to use
       * @default 'TODAY'
       */
      defaultTimeResolution?: RelativeTimeResolution
      /**
       * Default Time Range is 'Today'
       */
      options?: GetTimeRangeOptions
    }
  | undefined

export type TimeRangeProviderProps = {
  options?: GetTimeRangeOptions
  syncToLocalStorage?: boolean
  children: ReactNode
} & TimeRangeConfig

export const TimeRangeProvider = ({
  defaultTimeResolution = 'DAY',
  ranges = timeResolutions,
  options = defaultRangeOptions,
  syncToLocalStorage = false,
  children,
}: TimeRangeProviderProps) => {
  const { preferredTimeZone, tzDayjs } = useUserPrefs()
  const [selectedTimeResolution, setSelectedTimeResolution] =
    useLocalStorageState<RelativeTimeResolution>('TimeRange', {
      defaultValue: defaultTimeResolution,
      skip: !syncToLocalStorage,
    })

  const [customRange, setCustomRange] = useState<[Dayjs, Dayjs]>([
    tzDayjs().startOf('day'),
    options.useEndOfCurrentDay ? tzDayjs().endOf('day') : tzDayjs(),
  ])

  const isCustom = selectedTimeResolution === 'CUSTOM'

  const [from, to] = isCustom
    ? customRange
    : getTimeRange(selectedTimeResolution, {
        timezone: preferredTimeZone,
        ...options,
      })

  // if this is the first render of the page reset
  // the selected time range from custom to default
  useFirstRender(() => {
    if (isCustom) setSelectedTimeResolution(defaultTimeResolution)
  })

  return (
    <TimeRangeContextProvider
      value={{
        selectedTimeResolution,
        setSelectedTimeResolution,
        setCustomRange,
        ranges,
        from,
        to,
        options,
      }}
    >
      {children}
    </TimeRangeContextProvider>
  )
}

export const useTimeRange = () => useContext(TimeRangeContext)
export default useTimeRange

export const TimeRangeDropdown = () => {
  const { selectedTimeResolution, setSelectedTimeResolution, ranges } =
    useTimeRange()

  return (
    <Dropdown
      onChange={(e) =>
        setSelectedTimeResolution(e.target.value as RelativeTimeResolution)
      }
      value={selectedTimeResolution}
    >
      {ranges.map((timeResolution) => (
        <MenuItem key={timeResolution} value={timeResolution}>
          {timeResolutionMap[timeResolution].label}
        </MenuItem>
      ))}
    </Dropdown>
  )
}

export const TimeRangeButtons = () => {
  const { selectedTimeResolution, setSelectedTimeResolution, ranges } =
    useTimeRange()

  return (
    <ButtonGroup key="timeRangeButtons" color="secondary" variant="outlined">
      {ranges.map((timeResolution) => {
        const isSelected = timeResolution === selectedTimeResolution
        const color = isSelected ? 'primary' : 'secondary'
        const variant = isSelected ? 'contained' : 'outlined'
        return (
          <Button
            key={timeResolution}
            onClick={() => setSelectedTimeResolution(timeResolution)}
            value={timeResolution}
            {...{ variant, color }}
          >
            {timeResolutionMap[timeResolution].shortLabel}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
