import { Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { SettingsMenu } from '@synop-react/user'

export function SettingsPage() {
  return (
    <Grid container flexWrap="nowrap" spacing={0}>
      <Grid item sx={{ minWidth: 256 }} zeroMinWidth>
        <SettingsMenu />
      </Grid>
      <Grid item sx={{ minHeight: '500px', maxWidth: '1152px', width: '100%' }}>
        <Outlet />
      </Grid>
    </Grid>
  )
}

export default SettingsPage
