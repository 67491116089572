import { EntityMap } from '../Map'
import EntityList from '../List'
import Grid from '@mui/material/Grid'

export function EntityListMap() {
  return (
    <>
      <Grid item sm={4} sx={{ minWidth: 352 }}>
        <EntityList />
      </Grid>
      <Grid item sm={8}>
        <EntityMap />
      </Grid>
    </>
  )
}

export default EntityListMap
