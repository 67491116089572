import { Typography } from '@mui/material'

import { ChargerTableColDef, EntityProp } from '../common'
import { Table } from '@synop-react/common'
import { useRouting } from '@synop-react/common'

function Site({ entity }: EntityProp) {
  const { routes } = useRouting()
  const { isCharger } = entity
  if (!isCharger) return null
  const { siteId, siteName } = entity

  return siteId ? (
    <Table.Link href={routes.siteDetails(siteId)} weight={600}>
      {siteName}
    </Table.Link>
  ) : (
    <Typography fontWeight={600} variant="body2">
      {siteName}
    </Typography>
  )
}

export default {
  field: 'siteName',
  headerName: 'Site',
  width: 160,
  renderCell: ({ row }) => <Site entity={row} />,
} as ChargerTableColDef<'name'>
