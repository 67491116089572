import { Grid, Stack, Typography, useTheme } from '@mui/material'
import { Info, Loader } from '../../Icons'

type ChargerAwaitingConnectionProps = {
  isLoadingCharger: boolean
}

const ChargerAwaitingConnection = ({
  isLoadingCharger,
}: ChargerAwaitingConnectionProps) => {
  const { palette } = useTheme()

  return (
    <Stack direction="row" spacing={2} sx={{ p: 0 }}>
      <Grid
        container
        direction="row"
        item
        spacing={2}
        sx={{ alignItems: 'center', p: 0 }}
      >
        <Grid
          container
          direction="column"
          item
          md={4}
          mt={2}
          sx={{ alignItems: 'center' }}
        >
          {isLoadingCharger ? (
            <Loader
              fontSize="large"
              sx={{ color: palette.text.disabled, margin: '16px' }}
            />
          ) : (
            <Info
              fontSize="large"
              sx={{ color: palette.warning.main, margin: '16px' }}
            />
          )}
          <Typography variant="subtitle2">
            {isLoadingCharger ? 'Loading...' : 'Charger Awaiting Connection'}
          </Typography>
        </Grid>
        <Grid direction="row" item md={8}>
          <Typography variant="body2">
            The OCPP server is awaiting connection from the charger. Please
            ensure that the correct values are entered within your OEM Portal.
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ChargerAwaitingConnection
