import { BillingCycleSelector } from './BillingCycleSelector'
import {
  emDash,
  reduceTime,
  Table,
  TableCask,
  useFormat,
  useTableCask,
} from '@synop-react/common'
import { FormProvider } from 'react-hook-form'
import { getCSVColumns, titleCase } from './HomeChargingUsers'
import { Link, Typography } from '@mui/material'
import {
  RootAPI,
  useCurrentOrgId,
  useOrgChargers,
  useOrgCustomers,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { Tuple } from '@synop-react/types'
import { useCallback, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

const { useGetReimbursementUsageSummaryQuery } = RootAPI

export function HomeChargingUsage() {
  const { formMethods, from, to } = useTableCask()
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const { formatDateTime } = useFormat()
  const [range, setRange] = useState<Tuple<Dayjs | null>>([null, null])
  const orgId = useCurrentOrgId()

  const { data } = useGetReimbursementUsageSummaryQuery({
    billingMonth: (range[0] ?? tzDayjs()).format('YYYY-MM-DD'),
    organizationId: orgId,
  })

  const { customers: organizations } = useOrgCustomers({
    includeParentOrg: true,
  })
  const { orgVehicles } = useOrgVehicles()
  const { orgChargers } = useOrgChargers()

  const columns = useMemo<
    Table.ColumnSpec<RootAPI.ChargeRecordModelOrganizationReimbursementUser>
  >(
    () => [
      {
        flex: 0.15,
        field: 'usageSourceId',
        minWidth: 120,
        headerName: 'Transaction ID',
      },
      {
        flex: 0.15,
        field: 'assetType',
        minWidth: 100,
        headerName: 'Asset Type',
        valueGetter: ({ row: { assetType } }) => titleCase(assetType ?? ''),
      },
      {
        flex: 0.15,
        minWidth: 200,
        field: 'assetId',
        headerName: 'Asset ID',
        valueGetter: ({ row: { assetId, assetType } }) =>
          assetType === 'CHARGER'
            ? assetId ?? ''
            : assetType === 'VEHICLE'
            ? `VIN${assetId ?? ''}`
            : emDash,
      },
      {
        flex: 0.15,
        field: 'assetName',
        minWidth: 110,
        headerName: 'Asset Name',
        valueGetter: ({ row: { assetId, assetType } }) =>
          (assetType === 'CHARGER'
            ? orgChargers[assetId ?? '']?.chargerName
            : orgVehicles[assetId ?? '']?.vehicleNm) ?? '',
        renderCell: ({ row: { assetId, assetType }, value }) => {
          const link =
            (assetType === 'CHARGER' ? '/chargers/' : '/vehicles/') + assetId

          return (
            <Typography variant="body2">
              {assetId ? (
                <Link href={link} underline="none">
                  {value}
                </Link>
              ) : (
                emDash
              )}
            </Typography>
          )
        },
      },
      {
        flex: 0.15,
        field: 'payeeName',
        minWidth: 110,
        headerName: 'Payee Name',
        valueGetter: ({ row: { payee } }) =>
          payee && `${payee.firstNm} ${payee.lastNm}`,
      },
      {
        flex: 0.15,
        field: 'email',
        minWidth: 110,
        headerName: 'Payee Email',
        valueGetter: ({ row: { payee } }) => payee?.email ?? '',
      },
      {
        flex: 0.15,
        minWidth: 80,
        field: 'appliedRate',
        headerName: 'Rate',
        renderCell: ({ row }) => (
          <Typography sx={{ color: 'text.primary' }} variant="body2">
            ${row.appliedRate} kWh
          </Typography>
        ),
      },
      {
        flex: 0.15,
        minWidth: 60,
        field: 'chargeCost',
        headerName: 'Cost',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'externalId',
        headerName: 'External ID',
        valueGetter: ({ row: { payee } }) => payee?.externalId ?? '',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'address',
        headerName: 'Address',
        valueGetter: ({ row: { payee } }) => payee?.address ?? '',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'organizationId',
        headerName: 'Organization',
        valueGetter: ({ row: { organizationId } }) =>
          organizations[organizationId ?? '']?.organizationNm ?? emDash,
      },
      {
        flex: 0.15,
        minWidth: 130,
        headerName: 'Start Date/Time',
        field: 'usageStart',
        valueGetter: ({ row: { usageStart } }) =>
          formatDateTime(usageStart).dateDotTime,
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'End Date/Time',
        field: 'usageEnd',
        valueGetter: ({ row: { usageEnd } }) =>
          formatDateTime(usageEnd).dateDotTime,
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Duration',
        field: 'duration',
        valueGetter: ({ row: { usageStart, usageEnd } }) => {
          const duration = dayjs(usageEnd).diff(dayjs(usageStart), 'second')
          const durationShow =
            duration || duration === 0
              ? reduceTime(duration)
              : { value: emDash, suffix: '' }
          return `${durationShow.value} ${durationShow.suffix}`
        },
      },
      {
        flex: 0.15,
        field: 'usage',
        minWidth: 130,
        headerName: 'Energy Imported',
        renderCell: (params) => (
          <Typography sx={{ color: 'text.primary' }} variant="body2">
            {params.row.usage ? `${params.row.usage} kWh` : ''}
          </Typography>
        ),
      },
    ],
    [formatDateTime, orgChargers, orgVehicles, organizations]
  )

  const handleRangeChange = useCallback(
    (range: [Dayjs | null, Dayjs | null]) => {
      setRange(range)
    },
    []
  )

  const csvColumns = useMemo(() => getCSVColumns(columns), [columns])

  const downloadTitle = useMemo(
    () =>
      `Home_Charging_Usage_${(range[0] ?? tzDayjs(from)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}_${(range[1] ?? tzDayjs(to)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}`,
    [from, preferredDateFormat, range, to, tzDayjs]
  )

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        columns={columns}
        csvColumns={csvColumns}
        downloadable
        downloadTitle={downloadTitle}
        getRowId={(row) => row.chargeRecordId ?? ''}
        initialState={{
          columns: {
            columnVisibilityModel: {
              assetId: false,
              assetName: false,
              email: false,
              externalId: false,
              duration: false,
            },
          },
        }}
        leftOtherActions={
          <BillingCycleSelector
            handleRangeChange={handleRangeChange}
            optionsToHide={['View By Year']}
          />
        }
        searchable={true}
        tableData={data ? data : []}
        title={''}
      />
    </FormProvider>
  )
}

export default HomeChargingUsage
