import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { DatePicker } from '../FormField'
import { Stack, Typography } from '@mui/material'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export type DateRangeForm = {
  from: Dayjs
  to: Dayjs
}

export function DateRange() {
  //TODO: Prevent fromDate from being greater than the toDate and vice versa
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '300px',
      }}
    >
      <DatePicker id="from" />
      <Typography>to</Typography>
      <DatePicker id="to" />
    </Stack>
  )
}

export default DateRange
