import { createContext, useContext } from 'react'
import { Dayjs } from 'dayjs'

import {
  defaultTimelineReducerState,
  TimelineState as TimelineReducerState,
} from './useTimelineReducer'
import { EventOptions } from './Provider'
import { GenericEvent } from '@synop-react/api'
import { TimelineWindow } from './useTimelineReducer/useTimelineReducer'
import { TooltipControls } from '../Tooltip/TimelineTooltip'

export type TimelineSelector<R> = (state: TimelineState) => R
export type TimelineState = TimelineReducerState
export type TimelineContextState = TimelineReducerState & {
  tooltip: TooltipControls
  hideCaptions: boolean
  selectState: <R>(selector: TimelineSelector<R>) => R
  eventOptions: EventOptions
  setDisplayedDate: (displayedDate: Dayjs) => void
  setInnerWindow: (window: TimelineWindow) => void
  displayEventOverlay: (event: GenericEvent) => void
  closeEventOverlay: () => void
}
const defaultContext = Object.assign({}, defaultTimelineReducerState, {
  selectState: <R>() => ({} as R),
  hideCaptions: false,
  tooltip: {} as TooltipControls,
  eventOptions: {} as EventOptions,
  setDisplayedDate: () => ({}),
  setInnerWindow: () => ({}),
  displayEventOverlay: () => ({}),
  closeEventOverlay: () => ({}),
})

const TimelineContext = createContext<TimelineContextState>(defaultContext)
export const TimelineContextProvider = TimelineContext['Provider']
export const TimelineConsumer = TimelineContext['Consumer']

export function useTimeline() {
  return useContext(TimelineContext)
}

export function useTimelineSelector<R>(selector: TimelineSelector<R>) {
  const { selectState } = useContext(TimelineContext)
  return selectState<R>(selector)
}

export default useTimeline
