import { Button, IconButton, Link, Typography, useTheme } from '@mui/material'
import {
  Cask,
  Edit3,
  formatPower,
  LoadingMessage,
  PlusCircle,
  Table,
} from '@synop-react/common'
import { CreateOrEditDepotOverlay } from '../CreateOrEditDepotOverlay'
import {
  parseToEntities,
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
} from '@synop-react/api'
import { useMemo } from 'react'

const {
  useGetDepotsQuery,
  useGetOrganizationQuery,
  useGetOrganizationCustomersQuery,
} = RootAPI.synopRootAPI

const DepotsSettings = () => {
  const theme = useTheme()
  const { synopUser, isAdmin } = useCurrentUser()

  const orgId = useCurrentOrgId()
  const { data: org } = useGetOrganizationQuery({ id: orgId }, { skip: !orgId })
  const { data: allOrgs } = useGetOrganizationCustomersQuery(
    { id: orgId },
    { skip: !orgId }
  )
  const idOrgs = useMemo(() => {
    if (!org || !allOrgs) return {}
    return parseToEntities(allOrgs.concat([org]), 'id').entities
  }, [org, allOrgs])

  const { data: depotsResponse, isLoading: isDepotsLoading } =
    useGetDepotsQuery(
      {
        fleetId: orgId as string,
        count: 500,
      },
      {
        skip: !orgId,
      }
    )

  const enrichedDepots = useMemo(() => {
    if (!depotsResponse?.items) return []
    return depotsResponse.items
      .map((depot) => {
        const orgName = idOrgs[depot.fleetId || '']?.organizationNm || '-'
        return { ...depot, orgName }
      })
      .filter((depot) => {
        return !(!isAdmin && !synopUser?.sites?.includes(depot.depotId))
      })
  }, [depotsResponse, idOrgs, isAdmin, synopUser])

  const columns: Table.ColumnSpec<RootAPI.DepotModel> = [
    {
      field: 'depotNm',
      headerName: 'Name',
      flex: 1,
      renderCell: (cell) => {
        const { value, row } = cell
        const { depotId } = row
        return (
          <Link
            href={`/sites/${depotId}`}
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              color: theme.palette.primary.main,
            }}
          >
            {value}
          </Link>
        )
      },
    },
    {
      field: 'orgName',
      headerName: 'Organization',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      valueFormatter: ({ value }) => value || '–',
    },
    {
      field: 'numChargers',
      headerName: 'Chargers',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      flex: 1,
    },
    {
      field: 'powerCeiling',
      headerName: 'Site Limit',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      type: 'number',
      valueFormatter: ({ value }) => formatPower(value),
    },
  ]

  if (isAdmin) {
    columns.push({
      field: 'edit',
      headerName: '',
      disableColumnMenu: true,
      disableReorder: true,
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 0.5,

      renderCell: ({ row: { depotId } }) => (
        <CreateOrEditDepotOverlay
          depotId={depotId}
          Trigger={
            <IconButton>
              <Edit3 />
            </IconButton>
          }
        />
      ),
    })
  }

  return (
    <>
      <Cask
        Actions={
          isAdmin ? (
            <CreateOrEditDepotOverlay
              organizationId={orgId}
              Trigger={
                <Button startIcon={<PlusCircle />} variant="outlined">
                  New Site
                </Button>
              }
            />
          ) : (
            <></>
          )
        }
        title="Sites"
      >
        <Typography variant="body1">
          {isAdmin
            ? 'Add or adjust the sites of your organization'
            : 'View the sites of your organization'}
          .
        </Typography>
        {isDepotsLoading ? (
          <LoadingMessage />
        ) : (
          <Table.ClientSide
            columns={columns}
            getRowId={(row) => row.depotId}
            height="700px"
            initialSortColumn="depotNm"
            rowCount={enrichedDepots.length}
            tableData={enrichedDepots}
          />
        )}
      </Cask>
    </>
  )
}

export default DepotsSettings
