import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'
import { useUserPrefs } from '@synop-react/api'

export const useDisplayedDateWithTimeRange = (rangeDays = 7) => {
  const { tzDayjs } = useUserPrefs()
  const [displayedDate, setDisplayedDate] = useState<Dayjs>(
    tzDayjs().startOf('day')
  )

  const { to, from } = useMemo(() => {
    return {
      to: displayedDate.add(rangeDays, 'day'),
      from: displayedDate.subtract(rangeDays, 'day'),
    }
  }, [displayedDate, rangeDays])

  return {
    displayedDate,
    setDisplayedDate,
    to,
    from,
  }
}
