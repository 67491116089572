import * as yup from 'yup'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { FieldError, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'

import {
  ChargerAutocomplete,
  ConnectorAutocomplete,
  DepotAutocomplete,
  VehicleAutocomplete,
} from '../../Autocomplete'
import {
  EventScheduler,
  EventSchedulerFields,
  freqLookupToDropdownMap,
  RecurranceDropdownType,
  useEventSchedulerSchema,
} from '../../FormField/EventScheduler'
import { FormField, Icons, OverlayDeprecated } from '../..'
import {
  PowerTargetDropdown,
  PowerTargetOption,
  PowerTargetType,
} from '../PowerTargetDropdown'
import { RootAPI, useCurrentOrgId, useUserPrefs } from '@synop-react/api'

type ScheduleChargingSessionFormData = EventSchedulerFields & {
  vehicle: RootAPI.VehicleModel | null
  charger: RootAPI.ChargerModel | null
  connector: RootAPI.ConnectorModel | null
  depot: RootAPI.DepotModel | null
  targetSoc?: number | null
  targetPower?: number | null
  scheduledStart: string
  scheduledEnd: string
  powerTargetType: PowerTargetOption
}

export type EditChargingSessionOverlayProps = {
  eventId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  defaultDepot?: RootAPI.DepotModel
  defaultCharger?: RootAPI.ChargerModel
  defaultConnector?: RootAPI.ConnectorModel
  defaultVehicle?: RootAPI.VehicleModel
  defaultFrequency?: RecurranceDropdownType
  defaultSeriesStart?: string
  defaultSeriesEnd?: string
  defaultSoc?: number | null
  defaultTargetPower?: number | null
  defaultScheduledStart?: string
  defaultScheduledEnd?: string
  eventRecurrence?: RootAPI.EventRecurrenceModel | null
}

const EditChargingSessionOverlay = ({
  eventId,
  isOpen,
  setIsOpen,
  defaultDepot,
  defaultCharger,
  defaultConnector,
  defaultVehicle,
  defaultSoc = null,
  defaultTargetPower = null,
  defaultScheduledStart: maybeDefaultScheduledStart,
  defaultScheduledEnd: maybeDefaultScheduledEnd,
  eventRecurrence = null,
}: EditChargingSessionOverlayProps) => {
  const theme = useTheme()
  const { tzDayjs } = useUserPrefs()
  const orgId = useCurrentOrgId()
  const [optionsOverlayOpen, setOptionsOverlayOpen] = useState(true)
  const [editRecurrence, setEditRecurrence] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // Determine if this is editing a recurring event and need to give option to users
  if (optionsOverlayOpen && !eventId) setOptionsOverlayOpen(false)
  if (optionsOverlayOpen && eventId && !eventRecurrence)
    setOptionsOverlayOpen(false)

  const editChargeSessionSchema = useSchema(
    eventId !== undefined &&
      eventId !== '' &&
      maybeDefaultScheduledStart != undefined &&
      tzDayjs().isAfter(tzDayjs(maybeDefaultScheduledStart))
  )

  const [updateChargingSession, updateChargingSessionResponse] =
    RootAPI.synopRootAPI.useUpdateScheduledChargerSessionMutation()

  const [deleteChargingSession, deleteChargingSessionResponse] =
    RootAPI.synopRootAPI.useCancelScheduledChargerSessionMutation()

  const { defaultScheduledEnd, defaultScheduledStart } = useMemo(() => {
    return {
      defaultScheduledStart:
        maybeDefaultScheduledStart || tzDayjs().format('YYYY-MM-DDTHH:mm:ss'),
      defaultScheduledEnd:
        maybeDefaultScheduledEnd ||
        tzDayjs().add(1, 'hour').format('YYYY-MM-DDTHH:mm:ss'),
    }
  }, [maybeDefaultScheduledEnd, maybeDefaultScheduledStart, tzDayjs])

  // Extract data for setting up form defaults
  const frequencyType = freqLookupToDropdownMap.get(
    eventRecurrence?.recurrenceType ?? ''
  )

  // Get the series or event start depending on frequency type
  const seriesStart = eventRecurrence?.startDate ?? defaultScheduledStart
  const seriesEnd = eventRecurrence?.validUntilDate ?? defaultScheduledEnd
  const formMethods = useForm<ScheduleChargingSessionFormData>({
    defaultValues: {
      vehicle: defaultVehicle || null,
      charger: defaultCharger || null,
      connector: defaultConnector || null,
      depot: defaultDepot || null,
      scheduledStart: defaultScheduledStart,
      targetSoc: defaultSoc,
      targetPower: defaultTargetPower,
      scheduledEnd: defaultScheduledEnd,
      powerTargetType: defaultSoc
        ? {
            type: 'SOC' as const,
            label: 'SOC Target',
          }
        : {
            type: 'MAX_POWER' as const,
            label: 'Max Power',
          },
      seriesStart: tzDayjs(seriesStart).startOf('day'),
      seriesEnd: tzDayjs(seriesEnd).startOf('day'),
      singleEventStartDate: tzDayjs(defaultScheduledStart).startOf('day'),
      singleEventEndDate: tzDayjs(defaultScheduledEnd).startOf('day'),
      frequency: { name: frequencyType },
      recurringEventStartTime: tzDayjs(defaultScheduledStart),
      recurringEventEndTime: tzDayjs(defaultScheduledEnd),
      recurringWeekdays: ['MO'],
      recurrenceName: eventRecurrence?.recurrenceName,
    },
    resolver: yupResolver(editChargeSessionSchema),
  })
  const {
    control,
    formState: { errors, touchedFields },
    setValue,
    handleSubmit,
    watch,
  } = formMethods

  const { chargerId: selectedChargerId = '' } =
    useWatch({
      control,
      name: 'charger',
    }) || {}
  const { depotId: selectedDepotId = '' } =
    useWatch({ control, name: 'depot' }) || {}
  const selectedRecurringEventStartTime = watch('recurringEventStartTime')
  const selectedRecurringEventEndTime = watch('recurringEventEndTime')

  const powerTargetType = useWatch({
    control,
    name: 'powerTargetType',
  })

  useEffect(() => {
    if (
      updateChargingSessionResponse.isSuccess ||
      deleteChargingSessionResponse.isSuccess
    ) {
      setIsOpen(false)
    }
    // Handle API errors
    const getErrorMessage = (status: number) => {
      if (status === 409) {
        return 'There is already a charge event scheduled during this timeframe.'
      } else if (status === 404) {
        return 'Depot event not found.'
      } else if (status === 400) {
        return 'Invalid parameters.'
      } else {
        return 'Unspecified backend error.'
      }
    }

    if (
      updateChargingSessionResponse.isError &&
      'data' in updateChargingSessionResponse.error
    ) {
      setErrorMessage(
        getErrorMessage(
          (updateChargingSessionResponse.error.data as FetchBaseQueryError)
            .status as number
        )
      )
    } else if (
      deleteChargingSessionResponse.isError &&
      'data' in deleteChargingSessionResponse.error
    ) {
      setErrorMessage(
        getErrorMessage(
          (deleteChargingSessionResponse.error.data as FetchBaseQueryError)
            .status as number
        )
      )
    } else {
      // Clear if nothing is in error
      setErrorMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateChargingSessionResponse,
    deleteChargingSessionResponse,
    setErrorMessage,
  ])

  // Reset the Charger if the depot changes
  useEffect(() => {
    if (selectedDepotId !== defaultDepot?.depotId) setValue('charger', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepotId])

  // Reset the Connector if the charger changes
  useEffect(() => {
    if (selectedChargerId !== defaultCharger?.chargerId)
      setValue('connector', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChargerId])

  /** Returns true if the event is active at midnight */
  const isOvernightEvent = () => {
    return (
      selectedRecurringEventEndTime.get('hour') <
      selectedRecurringEventStartTime.get('hour')
    )
  }

  const onSaveSession = async ({
    depot,
    charger,
    connector,
    powerTargetType: { type: selectedPowerTargetType },
    targetPower,
    targetSoc,
    vehicle,
    ...rest
  }: ScheduleChargingSessionFormData) => {
    const { chargerId = '' } = charger || {}
    const {
      recurringEventStartTime,
      recurringEventEndTime,
      singleEventStartDate,
      singleEventEndDate,
    } = rest
    const overNightEvent = isOvernightEvent()

    const powerTargetMap: Record<PowerTargetType, Record<string, number>> = {
      MAX_POWER: { powerOffered: targetPower || 0 },
      SOC: { targetSoc: targetSoc || 100 },
    }

    const getEventTime = (eventDate: Dayjs, eventTime: Dayjs) => {
      return tzDayjs(eventDate)
        .set('hour', tzDayjs(eventTime).get('hour'))
        .set('minute', tzDayjs(eventTime).get('minute'))
        .toISOString()
    }

    const powerTarget = powerTargetMap[selectedPowerTargetType]
    if (editRecurrence) {
      await updateChargingSession({
        eventId,
        chargerId,
        isRecurrence: editRecurrence,
        chargerEventUpdateModel: {
          eventId,
          scheduledStart: isActiveSession
            ? undefined
            : tzDayjs(recurringEventStartTime).toISOString(),
          scheduledEnd: overNightEvent
            ? recurringEventEndTime.add(1, 'day').toISOString()
            : recurringEventEndTime.toISOString(),
          ...powerTarget,
        },
      })
    } else {
      await updateChargingSession({
        eventId,
        chargerId,
        isRecurrence: editRecurrence,
        chargerEventUpdateModel: {
          eventId,
          scheduledStart: isActiveSession
            ? undefined
            : getEventTime(
                tzDayjs(singleEventStartDate),
                recurringEventStartTime
              ),
          scheduledEnd: getEventTime(
            tzDayjs(singleEventEndDate),
            recurringEventEndTime
          ),
          ...powerTarget,
        },
      })
    }
  }

  const onCancelSession = async () => {
    const { chargerId = '' } = defaultCharger || {}
    await updateChargingSession({
      eventId,
      chargerId,
      chargerEventUpdateModel: {
        eventId,
        status: 'CANCELLED',
      },
    })
    // Need to then delete the recurrence afterwards
    deleteChargingSession({
      isRecurrence: editRecurrence,
      eventId,
      chargerId,
    })
  }

  const onDeleteSession = () => {
    const { chargerId = '' } = defaultCharger || {}
    deleteChargingSession({
      isRecurrence: editRecurrence,
      eventId,
      chargerId,
    })
  }

  dayjs.extend(minMax)
  dayjs.extend(isBetween)

  const isActiveSession = tzDayjs().isBetween(
    defaultScheduledStart,
    defaultScheduledEnd
  )
  const { type: selectedPowerTargetType } = powerTargetType

  if (optionsOverlayOpen) {
    return (
      <OverlayDeprecated
        isOpen={isOpen}
        OverlayActions={[
          <Button
            color="primary"
            onClick={() => setOptionsOverlayOpen(false)}
            variant="contained"
          >
            EDIT
          </Button>,
          <Button
            color="error"
            onClick={isActiveSession ? onCancelSession : onDeleteSession}
            variant="outlined"
          >
            Cancel charge event
          </Button>,
        ]}
        setIsOpen={setIsOpen}
        subtitle="Please select which events you wish to edit."
        title={'Edit Repeating Event'}
        TitleIcon={Icons.Edit3}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={!editRecurrence}
              onChange={() => {
                if (editRecurrence) {
                  setEditRecurrence(!editRecurrence)
                }
              }}
            />
          }
          label="This event"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editRecurrence}
              onChange={() => {
                if (!editRecurrence) {
                  setEditRecurrence(!editRecurrence)
                }
              }}
            />
          }
          label="This event and following events"
        />
      </OverlayDeprecated>
    )
  } else {
    return (
      <FormProvider {...formMethods}>
        <OverlayDeprecated
          isOpen={isOpen}
          OverlayActions={[
            <Button
              color="primary"
              onClick={handleSubmit(onSaveSession)}
              variant="contained"
            >
              SAVE
            </Button>,
            <Button
              color="error"
              onClick={isActiveSession ? onCancelSession : onDeleteSession}
              variant="outlined"
            >
              {isActiveSession ? 'CANCEL' : 'DELETE'} CHARGING SESSION
            </Button>,
          ]}
          setIsOpen={setIsOpen}
          subtitle="Adjust the scheduled charging session below."
          title="Edit Charging Session"
          TitleIcon={Icons.Calendar}
        >
          <form>
            {errorMessage && (
              <FormHelperText
                sx={{ color: theme.palette.error.main, paddingBottom: 2 }}
              >
                {errorMessage}
              </FormHelperText>
            )}
            <Grid container direction="row" item spacing={theme.spacing(2)}>
              <Grid item md={6} xs={12}>
                <VehicleAutocomplete.Select
                  control={control}
                  disabled
                  error={errors.vehicle as FieldError}
                  fleetId={orgId}
                  id="vehicle"
                  touchedField={Boolean(touchedFields.vehicle)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DepotAutocomplete.Select
                  control={control}
                  disabled
                  error={errors.depot as FieldError}
                  fleetId={orgId}
                  id="depot"
                  touchedField={Boolean(touchedFields.depot)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ChargerAutocomplete.Select
                  control={control}
                  depotId={selectedDepotId}
                  disabled
                  error={errors.charger as FieldError}
                  id="charger"
                  touchedField={Boolean(touchedFields.charger)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ConnectorAutocomplete.Select
                  chargerId={selectedChargerId}
                  control={control}
                  disabled
                  error={errors.connector as FieldError}
                  id="connector"
                  touchedField={Boolean(touchedFields.connector)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Charge Session Target
                </Typography>
              </Grid>

              <Grid item md={3} xs={12}>
                <PowerTargetDropdown
                  control={control}
                  disabled
                  id="powerTargetType"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {selectedPowerTargetType === 'SOC' && (
                  <FormField.SocSlider
                    control={control}
                    error={errors.targetSoc as FieldError}
                    id="targetSoc"
                  />
                )}
                {selectedPowerTargetType === 'MAX_POWER' && (
                  <FormField.PowerSlider
                    control={control}
                    error={errors.targetPower as FieldError}
                    id="targetPower"
                  />
                )}
              </Grid>
              <EventScheduler
                activeEvent={isActiveSession}
                editMode={true}
                editRecurrence={editRecurrence}
                readonlyRRuleForEdit={eventRecurrence?.recurrenceRule}
              />
            </Grid>
          </form>
        </OverlayDeprecated>
      </FormProvider>
    )
  }
}

function useSchema(isActiveEvent: boolean) {
  const eventSchedulerSchema = useEventSchedulerSchema(isActiveEvent)
  return yup
    .object({
      vehicle: yup.object().when('powerTargetType', {
        is: ({ type }: PowerTargetOption) => type === 'MAX_POWER',
        then: VehicleAutocomplete.Schema({ isRequired: false }),
        otherwise: VehicleAutocomplete.Schema(),
      }),
      charger: ChargerAutocomplete.Schema,
      depot: DepotAutocomplete.Schema,
      connector: ConnectorAutocomplete.Schema,
      targetSoc: yup
        .number()
        .nullable()
        .when('powerTargetType', {
          is: ({ type }: PowerTargetOption) => type === 'SOC',
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable(true)
            .min(0, "Target State of Charge can't be less than 0")
            .max(100, "Target State of Charge can't be greater than 100")
            .required('Target State of Charge is required'),
        }),
      targetPower: yup
        .number()
        .nullable()
        .when('powerTargetType', {
          is: ({ type }: PowerTargetOption) => type === 'MAX_POWER',
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable(true)
            .required('Max Power is required'),
        }),
      scheduledStart: yup.date().required(),
      scheduledEnd: yup
        .date()
        .min(yup.ref('scheduledStart'), "End time can't be before start time")
        .required(),
    })
    .required()
    .concat(eventSchedulerSchema)
}

export default EditChargingSessionOverlay
