import * as yup from 'yup'
import { Dayjs } from 'dayjs'
import { FieldError, useFormContext } from 'react-hook-form'
import { FormField } from '../index'
import { Grid, Tooltip, Typography } from '@mui/material'
import { Info } from '../Icons/index'
import { isEqual } from 'lodash'
import { RecurrenceTypeAutocomplete } from '../Autocomplete'
import { useEffect } from 'react'
import { usePreviousValue } from '../utils/hooks'
import { useUserPrefs } from '@synop-react/api'

export type RecurrenceRRuleTypes = 'DAILY' | 'WEEKLY' | 'WEEKDAYS' | 'CUSTOM'
export type RecurranceDropdownType =
  | 'Single Event'
  | 'Daily Repeating'
  | 'Weekly Repeating'
  | 'Weekdays Repeating'
  | 'Custom Repeating'

export const freqLookupToDropdownMap = new Map<string, RecurranceDropdownType>([
  ['SINGLE', 'Single Event'],
  ['DAILY', 'Daily Repeating'],
  ['WEEKLY', 'Weekly Repeating'],
  ['WEEKDAYS', 'Weekdays Repeating'],
  ['CUSTOM', 'Custom Repeating'],
])

// For generating RRULES
export const freqRRuleLookupMap = new Map<string, RecurrenceRRuleTypes>([
  ['Daily Repeating', 'DAILY'],
  ['Weekly Repeating', 'WEEKLY'],
  ['Weekdays Repeating', 'WEEKLY'],
  ['Custom Repeating', 'WEEKLY'],
])

export const freqRecurrenceTypeLookupMap = new Map<
  string,
  RecurrenceRRuleTypes
>([
  ['Daily Repeating', 'DAILY'],
  ['Weekly Repeating', 'WEEKLY'],
  ['Weekdays Repeating', 'WEEKDAYS'],
  ['Custom Repeating', 'CUSTOM'],
])

export type EventSchedulerFields = {
  frequency: RecurrenceTypeAutocomplete.RecurrenceTypeOption
  singleEventStartDate: Dayjs
  singleEventEndDate: Dayjs
  seriesStart: Dayjs
  seriesEnd: Dayjs
  recurringEventStartTime: Dayjs
  recurringEventEndTime: Dayjs
  recurringWeekdays: string[]
  recurrenceName: string
}

type EventSchedulerProps = {
  label?: string
  initialRecurrenceType?: RecurranceDropdownType
  editMode?: boolean
  editRecurrence?: boolean
  // Provide `activeEvent` if the event is currently ongoing and we should disable start time editing
  activeEvent?: boolean
  // Provide `readonlyRRuleForEdit` if edit for weekly and custom to parse out days (not in api response yet)
  readonlyRRuleForEdit?: string
}

export function useEventSchedulerSchema(isActiveEvent: boolean) {
  const { tzDayjs } = useUserPrefs()
  return yup.object({
    frequency: RecurrenceTypeAutocomplete.Schema, // Needs to be done before all date validation as they rely on this field
    seriesEnd: yup
      .date()
      .typeError('Please enter a valid end date.')
      .test(
        'is-eventstartDate-after-start-date',
        'An event recurrence should be at least one day in length',
        function (value, ctx) {
          // Only check this if event is recurring
          if (ctx.parent.frequency?.name !== 'Single Event') {
            return tzDayjs(value)
              .startOf('day')
              .isSameOrAfter(
                tzDayjs(ctx.parent.seriesStart).startOf('day').add(1, 'day')
              )
          }
          return true
        }
      )
      .test(
        'is-less-than-1-year',
        'Please ensure your event series is no longer than one year.',
        function (value) {
          if (tzDayjs(value) > tzDayjs().add(1, 'year')) {
            return false
          }
          return true
        }
      )
      .required(),
    singleEventEndDate: yup
      .date()
      .typeError('Please enter a valid end time.')
      .test(
        'is-singleEventEndDate-after-start-date',
        'Event end date must be the same as or after event start date.',
        function (value, ctx) {
          // Only check this if event is single
          if (ctx.parent.frequency?.name == 'Single Event') {
            return tzDayjs(value)
              .startOf('day')
              .isSameOrAfter(
                tzDayjs(ctx.parent.singleEventStartDate).startOf('day')
              )
          }
          return true
        }
      )
      .test(
        'is-singleEventEndTime-after-start-time',
        'For single events, end time must be after start time.',
        function (value, ctx) {
          // Only check this if event is single
          if (ctx.parent.frequency?.name == 'Single Event') {
            const eventStartTime = tzDayjs(ctx.parent.singleEventStartDate)
              .set('hour', tzDayjs(ctx.parent.recurringEventStartTime).hour())
              .set(
                'minute',
                tzDayjs(ctx.parent.recurringEventStartTime).minute()
              )
            const eventEndTime = tzDayjs(value)
              .set('hour', tzDayjs(ctx.parent.recurringEventEndTime).hour())
              .set('minute', tzDayjs(ctx.parent.recurringEventEndTime).minute())

            return eventEndTime.isAfter(eventStartTime)
          }
          return true
        }
      )
      .test(
        'is-less-than-1-year',
        'Please ensure your single event is no longer than one year.',
        function (value) {
          if (tzDayjs(value) > tzDayjs().add(1, 'year')) {
            return false
          }
          return true
        }
      )
      .required(),
    recurringEventStartTime: yup
      .date()
      .typeError('Please enter a valid start time.')
      .test(
        'is-singleEventStartTime-before-now',
        'Event start time cannot be in the past.',
        function (value, ctx) {
          // Only check this if event is single
          if (ctx.parent.frequency?.name == 'Single Event' && !isActiveEvent) {
            return tzDayjs(ctx.parent.singleEventStartDate)
              .set('hour', tzDayjs(value).hour())
              .set('minute', tzDayjs(value).minute())
              .startOf('minute')
              .isSameOrAfter(tzDayjs().subtract(3, 'minutes'))
          }
          return true
        }
      )
      .required(),
  })
}

const MINS_PER_HOUR = 60
const MINS_PER_DAY = MINS_PER_HOUR * 24

export const EventScheduler = ({
  label = 'Schedule',
  editMode = false,
  editRecurrence = false,
  activeEvent = false,
  readonlyRRuleForEdit,
}: EventSchedulerProps) => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<EventSchedulerFields>()
  const { tzDayjs } = useUserPrefs()

  const recurrenceType = watch('frequency')
  const singleEventStartDate = watch('singleEventStartDate')
  const singleEventEndDate = watch('singleEventEndDate')
  const seriesStart = watch('seriesStart')
  const seriesEnd = watch('seriesEnd')
  const selectedRecurringWeekdays = watch('recurringWeekdays')
  const selectedRecurringEventStartTime = watch('recurringEventStartTime')
  const selectedRecurringEventEndTime = watch('recurringEventEndTime')
  const isSingleEvent = recurrenceType?.name === 'Single Event'

  const previousRecurrenceType = usePreviousValue(recurrenceType)
  const lastSelectedRecurringWeekdays = usePreviousValue(
    selectedRecurringWeekdays
  )

  // Handle setting of weekdays if we are in editMode and it is custom or weekly repeating (hack for pending api fix)
  useEffect(() => {
    if (
      recurrenceType?.name === 'Weekly Repeating' ||
      recurrenceType?.name === 'Custom Repeating'
    ) {
      if (readonlyRRuleForEdit) {
        const firstHalfOfRRule = readonlyRRuleForEdit.split(';INTERVAL')[0]
        const days = firstHalfOfRRule?.split(';BYDAY=')[1]?.split(',') ?? []

        // Fix backend day offset
        const weekdayBackwardsOffsetLookupMap = new Map([
          ['SU', 'SA'],
          ['MO', 'SU'],
          ['TU', 'MO'],
          ['WE', 'TU'],
          ['TH', 'WE'],
          ['FR', 'TH'],
          ['SA', 'FR'],
        ])

        const startTime = tzDayjs(selectedRecurringEventStartTime)
        const actualWeekdays = days.map((day) => {
          const minsToHourStart = startTime.hour() * MINS_PER_HOUR
          const minsSinceHourStart = startTime.minute()
          const minsOffset = Math.abs(startTime.utcOffset())

          return minsToHourStart + minsSinceHourStart + minsOffset >=
            MINS_PER_DAY
            ? (weekdayBackwardsOffsetLookupMap.get(day) as string)
            : day
        })
        setValue('recurringWeekdays', actualWeekdays)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- selectedRecurringEventStartTime re render will cause an issue with this if you change it while editing
  }, [editMode, recurrenceType, readonlyRRuleForEdit, setValue])

  // Handle setting of Weekdays Repeating to set days selected
  useEffect(() => {
    if (recurrenceType?.name === 'Weekdays Repeating') {
      setValue('recurringWeekdays', ['MO', 'TU', 'WE', 'TH', 'FR'])
    }
  }, [recurrenceType, setValue])

  // Handle setting of Weekly Repeating to set days selected
  useEffect(() => {
    function filterWeekdays(argument: string | undefined): argument is string {
      return (
        argument !== undefined &&
        !lastSelectedRecurringWeekdays?.includes(argument)
      )
    }

    if (
      lastSelectedRecurringWeekdays &&
      selectedRecurringWeekdays &&
      recurrenceType?.name === 'Weekly Repeating' &&
      selectedRecurringWeekdays.length !== 1
    ) {
      setValue(
        'recurringWeekdays',
        selectedRecurringWeekdays?.filter(filterWeekdays)
      )
    }
  }, [
    recurrenceType,
    selectedRecurringWeekdays,
    lastSelectedRecurringWeekdays,
    setValue,
  ])

  // Handle setting of Weekly Repeating to set days selected
  useEffect(() => {
    if (recurrenceType?.name === 'Weekly Repeating') {
      setValue('recurringWeekdays', ['MO'])
    }
  }, [recurrenceType, setValue])

  // Handle setting of going off of weekdays repeating special case for when user selects/deselects day in weekdays repeating mode
  useEffect(() => {
    const hadAllDaysSelected = isEqual(
      lastSelectedRecurringWeekdays?.sort(),
      ['MO', 'TU', 'WE', 'TH', 'FR'].sort((a, b) => a.localeCompare(b))
    )

    const hasAllDaysSelected = isEqual(
      selectedRecurringWeekdays?.sort(),
      ['MO', 'TU', 'WE', 'TH', 'FR'].sort((a, b) => a.localeCompare(b))
    )

    if (
      lastSelectedRecurringWeekdays &&
      selectedRecurringWeekdays &&
      recurrenceType?.name === 'Weekdays Repeating' &&
      hadAllDaysSelected &&
      !hasAllDaysSelected
    ) {
      setValue('frequency', RecurrenceTypeAutocomplete.options.CustomRepeating)
    }
  }, [
    recurrenceType,
    selectedRecurringWeekdays,
    lastSelectedRecurringWeekdays,
    setValue,
  ])

  // Switch over dates to new form if transition from series to single/vice versa
  useEffect(() => {
    // Other Frequency -> Single Event
    if (
      !editMode &&
      isSingleEvent &&
      previousRecurrenceType?.name !== undefined &&
      previousRecurrenceType?.name !== 'Single Event'
    ) {
      setValue('singleEventStartDate', seriesStart)
      setValue('singleEventEndDate', seriesEnd)
    }

    // Single Event -> Other Frequency
    if (!isSingleEvent && previousRecurrenceType?.name === 'Single Event') {
      setValue('seriesStart', singleEventStartDate)
      setValue('seriesEnd', singleEventEndDate)
    }
  }, [
    editMode,
    isSingleEvent,
    previousRecurrenceType,
    setValue,
    seriesStart,
    seriesEnd,
    singleEventStartDate,
    singleEventEndDate,
  ])

  // Handle editMode and editRecurrence to be single event for form control
  useEffect(() => {
    if (editMode && !editRecurrence) {
      setValue('frequency', RecurrenceTypeAutocomplete.options.SingleEvent)
    }
  }, [editMode, editRecurrence, setValue])

  const weekdayButtons = [
    { value: 'MO', display: 'Mon' },
    { value: 'TU', display: 'Tue' },
    { value: 'WE', display: 'Wed' },
    { value: 'TH', display: 'Thu' },
    { value: 'FR', display: 'Fri' },
    { value: 'SA', display: 'Sat' },
    { value: 'SU', display: 'Sun' },
  ]

  const overNightEvent =
    !isSingleEvent &&
    selectedRecurringEventEndTime &&
    selectedRecurringEventStartTime &&
    selectedRecurringEventEndTime.get('hour') <
      selectedRecurringEventStartTime.get('hour')

  const showSeriesNameInput = !isSingleEvent
  const showWeekdayButtons = [
    'Weekly Repeating',
    'Weekdays Repeating',
    'Custom Repeating',
  ].includes(recurrenceType?.name)

  return (
    <>
      <Grid container direction="row" item spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle2">{label}</Typography>
        </Grid>
        {!isSingleEvent && (
          <Grid item xs={1}>
            <Tooltip
              placement="left-start"
              title="Repeating events have a maximum duration of 24 hours. To schedule an event that extends to the next day, choose an end time earlier than your selected start time."
            >
              <Info />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <RecurrenceTypeAutocomplete.Select
          disabled={editMode}
          error={errors['frequency'] as FieldError}
          fullWidth
          id="frequency"
          label="Frequency"
        />
      </Grid>

      {showWeekdayButtons ? (
        <Grid
          alignItems="center"
          alignSelf="stretch"
          container
          direction="column"
          display="flex"
          item
          justifyContent="center"
          md={6}
          xs={12}
        >
          <FormField.ToggleButtonGroup
            buttons={weekdayButtons}
            disabled={editMode}
            id="recurringWeekdays"
          />
        </Grid>
      ) : (
        <Grid md={6} xs={0} />
      )}

      {showSeriesNameInput && (
        <Grid item xs={12}>
          <FormField.TextFormField
            control={control}
            disabled={editMode}
            fullWidth
            id="recurrenceName"
            inputProps={{ maxLength: 70 }}
            label="Series Name"
          />
        </Grid>
      )}

      <Grid item md={6} xs={12}>
        <FormField.DatePicker
          disabled={editRecurrence || (editMode && activeEvent)}
          error={
            errors[
              isSingleEvent || (editMode && !editRecurrence)
                ? 'singleEventStartDate'
                : 'seriesStart'
            ] != undefined
          }
          id={
            isSingleEvent || (editMode && !editRecurrence)
              ? 'singleEventStartDate'
              : 'seriesStart'
          }
          label={
            isSingleEvent || (editMode && !editRecurrence)
              ? 'Event Start'
              : 'Series Start'
          }
          minDate={tzDayjs()}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormField.DatePicker
          disabled={editRecurrence}
          error={
            errors[
              isSingleEvent || (editMode && !editRecurrence)
                ? 'singleEventEndDate'
                : 'seriesEnd'
            ] != undefined
          }
          id={
            isSingleEvent || (editMode && !editRecurrence)
              ? 'singleEventEndDate'
              : 'seriesEnd'
          }
          label={
            isSingleEvent || (editMode && !editRecurrence)
              ? 'Event End'
              : 'Series End'
          }
          minDate={
            isSingleEvent || (editMode && !editRecurrence)
              ? singleEventStartDate
              : seriesStart
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormField.TimePicker
          disabled={editMode && activeEvent}
          error={errors['recurringEventStartTime'] as FieldError}
          id="recurringEventStartTime"
          label="Start Time"
          variant="standard"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormField.TimePicker
          error={errors['recurringEventEndTime'] as FieldError}
          helperText={overNightEvent ? 'This event will end the next day.' : ''}
          id="recurringEventEndTime"
          label="End Time"
          variant="standard"
        />
      </Grid>
    </>
  )
}
