import { Hub, HubCapsule } from '@aws-amplify/core'
import { useEffect } from 'react'
import { useUserLogout } from './useUserLogout'

const TOKEN_REFRESH_FAILURE = 'tokenRefresh_failure'

export const useAuthListener = () => {
  const logoutUser = useUserLogout()

  useEffect(() => {
    const listener = (data: HubCapsule) => {
      if (data.payload.event === TOKEN_REFRESH_FAILURE) {
        logoutUser()
      }
    }

    Hub.listen('auth', listener)

    return function cleanup() {
      Hub.remove('auth', listener)
    }
  }, [logoutUser])
}
