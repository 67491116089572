import { emDash, Icons, useRouting } from '@synop-react/common'
import { Link, Stack, Typography } from '@mui/material'
import { RootAPI, useLocationsOfInterest, useOrgSites } from '@synop-react/api'
import { sortBy } from 'lodash'

type VehicleCurrentLocationProps = {
  currentLocation?: RootAPI.CurrentLocation
}

export const CurrentVehicleLocations = ({
  currentLocation,
}: VehicleCurrentLocationProps) => {
  const {
    street,
    cardinalDirection,
    locationOfInterestModels = [],
  } = currentLocation ?? {}

  const getLoiName = useGetLoiName()

  const sortedLois = sortBy(locationOfInterestModels ?? [], (loi) =>
    getLoiName(loi).toLowerCase()
  )

  const CurrentLocations = sortedLois.map((location) => (
    <CurrentVehicleLocation key={location.id} location={location} />
  ))

  const currentHeading = getCurrentHeading(street, cardinalDirection)
  const hasLOIs = !!sortedLois.length

  // If there's no location information render an em dash placeholder
  if (!hasLOIs && !currentHeading)
    return <Typography variant="body2">{emDash}</Typography>

  return hasLOIs ? (
    <Stack direction="row" spacing={1} sx={{ overflow: 'hidden' }}>
      {CurrentLocations}
    </Stack>
  ) : (
    <Typography variant="body2">{currentHeading}</Typography>
  )
}

type CurrentVehicleLocationProps = {
  location: RootAPI.LocationOfInterestModel
}

export const CurrentVehicleLocation = ({
  location,
}: CurrentVehicleLocationProps) => {
  const { routes } = useRouting()
  const getLoiName = useGetLoiName()

  const LoiIcon =
    location.type === 'Site' ? (
      <Icons.MapPinDefault size={20} />
    ) : (
      <Icons.Target color="black" size={20} />
    )

  const locationName = getLoiName(location)
  const LoiText =
    location.type === 'Site' ? (
      <Link
        href={routes.siteDetails(location.id ?? '')}
        noWrap
        underline="none"
        variant="body2"
      >
        {locationName}
      </Link>
    ) : (
      <Typography noWrap variant="body2">
        {locationName}
      </Typography>
    )

  // If the location has been deleted we can't resolve the name and we shouldn't show this LoI
  return locationName ? (
    <Stack direction="row" spacing={1} sx={{ overflow: 'auto' }}>
      {LoiIcon}
      {LoiText}
    </Stack>
  ) : null
}

export const getCurrentHeading = (
  street?: RootAPI.SourceAttributeValueString,
  cardinalDirection?: RootAPI.SourceAttributeValueString
) =>
  cardinalDirection?.value && street?.value
    ? `${cardinalDirection.value} on ${street.value}`
    : ''

export const useGetLoiName = () => {
  const { orgLocations } = useLocationsOfInterest()
  const { orgSites } = useOrgSites()

  return ({ type, id = '' }: RootAPI.LocationOfInterestModel) =>
    type === 'Site'
      ? orgSites[id]?.depotNm ?? ''
      : orgLocations[id]?.loiNm ?? ''
}
