import { Configuration } from 'rollbar'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { PropsWithChildren, useMemo } from 'react'

import { Config, getEnvConfig } from '@synop-react/config'
import { datadogRum } from '@datadog/browser-rum'

const appConfig = getEnvConfig()

type RollbarProps = PropsWithChildren<{ config?: Config }>

export const Rollbar = ({ children, config = appConfig }: RollbarProps) => {
  const rollbarConfig = useMemo<Configuration>(
    () => ({
      accessToken: config.ROLLBAR_CLIENT_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: ['prod', 'gamma', 'beta', 'pr'].includes(config.ROLLBAR_ENV),
      environment: config.ROLLBAR_ENV,
      payload: {
        client: {
          javascript: {
            code_version: config.APP_VERSION,
            source_map_enabled: true,

            // Rollbar will guess which frames the error was thrown from when the browser does not
            // provide line  and column numbers.
            guess_uncaught_frames: true,
          },
        },
      },

      // Add the LogRocket session URL to the Rollbar payload
      transform: (payload) => {
        payload['sessionUrl'] = datadogRum?.getSessionReplayLink()
      },
    }),
    [config]
  )

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </RollbarProvider>
  )
}
