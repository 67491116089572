import {
  Card,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { emDash } from '../../utils'

export type DashboardOverviewItem = {
  value?: string | number
  label: string
  units?: string
  tooltip?: string
}

export type DashboardOverviewTileProps = {
  items?: DashboardOverviewItem[][]
  isLoading?: boolean
}

export const DashboardOverviewTile = ({
  items = [[]],
  isLoading = false,
}: DashboardOverviewTileProps) => {
  const theme = useTheme()

  if (isLoading)
    return (
      <Skeleton
        height="150px"
        sx={{ mt: 4 }}
        variant="rectangular"
        width="100%"
      />
    )

  return (
    <Card
      elevation={2}
      sx={{
        mt: 2,
        justifyContent: 'center',
        width: '100%',
        borderLeft: `8px solid ${theme.palette.primary.main}`,
        borderRadius: 0,
      }}
    >
      {items.map((row, i) => {
        const width = 100 / row.length
        return (
          <Stack
            // This is a rare case where it's ok to use index for a key @wslater
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            direction="row"
            divider={
              <Divider
                flexItem
                orientation="vertical"
                sx={{ height: '40px', alignSelf: 'center' }}
              />
            }
            spacing={2}
            sx={{
              justifyContent: 'center',
            }}
          >
            {row.map((item) => (
              <DashboardOverviewItem
                key={item.label}
                label={item.label}
                tooltip={item.tooltip}
                units={item.units}
                value={item.value}
                width={width + '%'}
              />
            ))}
          </Stack>
        )
      })}
    </Card>
  )
}

type DashboardOverviewItemProps = DashboardOverviewItem & {
  width?: string
}

const DashboardOverviewItem = ({
  value = emDash,
  label,
  units = '',
  width,
  tooltip,
}: DashboardOverviewItemProps) => {
  const theme = useTheme()
  return (
    <Stack direction="column" sx={{ width, pl: 3, pt: 1, pb: 1 }}>
      <Stack direction="row" spacing={0.5}>
        <Typography sx={{ color: theme.palette.secondary.main }} variant="h4">
          {value}
        </Typography>
        {units && (
          <Typography
            sx={{
              color: theme.palette.secondary.main,
              alignSelf: 'flex-end',
            }}
            variant="h6"
          >
            {units}
          </Typography>
        )}
      </Stack>
      <Tooltip title={tooltip}>
        <Typography
          sx={{ color: theme.palette.secondary.main }}
          variant="caption"
        >
          {label}
        </Typography>
      </Tooltip>
    </Stack>
  )
}
