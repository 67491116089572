import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PartialStore } from '@synop-react/types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

type DisplayStore = PartialStore<typeof displaySlice>
type DisplayState = {
  fullscreen: boolean
  snackbar: SnackbarState
}

type OpenSnackbarPayload = {
  message: string
  severity?: SnackbarSeverity
  title?: string
}

type SnackbarSeverity = 'success' | 'info' | 'warning' | 'error'
type SnackbarState =
  | { isOpen: false; message: null; severity: null; title: null }
  | {
      isOpen: true
      message: string
      severity: SnackbarSeverity
      title?: string
    }

const initialState: DisplayState = {
  fullscreen: false,
  snackbar: {
    isOpen: false,
    message: null,
    severity: null,
    title: null,
  },
}

const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    closeSnackbar: (state) => {
      return {
        ...state,
        snackbar: {
          isOpen: false,
          message: null,
          severity: null,
          title: null,
        },
      }
    },
    openSnackbar: (state, action: PayloadAction<OpenSnackbarPayload>) => {
      return {
        ...state,
        snackbar: {
          isOpen: true,
          message: action.payload.message,
          severity: action.payload.severity ?? 'success',
          title: action.payload.title,
        },
      }
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      return { ...state, fullscreen: action.payload }
    },
  },
})

const { setFullscreen } = displaySlice.actions
export const displayReducer = displaySlice.reducer

const selectIsFullscreen = (state: DisplayStore) => state.display.fullscreen
const selectSnackbar = (state: DisplayStore) => state.display.snackbar

export const useIsFullscreen = () => useSelector(selectIsFullscreen)
export const useSnackbar = () => useSelector(selectSnackbar)

// Provides easy access to dispatching actions
type OpenSnackbarOptions = { severity?: SnackbarSeverity; title?: string }

export const useSnackbarControls = () => {
  const dispatch = useDispatch()

  const openSnackbar = useCallback(
    (message: string, options: OpenSnackbarOptions = {}) =>
      dispatch(displaySlice.actions.openSnackbar({ message, ...options })),
    [dispatch]
  )

  const closeSnackbar = useCallback(
    () => dispatch(displaySlice.actions.closeSnackbar()),
    [dispatch]
  )

  return {
    closeSnackbar,
    openSnackbar,
  }
}

export const useFullscreenControls = () => {
  const dispatch = useDispatch()

  const enterFullscreen = useCallback(
    () => dispatch(setFullscreen(true)),
    [dispatch]
  )

  const exitFullscreen = useCallback(
    () => dispatch(setFullscreen(false)),
    [dispatch]
  )

  return {
    enterFullscreen,
    exitFullscreen,
  }
}
