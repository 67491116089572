import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { FormField } from '../../index'
import { RootAPI } from '@synop-react/api'

const { useGetFleetsQuery } = RootAPI.synopRootAPI

export type FleetAutocompleteSelectProps<FormData extends FieldValues> = {
  label?: string
  orgId: string
} & AutocompleteFormControls<FormData, RootAPI.Fleet> &
  AutocompleteTextFieldProps

export const FleetAutocompleteSelect = <FormData extends FieldValues>({
  orgId,
  label = 'Fleet',
  ...autocompleteProps
}: FleetAutocompleteSelectProps<FormData>) => {
  const FleetOptions = FormField.useOptions<
    RootAPI.Fleet,
    RootAPI.GetFleetsApiArg,
    RootAPI.Fleet[]
  >({
    queryArgs: { id: orgId },
    useQuery: useGetFleetsQuery,
  })

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.Fleet>
      label={label}
      {...FleetOptions}
      {...autocompleteProps}
      filterOptions={(options, { inputValue }) =>
        options.filter(({ fleetNm }) => {
          const FleetName = fleetNm?.toLowerCase() || ''
          const searchTerm = inputValue.toLowerCase()
          return FleetName.includes(searchTerm)
        })
      }
      getOptionLabel={({ fleetNm }: RootAPI.Fleet) => fleetNm || ''}
      keyExtractor={({ id }: RootAPI.Fleet) => id || ''}
    />
  )
}

const FleetSelectSchema = yup
  .object({ id: yup.string().required() })
  .nullable()
  .required('Fleet must be selected')

export { FleetAutocompleteSelect as Select, FleetSelectSchema as Schema }
