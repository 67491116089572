import { Autocomplete, TextField } from '@mui/material'

export type FormlessSimpleAutocompleteOption<T extends string> = {
  name: string
  id: T
}

export type FormlessSimpleAutocompleteProps = {
  label: string
  value?: string
  onChange: (newValue?: string) => void
  disabled?: boolean
  disableClearable?: boolean
}

export function formlessSimpleAutocompleteFactory<
  T extends string,
  Option extends FormlessSimpleAutocompleteOption<T>
>(options: Option[]) {
  return function FormlessSimpleAutocomplete(
    props: FormlessSimpleAutocompleteProps
  ) {
    const { label, onChange, value, ...rest } = props
    return (
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.name}
        multiple={false}
        onChange={(_, newValue) => onChange(newValue ? newValue.id : undefined)}
        options={options}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              key={params.id}
              label={label}
              variant="standard"
            />
          )
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        value={options.find(({ id }) => id === value)}
        {...rest}
      />
    )
  }
}
