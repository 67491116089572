import { Box, Stack, Typography } from '@mui/material'
import { Marker } from 'react-map-gl'
import { PropsWithChildren } from 'react'

export type MapBadgeMarkerProps = PropsWithChildren<{
  badgeColor: string
  latitude?: number
  longitude?: number
  showCaret?: boolean
}>

export const MapBadgeMarker = ({
  badgeColor,
  children,
  latitude,
  longitude,
  showCaret = true,
}: MapBadgeMarkerProps) => {
  const Children =
    typeof children === 'string' ? (
      <Typography color="white" variant="caption">
        {children}
      </Typography>
    ) : (
      children
    )

  return (
    <Marker anchor="bottom" latitude={latitude} longitude={longitude}>
      <Stack alignItems="center">
        <Box
          sx={{ backgroundColor: badgeColor, borderRadius: 2, px: 1, py: 0.25 }}
        >
          {Children}
        </Box>
        {showCaret && (
          <Box
            sx={{
              width: 0,
              height: 0,
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderTop: `6px solid ${badgeColor}`,
            }}
          />
        )}
      </Stack>
    </Marker>
  )
}
