import { Button } from '@mui/material'
import { Cask, Crate, DollarSign } from '@synop-react/common'

//TODO: Move billing to its own billing lib
export function BillingSettings() {
  return (
    <Cask title={'Billing'}>
      <Crate
        description="You organization has not set up billing. Please set up billing to charge your customers for access."
        Icon={<DollarSign />}
      >
        <Button variant="outlined">Turn on billing</Button>
      </Crate>
    </Cask>
  )
}

export default BillingSettings
