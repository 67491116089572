import { AlertStep, AlertStepCommonProps } from './AlertStep'
import { FormField } from '@synop-react/common'
import { Grid } from '@mui/material'
import { RootAPI, useCurrentOrganization } from '@synop-react/api'
import { tooltipText } from './tooltipText'
import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

const { useGetOrganizationCustomersQuery, useGetOrganizationUsersQuery } =
  RootAPI

export const AlertCommunicationStep = (props: AlertStepCommonProps) => {
  const message = useWatch({ name: 'message' })
  return (
    <AlertStep
      {...props}
      stepDescription="Configure who will receive this alert."
      stepName="Communication"
      stepNumber={2}
    >
      <Grid item sm={6} xs={12}>
        <FormField.WrappedTextFormField
          helperText={`${message.length} / 500 Characters`}
          id="message"
          label="Alert Message"
          multiline
          rows={6}
          sx={{ my: 3 }}
          tooltip={tooltipText.message}
          variant="outlined"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <UserMultiSelect />
      </Grid>
    </AlertStep>
  )
}

const UserMultiSelect = () => {
  const { currentOrg } = useCurrentOrganization()
  const { data: users, isLoading: isLoadingUsers } =
    useGetOrganizationUsersQuery(
      { id: currentOrg?.id ?? '', skipEnhanceData: true },
      { skip: !currentOrg }
    )

  const { data: organizations, isLoading: isLoadingOrgs } =
    useGetOrganizationCustomersQuery(
      { id: currentOrg?.id ?? '', skipEnhanceData: true },
      { skip: !currentOrg }
    )

  const userOptions = useMemo(() => {
    // Create a mapping from org IDs to names for the user labels
    const allOrgs = [...(organizations ?? []), currentOrg]
    const orgIdNamePairs =
      allOrgs
        .filter((org) => org?.organizationNm)
        .map((org) => [org.id, org.organizationNm as string]) ?? []
    const orgMap = Object.fromEntries(orgIdNamePairs)

    // Put together the actual list of users, filtering out those without the requisite data
    return (users ?? []).flatMap((user) => {
      if (!user) return []

      const { name, id, organizationId } = user
      if (!name || !id || !organizationId) return []

      return {
        label: `${name} (${orgMap[organizationId]})`,
        value: id,
      }
    })
  }, [currentOrg, organizations, users])

  return (
    <FormField.WrappedAutocompleteMultiselectFormField
      id="recipients"
      label="Users"
      loading={isLoadingUsers || isLoadingOrgs}
      options={userOptions}
      tooltip={tooltipText.recipients}
    />
  )
}
