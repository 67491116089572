import { asFraction, ColorDot } from '@synop-react/common'
import { Depot } from '@synop-react/api'
import { Stack, Typography, TypographyProps, useTheme } from '@mui/material'

export type DepotActiveChargerDetailProps = Partial<
  Pick<Depot, 'activeChargers' | 'numChargers'>
> &
  TypographyProps

export function DepotActiveChargerDetail({
  activeChargers,
  numChargers,
  ...typographyProps
}: DepotActiveChargerDetailProps) {
  const { palette } = useTheme()

  const formatActiveCharger = (
    activeChargerCount?: number,
    numChargers?: number
  ) => {
    if (activeChargerCount === undefined) {
      return 'n/a'
    }

    const fractionString = asFraction(activeChargerCount, numChargers)

    return `${fractionString} Chargers in Use`
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <ColorDot color={palette.success.main} />
      <Typography {...typographyProps}>
        {formatActiveCharger(activeChargers, numChargers)}
      </Typography>
    </Stack>
  )
}
export default DepotActiveChargerDetail
