import { Chip, useTheme } from '@mui/material'
import { UserStatus } from '@synop-react/api'

type ChipConfig = {
  mainColor: string
  secondaryColor: string
  label: string
}

type StatusChipProps = {
  status: UserStatus
}

export const UserStatusChip = ({ status }: StatusChipProps) => {
  const { palette } = useTheme()
  const statusChipConfig: Record<UserStatus, ChipConfig> = {
    CONFIRMED: {
      mainColor: palette.success.main,
      secondaryColor: palette.success['4p'],
      label: 'Confirmed',
    },
    UNCONFIRMED: {
      mainColor: palette.charting.chart1.main,
      secondaryColor: palette.charting.chart1['4p'],

      label: 'Unconfirmed',
    },
    ARCHIVED: {
      mainColor: palette.charting.chart2.main,
      secondaryColor: palette.charting.chart2['4p'],
      label: 'Archived',
    },
    FORCE_CHANGE_PASSWORD: {
      mainColor: palette.charting.chart5.main,
      secondaryColor: palette.charting.chart5['4p'],
      label: 'Force Change Password',
    },
    UNKNOWN: {
      mainColor: palette.charting.chart3.main,
      secondaryColor: palette.charting.chart3['4p'],

      label: 'Unknown',
    },
    RESET_REQUIRED: {
      mainColor: palette.charting.chart6.main,
      secondaryColor: palette.charting.chart6['4p'],

      label: 'Reset Required',
    },
    UNKNOWN_TO_SDK_VERSION: {
      mainColor: palette.charting.chart4.main,
      secondaryColor: palette.charting.chart4['4p'],

      label: 'Unknown To SDK Version',
    },
    DISABLED: {
      mainColor: palette.error.main,
      secondaryColor: palette.error['4p'],
      label: 'Disabled',
    },
  }

  const {
    mainColor = palette.secondary.main,
    secondaryColor = palette.secondary['4p'],
    label = 'Unknown Status',
  } = statusChipConfig[status]

  return (
    <Chip
      label={label}
      size="small"
      sx={{
        color: mainColor,
        borderColor: mainColor,
        bgcolor: secondaryColor,
      }}
      variant="outlined"
    />
  )
}
