import { Button, Typography } from '@mui/material'
import { Check, OverlayDeprecated } from '@synop-react/common'

type AcknowledgeAllOverlayProps = {
  onConfirm: () => void
  setIsOpen: (isOpen: boolean) => void
  activeCount: number
  resolvedCount: number
}

export const AcknowledgeAllOverlay = ({
  onConfirm,
  setIsOpen,
  activeCount,
  resolvedCount,
}: AcknowledgeAllOverlayProps) => {
  return (
    <OverlayDeprecated
      isOpen
      OverlayActions={[
        <Button
          key="confirm"
          color="primary"
          onClick={onConfirm}
          variant="contained"
        >
          Confirm
        </Button>,
        <Button
          key="cancel"
          color="primary"
          onClick={() => setIsOpen(false)}
          variant="outlined"
        >
          CANCEL
        </Button>,
      ]}
      setIsOpen={setIsOpen}
      title="Acknowledge All Notifications?"
      TitleIcon={Check}
    >
      <Typography variant="body1">
        You have selected {activeCount} Active and {resolvedCount} Resolved
        unacknowledged notifications. Are you sure you wish to proceed to mark
        all as acknowledged?
      </Typography>
    </OverlayDeprecated>
  )
}
