import {
  formatDistanceSourceAttribute,
  getFloatFromSourceAttribute,
  ProgressPillColor,
  StatusCask,
} from '@synop-react/common'
import { VehicleModel } from '@synop-react/api'

export type VehicleRangeDetailProps = Pick<
  VehicleModel,
  'remainingRange' | 'estimatedRange' | 'distanceUntilHome'
>

export function VehicleRangeDetail({
  remainingRange,
  estimatedRange,
  distanceUntilHome,
}: VehicleRangeDetailProps) {
  const rangeRemaining = getFloatFromSourceAttribute(remainingRange)
  const totalRange = estimatedRange || rangeRemaining
  const distanceToHome = getFloatFromSourceAttribute(distanceUntilHome)

  const warningRange = totalRange * 0.2
  const dangerRange = totalRange * 0.1

  const getBarColor = (): ProgressPillColor => {
    const returningRange = rangeRemaining - distanceToHome
    if (returningRange <= dangerRange) return 'error'
    else if (returningRange < warningRange) return 'warning'
    return 'secondary'
  }

  return (
    <StatusCask.Item
      label="Remaining Range"
      progress={{
        value: rangeRemaining,
        color: getBarColor(),
        upperBound: totalRange,
      }}
      value={formatDistanceSourceAttribute(remainingRange, { sigFigs: 0 })}
    />
  )
}

export default VehicleRangeDetail
