export const chargers = [
  {
    chargerId: '0056800a724f49b88f21732723ff3f6b',
    chargerName: '1234',
    endpointAddress: null,
    ocppProtocol: null,
    registrationStatus: 'Accepted',
    chargePointYear: 2022,
    chargePointVendor: 'Power Electronics',
    chargePointModel: 'NB 120',
    chargePointSerialNumber: 'NBDICharger30894482',
    chargeBoxSerialNumber: null,
    firmwareVersion: null,
    firmwareUpdateStatus: null,
    firmwareUpdateTimestamp: null,
    iccid: null,
    imsi: null,
    location: {
      type: 'Point',
      coordinates: [-117.655494, 33.676381],
    },
    meterType: null,
    meterSerialNumber: null,
    diagnosticsStatus: null,
    diagnosticsTimestamp: null,
    lastHeartbeatTimestamp: '2022-07-19T19:02:06.538Z',
    description: null,
    note: null,
    autoRegisterTags: false,
    groupId: null,
    maxPower: 3.84,
    maxCurrent: null,
    voltage: 367.1,
    currentType: 'DC',
    depotId: '1657633797df484a9af9ef6ecfab0f4d',
    fleetId: '',
    externalId: '9010',
    latitude: 33.676381,
    longitude: -117.655494,
    created: '2022-07-12T08:00:00',
    updated: '2022-09-29T00:00:00',
    connectorIds: [],
    activeConnectorIds: [],
    connectorStatus: null,
    lastTelemetry: {},
    lastSession: {},
    v2gEnabled: null,
    livePowerImport: 0,
    livePowerImportUnit: null,
    livePowerExport: 0,
    livePowerExportUnit: null,
    powerUnit: 'kW',
  },
  {
    chargerId: 'T184-IT1-2921-051',
    chargerName: 'Holman Charger 1',
    endpointAddress: null,
    ocppProtocol: 'ocpp1.6J',
    registrationStatus: 'Accepted',
    chargePointYear: null,
    chargePointVendor: 'ABB',
    chargePointModel: 'MD_TERRA_D',
    chargePointSerialNumber: 'T184-IT1-2921-051',
    chargeBoxSerialNumber: null,
    firmwareVersion: '1.3.3.25',
    firmwareUpdateStatus: null,
    firmwareUpdateTimestamp: null,
    iccid: null,
    imsi: null,
    location: {
      type: 'Point',
      coordinates: [-117.655494, 33.676381],
    },
    meterType: 'DC',
    meterSerialNumber: null,
    diagnosticsStatus: null,
    diagnosticsTimestamp: null,
    lastHeartbeatTimestamp: '2022-09-27T19:24:05.996Z',
    description: null,
    note: null,
    autoRegisterTags: true,
    groupId: null,
    maxPower: 180.0,
    maxCurrent: null,
    voltage: 500.0,
    currentType: 'DC',
    depotId: '1657633797df484a9af9ef6ecfab0f4d',
    fleetId: '2ca0ae187e8d1f29017eb58c23610000',
    externalId: '9010',
    latitude: 33.676381,
    longitude: -117.655494,
    created: '2022-09-29T00:00:00',
    updated: '2022-09-29T00:00:00',
    connectorIds: [0, 1, 2],
    activeConnectorIds: [],
    connectorStatus: {
      '0': {
        chargerId: null,
        chargerNm: null,
        connectorId: 0,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-27T19:16:43.460Z',
      },
      '1': {
        chargerId: null,
        chargerNm: null,
        connectorId: 1,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-28T13:26:32.239Z',
      },
      '2': {
        chargerId: null,
        chargerNm: null,
        connectorId: 2,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-27T23:49:57.145Z',
      },
    },
    lastTelemetry: {},
    lastSession: {},
    v2gEnabled: null,
    livePowerImport: 0,
    livePowerImportUnit: null,
    livePowerExport: 0,
    livePowerExportUnit: null,
    powerUnit: 'kW',
  },
  {
    chargerId: 'T184-IT1-2821-055',
    chargerName: 'Holman Charger 2',
    endpointAddress: null,
    ocppProtocol: 'ocpp1.6J',
    registrationStatus: 'Accepted',
    chargePointYear: null,
    chargePointVendor: 'ABB',
    chargePointModel: 'MD_TERRA_D',
    chargePointSerialNumber: 'T184-IT1-2821-055',
    chargeBoxSerialNumber: null,
    firmwareVersion: '1.3.3.25',
    firmwareUpdateStatus: null,
    firmwareUpdateTimestamp: null,
    iccid: null,
    imsi: null,
    location: {
      type: 'Point',
      coordinates: [-117.655494, 33.676381],
    },
    meterType: 'DC',
    meterSerialNumber: null,
    diagnosticsStatus: null,
    diagnosticsTimestamp: null,
    lastHeartbeatTimestamp: '2022-09-27T19:23:54.965Z',
    description: null,
    note: null,
    autoRegisterTags: true,
    groupId: null,
    maxPower: 180.0,
    maxCurrent: null,
    voltage: 500.0,
    currentType: 'DC',
    depotId: '1657633797df484a9af9ef6ecfab0f4d',
    fleetId: '2ca0ae187e8d1f29017eb58c23610000',
    externalId: '9010',
    latitude: 33.676381,
    longitude: -117.655494,
    created: '2022-09-29T00:00:00',
    updated: '2022-09-29T00:00:00',
    connectorIds: [0, 1, 2],
    activeConnectorIds: [],
    connectorStatus: {
      '0': {
        chargerId: null,
        chargerNm: null,
        connectorId: 0,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-20T10:04:01.689Z',
      },
      '1': {
        chargerId: null,
        chargerNm: null,
        connectorId: 1,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-28T10:33:53.543Z',
      },
      '2': {
        chargerId: null,
        chargerNm: null,
        connectorId: 2,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-28T19:19:32.550Z',
      },
    },
    lastTelemetry: {},
    lastSession: {},
    v2gEnabled: null,
    livePowerImport: 0,
    livePowerImportUnit: null,
    livePowerExport: 0,
    livePowerExportUnit: null,
    powerUnit: 'kW',
  },
  {
    chargerId: 'T184-IT1-2821-059',
    endpointAddress: null,
    ocppProtocol: 'ocpp1.6J',
    registrationStatus: 'Accepted',
    chargePointYear: null,
    chargePointVendor: 'ABB',
    chargePointModel: 'MD_TERRA_D',
    chargePointSerialNumber: 'T184-IT1-2821-055',
    chargeBoxSerialNumber: null,
    firmwareVersion: '1.3.3.25',
    firmwareUpdateStatus: null,
    firmwareUpdateTimestamp: null,
    iccid: null,
    imsi: null,
    location: {
      type: 'Point',
      coordinates: [-117.655494, 33.676381],
    },
    meterType: 'DC',
    meterSerialNumber: null,
    diagnosticsStatus: null,
    diagnosticsTimestamp: null,
    lastHeartbeatTimestamp: '2022-09-27T19:23:59.965Z',
    description: null,
    note: null,
    autoRegisterTags: true,
    groupId: null,
    maxPower: 180.0,
    maxCurrent: null,
    voltage: 500.0,
    currentType: 'DC',
    depotId: '1657633797df484a9af9ef6ecfab0f4d',
    fleetId: '2ca0ae187e8d1f29017eb58c23610000',
    externalId: '9010',
    latitude: 33.676381,
    longitude: -117.655494,
    created: '2022-09-29T00:00:00',
    updated: '2022-09-29T00:00:00',
    connectorIds: [0, 1],
    activeConnectorIds: [],
    connectorStatus: {
      '0': {
        chargerId: null,
        chargerNm: null,
        connectorId: 0,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-20T10:04:01.689Z',
      },
      '1': {
        chargerId: null,
        chargerNm: null,
        connectorId: 1,
        status: 'Unavailable',
        errorCode: 'NoError',
        errorInfo: '',
        vendorId: null,
        vendorErrorCode: null,
        statusTimestamp: '2022-09-28T10:33:53.543Z',
      },
    },
    lastTelemetry: {},
    lastSession: {},
    v2gEnabled: null,
    livePowerImport: 0,
    livePowerImportUnit: null,
    livePowerExport: 0,
    livePowerExportUnit: null,
    powerUnit: 'kW',
  },
] as const

export const connectors = [
  {
    connectorId: 0,
    chargerId: chargers[0].chargerId,
    maxPower: null,
    voltage: null,
    currentType: null,
    powerFactor: null,
  },
  {
    connectorId: 1,
    chargerId: chargers[0].chargerId,
    maxPower: null,
    voltage: null,
    currentType: null,
    powerFactor: null,
  },
  {
    connectorId: 2,
    chargerId: chargers[0].chargerId,
    maxPower: null,
    voltage: null,
    currentType: null,
    powerFactor: null,
  },
]
