import { Box, Button, Stack, useTheme } from '@mui/material'
import { ControlPosition, MapRef, useControl } from 'react-map-gl'
import { Feature, FeatureCollection, Polygon } from '@turf/helpers'
import { GeofenceProperties, PolygonFeature } from '@synop-react/types'
import { isGeofenceValid } from '../../utils'
import { MapProps } from '../Map'
import { useEditGeofenceMap } from './useGeofenceMap'
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw'
import React, { PropsWithChildren, useCallback } from 'react'

export type Geofence<T extends GeofenceProperties = GeofenceProperties> =
  Feature<Polygon, T>

type MapTileProps = PropsWithChildren<
  {
    geofence?: Geofence
  } & MapProps
>

type EditGeofenceMapProps = {
  /*
   * Must match the mapId of the map layers used as children
   */
  mapId: string
  MapTile: React.ComponentType<MapTileProps>
}

export const EditGeofenceMap = ({ mapId, MapTile }: EditGeofenceMapProps) => {
  const { palette } = useTheme()

  const {
    isEditingGeofence,
    setIsEditingGeofence,
    geofence,
    setGeofence,
    prevGeofence,
    setPrevGeofence,
    locationMarker,
  } = useEditGeofenceMap()

  const onCreate = useCallback<OnDrawCreateCallback>((e) => {
    const mapGeofences = e.features.filter(
      (feature) => feature.geometry.type === 'Polygon'
    ) as PolygonFeature[]
    setGeofence(mapGeofences[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUpdate = useCallback<OnDrawUpdateCallback>((e) => {
    const mapGeofences = e.features.filter(
      (feature) => feature.geometry.type === 'Polygon'
    ) as PolygonFeature[]
    setGeofence(mapGeofences[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDelete = useCallback<OnDrawDeleteCallback>(() => {
    /**
     * Because we dont show controls for deleting and delete and
     * backspace key commands do not flow through to the map
     * correctly, I dont know that there is anyway for this to
     * actually trigger.
     */
    setGeofence(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DrawControls = isEditingGeofence ? (
    <DrawControl
      defaultMode="draw_polygon"
      displayControlsDefault={false}
      onCreate={onCreate}
      onDelete={onDelete}
      onUpdate={onUpdate}
      position="top-left"
    />
  ) : null

  const UpdateGeofenceControls = (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 3,
      }}
    >
      <Button
        disabled={!isGeofenceValid(geofence?.geometry?.coordinates[0])}
        onClick={() => setIsEditingGeofence(false)}
        variant="contained"
      >
        SAVE GEOFENCE
      </Button>
      <Button
        onClick={() => {
          setGeofence(prevGeofence)
          setIsEditingGeofence(false)
        }}
        sx={{
          ml: 2,
          backgroundColor: palette.background.paper,
          '&:hover': {
            backgroundColor: palette.background.paper,
          },
        }}
        variant="outlined"
      >
        CANCEL
      </Button>
    </Stack>
  )

  const isUpdatingGeofence = geofence || prevGeofence
  const ToggleGeofenceControls = (
    <Box
      sx={{
        minWidth: '420px',
        position: 'relative',
        zIndex: 2,
      }}
    >
      <Button
        onClick={() => {
          setPrevGeofence(geofence)
          setGeofence(undefined)
          setIsEditingGeofence(true)
        }}
        sx={{
          maxWidth: '165px',
          backgroundColor: palette.background.paper,
          position: 'absolute',
          top: 8,
          left: 8,
          zIndex: 3,
          '&:hover': {
            backgroundColor: palette.background.paper,
          },
        }}
        variant="outlined"
      >
        {isUpdatingGeofence ? 'EDIT GEOFENCE' : 'CREATE GEOFENCE'}
      </Button>
    </Box>
  )

  const EditGeofenceControls = isEditingGeofence
    ? UpdateGeofenceControls
    : ToggleGeofenceControls

  return (
    <Box sx={{ position: 'relative', zIndex: 2 }}>
      {EditGeofenceControls}
      <Stack spacing={1}>
        <Box sx={{ width: '100%', height: '368px' }}>
          <MapTile
            geofence={geofence}
            id={mapId}
            latitude={locationMarker ? locationMarker[1] : undefined}
            longitude={locationMarker ? locationMarker[0] : undefined}
          >
            {DrawControls}
          </MapTile>
        </Box>
      </Stack>
    </Box>
  )
}

type OnDrawCreateCallback = (evt: DrawCreateEvent) => void
type OnDrawUpdateCallback = (evt: DrawUpdateEvent) => void
type OnDrawDeleteCallback = (evt: DrawDeleteEvent) => void

type DrawControlProps<T = unknown> = ConstructorParameters<
  typeof MapboxDraw
>[0] & {
  selectedFeatures?: FeatureCollection<Polygon, T>
  defaultSelect?: boolean
  position?: ControlPosition
  onCreate?: OnDrawCreateCallback
  onUpdate?: OnDrawUpdateCallback
  onDelete?: OnDrawDeleteCallback
}

export const DrawControl = ({
  selectedFeatures,
  onCreate = () => ({}),
  onUpdate = () => ({}),
  onDelete = () => ({}),
  position,
  ...drawProps
}: DrawControlProps) => {
  useControl<MapboxDraw>(
    () => new MapboxDraw({ ...drawProps }),
    ({ map }: { map: MapRef }) => {
      map.on('draw.create', onCreate)
      map.on('draw.update', onUpdate)
      map.on('draw.delete', onDelete)
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', onCreate)
      map.off('draw.update', onUpdate)
      map.off('draw.delete', onDelete)
    },
    {
      position: position,
    }
  )

  return null
}
