import { Button, Typography } from '@mui/material'
import { Cask, Crate, LifeBuoy, Table } from '@synop-react/common'
import {
  RootAPI,
  useCurrentOrganization,
  useFleetVehicles,
  VehicleModel,
} from '@synop-react/api'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface FleetsSettingsProps {}

const { useGetFleetsQuery } = RootAPI.synopRootAPI

export function FleetsSettings() {
  const [isFleetOverlayOpen, setIsFleetOverlayOpen] = useState(false)
  const [, /* selectedFleet */ setSelectedFleet] =
    useState<RootAPI.Fleet | null>(null)

  const { currentOrg } = useCurrentOrganization()
  const { data: fleets } = useGetFleetsQuery({ id: currentOrg?.id })

  const fleetColumns: Table.ColumnSpec<RootAPI.Fleet> = [
    { field: 'fleetNm', headerName: 'Name', width: 400 },
    {
      field: 'vehicleCount',
      headerName: 'Vehicles',
      width: 200,
      renderCell: (params) => <VehicleCount fleetId={params.row.id} />,
      type: 'number',
    },
  ]

  return (
    <Cask
      // Actions={
      //   <CreateOrEditFleetOverlay
      //     defaultIsOpen={false}
      //     fleetId={selectedFleet?.id ? selectedFleet.id : ''}
      //     // organizationId={currentOrg?.id}
      //     // setIsOpen={setIsFleetOverlayOpen}
      //     Trigger={
      //       <Button startIcon={<PlusCircle />} variant="outlined">
      //         New Fleet
      //       </Button>
      //     }
      //   />
      // }
      title="Fleets"
    >
      <Typography variant="body1">
        Add or adjust the fleets of your organization.
      </Typography>
      {fleets && fleets.length > 0 ? (
        <Table.ClientSide
          columns={fleetColumns}
          height="450px"
          onRowClick={(params) => {
            setSelectedFleet(params.row)
            setIsFleetOverlayOpen(!isFleetOverlayOpen)
          }}
          rowCount={fleets?.length || 0}
          tableData={fleets}
        />
      ) : (
        <Crate
          description="Your organization does not have any fleets."
          Icon={<LifeBuoy />}
        >
          <Button variant="outlined">Create new Fleet</Button>
        </Crate>
      )}
    </Cask>
  )
}

export interface VehicleCountProps {
  fleetId: string
}

export const VehicleCount = (props: VehicleCountProps) => {
  const { fleetId } = props
  const { fleetVehicles } = useFleetVehicles()
  if (fleetVehicles) {
    return (
      <Typography>
        {fleetVehicles[fleetId]
          ? (fleetVehicles[fleetId] as Array<VehicleModel>).length
          : 0}
      </Typography>
    )
  } else {
    return <Typography>{'Loading'}</Typography>
  }
}

export default FleetsSettings
