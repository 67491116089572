import { CurrentChargerStatus, CurrentConnectorStatus } from '@synop-react/api'
import {
  Grid,
  LinearProgress,
  Link,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import {
  useChargerStatusColors,
  useConnectorStatusColors,
} from '@synop-react/common'

interface RowProps {
  actionButton?: ReactNode
  entityUrl?: string
  status: CurrentConnectorStatus | CurrentChargerStatus
  statusTooltip?: string
  title: string
  titleColor?: string
  StartDetails?: ReactNode
  EndDetails?: ReactNode
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '250px',
  },
})

const useTitleOnOverflowRef = () => {
  const ref = useRef<HTMLElement>(null)

  // Checks if the ref's DOM element has a wider scroll width (which includes content
  // not visible on the screen due to overflow) than client width (which is the inner
  // width of the element). If so, sets the title attribute to the text content.
  const updateTitle = useCallback(() => {
    const element = ref.current
    if (!element) return
    if (element.scrollWidth > element.clientWidth) {
      element.setAttribute('title', element.innerText)
    } else {
      element.removeAttribute('title')
    }
  }, [])

  // Listen for changes to the element's size and update the attribute when it happens.
  // If the user's browser doesn't support ResizeObserver, this will do nothing.
  useEffect(() => {
    if (typeof ResizeObserver !== 'function') return

    const resizeObserver = new ResizeObserver(updateTitle)
    if (ref.current) resizeObserver.observe(ref.current)

    // Disconnect the observer when the component unmounts
    return () => resizeObserver.disconnect()
  }, [updateTitle])

  return ref
}

export const Row = ({
  actionButton = null,
  entityUrl,
  status,
  statusTooltip,
  title,
  StartDetails,
  EndDetails,
  titleColor = 'primary.main',
}: RowProps) => {
  const color = useStatusColor(status)
  const ref = useTitleOnOverflowRef()
  const titleText = (
    <Typography
      ref={ref}
      color={titleColor}
      overflow="hidden"
      textOverflow="ellipsis"
      variant="h6"
    >
      {title}
    </Typography>
  )

  return (
    <Grid container direction="column">
      <Grid container item>
        <LinearProgress
          color="inherit"
          sx={{ color, minWidth: '100%' }}
          value={100}
          variant="determinate"
        />
      </Grid>

      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="space-between"
        wrap="nowrap"
        xs={12}
      >
        <Grid flexGrow={1} item overflow="hidden">
          {entityUrl ? (
            <Link href={entityUrl} underline="none">
              {titleText}
            </Link>
          ) : (
            titleText
          )}
        </Grid>

        <Grid flexShrink={0} item sx={{ pl: 0.5 }}>
          <CustomTooltip title={statusTooltip}>
            <Typography color={color} variant="h6">
              {status}
            </Typography>
          </CustomTooltip>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        item
        justifyContent="space-between"
        xs={12}
      >
        <Grid item xs={6}>
          {actionButton}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        item
        justifyContent="space-between"
        xs={6}
      >
        <Grid item>{StartDetails}</Grid>
        <Grid item>{EndDetails}</Grid>
      </Grid>
    </Grid>
  )
}

function useStatusColor(status: CurrentConnectorStatus | CurrentChargerStatus) {
  const connectorColorMap = useConnectorStatusColors()
  const chargerColorMap = useChargerStatusColors()

  // "Operative" is the only status that can apply to a charger and not to a connector
  if (status === 'Operative') {
    return chargerColorMap.Operative
  } else {
    return connectorColorMap[status]
  }
}
