import { AlertTriangle, ChevronDown } from '../../Icons'
import {
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import {
  CustomDateRange,
  TimeRangeDropdown,
  useTimeRange,
} from '../../TimeRange'
import { MouseEvent, useEffect, useState } from 'react'
import { useFormat, useValidate } from '../../utils'
import { useLocalStorage } from 'react-use'
import { useRouting } from '../../utils'

const DASHBOARD_KEY = 'lastDashboard'
export const DASHBOARD_MAX_DATE_RANGE = 60

type DashboardHeaderProps = {
  title?: string
  showTimerange?: boolean
  showDashboardMenu?: boolean
  controls?: React.ReactNode[]
}

export const DashboardHeader = ({
  title = '',
  showTimerange = false,
  showDashboardMenu = true,
  controls,
}: DashboardHeaderProps) => {
  const { formatDateTime } = useFormat()
  const { from, to } = useTimeRange()

  const { location, navigate, routes } = useRouting()
  const { validateTimeRange } = useValidate()

  const [lastDashboard, setLastDashboard] = useLocalStorage<string>(
    DASHBOARD_KEY,
    routes.dashboard.chargers
  )

  useEffect(() => {
    // If we are on the base dashboard page navigate to the users last dashboard
    if (location.pathname === routes.dashboard.root && lastDashboard) {
      navigate(lastDashboard)
    } else if (
      location.pathname !== lastDashboard &&
      location.pathname !== routes.dashboard.root
    ) {
      // If we are on a dashboard page update the last dashboard
      setLastDashboard(location.pathname)
    }
  }, [lastDashboard, location.pathname, navigate, routes, setLastDashboard])

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget)
  }
  const handleMenuClose = (route?: string) => {
    setMenuAnchor(null)
    if (route && route !== location.pathname) navigate(route)
  }

  const errorContent = validateTimeRange(
    DASHBOARD_MAX_DATE_RANGE
  ).timeRangeErrorMessages

  return (
    <>
      <Grid item xs={12}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
        >
          <Grid item xs={6}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Typography variant="h3">{title}</Typography>
              {showDashboardMenu && (
                <IconButton onClick={handleMenuClick}>
                  <ChevronDown />
                </IconButton>
              )}
            </Stack>
            <Grid container>
              {showTimerange && (
                <Grid item xs={12}>
                  <Typography
                    sx={{ color: 'text.secondary' }}
                    variant="overline"
                  >
                    {formatDateTime(from, to).betweenLongFormDateTimes}
                  </Typography>
                </Grid>
              )}
              {errorContent.map((message) => (
                <Grid key={message} item xs={12}>
                  <AlertTriangle
                    sx={{
                      mr: 1,
                      width: '16px',
                      height: '16px',
                      color: 'error.main',
                      verticalAlign: 'middle',
                    }}
                  />
                  <Typography sx={{ color: 'error.main' }} variant="caption">
                    {message}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ width: 'auto', ml: 'auto', mr: 0 }}
              >
                <CustomDateRange
                  disableToFuture={true}
                  disableToPast={false}
                  maxRange={DASHBOARD_MAX_DATE_RANGE}
                />
                <TimeRangeDropdown />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: 'flex-end' }}
              >
                {controls &&
                  controls.map((control, idx) => (
                    <Container
                      // I believe this is a scenario where this is not a problem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`control-${idx}`}
                      disableGutters
                      sx={{ width: 'auto', ml: 'auto', mr: 0 }}
                    >
                      {control}
                    </Container>
                  ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <DashboardHeaderMenu
        handleMenuClose={handleMenuClose}
        menuAnchor={menuAnchor}
      />
    </>
  )
}

type DashboardHeaderMenuProps = {
  menuAnchor: null | HTMLElement
  handleMenuClose: (route?: string) => void
}

const DashboardHeaderMenu = ({
  menuAnchor,
  handleMenuClose,
}: DashboardHeaderMenuProps) => {
  const { routes } = useRouting()
  const open = Boolean(menuAnchor)
  return (
    <Menu
      anchorEl={menuAnchor}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClick={() => handleMenuClose()}
      onClose={() => handleMenuClose()}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem onClick={() => handleMenuClose(routes.dashboard.chargers)}>
        <Typography variant="h6">Energy</Typography>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose(routes.dashboard.vehicles)}>
        <Typography variant="h6">Vehicle</Typography>
      </MenuItem>
    </Menu>
  )
}
