import {
  AdminFeature,
  ChargerUptimeCask,
  CreateChargingSessionOverlay,
  DetailPageHeader,
  EditChargerOverlay,
  formatPower,
  Icons,
  Settings,
  useOverlay,
  useRouting,
} from '@synop-react/common'
import { Button, Grid, IconButton, ListItem, useTheme } from '@mui/material'
import { ChargerPanel } from '@synop-react/depot'
import { ChargerStatusCask } from './components/ChargerStatusCask'
import { ConnectorRow } from './components/ConnectorRow'
import { isNumber } from 'lodash'
import { RebootChargerOverlay } from './RebootChargerOverlay'
import {
  RootAPI,
  useChargerDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'
import { StartChargingSessionOverlay } from '../../../components/Overlays/StartChargingSessionOverlay'
import { StopChargingSessionOverlay } from '../../../components/Overlays/StopChargingSessionOverlay'
import { useState } from 'react'
import ChargerDetailsCask from './components/ChargerDetailsCask'
import ChargerUtilizationChartCask from './components/ChargerUtilization/ChargerUtilizationChartCask'
import ChargingSessionTabCaskView from './components/ChargingSessions/ChargingSessionTabCaskView'

export interface ChargerDetailsPageProps {
  charger: RootAPI.ChargerDetailModel
  isChargerSuccess: boolean
}

const ChargerDetailsPage = ({
  charger,
  isChargerSuccess = false,
}: ChargerDetailsPageProps) => {
  const {
    chargerId = '',
    chargerName,
    description,
    depotId,
    depotName = '',
    firmwareVersion,
    lastHeartbeatTimestamp,
    lastSession,
    chargerMakeModel,
    endpointAddress,
    ocppProtocol,
    chargerManufacturerModel,
    maxPower = 0,
    livePowerImport,
    connectorIds = [],
    connectorStatus: connectorStatuses,
  } = charger

  const { routes } = useRouting()
  const theme = useTheme()
  const { tzDayjs } = useUserPrefs()

  const [isChargingSessionOverlayOpen, setChargingSessionOverlayOpen] =
    useState(false)

  const [
    isStartChargingSessionOverlayOpen,
    setStartChargingSessionOverlayOpen,
  ] = useState(false)

  const [isStopChargingSessionOverlayOpen, setStopChargingSessionOverlayOpen] =
    useState(false)

  const [selectedConnectorId, setSelectedConnectorId] = useState<
    number | undefined
  >(undefined)

  const {
    getChargerTransactions: { data, isFetching, isLoading },
  } = useChargerDetailsFromPoll({
    chargerId,
    pollingIntervalInSeconds: 5,
  })
  const { items: chargingTransactions = [] } = (data ||
    {}) as RootAPI.PagedResponseTransactionSummaryModel

  const selectedChargingSession = selectedConnectorId
    ? lastSession?.[selectedConnectorId]
    : undefined

  const realConnectorIds =
    connectorIds?.filter((connectorId: number) => connectorId !== 0) || []

  // define manufacturer and model metadata
  const manufacturer = chargerManufacturerModel?.name || ''
  const model = chargerMakeModel?.chargerModel || ''

  // determine subtitle
  const subtitle = [`Charger ID: ${chargerId}`]
  if (lastHeartbeatTimestamp) {
    subtitle.push(
      [manufacturer, model, ocppProtocol].filter((s) => !!s).join(' • ')
    )
  } else {
    subtitle.push('Pending connection to OCPP server')
  }

  const depot: RootAPI.DepotModel = {
    depotId: depotId || '',
    depotNm: depotName,
  }

  const opts = { omitUnits: true }
  const roundedPowerImport = formatPower(livePowerImport ?? 0, opts)
  const roundedMaxPower = formatPower(maxPower, opts)
  const connectorPanelSubtitle = realConnectorIds.length
    ? `Using ${roundedPowerImport} of ${roundedMaxPower} kW`
    : `Connectors will populate here once the charger connects to Synop's OCPP server.`

  const rebootOverlay = useOverlay()

  return (
    <>
      <RebootChargerOverlay chargerId={chargerId} {...rebootOverlay} />
      {isChargingSessionOverlayOpen ? (
        <CreateChargingSessionOverlay
          defaultCharger={charger}
          defaultDepot={depot}
          isOpen={isChargingSessionOverlayOpen}
          setIsOpen={setChargingSessionOverlayOpen}
        />
      ) : null}
      {isStartChargingSessionOverlayOpen &&
      selectedConnectorId !== undefined ? (
        <StartChargingSessionOverlay
          chargerId={chargerId}
          connectorId={selectedConnectorId}
          depotName={depotName}
          isOpen={isStartChargingSessionOverlayOpen}
          setIsOpen={setStartChargingSessionOverlayOpen}
        />
      ) : null}
      {isStopChargingSessionOverlayOpen && selectedConnectorId !== undefined ? (
        <StopChargingSessionOverlay
          assetId={selectedChargingSession?.vehicleId || ''}
          chargerId={chargerId}
          chargingSessionId={selectedChargingSession?.sessionId || ''}
          connectorId={selectedConnectorId}
          depotName={depotName}
          isOpen={isStopChargingSessionOverlayOpen}
          setIsOpen={setStopChargingSessionOverlayOpen}
        />
      ) : null}
      <Grid container rowSpacing={theme.spacing(2)} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <DetailPageHeader
            breadcrumbLink={depotId && routes.siteDetails(depotId)}
            breadcrumbTitle={depotName}
            subtitle={subtitle}
            title={chargerName as string}
          >
            <Grid
              container
              justifyContent="flex-end"
              spacing={theme.spacing(2)}
            >
              <AdminFeature>
                <Grid item>
                  <EditChargerOverlay
                    chargerId={chargerId}
                    Trigger={
                      <IconButton
                        data-cy="edit-charger-button"
                        size="large"
                        sx={{ cursor: 'pointer', paddingY: '5px' }}
                      >
                        <Settings fontSize="medium" />
                      </IconButton>
                    }
                  />
                </Grid>
              </AdminFeature>
              <Grid item>
                <Button
                  color="error"
                  onClick={rebootOverlay.openOverlay}
                  variant="outlined"
                >
                  Reboot Charger
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setChargingSessionOverlayOpen(true)}
                  startIcon={
                    <Icons.Plus
                      strokeWidth={2}
                      sx={{ color: theme.palette.primary.contrastText }}
                    />
                  }
                  variant="contained"
                >
                  Schedule Charging Session
                </Button>
              </Grid>
            </Grid>
          </DetailPageHeader>
        </Grid>

        <Grid container item spacing={theme.spacing(2)} xs={12}>
          <Grid container item rowSpacing={theme.spacing(2)} xs={8}>
            <Grid item xs={12}>
              <ChargerStatusCask chargerId={chargerId} />
            </Grid>
            <Grid item xs={12}>
              <ChargerUtilizationChartCask
                chargerId={chargerId}
                powerCeiling={maxPower}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <ChargerPanel.Panel
              data={realConnectorIds}
              isFetching={isFetching || isLoading}
              isSuccess={isChargerSuccess}
              // NOTE: index is not the same as connectorId bc we filter out connectorId 0,
              // so be sure to use connectorId
              rowContent={(_index: number, connectorId: number) => {
                const csModel = connectorStatuses[connectorId]
                const connectorStatus =
                  csModel?.currentCombinedStatus ?? 'Unknown'
                const lastHeard = csModel?.statusTimestamp
                const utilization = charger.connectorUtilization?.[connectorId]
                const livePowerImport = utilization?.livePowerImportKw
                const livePowerExport = utilization?.livePowerExportKw
                const isExporting =
                  !livePowerImport &&
                  isNumber(livePowerExport) &&
                  livePowerExport > 0
                const livePower = isExporting
                  ? livePowerExport
                  : livePowerImport

                return (
                  <ListItem key={`connector-row-${connectorId}`} disableGutters>
                    <ConnectorRow
                      connectorId={connectorId}
                      currentStatus={connectorStatus}
                      currentTransaction={chargingTransactions.find(
                        (value) =>
                          value.connectorId === connectorId &&
                          tzDayjs(value.startTimestamp).isBefore(tzDayjs()) &&
                          (tzDayjs(value.stopTimestamp).isAfter(tzDayjs()) ||
                            !value.stopTimestamp)
                      )}
                      isExporting={isExporting}
                      lastHeard={lastHeard ? new Date(lastHeard) : undefined}
                      lastKnownStatus={csModel?.status}
                      livePower={livePower}
                      setSelectedConnectorId={setSelectedConnectorId}
                      setStartChargingSessionOverlayOpen={
                        setStartChargingSessionOverlayOpen
                      }
                      setStopChargingSessionOverlayOpen={
                        setStopChargingSessionOverlayOpen
                      }
                    />
                  </ListItem>
                )
              }}
              subtitle={connectorPanelSubtitle}
              title="Connectors"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ChargingSessionTabCaskView
            chargerId={chargerId}
            depotId={depotId || ''}
          />
        </Grid>

        <Grid item xs={12}>
          <ChargerUptimeCask chargerId={chargerId} />
        </Grid>

        <Grid item xs={12}>
          <ChargerDetailsCask
            chargerId={chargerId}
            chargerName={chargerName}
            description={description}
            firmwareVersion={firmwareVersion}
            model={model}
            ocppEndpoint={endpointAddress}
            ocppVersion={ocppProtocol}
            oem={manufacturer}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ChargerDetailsPage
