import { DateSelector } from '../DateSelector'
import { DateSelectorProps } from '../DateSelector/DateSelector'
import { Grid } from '@mui/material'
import { useUserPrefs } from '@synop-react/api'
import useTimeRange from './useTimeRange'

export type CustomSingleDateRangeProps = Omit<
  DateSelectorProps,
  'handleSelectedDate' | 'variant'
>

export const CustomSingleDateRange = (
  dateSelectorProps: CustomSingleDateRangeProps
) => {
  const { selectedTimeResolution, setCustomRange, options } = useTimeRange()
  const { tzDayjs } = useUserPrefs()

  const handleChange = (maybeDate: Date | null) => {
    const date = tzDayjs(maybeDate)
    if (date.isValid()) {
      const from = date.startOf('day')
      const toEnd = tzDayjs().isSame(date, 'day') ? date : date.endOf('day')
      const to = options.useEndOfCurrentDay ? date.endOf('day') : toEnd
      setCustomRange([from, to])
    }
  }

  const isCustom = selectedTimeResolution === 'CUSTOM'
  return isCustom ? (
    <Grid item>
      <DateSelector
        handleSelectedDate={handleChange}
        variant="outlined"
        {...dateSelectorProps}
      />
    </Grid>
  ) : null
}
