import { CsvColumn, CsvColumnSet } from '../download'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { OneOrMore } from '@synop-react/types'
import { Table } from '../../Table'

export type CsvField<T extends GridValidRowModel> = {
  csv: OneOrMore<CsvColumn<T>>
}

export type ReportField<T extends GridValidRowModel> = CsvField<T> & {
  column: Table.GridColumn<T>
}

export const getFieldColumns = <T extends GridValidRowModel>(
  ...reportFields: Array<ReportField<T> | CsvField<T>>
) =>
  reportFields.reduce(
    (acc, field) => {
      if ('column' in field) acc.tableColumns.push(field.column)

      // A ReportField can have one or more CSV columns. We need to differentiate between multiple
      // CSV columns and a single column which in some cases can be an array.
      const { csv } = field
      if (
        // If it's just a string...
        !Array.isArray(csv) ||
        // ...or if the first or second element is a string...
        typeof csv[0] === 'string' ||
        typeof csv[1] === 'string'
      ) {
        // ...then it's a single column...
        acc.csvColumns.push(csv as CsvColumn<T>)
      } else {
        // ...otherwise it's an array of columns.
        acc.csvColumns.push(...(csv as CsvColumnSet<T>))
      }

      return acc
    },
    {
      csvColumns: [] as CsvColumnSet<T>,
      tableColumns: [] as Table.GridColumn<T>[],
    }
  )
