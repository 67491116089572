import { List, ListProps } from '@mui/material'
import { ListItem, ListItemProps } from './ListItem'
import { NestedListItem } from './NestedListItem'
import { PropsWithChildren, ReactElement, useState } from 'react'
import React from 'react'

export type MenuProps = {
  children: ReactElement[]
} & ListProps

export function Menu({ children, ...ListProps }: MenuProps) {
  const [selectedItemIndex, setSelectedItemIndex] = useState<string>('0')
  const listItems = React.Children.map(
    children,
    (child: ReactElement<PropsWithChildren<ListItemProps>>, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          id: `${index}`,
          selectedItemId: selectedItemIndex,
          setSelectedId: setSelectedItemIndex,
        })
      }
      return child
    }
  )

  return (
    <List component="nav" {...ListProps}>
      {listItems}
    </List>
  )
}

Menu.ListItem = ListItem
Menu.NestedListItem = NestedListItem

export default Menu
