import { Dayjs } from 'dayjs'

import { GenericEvent } from '@synop-react/api'
import { TimelineWindow } from './useTimelineReducer'

export enum TimelineActions {
  SET_EVENTS = 'SET_EVENTS',
  SET_DISPLAYED_DAY = 'SET_DISPLAYED_DAY',
  SET_INNER_WINDOW = 'SET_INNER_WINDOW',
  DISPLAY_EVENT_OVERLAY = 'DISPLAY_EVENT_OVERLAY',
  CLOSE_EVENT_OVERLAY = 'CLOSE_EVENT_OVERLAY',
}

export type TimelineActionGenerators =
  | SetTimelineEvents
  | SetTimelineDisplayedDay
  | SetTimelineWindow
  | DisplayEventOverlay
  | CloseEventOverlay

export type TimelinePayload = ReturnType<TimelineActionGenerators>

export type TimelineDispatch = (value: TimelinePayload) => void

export type SetTimelineEvents = typeof setTimelineEvents
export const setTimelineEvents = (allEvents: GenericEvent[]) => ({
  type: TimelineActions.SET_EVENTS as const,
  payload: allEvents,
})

export type SetTimelineDisplayedDay = typeof setTimelineDisplayedDay
export const setTimelineDisplayedDay = (displayedDay: Dayjs) => ({
  type: TimelineActions.SET_DISPLAYED_DAY as const,
  payload: displayedDay,
})

export type SetTimelineWindow = typeof setTimelineWindow
export const setTimelineWindow = (window: TimelineWindow) => ({
  type: TimelineActions.SET_INNER_WINDOW as const,
  payload: window,
})

export type DisplayEventOverlay = typeof displayEventOverlay
export const displayEventOverlay = (event: GenericEvent) => ({
  type: TimelineActions.DISPLAY_EVENT_OVERLAY as const,
  payload: event,
})

export type CloseEventOverlay = typeof closeEventOverlay
export const closeEventOverlay = () => ({
  type: TimelineActions.CLOSE_EVENT_OVERLAY as const,
})

export const dispatchSetTimelineEvents =
  (dispatch: TimelineDispatch) => (scheduledEvents: GenericEvent[]) => {
    dispatch(setTimelineEvents(scheduledEvents))
  }

export const dispatchSetDisplayedDate =
  (dispatch: TimelineDispatch) => (displayedDay: Dayjs) =>
    dispatch(setTimelineDisplayedDay(displayedDay.startOf('day')))

export const dispatchSetInnerWindow =
  (dispatch: TimelineDispatch) => (window: TimelineWindow) =>
    dispatch(setTimelineWindow(window))

export const dispatchDisplayEventOverlay =
  (dispatch: TimelineDispatch) => (event: GenericEvent) =>
    dispatch(displayEventOverlay(event))

export const dispatchCloseEventOverlay = (dispatch: TimelineDispatch) => () =>
  dispatch(closeEventOverlay())
