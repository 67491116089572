import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material'
import { ChevronDown, HelpCircle } from '../../Icons'

const OemHelperAccordion = () => {
  return (
    <Accordion
      elevation={0}
      sx={{
        border: '1px solid rgba(62, 119, 254, 0.3)',
        borderRadius: 1,
        pr: 2,
        pl: 2,
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ChevronDown />}
        id="panel1a-header"
        sx={{ p: 0 }}
      >
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <HelpCircle sx={{ pr: 0.5 }} />
          <Typography sx={{ pl: 1 }} variant="subtitle2">
            Is your OEM / Manufacturer not listed?
          </Typography>
        </Stack>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Stack direction="column" spacing={2} sx={{ pl: 4, pr: 2 }}>
          <Typography variant="body2">
            We are constantly adding new chargers to our system. If you have a
            charger which is not listed, please fill our the form below and we
            will contact you when it is added.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="body2">
            <a
              color="inherit"
              href="https://forms.gle/DBXM9s2KAr3XN2Ec7"
              target="_blank"
            >
              Request to Add Charger
            </a>
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default OemHelperAccordion
