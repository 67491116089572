import {
  Card,
  Fade,
  Grid,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { Icon } from 'react-feather'
import React from 'react'

import { AuthPageHeader } from './AuthPageHeader'
import { AuthPageLogo } from './AuthPageLogo'

interface AuthPageProps {
  actions?: React.ReactNode
  fields: React.ReactNode
  footer?: React.ReactNode
  errorMessage?: string
  sx?: SxProps<Theme>
  subtitle?: string
  title: string
  Icon?: Icon
  logoUrl?: string
}

const AuthPage = ({
  actions,
  errorMessage,
  fields,
  footer,
  sx,
  subtitle,
  title,
  Icon,
  logoUrl,
}: AuthPageProps) => {
  const theme = useTheme()

  return (
    <Stack direction="column">
      <AuthPageLogo logoUrl={logoUrl} />
      <StyledCard elevation={8} sx={sx}>
        <Grid
          container
          direction="column"
          rowSpacing={theme.spacing(2)}
          sx={{ justifyContent: 'space-between' }}
        >
          <AuthPageHeader Icon={Icon} subtitle={subtitle} title={title} />
          <Grid item>
            <Fade in={!!errorMessage}>
              <Typography color="error.main" fontWeight="600">
                {errorMessage}
              </Typography>
            </Fade>
          </Grid>

          <Grid container direction="column" item rowSpacing={theme.spacing(2)}>
            {fields}
          </Grid>
          <Grid
            container
            direction="row"
            item
            spacing={theme.spacing(2)}
            sx={{ marginTop: theme.spacing(1) }}
          >
            {actions}
          </Grid>
          {footer && (
            <Grid container direction="row" item>
              {footer}
            </Grid>
          )}
        </Grid>
      </StyledCard>
    </Stack>
  )
}

const StyledCard = styled(Card)(({ theme }) => ({
  alignItems: 'flex-start',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
}))

export default AuthPage
