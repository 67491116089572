import * as IsReact from 'react-is'
import { ReactElement, useEffect } from 'react'
import { SourceProps, useMap } from 'react-map-gl'

export type MapLayerChildren =
  | ReactElement<SourceProps>
  | Array<ReactElement<SourceProps>>

export interface MapAssetsProps {
  mapId: string
  children: MapLayerChildren
  layerAssets?: Record<string, HTMLImageElement | string>
}

export function MapAssets({
  mapId,
  children,
  layerAssets = {},
}: MapAssetsProps) {
  const { [mapId]: mapComponent } = useMap()

  useEffect(() => {
    for (const [assetName, asset] of Object.entries(layerAssets)) {
      const isImagePath = typeof asset === 'string'
      const imagePath = isImagePath ? asset : asset.src
      mapComponent?.loadImage(imagePath, (error, image) => {
        if (!error) {
          const loadedImage = isImagePath ? image : asset

          if (!mapComponent.hasImage(assetName) && loadedImage) {
            const imageOptions = { sdf: true } // TODO: make sure this only gets passed in if it's SDF enabled
            mapComponent.addImage(assetName, loadedImage, imageOptions)
          }
        }
      })
    }
  }, [mapComponent, layerAssets])

  const wrappedChildren = IsReact.isElement(children) ? (
    children
  ) : (
    // In this case children is an array and needs to be wrapped

    <>{children}</>
  )

  return wrappedChildren
}

export default MapAssets
