import { useMemo } from 'react'

import {
  selectDisplayedEntities,
  useBrowserSelector,
  useEntityBrowser,
} from '../../Browser/useEntityBrowser'
import { SynopMap } from '../../..'

export const FullscreenEntityMap = () => {
  const displayedEntities = useBrowserSelector(selectDisplayedEntities)

  const {
    visibleEntityIds,
    mapConfig: { getCoords, FullscreenMapLayers, MapLegend },
  } = useEntityBrowser()

  const displayedEntitiesCollection = useMemo(
    () => getCoords(displayedEntities),
    // Only updated the bounded displayed entities when the filtered entities change
    // This allows polling updates to not change the map view
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleEntityIds]
  )

  return FullscreenMapLayers ? (
    <SynopMap boundedData={displayedEntitiesCollection} id="entityMap">
      {FullscreenMapLayers}
      {MapLegend}
    </SynopMap>
  ) : null
}
