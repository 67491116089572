import { Entity } from '@synop-react/types'
import { EntityFilterer } from '../../../..'
import {
  parseEntityMapToBoolMap,
  useEntityBrowser,
} from '../../useEntityBrowser'
import { SearchInput, SearchInputProps } from '../../../../SearchInput'

export type EntitySearchInputProps<T> = {
  filterFn: EntityFilterer<T>
} & Omit<SearchInputProps, 'filterFn'>

export function EntitySearchInput<T extends Entity>({
  filterFn,
  ...searchProps
}: EntitySearchInputProps<T>) {
  const { setDisplayedEntities, allEntities } = useEntityBrowser<T>()

  const filterEntities = (searchString: string) => {
    const filteredEntities =
      searchString === ''
        ? parseEntityMapToBoolMap(allEntities)
        : filterFn(searchString, allEntities)
    setDisplayedEntities(filteredEntities)
  }

  return <SearchInput filterFn={filterEntities} isAlwaysOpen {...searchProps} />
}

export default EntitySearchInput
