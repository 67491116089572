import { emDash } from './constants'
import {
  formatUnitDesc,
  formatUnits,
  formatUnitsAbbr,
  UnitsFormat,
} from './units'

export type TemperatureUnit = 'celsius' | 'fahrenheit'

const temperatureFormats: UnitsFormat<TemperatureUnit> = {
  celsius: {
    long: 'Celsius',
    short: '°C',
  },
  fahrenheit: {
    long: 'Fahrenheit',
    short: '°F',
  },
}

export const formatTemperatureUnits = formatUnits(temperatureFormats)
export const formatTemperatureUnitsAbbr = formatUnitsAbbr(temperatureFormats)
export const formatTemperatureUnitsDesc = formatUnitDesc(temperatureFormats)

type TemperatureOptions = {
  longForm: boolean
  sigFigs: number
}
const defaultTemperatureOptions: TemperatureOptions = {
  longForm: false,
  sigFigs: 0,
}

export const formatTemperature = (
  temperature?: number | string,
  temperatureUnits: TemperatureUnit = 'celsius',
  options: Partial<TemperatureOptions> = defaultTemperatureOptions
) => {
  const { longForm, sigFigs } = { ...defaultTemperatureOptions, ...options }

  temperatureUnits = temperatureUnits.toLowerCase() as TemperatureUnit

  const units = longForm
    ? formatTemperatureUnits(temperatureUnits)
    : formatTemperatureUnitsAbbr(temperatureUnits)
  if (typeof temperature !== 'string' && typeof temperature !== 'number')
    return emDash

  const temperatureValue =
    sigFigs > 0
      ? parseFloat(`${temperature}`).toFixed(sigFigs)
      : parseInt(`${temperature}`)

  return `${temperatureValue}${units}`
}
