import { Card, CardContent, Grid } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from '@synop-react/common'
import { PropsWithChildren } from 'react'

type FallbackProps = {
  error: Error
  resetErrorBoundary: () => void
}

function FallbackWrapper(props: FallbackProps) {
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={6}>
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <Fallback {...props} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export const MainErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackWrapper}>
      {children}
    </ErrorBoundary>
  )
}
