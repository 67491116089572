import { Depot } from '@synop-react/api'
import {
  DepotFeatures,
  DepotLocationProperties,
  GeofenceProperties,
  isDefined,
  Maybe,
  PolygonFeature,
} from '@synop-react/types'
import { EntityCoordAccessor, isGeofenceValid } from '@synop-react/common'
import {
  featureCollection,
  Point,
  point,
  Polygon,
  polygon,
  Position,
} from '@turf/helpers'

export const DEPOT_MARKER_TYPE = 'POINT'
export const DEPOT_GEOFENCE_TYPE = 'POLYGON'

export const getDepotLocationCollection: EntityCoordAccessor<Depot> = (
  depots
) => {
  const depotFeatures = Object.values(depots).flatMap((depot) => {
    const features = getDepotFeatures(depot)
    return Object.values(features).filter(isDefined)
  })

  return featureCollection<
    Point | Polygon,
    DepotLocationProperties | GeofenceProperties
  >(depotFeatures)
}

type GetDepotFeaturesInput = {
  depotId: string
  latitude?: number
  longitude?: number
  geofence?: string | PolygonFeature
  numChargers?: number
  depotNm?: string
}

export function getDepotFeatures(depot?: undefined): undefined
export function getDepotFeatures(depot: GetDepotFeaturesInput): DepotFeatures
export function getDepotFeatures(
  depot: Maybe<GetDepotFeaturesInput>
): Maybe<DepotFeatures>

export function getDepotFeatures(
  depot: GetDepotFeaturesInput | undefined
): Maybe<DepotFeatures> {
  if (!depot) return

  const { depotId, latitude, longitude, geofence, numChargers, depotNm } = depot
  const depotMarker =
    latitude && longitude
      ? point([longitude, latitude], {
          type: DEPOT_MARKER_TYPE,
          id: depotId,
          depotNm,
          numChargers,
        })
      : undefined

  if (typeof geofence === 'string') {
    const geoFencePositions: Position[] = JSON.parse(geofence)
    const depotGeofence = isGeofenceValid(geoFencePositions)
      ? polygon<GeofenceProperties>([geoFencePositions], {
          type: DEPOT_GEOFENCE_TYPE,
        })
      : undefined
    return { depotMarker, depotGeofence }
  } else {
    return { depotMarker, depotGeofence: geofence }
  }
}
