import { alpha, Chip } from '@mui/material'
import { MfaStatus } from '@synop-react/api'
import { usePalette } from '@synop-react/theme'

const UNKNOWN_MFA_STATUS = 'UNKNOWN' as const
type MaybeMfaStatus = MfaStatus | typeof UNKNOWN_MFA_STATUS

type MfaStatusChipProps = {
  mfaStatus?: MaybeMfaStatus
}

export const MfaStatusChip = ({
  mfaStatus = UNKNOWN_MFA_STATUS,
}: MfaStatusChipProps) => {
  const { palette, charting } = usePalette()

  const statusChipConfig: Record<MaybeMfaStatus, string> = {
    COMPLETED: palette.success.main,
    INCOMPLETE: charting[1].main,
    NOT_APPLICABLE: charting[3].main,
    UNKNOWN: palette.secondary.main,
  }

  const color =
    statusChipConfig[mfaStatus] ?? statusChipConfig[UNKNOWN_MFA_STATUS]
  const backgroundColor = alpha(color, 0.04)

  const mfaStatusLabel = getMfaStatusLabel(mfaStatus ?? UNKNOWN_MFA_STATUS)

  return (
    <Chip
      label={mfaStatusLabel}
      size="small"
      sx={{
        color,
        borderColor: color,
        bgcolor: backgroundColor,
      }}
      variant="outlined"
    />
  )
}

export const getMfaStatusLabel = (status: MaybeMfaStatus) => {
  const statusLabels: Record<MaybeMfaStatus, string> = {
    COMPLETED: 'Enabled',
    INCOMPLETE: 'Disabled',
    NOT_APPLICABLE: 'Disabled',
    UNKNOWN: 'Unknown',
  }

  return statusLabels[status]
}
