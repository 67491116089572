import { emDash } from './constants'
import { EnergyUnit, energyUnits } from '@synop-react/api'
import { formatUnits, formatUnitsAbbr, UnitsFormat } from './units'
import { roundValueForFormatting } from './power'

const energyFormats: UnitsFormat<EnergyUnit> = {
  wh: {
    long: 'Watt Hour',
    short: 'Wh',
  },
  kwh: {
    long: 'Kilowatt Hour',
    short: 'kWh',
  },
  mwh: {
    long: 'Megawatt Hour',
    short: 'MWh',
  },
  gwh: {
    long: 'Gigawatt Hour',
    short: 'GWh',
  },
  twh: {
    long: 'Terawatt Hour',
    short: 'TWh',
  },
  pwh: {
    long: 'Petawatt Hour',
    short: 'PWh',
  },
}

export const formatEnergyUnits = formatUnits(energyFormats)
export const formatEnergyUnitsAbbr = formatUnitsAbbr(energyFormats)

type EnergyOptions = {
  longForm: boolean
  sigFigs: number
}
const defaultEnergyOptions: EnergyOptions = {
  longForm: false,
  sigFigs: 1,
}
export const formatEnergy = (
  energy: number | string = '',
  energyUnit: EnergyUnit = 'kwh',
  options = defaultEnergyOptions
) => {
  const { longForm, sigFigs } = { ...defaultEnergyOptions, ...options }

  energyUnit = energyUnit.toLowerCase() as EnergyUnit

  const units = longForm
    ? formatEnergyUnits(energyUnit)
    : formatEnergyUnitsAbbr(energyUnit)

  if (typeof energy !== 'string' && typeof energy !== 'number') {
    return emDash
  }

  const energyValue =
    sigFigs > 0
      ? parseFloat(`${energy}`).toFixed(sigFigs)
      : parseInt(`${energy}`)

  return (energyValue as number) >= 0 ? [energyValue, units].join(' ') : emDash
}

type ReduceWattHoursOutput = {
  num: number
  unit: EnergyUnit
}

export function reduceWattHours(
  num: number,
  unit: EnergyUnit = 'wh'
): ReduceWattHoursOutput {
  if (num >= 1000) {
    const nextUnit = energyUnits[energyUnits.indexOf(unit) + 1]
    if (nextUnit) return reduceWattHours(num / 1000, nextUnit as EnergyUnit)
  }

  num = roundValueForFormatting(num)
  return { num, unit }
}

export function convertWattHours(
  num: number,
  currentUnit: EnergyUnit,
  desiredUnit: EnergyUnit
): number {
  const currentUnitIndex = energyUnits.indexOf(currentUnit as EnergyUnit)
  const desiredUnitIndex = energyUnits.indexOf(desiredUnit as EnergyUnit)
  if (currentUnitIndex === -1 || desiredUnitIndex === -1) {
    console.error(
      `Invalid Energy Unit: Current: ${currentUnit} Desired: ${desiredUnit}`
    )
    return num
  }
  const diff = desiredUnitIndex - currentUnitIndex
  const convertedNum = num / Math.pow(1000, diff)
  return convertedNum
}
