import { rest } from 'msw'
import mockData from '../data'

export const usersHandlers = [
  rest.get('/api/users/:userId', (_req, res, ctx) => {
    return res(ctx.json(mockData.fleets.fleet))
  }),
]

export const preferredUnitsHandler = [
  rest.get('/api/ui/options', (_req, res, ctx) => {
    return res(ctx.json(mockData.users.preferredUnitOptions))
  }),
]
