import { ChevronRight } from '@mui/icons-material'
import {
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import { useMemo } from 'react'

import {
  CellWithIcon,
  ChargerOrConnectorDatum,
  ChargerTableColDef,
  EntityProp,
  HeaderWithMargin,
} from '../common'
import { Table, useRouting } from '@synop-react/common'

// The width of the space reserved for the chevron icon to the left of the name.
// This is not actually the width of the chevron icon itself.
const CHEVRON_WIDTH = 30

type NameColumnProps = GridRenderCellParams<ChargerOrConnectorDatum>

function NameColumn({ rowNode, row }: NameColumnProps) {
  const { setRowChildrenExpansion, state } = useGridApiContext().current
  const { filteredDescendantCountLookup } = state.filter
  const { id } = row
  const childrenExpanded =
    rowNode.type === 'group' ? rowNode.childrenExpanded : false

  const chevron = useMemo(() => {
    if (filteredDescendantCountLookup[id] === 0) return null

    return (
      <ChevronRight
        // Toggle the row's expansion state when the chevron is clicked
        onClick={(e) => {
          setRowChildrenExpansion(id, !childrenExpanded)
          e.stopPropagation()
        }}
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
          // Rotate the chevron if the row is expanded
          transform: childrenExpanded ? 'rotate(90deg)' : 'rotate(0)',
          transition: 'all 0.2s ease-in-out',
        }}
      />
    )
  }, [
    id,
    childrenExpanded,
    setRowChildrenExpansion,
    filteredDescendantCountLookup,
  ])

  return (
    <CellWithIcon icon={chevron} width={CHEVRON_WIDTH}>
      <NameLink entity={row} />
    </CellWithIcon>
  )
}

function NameLink({ entity }: EntityProp) {
  const { routes } = useRouting()
  const { name, isCharger } = entity
  if (!isCharger) return <>{name}</>

  const { id } = entity
  return (
    <Table.Link href={routes.chargers.details(id)} weight={700}>
      {name}
    </Table.Link>
  )
}

export default {
  field: 'name',
  headerName: 'Name',
  width: 200 + CHEVRON_WIDTH,
  renderCell: (params) => <NameColumn {...params} />,
  renderHeader: () => (
    <HeaderWithMargin colName="Name" margin={CHEVRON_WIDTH} />
  ),
  sortable: true,
  sortComparator: (v1, v2) => {
    if (!v1) return 1
    if (!v2) return -1
    return v1.localeCompare(v2)
  },
  valueGetter: ({ row }) => row.name,
} as ChargerTableColDef<'name'>
