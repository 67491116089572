import { EventOptions } from '../useTimeline/Provider'
import { extractFrequency, extractRecurrenceName } from './utils'
import { formatPower } from '../../../utils'
import { UseEventOptionsProps } from '.'
import { useFormat } from '../../../utils/hooks'
import { usePalette } from '@synop-react/theme'

type UseDepotEventProps = {
  depotId?: string
} & UseEventOptionsProps

const useDepotEventOption = ({
  renderOverlay,
  subtitle,
}: UseDepotEventProps): Pick<EventOptions, 'SITE_LIMIT'> => {
  const { formatDateTime } = useFormat()
  const { charting } = usePalette()

  return {
    SITE_LIMIT: {
      barColor: ({ eventRecurrence }) =>
        eventRecurrence ? charting[7].main : charting[8].main,
      caption: {
        title: ({ eventRecurrence }) => {
          return `Site Event` + (eventRecurrence ? ' (Recurring)' : '')
        },
        titleDescription: subtitle,
      },
      tooltip: {
        title: ({ curtailedSiteLimit }) => {
          return curtailedSiteLimit != undefined
            ? curtailedSiteLimit >= 0
              ? 'Site Curtailment Event'
              : 'Site Export Event'
            : 'Unknown Event'
        },
        details: [
          (e) => extractRecurrenceName(e.eventRecurrence),
          (e) => extractFrequency(e.eventRecurrence),
          ({ curtailedSiteLimit }) => ({
            label:
              curtailedSiteLimit != null && curtailedSiteLimit != undefined
                ? curtailedSiteLimit >= 0
                  ? 'Max Power Import'
                  : 'Max Power Export'
                : 'Site Limit',
            detail:
              curtailedSiteLimit != null
                ? formatPower(Math.abs(curtailedSiteLimit))
                : 'No Limit Specified',
          }),
          ({ scheduledStart }) => ({
            label: 'Start',
            detail: formatDateTime(scheduledStart).timeDotDate,
          }),
          ({ scheduledEnd }) => ({
            label: 'End',
            detail: formatDateTime(scheduledEnd).timeDotDate,
          }),
        ],
      },
      renderOverlay,
    },
  }
}

export default useDepotEventOption
