import { Checkbox, ListItemText, MenuItem } from '@mui/material'
import {
  Context,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { keyBy } from 'lodash'

import { Dropdown } from '../../Dropdown'
import { Entity, EntityIdMap, Id, Narrow } from '@synop-react/types'
import { parseToEntities, useCurrentOrgId } from '@synop-react/api'
import { useLocalStorageState, useSort } from '../../utils'

type SelectorContext<T, S> = Context<SelectorState<T, S>>
type DataHook<T> = () => T[]
type OptionFilterFn<T> = (option: T) => boolean
type ProviderProps<T> = PropsWithChildren<{
  initialFilterFn?: OptionFilterFn<T> | null
  syncToLocalStorage?: boolean
}>

export type SelectorState<T, S = T> = {
  multiselect: boolean
  options: T[]
  optionFilter: OptionFilterFn<T> | null
  selected: S | null
  setOptionFilter: (f: OptionFilterFn<T> | null) => void
  setSelected: (selectee: S | null) => void
}

type SelectorFactoryArgs<T, S> = {
  context: SelectorContext<T, S>
  nameField: Narrow.MaybeStringKeyOf<T>
  unselectedOption: string
}

type SelectionFn<T> = (selection: T | null) => void
type SelectorFieldProps<T, S> = {
  entities: T[]
  nameField: Narrow.MaybeStringKeyOf<T>
  selected: S | null
  setSelected: SelectionFn<S>
  unselectedOption: string
}

type SelectionContext = {
  orgId: string
}

// Factory for creating new Selector context providers
function selectorProviderFactory<T, S>(
  context: SelectorContext<T, S>,
  useData: DataHook<T>,
  nameField: Narrow.MaybeStringKeyOf<T>,
  multiselect: boolean
) {
  return function SelectorContextProvider({
    children,
    initialFilterFn = null,
    syncToLocalStorage = false,
  }: ProviderProps<T>) {
    const currentOrgId = useCurrentOrgId()
    const options = useData()

    const [selected, setSelected] = useLocalStorageState<S, SelectionContext>(
      `${nameField as string}Selector`,
      {
        skip: !syncToLocalStorage,
        // Clear the selection when the active organization changes. This will
        // happen whenever the user cloaks as a different org.
        invariantFn: useCallback(
          ({ context }: { context: SelectionContext }) => {
            // Don't clear the selection if we haven't received the current org ID yet
            if (!currentOrgId || context.orgId === currentOrgId) return null
            return `current org ID changed: was "${context.orgId}", now "${currentOrgId}"`
          },
          [currentOrgId]
        ),
      }
    )

    const updateSelection = useCallback(
      (selection: S | null) => setSelected(selection, { orgId: currentOrgId }),
      [currentOrgId, setSelected]
    )

    // Initial filter function must be passed to useState wrapped in a function because when
    // `useState` receives a function it calls it and uses the return value as the initial state.
    const [optionFilter, setOptionFilterFn] = useState(() => initialFilterFn)

    // Similarly, updating the filter function must also wrap the new function in a function when
    // passed to `useState`
    const setOptionFilter = useCallback((fn: OptionFilterFn<T> | null) => {
      setOptionFilterFn(() => fn)
    }, [])

    return (
      <context.Provider
        value={{
          multiselect,
          options,
          optionFilter,
          selected,
          setSelected: updateSelection,
          setOptionFilter,
        }}
      >
        {children}
      </context.Provider>
    )
  }
}

// Factory for creating new Selector contexts. The default state for all contexts is an empty array
// of `options`, a null `selected` value, and a no-op `setSelected` function. The key input is just
// the type of the entity.
const defaultState = {
  multiselect: false,
  options: [],
  optionFilter: null,
  setOptionFilter: () => null,
  selected: null,
  setSelected: () => null,
}

function selectorContextFactory<T, S>() {
  return createContext<SelectorState<T, S>>(defaultState)
}

// Factory for creating new entity-specific Selector components
function selectorComponentFactory<T extends Entity, S>({
  context,
  nameField,
  unselectedOption,
}: SelectorFactoryArgs<T, S>) {
  return function Selector() {
    const { multiselect, selected, setSelected, options, optionFilter } =
      useContext(context)

    if (multiselect) {
      return (
        <MultiEntitySelector
          entities={options.filter(optionFilter ?? (() => true))}
          nameField={nameField}
          selected={selected as unknown as T[]}
          setSelected={setSelected as SelectionFn<T[]>}
          unselectedOption={unselectedOption}
        />
      )
    } else {
      return (
        <EntitySelector
          entities={options.filter(optionFilter ?? (() => true))}
          nameField={nameField}
          selected={selected as unknown as T}
          setSelected={setSelected as SelectionFn<T>}
          unselectedOption={unselectedOption}
        />
      )
    }
  }
}

// The main selectorFactory function creates and exports a set of Selector components, contexts, and
// providers using the provided data-fetching hook, "name field" (i.e. the field to display in the
// dropdown), and default value to display when no entity is selected.
export function selectorFactory<T extends Entity, S = T>(
  useData: DataHook<T>,
  nameField: Narrow.MaybeStringKeyOf<T>,
  unselectedOption: string,
  multiselect = false
) {
  const SelectorContext = selectorContextFactory<T, S>()
  const SelectorProvider = selectorProviderFactory(
    SelectorContext,
    useData,
    nameField,
    multiselect
  )

  const Selector = selectorComponentFactory<T, S>({
    context: SelectorContext,
    nameField,
    unselectedOption,
  })

  return {
    Selector,
    Context: SelectorContext,
    Provider: SelectorProvider,
    useSelector: () => {
      const context = useContext(SelectorContext)

      // Provide some utilities on top of the context data
      const { options } = context
      const optionMap: EntityIdMap<T> = useMemo(
        () => keyBy(options, 'id'),
        [options]
      )

      const lookup = useCallback(
        (id?: Id) => (id ? optionMap[id] : undefined),
        [optionMap]
      )

      return { ...context, optionMap, lookup }
    },
  }
}

// `MultiEntitySelector` is a generic dropdown component that displays a list of entities and
// supports selecting multiple at once
const MultiEntitySelector = <T extends Entity>({
  entities,
  nameField,
  selected = [],
  setSelected,
  unselectedOption,
}: SelectorFieldProps<T, T[]>) => {
  const sorted = useSort(entities, nameField)
  const entityMap = useMemo(
    () => parseToEntities(entities, 'id').entities,
    [entities]
  )

  const selectedIds = selected?.map(({ id }) => id)
  return (
    <Dropdown
      multiple
      onChange={(e) => {
        const targetValues = e.target.value
        const newIds =
          typeof targetValues === 'string' ? [targetValues] : targetValues
        handleSelect(newIds)
      }}
      renderValue={(selected) => {
        return selected.length > 0
          ? selected
              .flatMap((selectedId) => {
                const selectedEntity = entityMap[selectedId]
                return selectedEntity ? [selectedEntity[nameField]] : []
              })
              .join(', ')
          : unselectedOption
      }}
      sx={{ maxWidth: '248px' }}
      unselectedOption={unselectedOption}
      value={selectedIds}
    >
      {sorted.map((entity) => {
        return (
          <MenuItem key={entity.id} value={entity.id}>
            <Checkbox checked={selectedIds?.includes(entity.id)} />
            <ListItemText primary={Narrow.getKeyOf(entity, nameField)} />
          </MenuItem>
        )
      })}
    </Dropdown>
  )
  // Updates the selected entity when the dropdown value changes. If the `id` is not found in the
  // option list, the selected entity is set to null. This is a bit of a hack to support the "All"
  // option, which is not an entity.
  function handleSelect(ids: Id[]) {
    const selectedOptions = entities.filter((entity) => ids.includes(entity.id))
    setSelected(selectedOptions.length ? selectedOptions : null)
  }
}

// `EntitySelector` is a generic dropdown component that displays a list of entities
const EntitySelector = <T extends Entity>({
  entities,
  nameField,
  selected,
  setSelected,
  unselectedOption,
}: SelectorFieldProps<T, T>) => {
  const sorted = useSort(entities, nameField)
  const ids = useMemo(() => sorted.map((entity) => entity.id), [sorted])

  return (
    <Dropdown
      onChange={(e) => handleSelect(e.target.value as string)}
      unselectedOption={unselectedOption}
      value={selected && ids.includes(selected.id) ? selected.id : undefined}
    >
      {sorted.map((entity) => (
        <MenuItem key={entity.id} value={entity.id}>
          {Narrow.getKeyOf(entity, nameField)}
        </MenuItem>
      ))}
    </Dropdown>
  )

  // Updates the selected entity when the dropdown value changes. If the `id` is not found in the
  // option list, the selected entity is set to null. This is a bit of a hack to support the "All"
  // option, which is not an entity.
  function handleSelect(id: Id) {
    const selected = entities.find((entity) => entity.id === id)
    setSelected(selected ?? null)
  }
}
