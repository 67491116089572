import { Clock } from '../../Icons'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { useTheme } from '@mui/material'
import AlertBar from '../AlertBar'
import dayjs from 'dayjs'

const { useUpdateUserMutation } = RootAPI.synopRootAPI

export const TimezoneAlertBar = () => {
  const { palette } = useTheme()
  const { user, synopUser } = useCurrentUser()

  const [updateUser] = useUpdateUserMutation()

  const { preferredTimeZone: savedTimezone } = synopUser || {}

  const currentTimezone = dayjs.tz.guess()
  const timezonePrefKey = `${user?.username}-lastTimezone`

  const updateTimezone = () => {
    localStorage.removeItem(timezonePrefKey)
    updateUser({
      userModel: { ...synopUser, preferredTimeZone: currentTimezone },
    })
  }

  const getPreviousTimezone = () => {
    return localStorage.getItem(timezonePrefKey)
  }

  const ignoreCurrentTimezone = () => {
    localStorage.setItem(timezonePrefKey, currentTimezone)
  }

  const hasTimezone = !!savedTimezone
  const previousTimezone = getPreviousTimezone()

  const shouldPromptUpdate =
    currentTimezone !== savedTimezone && previousTimezone !== currentTimezone

  const formatTimezone = (timezone: string) =>
    timezone.replace(/\//, ' - ').replace(/_/, ' ')

  const boldTimezone = (timezone: string) => (
    <strong>{formatTimezone(timezone)}</strong>
  )

  const timezoneMessge = hasTimezone ? (
    <>
      It looks like you're outside of your saved timezone{': '}
      {boldTimezone(savedTimezone)}. Would you like to update your timezone to
      {': '}
      {boldTimezone(currentTimezone)}?
    </>
  ) : (
    <>
      You currently do not have a timezone set. Would you like to update your
      timezone to {boldTimezone(currentTimezone)}?
    </>
  )

  const timezoneTitle = hasTimezone ? 'Update Time Zone' : 'Set Time Zone?'

  return shouldPromptUpdate ? (
    <AlertBar
      action={updateTimezone}
      actionText="UPDATE TIMEZONE"
      barColor={palette.secondary['4p'] as string}
      icon={<Clock sx={{ color: 'secondary.main' }} />}
      message={timezoneMessge}
      onClose={ignoreCurrentTimezone}
      textColor={palette.secondary.main}
      title={timezoneTitle}
    />
  ) : null
}

export default TimezoneAlertBar
