import * as yup from 'yup'
import { getDatadogConfig } from './getDatadogConfig'
import { RumInitConfiguration } from '@datadog/browser-rum'
import getAmplifyConfig from './getAmplifyConfig'

const schema = yup
  .object({
    APP_VERSION: yup.string(),
    NX_ENV: yup.string().required(),
    NX_ROLLBAR_CLIENT_ACCESS_TOKEN: yup.string().required(),
    NX_ROLLBAR_ENV: yup.string().required(),
  })
  .required()

export interface Config {
  APP_VERSION: string
  ROLLBAR_CLIENT_ACCESS_TOKEN: string
  ROLLBAR_ENV: string
  NX_SYNOP_API_URL: string
  NX_SYNOP_NEW_API_URL: string
  OCPP_WEBSOCKET_URL: string
  // Amplify itself sets this as 'any' vs. giving us a type to use
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amplify: any
  datadog: RumInitConfiguration | null
}

export const getEnvConfig = (): Config => {
  const environment = schema.validateSync(process.env)
  const envName = environment.NX_ENV
  const appVersion = environment.APP_VERSION ?? ''

  const configs = {
    beta: {
      NX_SYNOP_API_URL:
        'https://9lykjw9zli.execute-api.us-east-1.amazonaws.com/gamma',
      NX_SYNOP_NEW_API_URL:
        'https://9lykjw9zli.execute-api.us-east-1.amazonaws.com/gamma',
      OCPP_WEBSOCKET_URL: 'chargers.gamma.synop.ai/charger',
      amplify: getAmplifyConfig({
        awsCognitoRegion: 'us-east-1',
        awsProjectRegion: 'us-east-1',
        awsUserPoolsId: 'us-east-1_n6ZExRe7q',
        awsUserPoolsWebClientId: '6qg1ucqdedhd6vbh2j3i6f6qga',
        oauthDomain: 'synop-dev.auth.us-east-1.amazoncognito.com',
      }),
      datadog: getDatadogConfig('beta', appVersion),
    },
    gamma: {
      NX_SYNOP_API_URL:
        'https://9lykjw9zli.execute-api.us-east-1.amazonaws.com/gamma',
      NX_SYNOP_NEW_API_URL:
        'https://9lykjw9zli.execute-api.us-east-1.amazonaws.com/gamma',
      OCPP_WEBSOCKET_URL: 'chargers.gamma.synop.ai/charger',
      amplify: getAmplifyConfig({
        awsCognitoRegion: 'us-east-1',
        awsProjectRegion: 'us-east-1',
        awsUserPoolsId: 'us-east-1_n6ZExRe7q',
        awsUserPoolsWebClientId: '6qg1ucqdedhd6vbh2j3i6f6qga',
        oauthDomain: 'synop-dev.auth.us-east-1.amazoncognito.com',
      }),
      datadog: getDatadogConfig('gamma', appVersion),
    },
    prod: {
      NX_SYNOP_API_URL:
        'https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod',
      NX_SYNOP_NEW_API_URL:
        'https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod',
      OCPP_WEBSOCKET_URL: 'chargers.synop.ai/charger',
      amplify: getAmplifyConfig({
        awsCognitoRegion: 'us-east-1',
        awsProjectRegion: 'us-east-1',
        awsUserPoolsId: 'us-east-1_XWvky1XKq',
        awsUserPoolsWebClientId: '5bps4ed1vu0k99bsjses7r1fa8',
        oauthDomain: 'synop-prod.auth.us-east-1.amazoncognito.com',
      }),
      datadog: getDatadogConfig('prod', appVersion),
    },
    euPilot: {
      NX_SYNOP_API_URL:
        'https://w1fcqlqbg5.execute-api.eu-west-1.amazonaws.com/prod-eu',
      NX_SYNOP_NEW_API_URL:
        'https://w1fcqlqbg5.execute-api.eu-west-1.amazonaws.com/prod-eu',
      OCPP_WEBSOCKET_URL: 'chargers.eu.synop.ai/charger',
      amplify: getAmplifyConfig({
        awsCognitoRegion: 'eu-west-1',
        awsProjectRegion: 'eu-west-1',
        awsUserPoolsId: 'eu-west-1_bRC37y2te',
        awsUserPoolsWebClientId: '756rphi5f6m82aptdken8hmpo2',
        oauthDomain: 'synop-prod.auth.eu-west-1.amazoncognito.com',
      }),
      datadog: null,
    },
  }

  return {
    APP_VERSION: appVersion,
    ROLLBAR_CLIENT_ACCESS_TOKEN: environment.NX_ROLLBAR_CLIENT_ACCESS_TOKEN,
    ROLLBAR_ENV: environment.NX_ROLLBAR_ENV,
    ...configs[envName as keyof typeof configs],
  }
}
