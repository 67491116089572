import {
  Box,
  LinearProgress,
  LinearProgressPropsColorOverrides,
  styled,
  useTheme,
} from '@mui/material'
import { OverridableStringUnion } from '@mui/types'

export type ProgressPillProps = {
  value: number
  /** 
    Supports standard theme colors
    @default 'primary'
  */
  color?: ProgressPillColor
  lg?: boolean
  /** 
    Highest value in the range
    @default 100
  */
  upperBound?: number
  /** 
    Lowest value in the range
    @default 0
  */
  lowerBound?: number
  /**
   * Uses a funtion to determine what color should be shown based on value and bounds.
   * Will defer to 'color' prop
   */
  colorFilterFn?: ProgressPillColorFilter
}

export type ProgressPillColorFilter = (progressPill: {
  value: number
  upperBound: number
  lowerBound: number
  percent: number
}) => ProgressPillColor

export type ProgressPillColor = OverridableStringUnion<
  'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  LinearProgressPropsColorOverrides
>

export function ProgressPill({
  value,
  lowerBound = 0,
  upperBound = lowerBound,
  color,
  colorFilterFn = () => 'primary',
  lg = false,
}: ProgressPillProps) {
  const { palette } = useTheme()
  const size = lg ? '16px' : '4px'

  const absoluteBound = upperBound - lowerBound
  const defaultBarPercent = value ? 100 : 0
  const barPercent = absoluteBound
    ? (value / absoluteBound) * 100
    : defaultBarPercent
  const pillColor =
    color ||
    colorFilterFn({
      value,
      upperBound,
      lowerBound,
      percent: barPercent,
    })
  return (
    <Box sx={{ width: '100%', height: size }}>
      <StyledLinearProgress
        size={size}
        sx={{
          backgroundColor: palette.secondary['4p'],
          '& .MuiLinearProgress-bar': {
            backgroundColor: palette[pillColor].main,
          },
        }}
        value={barPercent}
        variant="determinate"
      />
    </Box>
  )
}

const StyledLinearProgress = styled(LinearProgress)(
  ({ size }: { size: string }) => ({
    height: size,
  })
)

export default ProgressPill
