import { Button, Typography } from '@mui/material'
import { createOverlay, Overlay, useOverlayContext } from '@synop-react/common'
import { useCallback } from 'react'

type MfaDisabledOverlayProps = {
  onConfirm: () => void
}

export const MfaDisabledOverlay = createOverlay<MfaDisabledOverlayProps>(
  ({ onConfirm }) => {
    const { closeOverlay } = useOverlayContext()

    const onClick = useCallback(() => {
      onConfirm()
      closeOverlay()
    }, [closeOverlay, onConfirm])

    return (
      <Overlay>
        <Overlay.Header title="Confim MFA Changes" />

        <Overlay.Content>
          <Typography fontWeight="bold" variant="body1">
            Are you sure you wish to turn off Multi-factor Authentication for
            all users?
          </Typography>
        </Overlay.Content>

        <Overlay.Actions>
          <Button color="error" onClick={onClick} variant="contained">
            DISABLE MFA
          </Button>

          <Button onClick={closeOverlay} variant="outlined">
            Cancel
          </Button>
        </Overlay.Actions>
      </Overlay>
    )
  }
)
