import {
  collectionToCSV,
  getFieldColumns,
  useReport,
  UseReportProps,
} from '@synop-react/common'
import {
  RootAPI,
  useOrgVehicles,
  useUserPrefs,
  VehicleMetrics,
  VehicleModel,
} from '@synop-react/api'
import { useMemo } from 'react'
import { useVehicleActivityFields, useVehicleFields } from './fields'

const { useGetVehicleReportQuery } = RootAPI.synopRootAPI

export type RawVehicleActivity = Omit<
  VehicleMetrics,
  'timeUtilization' | 'energyUtilization'
> & {
  totalTrips: number
  mpge: number
  vehicleId: string
}

export type VehicleActivity = RawVehicleActivity &
  Omit<VehicleModel, 'efficiency'>

export type UseVehicleActivityReportProps = Omit<
  UseReportProps<
    RootAPI.GetVehicleReportApiArg,
    RootAPI.ReportGenerationStatus
  >,
  'useQuery' | 'reportType'
>

export const useVehicleActivityReport = ({
  skip,
  queryArgs,
}: UseVehicleActivityReportProps) => {
  const { tzDayjs } = useUserPrefs()
  const { orgVehicles } = useOrgVehicles()

  const { report = [], ...reportStatus } = useReport<
    RootAPI.GetVehicleReportApiArg,
    RootAPI.ReportGenerationStatus,
    RawVehicleActivity
  >({
    useQuery: useGetVehicleReportQuery,
    queryArgs,
    reportType: 'VEHICLE_ACTIVITY',
    skip,
  })

  const reportData = useMemo(
    () =>
      report.reduce<VehicleActivity[]>((acc, activity) => {
        const { vehicleId, ...restActivity } = activity

        const activityVehicle = orgVehicles[vehicleId]
        if (!activityVehicle) return acc

        const { efficiency, ...vehicle } = activityVehicle
        acc.push({ vehicleId, ...vehicle, ...restActivity })

        return acc
      }, []),
    [report, orgVehicles]
  )

  const { vehicleName, vin, fleet, homeSite } =
    useVehicleFields<VehicleActivity>('id')
  const {
    totalTrips,
    distanceDriven,
    energyUsed,
    energyAdded,
    carbonEmissionsSaved,
    efficiency,
  } = useVehicleActivityFields<VehicleActivity>(reportData[0])

  const { csvColumns, tableColumns } = useMemo(
    () =>
      getFieldColumns(
        vehicleName,
        vin,
        fleet,
        homeSite,
        totalTrips,
        distanceDriven,
        carbonEmissionsSaved,
        efficiency,
        energyUsed,
        energyAdded
      ),
    [
      distanceDriven,
      energyAdded,
      energyUsed,
      totalTrips,
      vehicleName,
      vin,
      fleet,
      homeSite,
      efficiency,
      carbonEmissionsSaved,
    ]
  )

  const activityCSVBlob = useMemo(() => {
    const eventCSV = collectionToCSV(csvColumns)(reportData)
    return new Blob([eventCSV], { type: 'text/csv' })
  }, [csvColumns, reportData])

  const { from, to } = queryArgs
  const activityCSVOption = {
    extension: '.csv' as const,
    blob: activityCSVBlob,
    filename: `VEHICLE_ACTIVITY_${tzDayjs(from).format()}_${tzDayjs(
      to
    ).format()}`,
  }

  return {
    data: reportData,
    columns: tableColumns,
    ...reportStatus,
    csvOption: activityCSVOption,
  }
}
