import { ChargerMakeModelAutocomplete } from '../../Autocomplete/ChargerMakeModelAutocompleteSelect'
import { ChargerManufacturerAutocomplete } from '../../Autocomplete/ChargerManufacturerAutocompleteSelect'
import { ConfigureChargerFormData } from './ConfigureChargerOverlay'
import { DepotAutocomplete, FormField } from '../../index'
import { FieldError, useFormContext, useWatch } from 'react-hook-form'
import { FreeformAutocompleteWithHints } from '../../FormField/FreeformAutocompleteWithHints'
import { Grid } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

type BaseChargerFieldsProps = {
  editingExisting: boolean
  orgId: string
  setCurrentType: (type: string | undefined) => void
}

export const BaseChargerFields = ({
  editingExisting,
  orgId,
  setCurrentType,
}: BaseChargerFieldsProps) => {
  const [knownPowerRatingsOptions, setKnownPowerRatingsOptions] = useState<
    number[]
  >([])
  const [
    knownOperatingCurrentsAmpsOptions,
    setKnownOperatingCurrentsAmpsOptions,
  ] = useState<number[]>([])
  const [knownOperatingVoltagesOptions, setKnownOperatingVoltagesOptions] =
    useState<number[]>([])

  const {
    control,
    formState: { errors, touchedFields },
    setValue,
  } = useFormContext<ConfigureChargerFormData>()

  const manufacturer = useWatch({ control, name: 'manufacturer' })
  const makeModel = useWatch({ control, name: 'makeModel' })

  useEffect(() => {
    if (makeModel) {
      const { chargerMakeModelKnownOperatingParameters: params = {} } =
        makeModel

      setKnownPowerRatingsOptions(params.knownPowerRatingsKw || [])
      setKnownOperatingCurrentsAmpsOptions(
        params.knownOperatingCurrentsAmps || []
      )
      setKnownOperatingVoltagesOptions(params.knownOperatingVoltages || [])

      setCurrentType(makeModel?.currentType)

      if (makeModel.currentType === 'DC') {
        setValue('voltage', undefined)
        setValue('maxCurrent', undefined)
      }
    } else {
      setKnownPowerRatingsOptions([])
      setKnownOperatingCurrentsAmpsOptions([])
      setKnownOperatingVoltagesOptions([])
      setCurrentType(undefined)
      setValue('voltage', undefined)
      setValue('maxCurrent', undefined)
    }
  }, [makeModel, setCurrentType, setValue])

  const oldManufacturer = useRef(manufacturer)

  useEffect(() => {
    if (
      oldManufacturer.current &&
      oldManufacturer.current.id != manufacturer?.id
    ) {
      setValue('makeModel', null)
    }
    oldManufacturer.current = manufacturer
  }, [manufacturer, setValue])

  return (
    <Grid container direction="row" item md={4} spacing={2} xs={12}>
      <Grid item md={12}>
        <FormField.WrappedTextFormField id="chargerName" label="Name" />
      </Grid>
      <Grid item md={12}>
        <FormField.WrappedTextFormField
          disabled={editingExisting}
          id="chargerId"
          label="ID"
        />
      </Grid>
      <Grid item md={12}>
        <DepotAutocomplete.Select
          control={control}
          error={errors['depot'] as FieldError}
          fleetId={orgId}
          id="depot"
          label="Site"
          touchedField={Boolean(touchedFields['depot'])}
        />
      </Grid>

      <Grid item md={12}>
        <ChargerManufacturerAutocomplete.Select
          control={control}
          error={errors['manufacturer'] as FieldError}
          id="manufacturer"
          touchedField={Boolean(touchedFields['manufacturer'])}
        />
      </Grid>

      <Grid item md={12}>
        <ChargerMakeModelAutocomplete.Select
          control={control}
          disabled={!manufacturer}
          error={errors['makeModel'] as FieldError}
          id="makeModel"
          manufacturerId={manufacturer?.id || ''}
          touchedField={Boolean(touchedFields['makeModel'])}
        />
      </Grid>

      <Grid item md={12}>
        <FreeformAutocompleteWithHints<ConfigureChargerFormData, number>
          disabled={!makeModel}
          hints={knownPowerRatingsOptions}
          id="maxPower"
          label="Max Power (kW)"
        />
      </Grid>
      {makeModel?.currentType === 'AC' && (
        <Grid container item md={12}>
          <Grid item md={12}>
            <FreeformAutocompleteWithHints<ConfigureChargerFormData, number>
              disabled={!makeModel}
              hints={knownOperatingCurrentsAmpsOptions}
              id="maxCurrent"
              label="Max Operating Current (A)"
            />
          </Grid>

          <Grid item md={12}>
            <FreeformAutocompleteWithHints<ConfigureChargerFormData, number>
              disabled={!makeModel}
              hints={knownOperatingVoltagesOptions}
              id="voltage"
              label="Operating Voltage (V)"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
