import {
  Button,
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { useState } from 'react'

import { Columns, Sliders } from '../../Icons'
import { OverlayDeprecated } from '../../Overlay'
import { Table } from '../../Table'

type CustomizeTableOverlayProps<T extends GridValidRowModel> = {
  columns: Table.ColumnSpec<T>
  setVisibleColumns: (columns: Table.ColumnSpec<T>) => void
}

export function VisibilityOverlay<T extends GridValidRowModel>({
  columns,
  setVisibleColumns,
}: CustomizeTableOverlayProps<T>) {
  const [editedColumns, setEditedColumns] = useState(columns)
  const [isOpen, setIsOpen] = useState(false)

  const handleSave = () => {
    setVisibleColumns(editedColumns)
    setIsOpen(false)
  }

  const SaveButton = [
    <Button onClick={handleSave} variant="contained">
      Save
    </Button>,
  ]

  function handleChange<T extends GridValidRowModel>(
    event: React.ChangeEvent<HTMLInputElement>,
    col: Table.GridColumn<T>
  ) {
    const updateColumns = editedColumns.map((obj) => {
      if (obj.field === col.field) {
        return { ...obj, isDefaultVisible: !col.isDefaultVisible }
      }
      return obj
    })

    setEditedColumns(updateColumns)
  }

  const columnCheckboxes = editedColumns.map((col) => {
    return (
      <FormControlLabel
        key={col.field}
        control={
          <Checkbox
            checked={col.isDefaultVisible}
            onChange={(e) => {
              handleChange(e, col)
            }}
          />
        }
        label={col.headerName}
      />
    )
  })

  return (
    <Grid item>
      <Columns onClick={() => setIsOpen(true)} />
      <OverlayDeprecated
        isOpen={isOpen}
        OverlayActions={SaveButton}
        setIsOpen={setIsOpen}
        title="Customize Table"
        TitleIcon={Sliders}
      >
        <DialogContentText>
          Select which data fields to display on this table.
        </DialogContentText>
        <DialogContent>
          <FormGroup>{columnCheckboxes}</FormGroup>
        </DialogContent>
      </OverlayDeprecated>
    </Grid>
  )
}

export default VisibilityOverlay
