import { defaultEntityName } from '../../../utils/formatters/entity'

import { EventOptions } from '../useTimeline/Provider'
import { extractFrequency, extractRecurrenceName } from './utils'
import { useDepotChargers, useOrgVehicles } from '@synop-react/api'
import { UseEventOptionsProps } from '.'
import { useFormat } from '../../../utils/hooks'
import { useTheme } from '@mui/material'

export type UseScheduleChargeProps = {
  depotId?: string
} & UseEventOptionsProps

const useScheduleChargeOption = ({
  depotId,
  renderOverlay,
  subtitle,
  tooltipDetails = [],
}: UseScheduleChargeProps): Pick<EventOptions, 'TIMED_CHARGE'> => {
  const theme = useTheme()
  const { formatDateTime } = useFormat()
  const { orgVehicles } = useOrgVehicles()
  const { depotChargers } = useDepotChargers(depotId)

  return {
    TIMED_CHARGE: {
      barColor: theme.palette.secondary.main,
      caption: {
        title: () => `Scheduled Session`,
        titleDescription: subtitle,
      },
      tooltip: {
        title: ({ eventRecurrence }) => {
          return `Session` + (eventRecurrence ? ' (Recurring)' : ' (Scheduled)')
        },
        details: [
          (e) => extractRecurrenceName(e.eventRecurrence),
          (e) => extractFrequency(e.eventRecurrence),
          ({ assetId }) => ({
            label: 'Vehicle',
            detail:
              orgVehicles[assetId ?? '']?.vehicleNm ??
              defaultEntityName('Vehicle', assetId),
          }),
          ({ chargerId }) => ({
            label: 'Charger',
            detail:
              depotChargers.entities[chargerId ?? '']?.chargerName ??
              defaultEntityName('Charger', chargerId),
          }),
          ({ dispenserId }) => ({
            label: 'Connector',
            detail: defaultEntityName('Connector', dispenserId),
          }),
          ({ scheduledStart }) => ({
            label: 'Start',
            detail: formatDateTime(scheduledStart).timeDotDate,
          }),
          ({ scheduledEnd }) => ({
            label: 'End',
            detail: formatDateTime(scheduledEnd).timeDotDate,
          }),
          ...tooltipDetails,
        ],
      },
      renderOverlay,
    },
  }
}

export default useScheduleChargeOption
