import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { FormField } from '../../index'
import { RootAPI } from '@synop-react/api'

const { useGetOrganizationUsersQuery } = RootAPI.synopRootAPI

export type WorkplacePayorAutocompleteSelectProps<
  FormData extends FieldValues
> = {
  label?: string
  orgId: string
} & AutocompleteFormControls<FormData, RootAPI.UserModel> &
  AutocompleteTextFieldProps

export const WorkplacePayorAutocompleteSelect = <FormData extends FieldValues>({
  orgId,
  label = 'Choose an existing user',
  ...autocompleteProps
}: WorkplacePayorAutocompleteSelectProps<FormData>) => {
  const UserOptions = FormField.useOptions<
    RootAPI.UserModel,
    RootAPI.GetOrganizationUsersApiArg,
    RootAPI.UserModel[]
  >({
    queryArgs: { id: orgId },
    useQuery: useGetOrganizationUsersQuery,
  })

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.UserModel>
      isLoading={UserOptions.isLoading}
      isOpen={UserOptions.isOpen}
      label={label}
      options={UserOptions.options}
      setIsOpen={UserOptions.setIsOpen}
      {...autocompleteProps}
      filterOptions={(options, { inputValue }) =>
        options
          .filter(({ name }) =>
            name?.toLowerCase().includes(inputValue.toLowerCase())
          )
          .sort((a, b) => {
            const aName = (a?.name ?? '').toLowerCase()
            const bName = (b?.name ?? '').toLowerCase()
            return aName.localeCompare(bName)
          })
      }
      getOptionLabel={({ name }: RootAPI.UserModel) => name ?? ''}
      keyExtractor={({ id }) => id || ''}
    />
  )
}

const UserSelectSchema = yup
  .object({ id: yup.string().required() })
  .nullable()
  .required('User must be selected')

export {
  WorkplacePayorAutocompleteSelect as Select,
  UserSelectSchema as Schema,
}
