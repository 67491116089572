import {
  AdminFeature,
  DetailsCask,
  Edit2,
  formatPower,
} from '@synop-react/common'
import { CreateOrEditDepotOverlay } from '@synop-react/depot'
import { DepotMapTile } from '@synop-react/depot'
import { IconButton } from '@mui/material'
import {
  RootAPI,
  useDepotChargers,
  useDepotDetailsFromPoll,
} from '@synop-react/api'

const { useGetOrganizationQuery } = RootAPI

export interface DepotDetailsCaskProps {
  depotId: string
}

export function DepotDetailsCask({ depotId }: DepotDetailsCaskProps) {
  const {
    getDepot: { data: depot },
  } = useDepotDetailsFromPoll({ depotId })
  const { depotChargers, totalItems } = useDepotChargers(depotId)
  const { data: siteOrg } = useGetOrganizationQuery(
    { id: depot?.fleetId as string },
    { skip: !depot || !depot.fleetId }
  )

  const { depotNm, powerCeiling, address } = depot || {}

  // This number will not be accurate since the depotChargers.entities object
  // only includes 100 chargers
  const totalConnectorCount = Object.values(depotChargers.entities).reduce(
    (acc, { connectorIds = [] }) => {
      connectorIds.forEach((connectorId) => {
        if (connectorId !== 0) acc++
      })
      return acc
    },
    0
  )

  return (
    <DetailsCask
      EditOverlay={
        <AdminFeature>
          <CreateOrEditDepotOverlay
            depotId={depotId}
            Trigger={
              <IconButton>
                <Edit2 />
              </IconButton>
            }
          />
        </AdminFeature>
      }
      Map={<DepotMapTile depotId={depotId} />}
      title={'Site'}
    >
      <DetailsCask.Item label="Site Name" value={depotNm as string} />
      <DetailsCask.Item label="Site Address" value={address} />
      <DetailsCask.Item
        label="Site Organization"
        value={siteOrg?.organizationNm}
      />
      <DetailsCask.Item label="Site Limit" value={formatPower(powerCeiling)} />

      <DetailsCask.Item label="Total Chargers" value={String(totalItems)} />
      <DetailsCask.Item
        label="Total Connectors"
        value={String(totalConnectorCount)}
      />
    </DetailsCask>
  )
}

export default DepotDetailsCask
