import {
  AccessTimeOutlined,
  CachedOutlined,
  CheckOutlined,
  HelpOutline,
  NotInterestedOutlined,
  RemoveCircleOutlineOutlined,
} from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  ChipProps,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { ChargingInfoCard } from '../HomeChargingSettings/HomeChargingSettings'
import {
  emDash,
  reduceWattHours,
  TabCaskView,
  useFormat,
  useOverlay,
} from '@synop-react/common'
import { LoadingButton } from '@mui/lab'
import { RatesTableCask } from '../RatesTable'
import { ReactElement, useCallback, useMemo } from 'react'
import {
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
  useIsCloaking,
  useUserPrefs,
} from '@synop-react/api'
import { WorkplaceChargingBillingCycleOverlay } from './WorkplaceChargingBillingCycleOverlay'
import dayjs from 'dayjs'
import WorkplaceChargingInvoices from './WorkplaceChargingInvoices'
import WorkplaceChargingUsage from './WorkplaceChargingUsage'
import WorkplaceChargingUsers from './WorkplaceChargingUsers'

const {
  useGetWorkplaceChargingConfigurationQuery,
  useGetWorkplaceSummaryQuery,
} = RootAPI

export function WorkplaceChargingSettings() {
  return (
    <>
      <WorkplaceChargingSettingsOverview />
      <TabCaskView
        tabItems={[
          {
            label: 'Invoices',
            content: <WorkplaceChargingInvoices />,
          },
          {
            label: 'Usage',
            content: <WorkplaceChargingUsage />,
          },
          {
            label: 'Payors',
            content: <WorkplaceChargingUsers />,
          },
          {
            label: 'Rates',
            content: <RatesTableCask />,
          },
        ]}
      />
    </>
  )
}

export function WorkplaceChargingSettingsOverview() {
  const { breakpoints, palette } = useTheme()
  const { synopUser } = useCurrentUser()
  const isCloaking = useIsCloaking()

  const { tzDayjs } = useUserPrefs()
  const { formatDateTime } = useFormat()
  const monthStart = tzDayjs().startOf('month').format('YYYY-MM-DD')
  const orgId = useCurrentOrgId()
  const { data: reimbursementSummary } = useGetWorkplaceSummaryQuery(
    {
      billingMonth: monthStart,
      organizationId: orgId,
    },
    {
      skip: !orgId,
    }
  )

  const { data: reimbursementConfiguration } =
    useGetWorkplaceChargingConfigurationQuery(
      {
        organizationId: orgId,
      },
      { skip: !orgId }
    )

  const [getOnboardLink, getOnboardLinkResponse] =
    RootAPI.useOnboardOrganizationToStripeMutation()

  const billingCycleOverlay = useOverlay()

  const lastCapturedAt = useMemo(
    () => formatDateTime(reimbursementConfiguration?.updated).timeOnDate,
    [reimbursementConfiguration?.updated, formatDateTime]
  )

  const periodStart = useMemo(
    () => formatDateTime(reimbursementSummary?.periodStart).toLongFormDate,
    [formatDateTime, reimbursementSummary?.periodStart]
  )

  const periodEnd = useMemo(
    () => formatDateTime(reimbursementSummary?.periodEnd).toLongFormDate,
    [formatDateTime, reimbursementSummary?.periodEnd]
  )

  const nextPeriodStart = useMemo(
    () =>
      formatDateTime(dayjs(reimbursementSummary?.periodEnd).add(1, 'day'))
        .toLongFormDate,
    [formatDateTime, reimbursementSummary?.periodEnd]
  )

  const daysRemaining = useMemo(() => {
    const nextPeriodStart = dayjs(reimbursementSummary?.periodEnd).add(1, 'day')
    const now = dayjs().startOf('day')
    return nextPeriodStart.diff(now, 'days')
  }, [reimbursementSummary])

  const energyUseKwh = useMemo(
    () =>
      reimbursementSummary?.currentPeriodWorkplaceChargingEnergyUse ===
      undefined
        ? { num: emDash, unit: undefined }
        : reduceWattHours(
            reimbursementSummary.currentPeriodWorkplaceChargingEnergyUse,
            'kwh'
          ),
    [reimbursementSummary?.currentPeriodWorkplaceChargingEnergyUse]
  )

  // Only create a Stripe session if a user clicks the button
  const getOnboardStripeAccountLink = useCallback(async () => {
    const onboardResponse = await getOnboardLink({
      id: synopUser?.organizationId ?? '',
      urlRedirectModel: {
        baseUrl: window.location.origin,
        path: window.location.pathname,
        parameters: '', // `null` will  be included in the redirect url if parameters is undefined
      },
    })

    // Could be an error returned
    if ('data' in onboardResponse) {
      const stripeRedirectUrl = onboardResponse?.data?.value
      if (stripeRedirectUrl) {
        window.location.assign(stripeRedirectUrl)
      }
    }
  }, [getOnboardLink, synopUser])

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          action={
            <>
              <LoadingButton
                disabled={isCloaking}
                loading={getOnboardLinkResponse.isLoading}
                onClick={getOnboardStripeAccountLink}
                sx={{ mr: 2 }}
                variant="outlined"
              >
                MANAGE BILLING
              </LoadingButton>
              <Button
                onClick={billingCycleOverlay.openOverlay}
                variant="contained"
              >
                {reimbursementConfiguration?.billingAnchor
                  ? 'EDIT BILLING CYCLE'
                  : 'CREATE BILLING CYCLE'}
              </Button>
            </>
          }
          subheader={
            <Typography variant="caption">
              Track and manage your workplace charging collections.
            </Typography>
          }
          sx={{
            borderBottom: `1px solid ${palette.text['8p']}`,
            '& .MuiCardHeader-action': { alignSelf: 'unset', margin: 0 },
          }}
          title={
            <Stack alignItems="center" direction="row" spacing={3}>
              <Typography variant="h5">Workplace Charging</Typography>
              <WorkplaceStatusChargingChip
                status={reimbursementSummary?.status}
              />
            </Stack>
          }
        />
        <CardContent>
          <Grid container>
            <Grid container flexWrap={'nowrap'} gap={2} item md={6} xs={12}>
              <Grid item sm={4} xs={12}>
                <ChargingInfoCard
                  amountChange={
                    reimbursementSummary?.workplaceChargingRevenueMoMChange ?? 0
                  }
                  descriptionContent={lastCapturedAt}
                  descriptionLabel="Last captured at:"
                  title="Current Cycle Revenue"
                  value={`$${
                    reimbursementSummary?.currentPeriodWorkplaceChargingRevenue ??
                    0
                  }`}
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <ChargingInfoCard
                  amountChange={reimbursementSummary?.energyUseMoMChange ?? 0}
                  title="Current Cycle Energy"
                  value={`${energyUseKwh?.num ?? 0} ${
                    energyUseKwh?.unit ?? ''
                  }`}
                />
              </Grid>
              <Grid
                item
                sm={3}
                sx={{
                  [breakpoints.up('md')]: {
                    borderRight: `1px solid ${palette.text['8p']}`,
                  },
                }}
                xs={12}
              >
                <ChargingInfoCard
                  title="Onboarded Payors"
                  value={`${reimbursementSummary?.workplaceUsers ?? 0}`}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={true}
              sm={12}
              sx={{
                [breakpoints.up('md')]: {
                  ml: 6.5,
                },
              }}
            >
              <Grid item sm={6} xs={12}>
                <ChargingInfoCard
                  descriptionContent={`${periodStart} - ${periodEnd}`}
                  descriptionLabel="Current cycle:"
                  title="Billing Cycle"
                  value={`${daysRemaining} days remaining`}
                />
              </Grid>
              <Grid
                item
                sm={6}
                sx={{ backgroundColor: palette.primary['8p'] }}
                xs={12}
              >
                <ChargingInfoCard
                  descriptionContent={`$${
                    reimbursementSummary?.lastBillingPeriod
                      ?.currentPeriodWorkplaceChargingRevenue ?? ''
                  }
                  `}
                  descriptionLabel="Last cycle total payments:"
                  title="Next Payment Date"
                  value={`${nextPeriodStart}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <WorkplaceChargingBillingCycleOverlay {...billingCycleOverlay} />
    </>
  )
}

export default WorkplaceChargingSettings

type WorkplaceStatusChargingChipProps = {
  status: RootAPI.WorkplaceChargingSummaryModel['status']
}

type WorkplaceStatus = NonNullable<
  RootAPI.WorkplaceChargingSummaryModel['status']
>

type StatusDetail = {
  label: string
  color: ChipProps['color']
  icon: ReactElement
}

const WorkplaceStatusChargingChip = ({
  status,
}: WorkplaceStatusChargingChipProps) => {
  if (!status) return null

  const workplaceStatusDetails: Record<WorkplaceStatus, StatusDetail> = {
    UNKNOWN: {
      label: 'Unknown',
      color: 'secondary',
      icon: <HelpOutline />,
    },
    RESTRICTED: {
      label: 'Restricted',
      color: 'error',
      icon: <NotInterestedOutlined />,
    },
    RESTRICTED_SOON: {
      label: 'Restricted soon',
      color: 'warning',
      icon: <AccessTimeOutlined />,
    },
    PENDING: {
      label: 'Pending',
      color: 'secondary',
      icon: <CachedOutlined />,
    },
    ENABLED: {
      label: 'Enabled',
      color: 'primary',
      icon: <CheckOutlined />,
    },
    COMPLETE: {
      label: 'Complete',
      color: 'success',
      icon: <CheckOutlined />,
    },
    REJECTED: {
      label: 'Rejected',
      color: 'error',
      icon: <RemoveCircleOutlineOutlined />,
    },
  }

  const { label, color, icon } = workplaceStatusDetails[status]

  return (
    <Chip
      color={color}
      deleteIcon={icon}
      label={label}
      // onDelete is a noop
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onDelete={() => {}}
      size="small"
      sx={{
        '.MuiChip-deleteIcon': { cursor: 'default' },
      }}
      variant="outlined"
    />
  )
}
