const LOADING_MESSAGES = [
  'Reticulating splines',
  'Counting electrons',
  'Aligning electrical phases',
  'Energizing flux capacitor',
  'Assembling quarks',
  'Opening quantum tunnel',
  'Wiggling Pentaquarks',
  'Devouring Tetraquarks',
  'Calibrating Vacuum Tubes',
  'Reducing conducers',
  'Balancing valence orbitals',
  "Stressing Maxwell's tensor",
  'Harnessing eddy currents',
  'Polarizing neutrinos',
  'Calibrating thermocouples',
]

export const selectRandomLoadingMessage = () =>
  LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]
