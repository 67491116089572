import { Box, Button, Stack, Typography } from '@mui/material'
import {
  ChevronRight,
  emDash,
  Table,
  TableCask,
  titleCase,
  useOverlay,
} from '@synop-react/common'
import { CreateSiteChargingRateOverlay } from '../RateOverlays/SiteChargingRateOverlay'
import {
  EnhancedSiteChargingRate,
  RootAPI,
  useCurrentOrgId,
  useOrgChargers,
  useOrgSites,
} from '@synop-react/api'
import {
  GridFilterModel,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import { PropsWithChildren, useMemo, useState } from 'react'
import { SelectedRateMenu } from './SelectedRateMenu'

const { useGetWorkplaceChargingRatesQuery } = RootAPI

// The width of the space reserved for the chevron icon to the left of the name.
// This is not actually the width of the chevron icon itself.
const CHEVRON_WIDTH = 30

const usCurrencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const groupingRateColumn: GridGroupingColDefOverride<EnhancedSiteChargingRate> =
  {
    headerName: 'Name',
    width: 200,
    renderCell: (params) => <RateNameColumn {...params} />,
    renderHeader: () => (
      <HeaderWithMargin colName="Name" margin={CHEVRON_WIDTH} />
    ),
    valueGetter: ({ row }) => row.rateNm,
  }

const rateColumns: Table.ColumnSpec<EnhancedSiteChargingRate> = [
  {
    field: 'targetType',
    headerName: 'Type',
    width: 200,
    valueGetter: ({ row }) => titleCase(row.targetType),
  },
  {
    field: 'targetName',
    headerName: 'Asset',
    width: 200,
  },
  {
    field: 'rateStructure',
    headerName: 'Rate',
    width: 200,
    valueGetter: ({ row }) => {
      const { rateStructure } = row
      if (rateStructure?.length === 1)
        return usCurrencyFormatter.format(rateStructure[0]?.price ?? 0)
      return 'Multiple'
    },
  },
  {
    field: 'rateStatus',
    headerName: 'Status',
    width: 200,
    valueGetter: ({ row }) => titleCase(row.rateStatus ?? ''),
    type: 'singleSelect',
    valueOptions: ['Active', 'Inactive'],
  },
]

export function RatesTableCask() {
  const [selectedRates, setSelectedRates] = useState<GridRowId[]>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [{ field: 'rateStatus', operator: 'is', value: 'Active' }],
  })

  const parsedRates = useEnhancedChargingRates()

  const addSiteRateOverlay = useOverlay()

  const selectedRate = parsedRates.find(
    (rate) => rate.rateMappingId === selectedRates[0]
  )

  return (
    <>
      <CreateSiteChargingRateOverlay {...addSiteRateOverlay} />

      <TableCask.ClientSide<EnhancedSiteChargingRate>
        checkboxSelection
        columns={rateColumns}
        defaultPageSize={10}
        disableRowSelectionOnClick
        // disableMultipleRowSelection={true} // Doesn't currently work with checkboxSelection, but should be fixed in future upgrade to MUI https://github.com/mui/mui-x/pull/11448
        filterModel={filterModel}
        getRowId={(rate) => rate.id}
        getTreeDataPath={(rate) => rate.path}
        groupingColDef={groupingRateColumn}
        onFilterModelChange={setFilterModel}
        onRowSelectionModelChange={setSelectedRates}
        rightOtherActions={
          <>
            <Button onClick={addSiteRateOverlay.openOverlay} variant="outlined">
              ADD RATE
            </Button>

            <SelectedRateMenu
              disabled={selectedRates.length !== 1}
              selectedRate={selectedRate}
            />
          </>
        }
        tableData={parsedRates}
      />
    </>
  )
}

const useEnhancedChargingRates = () => {
  const currentOrgId = useCurrentOrgId()
  const { data: rawRates = [] } = useGetWorkplaceChargingRatesQuery({
    organizationId: currentOrgId,
  })
  const { orgChargers } = useOrgChargers()
  const { orgSites } = useOrgSites()

  return useMemo(() => {
    return rawRates.flatMap((rate) => {
      const { defaultRateStructure, overrides, siteId } = rate ?? {}

      const id = defaultRateStructure.rateMappingId
      const parsedSiteRate: EnhancedSiteChargingRate = {
        ...defaultRateStructure,
        id,
        path: [id],
        siteId,
        targetName: orgSites[siteId]?.depotNm ?? emDash,
        targetType: 'SITE',
      }

      const parsedOverrideRates = (overrides ?? []).map((override) => {
        const overrideId = override.rateMappingId
        return {
          ...override,
          id: overrideId,
          path: [id, overrideId],
          siteId,
          targetName:
            orgChargers[override.overrideTargetId]?.chargerName ?? emDash,
          targetType: override.overrideTargetType,
        }
      })

      return [parsedSiteRate, ...parsedOverrideRates]
    })
  }, [orgChargers, orgSites, rawRates])
}

// Copied from webapp because they aren't available to libraries
type NameColumnProps = GridRenderCellParams<EnhancedSiteChargingRate>

function RateNameColumn({ rowNode, row }: NameColumnProps) {
  const { setRowChildrenExpansion, state } = useGridApiContext().current
  const { filteredDescendantCountLookup } = state.filter
  const { rateMappingId = '' } = row
  const childrenExpanded =
    rowNode.type === 'group' ? rowNode.childrenExpanded : false

  const chevron = useMemo(() => {
    if (filteredDescendantCountLookup[rateMappingId] === 0) return null

    return (
      <ChevronRight
        // Toggle the row's expansion state when the chevron is clicked
        onClick={(e) => {
          setRowChildrenExpansion(rateMappingId, !childrenExpanded)
          e.stopPropagation()
        }}
        sx={{
          color: 'action.active',
          cursor: 'pointer',
          // Rotate the chevron if the row is expanded
          transform: childrenExpanded ? 'rotate(90deg)' : 'rotate(0)',
          transition: 'all 0.2s ease-in-out',
        }}
      />
    )
  }, [
    rateMappingId,
    childrenExpanded,
    setRowChildrenExpansion,
    filteredDescendantCountLookup,
  ])

  return (
    <CellWithIcon icon={chevron} width={CHEVRON_WIDTH}>
      <Typography sx={{ ml: row.path.length - 1 }} variant="body2">
        {row.rateNm}
      </Typography>
    </CellWithIcon>
  )
}

type HeaderWithMarginProps = {
  colName: string
  margin: number
}

export function HeaderWithMargin({ colName, margin }: HeaderWithMarginProps) {
  return (
    <Typography sx={{ ml: `${margin}px`, fontWeight: 600 }} variant="body2">
      {colName}
    </Typography>
  )
}

type CellWithMarginProps = PropsWithChildren<{
  icon?: React.ReactNode
  width: number
}>

export function CellWithIcon({ children, icon, width }: CellWithMarginProps) {
  return (
    <Stack alignItems="center" direction="row" maxWidth="100%">
      <Box sx={{ width, height: 24 }}>{icon}</Box>
      {children}
    </Stack>
  )
}
