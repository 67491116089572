import { ChartAttribute, RootAPI } from '..'
import { Dayjs } from 'dayjs'

const { useGetChartValuesQuery } = RootAPI.synopRootAPI

type QueryOptions = {
  attributes: ChartAttribute[]
  vehicleId: string
  from: Dayjs
  to: Dayjs
}

export const useChartData = ({
  attributes,
  vehicleId: id,
  from,
  to,
}: QueryOptions) => {
  const { data: chartPayload = {}, ...chartResponse } = useGetChartValuesQuery({
    id,
    attributes,
    from: from.toISOString(),
    to: to.toISOString(),
  })

  const { data = [], units = [] } = chartPayload
  return { data, units, ...chartResponse }
}
