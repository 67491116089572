import { isEqual } from 'lodash'
import { Position } from '@turf/helpers'

/**
 * isGeofenceValid
 *
 * Determine whether a geofence is valid based on its coordinates.
 * A geofence is valid when its first and last coordinates are equal, as this ensures the geofence is closed.
 *
 * @param geofencePositions the coordinates of the geofence
 * @returns true if the geofence is valid, false otherwise
 */
const isGeofenceValid = (geofencePositions: Position[] = []): boolean => {
  if (geofencePositions.length < 4) return false

  return isEqual(
    geofencePositions[0],
    geofencePositions[geofencePositions.length - 1]
  )
}

export default isGeofenceValid
