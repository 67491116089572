import { useParams } from 'react-router-dom'
import { VehicleDetails } from '@synop-react/vehicle'

type VehicleDetailsRouteParams = 'assetId'

const VehicleDetailsPage = () => {
  const { assetId = '' } = useParams<VehicleDetailsRouteParams>()

  return <VehicleDetails vehicleId={assetId} />
}

export default VehicleDetailsPage
