import * as yup from 'yup'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import parse from 'autosuggest-highlight/parse'
import Typography from '@mui/material/Typography'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  FormField,
} from '../../index'
import { FieldValues } from 'react-hook-form'
import { MaybeOption } from '../../FormField/AutocompleteSelect/AutocompleteSelect'
import useGoogleAPI, {
  GooglePlace,
  isAutocompleteResponse,
  isGooglePlace,
} from '../../utils/hooks/useGoogleAPI'

export type AddressAutocompleteSelectProps<FormData extends FieldValues> = {
  label?: string
} & AutocompleteFormControls<FormData, GooglePlace> &
  AutocompleteTextFieldProps

export const AddressAutocomplete = <FormData extends FieldValues>({
  label = 'Address',
  ...autocompleteProps
}: AddressAutocompleteSelectProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [autocompleteInputListener, setAutocompleteInputListener] = useState('')
  const [addressOptions, setAddressOptions] = useState<GooglePlace[]>([])

  const { fetchPredictions, isLoading } = useGoogleAPI()

  useEffect(() => {
    let active = true

    if (!isGooglePlace(autocompleteInputListener)) {
      fetchPredictions(
        { input: autocompleteInputListener, types: ['address'] },
        (results = []) => {
          if (active) {
            const newOptions = results && results?.length ? [...results] : []

            setAddressOptions(newOptions)
          }
        }
      )
    }

    return () => {
      active = false
    }
  }, [autocompleteInputListener, fetchPredictions])

  const renderAddressOptionLabel = (option: MaybeOption<GooglePlace>) => {
    if (!isAutocompleteResponse(option)) return null
    const matches = option?.structured_formatting?.main_text_matched_substrings
    const parts = parse(
      option?.structured_formatting?.main_text,
      matches.map((match) => [match.offset, match.offset + match.length])
    )

    return (
      <Grid alignItems="center" container>
        <Grid item>
          <Box
            component={LocationOnIcon}
            sx={{ color: 'text.secondary', mr: 2 }}
          />
        </Grid>
        <Grid item xs>
          {parts.map((part) => (
            <span
              key={`${part.text}`}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography color="text.secondary" variant="body2">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const noOptionsText = autocompleteInputListener.length
    ? 'No Addresses Found'
    : 'Search Addresses'

  return (
    <FormField.AutocompleteSelect<FormData, GooglePlace>
      {...autocompleteProps}
      // Do not filter options, google will do that for us.
      filterOptions={(options) => options}
      getOptionLabel={(option) => {
        if (!isGooglePlace(option)) return option
        return isAutocompleteResponse(option)
          ? option.description
          : option.formatted_address
      }}
      isLoading={isLoading}
      isOpen={isOpen}
      keyExtractor={(place) => {
        if (!isGooglePlace(place)) return 'input-string'
        return place.place_id
      }}
      label={label}
      noOptionsText={noOptionsText}
      onInputChange={setAutocompleteInputListener}
      options={addressOptions}
      renderOptionLabel={renderAddressOptionLabel}
      setIsOpen={setIsOpen}
    />
  )
}

// An address can be either a GooglePlace object which is returned by the Autocomplete API or a string
// when being set from a stored backend string. Both can be considered valid
const addressSelectSchema = yup.lazy((value) => {
  return typeof value === 'string'
    ? yup.string().required('Address is required')
    : yup
        .object({ place_id: yup.string().required('Address is required') })
        .nullable()
        .required('Address is required')
})

export { AddressAutocomplete as Select, addressSelectSchema as Schema }
