import { Stack } from '@mui/material'
import { useMemo, useState } from 'react'

import {
  reportOptions,
  ReportSelectorCask,
  ReportTableCask,
  SelectedReport,
  Table,
} from '@synop-react/common'
import { useChargingTransactionsReport } from '@synop-react/depot'
import { useCurrentOrgId, useCurrentUser, useUserPrefs } from '@synop-react/api'
import { useTripReport, useVehicleActivityReport } from '@synop-react/vehicle'

export const ReportsPage = () => {
  const { tzDayjs } = useUserPrefs()
  const currentOrgId = useCurrentOrgId()
  const { synopUser, isAdmin } = useCurrentUser()
  const [selectedReport, setSelectedReport] = useState<SelectedReport>(null)

  const {
    from = tzDayjs(),
    to = tzDayjs(),
    type: selectedReportType,
    ...queryParams
  } = useMemo<NonNullable<SelectedReport>>(
    () =>
      selectedReport ? selectedReport : ({} as NonNullable<SelectedReport>),
    [selectedReport]
  )

  const {
    data: vehicleActivityReport,
    columns: vehicleActivityColumns,
    csvOption: vehicleActivityCSVOption,
    ...vehicleActivityStatus
  } = useVehicleActivityReport({
    queryArgs: {
      from: from.toISOString(),
      to: to.toISOString(),
      ...queryParams,
      organizationId: queryParams.organizationId || currentOrgId,
    },
    skip: selectedReportType !== 'VEHICLE_ACTIVITY',
  })

  const {
    data: tripReport,
    columns: tripColumns,
    csvOption: tripCSVOption,
    ...tripStatus
  } = useTripReport({
    queryArgs: {
      from: from.toISOString(),
      to: to.toISOString(),
      ...queryParams,
      organizationId: queryParams.organizationId || currentOrgId,
    },
    skip: selectedReportType !== 'TRIP',
  })

  const {
    data: chargingTransactionsReport,
    columns: chargingTransactionsColumns,
    csvOption: chargingTransactionsCSVOption,
    ...chargingTransactionsStatus
  } = useChargingTransactionsReport({
    queryArgs: {
      from: from.toISOString(),
      to: to.toISOString(),
      ...queryParams,
      organizationId: queryParams.organizationId || currentOrgId,
    },
    skip: selectedReportType !== 'CHARGING_TRANSACTIONS',
  })

  const transactions = chargingTransactionsReport.filter((transaction) => {
    return !(
      !isAdmin &&
      transaction.depotId &&
      !synopUser?.sites?.includes(transaction.depotId)
    )
  })

  const reports = {
    VEHICLE_ACTIVITY: {
      data: vehicleActivityReport,
      columns: vehicleActivityColumns,
      csvFormat: vehicleActivityCSVOption,
      ...vehicleActivityStatus,
    },
    TRIP: {
      data: tripReport,
      columns: tripColumns,
      csvFormat: tripCSVOption,
      ...tripStatus,
    },
    CHARGING_TRANSACTIONS: {
      data: transactions,
      columns: chargingTransactionsColumns,
      csvFormat: chargingTransactionsCSVOption,
      ...chargingTransactionsStatus,
    },
  }

  const {
    data: currentReportData = [],
    columns: currentReportColumns = [],
    csvFormat: currentReportCSVFormat = undefined,
    isGenerating: isGeneratingCurrentReport = false,
  } = selectedReportType ? reports[selectedReportType] : {}

  const tableTitle = selectedReportType
    ? `${reportOptions[selectedReportType].label} Report`
    : ''

  const shouldShowTable = selectedReport !== null
  type ColumnsType = Table.ColumnSpec<(typeof currentReportData)[number]>

  return (
    <Stack spacing={3} sx={{ pb: 8 }}>
      <ReportSelectorCask
        {...{ setSelectedReport }}
        isLoading={isGeneratingCurrentReport}
      />

      {shouldShowTable && (
        <ReportTableCask
          columns={currentReportColumns as ColumnsType}
          downloadOptions={currentReportCSVFormat}
          getRowId={(row) => row.id}
          isLoading={isGeneratingCurrentReport}
          tableData={currentReportData}
          title={tableTitle}
        />
      )}
    </Stack>
  )
}

export default ReportsPage
