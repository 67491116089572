import {
  EntitySortComparator,
  EntitySorterMap,
  EntitySortSelect,
  getFloatFromSourceAttribute,
  SortDirection,
  SortDirections,
} from '@synop-react/common'
import { VehicleModel } from '@synop-react/api'

export function VehicleSortSelect() {
  const defaultNameSortDirection: SortDirection = SortDirections.ASCENDING
  const defaultSocSortDirection: SortDirection = SortDirections.DESCENDING
  const defaultStatusSortDirection: SortDirection = SortDirections.ASCENDING

  const sortByName: EntitySortComparator<VehicleModel> = (
    vehicles,
    direction = defaultNameSortDirection
  ) => {
    const newVehicleOrder = [...vehicles]
    return newVehicleOrder.sort(
      ({ vehicleNm: assetNmA = '' }, { vehicleNm: assetNmB = '' }) => {
        const sortOrder = direction === SortDirections.ASCENDING ? 1 : -1

        if (!assetNmA && !assetNmB) {
          return 0
        } else if (!assetNmA && assetNmB) {
          return 1 * sortOrder
        } else if (assetNmA && !assetNmB) {
          return -1 * sortOrder
        }

        return (assetNmA || '').localeCompare(assetNmB || '') * sortOrder
      }
    )
  }

  const sortByStatus: EntitySortComparator<VehicleModel> = (
    vehicles,
    direction = defaultStatusSortDirection
  ) => {
    const newVehicleOrder = [...vehicles]
    return newVehicleOrder.sort(
      ({ vehicleStatus: a }, { vehicleStatus: b }) => {
        const sortOrder = direction === SortDirections.ASCENDING ? 1 : -1

        if (!a && !b) {
          return 0
        } else if (!a && b) {
          return 1 * sortOrder
        } else if (a && !b) {
          return -1 * sortOrder
        }

        const statusA = a ? a?.value : ''
        const statusB = b ? b?.value : ''
        return statusA.localeCompare(statusB) * sortOrder
      }
    )
  }

  const sortBySoc: EntitySortComparator<VehicleModel> = (
    vehicles,
    direction = defaultSocSortDirection
  ) => {
    const newVehicleOrder = [...vehicles]
    return newVehicleOrder.sort(
      ({ stateOfCharge: socA }, { stateOfCharge: socB }) => {
        const sortOrder = direction === SortDirections.ASCENDING ? 1 : -1

        if (!socA && !socB) {
          return 0
        } else if (!socA && socB) {
          return 1 * sortOrder
        } else if (socA && !socB) {
          return -1 * sortOrder
        }

        const socAValue = getFloatFromSourceAttribute(socA)
        const socBValue = getFloatFromSourceAttribute(socB)
        const isAscending = direction === SortDirections.ASCENDING
        return isAscending ? socBValue - socAValue : socAValue - socBValue
      }
    )
  }

  // TODO: Commented out until Distance Remaining is working
  // const sortByRouteRemaining: EntitySortComparator<VehicleModel> = (
  //   vehicles,
  //   direction = defaultSocSortDirection
  // ) => {
  //   const newVehicleOrder = [...vehicles]
  //   return newVehicleOrder.sort(
  //     ({ distanceUntilHome: routeA }, { distanceUntilHome: routeB }) => {
  //       const sortOrder = direction === SortDirections.ASCENDING ? 1 : -1

  //       if (!routeA && !routeB) {
  //         return 0
  //       } else if (!routeA && routeB) {
  //         return 1 * sortOrder
  //       } else if (routeA && !routeB) {
  //         return -1 * sortOrder
  //       }

  //       const routeAValue = getFloatFromSourceAttribute(routeA)
  //       const routeBValue = getFloatFromSourceAttribute(routeB)
  //       const isAscending = direction === SortDirections.ASCENDING
  //       return isAscending
  //         ? routeBValue - routeAValue
  //         : routeAValue - routeBValue
  //     }
  //   )
  // }

  const sortByRangeRemaining: EntitySortComparator<VehicleModel> = (
    vehicles,
    direction = defaultSocSortDirection
  ) => {
    const newVehicleOrder = [...vehicles]
    return newVehicleOrder.sort(
      ({ remainingRange: rangeA }, { remainingRange: rangeB }) => {
        const sortOrder = direction === SortDirections.ASCENDING ? 1 : -1

        if (!rangeA && !rangeB) {
          return 0
        } else if (!rangeA && rangeB) {
          return 1 * sortOrder
        } else if (rangeA && !rangeB) {
          return -1 * sortOrder
        }

        const rangeAValue = getFloatFromSourceAttribute(rangeA)
        const rangeBValue = getFloatFromSourceAttribute(rangeB)

        const isAscending = direction === SortDirections.ASCENDING
        return isAscending
          ? rangeBValue - rangeAValue
          : rangeAValue - rangeBValue
      }
    )
  }

  const vehicleSorters: EntitySorterMap<VehicleModel> = {
    nameAsc: {
      label: 'Vehicle Name (a-z)',
      comparator: sortByName,
      direction: SortDirections.ASCENDING,
    },
    nameDesc: {
      label: 'Vehicle Name (z-a)',
      comparator: sortByName,
      direction: SortDirections.DESCENDING,
    },
    socAsc: {
      label: 'Highest State of Charge',
      comparator: sortBySoc,
      direction: SortDirections.ASCENDING,
    },
    socDesc: {
      label: 'Lowest State of Charge',
      comparator: sortBySoc,
      direction: SortDirections.DESCENDING,
    },
    /*
    TODO: Commented out until Distance Remaining is working
    routeAsc: {
      label: 'Longest Route Remaining',
      comparator: sortByRouteRemaining,
      direction: SortDirections.ASCENDING,
    },
    routeDesc: {
      label: 'Shortest Route Remaining',
      comparator: sortByRouteRemaining,
      direction: SortDirections.DESCENDING,
    },
     */
    rangeAsc: {
      label: 'Most Range Remaining',
      comparator: sortByRangeRemaining,
      direction: SortDirections.ASCENDING,
    },
    rangeDesc: {
      label: 'Least Range Remaining',
      comparator: sortByRangeRemaining,
      direction: SortDirections.DESCENDING,
    },

    statusAsc: {
      label: 'Status (a-z)',
      comparator: sortByStatus,
      direction: SortDirections.ASCENDING,
    },
    statusDesc: {
      label: 'Status (z-a)',
      comparator: sortByStatus,
      direction: SortDirections.DESCENDING,
    },
  }

  return <EntitySortSelect entitySorters={vehicleSorters} />
}

export default VehicleSortSelect
