import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useMemo, useState } from 'react'

import { RootAPI } from '@synop-react/api'
import {
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
  useStatusColorMaps,
} from '@synop-react/common'

import { parseStatusSummaries } from './parseStatusSummaries'
import {
  StatusEntity,
  StatusSummaryCharts,
  SummaryData,
} from './StatusSummaryCharts'

const { useGetLatestStatusSummaryQuery } = RootAPI.synopRootAPI

// Span of time in seconds between updates of the chargers overview page. The
// updates are to the chargers table and the status summary charts.
const UPDATE_INTERVAL_SECONDS = 60

export const ToggleableStatusSummaryCharts = () => {
  // The state of the toggles
  const [showLastKnownChargers, setShowLastKnownChargers] = useState(false)
  const [showLastKnownConnectors, setShowLastKnownConnectors] = useState(false)

  const summaryData = useStatusSummaryData(
    showLastKnownChargers,
    showLastKnownConnectors
  )

  return (
    <StatusSummaryCharts
      ChargerCardProps={{
        Actions: (
          <DisplayLastKnownToggle
            onChange={setShowLastKnownChargers}
            showLastKnown={showLastKnownChargers}
          />
        ),
        title: getTitle('chargers', showLastKnownChargers),
      }}
      ConnectorCardProps={{
        Actions: (
          <DisplayLastKnownToggle
            onChange={setShowLastKnownConnectors}
            showLastKnown={showLastKnownConnectors}
          />
        ),
        title: getTitle('connectors', showLastKnownConnectors),
      }}
      data={summaryData}
    />
  )

  function getTitle(type: StatusEntity, showLastKnown: boolean) {
    const capitalizedType = type === 'chargers' ? 'Charger' : 'Connector'
    const suffix = [capitalizedType, 'Status'].join(' ')
    return showLastKnown ? `Last Known ${suffix}` : suffix
  }
}

type DisplayLastKnownToggleProps = {
  onChange: (checked: boolean) => void
  showLastKnown: boolean
}

function DisplayLastKnownToggle({
  onChange,
  showLastKnown,
}: DisplayLastKnownToggleProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={showLastKnown}
          onChange={(_, checked) => onChange(checked)}
        />
      }
      label={<Typography variant="caption">Display Last Known</Typography>}
    />
  )
}

function useStatusSummaryData(
  lastKnownChargers: boolean,
  lastKnownConnectors: boolean
): SummaryData {
  const colorMaps = useStatusColorMaps()
  const siteId = useSiteSelector().selected?.id
  const orgId = useSelectedOrgIdOrCurrent()
  const { data, isLoading } = useGetLatestStatusSummaryQuery(
    {
      organizationId: orgId,
      siteIds: siteId ? [siteId] : undefined,
    },
    { pollingInterval: UPDATE_INTERVAL_SECONDS * 1000, skip: !orgId }
  )

  const chargerKey = lastKnownChargers ? 'lastKnownStatuses' : 'currentStatuses'
  const connectorKey = lastKnownConnectors
    ? 'lastKnownStatuses'
    : 'currentStatuses'

  const chargers = useMemo(
    () => parseStatusSummaries(data?.chargers[chargerKey], colorMaps.charger),
    [data?.chargers, colorMaps.charger, chargerKey]
  )

  const connectors = useMemo(
    () =>
      parseStatusSummaries(data?.connectors[connectorKey], colorMaps.connector),
    [data?.connectors, colorMaps.connector, connectorKey]
  )

  return { summaryData: { chargers, connectors }, isLoading }
}
