import { emDash } from './constants'
import { isNumber } from 'lodash'

export enum PowerUnits {
  WATT = 'W',
  KILOWATT = 'KW',
  MEGAWATT = 'MW',
  GIGAWATT = 'GW',
  TERAWATT = 'TW',
  PETAWATT = 'PW',
}

export const formatPowerUnits = (powerUnits: PowerUnits) => {
  switch (powerUnits) {
    case PowerUnits.WATT:
      return 'Watt'
    case PowerUnits.KILOWATT:
      return 'Kilowatt'
    case PowerUnits.MEGAWATT:
      return 'Megawatt'
    case PowerUnits.GIGAWATT:
      return 'Gigawatt'
    case PowerUnits.TERAWATT:
      return 'Terawatt'
    case PowerUnits.PETAWATT:
      return 'Petawatt'
    default:
      return powerUnits
  }
}

export const formatPowerUnitsAbbr = (powerUnits: PowerUnits) => {
  switch (powerUnits) {
    case PowerUnits.WATT:
      return 'W'
    case PowerUnits.KILOWATT:
      return 'kW'
    case PowerUnits.MEGAWATT:
      return 'MW'
    case PowerUnits.GIGAWATT:
      return 'GW'
    case PowerUnits.TERAWATT:
      return 'TW'
    case PowerUnits.PETAWATT:
      return 'PW'
    default:
      return powerUnits
  }
}

type PowerOptions = {
  longForm?: boolean
  omitUnits?: boolean
  reduce?: boolean
  units?: PowerUnits
}

export const roundValueForFormatting = (value: string | number): number => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  // always round power to one decimal places per UX guidlines
  // Number.EPSILON is used to avoid floating point rounding errors
  // per: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  return Math.round((value + Number.EPSILON) * 10) / 10
}

export const formatPower = (
  power: number | string | null = '',
  options?: PowerOptions
): string => {
  if (power === null || (typeof power === 'string' && power.trim() === ''))
    return emDash

  // If the options specify to do so, reduce the power to the largest unit that is >= 1
  let powerUnits = options?.units ?? PowerUnits.KILOWATT
  if (options?.reduce) {
    const powerNum = isNumber(power) ? power : parseFloat(power)
    const { num, unit } = reduceWatts(powerNum, powerUnits)
    power = num
    powerUnits = unit
  }

  // NOTE: since these got defined as uppercase, we need to convert the input to uppercase to match
  // (in case the backend doesn't pass them that way)
  powerUnits = powerUnits.toUpperCase() as PowerUnits

  const units = options?.longForm
    ? formatPowerUnits(powerUnits)
    : formatPowerUnitsAbbr(powerUnits)

  const powerValue = roundValueForFormatting(power)
  if (powerValue < 0) return emDash
  if (options?.omitUnits) return powerValue.toString()
  return [powerValue, units].join(' ')
}

const powerUnitStrings = Object.values(PowerUnits)

type ReduceWattsOutput = {
  num: number
  unit: PowerUnits
}

export function reduceWatts(
  num: number,
  unit: PowerUnits = PowerUnits.WATT
): ReduceWattsOutput {
  if (num >= 1000) {
    const nextUnit = powerUnitStrings[powerUnitStrings.indexOf(unit) + 1]
    if (nextUnit) return reduceWatts(num / 1000, nextUnit)
  }
  return { num, unit }
}

export function convertWatts(
  num: number,
  currentUnit: PowerUnits,
  desiredUnit: PowerUnits
): number {
  const currentUnitIndex = powerUnitStrings.indexOf(currentUnit as PowerUnits)
  const desiredUnitIndex = powerUnitStrings.indexOf(desiredUnit as PowerUnits)
  if (currentUnitIndex === -1 || desiredUnitIndex === -1) {
    console.error(
      `Invalid Power Unit: Current: ${currentUnit} Desired: ${desiredUnit}`
    )
    return num
  }
  const diff = desiredUnitIndex - currentUnitIndex
  const convertedNum = num / Math.pow(1000, diff)
  return convertedNum
}
