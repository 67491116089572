import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { CurrentOrganizationStore } from '../store/currentOrganizationSlice'

const selectPrincipalOrgId = (state: CurrentOrganizationStore) =>
  state.currentOrganization.principalOrgId

const selectCurrentOrgId = (state: CurrentOrganizationStore) =>
  state.currentOrganization.cloakedOrgIds.at(-1) as string

const selectIsCloaking = createSelector(
  selectCurrentOrgId,
  selectPrincipalOrgId,
  (currentOrgId, principalOrgId) => {
    return principalOrgId !== currentOrgId
  }
)

/** Converts a selector into a hook that can be used in a functional component. */
type CurrentOrganizationSelector<T> = (state: CurrentOrganizationStore) => T
function selectorHookFactory<T>(selector: CurrentOrganizationSelector<T>) {
  return () => useSelector(selector)
}

export const useCurrentOrgId = selectorHookFactory(selectCurrentOrgId)
export const usePrincipalOrgId = selectorHookFactory(selectPrincipalOrgId)
export const useIsCloaking = selectorHookFactory(selectIsCloaking)
