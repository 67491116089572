import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useMemo } from 'react'

import {
  Cask,
  CaskDatum,
  emDash,
  formatEnergy,
  formatPower,
  Icons,
  StatusChip,
  StatusColorProvider,
  useFormat,
} from '@synop-react/common'
import {
  RootAPI,
  statusIsInUse,
  useChargerDetailsFromPoll,
} from '@synop-react/api'
import { usePalette } from '@synop-react/theme'

type ChargerStatusCaskProps = { chargerId: string }
export const ChargerStatusCask = ({ chargerId }: ChargerStatusCaskProps) => {
  const { formatDateTime } = useFormat()
  const { spacing } = useTheme()

  const {
    getCharger: { data: charger },
  } = useChargerDetailsFromPoll({
    chargerId,
    pollingIntervalInSeconds: 5,
  })

  const {
    connectorIds = [],
    connectorStatus,
    importKwhLast24h,
    insights,
    lastHeardTimestamp,
    latestChargerStatus,
    livePowerImport = 0,
  } = charger ?? {}

  // Calculate the number of connectors in use. This is defined as the number of connectors
  // with a status of 'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', or 'Finishing'.
  const numConnectors = connectorIds.filter((id) => id !== 0).length
  const numConnectorsInUse = useMemo(
    () =>
      Object.entries(connectorStatus ?? {}).filter(
        ([id, { currentCombinedStatus }]) =>
          id !== '0' && statusIsInUse(currentCombinedStatus)
      ).length,
    [connectorStatus]
  )

  const currentStatus = latestChargerStatus?.currentCombinedStatus
  const gridItemProps = { item: true, sm: 3, xs: 6 }

  return (
    <Cask
      subtitle={
        lastHeardTimestamp && (
          <>
            Last Heard • <b>{formatDateTime(lastHeardTimestamp).timeOnDate}</b>
          </>
        )
      }
      title={
        <Stack alignItems="center" direction="row">
          <Typography variant="h5">Charger Status</Typography>
          <StatusColorProvider>
            <Box sx={{ ml: 2 }}>
              <StatusChip.Charger status={currentStatus} />
            </Box>
          </StatusColorProvider>
        </Stack>
      }
    >
      <Box>
        <Grid
          alignItems="center"
          container
          spacing={2}
          sx={{ height: spacing(14) }}
        >
          <Grid {...gridItemProps} textAlign="center">
            <ChargerHealthIcon {...insights} />
          </Grid>

          <Grid {...gridItemProps}>
            <CaskDatum
              label="Energy Import Today"
              tooltip="Sum of energy imported for this charger's transactions over the last 24 hours."
              value={formatEnergy(importKwhLast24h)}
            />
          </Grid>

          <Grid {...gridItemProps}>
            <CaskDatum
              label="Connectors in Use"
              tooltip="Number of connectors in one of the following statuses: Preparing, Charging, Suspended EVSE, Suspended EV, or Finishing."
              value={`${numConnectorsInUse} / ${numConnectors}`}
            />
          </Grid>

          <Grid {...gridItemProps}>
            <CaskDatum
              label="Live Utilization"
              tooltip="Current power output for this charger."
              value={
                currentStatus === 'Unknown' ? (
                  <>{emDash} kW</>
                ) : (
                  formatPower(livePowerImport)
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Cask>
  )
}

const tooltipText = {
  Healthy:
    'This charger has not been in a faulted or offline status in the last 24 hours.',
  Marginal: 'This charger has been offline in the last 24 hours.',
  Unhealthy: 'This charger has faulted in the last 24 hours.',
  Unknown: 'This charger has not connected to Synop.',
}

const ChargerHealthIcon = ({
  chargerHealth,
}: Partial<RootAPI.ChargerInsightsModel>) => {
  const { palette } = usePalette()

  const size = 20
  const icon = useMemo(() => {
    switch (chargerHealth) {
      case 'Healthy':
        return <Icons.CheckCircle color={palette.success.main} size={size} />
      case 'Marginal':
        return <Icons.CloudOff color={palette.warning.main} size={size} />
      case 'Unhealthy':
        return <Icons.AlertTriangle color={palette.error.main} size={size} />
      case 'Unknown':
        return <Icons.HelpCircle color={palette.secondary['30p']} size={size} />
      case undefined:
        return emDash
    }
  }, [chargerHealth, palette])

  return (
    <CaskDatum
      centered
      label="Charger Health"
      tooltip={
        chargerHealth && (
          <>
            <b>{chargerHealth}: </b>
            {tooltipText[chargerHealth]}
          </>
        )
      }
      value={icon}
    />
  )
}
