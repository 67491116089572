import { RootAPI } from '../..'

export const fleet: RootAPI.Organization = {
  id: '8ac5611d7c2d6a07017c2d6a2e1c0000',
  organizationNm: 'Fleet 1',
}

export const terms: RootAPI.OrganizationTerms[] = [
  {
    id: 'termsId-1',
    documentTitle: 'Fleet Terms 1',
    effectiveDt: '2021-01-01',
    organizationId: '1',
    terms: 'These are the terms',
  },
  {
    id: 'termsId-2',
    documentTitle: 'Fleet Terms 2',
    effectiveDt: '2021-01-01',
    organizationId: '2',
    terms: 'These are the terms',
  },
]

export const users = [
  {
    userId: '47915158-64b7-4ce8-8880-c1092ef782ac',
    name: 'Kevin Kong',
    email: 'kevin+81@synop.ai',
    phoneNum: '+18888888888',
    fleetId: '8ac5611d7c2d6a07017c2d6a2e1c0000',
    status: 'FORCE_CHANGE_PASSWORD',
    mfaEnabled: false,
    isActive: true,
    preferredUnits: null,
    roles: ['Admin'],
    depots: [],
    mfaOptionTypes: [],
    rfidTag: null,
    termsAccepted: null,
    termsAcceptedDt: null,
    rfidTags: [],
  },
  {
    userId: '136fc880-8db5-4317-9eb5-127d41009e35',
    name: 'Gagan Dhillon',
    email: 'gdhillon@synop.ai',
    phoneNum: '+18888888888',
    fleetId: '8ac5611d7c2d6a07017c2d6a2e1c0000',
    status: 'CONFIRMED',
    mfaEnabled: false,
    isActive: true,
    preferredUnits: null,
    roles: ['Driver'],
    depots: [],
    mfaOptionTypes: [],
    rfidTag: '123456789',
    termsAccepted: null,
    termsAcceptedDt: null,
    rfidTags: [],
  },
  {
    userId: '505dfb66-2665-4ecb-8e5b-7250f7cebc88',
    name: 'Tyler',
    email: 'tyler@synop.ai',
    phoneNum: '+18888888888',
    fleetId: '8ac5611d7c2d6a07017c2d6a2e1c0000',
    status: 'CONFIRMED',
    mfaEnabled: false,
    isActive: true,
    preferredUnits: 'SI',
    roles: ['Admin'],
    depots: [],
    mfaOptionTypes: [],
    rfidTag: null,
    termsAccepted: true,
    termsAcceptedDt: '2022-10-31T18:33:04.855',
    rfidTags: [],
  },
]
