import { BrowserView, ToolbarControls } from '../EntityBrowser'
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material'
import { Icons } from '../../../Icons'
import { List, Map } from 'react-feather'
import { useEffect } from 'react'
import { useFullscreenControls, useIsFullscreen } from '../../../store'
import { useLocalStorageState, useRouting } from '../../../utils'

export interface EntityBrowserToolbar {
  toolbarControls?: ToolbarControls
  setBrowserView: (view: BrowserView) => void
  displayedView: BrowserView
  resetDisplayedEntities: () => void
}

export function EntityBrowserToolbar({
  toolbarControls = [] as ToolbarControls,
  setBrowserView,
  displayedView,
  resetDisplayedEntities,
}: EntityBrowserToolbar) {
  const { palette } = useTheme()
  const isFullscreen = useIsFullscreen()
  const { enterFullscreen } = useFullscreenControls()
  const { location, routes } = useRouting()
  const [previousView, setPreviousView] = useLocalStorageState<BrowserView>(
    'previousView',
    { defaultValue: 'map' }
  )

  // Change back to regular map view when changing back from fullscreen
  useEffect(() => {
    if (!isFullscreen && displayedView === 'displaymode') {
      setBrowserView(previousView)
    }
  }, [isFullscreen, setBrowserView, displayedView, previousView])

  const renderToolbarControls = toolbarControls.map(({ key, Component }) => (
    <Grid key={key} item>
      {<Component />}
    </Grid>
  ))

  const fullscreenRoutes = [routes.vehicles.root]
  const isFullscreenable = fullscreenRoutes.includes(location.pathname)
  const MaybeFullscreenButton = isFullscreenable ? (
    <IconButton
      onClick={() => {
        enterFullscreen()
        setBrowserView('displaymode')
        resetDisplayedEntities()
      }}
    >
      <Icons.Monitor
        data-cy="map-fullscreen"
        stroke={palette.primary.main}
        strokeWidth="2"
      />
    </IconButton>
  ) : null

  return !isFullscreen ? (
    <Paper
      component={Stack}
      direction="column"
      elevation={1}
      justifyContent="center"
      sx={{
        bgcolor: 'brand.main',
        minHeight: 64,
        px: 2,
        py: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Grid container direction="row" spacing={2} sx={{ alignItems: 'end' }}>
          {renderToolbarControls}
        </Grid>
        {MaybeFullscreenButton}
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={(e, view) => {
            if (view) {
              setBrowserView(view)
              setPreviousView(view)
            }
          }}
          value={displayedView}
        >
          <ToggleButton value="map">
            <Map data-cy="map-view-toggle" strokeWidth="1" />
          </ToggleButton>
          <ToggleButton value="table">
            <List data-cy="table-view-toggle" strokeWidth="1" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Paper>
  ) : null
}

export default EntityBrowserToolbar
