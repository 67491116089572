import { useMemo } from 'react'

import {
  formatEfficiencyFromSourceAttributes,
  ProgressPillColor,
  StatusCask,
} from '@synop-react/common'
import { RootAPI, useUserPrefs, VehicleMetrics } from '@synop-react/api'

const { useGetMetricsForVehicleQuery } = RootAPI.synopRootAPI

export type VehicleEfficiencyDetailProps = {
  vehicleId: string
}
export function VehicleEfficiencyDetail({
  vehicleId,
}: VehicleEfficiencyDetailProps) {
  const { tzDayjs } = useUserPrefs()

  const currentDate = useMemo(
    () => tzDayjs().startOf('day').format('YYYY-MM-DD'),
    [tzDayjs]
  )

  const { data: dailyMetrics } = useGetMetricsForVehicleQuery({
    id: vehicleId,
    date: currentDate,
  })

  const { efficiency } = (dailyMetrics || {}) as VehicleMetrics

  const maxUpperBound = 3
  const upperBound = 2
  const lowerBound = 0.5

  const getBarColor = (): ProgressPillColor => {
    const minEfficiency = efficiency?.value || 0
    if (minEfficiency <= lowerBound) return 'error'
    else if (minEfficiency < upperBound) return 'secondary'
    else if (minEfficiency >= upperBound) return 'success'
    throw new Error('Vehicle Efficiency Out of Bounds')
  }

  const efficiencyBar =
    efficiency?.value >= 0
      ? {
          progress: {
            value: efficiency?.value || 0,
            color: getBarColor(),
            upperBound: maxUpperBound,
          },
        }
      : { hideProgress: true }
  return (
    <StatusCask.Item
      {...efficiencyBar}
      label="Live Efficiency"
      value={formatEfficiencyFromSourceAttributes(efficiency)}
    />
  )
}

export default VehicleEfficiencyDetail
