import {
  DistanceUnit,
  RootAPI,
  TimeUnits,
  Trip,
  useUserPrefs,
} from '@synop-react/api'
import {
  formatCO2,
  formatDistance,
  formatEfficiency,
  formatTimeUnits,
  StatusCask,
} from '@synop-react/common'
import calculateEfficiencyColor from '../../utils/calculators/calculateEfficiencyColor'
import calculateSocColor from '../../utils/calculators/calculateSocColor'

export interface TripStatusCaskProps {
  tripDetails: RootAPI.Trip
}

export function TripStatusCask({ tripDetails }: TripStatusCaskProps) {
  const { tzDayjs } = useUserPrefs()
  const {
    updated,
    startSoc,
    endSoc,
    efficiency,
    efficiencyUnit,
    emissionsSave,
    emissionsUnit,
    distance,
    distanceUnit,
    routeStart,
    routeEnd,
  } = (tripDetails || {}) as Trip

  const efficiencyValue = efficiency || 0

  const tripEfficiency = formatEfficiency(efficiency, efficiencyUnit)

  const totalMinutes = formatTimeUnits(
    tzDayjs(routeEnd).diff(tzDayjs(routeStart), 'minutes'),
    TimeUnits.MINUTES
  )
  const totalDistance = formatDistance(distance, distanceUnit as DistanceUnit)
  const lowerEfficiencyBound = efficiencyValue - efficiencyValue * 0.2
  const upperEfficiencyBound = efficiencyValue + efficiencyValue * 0.2

  return (
    <StatusCask lastUpdated={updated} title="Details">
      <StatusCask.Item
        label="Start Soc"
        progress={{
          value: startSoc || 0,
          color: calculateSocColor(startSoc as number),
        }}
        value={parsedSoC(startSoc)}
      />
      <StatusCask.Item
        label="End Soc"
        progress={{
          value: endSoc || 0,
          color: calculateSocColor(endSoc as number),
        }}
        value={parsedSoC(endSoc)}
      />
      <StatusCask.Item
        label="Trip Efficiency"
        progress={{
          value: efficiencyValue,
          lowerBound: lowerEfficiencyBound,
          upperBound: upperEfficiencyBound,
          color: calculateEfficiencyColor(efficiency as number),
        }}
        value={tripEfficiency}
      />
      <StatusCask.Item
        //TODO: Follow up on emissions range for colorss
        label="Emissions Saved"
        progress={{
          value: emissionsSave || 0,
          color: 'secondary',
        }}
        value={formatCO2(emissionsSave, emissionsUnit)}
      />
      <StatusCask.Item label="Total Time" value={`${totalMinutes}`} />
      <StatusCask.Item label="Total Distance" value={`${totalDistance}`} />
    </StatusCask>
  )
}

const parsedSoC = (soc: number | undefined) => {
  if (soc) {
    return `${Math.round(soc)}%`
  } else return 'n/a'
}

export default TripStatusCask
