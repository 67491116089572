import { alpha, Chip, useTheme } from '@mui/material'
import {
  ChargerStatuses,
  CurrentChargerStatus,
  CurrentConnectorStatus,
  CurrentConnectorStatuses,
} from '@synop-react/api'
import {
  createContext,
  forwardRef,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'

// Combine charger and connector statuses, removing duplicates
type ChargerOrConnectorStatus = (typeof ChargerOrConnectorStatuses)[number]
export const ChargerOrConnectorStatuses = [
  ...ChargerStatuses,
  ...CurrentConnectorStatuses,
].filter((val, i, arr) => arr.indexOf(val) === i)

/*********************
 * Status color maps *
 *********************/
export type ColorMap<T extends string> = Record<T, string>

type ChargerStatusColorMap = ColorMap<CurrentChargerStatus>
type ConnectorStatusColorMap = ColorMap<CurrentConnectorStatus>
type CommonStatuses = CurrentChargerStatus & CurrentConnectorStatus
type AllStatuses = CurrentChargerStatus | CurrentConnectorStatus

function useCommonColors(): ColorMap<CommonStatuses> {
  const { error, secondary, warning } = useTheme().palette
  return {
    Faulted: error.main,
    Offline: warning.main,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Unavailable: error[700]!,
    Unknown: secondary['30p'],
  }
}

export function useConnectorStatusColors(): ConnectorStatusColorMap {
  const { palette } = useTheme()
  const { charting } = palette
  return {
    ...useCommonColors(),
    Available: palette.info.main,
    Charging: palette.success.main,
    Finishing: charting.chart4.main,
    Preparing: charting.chart2.main,
    Reserved: palette.secondary.main,
    SuspendedEV: charting.chart1.main,
    SuspendedEVSE: charting.chart3.main,
  }
}

export function useChargerStatusColors(): ChargerStatusColorMap {
  const { palette } = useTheme()
  return {
    ...useCommonColors(),
    Operative: palette.info.main,
  }
}

const statusesWithOutlinedBackground: AllStatuses[] = [
  'Faulted',
  'Offline',
  'Unavailable',
  'Available',
  'Operative',
]

type StatusColorMaps = {
  charger: ChargerStatusColorMap
  connector: ConnectorStatusColorMap
}

const StatusColorContext = createContext({} as StatusColorMaps)
export const useStatusColorMaps = () => useContext(StatusColorContext)

export function StatusColorProvider({ children }: PropsWithChildren) {
  const charger = useChargerStatusColors()
  const connector = useConnectorStatusColors()
  return (
    <StatusColorContext.Provider value={{ charger, connector }}>
      {children}
    </StatusColorContext.Provider>
  )
}

/**************************
 * Status chip components *
 **************************/

type CommonProps = { variant?: 'filled' | 'outlined' }
type ChargerStatusChipProps = CommonProps & { status?: CurrentChargerStatus }
type ConnectorStatusChipProps = CommonProps & {
  status?: CurrentConnectorStatus
}

type ChargerOrConnectorStatusChipProps =
  | (ChargerStatusChipProps & { isCharger: true })
  | (ConnectorStatusChipProps & { isCharger: false })

const ChargerOrConnectorChip = forwardRef<
  HTMLDivElement,
  ChargerOrConnectorStatusChipProps
>(function ChargerOrConnectorStatusChip(props, ref) {
  const { isCharger, status, variant = 'filled', ...rest } = props
  const statusColors = useStatusColorMaps()

  // Determine the text, background and border colors for the chip. These will depend on the
  // status and the chip variant.
  const [color, backgroundColor, borderColor] = useMemo(() => {
    if (!status) return [null, null, null]

    const statusColor = isCharger
      ? statusColors.charger[status]
      : statusColors.connector[status]

    // For outlined chips, the text color will just be the status color. For filled chips, the text
    // color will be white.
    const isOutlined = variant === 'outlined'
    const textColor = isOutlined ? statusColor : '#fff'

    // If the variant is "outlined" and the status is one of those with a background color, the
    // color will be the main status color with an alpha of 0.04.
    const bgColor = isOutlined
      ? statusesWithOutlinedBackground.includes(status)
        ? alpha(statusColor, 0.04)
        : null
      : statusColor

    // Most statuses have a border color that is the same as the status color. However, "Faulted"
    // statuses use an opacity of 0.5.
    const borderColor =
      isOutlined && status === 'Faulted' ? alpha(statusColor, 0.5) : statusColor

    return [textColor, bgColor, borderColor]
  }, [isCharger, status, statusColors, variant])

  if (!status) return null

  return (
    <Chip
      ref={ref}
      label={status}
      sx={{ backgroundColor, borderColor, color, height: 24 }}
      variant={variant}
      // Required for tooltips
      {...rest}
    />
  )
})

const ChargerChip = forwardRef<HTMLDivElement, ChargerStatusChipProps>(
  function ChargerStatusChip(props, ref) {
    return <ChargerOrConnectorChip ref={ref} isCharger {...props} />
  }
)

const ConnectorChip = forwardRef<HTMLDivElement, ConnectorStatusChipProps>(
  function ConnectorStatusChip(props, ref) {
    return <ChargerOrConnectorChip ref={ref} isCharger={false} {...props} />
  }
)

export const StatusChip = {
  Charger: ChargerChip,
  ChargerOrConnector: ChargerOrConnectorChip,
  Connector: ConnectorChip,
}

/*******************
 * Status ordering *
 *******************/

/**
 * Returns the order of a status for sorting purposes. Higher priority statuses
 * receive higher order values. Connector statuses are ordered by their position
 * within the standard connector status flow.
 */
type NonNullStatus = NonNullable<ChargerOrConnectorStatus>
const statusOrderMap: Record<NonNullStatus, number> = {
  // Charger statuses
  Operative: 1,

  // Connector statuses
  Available: 2,
  Preparing: 3,
  Charging: 4,
  SuspendedEVSE: 5,
  SuspendedEV: 5,
  Finishing: 6,
  Reserved: 7,

  // Common statuses
  Unknown: 8,
  Unavailable: 9,
  Offline: 10,
  Faulted: 11,
}

export function getStatusOrder(status?: ChargerOrConnectorStatus) {
  return status === undefined ? 0 : statusOrderMap[status]
}
