import * as yup from 'yup'
import { CustomRatePeriodField } from './rateTypes'
import { DepotAutocomplete } from '@synop-react/common'
import { isNumber } from 'lodash'
import { RootAPI } from '@synop-react/api'
import dayjs, { Dayjs } from 'dayjs'

export const rateFormSchema = yup
  .object({
    allDayRate: yup.number().min(0, 'Rate must be positive'),
    depot: DepotAutocomplete.Schema,
    rateName: yup.string().nullable().required('Rate Name is required'),
    customRatePeriods: yup.array().of(
      yup.object().shape({
        price: yup.number().min(0, 'Rate must be positive'),
      })
    ),
  })
  .required()

export const defaultCustomRatePeriod = [
  {
    from: dayjs().startOf('day'),
    to: dayjs().startOf('day').add(1, 'hour'),
    price: '0.00',
  },
]

export const dayJsToLocalTime = (date: Dayjs) => date.format('HH:mm')

export const isAllDayRate = (ratePeriods: RootAPI.RatePeriod[]) => {
  const firstPeriod = ratePeriods[0]
  if (!firstPeriod) return false

  const { from, to } = firstPeriod

  return from === '00:00' && to === '23:59'
}

export const parseRatePeriodToDayjs = (ratePeriods: RootAPI.RatePeriod[]) =>
  isAllDayRate(ratePeriods)
    ? defaultCustomRatePeriod
    : (ratePeriods
        .map(({ price, from, to }) => {
          const [fromHour, fromMinute] = from
            .split(':')
            .map((str) => parseInt(str))
          const [toHour, toMinute] = to.split(':').map((str) => parseInt(str))

          return isNumber(fromHour) &&
            isNumber(fromMinute) &&
            isNumber(toHour) &&
            isNumber(toMinute)
            ? ({
                price: `${price}`,
                from: dayjs().set('hour', fromHour).set('minute', fromMinute),
                to: dayjs().set('hour', toHour).set('minute', toMinute),
              } as CustomRatePeriodField)
            : null
        })
        .filter(Boolean) as CustomRatePeriodField[])
