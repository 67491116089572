import { Avatar, Grid, useTheme } from '@mui/material'
import { MouseEvent } from 'react'
import { useCurrentUser } from '@synop-react/api'
import { useRouting } from '@synop-react/common'

interface UserAvatarProps {
  onClick: (event: MouseEvent<HTMLElement>) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const UserAvatar = ({ onClick = () => {} }: UserAvatarProps) => {
  const { location, routes } = useRouting()
  const { palette } = useTheme()
  const { synopUser } = useCurrentUser()

  const isActive = location.pathname.includes(routes.settings.root)
  const backgroundColor = isActive
    ? palette.secondary.main
    : palette.background.paper
  const color = isActive ? palette.background.paper : palette.secondary.main

  const initials = (synopUser?.name || '')
    .split(' ')
    .map((word) => word[0])
    .join('')

  return (
    <Grid item sx={{ cursor: 'pointer' }}>
      <Avatar
        onClick={onClick}
        sx={{
          backgroundColor,
          border: 2,
          borderColor: 'secondary.main',
          color,
        }}
      >
        {initials}
      </Avatar>
    </Grid>
  )
}

export default UserAvatar
