import { ControlProps } from '../AlertPropertyInitializedContext'
import { Grid, Slider, Stack, TextField, Typography } from '@mui/material'
import {
  simpleDropdownFactory,
  SimpleDropdownOption,
} from '@synop-react/common'
import { TimeControl } from './TimeControl'

type NumberControlProps = {
  min: number
  max: number
  unit: string
  operators: SimpleDropdownOption<string>[]
  withSlider?: boolean
}

export function numberControlFactory(numberControlProps: NumberControlProps) {
  return function NumberControl(props: ControlProps) {
    const {
      property,
      updateProperty,
      min,
      max,
      unit,
      operators,
      withSlider = false,
    } = { ...numberControlProps, ...props }

    // Number control can only edit a single property
    if ('rules' in property) return null

    const handler = (value: number) => {
      const trueValue = value > max ? max : value < min ? min : value
      updateProperty({ ...property, value: trueValue.toString() })
    }

    const OperatorComponent = simpleDropdownFactory(operators)
    return (
      <Grid container item spacing={2} xs={9}>
        <Grid alignSelf="flex-end" item xs="auto">
          <OperatorComponent
            disabled={operators.length === 1}
            onChange={(newValue) => {
              if (newValue) updateProperty({ ...property, operator: newValue })
            }}
            value={property.operator}
          />
        </Grid>

        <Grid item style={{ flexGrow: 1 }} xs="auto">
          {unit === 's' ? (
            <TimeControl property={property} updateProperty={updateProperty} />
          ) : (
            <Stack alignItems="flex-end" direction="row">
              <TextField
                fullWidth
                InputProps={{ inputProps: { min, max } }}
                label="Value"
                onChange={({ target: { value } }) => handler(Number(value))}
                type="number"
                value={property.value}
                variant="standard"
              />
              <Typography variant="subtitle2">{unit}</Typography>
            </Stack>
          )}
        </Grid>

        {withSlider && (
          <Grid alignSelf="flex-end" item style={{ flexGrow: 1 }} xs="auto">
            <Slider
              onChange={(_, num) => handler(num as number)}
              sx={{ maxWidth: 300 }}
              value={Number(property.value)}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}
