import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

export interface CrateProps {
  bold?: boolean
  children?: ReactNode
  title?: string
  description: string
  Icon?: ReactNode
  width?: string
  height?: string
}

export function Crate({
  bold,
  children = null,
  description,
  Icon = null,
  title,
  width = '100%',
}: CrateProps) {
  const theme = useTheme()
  const fontWeight = bold ? 'bold' : 'regular'
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary['4p'],
        py: 2,
        pl: 4,
        width,
      }}
    >
      <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
        {Icon}
        <Stack spacing={2}>
          {title && <Typography variant="h6">{title}</Typography>}
          {description && (
            <Typography sx={{ fontWeight: fontWeight }}>
              {description}
            </Typography>
          )}
          {children && <div>{children}</div>}
        </Stack>
      </Stack>
    </Box>
  )
}

export default Crate
