import { ColorDot, useFormat } from '@synop-react/common'
import { RootAPI } from '@synop-react/api'
import { Stack, Typography } from '@mui/material'

type NotificationStatusTileProps = {
  notification: RootAPI.NotificationModel
}

export const NotificationStatusTile = ({
  notification,
}: NotificationStatusTileProps) => {
  const { formatDateTime } = useFormat()
  const isInactive = notification.inactive

  if (isInactive) {
    return (
      <Stack>
        <Typography variant="subtitle2">Resolved</Typography>
        <Typography variant="caption">
          {formatDateTime(isInactive).timeOnDate}
        </Typography>
      </Stack>
    )
  } else {
    return (
      <Stack alignItems="center" direction="row" spacing={1}>
        <ColorDot color="primary.main" />
        <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
          Active
        </Typography>
      </Stack>
    )
  }
}
