import { Chart } from '@synop-react/common'
import { Dayjs } from 'dayjs'
import { SocChartData, useChartData, useUserPrefs } from '@synop-react/api'
import { useMemo } from 'react'

export type UseVehicleSocProps = {
  vehicleId: string
  from: Dayjs
  to: Dayjs
}

const useVehicleSoc = ({ from, to, vehicleId }: UseVehicleSocProps) => {
  const { tzDayjs } = useUserPrefs()
  const { data: socData, units: socUnits } = useChartData({
    vehicleId,
    from,
    to,
    attributes: ['stateOfCharge'],
  }) as unknown as SocChartData

  const socOverTime = useMemo(() => {
    return socData.reduce<Chart.AreaDatum[]>((acc, [timestamp, soc]) => {
      const sampleTime = tzDayjs(Number(timestamp)).toISOString()
      const datapoint = { x: sampleTime, y: soc }
      acc.push(datapoint)

      return acc
    }, [])
  }, [socData, tzDayjs])

  return {
    socOverTime,
    socUnit: socUnits[1],
  }
}

export default useVehicleSoc
