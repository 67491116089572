import { RootAPI } from '..'

export const billingCycleTypes = [
  'Current Cycle',
  'Previous Cycle',
  'Custom Cycle',
  'View By Year',
] as const

export type BillingCycleType = (typeof billingCycleTypes)[number]

type RateTargetType =
  RootAPI.OverrideRateStructureModelListRatePeriod['overrideTargetType']
export type EnhancedSiteChargingRate =
  RootAPI.RateStructureModelListRatePeriod & {
    id: string
    siteId: string
    path: string[]
    targetType: RateTargetType
    targetName: string
  }

export type EnhancedChargerChargingRate = {
  id: string
  siteId: string
  path: string[]
  targetType: RateTargetType
  targetName: string
} & RootAPI.OverrideRateStructureModelListRatePeriod
