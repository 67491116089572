import { Button, IconButton, Typography } from '@mui/material'
import { Cask, emDash, Icons, Table } from '@synop-react/common'
import { CreateOrEditLocationOverlay } from '../CreateOrEditLocationOverlay'
import {
  locationOfInterestTypes,
  RootAPI,
  useCurrentOrganization,
  useCurrentUser,
  useOrgCustomers,
} from '@synop-react/api'

const { useGetLocationsOfInterestForOrganizationQuery } = RootAPI.synopRootAPI

export const LocationSettings = () => {
  const { currentOrg } = useCurrentOrganization()
  const { isAdmin } = useCurrentUser()

  const { data: locations = [] } =
    useGetLocationsOfInterestForOrganizationQuery(
      {
        organizationId: currentOrg?.id,
      },
      {
        skip: !currentOrg?.id,
      }
    )

  const { customers: organizations } = useOrgCustomers()

  // Add the parent org to the list of possible orgs
  organizations[currentOrg?.id] = currentOrg

  const locationColumns: Table.ColumnSpec<RootAPI.LocationOfInterestResponseModel> =
    [
      {
        field: 'loiNm',
        headerName: 'Name',
        groupable: false,
        flex: 1,
      },
      {
        field: 'organizationId',
        headerName: 'Organization',
        groupable: false,
        flex: 1,
        valueGetter: ({ value = '' }) =>
          organizations[value]?.organizationNm ?? emDash,
      },
      {
        field: 'address',
        headerName: 'Address',
        groupable: false,
        flex: 2,
      },
      {
        field: 'loiType',
        headerName: 'Type',
        groupable: false,
        flex: 0.5,
        type: 'singleSelect',
        valueOptions: [...locationOfInterestTypes],
      },
    ]

  if (isAdmin) {
    locationColumns.push({
      field: 'edit',
      headerName: '',
      groupable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      disableReorder: true,
      width: 100,
      renderCell: ({ row: { id } }) => (
        <CreateOrEditLocationOverlay
          locationId={id}
          Trigger={
            <IconButton>
              <Icons.Edit3 />
            </IconButton>
          }
        />
      ),
    })
  }

  return (
    <Cask
      Actions={
        isAdmin
          ? [
              <CreateOrEditLocationOverlay
                key="addLocationButton"
                Trigger={
                  <Button startIcon={<Icons.PlusCircle />} variant="outlined">
                    New Location of Interest
                  </Button>
                }
              />,
            ]
          : []
      }
      title="Locations of Interest"
    >
      <Typography variant="body1">
        {isAdmin
          ? 'Add or adjust the locations of interest for your organization'
          : 'View the locations of interest for your organization'}
        .
      </Typography>
      <Table.ClientSide
        columns={locationColumns}
        initialSortColumn="loiNm"
        initialSortOrder="asc"
        tableData={locations}
      />
    </Cask>
  )
}
