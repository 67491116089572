import {
  CurrentVehicleLocations,
  getCurrentHeading,
  useGetLoiName,
} from './VehicleCurrentLocations'
import {
  EntityBrowser,
  EntityListItemDetail,
  EntityListItemRenderer,
  EntityMapConfig,
  EntityTableRowRenderer,
  FilterConfig,
  formatDistanceSourceAttribute,
  formatSocFromSourceAttributes,
  humanizeAccurateDuration,
  LoadingMessage,
  MultiOrgSelector,
  MultiOrgSelectorsProvider,
  MultiSiteSelector,
  SelectedPin,
  Table,
  useFormat,
  useMultiOrgSelector,
  useMultiSiteSelector,
} from '@synop-react/common'
import {
  getVehicleLocationCollection,
  VehicleMapSocLayers,
} from '../Map/SocLayers'
import { map } from 'lodash'
import {
  parseToEntities,
  PercentSourceAttribute,
  useOrgVehicles,
  VehicleModel,
} from '@synop-react/api'
import { useMemo } from 'react'
import { useSocColor } from '../utils/hooks/useSocColor'
import { useTheme } from '@mui/material'
import { useVehicleModelFields } from '../Reports/fields'
import { VehicleLocationBadgeMarkers } from '../Map/LocationBadgeMarkers'
import { VehicleMapLegend } from '../Map/Legend/VehicleMapLegend'
import { VehicleStatusIcon } from '../VehicleStatusIcon'
import VehicleSearchInput from './VehicleSearchInput'
import VehicleSortSelect from './VehicleSortSelect'

export function VehicleBrowser() {
  const { palette } = useTheme()
  const { selected: selectedSites } = useMultiSiteSelector()
  const { selected: selectedOrgs } = useMultiOrgSelector()

  const { orgVehicles: allOrgVehicles, isLoading } = useOrgVehicles({
    poll: true,
  })
  const getLoiName = useGetLoiName()

  const filteredVehicles = useMemo(() => {
    const hasSelectedSites = !!selectedSites?.length
    const hasSelectedOrgs = !!selectedOrgs?.length
    const selectedSiteIds = map(selectedSites, 'id')
    const selectedOrgIds = map(selectedOrgs, 'id')

    const filtered = Object.values(allOrgVehicles).filter(
      ({ organizationId, homeSiteId } = {} as VehicleModel) => {
        let matchSite = !hasSelectedSites
        let matchOrg = !hasSelectedOrgs

        if (hasSelectedSites && homeSiteId)
          matchSite = selectedSiteIds.includes(homeSiteId)
        if (hasSelectedOrgs && organizationId)
          matchOrg = selectedOrgIds.includes(organizationId)

        return matchSite && matchOrg
      }
    )

    return parseToEntities(filtered, 'id').entities
  }, [allOrgVehicles, selectedOrgs, selectedSites])

  const { formatDateTime } = useFormat()
  const {
    vehicleName,
    vehicleStatus,
    stateOfCharge,
    vin,
    modelYear,
    make,
    model,
    remainingRange,
    telematicsTimestamp,
  } = useVehicleModelFields()

  const renderListItem: EntityListItemRenderer<VehicleModel> = (vehicle) => {
    const {
      vehicleNm = 'Unnamed Vehicle',
      vehicleStatus,
      remainingRange,
      id = '',
      stateOfCharge,
      telematicsTimestamp,
      remainingChargeTime,
    } = vehicle

    const isCharging = vehicleStatus?.value === 'Charging'
    const hasChargeTime =
      isCharging && remainingChargeTime && remainingChargeTime.value > 0

    return {
      id,
      titleText: vehicleNm,
      titleIcon: (
        <VehicleStatusIcon
          color={palette.secondary.main}
          size={18}
          status={vehicleStatus}
        />
      ),
      descriptionText: formatDateTime(telematicsTimestamp).timeDotDate,
      details: [
        {
          value: <VehicleSocListItemDetail soc={stateOfCharge} />,
        },
        {
          value: <VehicleRangeListItemDetail range={remainingRange} />,
        },
        {
          value: hasChargeTime ? (
            <VehicleChargeTimeListItemDetail
              remainingTime={remainingChargeTime}
            />
          ) : null,
        },
      ],
      detailsUrl: `/vehicles/${id}`,
    }
  }

  const tableColumns: Table.ColumnSpec<VehicleModel> = [
    vehicleName.column,
    vehicleStatus.column,
    stateOfCharge.column,
    {
      field: 'currentLocation',
      headerName: 'Current Location',
      flex: 4,
      minWidth: 120,
      isDefaultVisible: false,
      valueGetter: ({ row: { currentLocation = {} } }) => {
        const { cardinalDirection, street, locationOfInterestModels } =
          currentLocation

        const streetHeading = getCurrentHeading(street, cardinalDirection)

        const locations = locationOfInterestModels ?? []
        const locationNames = locations.length
          ? locations
              .map((location) => getLoiName(location).toLowerCase())
              .sort()
              .join(' ')
          : ''

        return locationNames || streetHeading
      },
      renderCell: ({ row }) => (
        <CurrentVehicleLocations currentLocation={row.currentLocation} />
      ),
      sortable: true,
    },
    vin.column,
    modelYear.column,
    make.column,
    model.column,
    /*
    TODO: Commented out until Distance Remaining is working
    distanceUntilHome.column,
     */
    remainingRange.column,
    telematicsTimestamp.column,
  ]

  const renderTableRow: EntityTableRowRenderer<VehicleModel> = (vehicle) => {
    return vehicle
  }

  const toolbarControls = [
    { key: 'search', Component: VehicleSearchInput },
    { key: 'filterOrg', Component: MultiOrgSelector },
    { key: 'filterHomesite', Component: MultiSiteSelector },
    { key: 'sort', Component: VehicleSortSelect },
  ]

  const vehicleSocFilters: FilterConfig = {
    highChargeFilter: {
      filter: [
        'all',
        ['==', ['typeof', ['get', 'soc']], 'number'],
        ['>=', ['get', 'soc'], 81],
      ],
      color: palette.secondary.main,
    },
    midChargeFilter: {
      color: palette.warning.main,
      filter: [
        'all',
        ['==', ['typeof', ['get', 'soc']], 'number'],
        ['>=', ['get', 'soc'], 41],
        ['<', ['get', 'soc'], 81],
      ],
    },
    lowChargeFilter: {
      color: palette.error.main,
      filter: [
        'any',
        ['!=', ['typeof', ['get', 'soc']], 'number'],
        ['all', ['>=', ['get', 'soc'], 0], ['<', ['get', 'soc'], 41]],
      ],
    },
  }

  const vehicleMapConfig: EntityMapConfig<VehicleModel> = {
    layerFilters: vehicleSocFilters,
    layerIds: ['vehicles', 'vehicleCluster-circle'],
    FullscreenMapLayers: (
      <VehicleLocationBadgeMarkers vehicles={filteredVehicles} />
    ),
    OverviewMapLayers: (
      <>
        <SelectedPin />
        <VehicleMapSocLayers
          layerFilters={vehicleSocFilters}
          mapId="entityMap"
          sourceId="entityMapSource"
        />
      </>
    ),
    getCoords: getVehicleLocationCollection,
    MapLegend: <VehicleMapLegend />,
  }

  if (isLoading) {
    return <LoadingMessage />
  } else {
    return (
      <EntityBrowser
        entities={filteredVehicles}
        mapConfig={vehicleMapConfig}
        renderListItem={renderListItem}
        renderTableRow={renderTableRow}
        tableColumns={tableColumns}
        toolbarControls={toolbarControls}
      />
    )
  }
}

type VehicleSocListItemDetailProps = {
  soc: PercentSourceAttribute
}

const VehicleSocListItemDetail = ({ soc }: VehicleSocListItemDetailProps) => {
  const color = useSocColor(soc?.value)
  const formattedSoc = `${formatSocFromSourceAttributes(soc, {
    sigFigs: 1,
  })} SOC`

  return (
    <EntityListItemDetail
      color={color}
      fontWeight="bold"
      value={formattedSoc}
    />
  )
}

type VehicleRangeListItemDetailProps = {
  range: VehicleModel['remainingRange']
}

const VehicleRangeListItemDetail = ({
  range,
}: VehicleRangeListItemDetailProps) => {
  const formattedRange = `${formatDistanceSourceAttribute(range, {
    sigFigs: 0,
  })} RANGE`

  return (
    <EntityListItemDetail textTransform="uppercase" value={formattedRange} />
  )
}

type VehicleChargeTimeListItemDetailProps = {
  remainingTime: VehicleModel['remainingChargeTime']
}

const VehicleChargeTimeListItemDetail = ({
  remainingTime,
}: VehicleChargeTimeListItemDetailProps) => {
  if (!remainingTime || remainingTime?.value === 0) {
    return null
  }

  const formattedTime = humanizeAccurateDuration(
    remainingTime.value,
    remainingTime.units
  )
  return (
    <EntityListItemDetail textTransform="uppercase" value={formattedTime} />
  )
}
const VehicleBrowserWrapper = () => {
  return (
    <MultiOrgSelectorsProvider>
      <VehicleBrowser />
    </MultiOrgSelectorsProvider>
  )
}

export default VehicleBrowserWrapper
