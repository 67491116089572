import {
  collectionToCSV,
  getFieldColumns,
  ReportField,
  useReport,
} from '@synop-react/common'
import {
  EfficiencySourceAttribute,
  RootAPI,
  SourceAttribute,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { Entity, Narrow } from '@synop-react/types'
import {
  RawVehicleActivity,
  UseVehicleActivityReportProps,
  VehicleActivity,
} from './useVehicleActivityReport'
import { useMemo } from 'react'
import { useVehicleActivityFields } from './fields'

const { useGetVehicleReportQuery } = RootAPI.synopRootAPI

type FleetActivityEntity = Entity<FleetActivity>
type FleetActivity = Omit<RawVehicleActivity, 'vehicleId' | 'mpge'> &
  Pick<VehicleActivity, 'fleetId' | 'fleetNm'>

export const useFleetActivityReport = ({
  skip,
  queryArgs,
}: UseVehicleActivityReportProps) => {
  const { tzDayjs } = useUserPrefs()
  const { orgVehicles } = useOrgVehicles()
  const { report = [], ...reportStatus } = useReport<
    RootAPI.GetVehicleReportApiArg,
    RootAPI.ReportGenerationStatus,
    RawVehicleActivity
  >({
    useQuery: useGetVehicleReportQuery,
    queryArgs,
    reportType: 'VEHICLE_ACTIVITY',
    skip,
  })

  const reportData = useMemo(() => {
    return Object.values(
      report.reduce<Record<string, FleetActivityEntity>>((acc, activity) => {
        const { fleetId, fleetNm } = orgVehicles[activity.vehicleId] ?? {}
        if (!fleetId) return acc

        const fleetActivity = acc[fleetId]
        if (fleetActivity) {
          acc[fleetId] = combineFields(fleetActivity, activity)
        } else {
          acc[fleetId] = {
            id: fleetId,
            fleetId,
            fleetNm,
            ...calcEfficiencyForActivity(activity),
          }
        }

        return acc
      }, {})
    )
  }, [orgVehicles, report])

  const { totalTrips, distanceDriven, energyUsed, energyAdded } =
    useVehicleActivityFields<FleetActivity>(reportData[0])

  const { csvColumns, tableColumns } = useMemo(() => {
    const fleetName: ReportField<FleetActivity> = {
      csv: ['fleetNm', 'fleet_name'],
      column: {
        field: 'fleetNm',
        headerName: 'Fleet',
        flex: 1,
        headerAlign: 'center',
        sortable: true,
      },
    }

    return getFieldColumns(
      fleetName,
      totalTrips,
      distanceDriven,
      energyUsed,
      energyAdded
    )
  }, [distanceDriven, energyAdded, energyUsed, totalTrips])

  const fleetActivityCSVBlob = useMemo(() => {
    const eventCSV = collectionToCSV(csvColumns)(reportData)
    return new Blob([eventCSV], { type: 'text/csv' })
  }, [csvColumns, reportData])

  const { from, to } = queryArgs

  const activityCSVOption = {
    extension: '.csv' as const,
    blob: fleetActivityCSVBlob,
    filename: `FLEET_ACTIVITY_${tzDayjs(from).format()}_${tzDayjs(
      to
    ).format()}`,
  }

  return {
    data: reportData,
    columns: tableColumns,
    ...reportStatus,
    csvOption: activityCSVOption,
  }
}

const SA_FIELDS = ['units', 'lastUpdated', 'source', 'value']
const isSourceAttribute = (value: unknown): value is SourceAttribute => {
  if (!value || typeof value !== 'object') return false
  return SA_FIELDS.every((field) => field in value)
}

const calcEfficiencyForActivity = (
  activity: Omit<FleetActivity, 'efficiency'>
): FleetActivity => {
  const { distanceDriven = { value: 0 }, energyUsed = { value: 0 } } = activity

  const efficiency =
    distanceDriven.value && energyUsed.value
      ? distanceDriven.value / energyUsed.value
      : 0
  return {
    ...activity,
    efficiency: {
      value: efficiency,
      units: `${activity.distanceDriven?.units}/${activity.energyUsed?.units}`,
    } as EfficiencySourceAttribute,
  }
}

const combineFields = (a: FleetActivityEntity, b: FleetActivity) => {
  return {
    ...a,
    energyUsed: combineSourceAttribute('energyUsed'),
    energyAdded: combineSourceAttribute('energyAdded'),
    carbonEmissionsSaved: combineSourceAttribute('carbonEmissionsSaved'),
    distanceDriven: combineSourceAttribute('distanceDriven'),
    totalTrips: combineNumeric('totalTrips'),
  } as FleetActivityEntity

  function combineNumeric(field: Narrow.MaybeNumericKeyOf<FleetActivity>) {
    const valueA = a[field]
    const valueB = b[field]
    return typeof valueA === 'number' && typeof valueB === 'number'
      ? valueA + valueB
      : valueA
  }

  function combineSourceAttribute(
    field: Narrow.KeyOfType<FleetActivity, SourceAttribute>
  ): SourceAttribute {
    const valueA = a[field]
    const valueB = b[field]

    return isSourceAttribute(valueA) && isSourceAttribute(valueB)
      ? // TODO: check units, take most recent lastUpdated, do something with 'source' @wslater
        { ...valueB, value: valueA.value + valueB.value }
      : valueA
  }
}
