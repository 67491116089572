import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { LatLong, PolygonFeature } from '@synop-react/types'

type GeofenceState = {
  isEditingGeofence: boolean
  setIsEditingGeofence: (isEditing: boolean) => void
  geofence?: PolygonFeature
  setGeofence: (geofence?: PolygonFeature) => void
  prevGeofence?: PolygonFeature
  setPrevGeofence: (geofence?: PolygonFeature) => void
  /*
   * Longitude / Latitude
   */
  locationMarker?: LatLong
  setLocationMarker: (marker?: LatLong) => void
}

const defaultGeofenceState: GeofenceState = {
  isEditingGeofence: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsEditingGeofence: (isEditing) => ({}),
  geofence: {} as PolygonFeature,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setGeofence: (geofence) => ({}),
  prevGeofence: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPrevGeofence: (geofence) => ({}),
  locationMarker: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLocationMarker: (marker) => ({}),
}

const GeofenceContext = createContext(defaultGeofenceState)
const GeofenceContextProvider = GeofenceContext['Provider']

type GeofenceProviderProps = PropsWithChildren<{}>

export const GeofenceProvider = ({ children }: GeofenceProviderProps) => {
  const [isEditingGeofence, setIsEditingGeofence] = useState(false)
  const [locationMarker, setLocationMarker] = useState<LatLong>()
  const [geofence, setGeofence] = useState<PolygonFeature>()
  const [prevGeofence, setPrevGeofence] = useState<PolygonFeature>()

  const geofenceState = {
    isEditingGeofence,
    setIsEditingGeofence,
    geofence,
    setGeofence,
    prevGeofence,
    setPrevGeofence,
    locationMarker,
    setLocationMarker,
  }

  return (
    <GeofenceContextProvider value={geofenceState}>
      {children}
    </GeofenceContextProvider>
  )
}

export const useEditGeofenceMap = () => useContext(GeofenceContext)
