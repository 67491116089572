import { Copy } from '../Icons'
import { copyToClipboard } from '../utils'
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tooltip,
} from '@mui/material'
import { useState } from 'react'

interface CopyFieldProps {
  label: string
  value: string
  key: string
}

export function CopyField({ label, value, key }: CopyFieldProps) {
  const [tooltipText, setTooltipText] = useState('')
  return (
    <>
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      <Tooltip placement="top" title={tooltipText}>
        <Input
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="Copy to clipboard">
                <Copy />
              </IconButton>
            </InputAdornment>
          }
          id={key}
          onClick={() => {
            copyToClipboard(`${value}`)
            setTooltipText('Copied!')
            setTimeout(() => {
              setTooltipText('Copy')
            }, 3000)
          }}
          readOnly={true}
          sx={{ pointerEvents: 'click', width: '100%' }}
          value={value}
        />
      </Tooltip>
    </>
  )
}
