import { DateSelector } from '../DateSelector'
import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useUserPrefs } from '@synop-react/api'
import useTimeRange from './useTimeRange'

type CustomDateRangeProps = {
  disableFromFuture?: boolean
  disableFromPast?: boolean
  disableToFuture?: boolean
  disableToPast?: boolean
  maxRange?: number
}

export const CustomDateRange = ({
  disableFromFuture = true,
  disableFromPast = false,
  disableToFuture = false,
  disableToPast = true,
  maxRange,
}: CustomDateRangeProps) => {
  const { selectedTimeResolution, setCustomRange, options } = useTimeRange()
  const { tzDayjs } = useUserPrefs()
  const [fromDate, setFromDate] = useState(tzDayjs().startOf('day'))
  const [toDate, setToDate] = useState(tzDayjs())

  useEffect(() => {
    setCustomRange([fromDate, toDate])
    // Ignore setCustomRange change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate])

  if (selectedTimeResolution !== 'CUSTOM') return null

  const handleFromChange = (maybeDate: Date | null) => {
    const date = tzDayjs(maybeDate)
    if (date.isValid()) {
      const from = date.startOf('day')
      setFromDate(from)
    }
  }

  const handleToChange = (maybeDate: Date | null) => {
    const date = tzDayjs(maybeDate)
    if (date.isValid()) {
      const to =
        options.useEndOfCurrentDay || !date.isSame(tzDayjs(), 'day')
          ? date.endOf('day')
          : date
      setToDate(to)
    }
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <DateSelector
        disableFuture={disableFromFuture}
        disablePast={disableFromPast}
        handleSelectedDate={handleFromChange}
        variant="outlined"
      />
      <Typography>to</Typography>
      <DateSelector
        disableFuture={disableToFuture}
        disablePast={disableToPast}
        handleSelectedDate={handleToChange}
        maxDate={maxRange ? fromDate.add(maxRange, 'day').toDate() : undefined}
        minDate={fromDate.toDate()}
        variant="outlined"
      />
    </Stack>
  )
}
