import { emDash } from './constants'
import { formatUnits, formatUnitsAbbr, UnitsFormat } from './units'

export type SpeedUnit = 'kph' | 'mph'

const speedFormats: UnitsFormat<SpeedUnit> = {
  kph: {
    long: 'Kilometers per hour',
    short: 'kph',
  },
  mph: {
    long: 'Miles per hour',
    short: 'mph',
  },
}

export const formatSpeedUnits = formatUnits(speedFormats)
export const formatSpeedUnitsAbbr = formatUnitsAbbr(speedFormats)

type SpeedOptions = {
  longForm: boolean
  sigFigs: number
}
const defaultSpeedOptions: SpeedOptions = {
  longForm: false,
  sigFigs: 0,
}

export const formatSpeed = (
  speed?: number | string,
  speedUnits: SpeedUnit = 'kph',
  options: Partial<SpeedOptions> = defaultSpeedOptions
) => {
  const { longForm, sigFigs } = { ...defaultSpeedOptions, ...options }
  speedUnits = speedUnits.toLowerCase() as SpeedUnit

  const units = longForm
    ? formatSpeedUnits(speedUnits)
    : formatSpeedUnitsAbbr(speedUnits)
  if (typeof speed !== 'string' && typeof speed !== 'number') return emDash

  const speedValue =
    sigFigs > 0
      ? Number(parseFloat(`${speed}`).toFixed(sigFigs))
      : parseInt(`${speed}`)

  return `${speedValue} ${units}`
}
