import { Link as MuiLink, styled, Typography } from '@mui/material'
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom'

type NavLinkProps = {
  to: string
  label: string
}

const NavLink = ({ to, label }: NavLinkProps) => {
  const resolved = useResolvedPath(to)
  const pathMatch = useMatch({ path: resolved.pathname, end: false })
  const isActive = !!pathMatch

  return (
    <MuiLink
      component={RouterLink}
      sx={{ textDecoration: 'none' }}
      to={to}
      variant="body1"
    >
      <NavLabel isActive={isActive}>{label}</NavLabel>
    </MuiLink>
  )
}

export default NavLink

const NavLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => {
  const hoverStyle = isActive
    ? {}
    : {
        '&:hover': {
          textDecoration: 'underline',
          textDecorationColor: theme.palette.secondary.main,
        },
      }
  return {
    color: isActive
      ? theme.palette.secondary.main
      : theme.palette.text.disabled,
    fontWeight: isActive ? 'bold' : 'normal',
    textDecoration: isActive ? 'underline' : 'none',
    textUnderlineOffset: '8px',
    textDecorationThickness: isActive ? '2px' : '',
    ...hoverStyle,
  }
})
