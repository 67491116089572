import { Navigate, Route, Routes } from 'react-router-dom'

import { ChargersSettings, DepotsSettings } from '@synop-react/depot'
import {
  DataSourceSettings,
  VehicleBrowser,
  VehicleSettings,
} from '@synop-react/vehicle'

import {
  AlertsSettings,
  AlertWizardPage,
  EditNotificationsPage,
  NotificationsDashboard,
} from '@synop-react/notification'
import {
  APIKeySettings,
  BillingSettings,
  FleetsSettings,
  GeneralSettings,
  OrganizationsSettings,
  OrgMgmtSettings,
  ThemeSettings,
  UsersSettings,
} from '@synop-react/user'
import {
  ChargerDashboardPage,
  ChargerDetailsPage,
  ChargersOverviewPage,
} from '../pages/Chargers'
import {
  DashboardHeader,
  RouteDirectory,
  useRouting,
} from '@synop-react/common'
import { DepotBrowserPage, DepotDetailsPage } from '../pages/Depots'
import {
  HomeChargingSettings,
  WorkplaceChargingSettings,
} from '@synop-react/billing'
import { LocationSettings } from '@synop-react/location'
import { ReportsPage } from '../pages/Reports'
import { SettingsPage } from '../pages/Settings'
import { useAuthListener, useCurrentUser } from '@synop-react/api'
import AuthenticatedLayout from './layouts/AuthenticatedLayout'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import TripDetailsPage from '../pages/Trips/TripDetailsPage'
import VehicleDashboardPage from '../pages/Vehicles/VehicleDashboardPage'
import VehicleDetailsPage from '../pages/Vehicles/VehicleDetailsPage'

// =============================================================================
// Route Definitions
// =============================================================================

export default function AppRoutes() {
  const { routes } = useRouting()
  const { isAdmin, isUserLoading } = useCurrentUser()
  useAuthListener()

  return (
    <Routes>
      <Route element={<AuthenticatedRoutes />}>
        <Route element={<AuthenticatedLayout />}>
          {getAlertsRoutes(routes)}
          {getDashboardRoutes(routes)}
          {getVehicleRoutes(routes)}
          {getSiteRoutes(routes)}
          {getChargerRoutes(routes)}
          {getNotificationsRoutes(routes)}
          {getSettingsRoutes(
            routes,
            isAdmin ?? isUserLoading // If a user is loading we shouldn't redirect yet
          )}
          <Route element={<ReportsPage />} path={routes.reports} />
        </Route>
      </Route>

      {/* Only matches when no other routes match */}
      <Route element={<Navigate replace to={routes.root} />} path="*" />
    </Routes>
  )
}

function getAlertsRoutes(routes: RouteDirectory) {
  return (
    <>
      <Route path={routes.alerts.root}>
        <Route element={<AlertsSettings />} index />
        <Route element={<AlertWizardPage />} path={routes.alerts.new} />
        <Route
          element={<AlertWizardPage />}
          path={routes.alerts.edit(':alertId')}
        />
      </Route>
    </>
  )
}

function getDashboardRoutes(routes: RouteDirectory) {
  return (
    <>
      <Route element={<Navigate to={routes.dashboard.root} />} index />
      <Route path={routes.dashboard.root}>
        <Route element={<DashboardHeader />} path={routes.dashboard.root} />
        <Route path={routes.dashboard.chargers}>
          <Route element={<ChargerDashboardPage />} index />
        </Route>
        <Route path={routes.dashboard.vehicles}>
          <Route element={<VehicleDashboardPage />} index />
        </Route>
      </Route>
    </>
  )
}

function getVehicleRoutes(routes: RouteDirectory) {
  return (
    <Route path={routes.vehicles.root}>
      <Route element={<VehicleBrowser />} index />
      <Route
        element={<TripDetailsPage />}
        path={routes.vehicles.trips.details(':assetId', ':tripId')}
      />
      <Route
        element={<VehicleDetailsPage />}
        path={routes.vehicles.details(':assetId')}
      />
    </Route>
  )
}

function getSiteRoutes(routes: RouteDirectory) {
  return (
    <Route path={routes.sites}>
      <Route element={<DepotDetailsPage />} path=":depotId" />
      <Route element={<DepotBrowserPage />} index />
    </Route>
  )
}

function getChargerRoutes(routes: RouteDirectory) {
  return (
    <Route path={routes.chargers.root}>
      <Route element={<ChargerDetailsPage />} path=":chargerId" />
      <Route element={<ChargersOverviewPage />} index />
    </Route>
  )
}

function getNotificationsRoutes(routes: RouteDirectory) {
  return (
    <Route path={routes.notifications}>
      <Route element={<EditNotificationsPage />} path="edit" />
      <Route element={<NotificationsDashboard />} index />
    </Route>
  )
}

function getSettingsRoutes(routeDirectory: RouteDirectory, isAdmin?: boolean) {
  const routes = routeDirectory.settings
  return (
    <Route element={<SettingsPage />} path={routes.root}>
      <Route element={<GeneralSettings />} index />
      <Route element={<APIKeySettings />} path={routes.apiKeys} />
      {<Route element={<AlertsSettings />} path={routes.alerts} />}
      <Route element={<BillingSettings />} path={routes.billing} />
      <Route element={<HomeChargingSettings />} path={routes.homeCharging} />
      <Route
        element={<WorkplaceChargingSettings />}
        path={routes.workplaceCharging}
      />
      <Route element={<DepotsSettings />} path={routes.sites} />
      <Route element={<ChargersSettings />} path={routes.chargers} />
      <Route element={<UsersSettings />} path={routes.users} />
      {isAdmin && <Route element={<UsersSettings />} path={routes.users} />}
      <Route element={<ThemeSettings />} path={routes.themes} />
      <Route element={<LocationSettings />} path={routes.locations} />

      {isAdmin && (
        <Route element={<OrgMgmtSettings />} path={routes.orgManagement} />
      )}
      {isAdmin && (
        <Route element={<OrganizationsSettings />} path={routes.orgs} />
      )}
      <Route element={<FleetsSettings />} path={routes.fleets} />
      <Route element={<VehicleSettings />} path={routes.vehicles} />
      <Route element={<DataSourceSettings />} path={routes.dataSources} />
    </Route>
  )
}
