import {
  Button,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import {
  ChargerAutocomplete,
  createOverlay,
  DepotAutocomplete,
  FormField,
  Overlay,
  useOverlayContext,
} from '@synop-react/common'
import { CustomRatePeriodField } from '../rateTypes'
import {
  dayJsToLocalTime,
  isAllDayRate,
  parseRatePeriodToDayjs,
  rateFormSchema,
} from '../rateUtils'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import {
  Depot,
  EnhancedChargerChargingRate,
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
  useOrgChargers,
  useOrgSites,
} from '@synop-react/api'
import {
  FieldError,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { RateTimezoneNotice } from '../RateTimezoneNotice'
import { SetRateInput } from '../SetRateInput'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'

type ChargerChargingRateForm = {
  allDayRate: string
  depot: Depot
  charger: RootAPI.ChargerModel
  rateName: string | null
  customRatePeriods: CustomRatePeriodField[]
}

type EditChargerChargingRateOverlayProps = {
  chargingRate: EnhancedChargerChargingRate
}

export const EditChargerChargingRateOverlay =
  createOverlay<EditChargerChargingRateOverlayProps>(({ chargingRate }) => {
    const currentOrgId = useCurrentOrgId()
    const { synopUser } = useCurrentUser()

    const { closeOverlay } = useOverlayContext()

    const [rateTimePeriod, setRateTimePeriod] = useState(
      isAllDayRate(chargingRate.rateStructure) ? 'allDay' : 'custom'
    )

    const { orgSites } = useOrgSites()
    const { orgChargers } = useOrgChargers()

    const { siteId, overrideTargetId } = chargingRate

    const formMethods = useForm<ChargerChargingRateForm>({
      defaultValues: {
        //TODO: revisit this when the API returns more explicit all day rates
        allDayRate:
          isAllDayRate(chargingRate.rateStructure) &&
          chargingRate.rateStructure[0]?.price
            ? `${chargingRate.rateStructure[0].price}`
            : '0.00',

        depot: orgSites[chargingRate.siteId],
        charger: orgChargers[overrideTargetId],
        rateName: chargingRate.rateNm,
        customRatePeriods: parseRatePeriodToDayjs(chargingRate.rateStructure),
      },
      resolver: yupResolver(rateFormSchema),
    })

    const {
      control,
      handleSubmit,
      formState: { errors, touchedFields },
    } = formMethods

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'customRatePeriods',
    })

    const [updateChargerRate] =
      RootAPI.useUpdateWorkplaceChargingRatesMutation()
    const onSubmit = handleSubmit(async (formData) => {
      const { rateName, allDayRate, customRatePeriods } = formData

      const rateStructure =
        rateTimePeriod === 'allDay'
          ? [
              {
                from: dayJsToLocalTime(dayjs().startOf('day')),
                to: dayJsToLocalTime(dayjs().endOf('day')),
                price: parseFloat(allDayRate),
              },
            ]
          : customRatePeriods.map(({ from, to, price }) => ({
              from: dayJsToLocalTime(from),
              to: dayJsToLocalTime(to),
              price: parseFloat(price),
            }))

      try {
        await updateChargerRate({
          rateMappingId: chargingRate.rateMappingId,
          updateWorkplaceChargingRate: {
            rateMappingId: chargingRate.rateMappingId,
            organizationId: synopUser?.organizationId as string,
            rateStatus: chargingRate.rateStatus,
            defaultRateStructure: {
              rateNm: rateName as string,
              rateType: 'TIME_OF_USE',
              rateStructure,
              rateStatus: 'ACTIVE',
              rateMappingId: chargingRate.rateMappingId,
            },
          },
        }).unwrap()

        closeOverlay()
      } catch (error) {
        console.log('Error: ', error)
        //TODO: Set Error State to display to user
      }
    })

    return (
      <Overlay>
        <Overlay.Header title="Edit Charger Charging Rate" />

        <Overlay.Content>
          <Typography>
            You are editing a charging rate at the charger level, and it will go
            into effect during the next charging session for that timeframe.
          </Typography>

          <FormProvider {...formMethods}>
            <form>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <DepotAutocomplete.Select
                    control={control}
                    disabled
                    error={errors['depot'] as FieldError}
                    fleetId={currentOrgId}
                    id="depot"
                    label="Site"
                    touchedField={Boolean(touchedFields['depot'])}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.rateName}
                    fullWidth
                    id="rateName"
                    label="Rate Name"
                    touched={Boolean(touchedFields.rateName)}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <ChargerAutocomplete.Select
                    control={control}
                    depotId={siteId}
                    disabled
                    error={errors.charger as FieldError}
                    id="charger"
                    orgId={currentOrgId}
                    touchedField={Boolean(touchedFields.charger)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack alignItems="center" direction="row">
                    <Radio
                      checked={rateTimePeriod === 'allDay'}
                      inputProps={{ 'aria-label': 'all day rate range' }}
                      name="rateTimeRange"
                      onChange={(e) => setRateTimePeriod(e.target.value)}
                      value="allDay"
                    />

                    <Typography sx={{ mr: '22px' }}>All Day: </Typography>

                    <SetRateInput id="allDayRate" />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row">
                    <Radio
                      checked={rateTimePeriod === 'custom'}
                      inputProps={{ 'aria-label': 'custom rate range' }}
                      name="rateTimeRange"
                      onChange={(e) => setRateTimePeriod(e.target.value)}
                      sx={{ height: 48 }}
                      value="custom"
                    />

                    <Typography sx={{ mr: '16px', mt: '12px' }}>
                      Custom:
                    </Typography>

                    <Grid container rowGap={2}>
                      {fields.map((item, index) => (
                        <Grid key={item.id} item xs={12}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                          >
                            <FormField.TimePicker
                              id={`customRatePeriods[${index}].from`}
                              label="Start Time"
                              variant="standard"
                            />

                            <FormField.TimePicker
                              id={`customRatePeriods[${index}].to`}
                              label="End Time"
                              variant="standard"
                            />

                            <SetRateInput
                              id={`customRatePeriods[${index}].price`}
                            />

                            {fields.length > 1 && (
                              <IconButton
                                disabled={fields.length <= 1}
                                onClick={() => remove(index)}
                                size="medium"
                              >
                                <DeleteOutlineOutlined
                                  sx={{ color: 'action.active' }}
                                />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    disabled={rateTimePeriod === 'allDay'}
                    onClick={() => {
                      const lastField = fields.at(-1)
                      const nextStart = dayjs(lastField?.to).add(1, 'minute')

                      append({
                        from: nextStart,
                        to: nextStart.add(1, 'hour'),
                        price: '0.00',
                      })
                    }}
                  >
                    + ADD CUSTOM RATE
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <RateTimezoneNotice
                    description="All edits will go into effect during the next charging session."
                    title="This edit will be at the charger level, and will be set for the charger's timezone."
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Overlay.Content>

        <Overlay.Actions>
          <Button onClick={onSubmit} variant="contained">
            Save Changes
          </Button>
          <Button onClick={closeOverlay}>Cancel</Button>
        </Overlay.Actions>
      </Overlay>
    )
  })
