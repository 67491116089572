import {
  DataGridPremiumProps,
  GridColDef,
  GridRowParams,
  GridSortDirection,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'

export type ColumnSpec<T extends GridValidRowModel> = Array<GridColumn<T>>
export type GridColumn<T extends GridValidRowModel> = GridColDef<T> & {
  isDefaultVisible?: boolean
  tooltip?: string
}

export type TableProps<T extends GridValidRowModel> = {
  columns: ColumnSpec<T>
  initialSortColumn?: keyof T
  initialSortOrder?: GridSortDirection
  height?: string
  noRowsMessage?: string
  onRowClick?: (params: GridRowParams) => void
  tableData: T[]
  width?: string
} & Omit<DataGridPremiumProps<T>, 'rows' | 'treeData'>

export function dateValueGetter({ value }: { value: string | number | Date }) {
  return value ? new Date(value) : undefined
}
