import { emDash } from './constants'
import { EmissionsUnit } from '@synop-react/api'
import { formatUnits, formatUnitsAbbr, UnitsFormat } from './units'

const emissionsFormats: UnitsFormat<EmissionsUnit> = {
  'kg of CO2': {
    long: 'Kilograms of CO2',
    short: 'kg of CO2',
  },
  'lb of CO2': {
    long: 'Pounds of CO2',
    short: 'lb of CO2',
  },
}

export const formatEmissionsUnits = formatUnits(emissionsFormats)
export const formatEmissionsUnitsAbbr = formatUnitsAbbr(emissionsFormats)

type EmissionsOptions = {
  longForm: boolean
  sigFigs: number
}
const defaultEmissionsOptions: EmissionsOptions = {
  longForm: false,
  sigFigs: 0,
}
const DEFAULT_EMISSIONS: EmissionsUnit = 'kg of CO2'
export const formatCO2 = (
  emissions = 0,
  maybeEmissionsUnits: EmissionsUnit | null = DEFAULT_EMISSIONS,
  options: Partial<EmissionsOptions> = defaultEmissionsOptions
) => {
  const { longForm, sigFigs } = { ...defaultEmissionsOptions, ...options }

  const emissionsUnits = maybeEmissionsUnits ?? DEFAULT_EMISSIONS

  const units = longForm
    ? formatEmissionsUnits(emissionsUnits)
    : formatEmissionsUnitsAbbr(emissionsUnits)
  if (typeof emissions !== 'string' && typeof emissions !== 'number')
    return emDash

  const carbonValue =
    sigFigs > 0
      ? parseFloat(`${emissions}`).toFixed(sigFigs)
      : parseInt(`${emissions}`)

  return `${carbonValue} ${units}`
}
