import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { useState } from 'react'

import { BaseTable } from './BaseTable'
import { TableProps } from './types'

const DEFAULT_PAGE_SIZE = 25

type ClientSideTableProps<T extends GridValidRowModel> = TableProps<T> & {
  defaultPageSize?: number
}

function ClientSideTable<T extends GridValidRowModel>({
  defaultPageSize = DEFAULT_PAGE_SIZE,
  ...rest
}: ClientSideTableProps<T>) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  })

  return (
    <BaseTable
      {...rest}
      onPaginationModelChange={setPaginationModel}
      paginationModel={paginationModel}
    />
  )
}

export {
  ClientSideTable as ClientSide,
  ClientSideTableProps as ClientSideProps,
}
