import { Alert, Snackbar as MuiSnackbar } from '@mui/material'
import { useSnackbar, useSnackbarControls } from '../store'

export const Snackbar = () => {
  const { isOpen, message, severity, title } = useSnackbar()
  const { closeSnackbar } = useSnackbarControls()

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={4000}
      onClose={closeSnackbar}
      open={isOpen}
    >
      <Alert severity={severity ?? 'success'} sx={{ width: '100%' }}>
        {title && <b>{title}</b>} {message}
      </Alert>
    </MuiSnackbar>
  )
}
