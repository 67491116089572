import { ReactElement, useMemo } from 'react'
import { Stack, StackProps, Typography } from '@mui/material'

export type TooltipFormatter<T = Record<string, unknown>> = {
  title: TooltipTitle<T>
  details: TooltipDetailFormatter<T>[]
}

export type TooltipTitle<T = Record<string, unknown>> =
  | string
  | ((data: T) => string)
export type TooltipDetail = {
  label: string
  detail: string
}
export type TooltipDetailFormatter<T = Record<string, unknown>> = (
  data: T
) => TooltipDetail | null

export interface TooltipProps<T = Record<string, unknown>> {
  data: T
  title?: TooltipTitle<T>
  details: TooltipDetailFormatter<T>[]
  maxHeight?: number
  minWidth?: number
  maxWidth?: number
  rest?: StackProps
}

export function Tooltip<T>({
  title,
  data,
  details,
  maxHeight = 300,
  minWidth = 251,
  maxWidth = 500,
  ...rest
}: TooltipProps<T>) {
  const tooltipDetails = useMemo(
    () =>
      details
        .map((formatter) => {
          const detailValue = formatter(data)
          if (!detailValue) return null
          const { label, detail } = detailValue
          return (
            <Stack
              key={`${label}`}
              direction="row"
              sx={{ justifyContent: 'space-between' }}
            >
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="subtitle2">{detail}</Typography>
            </Stack>
          )
        })
        .reduce(
          (acc, detail) => (detail ? [...acc, detail] : acc),
          [] as ReactElement[]
        ),
    [details, data]
  )

  return tooltipDetails.length ? (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: 'text.disabled',
        color: 'primary.contrastText',
        padding: 1,
        maxHeight,
        minWidth,
        maxWidth,
      }}
      {...rest}
    >
      {title && (
        <Typography sx={{ alignSelf: 'flex-start' }} variant="overline">
          {title as string}
        </Typography>
      )}
      {tooltipDetails}
    </Stack>
  ) : null
}

export default Tooltip
