import {
  AppBar,
  Button,
  IconButton,
  Stack,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { Container } from '@mui/system'
import {
  Icons,
  useFullscreenControls,
  useIsFullscreen,
  useRouting,
} from '@synop-react/common'
import {
  resetCloaking,
  useCurrentOrganization,
  useIsCloaking,
} from '@synop-react/api'
import { useDispatch } from 'react-redux'
import NavLogo from './NavLogo'
import NavMenu from './NavMenu'
import SettingsTray from './SettingsTray'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export default function AppNav() {
  const { palette } = useTheme()

  const isCloaking = useIsCloaking()
  const isFullscreen = useIsFullscreen()
  const { exitFullscreen } = useFullscreenControls()

  const MaybeCloakingBar = isCloaking ? <CloakingBar /> : null
  const cloakingOffset = isCloaking ? 10 : 0

  // When in 'fullscreen' mode for display maps we need to hide some nav items and display the minimize button
  const MaybeNavMenu = isFullscreen ? null : <NavMenu />
  const MaybeMinimizeFullscreen = isFullscreen ? (
    <IconButton
      onClick={exitFullscreen}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Icons.Minimize
        data-cy="map-view-toggle"
        strokeWidth="2"
        sx={{ color: palette.primary.main }}
      />
    </IconButton>
  ) : null
  const MaybeSettingsTray = isFullscreen ? null : <SettingsTray />

  return (
    <>
      <AppBar sx={{ bgcolor: 'brand.main' }}>
        <Container disableGutters maxWidth={false}>
          {MaybeCloakingBar}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ px: 2 }}
          >
            <Stack direction="row" spacing={3}>
              <NavLogo />
              {MaybeNavMenu}
            </Stack>
            {MaybeMinimizeFullscreen}
            {MaybeSettingsTray}
          </Stack>
        </Container>
      </AppBar>
      <Offset sx={{ mb: cloakingOffset }} />
    </>
  )
}

const CloakingBar = () => {
  const dispatch = useDispatch()
  const { navigate, routes } = useRouting()

  const { currentOrg } = useCurrentOrganization()
  const { exitFullscreen } = useFullscreenControls()

  const handleUserChange = () => {
    navigate(routes.settings.orgs, {
      replace: true,
    })

    // Exit fullscreen to prevent users from seeing all pages in fullscreen mode
    exitFullscreen()
  }

  return (
    <StyledToolbar sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={1}>
        <Typography>Signed in as:</Typography>
        <Typography fontWeight="bold">{currentOrg?.organizationNm}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={handleUserChange}
          size="small"
          startIcon={
            <Icons.Users
              color="#ffffff"
              height="18"
              strokeWidth="1"
              width="18"
            />
          }
          sx={{ color: '#ffffff' }}
        >
          Change User
        </Button>
        <IconButton
          edge="start"
          onClick={() => {
            dispatch(resetCloaking())
          }}
          size="small"
        >
          <Icons.X color="#ffffff" strokeWidth="1" />
        </IconButton>
      </Stack>
    </StyledToolbar>
  )
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'space-between',
  backgroundColor: theme.palette.text.disabled,
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 50,
  },
}))
