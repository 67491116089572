import { Dropdown } from '../../../../Dropdown'
import { Entity } from '@synop-react/types'
import { EntitySorterMap } from '../../../..'
import { InputAdornment, MenuItem, Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { useEffect } from 'react'
import { useEntityBrowser } from '../../useEntityBrowser'
import FormControl from '@mui/material/FormControl'

interface EntitySortSelectProps<T> {
  entitySorters: EntitySorterMap<T>
}

export function EntitySortSelect<T extends Entity>({
  entitySorters,
}: EntitySortSelectProps<T>) {
  const { setEntitySorter, currentSorters, setSorters } = useEntityBrowser<T>()

  useEffect(() => {
    setEntitySorter(entitySorters)
    // Need to set the entitySorter only once when the component loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSort = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event

    // Sorters come from MUI MultiSelect as a string or a string[]
    const inputSelectedSorters =
      typeof value === 'string' ? value.split(',') : (value as string[])

    const hasAlreadySelectedSorter = inputSelectedSorters
      .map((newSorter) => currentSorters.includes(newSorter))
      .includes(true)

    const filteredSelectedSorters = hasAlreadySelectedSorter
      ? inputSelectedSorters.filter((sorter) => sorter !== value)
      : inputSelectedSorters

    setSorters(filteredSelectedSorters)
  }

  const sortOptions = Object.entries(entitySorters).map(([key, { label }]) => {
    return (
      <MenuItem key={key} value={key}>
        {label}
      </MenuItem>
    )
  })

  return (
    <FormControl variant="standard">
      <Dropdown
        defaultValue={['']}
        id="entitySortSelect"
        labelId="entitySortSelectLabel"
        onChange={handleSort}
        startAdornment={
          <InputAdornment position="start">
            <Typography
              color="primary"
              textTransform="uppercase"
              variant="subtitle2"
            >
              Sort by:
            </Typography>
          </InputAdornment>
        }
        value={currentSorters}
      >
        {sortOptions}
      </Dropdown>
    </FormControl>
  )
}

export default EntitySortSelect
