import { Auth } from 'aws-amplify'
import {
  Button,
  DialogContent,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import { LoadingMessage } from '@synop-react/common'
import {
  RootAPI,
  useCurrentOrganization,
  useCurrentUser,
} from '@synop-react/api'
import { useForm } from 'react-hook-form'
import AuthPage from './AuthPage'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { useState } from 'react'

const { useGetOrganizationTermsQuery } = RootAPI.synopRootAPI

const TermsOfService = () => {
  const theme = useTheme()
  const { handleSubmit } = useForm()
  const [currentTab, setCurrentTab] = useState(0)
  const { currentOrg, isLoading: isFleetLoading } = useCurrentOrganization()
  const { synopUser, isUserLoading } = useCurrentUser()

  const { data: fleetTermsData = [], isLoading: isFleetTermsLoading } =
    useGetOrganizationTermsQuery(
      { id: currentOrg?.id },
      { skip: !currentOrg?.id }
    )

  const [updateUser, updateUserResponse] = RootAPI.useUpdateUserMutation()

  if (isFleetLoading || isFleetTermsLoading || isUserLoading) {
    return <LoadingMessage />
  }

  const onSubmit = async () => {
    if (synopUser)
      await updateUser({
        userModel: {
          ...synopUser,
          termsAccepted: true,
          termsAcceptedDt: new Date().toISOString(),
        },
      })
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const TermsTabs = fleetTermsData.map((orgTerms) => (
    <Tab label={orgTerms.documentTitle} />
  ))

  const TermsTabPanels = fleetTermsData.map((orgTerms, i) => (
    <TermsTabPanel index={i} orgTerms={orgTerms} value={currentTab} />
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthPage
        actions={
          <Grid container direction="row" item spacing={theme.spacing(2)}>
            <Grid item>
              <LoadingButton
                color="primary"
                loading={updateUserResponse.isLoading}
                type="submit"
                variant="contained"
              >
                I Agree
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={() => Auth.signOut()}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        }
        fields={
          <Grid item>
            <DialogContent style={{ maxHeight: '216px', padding: 0 }}>
              <Tabs onChange={handleChange} value={currentTab}>
                {TermsTabs}
              </Tabs>
            </DialogContent>
            {TermsTabPanels}
          </Grid>
        }
        subtitle="Please agree to the following Terms of Service to continue."
        sx={{ maxWidth: '760px', width: '90vw' }}
        title="Terms of Service"
      />
    </form>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  orgTerms?: RootAPI.OrganizationTerms
}

function TermsTabPanel({
  children,
  value,
  index,
  orgTerms,
  ...other
}: TabPanelProps) {
  const { terms } = orgTerms || {}

  const FormattedTerms = terms ? (
    <div
      dangerouslySetInnerHTML={{
        __html: terms,
      }}
    />
  ) : (
    children
  )

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <DialogContent
          style={{
            height: '216px',
            overflowY: 'scroll',
            padding: 0,
          }}
        >
          <Typography>{FormattedTerms}</Typography>
        </DialogContent>
      )}
    </div>
  )
}

export default TermsOfService
