import {
  cloneElement,
  FC,
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
} from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  IconProps,
  Stack,
  Typography,
} from '@mui/material'

import { OverlayContext, useOverlayContext } from './OverlayContext'
import { usePalette } from '@synop-react/theme'
import { X } from '../Icons'

type OverlayProps<OwnProps = {}> = PropsWithChildren<
  OwnProps & OverlayControls & { onClose?: () => void }
>

/**
 * Function returning a HOC that conditionally renders the wrapped component if and only if
 * the `isOpen` prop is `true`. This allows us to create overlay components which make potentially-
 * expensive API calls without having to worry about the API call being made when the overlay is
 * not open.
 */
export function createOverlay<OwnProps>(Component: FC<OwnProps>) {
  return function OverlayWrapper(props: OverlayProps<OwnProps>) {
    const { isOpen, closeOverlay, onClose } = props

    const contextValue = useMemo(
      () => ({
        // Combine `closeOverlay` and `onClose` into a single `cancel` function
        cancel: () => {
          closeOverlay()
          onClose?.()
        },
        closeOverlay,
      }),
      [closeOverlay, onClose]
    )

    // Render nothing if the overlay is not open-- this prevents API calls when the overlay is closed
    if (!isOpen) return null
    return (
      <OverlayContext.Provider value={contextValue}>
        <Component {...props} />
      </OverlayContext.Provider>
    )
  }
}

type OverlayControls = {
  closeOverlay: () => void
  isOpen: boolean
  openOverlay: () => void
  setIsOpen: (isOpen: boolean) => void
}

export function useOverlay(): OverlayControls {
  const [isOpen, setIsOpen] = useState(false)

  return {
    closeOverlay: () => setIsOpen(false),
    isOpen,
    openOverlay: () => setIsOpen(true),
    setIsOpen,
  }
}

const OverlayDialog = ({ children }: PropsWithChildren) => {
  const { cancel } = useOverlayContext()
  return (
    <Dialog
      onClose={cancel}
      open
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root[role="dialog"]': {
            minWidth: `760px`,
            width: '100%',
          },
        },
      }}
    >
      {children}
    </Dialog>
  )
}

type OverlayHeaderProps = {
  title: string
  icon?: ReactElement<IconProps>
}

function OverlayHeader({ icon, title }: OverlayHeaderProps) {
  const { palette } = usePalette()
  const { cancel } = useOverlayContext()
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ pl: 3, pt: 2 }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        {icon &&
          // Add `color` and `strokeWidth` props to `icon`
          cloneElement(icon, {
            sx: { color: palette.text.primary, strokeWidth: 2 },
          })}
        <Typography sx={{ flex: 1 }} variant="h4">
          {title}
        </Typography>
      </Stack>

      <IconButton
        data-cy="close-overlay"
        onClick={cancel}
        size="large"
        sx={{ cursor: 'pointer' }}
      >
        <X fontSize="small" />
      </IconButton>
    </Stack>
  )
}

function OverlayContent({ children }: PropsWithChildren) {
  return (
    <DialogContent>
      <Stack spacing={2}>{children}</Stack>
    </DialogContent>
  )
}

export const Overlay = Object.assign(OverlayDialog, {
  Actions: DialogActions,
  Content: OverlayContent,
  Header: OverlayHeader,
})
