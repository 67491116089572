import { emDash } from './constants'
import { formatUnits, formatUnitsAbbr, UnitsFormat } from './units'
import { PercentUnit } from '@synop-react/api'

const percentFormats: UnitsFormat<PercentUnit> = {
  percent: {
    long: ' Percent',
    short: '%',
  },
}

export const formatPercentUnits = formatUnits(percentFormats)
export const formatPercentUnitsAbbr = formatUnitsAbbr(percentFormats)

export type SoCOptions = {
  sigFigs?: number
  longForm?: boolean
}
const defaultSoCOptions: SoCOptions = {
  sigFigs: 2,
  longForm: false,
}
type FormatSoC = (
  soc?: number | string,
  socUnits?: PercentUnit,
  options?: Partial<SoCOptions>
) => string
export const formatSoC: FormatSoC = (
  soc,
  socUnits = 'percent',
  options = defaultSoCOptions
) => {
  const { longForm } = { ...defaultSoCOptions, ...options }

  socUnits = socUnits.toLowerCase() as PercentUnit

  const units = longForm
    ? formatPercentUnits(socUnits)
    : formatPercentUnitsAbbr(socUnits)

  if (typeof soc === 'string' || typeof soc === 'number') {
    const parsedSoC = Math.floor(Number(parseFloat(`${soc}`)))

    return `${parsedSoC}${units}`
  }
  return emDash
}
