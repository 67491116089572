import { defaultEntityName } from '../../../utils/formatters/entity'
import { EventOptions } from '../useTimeline/Provider'
import { useDepotChargers, useOrgVehicles } from '@synop-react/api'
import { UseEventOptionsProps } from '.'
import { useFormat } from '../../../utils/hooks'
import { usePalette } from '@synop-react/theme'

type UseScheduleChargeProps = {
  depotId?: string
} & UseEventOptionsProps

const useUnscheduledChargeOption = ({
  depotId,
  renderOverlay,
  subtitle,
  tooltipDetails = [],
}: UseScheduleChargeProps): Pick<EventOptions, 'AD_HOC_CHARGE'> => {
  const { formatDateTime } = useFormat()
  const { orgVehicles } = useOrgVehicles()
  const { depotChargers } = useDepotChargers(depotId)
  const { charting } = usePalette()

  return {
    AD_HOC_CHARGE: {
      barColor: charting[2].main,
      caption: {
        title: `Session (Instant)`,
        titleDescription: subtitle,
      },
      tooltip: {
        title: 'Instant Session',
        details: [
          ({ assetId }) => ({
            label: 'Vehicle',
            detail: orgVehicles[assetId ?? '']?.vehicleNm ?? 'Unknown',
          }),
          ({ chargerId }) => ({
            label: 'Charger',
            detail:
              depotChargers.entities[chargerId ?? '']?.chargerName ??
              defaultEntityName('Charger', chargerId),
          }),
          ({ dispenserId }) => ({
            label: 'Connector',
            detail: defaultEntityName('Connector', dispenserId),
          }),
          ({ scheduledStart }) => {
            return {
              label: 'Start',
              detail: formatDateTime(scheduledStart).timeDotDate,
            }
          },
          ({ status, scheduledEnd }) => {
            const isActive = status === 'IN_PROGRESS'

            return {
              label: isActive ? '' : 'End',
              detail: isActive ? '' : formatDateTime(scheduledEnd).timeDotDate,
            }
          },
          ...tooltipDetails,
        ],
      },
      renderOverlay,
    },
  }
}

export default useUnscheduledChargeOption
