import { useMinuteTimer } from '@synop-react/common'

import connectorsColumn from './ConnectorsColumn'
import lastHeardColumn from './LastHeardColumn'
import nameColumn from './NameColumn'
import siteColumn from './SiteColumn'
import statusColumn from './StatusColumn'
import utilizationColumn from './UtilizationColumn'

export function useColumns() {
  // This is instantiated here rather than in the `LastHeardColumn` component so
  // that every row doesn't create its own timer, which would lead to a lot of
  // unnecessary re-renders.
  const now = useMinuteTimer()

  return {
    groupingColumn: nameColumn,
    columns: [
      statusColumn,
      siteColumn,
      utilizationColumn,
      connectorsColumn,
      lastHeardColumn(now),
    ],
  }
}
