import { FieldValues } from 'react-hook-form'
import { InputAdornment, Stack, Typography } from '@mui/material'
import TextFormField, { TextFieldFormControls } from '../TextFormField'

export type SoCSliderProps<FormData extends FieldValues> = {
  label?: string
  min?: number
  max?: number
  step?: number
  disabled?: boolean
} & TextFieldFormControls<FormData>

const SocSlider = function <FormData extends FieldValues>({
  label = 'Target SoC',
  min = 0,
  max = 100,
  step = 1,
  disabled = false,
  ...sliderProps
}: SoCSliderProps<FormData>) {
  const disableNumberInputButtons = {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  }
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <TextFormField
        disabled={disabled}
        error={sliderProps.error}
        fullWidth
        InputProps={{
          inputProps: { min: min, max: max, step: step },
          endAdornment: (
            <InputAdornment position="end">
              <Typography>%</Typography>
            </InputAdornment>
          ),
        }}
        isSubmitted={true}
        label={label}
        sx={{ ...disableNumberInputButtons, minWidth: 120 }}
        type="number"
        {...sliderProps}
      />
    </Stack>
  )
}

export default SocSlider
