import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { FormField } from '../../index'
import { orderBy } from 'lodash'
import { RootAPI, useCurrentUser } from '@synop-react/api'

const { useGetDepotsQuery } = RootAPI.synopRootAPI

export type DepotAutocompleteSelectProps<FormData extends FieldValues> = {
  label?: string
  fleetId: string
} & AutocompleteFormControls<FormData, RootAPI.DepotModel> &
  AutocompleteTextFieldProps

export const DepotAutocompleteSelect = <FormData extends FieldValues>({
  fleetId,
  label = 'Site',
  ...autocompleteProps
}: DepotAutocompleteSelectProps<FormData>) => {
  const depotOptions = FormField.useOptions<
    RootAPI.DepotModel,
    RootAPI.GetDepotsApiArg,
    RootAPI.PagedResponseDepotModel
  >({
    queryArgs: { count: 100, fleetId, page: 0 },
    useQuery: useGetDepotsQuery,
  })
  const { options, ...rest } = depotOptions
  const { synopUser, isAdmin } = useCurrentUser()

  const siteOptions = depotOptions.options.filter((depot) => {
    return !(!isAdmin && !synopUser?.sites?.includes(depot.depotId))
  })

  depotOptions.options = siteOptions

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.DepotModel>
      label={label}
      options={orderBy(siteOptions, 'depotNm')}
      {...rest}
      {...autocompleteProps}
      filterOptions={(options, { inputValue }) =>
        options.filter(({ depotNm }) => {
          const depotName = depotNm?.toLowerCase() || ''
          const searchTerm = inputValue.toLowerCase()
          return depotName.includes(searchTerm)
        })
      }
      getOptionLabel={({ depotNm }: RootAPI.DepotModel) => depotNm || ''}
      keyExtractor={({ depotId }: RootAPI.DepotModel) => depotId || ''}
    />
  )
}

const depotSelectSchema = yup
  .object({ depotId: yup.string().required() })
  .nullable()
  .required('Site is required')

export { DepotAutocompleteSelect as Select, depotSelectSchema as Schema }
