import { ReactNode } from 'react'
import { Typography, TypographyProps } from '@mui/material'

export type TEntityListItemDetail = {
  value: string | ReactNode
} & Omit<TypographyProps, 'variant'>

export function EntityListItemDetail({
  value,
  color = 'textSecondary',
  ...restProps
}: TEntityListItemDetail) {
  return (
    <Typography color={color} variant="subtitle2" {...restProps}>
      {value}
    </Typography>
  )
}

export default EntityListItemDetail
