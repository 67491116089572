import * as yup from 'yup'
import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  Bell,
  Crate,
  FormField,
  Icons,
  OverlayDeprecated,
} from '@synop-react/common'
import { Button, Chip, Grid, Stack, Typography } from '@mui/material'
import {
  cloneElement,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useForm,
  useWatch,
} from 'react-hook-form'
import { RootAPI } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'

const { useUpdateAlarmMutation } = RootAPI

export type EditNotificationOverlayProps = {
  Trigger: ReactElement
  alarm: RootAPI.AlarmModel
}

const alarmDeliveryTypes = ['EMAIL', 'PHONE'] as const
type AlarmDeliveryType = (typeof alarmDeliveryTypes)[number]

type EditNotificationFormData = {
  recipientEmailValue: string
  recipientPhoneValue: string
  recipientType: DeliveryOption
}

const schema = yup.object().shape({
  recipientEmailValue: yup.string().when('recipientType', {
    is: ({ type }: DeliveryOption) => type === 'EMAIL',
    then: FormField.emailValidator({ isRequired: false }),
  }),
  recipientPhoneValue: yup.string().when('recipientType', {
    is: ({ type }: DeliveryOption) => type === 'PHONE',
    then: FormField.phoneNumberValidator({ isRequired: false }),
  }),
})

export const EditNotificationOverlay = ({
  Trigger,
  alarm,
}: EditNotificationOverlayProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [recipients, setRecipients] = useState(alarm.recipients || [])
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
    trigger,
  } = useForm<EditNotificationFormData>({
    defaultValues: {
      recipientEmailValue: '',
      recipientPhoneValue: '',
      recipientType: { type: 'EMAIL', label: 'Email' },
    },
    resolver: yupResolver(schema),
  })
  const recipientEmailValue = useWatch({ control, name: 'recipientEmailValue' })
  const recipientPhoneValue = useWatch({ control, name: 'recipientPhoneValue' })

  const recipientType = useWatch({
    control,
    name: 'recipientType',
  })

  const [updateAlarm, updatedAlarmResponse] = useUpdateAlarmMutation()

  const onSubmit = useMemo(
    () => async () => {
      const formattedRecipients = recipients.map(
        ({ deliveryValue = '', ...recipient }) => ({
          ...recipient,
          deliveryValue: deliveryValue.replace(/ /g, ''),
        })
      )
      const updatedAlarm: RootAPI.AlarmModel = Object.assign({}, alarm, {
        recipients: formattedRecipients,
      })
      updateAlarm({ id: alarm.id, alarmModel: updatedAlarm })
    },
    [alarm, recipients, updateAlarm]
  )

  useEffect(() => {
    if (updatedAlarmResponse.isSuccess) {
      setIsOpen(false)
    }
  }, [updatedAlarmResponse])

  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => {
      setIsOpen(true)
    },
  })

  const { alarmNm = '', alarmDesc = '' } = alarm
  const { type: selectedDeliveryType } = recipientType

  const addRecipient: MouseEventHandler<HTMLButtonElement> = async () => {
    const isValid = await trigger([
      'recipientEmailValue',
      'recipientPhoneValue',
      'recipientType',
    ])
    const recipientValueMap: Record<AlarmDeliveryType, string> = {
      EMAIL: recipientEmailValue,
      PHONE: recipientPhoneValue,
    }

    const recipientValue = recipientValueMap[recipientType.type]

    if (isValid && recipientValue) {
      const newRecipient = {
        deliveryType: selectedDeliveryType,
        deliveryValue: recipientValue,
      }
      setRecipients([...recipients, newRecipient])
      reset({
        recipientEmailValue: '',
        recipientPhoneValue: '',
        recipientType,
      })
    }
  }

  const removeRecipient =
    (removeValue = '') =>
    () => {
      setRecipients(
        recipients.filter(({ deliveryValue }) => deliveryValue !== removeValue)
      )
    }

  const RecipientChips = recipients.map(({ deliveryValue }) => (
    <Chip
      key={deliveryValue}
      color="primary"
      label={deliveryValue}
      onDelete={removeRecipient(deliveryValue)}
      variant="outlined"
    />
  ))

  return (
    <>
      {OverlayTrigger}
      <OverlayDeprecated
        onClose={() => setRecipients(alarm.recipients || [])}
        OverlayActions={[
          <LoadingButton
            color="primary"
            loading={updatedAlarmResponse.isLoading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </LoadingButton>,
        ]}
        subtitle="Adjust the notification information below"
        title="Configure Notification"
        TitleIcon={Icons.Settings}
        {...{ isOpen, setIsOpen }}
      >
        <form>
          <Stack spacing={2}>
            <Crate
              description={alarmDesc}
              height="96px"
              Icon={<Bell />}
              title={alarmNm}
            />
            <Typography>
              When triggered, the following recipients will be notified
            </Typography>
            <Stack direction="row" spacing={2}>
              {RecipientChips}
            </Stack>
            <Grid alignItems="end" columnSpacing={2} container>
              <Grid item xs={3}>
                <DeliveryTypeDropdown id="recipientType" {...{ control }} />
              </Grid>
              <Grid item xs={4}>
                {selectedDeliveryType === 'EMAIL' && (
                  <FormField.TextFormField
                    control={control}
                    error={errors.recipientEmailValue as FieldError}
                    id="recipientEmailValue"
                    label="Email"
                    touched={Boolean(touchedFields.recipientEmailValue)}
                  />
                )}
                {selectedDeliveryType === 'PHONE' && (
                  <FormField.PhoneInput
                    control={control}
                    id="recipientPhoneValue"
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Button onClick={addRecipient} size="small" variant="outlined">
                  Add Recipient
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </OverlayDeprecated>
    </>
  )
}

export default EditNotificationOverlay

type DeliveryTypeDropdownProps<FormData extends FieldValues> = {
  id: Path<FormData>
  label?: string
  control: Control<FormData, unknown>
} & AutocompleteFormControls<FormData, DeliveryOption> &
  AutocompleteTextFieldProps
type DeliveryOption = { type: AlarmDeliveryType; label: string }

const DeliveryTypeDropdown = <FormData extends FieldValues>({
  id,
  control,
  label = 'Type',
  ...autocompleteProps
}: DeliveryTypeDropdownProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)
  const deliveryOptions: DeliveryOption[] = [
    {
      type: 'EMAIL',
      label: 'Email',
    },
    {
      type: 'PHONE',
      label: 'Phone',
    },
  ]

  return (
    <FormField.AutocompleteSelect<FormData, DeliveryOption>
      {...autocompleteProps}
      control={control}
      disableClearable={true}
      getOptionLabel={(option: DeliveryOption) => `${option.label}`}
      id={id}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: DeliveryOption) => option.type}
      label={label}
      options={deliveryOptions}
      setIsOpen={setIsOpen}
    />
  )
}
