import {
  appendOverlayNotifications,
  clearOverlayNotifications,
  NotificationOverlay,
  NotificationsDrawer,
  useNotificationOverlayOpen,
} from '@synop-react/notification'
import { Avatar, Badge, Grid, useTheme } from '@mui/material'
import { Icons, useFirstRender, useRouting } from '@synop-react/common'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { sumBy } from 'lodash'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

const { useGetNotificationsQuery } = RootAPI.synopRootAPI

const NotificationBell = () => {
  const dispatch = useDispatch()
  const isNotificationOverlayOpen = useNotificationOverlayOpen()
  const { palette } = useTheme()
  const { location, navigate, routes } = useRouting()
  const { user } = useCurrentUser()

  const [seenNotifications, setSeenNotifications] = useState<string[]>([])

  const { data: notifications = [] } = useGetNotificationsQuery(
    {
      userId: user?.username ?? '',
    },
    {
      skip: !user?.username,
      pollingInterval: 1000 * 60,
    }
  )
  useFirstRender(() => {
    dispatch(clearOverlayNotifications())
  })

  useEffect(() => {
    const newCriticalNotifications = notifications.filter(
      ({ id, acknowledged, priority }) =>
        !seenNotifications.includes(id) &&
        !acknowledged &&
        priority === 'Critical'
    )
    dispatch(appendOverlayNotifications([...newCriticalNotifications]))
    // exhaustive-deps wants to include seenNotifications but if we do we get an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, notifications])

  const unAcknowledgedCount = sumBy(notifications, ({ acknowledged }) =>
    acknowledged ? 0 : 1
  )

  const { pathname } = location
  const activeBackground = pathname.includes(routes.notifications)
    ? palette.secondary.main
    : '#00000000'

  const activeIcon = pathname.includes(routes.notifications)
    ? '#ffff'
    : palette.secondary.main

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    if (unAcknowledgedCount > 0) setAnchorEl(e.currentTarget)
  }

  return (
    <>
      {isNotificationOverlayOpen && (
        <NotificationOverlay
          onClose={(ids: string[]) => {
            setSeenNotifications([...seenNotifications, ...ids])
          }}
        />
      )}
      <Grid
        container
        item
        sx={{
          alignItems: 'center',
          height: '40px',
          justifyContent: 'center',
          width: '40px',
          cursor: 'pointer',
        }}
      >
        <Badge
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          badgeContent={unAcknowledgedCount}
          color="primary"
          max={999}
          overlap="circular"
        >
          <Avatar
            onMouseOver={handleMouseOver}
            sx={{ backgroundColor: activeBackground }}
          >
            <Icons.Bell
              onClick={() => navigate(routes.notifications)}
              sx={{ color: activeIcon, strokeWidth: 2 }}
            />
          </Avatar>
        </Badge>
        <NotificationsDrawer
          anchorEl={anchorEl}
          closeDrawer={() => setAnchorEl(null)}
          notifications={notifications.filter(
            ({ acknowledged }) => !acknowledged
          )}
        />
      </Grid>
    </>
  )
}

export default NotificationBell
