import {
  BrowserSelector,
  EntityListItemRenderer,
  EntityMapConfig,
  EntityTableRowRenderer,
} from '../EntityBrowser'
import { createContext, useContext } from 'react'
import { defaultEntityReducerState, EntityState } from './useEntityReducer'
import { Entity, EntityBooleanMap, Id } from '@synop-react/types'
import { EntitySorterMap } from '../../..'
import { GridColDef, GridRowModel } from '@mui/x-data-grid-premium'
import { TEntityListItem } from '../../List/Item'

export type BrowserContextState<T extends Entity> = EntityState<T> & {
  setDisplayedEntities: (entityIds: EntityBooleanMap) => void
  toggleSelectedEntity: (entityId: Id) => void
  setSortedEntityIds: (entityIds: Id[]) => void
  setEntitySorter: (entitySorter: EntitySorterMap<T>) => void
  setSorters: (sorters: string[]) => void
  mapConfig: EntityMapConfig<T>
  renderListItem: EntityListItemRenderer<T>
  tableColumns: GridColDef[]
  renderTableRow: EntityTableRowRenderer<T>
  selectState: <R>(selector: BrowserSelector<R>) => R
}

const defaultContext = {
  ...defaultEntityReducerState,
  renderListItem: (_entity: unknown) =>
    ({
      titleText: 'n/a',
      descriptionText: '',
    } as TEntityListItem),
  setSortedEntityIds: (_entityIds: string[]) => undefined,
  setEntitySorter: (_entitySorter: EntitySorterMap) => undefined,
  setSorters: (_sorters: string[]) => undefined,
  mapConfig: {} as EntityMapConfig,
  renderMarkers: (_entities: unknown) => [],
  renderTableRow: (_entity: unknown) => ({} as GridRowModel),
  setDisplayedEntities: (_entityIdMap: EntityBooleanMap) => ({}),
  toggleSelectedEntity: (_entityId: string) => ({}),
  tableColumns: [] as GridColDef[],
  selectState: <R>() => ({} as R),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EntityContext = createContext<BrowserContextState<any>>(defaultContext)
export const EntityProvider = EntityContext.Provider

export function useEntityBrowser<T extends Entity>() {
  return useContext<BrowserContextState<T>>(EntityContext)
}

export function useBrowserSelector<T>(selector: BrowserSelector<T>) {
  const { selectState } = useContext(EntityContext)
  return selectState<T>(selector)
}
