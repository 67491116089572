import { Box, Grid, Stack, Typography } from '@mui/material'
import { isNumber, isString, isUndefined } from 'lodash'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

import {
  CurrentConnectorStatus,
  useChargerDetailsFromPoll,
} from '@synop-react/api'
import { DonutChart } from '../Charts/DonutChart/DonutChart'
import { emDash, reduceTime } from '../utils'
import { Maybe } from '@synop-react/types'
import { StatusColorProvider, useConnectorStatusColors } from './ChargerStatus'
import { TimeCaskChildrenProps, TimeRangeCask } from '../TimeRangeCask'

dayjs.extend(minMax)

type ChargerUptimeCaskProps = {
  chargerId: string
  height?: string | number
}

export const ChargerUptimeCask = ({ chargerId }: ChargerUptimeCaskProps) => {
  return (
    <TimeRangeCask
      data-cy="charger-uptime-cask"
      defaultTimeResolution="WEEK"
      disableToPast={false}
      ranges={['DAY', 'WEEK', 'MONTH', 'CUSTOM']}
      title="Charger Uptime"
    >
      {({ from, to }) => (
        <Box px={1}>
          <ChargerUptime {...{ chargerId, from, to }} />
        </Box>
      )}
    </TimeRangeCask>
  )
}
type ChargerUptimeProps = {
  chargerId: string
} & TimeCaskChildrenProps

const ChargerUptime = ({ chargerId, from, to }: ChargerUptimeProps) => {
  const { getTimespanHistoricalStatuses } = useChargerDetailsFromPoll({
    chargerId,
    from,
    to,
    pollingIntervalInSeconds: 5,
  })

  const { numConnectors, statuses, uptimePercentage, uptimeMs } =
    getTimespanHistoricalStatuses.data?.connectorStatusHistory ?? {}

  // Convert the `uptimeMs` field to seconds and scale down by the number of connectors
  const totalUptime = useMemo(() => {
    if (isUndefined(uptimeMs)) return

    const uptimeSeconds = reduceTime(uptimeMs / 1000)
    if (isUndefined(numConnectors) || numConnectors < 1) {
      return uptimeSeconds
    } else {
      return {
        suffix: uptimeSeconds.suffix,
        value: (uptimeSeconds.value as number) / numConnectors,
      }
    }
  }, [uptimeMs, numConnectors])

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={6}>
        <DataBlock
          title="Uptime Percentage"
          units="%"
          value={uptimePercentage}
        />
      </Grid>

      <Grid item md={3} xs={6}>
        <DataBlock
          title="Avg. Uptime (per connector)"
          units={totalUptime?.suffix}
          value={totalUptime?.value}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <StatusColorProvider>
          <ConnectorStatusBreakdown statusAllocation={statuses} />
        </StatusColorProvider>
      </Grid>
    </Grid>
  )
}

type DataBlockProps = {
  title: string
  units?: string
  value: Maybe<number | string>
}

const DataBlock = ({ title, units, value }: DataBlockProps) => {
  const valueText = useMemo(() => {
    if (!isString(value) && !isNumber(value)) return emDash
    if (!isString(units)) return value
    return `${value} ${units}`
  }, [units, value])

  return (
    <Stack sx={{ height: '150px', justifyContent: 'center' }}>
      <Typography variant="h3">{valueText}</Typography>
      <Typography variant="caption">{title.toUpperCase()}</Typography>
    </Stack>
  )
}

type ConnectorStatusBreakdownProps = {
  statusAllocation: Maybe<Record<string, number>>
}

const ConnectorStatusBreakdown = ({
  statusAllocation = {},
}: ConnectorStatusBreakdownProps) => {
  const colorMap = useConnectorStatusColors()

  const data = useMemo(() => {
    const statusUptimeSeconds: Record<string, number> = {}
    let totalTime = 0

    Object.entries(statusAllocation).forEach(([status, value]) => {
      if (value === 0) return
      if (!statusUptimeSeconds[status]) statusUptimeSeconds[status] = 0
      statusUptimeSeconds[status] += value
      totalTime += value
    })

    const pieChartData = Object.entries(statusUptimeSeconds).map(
      ([status, uptime]) => ({
        color: colorMap[status as CurrentConnectorStatus],
        text: status,
        percentage: (uptime / totalTime) * 100,
      })
    )

    return pieChartData
  }, [colorMap, statusAllocation])

  return data.length === 0 ? (
    <DataBlock title="Uptime by Status" value={undefined} />
  ) : (
    <DonutChart
      data={data}
      donutFill={0.2}
      inactiveText=""
      margin={{ left: 0, right: 0 }}
    />
  )
}
