import { Button, Link, Typography, useTheme } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import {
  Cask,
  CreateChargerOverlay,
  emDash,
  formatPower,
  LoadingMessage,
  PlusCircle,
  Table,
} from '@synop-react/common'
import {
  ChargerEntity,
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
} from '@synop-react/api'

const { useGetChargersQuery } = RootAPI.synopRootAPI

const ChargersSettings = () => {
  const theme = useTheme()
  const { synopUser, isAdmin } = useCurrentUser()

  const organizationId = useCurrentOrgId()

  const { data: chargersResponse, isLoading: isChargersLoading } =
    useGetChargersQuery(
      { organizationId: [organizationId], count: 500 },
      { skip: !organizationId }
    )

  let items = chargersResponse?.items || []
  items = items.filter((charger) => {
    return !(
      !isAdmin &&
      charger.depotId &&
      !synopUser?.sites?.includes(charger.depotId)
    )
  })
  const columns: Table.ColumnSpec<ChargerEntity> = [
    {
      field: 'chargerName',
      headerName: 'Name',
      flex: 1,
      renderCell: (cell: GridRenderCellParams<ChargerEntity, string>) => {
        const { value, row } = cell
        const { chargerId } = row
        return (
          <Link
            href={`/chargers/${chargerId}`}
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              color: theme.palette.primary.main,
            }}
          >
            {value}
          </Link>
        )
      },
    },
    {
      field: 'depotName',
      headerName: 'Site',
      flex: 1,
      renderCell: (cell: GridRenderCellParams<ChargerEntity>) => {
        const { value, row } = cell
        const { depotId } = row
        return (
          <Link
            href={`/sites/${depotId}`}
            style={{
              textDecoration: 'none',
              color: theme.palette.primary.main,
            }}
          >
            {value}
          </Link>
        )
      },
    },
    {
      field: 'connectorIds',
      headerName: 'Connectors',
      flex: 0.5,
      groupingValueGetter: ({ row }) =>
        row.connectorIds?.filter((id) => id !== 0).length ?? emDash,
      valueGetter: ({ value }: GridRenderCellParams<ChargerEntity, number[]>) =>
        value?.filter((connector) => connector).length ?? emDash, // remove connector 0 from the connector count
      type: 'number',
    },
    {
      field: 'maxPower',
      headerName: 'Charger Limit',
      flex: 1,
      type: 'number',
      valueFormatter: (params) => formatPower(params.value),
    },
  ]

  return (
    <>
      <Cask
        Actions={
          isAdmin ? (
            <CreateChargerOverlay
              Trigger={
                <Button startIcon={<PlusCircle />} variant="outlined">
                  New Charger
                </Button>
              }
            />
          ) : (
            <></>
          )
        }
        title="Chargers"
      >
        <Typography variant="body1">
          {isAdmin
            ? 'Add or adjust the chargers for your organization'
            : 'View the chargers for your organization'}
          .
        </Typography>
        {isChargersLoading ? (
          <LoadingMessage />
        ) : (
          <Table.ClientSide
            columns={columns}
            getRowId={(row) => row.chargerId}
            height="700px"
            initialSortColumn="chargerName"
            tableData={items as ChargerEntity[]}
          />
        )}
      </Cask>
    </>
  )
}

export default ChargersSettings
