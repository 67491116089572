import { ReactElement } from 'react'
import { RootAPI } from '@synop-react/api'
import ConfigureChargerOverlay from '../ConfigureChargerOverlay'

const { useCreateChargerMutation } = RootAPI.synopRootAPI

export type CreateChargerOverlayProps = {
  Trigger: ReactElement
}

const CreateChargerOverlay = ({ Trigger }: CreateChargerOverlayProps) => {
  const formDefaults = {
    chargerName: '',
    chargerId: '',
    depot: null,
    manufacturer: null,
    makeModel: null,
    maxPower: undefined,
    maxCurrent: undefined,
    voltage: undefined,
    autoRegisterTags: false,
    networkConnectionMethod: null,
    networkConnectionDetails: '',
    externalId: '',
    utilityLocationId: '',
    commissioningDate: '',
    evseInstallationType: null,
    isAdaAccessible: null,
    latitude: undefined,
    longitude: undefined,
  }

  return (
    <ConfigureChargerOverlay
      createMutation={useCreateChargerMutation()}
      editingExisting={false}
      formDefaults={formDefaults}
      showOemHelper
      Trigger={Trigger}
    />
  )
}

export default CreateChargerOverlay
