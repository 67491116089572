import { Icons, useRouting } from '@synop-react/common'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useCallback, useState } from 'react'
import { useUserLogout } from '@synop-react/api'
import UserAvatar from './UserAvatar'

const UserMenu = () => {
  const logoutUser = useUserLogout()
  const { navigate, routes } = useRouting()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSettings = () => {
    navigate(routes.settings.root)
    handleClose()
  }

  const handleLogout = useCallback(() => {
    logoutUser()
    handleClose()
  }, [logoutUser])

  const open = Boolean(anchorEl)

  return (
    <>
      <UserAvatar onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id="settings-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleSettings}>
          <ListItemIcon>
            <Icons.Settings />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Icons.LogOut />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
