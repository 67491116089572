import { Button, Typography } from '@mui/material'
import { useCallback } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  createOverlay,
  Icons,
  Overlay,
  useOverlayContext,
  useSnackbarControls,
} from '@synop-react/common'
import { RootAPI } from '@synop-react/api'

type RebootChargerOverlayProps = {
  chargerId: string
}

export const RebootChargerOverlay = createOverlay<RebootChargerOverlayProps>(
  ({ chargerId }) => {
    const { closeOverlay } = useOverlayContext()
    const { openSnackbar } = useSnackbarControls()
    const [resetCharger, resetChargerResponse] =
      RootAPI.synopRootAPI.useResetChargerMutation()

    const onClick = useCallback(async () => {
      try {
        await resetCharger({ chargerId, type: 'HARD' }).unwrap()
        openSnackbar('The charger accepted the reboot request.', {
          title: 'Reboot Accepted.',
        })
      } catch (err) {
        console.error(err)
        openSnackbar('The charger rejected the reboot request.', {
          severity: 'error',
          title: 'Reboot Rejected.',
        })
      }

      // Close the overlay in either case
      closeOverlay()
    }, [chargerId, closeOverlay, openSnackbar, resetCharger])

    return (
      <Overlay>
        <Overlay.Header icon={<Icons.Power />} title="Reboot Charger" />

        <Overlay.Content>
          <Typography variant="body1">
            Are you sure you wish to proceed? A reboot will perform a hard reset
            and may cause a few minutes of downtime.
          </Typography>
        </Overlay.Content>

        <Overlay.Actions>
          <LoadingButton
            color="error"
            loading={resetChargerResponse.isLoading}
            onClick={onClick}
            variant="contained"
          >
            Reboot Charger
          </LoadingButton>

          <Button onClick={closeOverlay} variant="outlined">
            Cancel
          </Button>
        </Overlay.Actions>
      </Overlay>
    )
  }
)
