import { AxisBottom } from '@visx/axis'
import { Group } from '@visx/group'
import { NumberValue } from 'd3-scale'
import { scaleTime } from '@visx/scale'
import { useMemo } from 'react'

import { NonEmptyArray } from '@synop-react/types'
import { prevDayBuffer } from '../../Timeline/useTimeline/useTimelineReducer/selectors/scale'
import {
  selectBottomAxisArea,
  selectChartArea,
  selectLeftAxisArea,
} from '../../Timeline/useTimeline/useTimelineReducer/selectors/window'
import { useTimelineSelector } from '../../Timeline/useTimeline/useTimeline'
import { useUserPrefs } from '@synop-react/api'

export interface DayHourAxisProps {
  displayedDate?: Date
}

export function DayHourAxis({
  displayedDate: maybeDisplayedDate,
}: DayHourAxisProps) {
  const { tzDayjs, preferredHourFormat } = useUserPrefs()
  const { height: chartHeight } = useTimelineSelector(selectChartArea)
  const { width: axisWidth } = useTimelineSelector(selectBottomAxisArea)
  const { width: leftAxisWidth } = useTimelineSelector(selectLeftAxisArea)

  const displayedDate = useMemo(
    () => maybeDisplayedDate ?? tzDayjs().startOf('day').toDate(),
    [maybeDisplayedDate, tzDayjs]
  )

  const hourTicks = useMemo(() => {
    const startBuffer = prevDayBuffer(displayedDate, tzDayjs).toDate()

    const ticks: NonEmptyArray<Date> = [startBuffer]
    for (let i = 0; i <= 24; i++) {
      ticks.push(tzDayjs(displayedDate).hour(i).toDate())
    }
    return ticks
  }, [displayedDate, tzDayjs])

  const dayScale = useMemo(() => {
    const earliestTime = hourTicks[0]
    const dayEnd = hourTicks.at(-1) as Date
    return scaleTime({
      domain: [earliestTime, dayEnd],
      range: [0, axisWidth],
    })
  }, [axisWidth, hourTicks])

  const formatTick = (value: Date | NumberValue) => {
    const today = tzDayjs()
    const tickHour =
      typeof value === 'object' ? tzDayjs(value.valueOf()) : tzDayjs(value)
    const formattedHour = today.isSame(tickHour, 'day')
      ? tickHour.format(preferredHourFormat)
      : ''
    return formattedHour
  }

  return (
    <Group left={leftAxisWidth}>
      <AxisBottom
        hideAxisLine
        hideTicks
        hideZero
        numTicks={24}
        scale={dayScale}
        tickFormat={formatTick}
        tickValues={hourTicks}
        top={chartHeight}
      />
    </Group>
  )
}

export default DayHourAxis
