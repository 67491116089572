import { GeoJsonProperties } from 'geojson'
import {
  LngLatBoundsLike,
  MapProvider,
  MapRef,
  Map as ReactGlMap,
  MapProps as ReactGlMapProps,
  ViewState,
} from 'react-map-gl'
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import mapboxgl, { MapboxGeoJSONFeature } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { FeatureCollection } from '@turf/helpers'
import { MapTooltip } from './Tooltip'
import { TooltipDetailFormatter } from '../Tooltip'
import { usePreviousValue } from '../utils'
import bbox from '@turf/bbox'

export type MapProps = {
  /** A unique id for this map */
  id: string
  children?: ReactNode
  /** The [Mapbox map style](https://docs.mapbox.com/api/maps/styles/#mapbox-styles) to use on the base map */
  mapStyle?: string
  /** The default latitude to use when no bounds are specified. */
  defaultLat?: number
  /** The default longitude to use when no bounds are specified. */
  defaultLong?: number
  /** The minimum zoom level to use when no bounds are specified. */
  defaultMinZoom?: number
  /** The maximum zoom level to allow when the map view transitions to the specified bounds. */
  defaultMaxZoom?: number
  /** The amount of padding in pixels to add to the given bounds. */
  mapPadding?: number
  /** The features that have interactiveLayerId that were clicked on */
  onLayerClick?: (
    features: MapboxGeoJSONFeature[],
    map: MutableRefObject<MapRef | null>
  ) => void

  // Emit a map reference on load
  onMapLoad?: (map: MutableRefObject<MapRef | null>) => void

  // Emit an event on zoom action end
  onZoomEndAction?: (map: MutableRefObject<MapRef | null>) => void
  onStyleDataAction?: (map: MutableRefObject<MapRef | null>) => void

  onMoveEndAction?: (map: MutableRefObject<MapRef | null>) => void

  /** Whether the map can be exported to a PNG using map.getCanvas().toDataURL(); */
  preserveDrawingBuffer?: boolean
  /** The title to use for a tooltip when hovering over a feature */
  tooltipTitle?: string
  /**
   * An array of formatters that will be passed feature data from a layer and the label and detail value that
   * should be displayed
   * */
  tooltipDetails?: TooltipDetailFormatter<GeoJsonProperties>[]
  /** An array of layerIds that should have their properties passed to the Tooltip Details formatters */
  tooltipLayers?: string[]
  /**
   * The data that will be use to [fit to the bounds](https://docs.mapbox.com/mapbox-gl-js/api/map/#map#fitbounds).
   * Updates to this will cause the map to move to it's new bounding box
   * */
  boundedData?: FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
  /** Enable / disable map zooming and moving; defaults to true */
  canZoom?: boolean
  /** Override cursor on map */
  defaultCursor?: string
  /** The feature id which should have a feature state of 'selected' */
  selectedFeatureId?: string
  /** The Source id which should be used for updating the selected state */
  selectableSourceId?: string
} & Omit<ReactGlMapProps, 'mapboxAccessToken' | 'mapStyle' | 'fog' | 'terrain'>

type HoverInfo = {
  x: number
  y: number
  features?: MapboxGeoJSONFeature[]
}

const MAPBOX_TOKEN = process.env['NX_MAPBOX_ACCESS_TOKEN'] || ''
const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/light-v10'
// Default Lat / Long is centroid of contiguous US
const DEFAULT_LAT = 39.833333
const DEFAULT_LONG = -98.583333
const DEFAULT_MIN_ZOOM = 3
const DEFAULT_MAX_ZOOM = 13
const DEFAULT_MAP_PADDING = 50

function Map({
  id,
  mapStyle = DEFAULT_MAP_STYLE,
  canZoom = true,
  defaultCursor = 'grab',
  defaultLat = DEFAULT_LAT,
  defaultLong = DEFAULT_LONG,
  defaultMinZoom = DEFAULT_MIN_ZOOM,
  defaultMaxZoom = DEFAULT_MAX_ZOOM,
  mapPadding = DEFAULT_MAP_PADDING,
  onLayerClick = () => ({}),
  onMapLoad = () => ({}),
  onZoomEndAction = () => ({}),
  onStyleDataAction = () => ({}),
  onMoveEndAction = () => ({}),
  preserveDrawingBuffer = false,
  tooltipTitle = '',
  tooltipLayers = [],
  tooltipDetails = [],
  selectedFeatureId,
  selectableSourceId,
  boundedData,
  children,
  ...rest
}: MapProps) {
  const mapRef = useRef<MapRef | null>(null)
  const lastSelectedFeatureId = usePreviousValue(selectedFeatureId)
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null)
  const [cursor, setCursor] = useState(defaultCursor)

  const boundedCoords = useMemo(() => {
    if (boundedData && boundedData.features?.length) {
      const [swLat, swLong, neLat, neLong] = bbox(boundedData)
      return [swLat, swLong, neLat, neLong] as LngLatBoundsLike
    }
    return null
  }, [boundedData])

  const initialView: Partial<ViewState> = useMemo(
    () =>
      boundedCoords
        ? {
            bounds: boundedCoords,
            fitBoundsOptions: {
              padding: mapPadding,
              maxZoom: defaultMaxZoom,
            },
          }
        : {
            latitude: defaultLat,
            longitude: defaultLong,
            zoom: defaultMinZoom,
          },
    [
      boundedCoords,
      defaultLat,
      defaultLong,
      defaultMinZoom,
      defaultMaxZoom,
      mapPadding,
    ]
  )

  const onHover = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const { features, point } = event

    if (features?.length) {
      const tooltipOffset = 15
      setHoverInfo({
        features,
        x: point.x + tooltipOffset,
        y: point.y + tooltipOffset,
      })
      setCursor('pointer')
    }
  }, [])

  // Reposition the map when bounded coordinates change
  useEffect(() => {
    if (boundedCoords) {
      mapRef.current?.fitBounds(boundedCoords, {
        padding: DEFAULT_MAP_PADDING,
        maxZoom: defaultMaxZoom,
      })
    }
  }, [boundedCoords, defaultMaxZoom])

  // Optionally add 'selected' feature state to map features
  useEffect(() => {
    if (!selectableSourceId) return

    // Remove the selected state from the last selected feature
    if (lastSelectedFeatureId) {
      mapRef.current?.setFeatureState(
        {
          source: selectableSourceId,
          id: lastSelectedFeatureId,
        },
        { selected: false }
      )
    }

    // Add the selected state to the new selected feature
    if (selectedFeatureId) {
      mapRef.current?.setFeatureState(
        { source: selectableSourceId, id: selectedFeatureId },
        { selected: true }
      )
    }
  }, [selectedFeatureId, lastSelectedFeatureId, selectableSourceId])

  const resetTooltip = () => {
    setHoverInfo(null)
    setCursor('grab')
  }

  const zoomMoveProps = canZoom
    ? {}
    : {
        dragPan: false,
        dragRotate: false,
        scrollZoom: false,
        touchZoom: false,
        touchRotate: false,
        keyboard: false,
        doubleClickZoom: false,
      }

  return (
    <MapProvider>
      <ReactGlMap
        ref={mapRef}
        cursor={cursor}
        id={id}
        initialViewState={initialView}
        interactiveLayerIds={tooltipLayers}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={mapStyle}
        onClick={(event) => {
          onLayerClick(event.features ?? [], mapRef)
        }}
        onLoad={() => {
          onMapLoad(mapRef)
        }}
        onMouseEnter={onHover}
        onMouseLeave={resetTooltip}
        onMoveEnd={() => {
          onMoveEndAction(mapRef)
        }}
        onStyleData={() => {
          onStyleDataAction(mapRef)
        }}
        onZoomEnd={() => {
          onZoomEndAction(mapRef)
        }}
        preserveDrawingBuffer={preserveDrawingBuffer}
        {...zoomMoveProps}
        {...rest}
      >
        {children}
        {hoverInfo && (
          <MapTooltip
            {...hoverInfo}
            details={tooltipDetails}
            title={tooltipTitle}
          />
        )}
      </ReactGlMap>
    </MapProvider>
  )
}

export { Map as SynopMap }
