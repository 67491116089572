import { datadogRum } from '@datadog/browser-rum'
import { Outlet } from 'react-router-dom'
import {
  setPrincipalOrganization,
  useCurrentUser,
  usePrincipalOrgId,
} from '@synop-react/api'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

export type AuthRouteState = {
  preAuthRoute?: string
}

const AuthenticatedRoutes = () => {
  const dispatch = useDispatch()

  const { user, synopUser } = useCurrentUser()

  const principalOrgId = usePrincipalOrgId()
  useEffect(() => {
    try {
      if (user.username && user.attributes) {
        datadogRum.setUser({
          id: user.username,
          email: user.attributes.email,
          name: user.attributes['name'],
          organization: user.attributes['custom:fleetName'],
        })
        datadogRum.startSessionReplayRecording()
      } else {
        datadogRum.setUser({})
        datadogRum.stopSessionReplayRecording()
      }
    } catch (error) {
      console.log('Error authenticating user', error)
    }
  }, [user])

  // Set the Principal Org on login
  useEffect(() => {
    if (synopUser?.organizationId !== principalOrgId)
      dispatch(setPrincipalOrganization({ id: synopUser?.organizationId }))
  }, [synopUser, principalOrgId, dispatch])

  return <Outlet />
}

export default AuthenticatedRoutes
