import {
  CoordinateSourceAttribute,
  DistanceSourceAttribute,
  EfficiencySourceAttribute,
  EmissionsSourceAttribute,
  EnergySourceAttribute,
  FuelSourceAttribute,
  PercentSourceAttribute,
  TimeSourceAttribute,
} from './sourceAttribute'
import { DistanceUnit, EfficiencyUnit, EmissionsUnit, RootAPI } from '..'
import { Entity } from '@synop-react/types'

export const vehicleStatuses = ['Off', 'Charging', 'In Motion', 'Idle'] as const
export type VehicleStatus = (typeof vehicleStatuses)[number]

export type Trip = Entity<RootAPI.Trip> & {
  distanceUnit: DistanceUnit
  efficiencyUnit: EfficiencyUnit
  emissionsUnit: EmissionsUnit
}

// TODO move chart types to common with charts
export type ChartValues = RootAPI.ChartDataResponseModel

export type SocChartResponse = {
  /*
  Currently we expect an array of strings that looks like:
  ['timestamp', 'percent']
  */
  units: string[]
  /*
  Similarly, we expect an array of datapoints that look like:
    ['1664430524854', 65]
  */
  data: SocChartValue[]
}

export type SocChartValue = [string, number] | []

export type VehicleStatusSourceAttribute = {
  value: VehicleStatus
} & Omit<RootAPI.VehicleModel['vehicleStatus'], 'value'>

export type VehicleModel = {
  id: string
  distanceUntilHome: DistanceSourceAttribute
  stateOfCharge: PercentSourceAttribute
  remainingRange: DistanceSourceAttribute
  vehicleStatus: VehicleStatusSourceAttribute | null
  timeUntilHome: TimeSourceAttribute
  latitude: CoordinateSourceAttribute
  longitude: CoordinateSourceAttribute
  remainingChargeTime: TimeSourceAttribute | null
  odometer: DistanceSourceAttribute
} & Omit<RootAPI.VehicleModel, 'id'>

export type VehicleMetrics = {
  carbonEmissionsSaved: EmissionsSourceAttribute
  distanceDriven: DistanceSourceAttribute
  efficiency: EfficiencySourceAttribute
  energyAdded: EnergySourceAttribute
  energyUsed: EnergySourceAttribute
  fuelSaved?: FuelSourceAttribute
} & Omit<
  RootAPI.VehicleMetricsModel,
  | 'carbonEmissionsSaved'
  | 'distanceDriven'
  | 'efficiency'
  | 'energyAdded'
  | 'energyUsed'
>

export type VehicleStateSummary = keyof RootAPI.TimeUtilization
export type VehicleEnergyUseSummary = keyof RootAPI.EnergyUtilization

// Format of the JSON file downloaded from the Report endpoint
// TODO Get this added to API Spec @wslater
export type VehicleActivityReport = {
  carbonEmissionsSaved: EmissionsSourceAttribute
  distanceDriven: DistanceSourceAttribute
  efficiency: EfficiencySourceAttribute
  energyAdded: EnergySourceAttribute
  energyUsed: EnergySourceAttribute
}
