import {
  DASHBOARD_MAX_DATE_RANGE,
  formatVehicleName,
  getFieldColumns,
  TableCask,
  useFleetSelector,
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
  useTimeRange,
  useValidate,
} from '@synop-react/common'
import { Grid, Skeleton } from '@mui/material'
import { RootAPI, useOrgVehicles } from '@synop-react/api'
import { useMemo } from 'react'
import {
  useVehicleFields,
  useVehicleMetricsFields,
  VehicleMetrics,
} from '../../Reports/fields'

const { useGetTimespanDataForVehiclesQuery } = RootAPI

export const VehicleSummaryTable = () => {
  const { from, to } = useTimeRange()
  const orgId = useSelectedOrgIdOrCurrent()
  const { selected: selectedFleet } = useFleetSelector()
  const { validateTimeRange } = useValidate()

  const isValidTimeRange = validateTimeRange(DASHBOARD_MAX_DATE_RANGE).isValid
  const { data: vehiclesSummary = [], isFetching: isFetchingSummary } =
    useGetTimespanDataForVehiclesQuery(
      {
        from: from.toISOString(),
        to: to.toISOString(),
        organizationId: orgId,
        fleetId: selectedFleet?.id || undefined,
      },
      {
        skip: !orgId || !isValidTimeRange,
      }
    )

  const { vehicleName } = useVehicleFields<VehicleMetrics>('id')
  const {
    totalTrips,
    carbonEmissionsSaved,
    distanceDriven,
    drivingDays,
    efficiency,
    energyUsed,
    fuelSaved,
  } = useVehicleMetricsFields(vehiclesSummary[0])

  const siteLookup = useSiteSelector().lookup
  const {
    lookup: vehicleLookup,
    orgVehicles,
    isLoading: isLoadingVehicles,
  } = useOrgVehicles()

  const { tableColumns, csvColumns } = useMemo(
    () =>
      getFieldColumns(
        vehicleName,
        {
          csv: [
            'id',
            'organization',
            (id: string) => vehicleLookup(id)?.fleetNm ?? '-',
          ],
        },
        {
          csv: [
            'id',
            'site',
            (id: string) =>
              siteLookup(vehicleLookup(id)?.homeSiteId)?.depotNm ?? '-',
          ],
        },
        totalTrips,
        distanceDriven,
        fuelSaved,
        carbonEmissionsSaved,
        efficiency,
        energyUsed,
        drivingDays,
        {
          csv: [
            'id',
            'timezone',
            (id: string) => siteLookup(vehicleLookup(id)?.homeSiteId)?.timezone,
          ],
        }
      ),
    [
      vehicleName,
      totalTrips,
      distanceDriven,
      fuelSaved,
      carbonEmissionsSaved,
      efficiency,
      energyUsed,
      drivingDays,
      siteLookup,
      vehicleLookup,
    ]
  )

  const summarySearchFilter: TableCask.EntitySearchFilter<VehicleMetrics> =
    (vehicleSummaries, setVisibleSummaries) => (searchString) => {
      if (!searchString) {
        setVisibleSummaries(vehicleSummaries)
        return
      }
      const formattedSearchString = searchString.toLowerCase()
      const matchingEntities = vehicleSummaries.filter(({ id }) => {
        const vehicle = orgVehicles[id]

        if (!vehicle) return false

        const { vin: maybeVin } = vehicle

        const vehicleName = formatVehicleName(vehicle).toLowerCase()
        const vin = (maybeVin ?? '').toLowerCase()

        return (
          vehicleName.includes(formattedSearchString) ||
          vin.includes(formattedSearchString)
        )
      })
      setVisibleSummaries(matchingEntities)
    }

  // Org Vehicles need to load before the table can be rendered so the column definitions will have the vehicles to resolve vehicle name
  return isLoadingVehicles || isFetchingSummary ? (
    <Skeleton height="700px" variant="rectangular" width="100%" />
  ) : (
    <Grid item sx={{ width: '100%' }} xs={12}>
      <TableCask.ClientSide
        columns={tableColumns}
        csvColumns={csvColumns}
        downloadable
        getRowId={(row) => row.id}
        initialSortColumn="id"
        searchable
        searchFilter={summarySearchFilter}
        tableData={vehiclesSummary as VehicleMetrics[]}
        title="Vehicle Activity"
      />
    </Grid>
  )
}
