import { Entity, EntityIdMap } from '@synop-react/types'
import { EntitySorter } from '../../../..'
import { EntityState } from './useEntityReducer'

export type BrowserSelector<R> = (reducerState: EntityState<Entity>) => R

export const selectDisplayedEntities: BrowserSelector<EntityIdMap<Entity>> = ({
  visibleEntityIds,
  allEntities,
}) =>
  Object.entries(visibleEntityIds).reduce((acc, [id, isDisplayed]) => {
    if (isDisplayed) return Object.assign(acc, { [id]: allEntities[id] })
    return acc
  }, {} as EntityIdMap<Entity>)

export const selectDisplayedOrderedEntities: BrowserSelector<Entity[]> = ({
  visibleEntityIds,
  allEntities,
}): Entity[] => {
  return Object.entries(visibleEntityIds)
    .filter(([, isDisplayed]) => isDisplayed)
    .flatMap(([id]) => {
      const entity = allEntities[id]
      return entity ? [entity] : []
    })
}

export const selectAllOrderedEntities: BrowserSelector<Entity[]> = ({
  entityIdSortOrder,
  allEntities,
}): Entity[] => {
  return entityIdSortOrder.flatMap((id) => {
    const entity = allEntities[id]
    return entity ? [entity] : []
  })
}

export const selectActiveSorters: BrowserSelector<EntitySorter[]> = ({
  entitySorter,
  currentSorters,
}) => {
  return currentSorters
    .filter((sorter) => sorter !== '')
    .flatMap((sorter) => {
      const s = entitySorter[sorter]
      return s ? [s] : []
    })
}

export const selectSelectedEntity: BrowserSelector<Entity | null> = ({
  allEntities,
  selectedEntityId,
}) => {
  if (!selectedEntityId) return null
  return allEntities[selectedEntityId] ?? null
}
