import { FieldError, useFormContext } from 'react-hook-form'
import { InfoTooltip } from '../../Tooltip'
import { TextFieldProps } from '@mui/material'
import TextFormField from '../TextFormField'

type WrappedTextFormFieldProps = {
  id: string
  helperText?: string
  rows?: number
  tooltip?: string
} & Omit<TextFieldProps, 'error' | 'value' | 'defaultValue' | 'helperText'>

export const WrappedTextFormField = ({
  id,
  label,
  helperText = '',
  rows,
  tooltip,
  ...textFieldProps
}: WrappedTextFormFieldProps) => {
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext()

  return (
    <TextFormField
      control={control}
      error={errors[id] as FieldError}
      fullWidth
      helperText={helperText}
      id={id}
      InputProps={{
        ...textFieldProps.InputProps,
        endAdornment: (
          <>
            <InfoTooltip description={tooltip} />
            {textFieldProps.InputProps?.endAdornment}
          </>
        ),
      }}
      label={label}
      rows={rows}
      touched={Boolean(touchedFields[id])}
      {...textFieldProps}
    />
  )
}
