import { Icon } from 'react-feather'
import { IconButton, Stack, styled, Typography } from '@mui/material'

import { X } from '../../Icons'

export interface OverlayHeaderProps {
  title: string
  Icon?: Icon
  handleClose: () => void
  pill?: React.ReactNode
}

export function OverlayHeader({
  Icon,
  title,
  handleClose,
  pill,
}: OverlayHeaderProps) {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ pl: 3, pt: 2 }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <OverlayHeaderIcon Icon={Icon} />
        <Typography sx={{ flex: 1 }} variant="h4">
          {title}
        </Typography>
        {pill}
      </Stack>
      <IconButton
        data-cy="close-overlay"
        onClick={handleClose}
        size="large"
        sx={{ cursor: 'pointer' }}
      >
        <X fontSize="small" />
      </IconButton>
    </Stack>
  )
}

function OverlayHeaderIcon({ Icon }: { Icon: Icon | undefined }) {
  if (!Icon) return null

  const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette['text'].primary,
    strokeWidth: 2,
  }))

  return <StyledIcon />
}

export default OverlayHeader
