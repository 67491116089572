import {
  ChargingTransactionReportRow,
  RootAPI,
  useCurrentUser,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { collectionToCSV, useReport, UseReportProps } from '@synop-react/common'
import { getFieldColumns } from '@synop-react/common'
import { useChargingTransactionsReportFields } from './fields'
import { useMemo } from 'react'
import dayjs from 'dayjs'

const { useGetTransactionsReportQuery } = RootAPI.synopRootAPI

export type UseChargingTransactionsReportProps = Omit<
  UseReportProps<
    RootAPI.GetTransactionsReportApiArg,
    RootAPI.ReportGenerationStatusModel
  >,
  'useQuery' | 'reportType'
>

export const useChargingTransactionsReport = ({
  skip,
  queryArgs,
}: UseChargingTransactionsReportProps) => {
  const { tzDayjs } = useUserPrefs()
  const { orgVehicles } = useOrgVehicles()

  const { report = [], ...reportStatus } = useReport<
    RootAPI.GetTransactionsReportApiArg,
    RootAPI.ReportGenerationStatusModel,
    ChargingTransactionReportRow
  >({
    useQuery: useGetTransactionsReportQuery,
    queryArgs,
    skip,
    reportType: 'CHARGING_TRANSACTIONS',
    pollingInterval: 1000, // 1 second, this is shorter bc this report is relatively faster
  })
  const { synopUser, isAdmin } = useCurrentUser()

  const vehiclesByMacAddress = useMemo(() => {
    const vehiclesByMacAddress: Record<string, RootAPI.VehicleModel> = {}

    Object.values(orgVehicles).forEach((vehicle) => {
      if (!vehicle.macAddress) return

      vehiclesByMacAddress[vehicle.macAddress] = vehicle
    })

    return vehiclesByMacAddress
  }, [orgVehicles])

  const reportData = useMemo(
    () =>
      report.reduce<ChargingTransactionReportRow[]>((acc, transactionRow) => {
        const rowJoinData: Partial<ChargingTransactionReportRow> = {}
        const { ocppTag, ...restActivity } = transactionRow

        // Add duration in seconds
        const startTime = tzDayjs(transactionRow.startTimestamp)
        const endTime = tzDayjs(transactionRow.stopTimestamp || tzDayjs())
        const durationSeconds = dayjs
          .duration(endTime.diff(startTime))
          .asSeconds()

        // Add vehicle data
        if (ocppTag) {
          if (vehiclesByMacAddress[ocppTag]) {
            const vehicle = vehiclesByMacAddress[ocppTag]

            rowJoinData.vehicleId = vehicle?.id
            rowJoinData.vehicleName = vehicle?.vehicleNm
            rowJoinData.vin = vehicle?.vin
          }
        }

        // Verify the user is either an admin or has access ot see this site
        if (
          isAdmin ||
          (synopUser &&
            synopUser.sites &&
            synopUser.sites.length > 0 &&
            synopUser.sites.indexOf(transactionRow.depotId) > -1)
        ) {
          acc.push({
            ...restActivity,
            ...rowJoinData,
            ocppTag,
            durationSeconds,
          })
        }

        return acc
      }, []),
    [report, vehiclesByMacAddress, tzDayjs, isAdmin, synopUser]
  )

  /*
  TODO: Removing for now, as we don't have access to a vehicle ID
  const { vehicleName, vin } = useVehicleFields('assetId')
   */

  const {
    siteName,
    chargerName,
    transactionId,
    connectorId,
    ocppTag,
    vin,
    startTimestamp,
    stopTimestamp,
    energyImported,
    averagePower,
    maxPower,
    startSoc,
    endSoc,
    duration,
  } = useChargingTransactionsReportFields()

  const { csvColumns, tableColumns } = useMemo(
    () =>
      getFieldColumns(
        siteName,
        chargerName,
        connectorId,
        transactionId,
        /*
        TODO: Removing for now, as we don't have access to a vehicle ID
        vehicleName,
         */
        vin,
        ocppTag,
        startTimestamp,
        stopTimestamp,
        duration,
        energyImported,
        averagePower,
        maxPower,
        startSoc,
        endSoc
      ),
    [
      /*
      TODO: Removing for now, as we don't have access to a vehicle ID
      vehicleName,
       */
      vin,
      chargerName,
      transactionId,
      siteName,
      connectorId,
      ocppTag,
      startTimestamp,
      stopTimestamp,
      duration,
      energyImported,
      averagePower,
      maxPower,
      startSoc,
      endSoc,
    ]
  )

  const csvBlob = useMemo(() => {
    const eventCSV = collectionToCSV(csvColumns)(reportData)
    return new Blob([eventCSV], { type: 'text/csv' })
  }, [csvColumns, reportData])

  const { from, to } = queryArgs
  const csvOption = {
    extension: '.csv' as const,
    blob: csvBlob,
    filename: `CHARGING_TRANSACTIONS_${tzDayjs(from).format()}_${tzDayjs(
      to
    ).format()}`,
  }

  return {
    data: reportData,
    columns: tableColumns,
    ...reportStatus,
    csvOption: csvOption,
  }
}
