import { Box, Tab, Tabs } from '@mui/material'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import React, { useState } from 'react'

export type TabCaskViewProps = {
  tabItems: TabCaskItems[]
}

type TabCaskItems = {
  label: string
  content: EmotionJSX.Element
}

export function TabCaskView({ tabItems }: TabCaskViewProps) {
  const [visibleTab, setVisibleTab] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setVisibleTab(newValue)
  }

  const tabs = tabItems.map((tab) => {
    return <Tab key={tab.label} label={tab.label} />
  })

  const tabViews = tabItems.map((tab, index) => {
    return (
      <TabCask key={tab.label} index={index} value={visibleTab}>
        {tab.content}
      </TabCask>
    )
  })

  return (
    <Box>
      <Tabs onChange={handleChange} value={visibleTab}>
        {tabs}
      </Tabs>
      {tabViews}
    </Box>
  )
}

type TabCaskProps = {
  children?: EmotionJSX.Element
  index: number
  value: number
}

function TabCask({ children, value, index }: TabCaskProps) {
  if (value === index) return <div>{children}</div>
  else return <div />
}
