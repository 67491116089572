import { Controller, FieldError, useFormContext } from 'react-hook-form'
import { Dayjs } from 'dayjs'
import {
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from '@mui/x-date-pickers'
import { TextFieldProps } from '@mui/material'
import dayjs from 'dayjs'

import { useUserPrefs } from '@synop-react/api'

export type TimePickerProps = {
  id: string
  error?: FieldError
  touchedField?: boolean
  variant?: TextFieldProps['variant']
  helperText?: string
} & Partial<MuiTimePickerProps<Dayjs>>

export const TimePicker = function ({
  id,
  variant = 'standard',
  helperText,
  ...timePickerProps
}: TimePickerProps) {
  const { preferredTimeFormat } = useUserPrefs()
  const error = timePickerProps.error != undefined
  const timeFormat =
    preferredTimeFormat === 'hh:mm' ? 'hh:mm a' : preferredTimeFormat

  return (
    <Controller
      control={useFormContext().control}
      name={id}
      render={({ field: { value, ...field }, fieldState }) => (
        <MuiTimePicker
          {...field}
          {...timePickerProps}
          {...fieldState}
          ampm={preferredTimeFormat === 'hh:mm'}
          format={`${timeFormat}`}
          slotProps={{
            textField: {
              id,
              variant,
              sx: { width: '100%' },
              error: error,
              helperText: fieldState.error?.message
                ? fieldState.error?.message
                : helperText,
            },
          }}
          value={dayjs(value)}
        />
      )}
    />
  )
}
