import { simpleSelectFactory } from './SimpleSelect'

const networkConnectionMethod = ['SIM', 'WiFi', 'LAN'] as const
export type NetworkConnectionMethod = (typeof networkConnectionMethod)[number]
const installationType = ['Pedestal', 'WallMounted', 'Other'] as const
export type InstallationType = (typeof installationType)[number]
const adaAccessibleOptions = ['Yes', 'No'] as const
export type AdaAccessibleOptions = (typeof adaAccessibleOptions)[number]
const connectorType = [
  'J1772',
  'CHAdeMO',
  'CCS1',
  'CCS2',
  'NACS',
  'Other',
] as const
export type ConnectorType = (typeof connectorType)[number]

export const NetworkConnectionMethodSelect =
  simpleSelectFactory<NetworkConnectionMethod>([...networkConnectionMethod])

export const InstallationTypeSelect = simpleSelectFactory<InstallationType>([
  ...installationType,
])

export const AdaAccessibleSelect = simpleSelectFactory<AdaAccessibleOptions>([
  ...adaAccessibleOptions,
])

export const ConnectorTypeSelect = simpleSelectFactory<ConnectorType>([
  ...connectorType,
])
