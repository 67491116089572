import { Box, Grid, Stack, useTheme } from '@mui/material'

import {
  ChargerFaultsTable,
  DetailPageHeader,
  OrgSelector,
  OrgSelectorsProvider,
  SiteSelector,
  StatusColorProvider,
  TabCaskView,
  useFormat,
  useMinuteTimer,
} from '@synop-react/common'

import { ChargersTable } from './ChargersTable'
import { ToggleableStatusSummaryCharts } from '@synop-react/depot'

// Span of time in seconds between updates of the chargers overview page. The
// updates are to the chargers table and the status summary charts.
const UPDATE_INTERVAL_SECONDS = 60

export default function ChargersOverviewPage() {
  const theme = useTheme()

  return (
    <OrgSelectorsProvider>
      <StatusColorProvider>
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
          <Stack spacing={theme.spacing(2)} sx={{ width: 1152 }} useFlexGap>
            <ChargersOverviewHeader />
            <ToggleableStatusSummaryCharts />
            <TabCaskView
              tabItems={[
                {
                  label: 'Chargers',
                  content: (
                    <ChargersTable updateInterval={UPDATE_INTERVAL_SECONDS} />
                  ),
                },
                {
                  label: 'Faults & Warnings',
                  content: (
                    <ChargerFaultsTable
                      updateInterval={UPDATE_INTERVAL_SECONDS}
                    />
                  ),
                },
              ]}
            />
          </Stack>
        </Box>
      </StatusColorProvider>
    </OrgSelectorsProvider>
  )
}

const ChargersOverviewHeader = () => {
  const theme = useTheme()
  const { formatDateTime } = useFormat()
  const subtitle = formatDateTime(useMinuteTimer(UPDATE_INTERVAL_SECONDS), {
    dropLeadingZero: true,
    invalidDateValue: '',
  }).asTimeOnDate

  return (
    <DetailPageHeader
      alignChildren="center"
      subtitle={subtitle}
      title="Chargers"
    >
      <Grid container justifyContent="flex-end" spacing={theme.spacing(2)}>
        <Grid item>
          <OrgSelector />
        </Grid>

        <Grid item>
          <SiteSelector />
        </Grid>
      </Grid>
    </DetailPageHeader>
  )
}
