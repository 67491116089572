import { useMemo } from 'react'

import { ChargerEntity } from '../@types'
import { EMPTY_ENTITY_MAP, parseToEntityMap } from '../api/utils'
import { EntityMap } from '@synop-react/types'
import { RootAPI, useCurrentOrgId } from '..'

const { useGetChargersQuery } = RootAPI.synopRootAPI

interface UseDepotChargersResponse {
  depotChargers: EntityMap<ChargerEntity>
  totalItems: number
  isLoading: boolean
  isSuccess: boolean
}

type UseDepotChargersOptions = {
  page?: number
  count?: number
}

const defaultDepotChargersOptions: UseDepotChargersOptions = {
  page: 0,
  // Bumping this up to 1000 to avoid issues calculating
  // the total number of chargers and connectores in the site
  // TODO: Backend to add totalConnectors to response
  count: 1000,
}

export const useDepotChargers = (
  siteId = '',
  { page, count } = defaultDepotChargersOptions
): UseDepotChargersResponse => {
  const organizationId = useCurrentOrgId()

  const {
    isSuccess,
    isLoading,
    data: depotChargers,
  } = useGetChargersQuery(
    {
      siteIds: [siteId],
      organizationId: [organizationId],
      page,
      count,
    },
    {
      skip: !siteId || !organizationId,
    }
  )

  const parsedChargers = useMemo(() => {
    return isSuccess && depotChargers?.items?.length
      ? parseToEntityMap<RootAPI.ChargerModel, ChargerEntity>(
          depotChargers.items,
          'chargerId'
        )
      : (EMPTY_ENTITY_MAP as EntityMap<ChargerEntity>)
  }, [isSuccess, depotChargers])

  return {
    depotChargers: parsedChargers,
    totalItems: depotChargers?.totalItems || 0,
    isLoading,
    isSuccess,
  }
}
