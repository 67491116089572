import { StatusCask } from '@synop-react/common'
import calculateSocColor from '../../utils/calculators/calculateSocColor'

export interface VehicleSoCDetailProps {
  soc?: number
}

export function VehicleSoCDetail({ soc }: VehicleSoCDetailProps) {
  const parsedSoC = soc ? `${Math.round(soc)}%` : 'n/a'
  return (
    <StatusCask.Item
      label="State of Charge"
      progress={{ value: soc || 0, color: calculateSocColor(soc as number) }}
      value={parsedSoC}
    />
  )
}

export default VehicleSoCDetail
