import {
  ClickAwayListener,
  Grid,
  Input,
  InputAdornment,
  InputProps,
  styled,
} from '@mui/material'
import { Search } from '../Icons'
import React, { ChangeEvent, useState } from 'react'

export type SearchInputProps = {
  filterFn: (searchString: string) => void
  isDefaultOpen?: boolean
  isAlwaysOpen?: boolean
} & InputProps

export function SearchInput({
  filterFn,
  isDefaultOpen = false,
  isAlwaysOpen = false,
  ...restProps
}: SearchInputProps) {
  const [searchString, setSearchString] = useState<string>('')
  const [isOpen, setIsOpen] = useState(!isDefaultOpen)

  const handleClose = () => {
    if (!searchString) {
      setIsOpen(false)
    }
  }

  const toggleExpanded = () => setIsOpen(!isOpen)

  const onInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputString = event.target.value
    setSearchString(inputString)
    filterFn(inputString)
  }

  const shouldShowInput = isOpen || isAlwaysOpen

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Grid item xs="auto">
        {!shouldShowInput && <StyleSearchButton onClick={toggleExpanded} />}

        {shouldShowInput && (
          <Input
            onChange={onInputChange}
            value={searchString}
            {...restProps}
            endAdornment={
              <InputAdornment position="end">
                <Search onClick={toggleExpanded} />
              </InputAdornment>
            }
          />
        )}
      </Grid>
    </ClickAwayListener>
  )
}

export default SearchInput

const StyleSearchButton = styled(Search)`
  cursor: pointer;
`
