import { FieldError, useFormContext } from 'react-hook-form'
import { FormField } from '@synop-react/common'
import { InputAdornment } from '@mui/material'

type SetRateInputProps = { id: string; disabled?: boolean }

export function SetRateInput({ disabled, id }: SetRateInputProps) {
  const formMethods = useFormContext()

  const { errors, touchedFields } = formMethods.formState

  return (
    <FormField.TextFormField
      disabled={disabled}
      error={errors[id] as FieldError}
      id={id}
      InputProps={{
        endAdornment: '/kWh',
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      // eslint thinks `inputProps` is the same as `InputProps` but it's not...
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        pattern: '[0-9]*',
        inputMode: 'numeric',
        type: 'number',
        min: '0',
        max: '9.99',
        step: '0.01',
      }}
      label="Set Rate"
      sx={{ minWidth: '96px' }}
      touched={Boolean(touchedFields[id])}
      {...formMethods}
    />
  )
}
