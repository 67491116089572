import { Box, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium'
import { ReactElement, ReactNode, useEffect, useMemo } from 'react'

import { Cask } from '../Cask'
import { collectionToCSV, CsvColumnSet } from '../utils'
import { DateRange } from '../DateRange'
import { DownloadLink } from '../DownloadLink'
import { EntitySearchFilter, TableCaskControls, TableCaskProps } from './types'
import { Icons } from '../Icons'
import { Table } from '../Table'
import { TableSearch } from './TableSearch'
import { VisibilityOverlay } from './VisibilityOverlay'

type BaseTableCaskProps<T extends GridValidRowModel> = TableCaskProps<T> & {
  children: ReactElement
  visibleColumns: Table.ColumnSpec<T>
  setVisibleColumns: (columns: Table.ColumnSpec<T>) => void

  visibleData: T[]
  setVisibleData: (data: T[]) => void
}

export function BaseTableCask<T extends GridValidRowModel>({
  children,
  columns,
  csvColumns,
  downloadable = false,
  downloadTooltip: DownloadOverlayMessage,
  editableColumns = false,
  searchable = false,
  searchFilter,
  setVisibleColumns,
  setVisibleData,
  showDateRange = false,
  tableData,
  title = '',
  downloadTitle = title,
  visibleColumns,
  visibleData,
  rightOtherActions = <></>,
  leftOtherActions = <></>,
  ...caskProps
}: BaseTableCaskProps<T>) {
  // Update the visible data if new data is passed in
  useEffect(() => {
    setVisibleData(tableData)
  }, [setVisibleData, tableData])

  return (
    <Cask
      Actions={
        <TableCaskToolbar
          csvColumns={csvColumns}
          downloadable={downloadable}
          downloadTitle={downloadTitle}
          downloadTooltip={DownloadOverlayMessage}
          editableColumns={editableColumns}
          leftOtherActions={leftOtherActions}
          rightOtherActions={rightOtherActions}
          searchable={searchable || !!searchFilter}
          searchFilter={searchFilter}
          setVisibleColumns={setVisibleColumns}
          setVisibleData={setVisibleData}
          tableData={tableData}
          title={title}
          visibleColumns={visibleColumns}
          visibleData={visibleData}
        />
      }
      {...caskProps}
      Controls={showDateRange ? <DateRange /> : null}
      title={title}
    >
      {children}
    </Cask>
  )
}

type TableCaskToolbarProps<T extends GridValidRowModel> = {
  searchable: boolean
  downloadable: boolean
  csvColumns?: CsvColumnSet<T>
  downloadTooltip?: ReactNode
  title: string
  downloadTitle?: string
  visibleData: T[]
  visibleColumns: Table.ColumnSpec<T>
  setVisibleData: (data: T[]) => void
  setVisibleColumns: (columns: Table.ColumnSpec<T>) => void
  tableData: T[]
  leftOtherActions?: ReactNode
  rightOtherActions?: ReactNode
} & TableCaskControls<T>

function TableCaskToolbar<T extends GridValidRowModel>({
  title,
  downloadTooltip: DownloadOverlayMessage,
  downloadTitle = title,
  searchable,
  editableColumns,
  downloadable,
  visibleData,
  visibleColumns,
  setVisibleData,
  setVisibleColumns,
  searchFilter,
  tableData,
  csvColumns,
  rightOtherActions = <></>,
  leftOtherActions = <></>,
}: TableCaskToolbarProps<T>) {
  const filterTableFn = useMemo(() => {
    const searchFn = searchFilter || filterByTableColumns(visibleColumns)
    return searchFn(tableData, setVisibleData)
  }, [searchFilter, setVisibleData, visibleColumns, tableData])

  const tableCsvFormat =
    csvColumns != null ? csvColumns : visibleColumns.map((col) => col.field)

  const tableCsvBlob = useMemo(() => {
    if (!downloadable) return
    const eventCSV = collectionToCSV(tableCsvFormat)(visibleData)
    return new Blob([eventCSV], { type: 'text/csv' })
  }, [downloadable, tableCsvFormat, visibleData])

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        {leftOtherActions}
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <>
          {searchable && (
            <TableSearch onChange={(s) => filterTableFn(s ?? '')} />
          )}
          {editableColumns && (
            <VisibilityOverlay
              columns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
            />
          )}
          {tableCsvBlob && (
            <Grid item>
              <DownloadLink blob={tableCsvBlob} filename={downloadTitle}>
                <Tooltip arrow placement="top" title={DownloadOverlayMessage}>
                  <IconButton aria-label="Download">
                    <Icons.Download />
                  </IconButton>
                </Tooltip>
              </DownloadLink>
            </Grid>
          )}
          <Stack alignItems="center" direction="row" spacing={2}>
            {rightOtherActions}
          </Stack>
        </>
      </Box>
    </Box>
  )
}

function filterByTableColumns<T>(columns: GridColDef[]): EntitySearchFilter<T> {
  return (entities, setVisibleEntities) => (searchString) => {
    if (!searchString) {
      setVisibleEntities(entities)
      return
    }
    const formattedSearchString = searchString.toLowerCase()
    const matchingEntities = entities.filter((entity) =>
      columns.some(({ field, valueGetter }) => {
        // TODO: Edit the `GridColDef` type to force `field` to be keyof T
        const record = entity as Record<string, unknown>

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parameter = { row: record } as any

        let maybeSearchValue
        try {
          if (valueGetter) {
            maybeSearchValue = valueGetter(parameter)
          } else {
            maybeSearchValue = record[field]
          }
        } catch {
          maybeSearchValue = record[field]
        }

        const searchValue = ['string', 'number'].includes(
          typeof maybeSearchValue
        )
          ? String(maybeSearchValue).toLowerCase()
          : ''

        return searchValue.includes(formattedSearchString)
      })
    )
    setVisibleEntities(matchingEntities)
  }
}
