import { createTheme } from '@mui/material/styles'
import { linkComponents } from './links'
import { merge } from 'lodash'
import { typography, typographyComponents } from './typography'
import lightPalette, { lightPaletteComponents } from './palettes/lightPalette'

const synopTheme = createTheme({
  palette: lightPalette,
  components: merge(
    {},
    lightPaletteComponents,
    linkComponents,
    typographyComponents
  ),
  typography,
})

export type SynopTheme = typeof synopTheme

export default synopTheme
