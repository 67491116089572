import { RumInitConfiguration } from '@datadog/browser-rum'

export const getDatadogConfig = (
  env: string,
  appVersion: string
): RumInitConfiguration => {
  return {
    applicationId: 'e0092373-e9ff-4247-bdf1-556de092ac50',
    clientToken: 'pub18078480e52a94d7fa10e5a4543e17a5',
    site: 'datadoghq.com',
    service: 'synop-ui',
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    startSessionReplayRecordingManually: true,
  }
}
