import { muiSynopTheme } from '@synop-react/theme'
import { ThemeProvider, useTheme } from '@mui/material'
import convertComponentToSvg from '../../../utils/svg/convertToImage'

type PinColor = 'primary' | 'secondary' | 'error' | 'success' | 'warning'

export type MapPinProps = {
  color?: PinColor
  cursor?: string
}

// TODO: Fix how the height and viewbox work to resolve bug with map pin alignment on map. The
// selected pin and the map layer pin sometimes appear to not overlap entirely. There may be a
// few reasons why these don’t totally overlap when zoomed out, but they are very close when fully
// zoomed in. This may have to do with how the SDF icon is bounded / how we anchor this icon / how
// the Marker layer shifts as it zooms in.
export function MapPin({
  color = 'secondary',
  cursor = 'pointer',
}: MapPinProps) {
  const { palette } = useTheme()
  const pinColor = palette[color].main

  return (
    <svg
      height="43"
      style={{
        cursor,
        fill: pinColor,
      }}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.0711 2.92893C17.1957 1.05357 14.6522 0 12 0C9.34784 0 6.8043 1.05357 4.92893 2.92893C3.05357 4.8043 2 7.34784 2 10C2.0424 11.9206 2.56827 13.7995 3.529 15.463C4.41979 17.0463 5.51076 18.5084 6.775 19.813C8.19158 21.3077 9.75665 22.6543 11.446 23.832C11.6102 23.9414 11.8032 23.9998 12.0005 23.9998C12.1978 23.9998 12.3908 23.9414 12.555 23.832C14.2443 22.6543 15.8094 21.3077 17.226 19.813C18.4902 18.5084 19.5812 17.0463 20.472 15.463C21.4324 13.7994 21.9579 11.9205 22 10C22 7.34784 20.9464 4.8043 19.0711 2.92893Z" />
    </svg>
  )
}

export default MapPin

export function createMapPinImg(color?: PinColor, height = 34, width = 34) {
  return convertComponentToSvg(
    <ThemeProvider theme={muiSynopTheme}>
      <MapPin color={color} />
    </ThemeProvider>,
    height,
    width
  )
}
