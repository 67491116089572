import {
  AxisLabelFormatter,
  chargerDotConnector,
  CreateChargingSessionOverlay,
  defaultEntityName,
  EditChargingSessionOverlay,
  emDash,
  EventOption,
  EventOptions,
  formatSocFromSourceAttributes,
  TimelineCask,
  useAvailableOption,
  useDisplayedDateWithTimeRange,
  usePredictedEventOption,
  useScheduledChargeOption,
  useUnscheduledChargeOption,
  ViewChargingSessionOverlay,
} from '@synop-react/common'
import { Entity, EntityMap } from '@synop-react/types'
import {
  PercentSourceAttribute,
  RootAPI,
  useDepotChargers,
  useDepotDetailsFromPoll,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { useNavigate } from 'react-router-dom'

export interface VehiclesTimelineProps {
  depotId: string
  title?: string
  tooltip?: string
}

export function VehiclesTimeline({
  depotId,
  title = 'Site Schedule',
  tooltip = '',
}: VehiclesTimelineProps) {
  const navigate = useNavigate()
  const { tzDayjs } = useUserPrefs()

  const { displayedDate, setDisplayedDate, to, from } =
    useDisplayedDateWithTimeRange()

  const {
    getDepot: { data: depot },
    getScheduledChargerSessions: { parsedData: depotEvents },
  } = useDepotDetailsFromPoll({
    depotId,
    from,
    to,
    disablePolling: true,
  })

  const { orgVehicles, isSuccess: isVehiclesSuccess } = useOrgVehicles()

  const {
    depotChargers,
    isLoading: isLoadingChargers,
    isSuccess: isChargersSuccess,
  } = useDepotChargers(depotId)

  const vehicleEvents = Object.values(depotEvents.entities)

  // TODO Make sure this inclues all vehicles that have events at this depot as well @wslater
  const depotVehicles = Object.values(orgVehicles).reduce<
    EntityMap<Entity<RootAPI.VehicleModel>>
  >(
    (acc, vehicle) => {
      const { homeSiteId = '', id } = vehicle
      if (!id) return acc
      const isHomeDepot = homeSiteId && homeSiteId === depotId
      const hasDepotEvent = vehicleEvents.some((event) => event.assetId === id)
      if (isHomeDepot || hasDepotEvent) {
        acc.ids.push(id)
        acc.entities[id] = vehicle
      }
      return acc
    },
    { ids: [], entities: {} }
  )

  const isLoading = isLoadingChargers

  const isSuccess = isVehiclesSuccess && isChargersSuccess

  const scheduledChargeOptions = useScheduledChargeOption({
    depotId,
    subtitle: ({ chargerId, dispenserId }) => {
      const chargerName =
        depotChargers.entities[chargerId ?? '']?.chargerName ??
        defaultEntityName('Charger', chargerId)
      const connectorName = defaultEntityName('Connector', dispenserId)

      return chargerDotConnector(chargerName, connectorName)
    },
    renderOverlay: ({
      assetId = '',
      dispenserId,
      setIsOpen,
      isOpen,
      eventId,
      scheduledStart,
      scheduledEnd,
      chargerId = '',
      targetSoc,
      powerOffered,
    }) => {
      const eventCharger = depotChargers.entities[chargerId]
      const eventVehicle = orgVehicles[assetId]

      // Should never happen
      if (!eventCharger) return null

      const eventHasEnded = tzDayjs().isAfter(scheduledEnd)
      return !eventHasEnded ? (
        <EditChargingSessionOverlay
          defaultCharger={eventCharger}
          defaultConnector={{ connectorId: Number(dispenserId) }}
          defaultDepot={depot}
          defaultScheduledEnd={scheduledEnd}
          defaultScheduledStart={scheduledStart}
          defaultSoc={targetSoc}
          defaultTargetPower={powerOffered}
          defaultVehicle={eventVehicle}
          eventId={eventId as string}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <ViewChargingSessionOverlay
          charger={eventCharger}
          eventId={eventId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          vehicle={eventVehicle}
        />
      )
    },
  })
  const unscheduledChargeOption = useUnscheduledChargeOption({
    depotId,
    subtitle: ({ chargerId, dispenserId }) => {
      const chargerName =
        depotChargers.entities[chargerId ?? '']?.chargerName ??
        defaultEntityName('Charger', chargerId)
      const connectorName = defaultEntityName('Connector', dispenserId)

      return chargerDotConnector(chargerName, connectorName)
    },
  })
  const availableChargeOption = useAvailableOption({
    renderOverlay: ({
      dispenserId,
      setIsOpen,
      isOpen,
      scheduledEnd,
      chargerId = '',
      assetId,
    }) => {
      return tzDayjs(scheduledEnd).isAfter(tzDayjs()) ? (
        <CreateChargingSessionOverlay
          defaultCharger={depotChargers.entities[chargerId]}
          defaultConnector={{ connectorId: Number(dispenserId) }}
          defaultDepot={depot}
          defaultVehicle={assetId ? depotVehicles.entities[assetId] : null}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : null
    },
  })
  const predictedEventOption = usePredictedEventOption({
    subtitle: 'Predicted',
    renderOverlay: undefined,
  })

  const vehicleEventOptions: EventOptions = {
    ...scheduledChargeOptions,
    ...unscheduledChargeOption,
    ...availableChargeOption,
    ...predictedEventOption,
    SITE_LIMIT: {
      barColor: 'green',
      caption: { title: 'noop' },
    } as EventOption,
  }
  const formatVehicleLabel: AxisLabelFormatter = (vehicleId = '') => {
    const vehicle = depotVehicles.entities[vehicleId]
    const vehicleName =
      vehicle?.vehicleNm ?? defaultEntityName('Vehicle', vehicleId)

    const handleClick = vehicleId
      ? {
          onClick: () => {
            navigate(`/vehicles/${vehicleId}`)
          },
        }
      : {}

    const formattedSoc = formatSocFromSourceAttributes(
      vehicle?.stateOfCharge as PercentSourceAttribute
    )
    const socLabel = formattedSoc === emDash ? undefined : `${formattedSoc} SoC`

    return {
      primary: vehicleName,
      secondary: socLabel,
      ...handleClick,
    }
  }

  const filteredEvents = vehicleEvents.filter((event) =>
    depotVehicles.ids.some((id) => id === event.assetId)
  )

  return isSuccess ? (
    <TimelineCask
      displayedDate={displayedDate}
      eventIds={depotVehicles.ids}
      eventOptions={vehicleEventOptions}
      groupingKey="assetId"
      isLoading={isLoading}
      scheduledEvents={filteredEvents}
      setDisplayedDate={setDisplayedDate}
      title={title}
      tooltip={tooltip}
      yAxisLabelFormatter={formatVehicleLabel}
    />
  ) : null
}

export default VehiclesTimeline
