import * as chargers from './chargers'
import * as chargingSession from './chargingSession'
import * as depots from './depots'
import * as fleets from './fleets'
import * as notifications from './notifications'
import * as users from './users'
import * as utilization from './utilization'
import * as vehicles from './vehicles'

export default {
  chargers,
  chargingSession,
  depots,
  fleets,
  notifications,
  users,
  utilization,
  vehicles,
}
