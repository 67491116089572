import {
  Box,
  Divider,
  Paper,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

export interface TileProps {
  title: string
  isLoading?: boolean
  children: ReactNode
}

export function Tile({ title, isLoading = false, children }: TileProps) {
  const TileContent = isLoading ? (
    <Skeleton height="216px" variant="rectangular" width="100%" />
  ) : (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: '216px' }}
      >
        {children}
      </Stack>
    </>
  )

  return (
    <Stack
      component={TilePaper}
      direction="column"
      divider={<Divider />}
      justifyContent="center"
      justifyItems="center"
    >
      {TileContent}
      <Box
        sx={{
          height: '54px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>{title}</Typography>
      </Box>
    </Stack>
  )
}

export default Tile

const TilePaper = styled(Paper)(() => ({
  borderRadius: 0,
  width: '270px',
  height: '270px',
}))
