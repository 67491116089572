import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as ReduxProvider } from 'react-redux'
import { StrictMode, useEffect } from 'react'
import '@aws-amplify/ui-react/styles.css'

import { getEnvConfig } from '@synop-react/config'
import { muiSynopTheme } from '@synop-react/theme'
import { Snackbar } from '@synop-react/common'

import { AuthCustomizations } from './pages/Auth/AuthCustomizations'
import { datadogRum } from '@datadog/browser-rum'
import { getCustomAsset } from './utils'
import { MainErrorBoundary } from './components/MainErrorBoundary'
import { persistor, store } from './store'
import { Rollbar } from './components'
import AppRoutes from './appRoutes/AppRoutes'

window.global = window

const config = getEnvConfig()
Amplify.configure(config.amplify)
if (config.datadog) datadogRum.init(config.datadog)

const MUI_LICENSE_KEY =
  'd3c8d338b9f82bc575f0b02b2bf6ecc9Tz02MzUzOCxFPTE3MTIxODAyMDU3MDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <Rollbar>
    <StrictMode>
      <MainErrorBoundary>
        <CustomTitleAndFavicon />
        <BrowserRouter>
          <ScrollToTop />
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={muiSynopTheme}>
                <CssBaseline />
                <Authenticator components={AuthCustomizations} hideSignUp>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <AppRoutes />
                    <Snackbar />
                  </LocalizationProvider>
                </Authenticator>
              </ThemeProvider>
            </PersistGate>
          </ReduxProvider>
        </BrowserRouter>
      </MainErrorBoundary>
    </StrictMode>
  </Rollbar>
)

/** Scroll to page top on route change */
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function CustomTitleAndFavicon() {
  useEffect(() => {
    const customAsset = getCustomAsset()
    const { title, favicon } = customAsset ?? {}
    if (title) document.title = title
    if (favicon)
      document.getElementById('favicon')?.setAttribute('href', favicon)
  }, [])

  return null
}
