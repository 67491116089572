import * as yup from 'yup'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useState } from 'react'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  FormField,
} from '../../index'

export type RecurrenceTypeOption = { id: number | string; name: string }
export type RecurrenceTypeAutocompleteSelectProps = {
  label: string
} & AutocompleteTextFieldProps &
  Omit<AutocompleteFormControls<FieldValues, RecurrenceTypeOption>, 'control'>

const options = {
  SingleEvent: { id: 1, name: 'Single Event' },
  DailyRepeating: { id: 2, name: 'Daily Repeating' },
  WeeklyRepeating: { id: 3, name: 'Weekly Repeating' },
  WeekdaysRepeating: { id: 4, name: 'Weekdays Repeating' },
  CustomRepeating: { id: 5, name: 'Custom Repeating' },
}

export const RecurrenceTypeAutocompleteSelect = (
  props: RecurrenceTypeAutocompleteSelectProps
) => {
  const [isOpen, setIsOpen] = useState(false)
  const recurrenceTypeOptions: RecurrenceTypeOption[] = [
    options.SingleEvent,
    options.DailyRepeating,
    options.WeeklyRepeating,
    options.WeekdaysRepeating,
    options.CustomRepeating,
  ]

  return (
    <FormField.AutocompleteSelect
      control={useFormContext().control}
      defaultValue={props.defaultValue}
      getOptionLabel={(option: RecurrenceTypeOption) => `${option.name}`}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: RecurrenceTypeOption) => option.id}
      options={recurrenceTypeOptions}
      setIsOpen={setIsOpen}
      {...props}
    />
  )
}

const recurrenceTypeSelectSchema = yup
  .object({ name: yup.string().required() })
  .nullable()
  .required('Frequency is required')

export {
  options,
  RecurrenceTypeAutocompleteSelect as Select,
  recurrenceTypeSelectSchema as Schema,
}
