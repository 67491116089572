import { Typography, useTheme } from '@mui/material'
import Chart from 'react-apexcharts'

import { ApexUtilizationChartDatum } from '../Utilization/ApexUtilizationChart'
import { emDash } from '../../utils'
import { useUserPrefs } from '@synop-react/api'

export type TransactionChartProps = {
  seriesData: ApexUtilizationChartDatum[]
  title: string
  shortUnit: string
  longUnit: string
  color: string
}

export const TransactionChart = ({
  seriesData,
  title,
  shortUnit,
  longUnit,
  color,
}: TransactionChartProps) => {
  const { secondary } = useTheme().palette
  const { preferredTimeFormat } = useUserPrefs()

  const percentOverrides =
    longUnit === 'Percent'
      ? {
          tickAmount: 5,
          min: 0,
          max: 100,
        }
      : {}

  const droppedLeadingZero = `${preferredTimeFormat.replaceAll('hh', 'h')} tt`
  return (
    <>
      <Typography sx={{ textAlign: 'center' }} variant="subtitle2">
        {title}
      </Typography>
      <Chart
        height={234}
        options={{
          colors: [color],
          stroke: {
            width: 1,
          },
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            selection: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false,
              format: droppedLeadingZero,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            title: {
              text: longUnit,
              style: {
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 700,
                color: secondary['600'],
              },
            },
            labels: {
              formatter: (val) =>
                val ? parseFloat(val.toFixed(2)).toString() : emDash,
            },
            ...percentOverrides,
          },
          tooltip: {
            marker: {
              show: false,
            },
            y: {
              formatter: (val) =>
                `${
                  val?.toFixed(longUnit === 'Percent' ? 0 : 2) || emDash
                } ${shortUnit}`,
              title: {
                formatter: () => '',
              },
            },
            x: {
              format: `dd MMM yyyy ${droppedLeadingZero}`,
            },
          },
        }}
        series={[
          {
            data: seriesData,
          },
        ]}
        type="area"
      />
    </>
  )
}
