import { ChevronDown, ChevronUp } from '../../Icons'
import { Collapse, List } from '@mui/material'
import { ListItem, ListItemProps } from '../ListItem'
import { PropsWithChildren, ReactElement, ReactNode, useState } from 'react'
import React from 'react'

export interface NestedListItemProps {
  children: ReactElement[] | ReactElement
  icon: ReactNode
  text: string
  id?: string
  selectedItemId?: string
  setSelectedId?: (id: string) => void
  linkTo?: string
  startOpen?: boolean
}

export function NestedListItem({
  children,
  id = '0',
  icon,
  text,
  selectedItemId,
  setSelectedId = () => null,
  linkTo = '',
  startOpen = true,
}: NestedListItemProps) {
  const [open, setOpen] = useState(startOpen)
  const isSelected = `${id}` === selectedItemId
  const listItems = React.Children.map(
    children,
    (child: ReactElement<PropsWithChildren<ListItemProps>>, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          id: `${id}.${index.toString()}`,
          isNested: true,
          selectedItemId: selectedItemId,
          setSelectedId: setSelectedId,
        })
      }
      return child
    }
  )

  return (
    <>
      <ListItem
        icon={icon}
        linkTo={linkTo}
        onClick={() => {
          setSelectedId(id)
          setOpen(!open)
        }}
        selected={isSelected}
        text={text}
      >
        {open ? <ChevronUp /> : <ChevronDown />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">{listItems}</List>
      </Collapse>
    </>
  )
}

export default NestedListItem
