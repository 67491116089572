import {
  Chart,
  convertWattHours,
  convertWatts,
  PowerUnits,
  reduceWattHours,
  reduceWatts,
  useFormat,
} from '@synop-react/common'
import { EnergyUnit, RootAPI } from '@synop-react/api'
import { useMemo } from 'react'

const { useGetTransactionMeterValuesQuery } = RootAPI

type TransactionQueryValueData = {
  meterValueIsLoading: boolean
  currentImport: Chart.ApexUtilizationChartDatum[]
  currentImportUnit: string
  voltage: Chart.ApexUtilizationChartDatum[]
  voltageUnit: string
  powerActiveImport: Chart.ApexUtilizationChartDatum[]
  powerActiveImportUnit: PowerUnits
  powerActiveExport: Chart.ApexUtilizationChartDatum[]
  powerActiveExportUnit: PowerUnits
  energyActiveImport: Chart.ApexUtilizationChartDatum[]
  energyActiveImportUnit: EnergyUnit
  energyActiveExport: Chart.ApexUtilizationChartDatum[]
  energyActiveExportUnit: EnergyUnit
  vehicleSoc: Chart.ApexUtilizationChartDatum[]
  vehicleSocUnit: string
}

type TransactionQueryValues = {
  powerActiveImportUnit?: PowerUnits
  powerActiveExportUnit?: PowerUnits
  energyActiveImportUnit?: EnergyUnit
  energyActiveExportUnit?: EnergyUnit
} & Omit<
  TransactionQueryValueData,
  | 'meterValueIsLoading'
  | 'powerActiveImportUnit'
  | 'powerActiveExportUnit'
  | 'energyActiveImportUnit'
  | 'energyActiveExportUnit'
>

type TransactionQueryValueUnitKeys = keyof Omit<
  TransactionQueryValues,
  | 'currentImport'
  | 'voltage'
  | 'powerActiveImport'
  | 'powerActiveExport'
  | 'energyActiveImport'
  | 'energyActiveExport'
  | 'vehicleSoc'
>

type ProcessMeterValueKey = keyof Pick<
  RootAPI.TransactionMeterValuesModel,
  | 'currentImport'
  | 'voltage'
  | 'livePowerImport'
  | 'powerActiveExport'
  | 'energyActiveImportRegister'
  | 'energyActiveExportRegister'
  | 'soc'
>

type TransactionDataKey = keyof Pick<
  TransactionQueryValueData,
  | 'currentImport'
  | 'voltage'
  | 'powerActiveImport'
  | 'powerActiveExport'
  | 'energyActiveImport'
  | 'energyActiveExport'
  | 'vehicleSoc'
>

type PowerOrEnergy = 'power' | 'energy'
type TransactionDataUnitTypes = string | PowerUnits | EnergyUnit

export const useTransactionQueryValueData = (
  transactionId: string
): TransactionQueryValueData => {
  const { formatDateTime } = useFormat()
  const { data: meterValueData, isLoading: meterValueIsLoading } =
    useGetTransactionMeterValuesQuery({
      id: transactionId,
    })

  const {
    currentImportUnit,
    voltageUnit,
    powerActiveImportUnit,
    powerActiveExportUnit,
    energyActiveImportUnit,
    energyActiveExportUnit,
    vehicleSocUnit,
    ...rest
  } = useMemo(() => {
    // Default values, leaving unit of measure properties
    // undefined so we can dynamically set them below
    const transactionData: TransactionQueryValues = {
      currentImport: [],
      currentImportUnit: '',
      voltage: [],
      voltageUnit: '',
      powerActiveImport: [],
      powerActiveImportUnit: undefined,
      powerActiveExport: [],
      powerActiveExportUnit: undefined,
      energyActiveImport: [],
      energyActiveImportUnit: undefined,
      energyActiveExport: [],
      energyActiveExportUnit: undefined,
      vehicleSoc: [],
      vehicleSocUnit: '',
    }
    if (!meterValueData) return transactionData

    /**
     * Meter Value data is returned in reverse chronological order
     * so in order to display the data in chronological order
     * we use unshift to add the data to the beginning of the array
     *
     * We also use the first instance of data to set the unit of measure
     * for the chart, so if the first instance of data is smaller than
     * subsequent data we may end up showing a chart with a y-axis that
     * is Watts when it should probably be kW or MW, ect...
     * Todo: Would be nice to be able to find the mean unit of measure
     * for the data without having to loop through the entire array a
     * second time
     **/
    const processMeterValue = (
      meterValue: RootAPI.TransactionMeterValuesModel,
      meterValueKey: ProcessMeterValueKey,
      transactionDataKey: TransactionDataKey,
      type?: PowerOrEnergy
    ) => {
      if (!meterValue.measuredAt) return
      const x = formatDateTime(meterValue.measuredAt).fromDateTime.format(
        'YYYY-MM-DDTHH:mm:ss'
      )

      const currentMeterValue = meterValue[meterValueKey]
      const currentMeterValueUnit =
        type === 'power'
          ? meterValue[`${meterValueKey}Unit`]?.toUpperCase()
          : type === 'energy'
          ? meterValue[`${meterValueKey}Unit`]?.toLowerCase()
          : meterValue[`${meterValueKey}Unit`]
      const transactionDataUnitKey =
        `${transactionDataKey}Unit` as TransactionQueryValueUnitKeys

      if (!currentMeterValue || !currentMeterValueUnit) {
        transactionData[transactionDataKey].unshift({ x, y: 0 })
        return
      }

      if (!transactionData[transactionDataUnitKey]) {
        // Set the unit of measure for the chart
        ;(transactionData[transactionDataUnitKey] as TransactionDataUnitTypes) =
          type === 'power'
            ? reduceWatts(
                currentMeterValue,
                currentMeterValueUnit as PowerUnits
              ).unit
            : type === 'energy'
            ? reduceWattHours(
                currentMeterValue,
                currentMeterValueUnit as EnergyUnit
              ).unit
            : currentMeterValueUnit
      }
      // Add the data for this Meter Value to the chart data
      transactionData[transactionDataKey].unshift({
        x,
        y:
          type === 'power'
            ? convertWatts(
                currentMeterValue,
                currentMeterValueUnit as PowerUnits,
                transactionData[transactionDataUnitKey] as PowerUnits
              )
            : type === 'energy'
            ? convertWattHours(
                currentMeterValue,
                currentMeterValueUnit as EnergyUnit,
                transactionData[transactionDataUnitKey] as EnergyUnit
              )
            : currentMeterValue,
      })
    }

    type KeyTuple = [ProcessMeterValueKey, TransactionDataKey, PowerOrEnergy?]
    const processMeterValueParams: KeyTuple[] = [
      ['currentImport', 'currentImport'],
      ['voltage', 'voltage'],
      ['livePowerImport', 'powerActiveImport', 'power'],
      ['powerActiveExport', 'powerActiveExport', 'power'],
      ['energyActiveImportRegister', 'energyActiveImport', 'energy'],
      ['energyActiveExportRegister', 'energyActiveExport', 'energy'],
      ['soc', 'vehicleSoc'],
    ]

    meterValueData.forEach((meterValue) =>
      processMeterValueParams.forEach((params) =>
        processMeterValue(meterValue, ...params)
      )
    )

    return transactionData
  }, [formatDateTime, meterValueData])

  // Return the data we want to expose to the user while also
  // providing a default value for each unit of measure properties
  // that did not get set above
  return {
    meterValueIsLoading,
    currentImportUnit: currentImportUnit || 'A',
    voltageUnit: voltageUnit || 'V',
    powerActiveImportUnit: powerActiveImportUnit || PowerUnits.WATT,
    powerActiveExportUnit: powerActiveExportUnit || PowerUnits.WATT,
    energyActiveImportUnit: energyActiveImportUnit || 'wh',
    energyActiveExportUnit: energyActiveExportUnit || 'wh',
    vehicleSocUnit: vehicleSocUnit || 'Percent',
    ...rest,
  }
}
