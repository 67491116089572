import { createSelector } from '@reduxjs/toolkit'
import dayjs, { Dayjs } from 'dayjs'

import { EventGroupingKey } from '../useTimelineReducer'
import { GenericEvent } from '@synop-react/api'
import { selectUserTimezone } from './prefs'
import { TimelineSelector } from '../../useTimeline'

type DayjsFn = (date?: dayjs.ConfigType) => Dayjs

const selectScheduledEvents: TimelineSelector<GenericEvent[]> = (state) =>
  state.scheduledEvents

export const selectGroupingKey: TimelineSelector<EventGroupingKey> = (state) =>
  state.groupingKey

export const selectEventIds: TimelineSelector<string[]> = (state) =>
  state.eventIds

export const selectEventsWithIdle: TimelineSelector<
  Record<string, GenericEvent[]>
> = createSelector(
  selectScheduledEvents,
  selectGroupingKey,
  selectEventIds,
  selectUserTimezone,
  (scheduledEvents, groupingKey, eventIds, tzDayjs) => {
    return getIdleTimesBy(scheduledEvents, groupingKey, eventIds, tzDayjs)
  }
)

export const selectAllEventsList: TimelineSelector<GenericEvent[]> =
  createSelector(selectEventsWithIdle, (groupedEvents) => {
    console.log('Recalculating events with idle')
    return Object.values(groupedEvents).reduce(
      (events, acc) => [...acc, ...events],
      []
    )
  })

const getIdleBetween = (
  scheduledEvents: GenericEvent[],
  start: Dayjs,
  end: Dayjs,
  groupId: string,
  tzDayjs: DayjsFn
): GenericEvent[] => {
  if (!scheduledEvents.length) return []
  const thisEvent = scheduledEvents[0] as GenericEvent
  const { eventId, ...thisFilteredEvent } = thisEvent
  const {
    scheduledStart: thisScheduledStart,
    scheduledEnd: thisScheduledEnd = tzDayjs().toString(),
    eventType: thisEventType,
  } = thisFilteredEvent
  const finalTimelineDay =
    tzDayjs(thisScheduledEnd).endOf('day') > tzDayjs().endOf('day')
      ? tzDayjs(thisScheduledEnd).endOf('day')
      : tzDayjs().endOf('day')
  const nextEvent = scheduledEvents[1] || {
    scheduledStart: finalTimelineDay.toString(),
  }
  const { scheduledStart: nextScheduledStart } = nextEvent

  const remainingEvents = scheduledEvents.slice(1)

  // If there's room between events then add an Idle; may need adjusting for sporatdic AD_HOC_SESSIONS
  const INSERT_IDLE_THRESHOLD = 0
  const eventGap = tzDayjs(nextScheduledStart).diff(thisScheduledEnd)
  const needsIdle = eventGap > INSERT_IDLE_THRESHOLD

  if (thisEventType === 'IDLE')
    return [thisEvent].concat(
      getIdleBetween(remainingEvents, start, end, groupId, tzDayjs)
    )

  if (!needsIdle)
    return getIdleBetween(remainingEvents, start, end, groupId, tzDayjs)

  if (!remainingEvents.length)
    return [
      Object.assign({}, thisFilteredEvent, {
        scheduledStart: thisScheduledEnd,
        scheduledEnd: end.toString(),
        eventType: 'IDLE' as const,
        id: `${thisScheduledStart}-${groupId}`,
      }),
    ] as GenericEvent[]

  const endTime = tzDayjs(thisScheduledEnd)
  const nextStartTime = tzDayjs(nextScheduledStart)
  const idleTime: GenericEvent = Object.assign({}, thisFilteredEvent, {
    scheduledStart: endTime.toString(),
    scheduledEnd: nextStartTime.toString(),
    eventType: 'IDLE' as const,
    id: `${thisScheduledStart}-${groupId}`,
  })
  if (needsIdle) {
    return [idleTime].concat(
      getIdleBetween(remainingEvents, start, end, groupId, tzDayjs)
    )
  }
  return []
}

const getIdleTimesBy = (
  scheduledEvents: GenericEvent[],
  groupBy: EventGroupingKey,
  eventIds: string[],
  tzDayjs: DayjsFn
) => {
  let earliestTime = tzDayjs().startOf('day')
  let latestTime = tzDayjs().endOf('day')

  const eventIdMap = eventIds?.reduce<Record<string, GenericEvent[]>>(
    (acc, id) => {
      acc[id] = []
      return acc
    },
    {}
  )

  const eventMap = scheduledEvents.reduce<Record<string, GenericEvent[]>>(
    (acc, scheduledEvent) => {
      const timeboxedEvent = {
        scheduledEnd: tzDayjs().toString(),
        ...scheduledEvent,
      }

      const groupId =
        typeof groupBy === 'function'
          ? groupBy(timeboxedEvent)
          : timeboxedEvent[groupBy]

      if (!groupId) return acc

      const groupedEvents = acc[groupId]
      if (groupedEvents?.length) {
        groupedEvents.push(timeboxedEvent)
      } else {
        acc[groupId] = [timeboxedEvent]
      }

      const startTime = tzDayjs(timeboxedEvent.scheduledStart)
      const endTime = tzDayjs(timeboxedEvent.scheduledEnd)
      if (startTime.isBefore(earliestTime))
        earliestTime = startTime.startOf('day')
      if (endTime.isAfter(latestTime)) latestTime = endTime.endOf('day')

      return acc
    },
    eventIdMap
  )

  // Sort each group's events
  Object.values(eventMap).forEach((groupedEvents) =>
    groupedEvents.sort((eventA, eventB) => {
      return tzDayjs(eventA.scheduledStart).isBefore(eventB.scheduledStart)
        ? -1
        : 1
    })
  )

  // Get idle times for each group
  const allEvents = {} as Record<string, GenericEvent[]>
  Object.entries(eventMap).forEach(([groupedId, groupedEvents]) => {
    // Need to add the entity's id if it's not a composite id
    const entityId =
      typeof groupBy !== 'function' ? { [groupBy]: groupedId } : {}

    const { eventId, ...filteredFirstEvent } = groupedEvents[0] || {
      scheduledStart: tzDayjs(latestTime).endOf('day').toString(),
      eventType: 'IDLE',
      id: `${groupedId}`,
      ...entityId,
    }

    const { scheduledStart: firstScheduledStart } = filteredFirstEvent

    const firstIdleEvent: GenericEvent[] = tzDayjs(firstScheduledStart).isAfter(
      earliestTime
    )
      ? [
          Object.assign({}, filteredFirstEvent, {
            scheduledStart: earliestTime.toString(),
            scheduledEnd: firstScheduledStart,
            eventType: 'IDLE',
          }),
        ]
      : []

    const idleTimes = getIdleBetween(
      [...firstIdleEvent, ...groupedEvents],
      earliestTime,
      latestTime,
      groupedId,
      tzDayjs
    )

    const combinedEvents = [...groupedEvents, ...idleTimes].sort(
      (eventA, eventB) => {
        return tzDayjs(eventA.scheduledStart).isBefore(eventB.scheduledStart)
          ? -1
          : 1
      }
    )

    allEvents[groupedId] = combinedEvents
  })

  return allEvents
}
