import {
  Button,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import {
  ChargerAutocomplete,
  createOverlay,
  DepotAutocomplete,
  FormField,
  Overlay,
  useOverlayContext,
} from '@synop-react/common'
import {
  dayJsToLocalTime,
  defaultCustomRatePeriod,
  rateFormSchema,
} from '../rateUtils'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import {
  Depot,
  EnhancedSiteChargingRate,
  RootAPI,
  useCurrentOrgId,
  useOrgSites,
} from '@synop-react/api'
import {
  FieldError,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { RateTimezoneNotice } from '../RateTimezoneNotice'
import { SetRateInput } from '../SetRateInput'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs, { Dayjs } from 'dayjs'

type CustomRatePeriodField = {
  from: Dayjs
  to: Dayjs
  price: string
}

type ChargerChargingRateForm = {
  allDayRate: string
  depot: Depot
  charger: RootAPI.ChargerModel
  rateName: string | null
  customRatePeriods: CustomRatePeriodField[]
}

type CreateChargerChargingRateOverlayProps = {
  overrideRate: EnhancedSiteChargingRate
}

export const CreateChargerChargingRateOverlay =
  createOverlay<CreateChargerChargingRateOverlayProps>(({ overrideRate }) => {
    const currentOrgId = useCurrentOrgId()

    const { closeOverlay } = useOverlayContext()

    const [rateTimePeriod, setRateTimePeriod] = useState('allDay')

    const { orgSites } = useOrgSites()

    const { siteId } = overrideRate

    const formMethods = useForm<ChargerChargingRateForm>({
      defaultValues: {
        allDayRate: '0.00',
        depot: orgSites[siteId],
        rateName: null,
        customRatePeriods: defaultCustomRatePeriod,
      },
      resolver: yupResolver(rateFormSchema),
    })

    const {
      control,
      handleSubmit,
      formState: { errors, touchedFields },
    } = formMethods

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'customRatePeriods',
    })

    const [createChargerRate] =
      RootAPI.useOverrideWorkplaceChargingRateMutation()
    const onSubmit = handleSubmit(async (formData) => {
      const { charger, rateName, allDayRate, customRatePeriods } = formData

      // Validation ensures we have this
      const chargerId = charger?.chargerId as string

      const rateStructure =
        rateTimePeriod === 'allDay'
          ? [
              {
                from: dayJsToLocalTime(dayjs().startOf('day')),
                to: dayJsToLocalTime(dayjs().endOf('day')),
                price: parseFloat(allDayRate),
              },
            ]
          : customRatePeriods.map(({ from, to, price }) => ({
              from: dayJsToLocalTime(from),
              to: dayJsToLocalTime(to),
              price: parseFloat(price),
            }))

      try {
        await createChargerRate({
          rateMappingId: overrideRate.rateMappingId,
          overrideRateStructureModel: {
            rateNm: rateName as string,
            rateType: 'TIME_OF_USE',
            rateStructure,
            rateStatus: 'ACTIVE',
            rateMappingId: overrideRate.rateMappingId,
            rateId: undefined as unknown as string,
            overrideTargetType: 'CHARGER',
            overrideTargetId: chargerId,
            overridesMappingId: overrideRate.rateMappingId,
          },
        }).unwrap()

        closeOverlay()
      } catch (error) {
        console.log('Error: ', error)
        //TODO: Set Error State to display to user
      }
    })

    return (
      <Overlay>
        <Overlay.Header title="Override Site Charging Rate" />

        <Overlay.Content>
          <Typography>
            Set a default rate or schedule custom rates throughout a 24 hr
            period for a selected site.
          </Typography>

          <FormProvider {...formMethods}>
            <form>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <DepotAutocomplete.Select
                    control={control}
                    disabled
                    error={errors['depot'] as FieldError}
                    fleetId={currentOrgId}
                    id="depot"
                    label="Site"
                    touchedField={Boolean(touchedFields['depot'])}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.rateName}
                    fullWidth
                    id="rateName"
                    label="Rate Name"
                    touched={Boolean(touchedFields.rateName)}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <ChargerAutocomplete.Select
                    control={control}
                    depotId={siteId}
                    error={errors.charger as FieldError}
                    id="charger"
                    orgId={currentOrgId}
                    touchedField={Boolean(touchedFields.charger)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack alignItems="center" direction="row">
                    <Radio
                      checked={rateTimePeriod === 'allDay'}
                      inputProps={{ 'aria-label': 'all day rate range' }}
                      name="rateTimeRange"
                      onChange={(e) => setRateTimePeriod(e.target.value)}
                      value="allDay"
                    />

                    <Typography sx={{ mr: '22px' }}>All Day: </Typography>

                    <SetRateInput id="allDayRate" />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row">
                    <Radio
                      checked={rateTimePeriod === 'custom'}
                      inputProps={{ 'aria-label': 'custom rate range' }}
                      name="rateTimeRange"
                      onChange={(e) => setRateTimePeriod(e.target.value)}
                      sx={{ height: 48 }}
                      value="custom"
                    />

                    <Typography sx={{ mr: '16px', mt: '12px' }}>
                      Custom:
                    </Typography>

                    <Grid container rowGap={2}>
                      {fields.map((item, index) => (
                        <Grid key={item.id} item xs={12}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                          >
                            <FormField.TimePicker
                              id={`customRatePeriods[${index}].from`}
                              label="Start Time"
                              variant="standard"
                            />

                            <FormField.TimePicker
                              id={`customRatePeriods[${index}].to`}
                              label="End Time"
                              variant="standard"
                            />

                            <SetRateInput
                              id={`customRatePeriods[${index}].price`}
                            />

                            {fields.length > 1 && (
                              <IconButton
                                disabled={fields.length <= 1}
                                onClick={() => remove(index)}
                                size="medium"
                              >
                                <DeleteOutlineOutlined
                                  sx={{ color: 'action.active' }}
                                />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    disabled={rateTimePeriod === 'allDay'}
                    onClick={() => {
                      const lastField = fields.at(-1)
                      const nextStart = dayjs(lastField?.to).add(1, 'minute')

                      append({
                        from: nextStart,
                        to: nextStart.add(1, 'hour'),
                        price: '0.00',
                      })
                    }}
                  >
                    + ADD CUSTOM RATE
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <RateTimezoneNotice
                    description="Please note that this may be different than your timezone."
                    title="All rates will be set for the site's timezone."
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Overlay.Content>

        <Overlay.Actions>
          <Button onClick={onSubmit} variant="contained">
            Add Rate
          </Button>
          <Button onClick={closeOverlay}>Cancel</Button>
        </Overlay.Actions>
      </Overlay>
    )
  })
