import {
  DayjsConstructor,
  emDash,
  formatSoC,
  LineDatum,
  Tile,
} from '@synop-react/common'
import { RootAPI, SocChartData, useUserPrefs } from '@synop-react/api'

import { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { usePalette } from '@synop-react/theme'
import Chart from 'react-apexcharts'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export interface SocActivityGraphProps {
  vehicleId: string
  from: DayjsConstructor
  to: DayjsConstructor
}

export function SocActivityGraph({
  from,
  to,
  vehicleId,
}: SocActivityGraphProps) {
  const { charting } = usePalette()
  const { tzDayjs } = useUserPrefs()
  const { preferredTimeFormat } = useUserPrefs()
  const timeFormat =
    preferredTimeFormat === 'hh:mm' ? 'hh:mm tt' : preferredTimeFormat

  const { data: socChartData = {}, isLoading: isLoadingChart } =
    RootAPI.synopRootAPI.useGetChartValuesQuery({
      id: vehicleId,
      from: tzDayjs(from).toISOString(),
      to: tzDayjs(to).toISOString(),
      attributes: ['stateOfCharge'],
    })

  // TODO Downsample this in a better way @wslater
  const chartData = useMemo(() => {
    const { data: socChartValues = [] } =
      socChartData as unknown as SocChartData

    const sampleFrequency = dayjs.duration(60, 'minutes')
    let nextSampleTime =
      socChartValues[0] && socChartValues[0][0]
        ? tzDayjs(Number(socChartValues[0][0]))
        : tzDayjs()

    return socChartValues.reduce<LineDatum[]>((acc, [timestamp, soc], i) => {
      const sampleTime = tzDayjs(Number(timestamp))
      const isLastTimestamp = i === socChartValues.length - 1
      if (sampleTime.isAfter(tzDayjs(nextSampleTime)) || isLastTimestamp) {
        const datapoint = { x: sampleTime.toISOString(), y: soc }
        acc.push(datapoint)
        nextSampleTime = nextSampleTime.add(sampleFrequency)
      }

      return acc
    }, [])
  }, [socChartData, tzDayjs])

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [charting[1].main],
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        formatter: function (val) {
          return formatSoC(val)
        },
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        show: false,
        format: timeFormat,
      },
      tooltip: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 10,
        bottom: 10,
        left: -10,
        right: 0,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return val?.toFixed(0) || emDash
        },
      },
      x: {
        format: 'dd MMM yyyy ' + timeFormat,
      },
    },
  }

  const series = [
    {
      name: 'SoC (%)',
      data: chartData.map((elem) => [new Date(elem.x).getTime(), elem.y]),
    },
  ]

  return (
    <Tile isLoading={isLoadingChart} title="Daily State of Charge">
      <Chart
        height={250}
        options={options}
        series={series}
        type="area"
        width={250}
      />
    </Tile>
  )
}

export default SocActivityGraph
