import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'

import { isEqual } from 'lodash'
import { ReportType } from '..'
import { RootAPI } from '@synop-react/api'
import { useEffect, useMemo, useState } from 'react'

const {
  useGetStatusOfGeneratedReportQuery,
  useGetStatusOfGeneratedReport1Query,
} = RootAPI.synopRootAPI

const incompleteReportStatus = ['INIT', 'PROCESSING'] as const
type IncompleteReportStatus = (typeof incompleteReportStatus)[number]
const completeReportStatus = 'COMPLETED' as const
type CompleteReportStatus = typeof completeReportStatus
type ReportStatus = IncompleteReportStatus | CompleteReportStatus

export type ReportArgs =
  | RootAPI.GetVehicleReportApiArg
  | RootAPI.GetTripReportApiArg

type ReportResponse = {
  id?: string
  reportUrl?: string
}

type RequestedReport<ReportArgType, ReportType> = {
  queryArgs: ReportArgType
  id?: string
  json?: ReportType[]
  status: ReportStatus
}

type UseReportArgs<ReportArgType, ResponseType extends ReportResponse> = {
  queryArgs: ReportArgType
  useQuery: UseQuery<
    QueryDefinition<
      ReportArgType,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, unknown>,
        FetchBaseQueryMeta
      >,
      string | never,
      ResponseType,
      'api' | 'depot-api' | 'fleet-mgmt-api'
    >
  >
}

type UseReportResponse<ReportType> = {
  isGenerating: boolean
  report: ReportType[]
}

export type UseReportProps<
  ReportArgType,
  ResponseType extends ReportResponse
> = {
  skip?: boolean
  pollingInterval?: number // milliseconds
  reportType: ReportType
} & UseReportArgs<ReportArgType, ResponseType>

export const useReport = <
  ReportArgType,
  ResponseType extends ReportResponse,
  ReportType
>({
  useQuery,
  queryArgs,
  skip,
  pollingInterval = 5000,
  reportType,
}: UseReportProps<
  ReportArgType,
  ResponseType
>): UseReportResponse<ReportType> => {
  const [requestedReports, setRequestedReports] = useState<
    Record<string, RequestedReport<ReportArgType, ReportType>>
  >({})

  const { currentReport, hasRequestedCurrentReport, hasReceivedCurrentReport } =
    useMemo(() => {
      const currentReport = Object.values(requestedReports).find((request) =>
        isEqual(request.queryArgs, queryArgs)
      )

      return {
        currentReport,
        hasRequestedCurrentReport: Boolean(
          currentReport && currentReport.status !== 'INIT'
        ),
        hasReceivedCurrentReport: currentReport?.status === 'COMPLETED',
      }
    }, [requestedReports, queryArgs])

  const { data: requestReport } = useQuery(queryArgs, {
    skip: skip || hasRequestedCurrentReport,
  })

  // Poll report until we get a url
  // TODO Check for status indicating failure @wslater

  const isChargingReport = useMemo(
    () => reportType === 'CHARGING_TRANSACTIONS',
    [reportType]
  )

  // NOTE: this is for charging report
  const { data: chargingGenerationStatus } =
    useGetStatusOfGeneratedReport1Query(
      { id: requestReport?.id || '' },
      {
        pollingInterval: pollingInterval,
        skip: Boolean(
          skip ||
            !requestReport?.id ||
            hasReceivedCurrentReport ||
            !isChargingReport
        ),
      }
    )

  // NOTE: this is for fleet-management reports
  const { data: fleetGenerationStatus } = useGetStatusOfGeneratedReportQuery(
    { id: requestReport?.id || '' },
    {
      pollingInterval: pollingInterval,
      skip: Boolean(
        skip ||
          !requestReport?.id ||
          hasReceivedCurrentReport ||
          isChargingReport
      ),
    }
  )

  const generationStatus = useMemo(
    () => (isChargingReport ? chargingGenerationStatus : fleetGenerationStatus),
    [chargingGenerationStatus, fleetGenerationStatus, isChargingReport]
  )

  // Register report with map for tracking status
  useEffect(() => {
    const reportId = requestReport?.id
    if (reportId && !requestedReports[reportId]) {
      const requestedReport = {
        id: requestReport?.id,
        queryArgs,
        status: 'PROCESSING', // TODO Use the status from the response @wslater
      }
      const updatedReportRequests = Object.assign({}, requestedReports, {
        [reportId]: requestedReport,
      })
      setRequestedReports(updatedReportRequests)
    }
  }, [requestedReports, requestReport, queryArgs])

  // Download Report
  useEffect(() => {
    const { reportUrl, id } = generationStatus || {}
    const isMatchingReport = id === currentReport?.id
    if (reportUrl && isMatchingReport && !hasReceivedCurrentReport) {
      ;(async () => {
        const response = await fetch(reportUrl, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        const report = await response.json()

        if (currentReport?.id) {
          const updatedRequest = Object.assign({}, currentReport, {
            json: report,
            status: generationStatus?.status || 'COMPLETED',
          })
          const updatedReportRequests = Object.assign({}, requestedReports, {
            [currentReport.id]: updatedRequest,
          })
          setRequestedReports(updatedReportRequests)
        }
      })()
    }
  }, [
    generationStatus,
    requestedReports,
    currentReport,
    hasReceivedCurrentReport,
  ])

  return {
    isGenerating: !hasReceivedCurrentReport,
    report: currentReport?.json || [],
  }
}
