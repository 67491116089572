import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { DayjsConstructor } from '../../date'
import { emDash } from '../../formatters'
import { PreferredDateFormat, TimeFormat, TimeZone } from '@synop-react/api'

dayjs.extend(utc)
dayjs.extend(timezone)

export type FormatTimePreferences = {
  preferredDateFormat: PreferredDateFormat
  preferredTimeFormat: TimeFormat
  preferredTimeZone: TimeZone
}

type TimeOptions = {
  displaySeconds: boolean
  displayMeridiemIndicator: boolean
  dropLeadingZero: boolean
  invalidDateValue: string
}
type PartialOptions = Partial<TimeOptions>

export const defaultInvalidDateValue = emDash
const defaultTimeOptions: Omit<TimeOptions, 'displayMeridiemIndicator'> = {
  displaySeconds: false,
  dropLeadingZero: false,
  invalidDateValue: defaultInvalidDateValue,
}

const isTimeOptions = (
  maybeDate: DayjsConstructor | PartialOptions
): maybeDate is PartialOptions => {
  const isDate = maybeDate instanceof Date
  const isDayjs = maybeDate instanceof dayjs
  if (isDate || isDayjs || typeof maybeDate !== 'object' || !maybeDate)
    return false
  return true
}

export const formatDateTime =
  ({
    preferredDateFormat,
    preferredTimeFormat,
    preferredTimeZone,
  }: FormatTimePreferences) =>
  (
    fromDate: DayjsConstructor,
    maybeToDate: DayjsConstructor | PartialOptions = defaultTimeOptions,
    options?: PartialOptions
  ) => {
    const toDate: DayjsConstructor = isTimeOptions(maybeToDate)
      ? null
      : maybeToDate

    const defaultShowMeridiem = preferredTimeFormat === 'hh:mm'

    const timeOptions: TimeOptions = Object.assign(
      {},
      { ...defaultTimeOptions, displayMeridiemIndicator: defaultShowMeridiem },
      isTimeOptions(maybeToDate) ? maybeToDate : options
    )

    const { displaySeconds, displayMeridiemIndicator, invalidDateValue } =
      timeOptions
    const secondsFormat = displaySeconds ? ':ss' : ''
    const meridiemFormat = displayMeridiemIndicator ? ' a' : ''
    const timeOfDayFormat = timeOptions?.dropLeadingZero
      ? preferredTimeFormat.replaceAll('hh', 'h')
      : preferredTimeFormat
    const timeFormat = `${timeOfDayFormat}${secondsFormat}${meridiemFormat}`

    const maybeString =
      (value: unknown, noValueString: string) => (returnValue: string) => {
        return value ? returnValue : noValueString
      }
    const maybeFrom = maybeString(fromDate, invalidDateValue)
    const maybeFromTo = maybeString(fromDate && toDate, invalidDateValue)

    return {
      options: timeOptions,
      /* SINGLE DATE FORMATS */
      fromDateTime: dayjs(fromDate).tz(preferredTimeZone),
      get date() {
        return maybeFrom(this.fromDateTime.format(preferredDateFormat))
      },
      get dateDataExport() {
        return this.fromDateTime ? this.fromDateTime.format('YYYY-MM-DD') : ''
      },
      get time() {
        return maybeFrom(this.fromDateTime.format(timeFormat))
      },
      get asTimeOnDate() {
        return maybeFrom(`As of ${this.timeOnDate}`)
      },
      get atTimeOnDate() {
        return maybeFrom(`At ${this.timeOnDate}`)
      },
      get dateDotTime() {
        return maybeFrom(`${this.date} • ${this.time}`)
      },
      get fromToDayOnDate() {
        const rangeStartDate = !this.fromDateTime.isSame(toDate, 'day')
          ? `on ${this.date} `
          : ''

        return maybeFromTo(
          `From ${this.time} ${rangeStartDate}to ${this.toTime} on ${this.toDate}`
        )
      },
      get timeDotDate() {
        return maybeFrom(`${this.time} • ${this.date}`)
      },
      get timeOnDate() {
        return maybeFrom(`${this.time} on ${this.date}`)
      },
      /* MULTIPLE DATE FORMATS */
      toDateTime: dayjs(toDate).tz(preferredTimeZone),
      get toDate() {
        return maybeFromTo(this.toDateTime.format(preferredDateFormat))
      },
      get toTime() {
        return maybeFromTo(this.toDateTime.format(timeFormat))
      },
      get betweenTimes() {
        return maybeFromTo(`${this.time} - ${this.toTime}`)
      },
      get toLongFormDate() {
        const dateFormat = preferredDateFormat
          .replaceAll('YY', 'YYYY')
          .replaceAll('MM', 'MMM')
          .replaceAll('DD', 'D')
          .replaceAll('/', ' ')
        return maybeFrom(this.fromDateTime.format(dateFormat))
      },
      get toLongFormTime() {
        const timeFormat =
          preferredTimeFormat === 'hh:mm' ? 'hh:mm a' : preferredTimeFormat
        return maybeFrom(this.fromDateTime.format(timeFormat))
      },
      get toLongFormDateTime() {
        return maybeFrom(`${this.toLongFormDate} ${this.toLongFormTime}`)
      },
      get betweenLongFormDateTimes() {
        const dateFormat = preferredDateFormat
          .replaceAll('YY', 'YYYY')
          .replaceAll('MM', 'MMMM')
          .replaceAll('/', ' ')
        const timeFormat =
          preferredTimeFormat === 'hh:mm' ? 'hh:mm a' : preferredTimeFormat
        const format = `${dateFormat} ${timeFormat}`
        return maybeFromTo(
          `${this.fromDateTime.format(format)} -
           ${this.toDateTime.format(format)}`
        )
      },
    }
  }
