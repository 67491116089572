import { Duration } from 'dayjs/plugin/duration'

export const formatDurationTotal = (time: Duration): string => {
  if (time.asHours() >= 24) {
    let days = Math.floor(time.asDays())
    let hours = Math.round(time.hours() + time.minutes() / 60)
    if (hours == 24) {
      days += 1
      hours = 0
    }
    return `${days}d ${hours}h`
  } else {
    let hours = Math.floor(time.asHours())
    let minutes = Math.round(time.minutes() + time.seconds() / 60)
    if (minutes == 60) {
      hours += 1
      minutes = 0
    }
    return `${hours}h ${minutes}m`
  }
}
