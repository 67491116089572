import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PartialStore } from '@synop-react/types'
import { RootAPI } from '..'

export type CurrentOrganizationStore = PartialStore<
  typeof currentOrganizationSlice
>
type CurrentOrganizationState = {
  principalOrgId: string
  cloakedOrgIds: string[]
}

const initialState: CurrentOrganizationState = {
  principalOrgId: '',
  cloakedOrgIds: [],
}

const currentOrganizationSlice = createSlice({
  name: 'currentOrganization',
  initialState,
  reducers: {
    setPrincipalOrganization: (
      state,
      action: PayloadAction<Pick<RootAPI.Organization, 'id'>>
    ) => {
      const { id = '' } = action.payload
      state.principalOrgId = id
      state.cloakedOrgIds = [id]
      return state
    },
    cloakAsOrganization: (
      state,
      action: PayloadAction<RootAPI.Organization>
    ) => {
      state.cloakedOrgIds.push(action.payload.id ?? '')
      return state
    },
    revertToPreviousOrganization: (state) => {
      if (state.cloakedOrgIds.length > 1) state.cloakedOrgIds.pop()
      return state
    },
    resetCloaking: (state) => {
      state.cloakedOrgIds = [state.principalOrgId]
      return state
    },
  },
})

export const {
  setPrincipalOrganization,
  cloakAsOrganization,
  revertToPreviousOrganization,
  resetCloaking,
} = currentOrganizationSlice.actions

export const currentOrganizationReducer = currentOrganizationSlice.reducer
