import { useMemo } from 'react'

import {
  Depot,
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
} from '@synop-react/api'
import { selectorFactory } from './EntitySelector'

const {
  useGetDepotsQuery,
  useGetFleetsQuery,
  useGetOrganizationCustomersQuery,
} = RootAPI.synopRootAPI

/** Sites **/
function useSites() {
  const { synopUser, isAdmin } = useCurrentUser()
  const orgId = useCurrentOrgId()
  const { data } = useGetDepotsQuery(
    { fleetId: orgId, count: 500 },
    { skip: !orgId }
  )

  return useMemo(() => {
    return (
      data?.items
        ?.filter(
          (site) =>
            isAdmin ||
            (site.depotId && synopUser?.sites?.includes(site.depotId))
        )
        .map((site) => ({ id: site.depotId, ...site } as Depot)) ?? []
    )
  }, [data, isAdmin, synopUser])
}

export const {
  Selector: SiteSelector,
  Provider: SiteSelectorProvider,
  useSelector: useSiteSelector,
} = selectorFactory<Depot>(useSites, 'depotNm', 'All Sites')

export const {
  Selector: MultiSiteSelector,
  Provider: MultiSiteSelectorProvider,
  useSelector: useMultiSiteSelector,
} = selectorFactory<Depot, Depot[]>(useSites, 'depotNm', 'All Sites', true)

/** Organizations **/
function useOrgs() {
  const orgId = useCurrentOrgId()
  return (
    useGetOrganizationCustomersQuery({ id: orgId }, { skip: !orgId }).data ?? []
  )
}

export const {
  Selector: OrgSelector,
  Provider: OrgSelectorProvider,
  useSelector: useOrgSelector,
} = selectorFactory<RootAPI.OrganizationModel>(
  useOrgs,
  'organizationNm',
  'All Organizations'
)

export const {
  Selector: MultiOrgSelector,
  Provider: MultiOrgSelectorProvider,
  useSelector: useMultiOrgSelector,
} = selectorFactory<RootAPI.OrganizationModel, RootAPI.OrganizationModel[]>(
  useOrgs,
  'organizationNm',
  'All Organizations',
  true
)

/** Returns the selected org ID or the current org ID if no org is selected */
export function useSelectedOrgIdOrCurrent() {
  const { selected: selectedOrg } = useOrgSelector()
  const orgId = useCurrentOrgId()
  return selectedOrg?.id || orgId || undefined
}

/** Fleets **/
function useFleets() {
  const orgId = useSelectedOrgIdOrCurrent()
  return useGetFleetsQuery({ id: orgId ?? '' }, { skip: !orgId }).data ?? []
}

export const {
  Selector: FleetSelector,
  Provider: FleetSelectorProvider,
  useSelector: useFleetSelector,
} = selectorFactory<RootAPI.Fleet>(useFleets, 'fleetNm', 'All Fleets')
