import { mockData } from '../'
import { paginatedResponse } from './utils'
import { rest } from 'msw'

export const chargingSessionHandlers = [
  rest.get('/api/sessions', (req, res, ctx) => {
    return res(
      ctx.json(
        paginatedResponse(mockData.chargingSession.multipleScheduledCharges)
      )
    )
  }),
  rest.get('/api/sessions/:sessionId', (req, res, ctx) => {
    return res(ctx.json(mockData.chargingSession.singleScheduledCharges))
  }),
]
