import { Box, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { PropsWithChildren } from 'react'

import type {
  CurrentChargerStatus,
  CurrentConnectorStatus,
  LastKnownChargerStatus,
  LastKnownConnectorStatus,
  RootAPI,
} from '@synop-react/api'

type CommonFields = {
  connectorStatus?: RootAPI.ChargerModel['connectorStatus']
  id: string
  lastHeard?: string
  liveUtilization?: number | null
  name?: string
  path: string[]
}

export type ChargerDatum = CommonFields & {
  isCharger: true
  maxPower?: number
  numActiveConnectors: number
  siteId?: string
  siteName?: string
  currentStatus: CurrentChargerStatus
  lastKnownStatus: LastKnownChargerStatus
}

export type ConnectorDatum = CommonFields & {
  isCharger: false
  chargerStatus: CurrentChargerStatus
  currentStatus: CurrentConnectorStatus
  lastKnownStatus: LastKnownConnectorStatus
}

export type ChargerOrConnectorDatum = ChargerDatum | ConnectorDatum
export type ChargerTableColDef<T extends keyof ChargerOrConnectorDatum> =
  GridColDef<ChargerOrConnectorDatum, ChargerOrConnectorDatum[T]>

export type EntityProp = { entity: ChargerOrConnectorDatum }
export type ChargerProp = { charger: ChargerDatum }

type HeaderWithMarginProps = {
  colName: string
  margin: number
}

export function HeaderWithMargin({ colName, margin }: HeaderWithMarginProps) {
  return (
    <Typography sx={{ ml: `${margin}px`, fontWeight: 600 }} variant="body2">
      {colName}
    </Typography>
  )
}

type CellWithMarginProps = PropsWithChildren<{
  icon?: React.ReactNode
  width: number
}>

export function CellWithIcon({ children, icon, width }: CellWithMarginProps) {
  return (
    <Stack alignItems="center" direction="row" maxWidth="100%">
      <Box sx={{ width, height: 24 }}>{icon}</Box>
      {children}
    </Stack>
  )
}
