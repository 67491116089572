import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal'
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip'

import { GenericEvent } from '@synop-react/api'
import Tooltip from '../../../Tooltip'
import useTimeline from '../useTimeline/useTimeline'

export type TooltipData = { event: GenericEvent }
export type TooltipControls = UseTooltipParams<TooltipData>

export interface TimelineTooltipProps {
  Component: UseTooltipInPortal['TooltipInPortal']
}

export function TimelineTooltip({ Component }: TimelineTooltipProps) {
  const { tooltip, eventOptions } = useTimeline()

  if (tooltip && tooltip.tooltipData) {
    const { tooltipTop, tooltipLeft, tooltipData, tooltipOpen } = tooltip

    const event = tooltipData.event
    const { title, details } = eventOptions[event.eventType].tooltip

    const tooltipTitle = typeof title === 'string' ? title : title(event)

    return tooltipOpen ? (
      <Component
        left={tooltipLeft}
        style={{
          position: 'absolute',
          minWidth: 300,
          textAlign: 'center',
        }}
        top={tooltipTop}
      >
        <Tooltip data={event} details={details} title={tooltipTitle} />
      </Component>
    ) : null
  }
  return null
}

export default TimelineTooltip
