import { Entity, EntityBooleanMap, EntityIdMap, Id } from '@synop-react/types'
import { EntitySorterMap } from '../../../..'

export enum EntityActions {
  SET_ENTITIES = 'SET_ENTITIES',
  SET_DISPLAYED_ENTITIES = 'SET_DISPLAYED_ENTITES',
  RESET_DISPLAYED_ENTITIES = 'RESET_DISPLAYED_ENTITES',
  SET_SORTED_ENTITIES = 'SET_SORTED_ENTITIES',
  TOGGLE_SELECTED_ENTITY = 'TOGGLE_SELECTED_ENTITY',
  SET_ENTITY_SORTER = 'SET_ENTITY_SORTER',
  SET_SORTERS = 'SET_SORTERS',
  SET_FILTERS = 'SET_FILTERS',
}

export type Payload<T extends Entity> =
  | ActionObject<T, EntityActions.SET_ENTITIES>
  | ActionObject<T, EntityActions.SET_DISPLAYED_ENTITIES>
  | ActionObject<T, EntityActions.RESET_DISPLAYED_ENTITIES>
  | ActionObject<T, EntityActions.SET_SORTED_ENTITIES>
  | ActionObject<T, EntityActions.TOGGLE_SELECTED_ENTITY>
  | ActionObject<T, EntityActions.SET_ENTITY_SORTER>
  | ActionObject<T, EntityActions.SET_SORTERS>
  | ActionObject<T, EntityActions.SET_FILTERS>

// Helper type to map EntityActions to their respective payloads
interface PayloadMap<T extends Entity> {
  [EntityActions.SET_ENTITIES]: EntityIdMap<T>
  [EntityActions.SET_DISPLAYED_ENTITIES]: EntityBooleanMap
  [EntityActions.RESET_DISPLAYED_ENTITIES]: undefined
  [EntityActions.SET_SORTED_ENTITIES]: Id[]
  [EntityActions.TOGGLE_SELECTED_ENTITY]: Id
  [EntityActions.SET_ENTITY_SORTER]: EntitySorterMap<T>
  [EntityActions.SET_SORTERS]: string[]
  [EntityActions.SET_FILTERS]: string[]
}

type ActionObject<T extends Entity, Type extends EntityActions> = {
  type: Type
  payload: PayloadMap<T>[Type]
}

/**
 * Creates an action creator for the given action type. The returned function takes a payload which
 * has a type matched to the action type via the `PayloadMap`.
 */
function actionCreatorFactory<Action extends EntityActions>(action: Action) {
  return <T extends Entity>(
    payload: PayloadMap<T>[Action]
  ): ActionObject<T, Action> => ({
    type: action,
    payload,
  })
}

export type SetBrowserEntities = typeof setBrowserEntities
export const setBrowserEntities = actionCreatorFactory(
  EntityActions.SET_ENTITIES
)

export type SetBrowserDisplayedEntities = typeof setBrowserDisplayedEntities
export const setBrowserDisplayedEntities = actionCreatorFactory(
  EntityActions.SET_DISPLAYED_ENTITIES
)

export type ResetBrowserDisplayedEntities = typeof resetBrowserDisplayedEntities
export const resetBrowserDisplayedEntities = (): ActionObject<
  Entity,
  EntityActions.RESET_DISPLAYED_ENTITIES
> => ({
  type: EntityActions.RESET_DISPLAYED_ENTITIES,
  payload: undefined,
})

export type SetBrowserSortOrder = typeof setBrowserSortOrder
export const setBrowserSortOrder = actionCreatorFactory(
  EntityActions.SET_SORTED_ENTITIES
)

export type ToggleBrowserSelectedEntity = typeof toggleBrowserSelectedEntity
export const toggleBrowserSelectedEntity = actionCreatorFactory(
  EntityActions.TOGGLE_SELECTED_ENTITY
)

export type SetEntitySorter = typeof setEntitySorter
export const setEntitySorter = actionCreatorFactory(
  EntityActions.SET_ENTITY_SORTER
)

export type SetCurrentEntitySorters = typeof setCurrentEntitySorters
export const setCurrentEntitySorters = actionCreatorFactory(
  EntityActions.SET_SORTERS
)

export type SetEntityFilters = typeof setEntityFilters
export const setEntityFilters = actionCreatorFactory(EntityActions.SET_FILTERS)
