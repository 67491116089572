import { NoData } from '../NoData'
import { NoDataProps } from '../NoData/NoData'
import { Skeleton, SkeletonProps } from '@mui/material'

export type DataStateProps = {
  isLoading?: boolean
  hasData?: boolean
  skeletonProps?: Partial<SkeletonProps>
  noDataMessage?: string
  noDataVariant?: NoDataProps['variant']
}
export const DataState = ({
  isLoading = false,
  hasData = true,
  skeletonProps,
  noDataMessage,
  noDataVariant = 'container',
}: DataStateProps) => {
  if (isLoading) return <Skeleton {...skeletonProps} />
  if (!hasData)
    return <NoData message={noDataMessage} variant={noDataVariant} />
  return null
}

export default DataState
