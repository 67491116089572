import { Layer, LayerProps } from 'react-map-gl'
import { useTheme } from '@mui/material'
import MapLineLayer, { MapLineLayerProps } from '../Line/MapLineLayer'

export interface MapPolygonLayerProps {
  /** 
  A unique id for this layer
*/
  layerId: string
  /** 
  The id for the Source layer that this layer should receive 
*/
  sourceId: string
  /** 
  A color to fill the polygon and stroke the outline 
*/
  fillColor?: string
  /** 
  Number representing percent of opacity for the polygon fill 
*/
  fillOpacity?: number
  /** 
  Line Options that will be used for the polygon outline 
*/
  outlineOptions?: OutlineOptions
  /** 
  An optional filter for selecting data from a Feature / FeatureCollection from the Source
*/
  filter?: unknown[]
  /** 
  The min map zoom level that this layer will be displayed at
*/
  minZoom?: number
  /** 
  The max map zoom level that this layer will be displayed at
*/
  maxZoom?: number
}

export type OutlineOptions = Omit<MapLineLayerProps, 'layerId' | 'sourceId'>

const DEFAULT_FILL_OPACITY = 0.1
const DEFAULT_LINE_WIDTH = 2
const DEFAULT_LINE_OPTIONS = {
  defaultLineWidth: DEFAULT_LINE_WIDTH,
}
const defaultPolygonFilter = ['==', ['geometry-type'], 'Polygon']

export function MapPolygonLayer({
  layerId,
  sourceId,
  fillColor,
  fillOpacity = DEFAULT_FILL_OPACITY,
  outlineOptions = {},
  minZoom,
  maxZoom,
  filter = defaultPolygonFilter,
}: MapPolygonLayerProps) {
  const theme = useTheme()
  const minzoom = minZoom !== undefined ? { minzoom: minZoom } : {}
  const maxzoom = maxZoom !== undefined ? { maxzoom: maxZoom } : {}
  const polyFillColor = fillColor || theme.palette.info.main
  const polyFillLayer: LayerProps = {
    id: layerId,
    type: 'fill',
    source: sourceId,
    ...{ filter },
    ...{ ...minzoom },
    ...{ ...maxzoom },
    paint: {
      'fill-opacity': fillOpacity,
      'fill-color': polyFillColor,
    },
  }

  const outlineOption = Object.assign({}, DEFAULT_LINE_OPTIONS, outlineOptions)

  return (
    <>
      <MapLineLayer
        isDashed={true}
        layerId={`${layerId}-outline`}
        lineColor={polyFillColor}
        sourceId={sourceId}
        {...outlineOption}
        {...{ minZoom }}
        {...{ maxZoom }}
      />
      <Layer {...polyFillLayer} />
    </>
  )
}

export default MapPolygonLayer
