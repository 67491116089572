import { BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { localPoint } from '@visx/event'
import { useCallback, useMemo } from 'react'

import { GenericEvent } from '@synop-react/api'
import { selectLeftAxisArea } from '../useTimeline/useTimelineReducer/selectors'
import {
  selectXScale,
  selectYScale,
} from '../useTimeline/useTimelineReducer/selectors/scale'
import TimelineEventCaption from './Caption'
import useTimeline, { useTimelineSelector } from '../useTimeline/useTimeline'

export type TimelineEventProps = {
  scheduledEvent: GenericEvent
} & Partial<typeof BarRounded>

const barSpacing = 4
const barHeight = 8

export function TimelineEvent({ scheduledEvent }: TimelineEventProps) {
  const {
    tooltip,
    hideCaptions,
    eventOptions,
    groupingKey,
    displayEventOverlay,
    isOverlayOpen,
    closeEventOverlay,
  } = useTimeline()
  const xScale = useTimelineSelector(selectXScale)
  const yScale = useTimelineSelector(selectYScale)
  const { width: leftAxisWidth } = useTimelineSelector(selectLeftAxisArea)

  const xStart = useMemo(
    () => xScale(new Date(scheduledEvent.scheduledStart)) + barSpacing / 2,
    [xScale, scheduledEvent]
  )

  const barWidth = useMemo(() => {
    const endDate = scheduledEvent.scheduledEnd
      ? new Date(scheduledEvent.scheduledEnd)
      : new Date()
    const xEnd = xScale(endDate)
    const width = xEnd - xStart - barSpacing

    return width
  }, [xScale, xStart, scheduledEvent])

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGGElement> | React.MouseEvent<SVGGElement>) => {
      if (tooltip) {
        const { showTooltip } = tooltip
        const { x, y } = localPoint(event) || { x: 0 }
        showTooltip({
          tooltipData: { event: scheduledEvent },
          tooltipLeft: x,
          tooltipTop: y,
        })
      }
    },
    [tooltip, scheduledEvent]
  )

  const groupingId =
    typeof groupingKey === 'function'
      ? groupingKey(scheduledEvent)
      : (scheduledEvent[groupingKey] as string)
  const y = yScale(groupingId)

  const { eventType } = scheduledEvent

  const { barColor, fillOpacity, renderOverlay } = eventOptions[eventType]
  const barColorString =
    typeof barColor === 'function' ? barColor(scheduledEvent) : barColor

  const hideTooltip = tooltip?.hideTooltip ? tooltip.hideTooltip : () => null

  const colorOption = fillOpacity
    ? { fill: barColorString, fillOpacity }
    : { fill: barColorString }

  const isValidEvent = barWidth > 0

  const canRenderOverlay =
    renderOverlay &&
    renderOverlay({
      ...scheduledEvent,
      isOpen: isOverlayOpen,
      setIsOpen: closeEventOverlay,
    })

  const clickableProps = canRenderOverlay
    ? {
        style: { cursor: 'pointer' },
        onClick: () => displayEventOverlay(scheduledEvent),
      }
    : {
        style: { cursor: 'default' },
      }

  return isValidEvent ? (
    <Group
      left={leftAxisWidth}
      onMouseLeave={hideTooltip}
      onMouseMove={handleTooltip}
      onTouchMove={handleTooltip}
      onTouchStart={handleTooltip}
      {...clickableProps}
    >
      {
        // This 80 pixel number is somewhat arbitrary, based on our longest
        // event title "Scheduled" currently weighing in at ~67px
        !hideCaptions && barWidth > 80 && (
          <TimelineEventCaption scheduledEvent={scheduledEvent} />
        )
      }
      <BarRounded
        all
        height={barHeight}
        radius={100}
        width={barWidth}
        {...colorOption}
        x={xStart}
        y={y}
      />
    </Group>
  ) : null
}

export default TimelineEvent
