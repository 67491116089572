import { Entity, EntityBooleanMap, EntityIdMap, Id } from '@synop-react/types'
import { EntityActions, Payload } from './reducerActions'
import { EntitySorter, EntitySorterMap } from '../../../..'
import {
  parseEntitiesToIds,
  parseEntityMapToBoolMap,
  sortEntities,
} from './reducerUtils'
import {
  selectActiveSorters,
  selectAllOrderedEntities,
} from './reducerSelectors'
import { useReducer } from 'react'

export type EntityState<T extends Entity> = {
  allEntities: EntityIdMap<T>
  visibleEntityIds: EntityBooleanMap
  entityIdSortOrder: Id[]
  selectedEntityId: Id | null
  entitySorter: EntitySorterMap
  currentSorters: string[]
  currentFilters: string[]
}

export const defaultEntityReducerState = {
  allEntities: {},
  visibleEntityIds: {},
  selectedEntityId: null,
  entityIdSortOrder: [],
  entitySorter: {},
  currentSorters: [''],
  currentFilters: [''],
}

export function useEntityReducer<T extends Entity>(
  initialState: Partial<EntityState<T>> = defaultEntityReducerState
) {
  function entityReducer(state: EntityState<T>, action: Payload<T>) {
    const sortEntityIds = (entities: Entity[], sorters: EntitySorter[]) => {
      const sortedEntities = sortEntities(entities, sorters)
      return parseEntitiesToIds(sortedEntities)
    }

    switch (action.type) {
      case EntityActions.SET_ENTITIES: {
        const allEntities = action.payload
        const entityIdSortOrder = sortEntityIds(
          Object.values(allEntities),
          selectActiveSorters(state)
        )
        return { ...state, allEntities, entityIdSortOrder }
      }
      case EntityActions.SET_DISPLAYED_ENTITIES:
        return {
          ...state,
          visibleEntityIds: action.payload,
          selectedEntityId: null, // Reset selected entity when searching
        }
      case EntityActions.RESET_DISPLAYED_ENTITIES: {
        const allEntityIds = parseEntityMapToBoolMap(state.allEntities)
        return { ...state, visibleEntityIds: allEntityIds }
      }
      case EntityActions.SET_SORTED_ENTITIES:
        return { ...state, entityIdSortOrder: action.payload }
      case EntityActions.TOGGLE_SELECTED_ENTITY: {
        const entityId = action.payload
        const selectedEntityId =
          entityId === state.selectedEntityId ? null : entityId
        return { ...state, selectedEntityId }
      }
      case EntityActions.SET_ENTITY_SORTER:
        return Object.assign({}, state, {
          entitySorter: action.payload,
        })
      case EntityActions.SET_SORTERS: {
        const newState = { ...state, currentSorters: action.payload }
        const sortedEntities = selectAllOrderedEntities(newState)
        const selectedSorters = selectActiveSorters(newState)
        return {
          ...newState,
          entityIdSortOrder: sortEntityIds(sortedEntities, selectedSorters),
        }
      }
      case EntityActions.SET_FILTERS:
        return Object.assign({}, state, { currentFilters: action.payload })
      default:
        return state
    }
  }

  return useReducer(entityReducer, {
    allEntities: {},
    visibleEntityIds: {},
    selectedEntityId: null,
    entityIdSortOrder: [],
    entitySorter: {},
    currentSorters: [''],
    currentFilters: [''],
    ...initialState,
  })
}
