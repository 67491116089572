import { Checkbox, FormControlLabel, useTheme } from '@mui/material'
import { OrgMfaStatus } from '@synop-react/api'

type MfaRequiredCheckboxProps = {
  mfaStatus: OrgMfaStatus | null
  onChange: (isChecked: boolean) => void
}

export const MfaRequiredCheckbox = ({
  mfaStatus,
  onChange,
}: MfaRequiredCheckboxProps) => {
  const { palette } = useTheme()

  const isMfaRequired = mfaStatus === 'ON_REQUIRED'
  const isMfaEnabled = mfaStatus === 'ON_NOT_REQUIRED' || isMfaRequired

  return isMfaEnabled ? (
    <FormControlLabel
      control={
        <Checkbox
          checked={mfaStatus === 'ON_REQUIRED'}
          onChange={(e, checked) => onChange(checked)}
          size="small"
          sx={{ pl: 2 }}
        />
      }
      label="Require Multi-factor Authentication for all users."
      slotProps={{
        typography: {
          variant: 'body2',
          color: palette.text.secondary,
          pl: 1,
        },
      }}
    />
  ) : null
}
