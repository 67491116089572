import { defaultEntityName } from './entity'
import { VehicleModel } from '@synop-react/api'

type UnknownVehicle = Pick<
  VehicleModel,
  'vehicleNm' | 'make' | 'model' | 'modelYear' | 'id'
>

export const formatVehicleName = (
  { vehicleNm, make, model, modelYear, id } = {} as UnknownVehicle
) => {
  if (vehicleNm) return vehicleNm
  else if (make && model && modelYear) return `${modelYear} ${make} ${model}`
  else if (id) defaultEntityName('Vehicle', id)
  return 'Unknown Vehicle'
}
