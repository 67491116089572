import { Cask, CaskProps } from '../../Cask'
import { Children, ReactChild, ReactNode, useMemo } from 'react'
import { DayjsConstructor } from '../../utils/date/dateHelpers'
import { useFormat } from '../../utils/hooks'
import StatusCaskItem from './Item'
import StatusCaskRow from './Row'

export type StatusCaskProps = {
  title: string
  lastUpdated: DayjsConstructor
  children: ReactNode
  Footer?: ReactNode
} & CaskProps

export function StatusCask({
  title,
  lastUpdated,
  children,
  Footer,
  ...rest
}: StatusCaskProps) {
  const { formatDateTime } = useFormat()

  const ROW_SIZE = 4
  const itemRows = useMemo(() => {
    const StatusItems = Children.toArray(children) as ReactChild[]

    const items = StatusItems.reduce((arr, detail, i) => {
      const rowIndex = Math.floor(i / ROW_SIZE)
      const row = arr[rowIndex] || ([] as ReactChild[])
      arr[rowIndex] = [...row, detail]
      return arr
    }, [] as ReactChild[][])

    return items.map((rowItems, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <StatusCaskRow key={`row-${index}`}>{rowItems}</StatusCaskRow>
    ))
  }, [children])

  return (
    <Cask
      Footer={Footer}
      subtitle={
        (lastUpdated && formatDateTime(lastUpdated).asTimeOnDate) || undefined
      }
      title={title}
      {...rest}
    >
      {itemRows}
    </Cask>
  )
}

StatusCask.Item = StatusCaskItem

export default StatusCask
