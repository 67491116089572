import { Controller, useFormContext } from 'react-hook-form'
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton,
} from '@mui/material'

type ToggleButtonInfo = { value: string; display: string }
type ToggleButtonProps = {
  id: string
  buttons: Array<ToggleButtonInfo>
  disabled?: boolean
}

export const ToggleButtonGroup = function ({
  id,
  buttons,
  disabled,
}: ToggleButtonProps) {
  return (
    <Controller
      control={useFormContext().control}
      name={id}
      render={({ field }) => {
        return (
          <MuiToggleButtonGroup
            {...field}
            aria-label="text alignment"
            onChange={(e, value) => field.onChange(value)}
          >
            {buttons.map((button) => (
              <ToggleButton
                key={button.value}
                disabled={disabled}
                value={button.value}
              >
                {button.display}
              </ToggleButton>
            ))}
          </MuiToggleButtonGroup>
        )
      }}
    />
  )
}
