import { Button, Switch, Typography } from '@mui/material'
import {
  Cask,
  Edit3,
  emDash,
  LoadingMessage,
  PlusCircle,
  Table,
  useFormat,
  useRouting,
} from '@synop-react/common'
import {
  RootAPI,
  useCurrentOrganization,
  useCurrentUser,
} from '@synop-react/api'

const {
  useGetOrganizationUsersQuery,
  useGetAlertsByOrganizationIdQuery,
  useUpdateAlertByOrganizationsMutation,
} = RootAPI.synopRootAPI

export const AlertsSettings = () => {
  const { navigate, routes } = useRouting()
  const { isAdmin } = useCurrentUser()
  const { currentOrg } = useCurrentOrganization()
  const columns = useColumns()

  const { data: alerts } = useGetAlertsByOrganizationIdQuery(
    {
      organizationId: currentOrg?.id ?? '',
    },
    {
      skip: !isAdmin || !currentOrg,
    }
  )

  if (!isAdmin) {
    return (
      <Cask title="Alerts">
        <Typography variant="body1">
          You do not have permission to view this page.
        </Typography>
      </Cask>
    )
  }

  return (
    <Cask
      Actions={
        <Button
          onClick={() => navigate(routes.alerts.new)}
          startIcon={<PlusCircle />}
          variant="outlined"
        >
          New Alert
        </Button>
      }
      title="Alerts"
    >
      <Typography variant="body1">
        {isAdmin
          ? 'Add or adjust the alerts for your organization'
          : 'View the alerts for your organization'}
        .
      </Typography>

      {alerts ? (
        <Table.ClientSide
          columns={columns}
          height="450px"
          initialSortColumn="alertName"
          noRowsMessage="No alerts have been configured for your organization."
          tableData={alerts}
        />
      ) : (
        <LoadingMessage />
      )}
    </Cask>
  )
}

function useColumns(): Table.ColumnSpec<RootAPI.AlertConfigurationModel> {
  const { formatDateTime } = useFormat()
  const [updateAlert] = useUpdateAlertByOrganizationsMutation()
  const { navigate, routes } = useRouting()
  const { isAdmin } = useCurrentUser()

  const { currentOrg } = useCurrentOrganization()
  const { data: users, isLoading: isUsersLoading } =
    useGetOrganizationUsersQuery(
      {
        id: currentOrg?.id ?? '',
        skipEnhanceData: true,
      },
      { skip: !currentOrg }
    )

  const columns: Table.ColumnSpec<RootAPI.AlertConfigurationModel> = [
    {
      field: 'enabled',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => (
        <Switch
          checked={params.row.enabled}
          onChange={() =>
            updateAlert({
              alertConfigurationModel: {
                ...params.row,
                enabled: !params.row.enabled,
              },
            })
          }
        />
      ),
      tooltip:
        'Toggle between "on" or "off" to control whether an alert is active and should be sending notifications to users. Use this functionality while testing or building a new alert or to turn off an alert during a particular period of time.',
    },
    {
      field: 'alertName',
      headerName: 'Name',
      flex: 2.5,
      tooltip: 'The customizable name of the alert.',
    },
    {
      field: 'assetType',
      headerName: 'Type',
      flex: 0.5,
      tooltip: 'The type of alert (Charger or Vehicle).',
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      tooltip: 'The user who created the alert.',
      valueGetter: (params) =>
        isUsersLoading
          ? emDash
          : users?.find((u: RootAPI.UserModel) => u.id === params.row.createdBy)
              ?.name ?? emDash,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 1,
      type: 'date',
      valueGetter: (params) =>
        params.row.createdOn ? new Date(params.row.createdOn) : '',
      valueFormatter: (params) => formatDateTime(params.value).date,
      tooltip: 'The date the alert was created.',
    },
  ]

  if (isAdmin) {
    columns.push({
      field: 'id',
      headerName: '',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            navigate(routes.alerts.edit(params.row.id))
          }}
        >
          <Edit3 />
        </Button>
      ),
    })
  }

  return columns
}
