import { Button, Menu, MenuItem } from '@mui/material'
import {
  CreateChargerChargingRateOverlay,
  EditChargerChargingRateOverlay,
} from '../RateOverlays/ChargerChargingRateOverlay'
import { DeactivateChargerChargingRateOverlay } from '../RateOverlays/ChargerChargingRateOverlay/DeactivateChargerChargingRateOverlay'
import { DeactivateSiteChargingRateOverlay } from '../RateOverlays/SiteChargingRateOverlay/DeactivateSiteChargingRateOverlay'
import { EditSiteChargingRateOverlay } from '../RateOverlays/SiteChargingRateOverlay'
import {
  EnhancedChargerChargingRate,
  EnhancedSiteChargingRate,
  RootAPI,
  useCurrentOrgId,
} from '@synop-react/api'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useMemo, useState } from 'react'
import { useOverlay } from '@synop-react/common'

type SelectedRateMenuProps = {
  disabled?: boolean
  selectedRate?: EnhancedSiteChargingRate
}

export const SelectedRateMenu = ({
  disabled,
  selectedRate,
}: SelectedRateMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const currentOrgId = useCurrentOrgId()

  const [updateChargingRate] = RootAPI.useUpdateWorkplaceChargingRatesMutation()

  const editSiteRateOverlay = useOverlay()
  const deactivateSiteRateOveraly = useOverlay()
  const addChargerRateOverlay = useOverlay()
  const editChargerRateOverlay = useOverlay()
  const deactivateChargerRateOveraly = useOverlay()

  const openDeactivateOverlay = useMemo(() => {
    return selectedRate?.targetType === 'SITE'
      ? deactivateSiteRateOveraly.openOverlay
      : deactivateChargerRateOveraly.openOverlay
  }, [deactivateChargerRateOveraly, deactivateSiteRateOveraly, selectedRate])

  const closeMenu = () => setAnchorEl(null)

  const activateRate = () => {
    if (selectedRate) {
      const { rateStructure, rateNm, rateType, rateMappingId } = selectedRate
      updateChargingRate({
        rateMappingId,
        updateWorkplaceChargingRate: {
          rateMappingId,
          organizationId: currentOrgId,
          rateStatus: 'ACTIVE',
          defaultRateStructure: {
            rateNm,
            rateType,
            rateStatus: 'ACTIVE',
            rateMappingId,
            rateStructure,
          },
        },
      })
      closeMenu()
    }
  }

  const isOpen = Boolean(anchorEl)

  return (
    <>
      <EditSiteChargingRateOverlay
        chargingRate={selectedRate as EnhancedSiteChargingRate} //This isn't selectable unless there is a selectedRate
        {...editSiteRateOverlay}
      />
      <DeactivateSiteChargingRateOverlay
        chargingRate={selectedRate as EnhancedSiteChargingRate} //This isn't selectable unless there is a selectedRate
        {...deactivateSiteRateOveraly}
      />
      <CreateChargerChargingRateOverlay
        overrideRate={selectedRate as EnhancedSiteChargingRate} //This isn't selectable unless there is a selectedRate
        {...addChargerRateOverlay}
      />
      <EditChargerChargingRateOverlay
        chargingRate={selectedRate as EnhancedChargerChargingRate} //This isn't selectable unless there is a selectedRate
        {...editChargerRateOverlay}
      />
      <DeactivateChargerChargingRateOverlay
        chargingRate={selectedRate as EnhancedChargerChargingRate} //This isn't selectable unless there is a selectedRate
        {...deactivateChargerRateOveraly}
      />

      <Button
        disabled={disabled}
        endIcon={<KeyboardArrowDown />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="outlined"
      >
        Options
      </Button>
      <Menu anchorEl={anchorEl} onClose={closeMenu} open={isOpen}>
        <MenuItem
          onClick={() => {
            const editOverlay =
              selectedRate?.targetType === 'SITE'
                ? editSiteRateOverlay
                : editChargerRateOverlay

            editOverlay.openOverlay()
            closeMenu()
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={selectedRate?.targetType === 'CHARGER'}
          onClick={() => {
            addChargerRateOverlay.openOverlay()
            closeMenu()
          }}
        >
          Override
        </MenuItem>
        {selectedRate?.rateStatus === 'ACTIVE' ? (
          <MenuItem onClick={openDeactivateOverlay}>Deactivate</MenuItem>
        ) : (
          <MenuItem onClick={activateRate}>Activate</MenuItem>
        )}
      </Menu>
    </>
  )
}
