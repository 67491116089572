import dayjs, { Dayjs } from 'dayjs'

type DateFactory = (
  hour: number,
  minute?: number,
  relativeDays?: number
) => Dayjs
export const dateFactory: DateFactory = (hour, minute = 0, relativeDays = 0) =>
  dayjs().add(relativeDays, 'day').set('hour', hour).set('minute', minute)

export const isoDateFactory = (...dateFactorParams: Parameters<DateFactory>) =>
  dateFactory(...dateFactorParams).toISOString()

export const unixDateFactory = (...dateFactorParams: Parameters<DateFactory>) =>
  dateFactory(...dateFactorParams).valueOf()
