import { Cask } from '@synop-react/common'
import { ReactNode, useRef } from 'react'
import { Skeleton } from '@mui/material'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'

interface ChargerPanelProps<T> {
  Actions?: ReactNode
  Footer?: ReactNode
  data: T[]
  isSuccess: boolean
  rowContent: (index: number, data: T) => ReactNode
  subtitle: string
  title: string
  isFetching?: boolean
}

function ChargerPanel<T>({
  data,
  isSuccess,
  rowContent,
  subtitle,
  title,
  Actions,
  Footer,
  isFetching = false,
}: ChargerPanelProps<T>) {
  const virtuosoHandleRef = useRef<VirtuosoHandle>(null)

  if (!isSuccess || !data) {
    return <Skeleton height="100%" variant="rectangular" width="100%" />
  }

  return (
    <Cask
      Actions={Actions}
      Footer={Footer}
      isFetching={isFetching}
      stackProps={{ sx: { width: '100%' } }}
      subtitle={subtitle}
      subtitleGridProps={{ md: 12 }}
      sx={{ display: 'flex', height: '100%' }}
      title={title}
    >
      <Virtuoso
        ref={virtuosoHandleRef}
        data={data}
        itemContent={rowContent}
        style={{ flex: 1, marginTop: 0 }}
      />
    </Cask>
  )
}

export default ChargerPanel
