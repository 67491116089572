import { Chip } from '@mui/material'
import {
  GridValidRowModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium'

import {
  emDash,
  formatDistanceSourceAttribute,
  formatVehicleName,
  ReportField,
  Table,
  useFormat,
} from '@synop-react/common'
import { getStatusLabelFromSourceAttribute } from '../../utils/formatters/formatVehicleStatusDetail'
import { Narrow } from '@synop-react/types'
import {
  PercentSourceAttribute,
  useOrgSites,
  useOrgVehicles,
  VehicleModel,
  vehicleStatuses,
  VehicleStatusSourceAttribute,
} from '@synop-react/api'
import { VehicleLink } from '@synop-react/common'
import VehicleSocPill from '../../VehicleSocPill/VehicleSocPill'

export const useVehicleFields = <T extends GridValidRowModel>(
  idKey: Extract<Narrow.MaybeStringKeyOf<T>, string>
) => {
  const { orgVehicles } = useOrgVehicles()
  const { orgSites } = useOrgSites()

  const vehicleName: ReportField<T> = {
    csv: [
      idKey,
      'vehicle_name',
      (id: string) => (id && orgVehicles[id]?.vehicleNm) || emDash,
    ],
    column: {
      field: idKey,
      headerName: 'Vehicle Name',
      minWidth: 332,
      flex: 1,
      groupable: false,
      renderCell: ({ row }) => <VehicleLink id={row[idKey]} />,
      valueGetter: ({ row }) => formatVehicleName(orgVehicles[row[idKey]]),
    },
  }

  const vin: ReportField<T> = {
    csv: [
      idKey,
      'vin',
      (vehicleId: string) =>
        (vehicleId && orgVehicles[vehicleId]?.vin) || emDash,
    ],
    column: {
      field: 'vin',
      headerName: 'VIN',
      minWidth: 200,
      flex: 2,
      groupable: false,
      renderCell: ({ value }) => value ?? emDash,
      valueGetter: ({ row }) => orgVehicles[row[idKey]]?.vin,
      sortable: true,
    },
  }

  const fleet: ReportField<T> = {
    csv: [
      idKey,
      'fleet',
      (id: string) => (id && orgVehicles[id]?.fleetNm) || emDash,
    ],
    column: {
      field: 'fleetNm',
      headerName: 'Fleet',
      minWidth: 150,
      flex: 2,
      renderCell: ({ value }) => value ?? emDash,
      sortable: true,
    },
  }

  const homeSite: ReportField<T> = {
    csv: [
      idKey,
      'home_site',
      (id: string) =>
        orgSites[orgVehicles[id]?.homeSiteId ?? '']?.depotNm ?? '',
    ],
    column: {
      field: 'homeSiteId',
      headerName: 'Home Site',
      minWidth: 150,
      flex: 2,
      renderCell: ({ value: homeSiteId }) =>
        orgSites[homeSiteId]?.depotNm ?? '',
      sortable: true,
    },
  }

  // TODO: Commented out until Distance Remaining is working
  // const distanceUntilHome: ReportField<T> = {
  //   csv: [
  //     defaultIdKey,
  //     'distance_until_home',
  //     (id: string) => (id && orgVehicles[id]?.distanceUntilHome.value) || emDash,
  //   ],
  //   column: {
  //     align: 'left',
  //     headerAlign: 'left',
  //     field: 'distanceUntilHome',
  //     headerName: 'Distance Remaining',
  //     flex: 2,
  //     valueGetter: (params: GridValueGetterParams<DistanceSourceAttribute>) =>
  //       params.row.distanceUntilHome?.value,
  //     renderCell: ({ row }) =>
  //       formatDistanceSourceAttribute(row.distanceUntilHome),
  //     type: 'number',
  //     sortable: true,
  //   },
  // }

  return {
    vehicleName,
    vin,
    fleet,
    homeSite,
    // TODO: Commented out until Distance Remaining is working
    // distanceUntilHome,
  }
}

type VehicleModelFieldName =
  | 'vehicleName'
  | 'vin'
  | 'homeSite'
  | 'vehicleStatus'
  | 'stateOfCharge'
  | 'modelYear'
  | 'make'
  | 'model'
  | 'remainingRange'
  | 'telematicsTimestamp'
  | 'fleet'

export function useVehicleModelFields(): Record<
  VehicleModelFieldName,
  ReportField<VehicleModel>
> {
  const { formatDateTime } = useFormat()
  const { orgVehicles } = useOrgVehicles()

  return {
    ...useVehicleFields<VehicleModel>('id'),

    vehicleStatus: {
      csv: [
        'id',
        'vehicle_status',
        (id: string) => (id && orgVehicles[id]?.vehicleStatus.value) || emDash,
      ],
      column: {
        field: 'vehicleStatus',
        headerName: 'Status',
        flex: 2,
        minWidth: 120,
        renderCell: (params) => {
          return (
            <Chip
              label={getStatusLabelFromSourceAttribute({ value: params.value })}
              variant="outlined"
            />
          )
        },
        valueGetter: (
          params: GridValueFormatterParams<VehicleStatusSourceAttribute>
        ) => getStatusLabelFromSourceAttribute(params.value),
        groupingValueGetter: ({ value: sourceAttribute }) =>
          getStatusLabelFromSourceAttribute(sourceAttribute),
        type: 'singleSelect',
        sortable: true,
        valueOptions: [...vehicleStatuses],
      },
    },

    stateOfCharge: {
      csv: [
        'id',
        'state_of_charge',
        (id: string) => (id && orgVehicles[id]?.stateOfCharge.value) || emDash,
      ],
      column: {
        align: 'left',
        headerAlign: 'left',
        field: 'stateOfCharge',
        headerName: 'State of Charge',
        flex: 2,
        minWidth: 200,
        groupable: false,
        renderCell: (vehicle) => <VehicleSocPill {...vehicle.row} />,
        valueGetter: ({
          value: sourceAttribute,
        }: GridValueFormatterParams<PercentSourceAttribute>) =>
          sourceAttribute?.value,
        groupingValueGetter: ({ value: sourceAttribute }) =>
          Math.floor(sourceAttribute?.value || 0),
        sortable: true,
        type: 'number',
      },
    },

    modelYear: {
      csv: [
        'id',
        'model_year',
        (id: string) => (id && orgVehicles[id]?.modelYear) || emDash,
      ],
      column: {
        field: 'modelYear',
        align: 'left',
        headerAlign: 'left',
        headerName: 'Year',
        flex: 2,
        minWidth: 100,
        valueFormatter: ({ value: modelYear }) => modelYear || emDash,
        groupingValueGetter: ({ value: modelYear }) => modelYear || emDash,
        sortable: true,
        type: 'number',
      },
    },

    make: {
      csv: [
        'id',
        'make',
        (vehicleId: string) =>
          (vehicleId && orgVehicles[vehicleId]?.make) || emDash,
      ],
      column: {
        field: 'make',
        headerName: 'Make',
        flex: 2,
        minWidth: 100,
        valueFormatter: ({ value: make }) => make || emDash,
        groupingValueGetter: ({ value: make }) => make || emDash,
        sortable: true,
      },
    },

    model: {
      csv: [
        'id',
        'model',
        (id: string) => (id && orgVehicles[id]?.model) || emDash,
      ],
      column: {
        field: 'model',
        headerName: 'Model',
        flex: 2,
        minWidth: 100,
        valueFormatter: ({ value: model }) => model || emDash,
        groupingValueGetter: ({ value: model }) => model || emDash,
        sortable: true,
      },
    },

    remainingRange: {
      csv: [
        'id',
        'remaining_range',
        (id: string) => (id && orgVehicles[id]?.remainingRange.value) || emDash,
      ],
      column: {
        align: 'left',
        headerAlign: 'left',
        field: 'remainingRange',
        headerName: 'Range Remaining',
        flex: 2,
        groupable: false,
        valueGetter: (params) => params.row.remainingRange?.value,
        renderCell: (params) => {
          return formatDistanceSourceAttribute(params.row.remainingRange, {
            sigFigs: 0,
          })
        },
        type: 'number',
        sortable: true,
      },
    },

    telematicsTimestamp: {
      csv: [
        'id',
        'telematics_timestamp',
        (id: string) => (id && orgVehicles[id]?.telematicsTimestamp) || emDash,
      ],
      column: {
        field: 'telematicsTimestamp',
        headerName: 'Last Heard',
        flex: 2,
        minWidth: 200,
        groupable: false,
        renderCell: (params) =>
          formatDateTime(params.row.telematicsTimestamp).timeDotDate,
        type: 'dateTime',
        valueGetter: Table.dateValueGetter,
        sortable: true,
      },
    },

    fleet: {
      csv: ['id', 'fleetNm', (fleetNm: string) => fleetNm || emDash],
      column: {
        field: 'fleetNm',
        headerName: 'Fleet',
        flex: 2,
        minWidth: 150,
        valueFormatter: ({ value: fleetNm }) => fleetNm || emDash,
        groupingValueGetter: ({ value: fleetNm }) => fleetNm || emDash,
        sortable: true,
      },
    },
  }
}
