import { Card, Grid, IconButton } from '@mui/material'
import {
  ControlProps,
  useAlertPropertyContext,
} from '../AlertPropertyInitializedContext'
import { defaultDelayProperty, TimeControl } from './TimeControl'
import { FC } from 'react'
import { formlessSimpleAutocompleteFactory, Plus, X } from '@synop-react/common'
import { NonEmptyArray } from '@synop-react/types'
import { Rule } from '../../AlertSteps'

type ChainControlProps = {
  MainControl: FC<ControlProps>
  chainableProperties: NonEmptyArray<string>
}

type OnChangeProps = {
  newMainProperty?: Rule
  newDelayProperty?: Rule
  newSecondaryProperty?: Rule
}

export function chainControlFactory(chainControlProps: ChainControlProps) {
  return function ChainControl(props: ControlProps) {
    const allProps = { ...chainControlProps, ...props }
    const { chainableProperties, MainControl, property, updateProperty } =
      allProps

    const { propertyFieldMap, propertyIdControlMap } = useAlertPropertyContext()

    // Basic control with chainer button
    if ('field' in property) return <SinglePropertyControl {...allProps} />

    /* Chain control with delay and secondary property */

    // Get and type check the main property for structure
    const mainProperty = property.rules.find((rule) => 'field' in rule)
    if (!mainProperty || 'rules' in mainProperty) return null

    // Get and type check the secondary property rule group
    const subProperties = property.rules[1]
    if (!subProperties || 'field' in subProperties) return null

    // Get and type check the delay and secondary property
    const delayProperty = subProperties.rules.find(
      (rule) => 'field' in rule && rule.field === 'delay'
    )
    const secondaryProperty = subProperties.rules.find(
      (rule) => 'field' in rule && rule.field !== 'delay'
    )
    if (!delayProperty || 'rules' in delayProperty) return null
    if (!secondaryProperty || 'rules' in secondaryProperty) return null

    const onChange = ({
      newMainProperty = mainProperty,
      newDelayProperty = delayProperty,
      newSecondaryProperty = secondaryProperty,
    }: OnChangeProps) => {
      updateProperty({
        condition: 'and',
        rules: [
          newMainProperty,
          {
            condition: 'and',
            rules: [newDelayProperty, newSecondaryProperty],
          },
        ],
      })
    }

    const ChainableFieldSelect = formlessSimpleAutocompleteFactory(
      chainableProperties.flatMap((id) => {
        const control = propertyIdControlMap[id]
        if (!control) return []

        const { displayName, propertyName } = control.model
        return [{ name: displayName, id: propertyName }]
      })
    )

    const secondaryPropertyModel = propertyFieldMap[secondaryProperty.field]
    const SecondaryPropertyControl =
      propertyIdControlMap[secondaryPropertyModel?.id ?? '']?.component

    return (
      <>
        <MainControl
          property={mainProperty}
          updateProperty={(newProperty) => {
            if ('rules' in newProperty) return
            onChange({ newMainProperty: newProperty })
          }}
        />

        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={11}>
                <TimeControl
                  includeThenAfter
                  property={delayProperty}
                  updateProperty={(newProperty) => {
                    if ('rules' in newProperty) return
                    onChange({ newDelayProperty: newProperty })
                  }}
                />
              </Grid>

              <Grid
                item
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                xs={1}
              >
                <IconButton
                  edge="start"
                  onClick={() => updateProperty({ ...mainProperty })}
                  size="small"
                  sx={{ p: 0 }}
                >
                  <X />
                </IconButton>
              </Grid>

              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
                xs={3}
              >
                <ChainableFieldSelect
                  disableClearable
                  disabled={chainableProperties.length === 1}
                  label="Field"
                  onChange={(newValue) => {
                    const propertyId = propertyFieldMap[newValue ?? '']?.id
                    if (!propertyId) return

                    const control = propertyIdControlMap[propertyId]
                    if (!control) return

                    onChange({
                      newSecondaryProperty: { ...control.defaultProperty },
                    })
                  }}
                  value={secondaryProperty.field}
                />
              </Grid>

              {SecondaryPropertyControl && (
                <SecondaryPropertyControl
                  isSecondary
                  property={secondaryProperty}
                  updateProperty={(newProperty) => {
                    if ('rules' in newProperty) return
                    onChange({ newSecondaryProperty: newProperty })
                  }}
                />
              )}
            </Grid>
          </Card>
        </Grid>
      </>
    )
  }
}

const SinglePropertyControl = ({
  property,
  updateProperty,
  MainControl,
  chainableProperties,
  isSecondary = false,
}: ChainControlProps & ControlProps) => {
  const { propertyIdControlMap } = useAlertPropertyContext()
  const { defaultProperty } = propertyIdControlMap[chainableProperties[0]] ?? {}
  return (
    <>
      <MainControl property={property} updateProperty={updateProperty} />
      {!isSecondary && (
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
          <IconButton
            edge="start"
            onClick={() =>
              updateProperty({
                condition: 'and',
                rules: [
                  { ...property },
                  {
                    condition: 'and',
                    rules: [
                      { ...defaultDelayProperty },
                      { ...(defaultProperty as Rule) },
                    ],
                  },
                ],
              })
            }
            size="small"
          >
            <Plus />
          </IconButton>
        </Grid>
      )}
    </>
  )
}
