import * as yup from 'yup'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { FieldError, FormProvider, useForm } from 'react-hook-form'
import { RootAPI, useCurrentOrgId, useCurrentUser } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  createOverlay,
  FormField,
  OrgAutocomplete,
  Overlay,
  useOverlayContext,
  useSnackbarControls,
} from '@synop-react/common'
import { isEmpty } from 'lodash'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useEffect, useMemo } from 'react'

const {
  useGetWorkplaceUserQuery,
  useGetOrganizationQuery,
  useSaveWorkplaceUserMutation,
  useDeleteWorkplaceUserMutation,
  useUpdateWorkplaceUserMutation,
} = RootAPI

type CreateOrUpdateWorkplacePayorForm = {
  organization: RootAPI.OrganizationModel
  rfidTags: string[]
} & Pick<
  RootAPI.WorkplaceChargingUserModel,
  'id' | 'firstNm' | 'lastNm' | 'email' | 'externalId' | 'userId'
>

export type AddWorkplaceChargingUserProps = {
  workplaceUserId: string | null
}

const createWorkplaceChargingPayor = yup
  .object({
    firstNm: yup.string().required('First name is required'),
    lastNm: yup.string().required('Last name is required'),
    email: yup.string().required('Valid email is required'),
    organization: yup.object().nullable().required('Organization is required'),
    externalId: yup.string(),
  })
  .required()

const WrappedTextFormField = (
  props: FormField.TextFormFieldProps<CreateOrUpdateWorkplacePayorForm>
) => <FormField.TextFormField fullWidth {...props} />

export const CreateOrEditWorkplaceChargingPayorOverlay =
  createOverlay<AddWorkplaceChargingUserProps>(({ workplaceUserId }) => {
    const curOrganizationId = useCurrentOrgId()
    const { synopUser } = useCurrentUser()
    const { closeOverlay } = useOverlayContext()
    const { openSnackbar } = useSnackbarControls()

    const { data: workplaceUser } = useGetWorkplaceUserQuery(
      {
        workplaceUserId: workplaceUserId ?? '',
      },
      {
        skip: !workplaceUserId,
      }
    )

    const orgId = useCurrentOrgId()

    const { data: userOrg } = useGetOrganizationQuery(
      { id: (workplaceUser?.organizationId ?? orgId) as string },
      { skip: !workplaceUser?.organizationId && !orgId }
    )

    const [configureWorkplaceUser, configureWorkplaceUserResponse] =
      useSaveWorkplaceUserMutation()
    const [updateWorkplaceUser, updateWorkplaceUserResponse] =
      useUpdateWorkplaceUserMutation()

    const [deleteWorkplaceUser, deleteWorkplaceUserResponse] =
      useDeleteWorkplaceUserMutation()

    const formMethods = useForm<CreateOrUpdateWorkplacePayorForm>({
      mode: 'all',
      defaultValues: {
        firstNm: '',
        lastNm: '',
        email: '',
        externalId: '',
        organization: userOrg,
      },
      resolver: yupResolver(createWorkplaceChargingPayor),
    })
    const {
      control,
      formState: { errors, touchedFields },
      handleSubmit,
      reset,
      watch,
    } = formMethods

    useEffect(() => {
      if (
        configureWorkplaceUserResponse.status === QueryStatus.fulfilled ||
        configureWorkplaceUserResponse.status === QueryStatus.rejected
      ) {
        closeOverlay()
        if (configureWorkplaceUserResponse.status === QueryStatus.fulfilled) {
          openSnackbar('Workplace user has been created.')
        }
        reset()
      }
    }, [reset, configureWorkplaceUserResponse, openSnackbar, closeOverlay])

    useEffect(() => {
      if (
        updateWorkplaceUserResponse.status === QueryStatus.fulfilled ||
        updateWorkplaceUserResponse.status === QueryStatus.rejected
      ) {
        closeOverlay()
        if (updateWorkplaceUserResponse.status === QueryStatus.fulfilled) {
          openSnackbar('Workplace user has been updated.')
        }
        reset()
      }
    }, [closeOverlay, openSnackbar, reset, updateWorkplaceUserResponse])

    useEffect(() => {
      if (
        deleteWorkplaceUserResponse.status === QueryStatus.fulfilled ||
        deleteWorkplaceUserResponse.status === QueryStatus.rejected
      ) {
        closeOverlay()
        if (deleteWorkplaceUserResponse.status === QueryStatus.fulfilled) {
          openSnackbar('Workplace user has been deleted.')
        }
        reset()
      }
    }, [closeOverlay, deleteWorkplaceUserResponse, openSnackbar, reset])

    const options = useMemo(
      () =>
        (
          workplaceUser?.ocppTags?.filter(
            (tag) =>
              tag.useCase === 'WORKPLACE_CHARGING' && tag.ocppTagType === 'RFID'
          ) ?? []
        ).map((option) => option?.ocppTagValue ?? ''),
      [workplaceUser?.ocppTags]
    )

    useEffect(() => {
      if (!workplaceUser) return

      const { id, firstNm, lastNm, email, externalId, userId } = workplaceUser
      reset({
        id,
        firstNm,
        lastNm,
        email,
        externalId,
        userId,
        organization: userOrg,
        rfidTags: options,
      })
    }, [reset, workplaceUser, userOrg, options])

    const selectedTagValues = watch('rfidTags') ?? []
    const userTags: RootAPI.UserTagModel[] = selectedTagValues.map((tag) => {
      const userTag = workplaceUser?.ocppTags?.find(
        (t) => t.ocppTagValue === tag
      )
      if (userTag) return userTag
      return {
        ocppTagType: 'RFID',
        ocppTagValue: tag,
        useCase: 'WORKPLACE_CHARGING',
        created: new Date().toISOString(),
        createdBy: synopUser?.id ?? '',
      }
    })

    const onCreateSubmit = ({
      organization: { id: organizationId },
      ...workplaceUserFields
    }: CreateOrUpdateWorkplacePayorForm) => {
      configureWorkplaceUser({
        createWorkplaceChargingUser: {
          ...workplaceUserFields,
          organizationId,
          ocppTags: userTags,
        },
      })
    }

    const onUpdateSubmit = ({
      organization: { id: organizationId },
      ...workplaceUserFields
    }: CreateOrUpdateWorkplacePayorForm) => {
      updateWorkplaceUser({
        workplaceUserId: workplaceUser?.id ?? '',
        workplaceChargingUserModel: {
          ...workplaceUserFields,
          organizationId,
          ocppTags: userTags,
        },
      })
    }

    const updating = workplaceUserId !== null
    const onSubmit = handleSubmit(updating ? onUpdateSubmit : onCreateSubmit)
    const onDelete = () =>
      deleteWorkplaceUser({ workplaceUserId: workplaceUserId ?? '' })

    return (
      <Overlay>
        <Overlay.Header
          title={
            updating
              ? 'Edit Workplace Charging Payor'
              : 'Add Workplace Charging Payor'
          }
        />

        <Overlay.Content>
          <form onSubmit={onSubmit}>
            <FormProvider {...formMethods}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <WrappedTextFormField
                    control={control}
                    error={errors.firstNm}
                    id="firstNm"
                    label="First Name"
                    touched={Boolean(touchedFields.firstNm)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WrappedTextFormField
                    control={control}
                    error={errors.lastNm}
                    id="lastNm"
                    label="Last Name"
                    touched={Boolean(touchedFields.lastNm)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <WrappedTextFormField
                    control={control}
                    error={errors.email}
                    id="email"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <WrappedTextFormField
                    control={control}
                    id="externalId"
                    label="External ID"
                  />
                </Grid>
                <Grid item xs={6}>
                  <OrgAutocomplete.Select
                    control={control}
                    error={errors.organization as FieldError}
                    id="organization"
                    label="Organization"
                    orgId={curOrganizationId}
                    touchedField={Boolean(touchedFields.organization)}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Workplace RFID Tags</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormField.WrappedAutocompleteMultiselectFormField
                      freeSolo
                      id="rfidTags"
                      label="Enter RFID Tags"
                      loading={false}
                      options={[]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </FormProvider>
          </form>
        </Overlay.Content>

        <Overlay.Actions sx={{ px: 2 }}>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={1}>
              <Button
                disabled={!isEmpty(errors)}
                onClick={onSubmit}
                variant="contained"
              >
                {updating ? 'Save Changes' : 'Add Payor'}
              </Button>
              <Button onClick={closeOverlay}>Cancel</Button>
            </Stack>
            {updating && (
              <Button color="error" onClick={onDelete} variant="outlined">
                Delete Payor
              </Button>
            )}
          </Stack>
        </Overlay.Actions>
      </Overlay>
    )
  })
