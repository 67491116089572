import { Falsey, isEmpty } from 'lodash'
import { NonEmptyArray } from '.'

// Convenience re-export
export { Falsey }

export type Maybe<T> = T | undefined
export type Nullable<T> = T | null

export function isTruthy<T>(x: T | Falsey): x is T {
  return Boolean(x)
}

export function isDefined<T>(value: Maybe<Nullable<T>>): value is T {
  return value !== undefined && value !== null
}

export function isNotEmpty<T>(value: T[]): value is NonEmptyArray<T> {
  return !isEmpty(value)
}
