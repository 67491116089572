import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'

interface PagedResponse<OptionType> {
  items?: OptionType[]
}

interface UseOptionsArgs<ApiArgType, ResponseType> {
  queryArgs: ApiArgType
  useQuery: UseQuery<
    QueryDefinition<
      ApiArgType,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, unknown>,
        FetchBaseQueryMeta
      >,
      string | never,
      ResponseType,
      'api' | 'depot-api' | 'fleet-mgmt-api'
    >
  >
}

export type UseOptionsResponse<OptionType> = {
  isLoading: boolean
  isOpen: boolean
  options: OptionType[]
  setIsOpen: (isOpen: boolean) => void
}

const useOptions = <
  OptionType,
  ApiArgType,
  ResponseType extends OptionType[] | PagedResponse<OptionType>
>({
  queryArgs,
  useQuery,
}: UseOptionsArgs<
  ApiArgType,
  ResponseType
>): UseOptionsResponse<OptionType> => {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([])

  const { data, isFetching, isLoading, isSuccess } = useQuery(
    queryArgs,
    Object.assign(
      {},
      {
        skip: !isOpen,
      }
    )
  )

  useEffect(() => {
    if (!isSuccess || !data) return

    // When the data is a paged response, we need to extract the items
    if ('items' in data) {
      setOptions(data.items as unknown[] as OptionType[])
      return
    }

    // Otherwise, we can just use the data as is
    setOptions(data as unknown[] as OptionType[])
  }, [isSuccess, data])

  useEffect(() => {
    if (isOpen) return

    setOptions([])
  }, [isOpen])

  return {
    isLoading: isFetching || isLoading,
    isOpen,
    options,
    setIsOpen,
  }
}

export default useOptions
