import { useEffect, useState } from 'react'

import { BaseTableCask } from './BaseTableCask'
import { Table } from '../Table'
import { TableCaskProps } from './types'

type ServerSideTableCaskProps<
  T extends Table.ApiArg,
  U extends Table.ApiResponse
> = Omit<TableCaskProps<Table.ServerSideDatum<U>>, 'tableData'> & {
  TableProps: Omit<Table.ServerSideProps<T, U>, 'columns'>
}

function ServerSideTableCask<
  T extends Table.ApiArg,
  U extends Table.ApiResponse
>({ columns, TableProps, ...rest }: ServerSideTableCaskProps<T, U>) {
  const [visibleData, setVisibleData] = useState<Table.ServerSideData<U>>([])
  const [visibleColumns, setVisibleColumns] = useState(columns)

  // Update the columns if they change
  useEffect(() => {
    setVisibleColumns(columns)
  }, [columns])

  return (
    <BaseTableCask
      {...rest}
      columns={visibleColumns}
      setVisibleColumns={setVisibleColumns}
      setVisibleData={setVisibleData}
      tableData={visibleData}
      visibleColumns={visibleColumns}
      visibleData={visibleData}
    >
      <Table.ServerSide
        columns={visibleColumns.filter((col) => col.isDefaultVisible ?? true)}
        {...{
          setData: setVisibleData,
          ...TableProps,
        }}
      />
    </BaseTableCask>
  )
}

export { ServerSideTableCask as ServerSide }
