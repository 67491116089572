import { CaptionTitle } from '../Event/Caption/TimelineEventCaption'
import { EventOptions } from '..'
import { EventOverlayRenderer } from '../useTimeline/Provider'
import { humanizeScheduledDuration } from '../../../utils/formatters/time'
import { useFormat } from '../../../utils/hooks'
import { useTheme } from '@mui/material'
import { useUserPrefs } from '@synop-react/api'

type UseAvailableOptionProps = {
  renderOverlay?: EventOverlayRenderer
}

const useAvailableOption = ({
  renderOverlay,
}: UseAvailableOptionProps): Pick<EventOptions, 'IDLE'> => {
  const { tzDayjs } = useUserPrefs()
  const theme = useTheme()
  const { formatDateTime } = useFormat()

  const availableTitle: CaptionTitle = ({ scheduledEnd = tzDayjs() }) =>
    tzDayjs(scheduledEnd).isBefore(tzDayjs()) ? 'Idle' : 'Available'

  return {
    IDLE: {
      barColor: theme.palette.text.disabled,
      fillOpacity: '0.5',
      caption: {
        title: availableTitle,
        titleDescription: ({ scheduledStart, scheduledEnd = tzDayjs() }) =>
          humanizeScheduledDuration(scheduledStart, scheduledEnd, tzDayjs),
      },
      tooltip: {
        title: availableTitle,
        details: [
          ({ scheduledStart }) => ({
            label: 'From',
            detail: formatDateTime(scheduledStart).timeOnDate,
          }),
          ({ scheduledEnd }) => ({
            label: 'To',
            detail: formatDateTime(scheduledEnd).timeOnDate,
          }),
        ],
      },
      ...{ renderOverlay },
    },
  }
}

export default useAvailableOption
