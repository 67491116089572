import { rest } from 'msw'
import mockData from '../data'

export const vehiclesHandlers = [
  rest.get('/api/vehicles/:id', (req, res, ctx) => {
    return res(ctx.json(mockData.vehicles.vehicles[0]))
  }),
  rest.get('/api/organizations/:id/vehicles', (req, res, ctx) => {
    return res(ctx.json(mockData.vehicles.vehicles))
  }),
]
