import { Grid, styled, Typography } from '@mui/material'
import { Icon } from 'react-feather'

type AuthPageHeaderProps = {
  subtitle?: string
  title: string
  Icon?: Icon
}

export const AuthPageHeader = ({
  subtitle,
  title,
  Icon,
}: AuthPageHeaderProps) => {
  return (
    <>
      <Grid container item spacing={1} sx={{ alignItems: 'center' }}>
        {Icon && (
          <Grid item>
            <HeaderIcon Icon={Icon} />
          </Grid>
        )}
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
      </Grid>
      {subtitle && (
        <Grid item>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Grid>
      )}
    </>
  )
}

function HeaderIcon({ Icon }: { Icon: Icon | undefined }) {
  if (!Icon) {
    return null
  }

  const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette['text'].primary,
    strokeWidth: 2,
  }))

  return <StyledIcon />
}
