import { useMemo } from 'react'

import { parseToEntityMap } from '../api/utils'
import { RootAPI, useCurrentOrgId } from '..'

const { useGetLocationsOfInterestForOrganizationQuery } = RootAPI.synopRootAPI

type UseOrgLocationsArgs = { orgId: string }

export const useLocationsOfInterest = (args?: UseOrgLocationsArgs) => {
  const currentOrgId = useCurrentOrgId()
  const { orgId = currentOrgId } = args ?? {}
  const { isSuccess, isLoading, data } =
    useGetLocationsOfInterestForOrganizationQuery(
      {
        organizationId: orgId,
      },
      { skip: !orgId }
    )

  const parsedLocations = useMemo(
    () =>
      parseToEntityMap<RootAPI.LocationOfInterestResponseModel>(
        data ?? [],
        'id'
      ),
    [data]
  )

  return {
    orgLocations: parsedLocations.entities,
    isLoading,
    isSuccess,
  }
}
