import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form'
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers'

import { Dayjs } from 'dayjs'
import { TextFieldProps } from '@mui/material'
import { useUserPrefs } from '@synop-react/api'

export type DateTimePickerProps<FormData extends FieldValues> = {
  control: Control<FormData, unknown>
  id: Path<FormData>
  error?: FieldError
  touchedField?: boolean
  variant?: Pick<TextFieldProps, 'variant'>['variant']
} & Partial<MuiDateTimePickerProps<Date | Dayjs>>

const DateTimePicker = function <FormData extends FieldValues>({
  control,
  id,
  variant = 'outlined',
  ...datePickerProps
}: DateTimePickerProps<FormData>) {
  const { tzDayjs, preferredTimeFormat, preferredDateFormat } = useUserPrefs()

  const timeFormat =
    preferredTimeFormat === 'hh:mm' ? 'hh:mm a' : preferredTimeFormat
  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { value, ...field }, fieldState }) => (
        <MuiDateTimePicker
          {...field}
          {...datePickerProps}
          {...fieldState}
          ampm={preferredTimeFormat === 'hh:mm'}
          format={`${preferredDateFormat} ${timeFormat}`}
          slotProps={{ textField: { id, variant, sx: { width: '100%' } } }}
          value={tzDayjs(value)}
        />
      )}
    />
  )
}

export default DateTimePicker
