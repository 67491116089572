import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import {
  AdaAccessibleSelect,
  ConnectorTypeSelect,
  InstallationTypeSelect,
  NetworkConnectionMethodSelect,
} from '../../Autocomplete/MetadataSelects'
import { ChevronDown } from '../../Icons'
import { ConfigureChargerFormData } from './ConfigureChargerOverlay'
import { FormField } from '../../index'

type MetadataFieldsProps = {
  editingExisting: boolean
  formDefaults: ConfigureChargerFormData
}

export const MetadataFields = ({
  editingExisting,
  formDefaults,
}: MetadataFieldsProps) => {
  const theme = useTheme()
  return (
    <Accordion
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: theme.palette.grey[300],
        borderRadius: 1,
        px: 2,
      }}
    >
      <AccordionSummary expandIcon={<ChevronDown />} sx={{ p: 0, height: 3 }}>
        <Typography variant="subtitle2">Metadata</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 1, pb: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <NetworkConnectionMethodSelect
              id="networkConnectionMethod"
              label="Network Connection Method"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormField.WrappedTextFormField
              id="networkConnectionDetails"
              label="Network Connection Details"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormField.WrappedTextFormField id="externalId" label="Asset ID" />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormField.WrappedTextFormField
              id="utilityLocationId"
              label="Utility Location ID"
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormField.DatePicker
              error={false}
              id="commissioningDate"
              label="Commissioning Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InstallationTypeSelect
              id="evseInstallationType"
              label="Installation Type"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AdaAccessibleSelect id="isAdaAccessible" label="ADA Accessible" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormField.WrappedTextFormField
              id="latitude"
              label="Latitude"
              placeholder="If blank, will default to site latitude"
              type="number"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormField.WrappedTextFormField
              id="longitude"
              label="Longitude"
              placeholder="If blank, will default to site longitude"
              type="number"
            />
          </Grid>
          {editingExisting &&
            Object.keys(formDefaults).map((formField) => {
              if (!formField.startsWith('connector')) return null
              return (
                <Grid key={formField} container item spacing={2} xs={12}>
                  <Grid
                    item
                    sx={{ display: 'flex', alignItems: 'center' }}
                    xs={6}
                  >
                    <Typography variant="subtitle2">
                      Connector {formField.replace('connector', '')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ConnectorTypeSelect
                      id={formField as `connector${string}`}
                      label="Connector Type"
                    />
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
