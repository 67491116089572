import {
  ChargerOverviewTile,
  ChargerSummary,
  HistoricalStatusSummaryCharts,
} from '@synop-react/depot'
import {
  DashboardHeader,
  OrgSelector,
  OrgSelectorsProvider,
  SiteSelector,
  StatusColorProvider,
  TimeRangeProvider,
} from '@synop-react/common'
import { Grid } from '@mui/material'
const ChargerDashboardPage = () => {
  return (
    <TimeRangeProvider
      defaultTimeResolution="WEEK"
      ranges={['DAY', 'WEEK', 'MONTH', 'CUSTOM']}
      syncToLocalStorage
    >
      <OrgSelectorsProvider syncToLocalStorage>
        <StatusColorProvider>
          <Grid container spacing={2} sx={{ px: 5, justifyContent: 'center' }}>
            <DashboardHeader
              controls={[<OrgSelector />, <SiteSelector />]}
              showTimerange
              title="Energy"
            />
            <ChargerOverviewTile />
            <HistoricalStatusSummaryCharts />
            <Grid item xs={12}>
              <ChargerSummary />
            </Grid>
          </Grid>
        </StatusColorProvider>
      </OrgSelectorsProvider>
    </TimeRangeProvider>
  )
}

export default ChargerDashboardPage
