import { chargers } from './chargers'
import { isoDateFactory } from './utils'
import { ScheduledEvent } from '../../@types'

const [, charger2, charger3] = chargers

export const singleScheduledCharges: ScheduledEvent[] = [
  {
    id: '2ca0894381a5db560181bbe772010000',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: charger2.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(1, 0, -1),
    scheduledEnd: isoDateFactory(6, 30, -1),
    eventId: '1',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 64',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010001',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: charger2.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(10, 0, -1),
    scheduledEnd: isoDateFactory(12, 0, -1),
    eventId: '2',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 64',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010003',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: charger2.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(11),
    scheduledEnd: isoDateFactory(16),
    eventId: '3',
    eventType: 'AD_HOC_CHARGE',
    eventTargetNm: 'Bus 64',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
]

export const multipleScheduledCharges: ScheduledEvent[] = [
  {
    id: '2ca0894381a5db560181bbe772010003',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: charger2.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(9, 0, -1),
    scheduledEnd: isoDateFactory(14, 30, -1),
    eventType: 'TIMED_CHARGE',
    eventId: '1',
    eventTargetNm: 'Bus 2',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010004',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0003',
    chargerId: charger2.chargerId,
    dispenserId: '2',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(1, 0, -1),
    scheduledEnd: isoDateFactory(8, 45, -1),
    eventType: 'TIMED_CHARGE',
    eventId: '2',
    eventTargetNm: 'Bus 3',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010007',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0004',
    chargerId: charger2.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(12, 0),
    scheduledEnd: isoDateFactory(15, 30),
    eventId: '3',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 4',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010005',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: charger3.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(17, 0, -1),
    scheduledEnd: isoDateFactory(21, 30, -1),
    eventId: '4',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 2',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010006',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0003',
    chargerId: charger3.chargerId,
    dispenserId: '2',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(16, 45, -1),
    scheduledEnd: isoDateFactory(23, 0, -1),
    eventId: '5',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 3',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010087',
    depotId: 'testDepot1',
    assetId: '2ca0857a81490d850181490eeeab0005',
    chargerId: charger3.chargerId,
    dispenserId: '1',
    status: 'SCHEDULED',
    scheduledStart: isoDateFactory(13),
    scheduledEnd: isoDateFactory(13, 45),
    eventId: '6',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 66',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
]
