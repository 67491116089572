import {
  collectionToCSV,
  getFieldColumns,
  useReport,
  UseReportProps,
} from '@synop-react/common'
import {
  CoordinateSourceAttribute,
  DistanceSourceAttribute,
  EfficiencySourceAttribute,
  EmissionsSourceAttribute,
  EnergySourceAttribute,
  PercentSourceAttribute,
  RootAPI,
  useUserPrefs,
} from '@synop-react/api'
import { useMemo } from 'react'
import { useTripFields, useTripReportFields, useVehicleFields } from './fields'

const { useGetTripReportQuery } = RootAPI.synopRootAPI

export type TripReport = {
  id: string
  vehicleId: string
  distanceDriven: DistanceSourceAttribute
  energyUsed: EnergySourceAttribute
  efficiency: EfficiencySourceAttribute
  carbonEmissionsSaved: EmissionsSourceAttribute
  tripStart: string
  tripEnd: string
  startStateOfCharge: PercentSourceAttribute
  endStateOfCharge: PercentSourceAttribute
  startLatitude: CoordinateSourceAttribute
  startLongitude: CoordinateSourceAttribute
  endLatitude: CoordinateSourceAttribute
  endLongitude: CoordinateSourceAttribute
}

export type UseTripReportProps = Omit<
  UseReportProps<
    RootAPI.GetVehicleReportApiArg,
    RootAPI.ReportGenerationStatus
  >,
  'useQuery' | 'reportType'
>

export const useTripReport = ({ skip, queryArgs }: UseTripReportProps) => {
  const { tzDayjs } = useUserPrefs()
  const { report = [], ...reportStatus } = useReport<
    RootAPI.GetTripReportApiArg,
    RootAPI.ReportGenerationStatus,
    TripReport
  >({
    useQuery: useGetTripReportQuery,
    queryArgs,
    reportType: 'TRIP',
    skip,
  })

  const { vehicleName, vin } = useVehicleFields<TripReport>('vehicleId')
  const { distanceDriven, energyUsed, carbonEmissionsSaved } =
    useTripReportFields(report[0])
  const {
    startLocation,
    endLocation,
    startStateOfCharge,
    endStateOfCharge,
    tripStart,
    tripEnd,
  } = useTripFields({
    sampleRecord: report[0],
  })

  const { csvColumns, tableColumns } = useMemo(
    () =>
      getFieldColumns(
        vehicleName,
        vin,
        tripStart,
        tripEnd,
        startStateOfCharge,
        endStateOfCharge,
        distanceDriven,
        energyUsed,
        // TODO ADD EFFICIENCY! @wslater
        carbonEmissionsSaved,
        startLocation,
        endLocation
      ),
    [
      carbonEmissionsSaved,
      distanceDriven,
      endLocation,
      endStateOfCharge,
      energyUsed,
      startLocation,
      startStateOfCharge,
      tripEnd,
      tripStart,
      vehicleName,
      vin,
    ]
  )

  const activityCSVBlob = useMemo(() => {
    const eventCSV = collectionToCSV(csvColumns)(report)
    return new Blob([eventCSV], { type: 'text/csv' })
  }, [csvColumns, report])

  const { from, to } = queryArgs

  const activityCSVOption = {
    extension: '.csv' as const,
    blob: activityCSVBlob,
    filename: `TRIPS_${tzDayjs(from).format()}_${tzDayjs(to).format()}`,
  }

  return {
    data: report,
    columns: tableColumns,
    ...reportStatus,
    csvOption: activityCSVOption,
  }
}
