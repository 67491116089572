import { GeoJsonProperties } from 'geojson'
import {
  GeoJSONSource,
  MapboxGeoJSONFeature,
  MapProvider,
  MapRef,
  Source,
} from 'react-map-gl'

import { Marker } from 'mapbox-gl'
import { MutableRefObject, useMemo } from 'react'

import { map, mapValues } from 'lodash'
import {
  selectDisplayedEntities,
  selectSelectedEntity,
} from '../Browser/useEntityBrowser/useEntityReducer'
import { SynopMap } from '../../Map'
import {
  useBrowserSelector,
  useEntityBrowser,
} from '../Browser/useEntityBrowser'
import { useTheme } from '@mui/material'

type ClusterProperty = (string | (string | number | mapboxgl.Expression)[])[]

export const EntityMap = () => {
  const { palette } = useTheme()

  const {
    mapConfig: {
      OverviewMapLayers: MapLayers,
      getCoords,
      layerIds,
      layerFilters,
      MapLegend,
    },
    toggleSelectedEntity,
    visibleEntityIds,
  } = useEntityBrowser()
  const displayedEntities = useBrowserSelector(selectDisplayedEntities)
  const selectedEntity = useBrowserSelector(selectSelectedEntity)

  const displayedEntitiesCollection = useMemo(
    () => getCoords(displayedEntities),
    [displayedEntities, getCoords]
  )

  const boundedFeatures = useMemo(
    () =>
      selectedEntity !== null
        ? getCoords({ [selectedEntity.id]: selectedEntity })
        : displayedEntitiesCollection,
    // Only updated the bounded displayed entities when the filtered / selected entities change
    // This allows polling updates to not change the map view
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleEntityIds, selectedEntity]
  )

  const mapSourceId = 'entityMapSource'

  const clusterProperties = mapValues(
    layerFilters,
    ({ filter }): ClusterProperty => ['+', ['case', filter, 1, 0]]
  )

  const colors = layerFilters
    ? map(layerFilters, 'color')
    : [palette.secondary.main, palette.warning.main, palette.error.main]

  const clusterPropertyKeys = Object.keys(clusterProperties)
  const clusterProps = layerFilters
    ? {
        cluster: true,
        clusterProperties: clusterProperties,
        clusterRadius: 60,
        clusterMaxZoom: 15,
      }
    : {}

  const createDonut = (props: GeoJsonProperties) => {
    const offsets: number[] = []
    const counts: number[] = clusterPropertyKeys.map((key) => props?.[key] ?? 0)

    let total = 0
    for (const count of counts) {
      offsets.push(total)
      total += count
    }
    const fontSize =
      total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16
    const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18
    const r0 = Math.round(r * 0.8)
    const w = r * 2

    let html = `<div>
<svg width='${w}' height='${w}' viewbox='0 0 ${w} ${w}' text-anchor='middle' style='font: ${fontSize}px sans-serif; display: block'>`

    for (let i = 0; i < counts.length; i++) {
      const segmentStart = offsets[i] ?? 0
      const segmentCount = counts[i] ?? 0
      html += donutSegment(
        segmentStart / total,
        (segmentStart + segmentCount) / total,
        r,
        r0,
        colors[i] ?? '#FFFFFF'
      )
    }
    html += `<circle cx='${r}' cy='${r}' r='${r0}' fill='white' />
              <text dominant-baseline='central' transform='translate(${r}, ${r})'>
                ${total.toLocaleString()}
              </text>
              </svg>
              </div>`

    const el = document.createElement('div')
    el.innerHTML = html
    return el.firstChild
  }

  const donutSegment = (
    start: number,
    end: number,
    r: number,
    r0: number,
    color: string
  ) => {
    if (end - start === 1) end -= 0.00001
    const a0 = 2 * Math.PI * (start - 0.25)
    const a1 = 2 * Math.PI * (end - 0.25)
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0)
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1)
    const largeArc = end - start > 0.5 ? 1 : 0

    // draw an SVG path
    return `<path d='M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
      r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
      r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
      r + r0 * y0
    }' fill='${color}' />`
  }

  // keep only one event subscription
  let eventSubscription: unknown = null
  const renderClusters = (mapRef: MutableRefObject<MapRef | null>) => {
    const markers: Record<string, Marker> = {}
    let markersOnScreen: Record<string, Marker> = {}

    if (!eventSubscription) {
      eventSubscription = mapRef.current?.on('moveend', () => {
        if (!mapRef.current?.getMap().isSourceLoaded(mapSourceId)) return

        const newMarkers: Record<string, Marker> = {}
        const features = mapRef.current
          ?.getMap()
          .querySourceFeatures(mapSourceId)

        for (const feature of features) {
          if (feature.geometry.type === 'Point') {
            const coords = feature.geometry['coordinates']
            const props = feature.properties

            if (!props?.['cluster']) {
              continue
            }

            const id = props['cluster_id']
            let marker = markers[id]

            if (!marker) {
              const el = createDonut(props)

              if (!el) {
                return
              }

              el.addEventListener('click', () => {
                if (!mapRef.current) return

                const clusterSource = mapRef.current.getSource(
                  mapSourceId
                ) as GeoJSONSource

                clusterSource.getClusterExpansionZoom(
                  id,
                  (err, minZoomLevel) => {
                    if (err) return
                    const zoom = minZoomLevel + 2
                    mapRef.current?.easeTo({
                      center: coords as [number, number],
                      zoom,
                    })
                  }
                )
              })

              marker = markers[id] = new Marker({
                element: el as HTMLElement,
              }).setLngLat(coords as [number, number])
            }

            newMarkers[id] = marker as Marker

            if (!markersOnScreen[id]) {
              marker.addTo(mapRef.current?.getMap())
            }
          }
        }

        Object.entries(markersOnScreen).forEach(([id, marker]) => {
          if (!newMarkers[id]) {
            marker.remove()
          }
        })

        markersOnScreen = newMarkers
      })

      setTimeout(() => {
        // Force invoke moveend event in mapbox
        mapRef.current?.getMap().fire('moveend')
      }, 500)
    }
  }

  const selectTopFeature = (
    features: MapboxGeoJSONFeature[],
    mapRef: MutableRefObject<MapRef | null>
  ) => {
    const topFeature = features[0]

    const featureProperties = topFeature?.properties

    if (featureProperties) {
      const isClustered = 'point_count' in featureProperties

      if (isClustered && mapRef.current) {
        const clusterId = featureProperties['cluster_id']

        const clusterSource = mapRef.current.getSource(
          mapSourceId
        ) as GeoJSONSource

        clusterSource.getClusterExpansionZoom(
          clusterId,
          (err, minZoomLevel) => {
            if (err) return
            if (topFeature.geometry.type === 'Point') {
              const zoom = minZoomLevel + 2
              mapRef.current?.easeTo({
                center: topFeature.geometry.coordinates as [number, number],
                zoom,
              })
            }
          }
        )
      } else if (featureProperties['id']) {
        toggleSelectedEntity(featureProperties['id'])
      }
    }
  }

  return (
    <MapProvider>
      <SynopMap
        boundedData={boundedFeatures}
        defaultMaxZoom={18}
        id="entityMap"
        onLayerClick={selectTopFeature}
        onMapLoad={renderClusters}
        onStyleDataAction={renderClusters}
        selectableSourceId={mapSourceId}
        selectedFeatureId={selectedEntity?.id}
        tooltipLayers={layerIds}
      >
        <Source
          {...clusterProps}
          data={displayedEntitiesCollection}
          id={mapSourceId}
          lineMetrics={true}
          promoteId="id" // Allows us to set the 'id' from the GeoJSON feature as the 'id' on the mapbox feature
          type="geojson"
        >
          {MapLayers}
        </Source>
        {MapLegend}
      </SynopMap>
    </MapProvider>
  )
}
