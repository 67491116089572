import { ControlProps } from '../AlertPropertyInitializedContext'
import {
  formlessSimpleAutocompleteFactory,
  simpleDropdownFactory,
  SimpleDropdownOption,
} from '@synop-react/common'
import { Grid } from '@mui/material'

type StringControlProps = {
  label: string
  operators: SimpleDropdownOption<string>[]
  options: SimpleDropdownOption<string>[]
}

export function stringControlFactory(stringControlProps: StringControlProps) {
  return function StringControl(props: ControlProps) {
    const { property, updateProperty, label, operators, options } = {
      ...stringControlProps,
      ...props,
    }

    // String control can only edit a single property
    if ('rules' in property) return null

    const OperatorComponent = simpleDropdownFactory(operators)
    const OptionComponent = formlessSimpleAutocompleteFactory(options)
    if (operators.length === 1 && options.length === 1) return null

    return (
      <Grid container item spacing={2} xs={9}>
        <Grid alignSelf="flex-end" item xs="auto">
          <OperatorComponent
            disabled={operators.length === 1}
            onChange={(newValue) => {
              if (newValue) updateProperty({ ...property, operator: newValue })
            }}
            value={property.operator}
          />
        </Grid>

        <Grid item sm={4} xs={6}>
          <OptionComponent
            disableClearable
            disabled={options.length === 1}
            label={label}
            onChange={(newValue) => {
              if (newValue) updateProperty({ ...property, value: newValue })
            }}
            value={property.value}
          />
        </Grid>
      </Grid>
    )
  }
}
