import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'
import { find, orderBy } from 'lodash'
import { useMemo } from 'react'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { FormField } from '../../index'
import { RootAPI } from '@synop-react/api'

const DEFAULT_MANUFACTURER_COUNT = 100

export type ChargerManufacturerAutocompleteSelectProps<
  FormData extends FieldValues
> = AutocompleteFormControls<FormData, RootAPI.ChargerManufacturerModel> &
  AutocompleteTextFieldProps

export const ChargerManufacturerAutocompleteSelect = <
  FormData extends FieldValues
>({
  ...formControls
}: ChargerManufacturerAutocompleteSelectProps<FormData>) => {
  const manufacturerOptions = FormField.useOptions<
    RootAPI.ChargerManufacturerModel,
    RootAPI.GetManufacturersApiArg,
    RootAPI.PagedResponseChargerManufacturerModel
  >({
    queryArgs: { count: DEFAULT_MANUFACTURER_COUNT },
    useQuery: RootAPI.synopRootAPI.useGetManufacturersQuery,
  })
  const { options, ...rest } = manufacturerOptions

  // Sort the options by name, but move the "Unlisted" option to the top
  const orderedOptions = useMemo(() => {
    const ordered = orderBy(options, 'name')
    const unlisted = find(ordered, { name: 'Unlisted' })
    if (unlisted) {
      const withoutUnlisted = ordered.filter((option) => option !== unlisted)
      return [unlisted, ...withoutUnlisted]
    } else {
      return ordered
    }
  }, [options])

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.ChargerManufacturerModel>
      label="OEM / Manufacturer"
      options={orderedOptions}
      {...rest}
      {...formControls}
      filterOptions={(options, { inputValue }) =>
        options.filter(({ name: manufacturerName }) => {
          const name = manufacturerName?.toLowerCase() || ''
          const searchTerm = inputValue.toLowerCase()
          return name.includes(searchTerm)
        })
      }
      getOptionLabel={(option: RootAPI.ChargerManufacturerModel) =>
        option?.name || ''
      }
      keyExtractor={(option: RootAPI.ChargerManufacturerModel) =>
        option?.id || ''
      }
    />
  )
}

const selectSchema = yup
  .object({ id: yup.string().required() })
  .nullable()
  .required('OEM is required')

export {
  ChargerManufacturerAutocompleteSelect as Select,
  selectSchema as Schema,
}
