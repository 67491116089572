import { emDash } from './constants'
import { omitFalsey } from '../omitFalsey'

export type FormatNumberOptions = {
  locale?: string
  suffix?: string
} & Intl.NumberFormatOptions

const defaultOptions: FormatNumberOptions = {
  locale: undefined, // defer to the user's locale
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

export const formatNumber = (
  value?: number | string | null,
  options = defaultOptions
): string => {
  if (value === undefined || value === null) return emDash

  const valueToFormat = typeof value === 'string' ? parseFloat(value) : value

  const { locale, suffix, ...formatOptions } = {
    ...defaultOptions,
    ...options,
  }

  const formattedNumber = valueToFormat.toLocaleString(locale, formatOptions)

  return omitFalsey([formattedNumber, suffix]).join(' ')
}
