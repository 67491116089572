import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { selectRandomLoadingMessage } from './loadingMessages'

type LoadingMessageProps = {
  loadingMessage?: string
  height?: string
}

export function LoadingMessage({
  loadingMessage,
  height = '100vh',
}: LoadingMessageProps) {
  const message = loadingMessage ? loadingMessage : selectRandomLoadingMessage()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
      }}
    >
      <Stack sx={{ alignItems: 'center' }}>
        <CircularProgress />
        <Typography>{message}</Typography>
      </Stack>
    </Box>
  )
}

export default LoadingMessage
