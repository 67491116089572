import { FieldValues, useFormContext } from 'react-hook-form'
import { useState } from 'react'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  FormField,
} from '..'

type SimpleSelectProps<OptionType> = {
  label: string
  disableClearable?: boolean
  tooltip?: string
} & Omit<AutocompleteFormControls<FieldValues, OptionType>, 'control'> &
  AutocompleteTextFieldProps

export function simpleSelectFactory<T extends string>(options: T[]) {
  return function SimpleSelect(props: SimpleSelectProps<T>) {
    const { control } = useFormContext()
    const [isOpen, setIsOpen] = useState(false)

    return (
      <FormField.AutocompleteSelect
        {...props}
        control={control}
        getOptionLabel={(option) => option}
        isLoading={false}
        isOpen={props.disabled ? false : isOpen}
        keyExtractor={(option) => option}
        options={options}
        setIsOpen={setIsOpen}
      />
    )
  }
}
