import * as yup from 'yup'
import { Button, Grid, InputAdornment } from '@mui/material'
import {
  FormField,
  OverlayDeprecated,
  useOverlayContext,
  useSnackbarControls,
} from '@synop-react/common'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { RootAPI } from '@synop-react/api'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

export type ApplyReimbursementUpdateProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedUsers: Array<string>
}
const BulkEditReimbursementRateOverlay = ({
  isOpen,
  setIsOpen,
  selectedUsers,
}: ApplyReimbursementUpdateProps) => {
  const { openSnackbar } = useSnackbarControls()
  const { closeOverlay } = useOverlayContext()
  const [
    bulkReimbursementConfiguration,
    bulkReimbursementConfigurationResponse,
  ] = RootAPI.useBulkUpdateReimbursementUsersMutation()

  const bulkUpdateRate = yup
    .object({
      reimbursementRate: yup
        .number()
        .required('The rate is required.')
        .typeError('Rate must be a number')
        .positive('Rate must be positive'),
    })
    .required()

  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
    reset,
  } = useForm<BulkUpdateReimbursementConfiguration>({
    defaultValues: {
      reimbursementRate: 0,
    },
    resolver: yupResolver(bulkUpdateRate),
  })

  const onSubmit = ({
    reimbursementRate,
  }: BulkUpdateReimbursementConfiguration) => {
    bulkReimbursementConfiguration({
      bulkReimbursementConfiguration: {
        reimbursementUserIds: selectedUsers,
        rate: reimbursementRate,
      } as RootAPI.BulkReimbursementConfiguration,
    })
  }

  useEffect(() => {
    if (
      bulkReimbursementConfigurationResponse.status === QueryStatus.fulfilled
    ) {
      setIsOpen(false)
      openSnackbar(`Reimbursement rate updated has been applied.`)
      closeOverlay()
    }
  }, [
    reset,
    bulkReimbursementConfigurationResponse,
    openSnackbar,
    closeOverlay,
    setIsOpen,
  ])

  return (
    <OverlayDeprecated
      isOpen={isOpen}
      OverlayActions={[
        <Button
          color="primary"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
        >
          Apply
        </Button>,
      ]}
      setIsOpen={setIsOpen}
      subtitle="Apply updates to users enrolled in reimbursements"
      title="Adjust Reimbursement Rate"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <FormField.TextFormField
              control={control}
              error={errors.reimbursementRate}
              fullWidth
              id="reimbursementRate"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">/ kWh</InputAdornment>
                ),
              }}
              label="Rate"
              touched={Boolean(touchedFields.reimbursementRate)}
              type={'number'}
              variant="standard"
            />
          </Grid>
        </Grid>
      </form>
    </OverlayDeprecated>
  )
}

interface BulkUpdateReimbursementConfiguration {
  reimbursementUserIds: string[]
  reimbursementRate?: number
}

export default BulkEditReimbursementRateOverlay
