import { ReactNode, useMemo } from 'react'
import { styled } from '@mui/material'

type DownloadLinkProps = {
  filename: string
  blob: Blob
  children?: ReactNode
  isOneOff?: boolean
}

export function DownloadLink({
  filename,
  blob,
  isOneOff = true,
  children,
}: DownloadLinkProps) {
  const blobUrl = useMemo(() => URL.createObjectURL(blob), [blob])

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const cleanupFile = function () {
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl)
    }, 150)
  }

  const handleClick = () => {
    const cleanup = isOneOff ? cleanupFile : () => null
    cleanup()
  }

  return (
    <StyledAnchor
      download={filename || 'download'}
      href={blobUrl}
      onClick={handleClick}
    >
      {children}
    </StyledAnchor>
  )
}

const StyledAnchor = styled('a')`
  text-decoration: none;
`
