import { FillLayer } from 'react-map-gl'

export const geojsonLayer: FillLayer = {
  id: 'depotFence',
  type: 'fill',
  source: 'depotFence', // reference the data source
  layout: {},
  paint: {
    'fill-color': '#ffc107', // blue color fill
    'fill-opacity': 0.25,
  },
}
