import { hourlyResolutions } from '../TimeRange'
import { TimeRangeCask, TimeRangeCaskProps } from '../TimeRangeCask'

export type UtilizationChartCaskProps = {} & Omit<
  TimeRangeCaskProps,
  'rangeControl'
>

const UtilizationChartCask = ({
  defaultTimeResolution = 'TWELVE_HOURS',
  ranges = hourlyResolutions,
  ...caskProps
}: UtilizationChartCaskProps) => {
  return (
    <TimeRangeCask
      rangeControl="Button"
      {...{ defaultTimeResolution, ranges }}
      {...caskProps}
    />
  )
}

export default UtilizationChartCask
