import {
  DistanceSourceAttribute,
  EfficiencySourceAttribute,
  EmissionsSourceAttribute,
  EnergySourceAttribute,
  FuelSourceAttribute,
} from '@synop-react/api'
import {
  formatDistanceSourceAttribute,
  formatEfficiencyFromSourceAttributes,
  formatEmissionsFromSourceAttributes,
  formatEnergyFromSourceAttributes,
  formatFuelFromSourceAttributes,
  getFloatFromSourceAttribute,
  isSourceAttributeValue,
  ReportField,
} from '@synop-react/common'
import { TripReport } from '../useTripReport'
import { useMemo } from 'react'

const replaceSlash = (value: unknown) => {
  if (typeof value !== 'string') return ''
  return value.replace(/\//g, '_per_')
}

const replaceSpace = (value: unknown) => {
  if (typeof value !== 'string') return ''
  return value.replace(/ /g, '_')
}

const formatCsvTitle = (value: unknown) => replaceSpace(replaceSlash(value))

type SampleRecord = Record<string, unknown>
type UnitsObject = Record<string, string | undefined>

function useUnits(sampleRecord: SampleRecord) {
  return useMemo(
    () =>
      Object.entries(sampleRecord).reduce<UnitsObject>(
        (acc, [key, reportAttribute]) => {
          if (isSourceAttributeValue(reportAttribute)) {
            acc[key] = reportAttribute.units
          }
          return acc
        },
        {}
      ),
    [sampleRecord]
  )
}

type VehicleActivityFields = TotalTripsObject &
  DistanceDrivenObject &
  EnergyUsedObject &
  EnergyAddedObject &
  CarbonEmissionsSavedObject &
  EfficiencyObject

export const useVehicleActivityFields = <T extends VehicleActivityFields>(
  sampleRecord: SampleRecord = {}
) => {
  const units = useUnits(sampleRecord)
  return {
    totalTrips: useTotalTrips<T>(),
    distanceDriven: useDistanceDriven<T>(units),
    energyUsed: useEnergyUsed<T>(units),
    energyAdded: useEnergyAdded<T>(units),
    carbonEmissionsSaved: useCarbonEmissionsSaved<T>(units),
    efficiency: useEfficiency<T>(units),
  }
}

export function useTripReportFields(sampleRecord: SampleRecord = {}) {
  const units = useUnits(sampleRecord)
  return {
    distanceDriven: useDistanceDriven<TripReport>(units),
    energyUsed: useEnergyUsed<TripReport>(units),
    carbonEmissionsSaved: useCarbonEmissionsSaved<TripReport>(units),
  }
}

export type VehicleMetrics = {
  id: string
  totalTrips: number
  numDrivingDays: number
  fuelSaved: FuelSourceAttribute
  energyUsed: EnergySourceAttribute
  efficiency: EfficiencySourceAttribute
  distanceDriven: DistanceSourceAttribute
  carbonEmissionsSaved: EmissionsSourceAttribute
}

export const useVehicleMetricsFields = (sampleRecord: SampleRecord = {}) => {
  const units = useUnits(sampleRecord)
  return {
    totalTrips: useTotalTrips<VehicleMetrics>(),
    carbonEmissionsSaved: useCarbonEmissionsSaved<VehicleMetrics>(units),
    distanceDriven: useDistanceDriven<VehicleMetrics>(units),
    drivingDays: useDrivingDays<VehicleMetrics>(),
    efficiency: useEfficiency<VehicleMetrics>(units),
    energyUsed: useEnergyUsed<VehicleMetrics>(units),
    fuelSaved: useFuelSaved<VehicleMetrics>(units),
  }
}

type DistanceDrivenObject = { distanceDriven: DistanceSourceAttribute }
function useDistanceDriven<T extends DistanceDrivenObject>(
  units: UnitsObject
): ReportField<T> {
  return {
    csv: [
      'distanceDriven',
      `distance_driven_in_${units['distanceDriven']}`,
      (distanceDriven: DistanceSourceAttribute) =>
        `${getFloatFromSourceAttribute(distanceDriven).toFixed(2)}`,
    ],
    column: {
      align: 'left',
      field: 'distanceDriven',
      headerAlign: 'left',
      headerName: `Distance Traveled`,
      sortable: true,
      minWidth: 135,
      valueGetter: ({ row }) => row.distanceDriven?.value,
      renderCell: ({ row }) =>
        formatDistanceSourceAttribute(row.distanceDriven),
      type: 'number',
      flex: 1,
    },
  }
}

type CarbonEmissionsSavedObject = {
  carbonEmissionsSaved: EmissionsSourceAttribute
}
function useCarbonEmissionsSaved<T extends CarbonEmissionsSavedObject>(
  units: UnitsObject
): ReportField<T> {
  return {
    csv: [
      'carbonEmissionsSaved',
      `carbon_saved_in_${formatCsvTitle(units['carbonEmissionsSaved'])}`,
      (carbonSaved: EmissionsSourceAttribute) =>
        `${getFloatFromSourceAttribute(carbonSaved)}`,
    ],
    column: {
      align: 'left',
      field: 'carbonEmissionsSaved',
      headerAlign: 'left',
      headerName: 'Carbon Saved',
      sortable: true,
      minWidth: 125,
      flex: 1,
      renderCell: ({ row }) =>
        formatEmissionsFromSourceAttributes(row.carbonEmissionsSaved),
      valueGetter: ({ row }) =>
        row.carbonEmissionsSaved
          ? getFloatFromSourceAttribute(row.carbonEmissionsSaved)
          : 0,
      type: 'number',
    },
  }
}

type EnergyAddedObject = { energyAdded: EnergySourceAttribute }
function useEnergyAdded<T extends EnergyAddedObject>(
  units: UnitsObject
): ReportField<T> {
  return {
    csv: [
      'energyAdded',
      `energy_added_in_${units['energyAdded']}`,
      (energyAdded: EnergySourceAttribute) =>
        `${getFloatFromSourceAttribute(energyAdded)}`,
    ],
    column: {
      align: 'left',
      field: 'energyAdded',
      headerAlign: 'left',
      headerName: `Energy Added`,
      sortable: true,
      flex: 1,
      minWidth: 115,
      renderCell: ({ row }) =>
        formatEnergyFromSourceAttributes(row.energyAdded),
      valueGetter: ({ row }) => getFloatFromSourceAttribute(row.energyAdded),
      type: 'number',
    },
  }
}

type EnergyUsedObject = { energyUsed: EnergySourceAttribute }
function useEnergyUsed<T extends EnergyUsedObject>(
  units: UnitsObject
): ReportField<T> {
  return {
    csv: [
      'energyUsed',
      `energy_used_in_${units['energyUsed']}`,
      (energyUsed: EnergySourceAttribute) =>
        `${getFloatFromSourceAttribute(energyUsed)}`,
    ],
    column: {
      align: 'left',
      field: 'energyUsed',
      headerAlign: 'left',
      headerName: `Energy Used`,
      sortable: true,
      minWidth: 115,
      flex: 1,
      renderCell: ({ row }) => formatEnergyFromSourceAttributes(row.energyUsed),
      valueGetter: ({ row }) => getFloatFromSourceAttribute(row.energyUsed),
      type: 'number',
    },
  }
}

type EfficiencyObject = { efficiency: EfficiencySourceAttribute }
function useEfficiency<T extends EfficiencyObject>(
  units: UnitsObject
): ReportField<T> {
  const efficiencyUnits = units['efficiency']
  const efficiencySuffix = efficiencyUnits ? `_in_${efficiencyUnits}` : ''
  return {
    csv: [
      'efficiency',
      'efficiency' + efficiencySuffix,
      (efficiency: EfficiencySourceAttribute) => {
        const value = getFloatFromSourceAttribute(efficiency)
        return isNaN(value) ? 'null' : value
      },
    ],
    column: {
      align: 'left',
      field: 'efficiency',
      headerAlign: 'left',
      headerName: `Efficiency`,
      sortable: true,
      type: 'number',
      minWidth: 110,
      flex: 1,
      renderCell: ({ row }) =>
        formatEfficiencyFromSourceAttributes(row.efficiency),
      valueGetter: ({ row }) =>
        row.efficiency ? getFloatFromSourceAttribute(row.efficiency) : 0,
    },
  }
}

type FuelSavedObject = { fuelSaved: FuelSourceAttribute }
function useFuelSaved<T extends FuelSavedObject>(
  units: UnitsObject
): ReportField<T> {
  return {
    csv: [
      'fuelSaved',
      `fuel_saved_in_${formatCsvTitle(units['fuelSaved'])}`,
      (fuelSaved: FuelSourceAttribute) =>
        `${getFloatFromSourceAttribute(fuelSaved)}`,
    ],
    column: {
      align: 'left',
      field: 'fuelSaved',
      headerAlign: 'left',
      headerName: 'Fuel Saved',
      sortable: true,
      flex: 1,
      renderCell: ({ row }) => formatFuelFromSourceAttributes(row.fuelSaved),
      valueGetter: ({ row }) => getFloatFromSourceAttribute(row.fuelSaved),
      type: 'number',
    },
  }
}

type TotalTripsObject = { totalTrips?: number }
function useTotalTrips<T extends TotalTripsObject>(): ReportField<T> {
  return {
    csv: ['totalTrips', `total_trips`],
    column: {
      align: 'left',
      field: 'totalTrips',
      headerAlign: 'left',
      headerName: 'Total Trips',
      minWidth: 110,
      sortable: true,
      type: 'number',
      flex: 1,
    },
  }
}

type DrivingDaysObject = { numDrivingDays: number }
function useDrivingDays<T extends DrivingDaysObject>(): ReportField<T> {
  return {
    csv: ['numDrivingDays', 'total_driving_days'],
    column: {
      align: 'left',
      field: 'numDrivingDays',
      headerAlign: 'left',
      headerName: 'Driving Days',
      sortable: true,
      type: 'number',
      flex: 1,
    },
  }
}
