import {
  defaultEntityName,
  DetailPageHeader,
  formatVehicleName,
} from '@synop-react/common'
import { RootAPI } from '@synop-react/api'

export interface TripDetailPageHeaderProps {
  tripDetails: RootAPI.Trip
}
export function TripDetailPageHeader({
  tripDetails,
}: TripDetailPageHeaderProps) {
  const { vehicleId = '', id } = tripDetails
  const { data: vehicle, isLoading } = RootAPI.synopRootAPI.useGetVehicleQuery({
    id: vehicleId,
  })

  const { modelYear, make, model, vin } = vehicle || {}
  const subtitle = [modelYear, make, model, vin].filter(Boolean).join(' • ')
  const vehicleName = formatVehicleName(vehicle)
  const vehicleLink = `/vehicles/${vehicleId}`
  return (
    <DetailPageHeader
      breadcrumbLink={vehicleLink}
      breadcrumbTitle={vehicleName}
      isLoading={isLoading}
      subtitle={subtitle}
      title={defaultEntityName('Trip', id)}
    />
  )
}

export default TripDetailPageHeader
