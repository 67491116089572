import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { FormField } from '../../index'
import { orderBy } from 'lodash'
import { RootAPI } from '@synop-react/api'

const DEFAULT_MAKE_MODEL_COUNT = 100

export type ChargerMakeModelAutocompleteSelectProps<
  FormData extends FieldValues
> = {
  manufacturerId: string
} & AutocompleteFormControls<FormData, RootAPI.ChargerMakeModelResponseModel> &
  AutocompleteTextFieldProps

export const ChargerMakeModelAutocompleteSelect = <
  FormData extends FieldValues
>({
  manufacturerId,
  ...formControls
}: ChargerMakeModelAutocompleteSelectProps<FormData>) => {
  const makeModelOptions = FormField.useOptions<
    RootAPI.ChargerMakeModelResponseModel,
    RootAPI.GetManufacturerMakeModelsApiArg,
    RootAPI.PagedResponseChargerMakeModelResponseModel
  >({
    queryArgs: { manufacturerId, count: DEFAULT_MAKE_MODEL_COUNT },
    useQuery: RootAPI.synopRootAPI.useGetManufacturerMakeModelsQuery,
  })
  const { options, ...rest } = makeModelOptions

  return (
    <FormField.AutocompleteSelect<
      FormData,
      RootAPI.ChargerMakeModelResponseModel
    >
      label="Model"
      options={orderBy(options, 'chargerModel')}
      {...rest}
      {...formControls}
      filterOptions={(options, { inputValue }) =>
        options.filter(({ modelHelperText, chargerModel }) => {
          const model = chargerModel?.toLowerCase() || ''
          const helpText = modelHelperText?.toLowerCase() || ''
          const searchTerm = inputValue.toLowerCase()
          return model.includes(searchTerm) || helpText.includes(searchTerm)
        })
      }
      getOptionLabel={({
        modelHelperText,
        chargerModel,
      }: RootAPI.ChargerMakeModelResponseModel) =>
        '' + chargerModel + (modelHelperText ? ` (${modelHelperText})` : '')
      }
      keyExtractor={(option: RootAPI.ChargerMakeModelResponseModel) =>
        option?.id || ''
      }
    />
  )
}

const selectSchema = yup
  .object({ id: yup.string().required() })
  .nullable()
  .required('Model is required')

export { ChargerMakeModelAutocompleteSelect as Select, selectSchema as Schema }
