import { RootAPI } from '..'

type BaseSA = Omit<Required<RootAPI.SourceAttributeValueBigDecimal>, 'units'>
type UnitFactory<U> = BaseSA & { units: U }

// NOTE: all unit types should be in lowercase, even though (1) they might not
// be that way in the API, and (2) we might not want to display them that way.
// We'll handle the manipulations in other functions but the unit types should
// be lowercase.

// Coordinate units
export type CoordinateUnit = 'degrees'
export type CoordinateSourceAttribute = UnitFactory<CoordinateUnit>

// Distance units
export const distanceUnits = ['km', 'm', 'mi'] as const
export type DistanceUnit = (typeof distanceUnits)[number]
export type DistanceSourceAttribute = UnitFactory<DistanceUnit>
export enum DistanceUnits {
  MILES = 'Mi',
  KILOMETERS = 'Km',
}

// Efficiency units
export const efficiencyUnits = ['km/kwh', 'kwh/km', 'mi/kwh', 'kwh/mi'] as const
export type EfficiencyUnit = (typeof efficiencyUnits)[number]
export type EfficiencySourceAttribute = UnitFactory<EfficiencyUnit>

// Emissions units
export const emissionsUnits = ['kg of CO2', 'lb of CO2'] as const
export type EmissionsUnit = (typeof emissionsUnits)[number]
export type EmissionsSourceAttribute = UnitFactory<EmissionsUnit>

// Energy units
export const energyUnits = ['wh', 'kwh', 'mwh', 'gwh', 'twh', 'pwh'] as const
export type EnergyUnit = (typeof energyUnits)[number]
export type EnergySourceAttribute = UnitFactory<EnergyUnit>

// Fuel units
export const fuelUnits = ['gal', 'L'] as const
export type FuelUnit = (typeof fuelUnits)[number]
export type FuelSourceAttribute = UnitFactory<FuelUnit>

// Percent units
export const percentUnit = 'percent' as const
export type PercentUnit = typeof percentUnit
export type PercentSourceAttribute = UnitFactory<PercentUnit>

// Time units
export type TimeSourceAttribute = UnitFactory<TimeUnits>
export enum TimeUnits {
  MILLISECONDS = 'milliseconds',
  SECONDS = 's',
  MINUTES = 'min',
  HOURS = 'hr',
  DAYS = 'd',
}

// SourceAttribute is the union of all source attribute types
export type SourceAttribute =
  | CoordinateSourceAttribute
  | DistanceSourceAttribute
  | EfficiencySourceAttribute
  | EnergySourceAttribute
  | EmissionsSourceAttribute
  | TimeSourceAttribute
  | PercentSourceAttribute
