import { Layer, LayerProps } from 'react-map-gl'
import { LinePaint } from 'mapbox-gl'

export interface MapLineLayerProps {
  /** 
  A unique id for this layer
*/
  layerId: string
  /** 
  The id for the Source layer that this layer should receive 
*/
  sourceId: string
  /** 
  A color to stroke the line 
*/
  lineColor?: string
  /** 
  [Line Paint](https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-property) properties for the line; this allows using MapLineLayer as base for other components
*/
  linePaint?: LinePaint
  /** 
  Whether the line should be a dashed or solid line 
*/
  isDashed?: boolean
  /** 
  An optional filter for selecting data from a Feature / FeatureCollection from the Source
*/
  filter?: unknown[]
  /** 
  Allows overriding the line width
*/
  defaultLineWidth?: number
  /** 
  The min map zoom level that this layer will be displayed at
*/
  minZoom?: number
  /** 
The max map zoom level that this layer will be displayed at
*/
  maxZoom?: number
}

export const DEFAULT_LINE_WIDTH = 4
export const DEFAULT_DASHED_WIDTH = [1, 2]

export function MapLineLayer({
  layerId,
  sourceId,
  lineColor,
  linePaint,
  minZoom,
  maxZoom,
  isDashed = false,
  defaultLineWidth = DEFAULT_LINE_WIDTH,
  filter = ['all'],
}: MapLineLayerProps) {
  const lineFill = lineColor ? { 'line-color': lineColor } : linePaint
  const dashedLine = isDashed ? { 'line-dasharray': DEFAULT_DASHED_WIDTH } : {}
  const minzoom = minZoom !== undefined ? { minzoom: minZoom } : {}
  const maxzoom = maxZoom !== undefined ? { maxzoom: maxZoom } : {}
  const mapLineLayer: LayerProps = {
    id: layerId,
    type: 'line',
    source: sourceId,
    ...{ ...minzoom },
    ...{ ...maxzoom },
    ...{ filter },
    paint: {
      'line-width': defaultLineWidth,
      ...dashedLine,
      ...lineFill,
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
  }

  return <Layer {...mapLineLayer} />
}

export default MapLineLayer
