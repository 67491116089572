import * as yup from 'yup'
import { Button, Grid, InputAdornment } from '@mui/material'
import { FieldError, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { cloneElement, ReactElement, useEffect, useMemo, useState } from 'react'
import {
  DepotAutocomplete,
  FormField,
  OverlayDeprecated,
  Plus,
  Truck,
} from '@synop-react/common'

import {
  RootAPI,
  useCurrentOrgId,
  useDepotDetailsFromPoll,
} from '@synop-react/api'
import dayjs from 'dayjs'

type CreateOrEditVehicleOverlayProps = {
  vehicleId?: string
  defaultIsOpen?: boolean
  Trigger: ReactElement
}

const schema = yup
  .object({
    vehicleNm: yup.string().nullable().required('Vehicle name is required'),
    make: yup.string().nullable().optional(),
    model: yup.string().nullable().optional(),
    modelYear: yup.string().length(4, 'Vehicle year is required').optional(),
    vin: yup.string().nullable().required('VIN is required'),
    macAddress: yup.string().nullable(),
    batterySize: yup.string().nullable().required('Battery size is required.'),
    estimatedRange: yup.string().nullable().optional(),
    efficiency: yup.string().nullable().optional(),
    homeSite: yup.object({ depotId: yup.string().required() }).nullable(),
  })
  .required()

type EditVehicleFormData = {
  homeSite: RootAPI.DepotModel | null
} & Pick<
  RootAPI.VehicleModel,
  | 'batterySize'
  | 'estimatedRange'
  | 'efficiency'
  | 'macAddress'
  | 'make'
  | 'model'
  | 'modelYear'
  | 'vehicleNm'
  | 'vin'
>

const defaultModelYear = dayjs().year()

export function CreateOrEditVehicleOverlay({
  vehicleId,
  defaultIsOpen = false,
  Trigger,
}: CreateOrEditVehicleOverlayProps) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const orgId = useCurrentOrgId()
  const { data: vehicle, isLoading: isLoadingVehicle } =
    RootAPI.synopRootAPI.useGetVehicleQuery(
      { id: vehicleId ?? '' },
      { skip: !vehicleId }
    )

  const {
    getDepot: { data: homeSite },
  } = useDepotDetailsFromPoll({
    depotId: vehicle?.homeSiteId || '',
    disablePolling: true,
  })

  const {
    control,
    setValue,
    formState: { errors, dirtyFields, isSubmitted },
    handleSubmit,
    reset,
  } = useForm<EditVehicleFormData>({
    defaultValues: {
      batterySize: 0,
      estimatedRange: 0,
      efficiency: 0,
      homeSite: null,
      macAddress: '',
      make: '',
      model: '',
      modelYear: defaultModelYear,
      vehicleNm: '',
      vin: '',
    },
    resolver: yupResolver(schema),
  })

  const [updateVehicle, updatedVehicleResponse] =
    RootAPI.useUpdateVehicleMutation()

  const [createVehicle, createVehicleResponse] =
    RootAPI.useCreateVehicleWithMetadataMutation()

  useEffect(() => {
    if (homeSite) {
      setValue('homeSite', homeSite)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeSite])

  useEffect(() => {
    if (!isLoadingVehicle) {
      const {
        batterySize,
        estimatedRange,
        efficiency,
        macAddress,
        make,
        model,
        modelYear,
        vehicleNm,
        vin,
      } = vehicle || {}
      reset({
        batterySize,
        estimatedRange,
        efficiency,
        homeSite: homeSite || null,
        macAddress,
        make,
        model,
        modelYear: modelYear || defaultModelYear,
        vehicleNm,
        vin,
      })
    }
    // Only reset after the vehicle loads the first time; ignore polling updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVehicle])

  const updating = !!vehicleId
  const onSubmit = useMemo(() => {
    const onUpdateSubmit = ({
      homeSite,
      ...vehicleFields
    }: EditVehicleFormData) => {
      updateVehicle({
        vehicleModel: {
          ...vehicle,
          homeSiteId: homeSite?.depotId,
          ...vehicleFields,
        } as RootAPI.VehicleModel,
      })
    }

    const onCreateSubmit = ({
      homeSite,
      ...vehicleFields
    }: EditVehicleFormData) => {
      createVehicle({
        createVehicleModel: {
          homeSiteId: homeSite?.depotId,
          organizationId: orgId,
          ...vehicleFields,
        },
      })
    }
    return updating ? onUpdateSubmit : onCreateSubmit
  }, [updating, updateVehicle, vehicle, createVehicle, orgId])

  useEffect(() => {
    if (updatedVehicleResponse.isSuccess || createVehicleResponse.isSuccess) {
      setIsOpen(false)
      // openSnackbar(
      //   `Vehicle has been successfully ${updating ? 'updated' : 'created'}.`
      // )
      reset()
    }
  }, [
    updatedVehicleResponse,
    createVehicleResponse,
    setIsOpen,
    // openSnackbar,
    reset,
  ])

  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => {
      setIsOpen(true)
    },
  })

  return (
    <>
      {OverlayTrigger}
      <OverlayDeprecated
        isOpen={isOpen}
        OverlayActions={[
          <Button
            key="save"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </Button>,
        ]}
        setIsOpen={setIsOpen}
        subtitle={
          updating
            ? 'Adjust the vehicle metadata below.'
            : 'Provide the following information to add a new vehicle to your organization.'
        }
        title={updating ? 'Configure Vehicle' : 'New Vehicle'}
        TitleIcon={updating ? Truck : Plus}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.vehicleNm}
                fullWidth
                id="vehicleNm"
                isSubmitted={isSubmitted}
                label="Vehicle Name"
                touched={Boolean(dirtyFields.vehicleNm)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.make}
                fullWidth
                id="make"
                isSubmitted={isSubmitted}
                label="Make"
                touched={Boolean(dirtyFields.make)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.model}
                fullWidth
                id="model"
                isSubmitted={isSubmitted}
                label="Model"
                touched={Boolean(dirtyFields.model)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.YearPicker
                control={control}
                error={errors.modelYear}
                fullWidth
                id="modelYear"
                isSubmitted={isSubmitted}
                label="Year"
                touched={Boolean(dirtyFields.modelYear)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                disabled={updating}
                error={errors.vin}
                fullWidth
                id="vin"
                isSubmitted={isSubmitted}
                label="VIN"
                touched={Boolean(dirtyFields.vin)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                disabled={Boolean(vehicle?.macAddress)}
                error={errors.macAddress}
                fullWidth
                id="macAddress"
                isSubmitted={isSubmitted}
                label="OCPP Tag"
                touched={Boolean(dirtyFields.macAddress)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.batterySize}
                fullWidth
                id="batterySize"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kWh</InputAdornment>
                  ),
                }}
                isSubmitted={isSubmitted}
                label="Battery Capacity"
                touched={Boolean(dirtyFields.batterySize)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.estimatedRange}
                fullWidth
                id="estimatedRange"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mi</InputAdornment>
                  ),
                }}
                isSubmitted={isSubmitted}
                label="Estimated Range"
                touched={Boolean(dirtyFields.estimatedRange)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.efficiency}
                fullWidth
                id="efficiency"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kWh/mi</InputAdornment>
                  ),
                }}
                isSubmitted={isSubmitted}
                label="Vehicle Efficiency"
                touched={Boolean(dirtyFields.efficiency)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <DepotAutocomplete.Select
                control={control}
                error={errors.homeSite as FieldError}
                fleetId={orgId || ''}
                id="homeSite"
                label="Home Site"
                touchedField={Boolean(dirtyFields.homeSite)}
              />
            </Grid>
          </Grid>
        </form>
      </OverlayDeprecated>
    </>
  )
}
