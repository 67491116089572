import {
  AnimatedLineSeries,
  Axis,
  GlyphSeries,
  Grid,
  XYChart,
} from '@visx/xychart'
import { Fragment, useMemo } from 'react'
import { NoData } from '../../NoData'
import { TickFormatter } from '@visx/axis'
import { useTheme } from '@mui/material'

export type LineData = {
  /**
  A unique id for this line
  **/
  id: string
  /**
  A list of datapoints for this line
  **/
  datapoints: LineDatum[]
  /**
  A color for line stroke, default color is water.main from chart palettes. Accepts any color from theme.
  **/
  color?: string
  /**
  Acts as a highlight around the marker, default color is water.light from chart palettes. Accepts any color from theme.
  **/
  markerHighlightColor?: string
}

export type LineDatum = {
  x: Date | string | number
  y: number
}

const accessors = {
  xAccessor: (d: LineDatum) => d.x,
  yAccessor: (d: LineDatum) => d.y,
}

export interface LineGraphProps {
  /**
  An array of line data objects to plot on graph
  **/
  lineData: LineData[]
  /**
  Adjustable width for graph
  **/
  graphWidth?: number
  /**
  Adjustable height for graph
  **/
  graphHeight?: number
  /**
  Size of the dots on the graph
  **/
  glyphSize?: number
  /**
  Adjustable tick number for the x-axis
  **/
  xAxisTickNumber?: number
  /**
  Adjustable tick number for the y-axis
  **/
  yAxisTickNumber?: number
  xTickFormat?: TickFormatter<LineDatum['x']>
  /**
    Optional message to display if no data is available
    **/
  noDataMessage?: string
}

/**
  Default values for optional props
**/
const DEFAULT_TICKS = 6
const DEFAULT_WIDTH = 600
const DEFAULT_HEIGHT = 400
const DEFAULT_MARGIN = 40

export function LineGraph({
  lineData,
  graphWidth = DEFAULT_WIDTH,
  graphHeight = DEFAULT_HEIGHT,
  xAxisTickNumber = DEFAULT_TICKS,
  xTickFormat,
  yAxisTickNumber = DEFAULT_TICKS,
  glyphSize = 12,
  noDataMessage,
}: LineGraphProps) {
  const theme = useTheme()

  const hasData = useMemo(
    () =>
      lineData.reduce<boolean>(
        (acc, { datapoints }) => Boolean(acc || datapoints.length > 0),
        false
      ),
    [lineData]
  )

  const DEFAULT_COLOR = theme.palette.charting?.chart1.main
  const DEFAULT_MARKER_HIGHLIGHT_COLOR = theme.palette.charting?.chart2.light

  const margin = {
    top: DEFAULT_MARGIN / 2,
    right: DEFAULT_MARGIN,
    bottom: DEFAULT_MARGIN * 2,
    left: DEFAULT_MARGIN,
  }

  return hasData ? (
    <XYChart
      height={graphHeight}
      margin={margin}
      width={graphWidth}
      xScale={{ type: 'band' }}
      yScale={{ type: 'linear' }}
    >
      <Grid
        columns={false}
        lineStyle={{
          stroke: theme.palette.grey[200],
          strokeWidth: 1,
        }}
        numTicks={xAxisTickNumber}
        strokeDasharray="3, 5"
      />
      <Axis
        hideAxisLine
        hideTicks
        hideZero
        numTicks={yAxisTickNumber}
        orientation="left"
      />
      <Axis
        numTicks={xAxisTickNumber}
        orientation="bottom"
        stroke={theme.palette.grey[900]}
        tickFormat={xTickFormat}
      />
      {lineData.map((line) => {
        return (
          <Fragment key={line.id}>
            <AnimatedLineSeries
              data={line.datapoints}
              dataKey={line.id}
              stroke={line.color ? line.color : DEFAULT_COLOR}
              strokeWidth={1}
              {...accessors}
            />
            <GlyphSeries
              colorAccessor={() => {
                return line.color ? line.color : DEFAULT_COLOR
              }}
              data={line.datapoints}
              dataKey={line.id}
              size={glyphSize}
              {...accessors}
            />
            <GlyphSeries
              colorAccessor={() => {
                return line.markerHighlightColor
                  ? line.markerHighlightColor
                  : DEFAULT_MARKER_HIGHLIGHT_COLOR
              }}
              data={line.datapoints}
              dataKey={line.id}
              size={glyphSize}
              {...accessors}
            />
          </Fragment>
        )
      })}
    </XYChart>
  ) : (
    <NoData message={noDataMessage} />
  )
}

export default LineGraph
