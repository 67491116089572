import { RootAPI, Trip } from '../..'
import { TimeUnits } from '../../@types/sourceAttribute'
import { VehicleModel } from '../../@types'

export const vehicles: VehicleModel[] = [
  {
    id: '2ca08bf48203a8d3018203ac48ed028e',
    vehicleNm: 'DHL-34',
    vin: '1FTRS4X8XMKA92927',
    batterySize: 40000,
    model: undefined,
    make: undefined,
    modelYear: undefined,
    vehicleType: 'EV',
    vehicleClass: undefined,
    estimatedRange: 60,
    macAddress: undefined,
    active: true,
    logicalDeleteIn: false,
    providerId: undefined,
    efficiency: undefined,
    homeSiteId: undefined,
    fleetId: '3d4ddb9871c011edad9e0af17adeafe7',
    fleetNm: 'Lightning eMotors',
    vehicleStatus: {
      value: 'Charging',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    latitude: {
      value: 40.7656403,
      units: 'degrees',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    longitude: {
      value: -73.9356232,
      units: 'degrees',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    remainingChargeTime: {
      value: 0,
      units: TimeUnits.MINUTES,
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    remainingRange: {
      value: 0.0,
      units: 'km',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    stateOfCharge: {
      value: 69.6562,
      units: 'percent',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    odometer: {
      value: 4496.6,
      units: 'km',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    speed: {
      value: 0,
      units: 'kph',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    ambientAirTemperature: {
      value: 24,
      units: 'C',
      lastUpdated: 1671223598742,
      source: 'Lightning',
    },
    batteryPotential: undefined,
    distanceUntilHome: {
      value: 0.0,
      units: 'km',
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    timeUntilHome: {
      value: 15,
      units: TimeUnits.MINUTES,
      lastUpdated: 1671221819000,
      source: 'Lightning',
    },
    engineOnLifetime: {
      value: 171.378,
      units: 'hr',
      lastUpdated: 1664217307000,
      source: 'Lightning',
    },
    drivingTimeLifetime: {
      value: 97.335,
      units: 'hr',
      lastUpdated: 1664217307000,
      source: 'Lightning',
    },
    eptoUseLifetime: undefined,
    batteryDischargeLifetime: undefined,
    batteryRegenLifetime: undefined,
    tracInverterMotorLifetime: undefined,
    tracInverterRegenLifetime: undefined,
    batteryManagementLifetime: undefined,
    dcdcLifetime: undefined,
    hvacLifetime: undefined,
    chargerPowerSupplied: undefined,
    subscriptions: undefined,
    faults: undefined,
    telematicsTimestamp: undefined,
    telematicsTimestampEpochMillisecond: undefined,
  },
]

export const trip: Trip = {
  created: '2022-06-18T02:00:08.272Z',
  updated: '2022-06-22T18:16:39.964Z',
  id: '2ca0823781747b0301817489f93e0006',
  routeId: '2ca0823781747b0301817489f93e0006',
  vehicleId: '2ca0857a81490d850181490eeeab0002',
  distance: 38030.0,
  distanceUnit: 'mi',
  averageSpeed: 0,
  speedUnit: 'MM/S',
  routeStart: '2022-06-17T16:49:39.000Z',
  routeEnd: '2022-06-17T19:31:38.000Z',
  emissionsSave: 38.23,
  emissionsUnit: 'kg of CO2',
  efficiency: 0,
  efficiencyUnit: 'kwh/mi',
  startLatitude: 0,
  startLongitude: 0,
  endLatitude: 0,
  endLongitude: 0,
  startSoc: 67,
  endSoc: 37,
  driverId: '',
  originNm: '',
  destinationNm: '',
  tripStatus: 'COMPLETED',
}

export const telematics: RootAPI.AssetTelematics[] = [
  {
    assetId: '2ca087787dddbe1c017dddef8c5b0006',
    stateOfCharge: 99,
    telematicsTimestamp: '2022-01-08T23:27:52.000Z',
    latitude: 42.5647455,
    longitude: -70.8818771,
    speed: undefined,
    speedUnit: undefined,
    odometer: 0,
    odometerUnit: undefined,
    batteryDischargeLifetimeWh: undefined,
    batteryRegenLifetimeWh: undefined,
    tracInverterMotorLifetimeWh: undefined,
    tracInverterRegenLifetimeWh: undefined,
    dcdcLifetimeWh: undefined,
    hvacLifetimeWh: undefined,
    batteryManagementLifetimeWh: undefined,
    ambientAirTemperatureDegc: undefined,
    rangeRemainingEstimateKm: undefined,
    gearSelect: undefined,
    keySwitchState: undefined,
    keyOnTimeHr: undefined,
    drivingTimeHr: undefined,
    vehicleStatus: undefined,
    chargeTimeEstimateMin: undefined,
    totalEnergyConsumedKwh: undefined,
    hvacEnergyKwh: undefined,
    motorEnergyConsumedKwh: undefined,
    regenEnergyKwh: undefined,
    dcdcEnergyKwh: undefined,
    batteryManagementEnergyKwh: undefined,
    airAmbientAvgTempC: undefined,
    chargeEnergyKwh: undefined,
  },
  {
    assetId: '2ca087787dddbe1c017dddef8c5b0006',
    stateOfCharge: 43,
    telematicsTimestamp: '2022-01-08T23:34:17.000Z',
    latitude: 42.5647455,
    longitude: -70.8818771,
    speed: undefined,
    speedUnit: undefined,
    odometer: 0,
    odometerUnit: undefined,
    batteryDischargeLifetimeWh: undefined,
    batteryRegenLifetimeWh: undefined,
    tracInverterMotorLifetimeWh: undefined,
    tracInverterRegenLifetimeWh: undefined,
    dcdcLifetimeWh: undefined,
    hvacLifetimeWh: undefined,
    batteryManagementLifetimeWh: undefined,
    ambientAirTemperatureDegc: undefined,
    rangeRemainingEstimateKm: undefined,
    gearSelect: undefined,
    keySwitchState: undefined,
    keyOnTimeHr: undefined,
    drivingTimeHr: undefined,
    vehicleStatus: undefined,
    chargeTimeEstimateMin: undefined,
    totalEnergyConsumedKwh: undefined,
    hvacEnergyKwh: undefined,
    motorEnergyConsumedKwh: undefined,
    regenEnergyKwh: undefined,
    dcdcEnergyKwh: undefined,
    batteryManagementEnergyKwh: undefined,
    airAmbientAvgTempC: undefined,
    chargeEnergyKwh: undefined,
  },
  {
    assetId: '2ca087787dddbe1c017dddef8c5b0006',
    stateOfCharge: 12,
    telematicsTimestamp: '2022-01-08T23:35:06.000Z',
    latitude: 42.5653771,
    longitude: -70.8834232,
    speed: undefined,
    speedUnit: undefined,
    odometer: 0,
    odometerUnit: undefined,
    batteryDischargeLifetimeWh: undefined,
    batteryRegenLifetimeWh: undefined,
    tracInverterMotorLifetimeWh: undefined,
    tracInverterRegenLifetimeWh: undefined,
    dcdcLifetimeWh: undefined,
    hvacLifetimeWh: undefined,
    batteryManagementLifetimeWh: undefined,
    ambientAirTemperatureDegc: undefined,
    rangeRemainingEstimateKm: undefined,
    gearSelect: undefined,
    keySwitchState: undefined,
    keyOnTimeHr: undefined,
    drivingTimeHr: undefined,
    vehicleStatus: undefined,
    chargeTimeEstimateMin: undefined,
    totalEnergyConsumedKwh: undefined,
    hvacEnergyKwh: undefined,
    motorEnergyConsumedKwh: undefined,
    regenEnergyKwh: undefined,
    dcdcEnergyKwh: undefined,
    batteryManagementEnergyKwh: undefined,
    airAmbientAvgTempC: undefined,
    chargeEnergyKwh: undefined,
  },
  {
    assetId: '2ca087787dddbe1c017dddef8c5b0006',
    stateOfCharge: 43,
    telematicsTimestamp: '2022-01-08T23:35:30.000Z',
    latitude: 42.5649931,
    longitude: -70.8847857,
    speed: undefined,
    speedUnit: undefined,
    odometer: 0,
    odometerUnit: undefined,
    batteryDischargeLifetimeWh: undefined,
    batteryRegenLifetimeWh: undefined,
    tracInverterMotorLifetimeWh: undefined,
    tracInverterRegenLifetimeWh: undefined,
    dcdcLifetimeWh: undefined,
    hvacLifetimeWh: undefined,
    batteryManagementLifetimeWh: undefined,
    ambientAirTemperatureDegc: undefined,
    rangeRemainingEstimateKm: undefined,
    gearSelect: undefined,
    keySwitchState: undefined,
    keyOnTimeHr: undefined,
    drivingTimeHr: undefined,
    vehicleStatus: undefined,
    chargeTimeEstimateMin: undefined,
    totalEnergyConsumedKwh: undefined,
    hvacEnergyKwh: undefined,
    motorEnergyConsumedKwh: undefined,
    regenEnergyKwh: undefined,
    dcdcEnergyKwh: undefined,
    batteryManagementEnergyKwh: undefined,
    airAmbientAvgTempC: undefined,
    chargeEnergyKwh: undefined,
  },
  {
    assetId: '2ca087787dddbe1c017dddef8c5b0006',
    stateOfCharge: 4,
    telematicsTimestamp: '2022-01-08T23:35:39.000Z',
    latitude: 42.564774,
    longitude: -70.8851526,
    speed: undefined,
    speedUnit: undefined,
    odometer: 0,
    odometerUnit: undefined,
    batteryDischargeLifetimeWh: undefined,
    batteryRegenLifetimeWh: undefined,
    tracInverterMotorLifetimeWh: undefined,
    tracInverterRegenLifetimeWh: undefined,
    dcdcLifetimeWh: undefined,
    hvacLifetimeWh: undefined,
    batteryManagementLifetimeWh: undefined,
    ambientAirTemperatureDegc: undefined,
    rangeRemainingEstimateKm: undefined,
    gearSelect: undefined,
    keySwitchState: undefined,
    keyOnTimeHr: undefined,
    drivingTimeHr: undefined,
    vehicleStatus: undefined,
    chargeTimeEstimateMin: undefined,
    totalEnergyConsumedKwh: undefined,
    hvacEnergyKwh: undefined,
    motorEnergyConsumedKwh: undefined,
    regenEnergyKwh: undefined,
    dcdcEnergyKwh: undefined,
    batteryManagementEnergyKwh: undefined,
    airAmbientAvgTempC: undefined,
    chargeEnergyKwh: undefined,
  },
]

export const tripNotes: RootAPI.TripNote[] = [
  {
    created: '2022-06-18T02:00:08.272Z',
    updated: '2022-06-22T18:16:39.964Z',
    id: 'tripNoteId-1',
    tripId: '2ca0823781747b0301817489f93e0006',
    fromUserId: 'hankUserId',
    content: 'Last quart of trip pulled excessive SOC.',
    senderName: 'Hank',
  },
]
