import {
  Divider,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import { ReactNode } from 'react'

export interface ListItemProps {
  children?: ReactNode
  icon: ReactNode
  id?: string
  text: string
  /**Optional: if wanted to be accessed directly (not through Menu) */
  selected?: boolean
  /**Optional: used when under a parent component */
  selectedItemId?: string
  setSelectedId?: (id: string) => void
  isNested?: boolean
  linkTo?: string
  onClick?: () => void
}

export function ListItem({
  children,
  icon,
  id = '0',
  text,
  selectedItemId,
  selected = false,
  setSelectedId = () => null,
  isNested = false,
  linkTo = '',
  onClick = () => null,
}: ListItemProps) {
  const isSelected = selected || id === selectedItemId
  const ItemGroup = (
    <ListItemButton
      onClick={() => {
        setSelectedId(id)
        onClick()
      }}
      selected={isSelected}
      sx={{ pl: isNested ? 4 : null }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      {children}
    </ListItemButton>
  )
  return (
    <>
      {isSelected ? (
        <Link href={linkTo} underline="none">
          {ItemGroup}
        </Link>
      ) : (
        <DefaultLink href={linkTo} underline="none">
          {ItemGroup}
        </DefaultLink>
      )}
      <Divider />
    </>
  )
}

export default ListItem

const DefaultLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
