import { Icons, MapLegend } from '@synop-react/common'
import { useTheme } from '@mui/material'

export const VehicleMapLegend = () => {
  const { palette } = useTheme()

  return (
    <MapLegend>
      <MapLegend.Row>
        <MapLegend.Item
          label="Vehicle SOC < 40%"
          legendKey={<MapLegend.Key color={palette.error.main} />}
        />
        <MapLegend.Item
          label="Vehicle SOC 41-79%"
          legendKey={<MapLegend.Key color={palette.warning.main} />}
        />
        <MapLegend.Item
          label="Vehicle SOC 80-100%"
          legendKey={<MapLegend.Key color={palette.secondary.main} />}
        />
      </MapLegend.Row>
      <MapLegend.Row>
        <MapLegend.Item
          label="Vehicle Charging"
          legendKey={<Icons.Zap color={palette.secondary.main} size={10} />}
        />
        <MapLegend.Item
          label="Vehicle In Motion"
          legendKey={
            <Icons.Navigation color={palette.secondary.main} size={10} />
          }
        />
        <MapLegend.Item
          label="Vehicle Idle"
          legendKey={<Icons.Moon color={palette.secondary.main} size={10} />}
        />
        <MapLegend.Item
          label="Vehicle Off"
          legendKey={<Icons.Slash color={palette.secondary.main} size={10} />}
        />
        <MapLegend.Item
          label="Unknown Vehicle Status"
          legendKey={
            <Icons.AlertOctagon color={palette.secondary.main} size={10} />
          }
        />
      </MapLegend.Row>
    </MapLegend>
  )
}
