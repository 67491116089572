import { ChangeEvent, useCallback, useState } from 'react'
import { ControlProps } from '../AlertPropertyInitializedContext'
import { convertTime, TimeDropdown } from '@synop-react/common'
import { Stack, TextField, Typography } from '@mui/material'
import { TimeUnits } from '@synop-react/api'

export const defaultDelayProperty = {
  field: 'delay',
  operator: '=',
  value: '3600',
}

type TimeControlProps = ControlProps & {
  includeThenAfter?: boolean
}

export const TimeControl = ({
  property,
  updateProperty,
  includeThenAfter = false,
}: TimeControlProps) => {
  const [timeUnit, setTimeUnit] = useState(TimeUnits.MINUTES)

  const handleTime = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      const convertedValue = convertTime(
        Number(value ?? '0'),
        timeUnit,
        TimeUnits.SECONDS
      )

      updateProperty({ ...property, value: convertedValue.toString() })
    },
    [property, timeUnit, updateProperty]
  )

  // Time Control only handles properties with time value fields
  if ('rules' in property) return null

  return (
    <Stack alignItems="flex-end" direction="row" spacing={2}>
      {includeThenAfter && (
        <Typography variant="subtitle2">Then After...</Typography>
      )}

      <TextField
        label="Time"
        onChange={handleTime}
        sx={{ minWidth: 100 }}
        type="number"
        value={convertTime(
          Number(property.value),
          TimeUnits.SECONDS,
          timeUnit
        ).toFixed(0)}
        variant="standard"
      />

      <TimeDropdown
        label="Unit"
        onChange={(newValue) => {
          if (newValue) setTimeUnit(newValue)
        }}
        value={timeUnit}
      />
    </Stack>
  )
}
