import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { currentOrganizationReducer } from '../../store/currentOrganizationSlice'
import { synopRootAPI } from '../../api/synopRootAPI'

export const mockAPIReducer = {
  [synopRootAPI.reducerPath]: synopRootAPI.reducer,
}

const MOCK_ORG_ID = '8ac5611d7c2d6a07017c2d6a2e1c0000'

const initialMockState = {
  currentOrganization: {
    principalOrgId: MOCK_ORG_ID,
    cloakedOrgIds: [MOCK_ORG_ID],
  },
}

const mockStore = configureStore({
  preloadedState: initialMockState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(synopRootAPI.middleware),
  reducer: combineReducers({
    currentOrganization: currentOrganizationReducer,
    ...mockAPIReducer,
  }),
})

export default mockStore
