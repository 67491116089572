import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

import { AlertWizard } from './AlertWizard'
import { CancelOverlay } from './CancelOverlay'
import { DetailPageHeader, useRouting } from '@synop-react/common'

export const AlertWizardPage = () => {
  const [cancelOverlayOpen, setCancelOverlayOpen] = useState(false)
  const { navigate, routes } = useRouting()
  const { alertId } = useParams()

  return (
    <>
      {cancelOverlayOpen && (
        <CancelOverlay
          isOpen
          onCancel={() => setCancelOverlayOpen(false)}
          onConfirm={() => {
            setCancelOverlayOpen(false)
            navigate(routes.settings.alerts)
          }}
          setIsOpen={setCancelOverlayOpen}
        />
      )}

      <Grid container rowSpacing={2} sx={{ pb: 8 }}>
        <Grid item xs={12}>
          <DetailPageHeader
            breadcrumbLink={routes.settings.alerts}
            breadcrumbTitle="Alerts"
            title={`${alertId ? 'Configure' : 'Create'} Alert`}
          />
        </Grid>

        <Grid item xs={12}>
          <AlertWizard
            alertId={alertId}
            onCancel={() => setCancelOverlayOpen(true)}
          />
        </Grid>
      </Grid>
    </>
  )
}
