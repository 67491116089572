import { Link as MuiLink, Tooltip, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

export * from './ClientSideTable'
export * from './ServerSideTable'
export * from './types'

type TableHeaderCellProps = {
  title?: string
  tooltip?: string
}

export function HeaderCell({ title, tooltip = '' }: TableHeaderCellProps) {
  return (
    <Tooltip title={tooltip}>
      <Typography sx={{ fontWeight: 'bold' }} variant="body2">
        {title}
      </Typography>
    </Tooltip>
  )
}

type TableLinkProps = PropsWithChildren<{
  href: string
  weight: number
}>

export function Link({ children, href, weight }: TableLinkProps) {
  return (
    <MuiLink
      fontWeight={weight}
      href={href}
      sx={{
        color: 'primary.main',
        maxWidth: '100%',
        overflow: 'hidden',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </MuiLink>
  )
}
