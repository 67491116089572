import { extent } from 'd3-array'
import { LinePaint } from 'mapbox-gl'
import { scaleLinear } from '@visx/scale'
import { useMemo } from 'react'

import _ from 'lodash'
import MapLineLayer, { MapLineLayerProps } from '../MapLineLayer'

export type MapGradientLineLayerProps = {
  /** 
  An array of possible values to be used with [d3-array extent](https://www.geeksforgeeks.org/d3-js-d3-extent-function/) to find the min / max values
*/
  gradientDomain: number[]
  /** 
  An array of numbers to be used to map which values in the domain will map to which colors 
*/
  gradientStops: number[]
  /** 
  An array of color values to be used with gradientStops to determine which colors should be used to create the line gradient
*/
  gradientRange: string[]
} & Omit<MapLineLayerProps, 'lineColor' | 'linePaint'>

// Requires the Source to have `lineMetrics={true}`
export function MapGradientLineLayer({
  gradientStops,
  gradientDomain,
  gradientRange,
  ...rest
}: MapGradientLineLayerProps) {
  const gradientColorScale = useMemo(
    () =>
      scaleLinear({
        domain: gradientStops,
        range: gradientRange,
      }),
    [gradientRange, gradientStops]
  )

  // Maps max of domain to beginning of the line and min of domain to end of the line
  const gradientDurationScale = useMemo(() => {
    return scaleLinear({
      domain: extent(gradientDomain) as [number, number],
      range: [1, 0],
    })
  }, [gradientDomain])

  // Order the gradient steps in ascending order for Mapbox to draw
  const gradientSteps = useMemo(
    () =>
      _.orderBy(gradientDomain, [], 'desc').flatMap((gradient) => [
        gradientDurationScale(gradient),
        gradientColorScale(gradient),
      ]),
    [gradientDomain, gradientDurationScale, gradientColorScale]
  )

  const gradientLineLayer: Partial<LinePaint> = {
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      ...gradientSteps,
    ],
  }

  return <MapLineLayer {...rest} linePaint={gradientLineLayer} />
}

export default MapGradientLineLayer
