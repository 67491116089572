import { Marker } from 'react-map-gl'
import { selectSelectedEntity } from '../../Browser/useEntityBrowser/useEntityReducer'
import {
  useBrowserSelector,
  useEntityBrowser,
} from '../../Browser/useEntityBrowser'
import MapPin from '../Pin'

export const SelectedPin = () => {
  const { mapConfig } = useEntityBrowser()
  const selectedEntity = useBrowserSelector(selectSelectedEntity)

  const selectedFeature = selectedEntity
    ? mapConfig.getCoords({ [selectedEntity.id]: selectedEntity }).features[0]
    : null

  const [long, lat] =
    selectedFeature?.geometry.type === 'Point'
      ? selectedFeature.geometry.coordinates
      : []

  return long !== undefined && lat !== undefined ? (
    <Marker anchor="top" latitude={lat} longitude={long}>
      <MapPin color="primary" />
    </Marker>
  ) : null
}
