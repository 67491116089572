import {
  DashboardHeader,
  OrgSelector,
  OrgSelectorsProvider,
  TimeRangeProvider,
} from '@synop-react/common'
import { Grid } from '@mui/material'
import {
  VehicleDashboardOverviewTile,
  VehicleSummaryTable,
} from '@synop-react/vehicle'

const VehicleDashboardPage = () => {
  return (
    <TimeRangeProvider
      defaultTimeResolution="WEEK"
      ranges={['DAY', 'WEEK', 'MONTH', 'CUSTOM']}
      syncToLocalStorage
    >
      <OrgSelectorsProvider syncToLocalStorage>
        <Grid
          container
          rowSpacing={3}
          sx={{ pl: 5, pr: 5, justifyContent: 'center', mb: 8 }}
        >
          <DashboardHeader
            controls={[<OrgSelector />]}
            showTimerange
            title="Vehicle"
          />
          <VehicleDashboardOverviewTile />
          <VehicleSummaryTable />
        </Grid>
      </OrgSelectorsProvider>
    </TimeRangeProvider>
  )
}

export default VehicleDashboardPage
