import { ReactElement } from 'react'
import { Stack } from '@mui/material'

type CenterProps<T extends string> = { children: ReactElement } & {
  [K in T]?: string | number
}

const Horizontal = ({ children, width = '100%' }: CenterProps<'width'>) => (
  <Stack direction="row" sx={{ width, justifyContent: 'center' }}>
    {children}
  </Stack>
)

const Vertical = ({ children, height = '100%' }: CenterProps<'height'>) => (
  <Stack direction="column" sx={{ height, justifyContent: 'center' }}>
    {children}
  </Stack>
)

export const Center = {
  Horizontal,
  Vertical,
}
