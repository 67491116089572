import { AlertPropertyGarden } from '../AlertPropertyGarden'
import { AlertStep, AlertStepCommonProps } from './AlertStep'
import { AssetType } from '@synop-react/api'
import { Button, Grid } from '@mui/material'
import { Plus } from '@synop-react/common'
import { useAlertPropertyContext } from '../AlertProperty'
import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export type Rule = {
  field: string
  value: string
  operator: string
}

export type RuleGroup = {
  rules: Property[]
  condition: string
}

export type Property = Rule | RuleGroup

const emptyDefaultProperty = {
  field: '',
  value: '',
  operator: '',
}

export const AlertRuleStep = (props: AlertStepCommonProps) => {
  const { propertyControlMap } = useAlertPropertyContext()
  const assetType: AssetType = useWatch({ name: 'assetType' })

  const emptyRule = useMemo(() => {
    const assetControlMap = propertyControlMap[assetType]
    const firstControl = Object.values(assetControlMap)[0]
    return firstControl?.defaultProperty ?? emptyDefaultProperty
  }, [assetType, propertyControlMap])

  const { setValue } = useFormContext()
  const properties: RuleGroup = useWatch({ name: 'rules' })
  const setProperties = useCallback(
    (properties: RuleGroup) => setValue('rules', properties),
    [setValue]
  )

  useEffect(() => {
    if (assetType === 'Charger') {
      setProperties({
        rules: [properties.rules[0] ?? emptyRule],
        condition: 'and',
      })
    }
    // Only want to remove excess properties when asset Type changes to 'Charger'
    // can not run when other dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetType])

  return (
    <AlertStep
      {...props}
      stepDescription="Configure the conditions and properties of the alert."
      stepName="Rule"
      stepNumber={1}
    >
      <AlertPropertyGarden
        properties={properties}
        setProperties={setProperties}
      />

      <Grid item xs={12}>
        <Button
          disabled={properties.rules.length >= 3 || assetType === 'Charger'}
          onClick={() =>
            setProperties({
              ...properties,
              rules: [...properties.rules, emptyRule],
            })
          }
          startIcon={<Plus sx={{ color: 'inherit' }} />}
          variant="outlined"
        >
          ADD PROPERTY
        </Button>
      </Grid>
    </AlertStep>
  )
}
