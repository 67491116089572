import { RootAPI } from '../..'

export const user: RootAPI.UserModel = {
  id: '123',
  name: 'User 1',
  email: 'user@synop.ai',
  phoneNumber: '12120000',
  organizationId: '8ac5611d7c2d6a07017c2d6a2e1c0000',
  status: 'active',
  mfaEnabled: false,
  isActive: true,
  preferredDistanceUnits: 'Imperial (Feet / Miles)',
  preferredTemperatureUnits: 'fahrenheit',
  preferredEfficiencyUnits: 'kWh/mi',
  preferredTimeFormat: '12 Hour Clock (01:00 - 12:00)',
  preferredDateFormat: 'MM/DD/YY',
  preferredTimeZone: 'Eastern (UTC -5)',
  roles: [],
  sites: [],
  mfaOptionTypes: [],
  termsAccepted: false,
  termsAcceptedDt: undefined,
  rfidTags: [],
}

export const preferredUnitOptions: RootAPI.OptionValue[] = [
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'date_format',
    value: 'DD/MM/YY',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'date_format',
    value: 'MM/DD/YY',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'date_format',
    value: 'YY/MM/DD',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'distance_units',
    value: 'Imperial (Feet / Miles)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'distance_units',
    value: 'Metric (Meter / Kilometer)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'efficiency_units',
    value: 'km/kWh',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'efficiency_units',
    value: 'kWh/km',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'efficiency_units',
    value: 'kWh/mi',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'efficiency_units',
    value: 'mi/kWh',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'temperature_units',
    value: 'celsius',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'temperature_units',
    value: 'fahrenheit',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_format',
    value: '12 Hour Clock (01:00 - 12:00)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_format',
    value: '24 Hour Clock (00:00 - 23:59)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_zone',
    value: 'Atlantic (UTC -4)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_zone',
    value: 'Central (UTC -6)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_zone',
    value: 'Eastern (UTC -5)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_zone',
    value: 'Mountain (UTC -7)',
  },
  {
    created: '2022-12-01T17:10:39.159Z',
    updated: '2022-12-01T17:10:39.159Z',
    optionType: 'time_zone',
    value: 'Pacific (UTC -8)',
  },
]
