import { useMemo } from 'react'

import { EMPTY_ENTITY_MAP, parseToEntityMapList } from '../api/utils'
import { EntityMap } from '@synop-react/types'
import { useOrgVehicles, VehicleModel } from '..'

export const useFleetVehicles = () => {
  const { orgVehicles } = useOrgVehicles()

  const fleetVehicles = useMemo(() => {
    return orgVehicles
      ? parseToEntityMapList(Object.values(orgVehicles), 'fleetId')
      : (EMPTY_ENTITY_MAP as EntityMap<VehicleModel>)
  }, [orgVehicles])

  return {
    fleetVehicles: fleetVehicles.entities,
  }
}
