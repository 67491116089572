import { AlertProperty, getTopLevelField } from '../AlertProperty'
import { Box, Grid, Stack } from '@mui/material'
import { Property, RuleGroup } from '../AlertSteps'
import { simpleDropdownFactory } from '@synop-react/common'
import { useCallback } from 'react'

type AlertPropertyGardenProps = {
  properties: RuleGroup
  setProperties: (properties: RuleGroup) => void
}

export const AlertPropertyGarden = ({
  properties,
  setProperties,
}: AlertPropertyGardenProps) => {
  const setProperty = useCallback(
    (index: number, property: Property | null) => {
      const newProperties = [...properties.rules]

      if (property === null) {
        newProperties.splice(index, 1)
      } else {
        newProperties[index] = property
      }

      setProperties({ ...properties, rules: newProperties })
    },
    [properties, setProperties]
  )

  // This will currently be disabled and locked to "and" with the intent to add "or" in the future
  const AndOrOperatorDropdown = simpleDropdownFactory([
    { name: 'And', id: 'and' },
    { name: 'Or', id: 'or' },
  ])

  return (
    <Grid container item spacing={2} xs={12}>
      {properties.rules.map((property, index) => (
        <Grid
          key={`property${index + 1}-${getTopLevelField(property)}`}
          item
          xs={12}
        >
          <Stack spacing={2}>
            {index !== 0 && (
              <Box alignSelf="center">
                <AndOrOperatorDropdown
                  disabled
                  onChange={() => null}
                  value="and"
                />
              </Box>
            )}

            <AlertProperty
              index={index}
              property={property}
              setProperty={setProperty}
              totalProperties={properties.rules.length}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
