import { useTimeRange } from '../../../TimeRange'
import { validateTimeRange } from './validateTimeRange'

export default function useValidate() {
  const { from, to } = useTimeRange()

  return {
    validateTimeRange: validateTimeRange({
      from,
      to,
    }),
  }
}
