import * as FormField from '../../FormField'
import { Control, FieldValues, Path } from 'react-hook-form'
import { useState } from 'react'

export type PowerTargetType = 'SOC' | 'MAX_POWER'
export type PowerTargetOption = { type: PowerTargetType; label: string }

type PowerTargetDropdownProps<FormData extends FieldValues> = {
  id: Path<FormData>
  label?: string
  control: Control<FormData, unknown>
} & FormField.AutocompleteFormControls<FormData, PowerTargetOption> &
  FormField.AutocompleteTextFieldProps

const PowerTargetDropdown = <FormData extends FieldValues>({
  id,
  control,
  label = 'Type',
  ...autocompleteProps
}: PowerTargetDropdownProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)
  const deliveryOptions: PowerTargetOption[] = [
    {
      type: 'SOC',
      label: 'SOC Target',
    },
    {
      type: 'MAX_POWER',
      label: 'Max Power',
    },
  ]

  return (
    <FormField.AutocompleteSelect<FormData, PowerTargetOption>
      {...autocompleteProps}
      control={control}
      disableClearable={true}
      getOptionLabel={(option: PowerTargetOption) => `${option.label}`}
      id={id}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: PowerTargetOption) => option.type}
      label={label}
      options={deliveryOptions}
      setIsOpen={setIsOpen}
    />
  )
}

export default PowerTargetDropdown
