import { ProgressPillColor } from '@synop-react/common'

export default function calculateEfficiencyColor(efficiency: number) {
  let color: ProgressPillColor = 'secondary'

  //Currently we in gamma we are calculating efficiency thresholds as such:
  //TODO: Follow up on a better efficiency bounds to calculate
  const lowerThreshold = efficiency - efficiency * 0.2
  const upperThreshold = efficiency + efficiency * 0.2

  if (efficiency >= upperThreshold) {
    color = 'success'
  } else if (efficiency <= lowerThreshold) {
    color = 'error'
  }

  return color
}
