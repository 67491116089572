import {
  emDash,
  formatPower,
  Icons,
  OverlayDeprecated,
  OverlayTitlePill,
} from '../../index'
import { freqLookupToDropdownMap } from '../../FormField'
import { isUndefined } from 'lodash'
import { RootAPI } from '@synop-react/api'
import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'

import { useFormat } from '../../utils/hooks'

type VehicleDepotEventOverlayProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  eventId?: string
  depotId?: string
}

export function ViewDepotEventOverlay({
  isOpen,
  setIsOpen,
  eventId = '',
  depotId = '',
}: VehicleDepotEventOverlayProps) {
  const { formatDateTime } = useFormat()
  const { data: event } = RootAPI.useGetDepotEventByIdQuery(
    {
      eventId: eventId,
      depotId: depotId,
    },
    {
      skip: !eventId || !depotId,
    }
  )
  const { eventRecurrence } = event || {}

  const firstInterval = event?.schedule.intervals[0]
  const title = useMemo(() => {
    const { curtailedSiteLimit } = firstInterval ?? {}
    if (isUndefined(curtailedSiteLimit)) {
      return 'Unknown Event'
    } else if (curtailedSiteLimit >= 0) {
      return 'Site Curtailment Event'
    } else {
      return 'Site Export Event'
    }
  }, [firstInterval])

  return (
    <OverlayDeprecated
      isOpen={isOpen}
      maxWidth="xs"
      overlayPixelWidth={eventRecurrence ? undefined : 300}
      setIsOpen={setIsOpen}
      title={title}
      TitleIcon={Icons.FileText}
      titlePill={
        eventRecurrence && (
          <OverlayTitlePill
            primaryText="Recurring Event"
            secondaryText={eventRecurrence.recurrenceName}
            TitlePillIcon={Icons.Repeat}
          />
        )
      }
    >
      <Stack direction="row" spacing={3}>
        <Stack>
          <Typography color="secondary.main" variant="h6">
            {firstInterval?.startDateTime
              ? formatDateTime(firstInterval.startDateTime).time
              : emDash}
          </Typography>
          <Typography color="secondary.main" variant="caption">
            From
          </Typography>
        </Stack>
        <Stack>
          <Typography color="secondary.main" variant="h6">
            {firstInterval?.endDateTime
              ? formatDateTime(firstInterval.endDateTime).time
              : emDash}
          </Typography>
          <Typography color="secondary.main" variant="caption">
            To
          </Typography>
        </Stack>
        <Stack>
          <Typography color="secondary.main" variant="h6">
            {formatPower(
              firstInterval?.curtailedSiteLimit != undefined
                ? Math.abs(firstInterval.curtailedSiteLimit)
                : undefined
            )}
          </Typography>
          <Typography color="secondary.main" variant="caption">
            {firstInterval?.curtailedSiteLimit &&
            firstInterval.curtailedSiteLimit >= 0
              ? 'Curtailed Site Limit'
              : 'Max Export'}
          </Typography>
        </Stack>
        {eventRecurrence && (
          <Stack>
            <Typography color="secondary.main" variant="h6">
              {eventRecurrence.recurrenceType
                ? freqLookupToDropdownMap.get(eventRecurrence.recurrenceType)
                : emDash}
            </Typography>
            <Typography color="secondary.main" variant="caption">
              Frequency
            </Typography>
          </Stack>
        )}
      </Stack>
    </OverlayDeprecated>
  )
}

export default ViewDepotEventOverlay
