import { BillingCycleSelector, getCSVColumns } from '../HomeChargingSettings'
import { Dayjs } from 'dayjs'
import { emDash, Table, TableCask, useTableCask } from '@synop-react/common'
import { FormProvider } from 'react-hook-form'
import { Link, Typography } from '@mui/material'
import {
  RootAPI,
  useCurrentOrgId,
  useOrgChargers,
  useOrgCustomers,
  useUserPrefs,
} from '@synop-react/api'
import { Tuple } from '@synop-react/types'
import { useCallback, useMemo, useState } from 'react'

const { useGetWorkplaceChargingUsageQuery, useGetWorkplaceSummaryQuery } =
  RootAPI

export function WorkplaceChargingUsage() {
  const { formMethods, from, to } = useTableCask()
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const [range, setRange] = useState<Tuple<Dayjs | null>>([null, null])
  const orgId = useCurrentOrgId()

  const { data } = useGetWorkplaceChargingUsageQuery({
    billingMonth: (range[0] ?? tzDayjs()).format('YYYY-MM-DD'),
    organizationId: orgId,
  })

  const { customers: organizations } = useOrgCustomers({
    includeParentOrg: true,
  })
  const { orgChargers } = useOrgChargers()

  const columns = useMemo<
    Table.ColumnSpec<RootAPI.ChargeRecordModelWorkplaceChargingUserOrganization>
  >(
    () => [
      {
        flex: 0.15,
        field: 'usageSourceId',
        minWidth: 120,
        headerName: 'Transaction ID',
      },
      {
        flex: 0.15,
        field: 'charger',
        minWidth: 110,
        headerName: 'Charger',
        valueGetter: ({ row: { assetId } }) =>
          orgChargers[assetId ?? '']?.chargerName,
        renderCell: ({ row: { assetId }, value }) => (
          <Typography variant="body2">
            {assetId ? (
              <Link href={`/chargers/${assetId}`} underline="none">
                {value}
              </Link>
            ) : (
              emDash
            )}
          </Typography>
        ),
      },
      {
        flex: 0.15,
        field: 'assetId',
        minWidth: 120,
        headerName: 'Charger ID',
      },
      {
        flex: 0.15,
        field: 'usage',
        minWidth: 120,
        headerName: 'Usage',
      },
      {
        flex: 0.15,
        field: 'appliedRate',
        minWidth: 120,
        headerName: 'Rate',
        renderCell: ({ row }) => (
          <Typography sx={{ color: 'text.primary' }} variant="body2">
            ${row.appliedRate} kWh
          </Typography>
        ),
      },
      {
        flex: 0.15,
        field: 'chargeCost',
        minWidth: 120,
        headerName: 'Cost',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'organizationId',
        headerName: 'Organization',
        valueGetter: ({ row: { organizationId } }) =>
          organizations[organizationId ?? '']?.organizationNm ?? emDash,
      },
      {
        flex: 0.15,
        field: 'payorName',
        minWidth: 110,
        headerName: 'Payor Name',
        valueGetter: ({ row: { payer } }) =>
          payer && `${payer.firstNm} ${payer.lastNm}`,
      },
      {
        flex: 0.15,
        field: 'payorEmail',
        minWidth: 110,
        headerName: 'Payee Email',
        valueGetter: ({ row: { payer } }) => payer?.email ?? '',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'externalId',
        headerName: 'External ID',
        valueGetter: ({ row: { payer } }) => payer?.externalId ?? '',
      },
    ],
    [orgChargers, organizations]
  )

  const handleRangeChange = useCallback(
    (range: [Dayjs | null, Dayjs | null]) => {
      setRange(range)
    },
    []
  )

  const csvColumns = useMemo(() => getCSVColumns(columns), [columns])

  const rangeSuffix = useMemo(
    () =>
      `${(range[0] ?? tzDayjs(from)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}_${(range[1] ?? tzDayjs(to)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}`,
    [from, preferredDateFormat, range, to, tzDayjs]
  )
  const downloadTitle = useMemo(
    () => `Workplace_Charging_Usage_${rangeSuffix}`,
    [rangeSuffix]
  )

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        columns={columns}
        csvColumns={csvColumns}
        downloadable
        downloadTitle={downloadTitle}
        getRowId={(row) => row.chargeRecordId ?? ''}
        initialState={{
          columns: {
            columnVisibilityModel: {
              assetId: false,
            },
          },
        }}
        leftOtherActions={
          <BillingCycleSelector
            handleRangeChange={handleRangeChange}
            useSummaryQuery={useGetWorkplaceSummaryQuery}
          />
        }
        searchable={true}
        tableData={data ?? []}
        title={''}
      />
    </FormProvider>
  )
}

export default WorkplaceChargingUsage
