// TODO fix tsconfig to allow importing this namespace
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../../node_modules/@types/google.maps/index.d.ts" />

import { Maybe, Tuple } from '@synop-react/types'
import { useCallback, useMemo, useRef } from 'react'
import { useGoogleLibraries } from './useGoogleLibraries'
import throttle from 'lodash/throttle'

export type GooglePlace = GoogleAutocomplete | GeocodeResult

export type AutocompleteCallback = Parameters<
  google.maps.places.AutocompleteService['getPlacePredictions']
>[1]
export type AutocompleteResponse = google.maps.places.AutocompletePrediction[]
export type GoogleAutocomplete = google.maps.places.AutocompletePrediction

export type GeocodeCallback = Parameters<google.maps.Geocoder['geocode']>[1]
export type GeocodeResult = { location: [number, number] } & Omit<
  google.maps.GeocoderResult,
  'geometry'
>

type MaybeGooglePlace = Maybe<GooglePlace | string>

export const isGooglePlace = (
  input: MaybeGooglePlace
): input is GooglePlace => {
  if (typeof input === 'string') return false
  return input !== undefined
}

export const isGeocodeResult = (
  input: MaybeGooglePlace
): input is GeocodeResult => {
  if (typeof input === 'string') return false
  if (input === undefined) return false
  return 'location' in input
}

export const isAutocompleteResponse = (
  input: MaybeGooglePlace | null
): input is google.maps.places.AutocompletePrediction => {
  if (typeof input === 'string') return false
  if (input === undefined || input === null) return false
  return 'description' in input
}

export const useGoogleAPI = () => {
  const hasLoadedService = useGoogleLibraries()

  const autocompleteService = useRef<google.maps.places.AutocompleteService>()
  const geocodeService = useRef<google.maps.Geocoder>()

  // Callback for retrieving place autocomplete predictions
  const fetchPredictions = useCallback(
    (
      request: google.maps.places.AutocompletionRequest,
      callback: AutocompleteCallback
    ) => {
      // Initialize Autocomplete Service if it hasn't been created yet
      if (!autocompleteService.current && window.google?.maps?.places) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService()
      }

      return autocompleteService.current?.getPlacePredictions(request, callback)
    },
    []
  )

  // Callback for retrieving geocode details
  const fetchGeoDetails = useCallback(
    (
      request: google.maps.GeocoderRequest,
      callback: (result: GeocodeResult, b: google.maps.GeocoderStatus) => void
    ) => {
      const filteredGeocodeResponse: GeocodeCallback = (results, status) => {
        const result = results?.[0]
        if (!result) return

        const { geometry, ...geocodeResult } = result
        const { lat, lng } = geometry.location
        const location: Tuple<number> = [lng(), lat()]
        callback({ location, ...geocodeResult }, status)
      }

      // Initialize Geocoder Service if it hasn't been created yet
      if (!geocodeService.current && window.google?.maps?.Geocoder) {
        geocodeService.current = new window.google.maps.Geocoder()
      }

      return geocodeService.current?.geocode(request, filteredGeocodeResponse)
    },
    []
  )

  // Throttle the API calls to avoid rate limiting
  return {
    fetchPredictions: useMemo(
      () => throttle(fetchPredictions, 200),
      [fetchPredictions]
    ),
    fetchGeoDetails: useMemo(
      () => throttle(fetchGeoDetails, 200),
      [fetchGeoDetails]
    ),
    isLoading: !hasLoadedService,
  }
}

export default useGoogleAPI
