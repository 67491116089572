import { RootAPI, SynopUser } from '..'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useMemo } from 'react'

const { useGetUserQuery } = RootAPI.synopRootAPI

type MaybeSynopUser = SynopUser | undefined

export const useCurrentUser = () => {
  const { user } = useAuthenticator((context) => [context.user])

  const { data: currentUser, isLoading: isUserLoading } = useGetUserQuery(
    { id: user.username || '' },
    { skip: !user.username }
  )
  const isAdmin = useMemo(() => {
    return currentUser?.roles?.includes('Admin')
  }, [currentUser])

  return {
    user,
    synopUser: currentUser as MaybeSynopUser,
    isUserLoading,
    isAdmin,
  }
}
