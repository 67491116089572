import { useTheme } from '@mui/material'

export function usePalette() {
  return {
    palette: useTheme().palette,
    charting: useChartingColors(),
  }
}

type ChartColorIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

/**
 * Provides quick access to the charting colors from the theme.
 */
export function useChartingColors() {
  const { charting } = useTheme().palette
  const chartColors = [
    charting.chart1,
    charting.chart2,
    charting.chart3,
    charting.chart4,
    charting.chart5,
    charting.chart6,
    charting.chart7,
    charting.chart8,
  ]

  const mainColors = chartColors.map((color) => color.main)

  return Object.assign(makeColorObj(chartColors), {
    mainColors: makeColorObj(mainColors),
  })
}

function makeColorObj<T>(colorArray: T[]) {
  const colorObject = {
    1: colorArray[0],
    2: colorArray[1],
    3: colorArray[2],
    4: colorArray[3],
    5: colorArray[4],
    6: colorArray[5],
    7: colorArray[6],
    8: colorArray[7],
  } as Record<ChartColorIndex, T>

  return Object.assign(colorObject, { asArray: () => colorArray })
}
