import { useReducer } from 'react'

import { GenericEvent, useUserPrefs } from '@synop-react/api'
import { TimelineActions, TimelinePayload } from './timelineActions'
import dayjs, { Dayjs } from 'dayjs'

export type ChartArea = {
  height: number
  width: number
}

export type TimelineWindow = {
  width: number
  height: number
}

export type EventGroupingKey =
  | 'assetId'
  | 'chargerId'
  | 'dispenserId'
  | ('actorId' & keyof GenericEvent)
  | ((event: GenericEvent) => string)

export type TimelineOptions = {
  displayYAxis: boolean
}

export type TimelineState = {
  scheduledEvents: GenericEvent[]
  groupingKey: EventGroupingKey
  eventIds: string[]
  prevDisplayedDate: Dayjs
  displayedDate: Dayjs
  window: TimelineWindow
  leftAxis: ChartArea
  bottomAxis: ChartArea
  chartArea: ChartArea
  backgroundArea: ChartArea
  rowArea: ChartArea
  isOverlayOpen: boolean
  overlayEvent: GenericEvent | null
  tzDayjs: (date: dayjs.ConfigType) => Dayjs
} & TimelineOptions

const DEFAULT_WIDTH = 800
const DEFAULT_HEIGHT = 104
export const BOTTOM_AXIS_HEIGHT = 60
export const LEFT_AXIS_WIDTH = 148

export const defaultTimelineReducerState: TimelineState = {
  scheduledEvents: [],
  groupingKey: 'chargerId',
  eventIds: [],
  prevDisplayedDate: dayjs(),
  displayedDate: dayjs(),
  window: { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT },
  leftAxis: { width: LEFT_AXIS_WIDTH, height: 0 },
  bottomAxis: { width: DEFAULT_WIDTH, height: BOTTOM_AXIS_HEIGHT },
  chartArea: { width: DEFAULT_WIDTH, height: 0 },
  backgroundArea: { width: DEFAULT_WIDTH, height: 0 },
  rowArea: { width: DEFAULT_WIDTH, height: 0 },
  overlayEvent: null,
  isOverlayOpen: false,
  displayYAxis: false,
  tzDayjs: dayjs,
}

export default function useTimelineReducer(
  initialState: Partial<TimelineState> = defaultTimelineReducerState
) {
  const { tzDayjs } = useUserPrefs()
  const defaultState = Object.assign(
    {},
    {
      ...defaultTimelineReducerState,
      prevDisplayedDate: tzDayjs(),
      displayedDate: tzDayjs(),
      tzDayjs,
    },
    initialState
  )

  const timelineReducer = (
    state: TimelineState,
    action: TimelinePayload
  ): TimelineState => {
    switch (action.type) {
      case TimelineActions.SET_EVENTS: {
        const allEvents = action.payload
        const eventsWithEnd = allEvents.map(
          ({ scheduledEnd, ...scheduledEvent }) => {
            const endTime = scheduledEnd || tzDayjs().toString()
            return Object.assign({}, { scheduledEnd: endTime }, scheduledEvent)
          }
        )
        return Object.assign({}, state, { scheduledEvents: eventsWithEnd })
      }
      case TimelineActions.SET_DISPLAYED_DAY:
        return Object.assign({}, state, {
          prevDisplayedDate: state.displayedDate,
          displayedDate: action.payload,
        })
      case TimelineActions.SET_INNER_WINDOW:
        return Object.assign({}, state, {
          window: action.payload,
        })
      case TimelineActions.DISPLAY_EVENT_OVERLAY:
        return Object.assign({}, state, {
          overlayEvent: action.payload,
          isOverlayOpen: true,
        })
      case TimelineActions.CLOSE_EVENT_OVERLAY:
        return Object.assign({}, state, {
          overlayEvent: null,
          isOverlayOpen: false,
        })
      default:
        return state
    }
  }

  return useReducer(timelineReducer, defaultState)
}
