import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { ManageOrgMfaStatus } from '../MFA/ManageOrgMfaStatus'
import { OrgServiceTiles } from '../OrgServiceTiles'

export function OrgMgmtSettings() {
  return (
    <Stack spacing={1}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5">Settings</Typography>
        <Typography my={2} variant="body1">
          Enable or disable services for your organization account.
        </Typography>
        <Divider />
        <Box sx={{ pt: 4 }}>
          <Typography sx={{ pb: 2 }} variant="h6">
            Multi-factor Authentication
          </Typography>
          <Box sx={{ pl: 2 }}>
            <ManageOrgMfaStatus />
          </Box>
        </Box>
      </Paper>
      <OrgServiceTiles />
    </Stack>
  )
}

export default OrgMgmtSettings
