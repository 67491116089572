import { omit } from 'lodash'
import { useMemo, useState } from 'react'

import {
  Cask,
  LoadingMessage,
  Table,
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
} from '@synop-react/common'
import {
  CurrentChargerStatus,
  getDisplayedChargerStatus,
  RootAPI,
  useCurrentUser,
} from '@synop-react/api'

import { ChargerDatum, ChargerOrConnectorDatum, ConnectorDatum } from './common'
import { TableActions } from './TableActions'
import { useColumns } from './columns'

const { useGetChargersQuery } = RootAPI.synopRootAPI

type ChargersTableProps = {
  updateInterval?: number
}

/**
 * Returns the row's tree data path, which is used to determine the row's
 * nesting level in the table.
 *
 * NOTE: it's important that this function does not change between renders,
 * otherwise the table will re-render constantly, causing opened table rows
 * to be automatically closed after only a few seconds.
 */
function getTreeDataPath(row: ChargerOrConnectorDatum) {
  return row.path
}

export function ChargersTable({ updateInterval }: ChargersTableProps) {
  const { groupingColumn, columns } = useColumns()

  const [statuses, setStatuses] = useState<CurrentChargerStatus[]>([])
  const [searchString, setSearchString] = useState<string>()
  const { isLoading, ...parsedResponse } = useChargerAndConnectorData(
    statuses,
    searchString,
    updateInterval
  )

  const { synopUser, isAdmin } = useCurrentUser()

  if (
    parsedResponse &&
    parsedResponse.items &&
    !isAdmin &&
    synopUser &&
    synopUser.sites &&
    synopUser.sites.length > 0
  ) {
    parsedResponse.items = parsedResponse.items.filter(
      (item) =>
        synopUser &&
        synopUser.sites &&
        item.isCharger &&
        item.siteId &&
        synopUser.sites.includes(item.siteId)
    )
  }
  return (
    <Cask
      Actions={
        <TableActions
          setSearchString={setSearchString}
          setStatuses={setStatuses}
          statuses={statuses}
        />
      }
      title="Chargers"
    >
      {isLoading ? (
        <LoadingMessage />
      ) : (
        <Table.ClientSide<ChargerOrConnectorDatum>
          columns={columns}
          getRowId={(row) => row.id}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColumn}
          initialSortColumn="currentStatus"
          initialSortOrder="desc"
          noRowsMessage="No chargers match the selected filters"
          tableData={parsedResponse?.items || []}
        />
      )}
    </Cask>
  )
}

function useChargerAndConnectorData(
  statuses: CurrentChargerStatus[],
  searchString?: string,
  updateInterval = 60
) {
  const { selected: selectedSite } = useSiteSelector()
  const orgId = useSelectedOrgIdOrCurrent()
  const { data: chargersResponse, isLoading } = useGetChargersQuery(
    {
      organizationId: orgId ? [orgId] : undefined,
      siteIds: selectedSite?.id ? [selectedSite.id] : undefined,
      chargerStatus: statuses,
      count: 1000,
      searchString,
    },
    { skip: !orgId, pollingInterval: updateInterval * 1000 }
  )

  const items: ChargerOrConnectorDatum[] = useMemo(() => {
    if (!chargersResponse || !chargersResponse.items) return []
    return chargersResponse.items.flatMap((charger) => {
      return [parseChargerDatum(charger), ...parseConnectorData(charger)]
    })
  }, [chargersResponse])

  return { ...chargersResponse, items, isLoading }
}

/** Parses a charger from the API into a ChargerDatum */
function parseChargerDatum(charger: RootAPI.ChargerModel): ChargerDatum {
  return {
    connectorStatus: omit(charger.connectorStatus, ['0']),
    id: charger.chargerId,
    isCharger: true,
    lastHeard: charger.lastHeardTimestamp,
    liveUtilization: charger.livePowerImport,
    maxPower: charger.maxPower,
    name: charger.chargerName,
    numActiveConnectors: charger.activeConnectorIds?.length || 0,
    path: [charger.chargerId],
    siteId: charger.depotId,
    siteName: charger.depotName,
    currentStatus: getDisplayedChargerStatus(charger),
    lastKnownStatus: charger.latestChargerStatus.status ?? 'Unknown',
  }
}

/** Parses the connectors associated with a charger into `ConnectorDatum` objects */
function parseConnectorData(charger: RootAPI.ChargerModel): ConnectorDatum[] {
  const { chargerId, connectorStatus = {}, connectorUtilization } = charger
  if (!connectorStatus) return []
  return Object.entries(connectorStatus).flatMap(([connectorId, connector]) =>
    connectorId === '0'
      ? []
      : [
          {
            chargerStatus: getDisplayedChargerStatus(charger),
            id: `${chargerId}--${connectorId}`,
            isCharger: false,
            lastHeard: connector.statusTimestamp,
            liveUtilization:
              connectorUtilization?.[connectorId]?.livePowerImportKw,
            name: `Connector ${connectorId}`,
            path: [chargerId, connectorId],
            lastKnownStatus: connector.status ?? 'Unknown',
            currentStatus: connector.currentCombinedStatus ?? 'Unknown',
          },
        ]
  )
}
