import { AssetType, RootAPI } from '@synop-react/api'
import {
  Bell,
  OverlayDeprecated,
  useFormat,
  useRouting,
} from '@synop-react/common'
import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { NotificationPriorityPill } from '../NotificationPriorityPill'
import { NotificationStatusTile } from '../NotificationStatusTile'
import {
  removeOverlayNotifications,
  setOverlayOpen,
  useNotificationOverlayOpen,
  useOverlayNotifications,
} from '../store'
import { useDispatch } from 'react-redux'
import { useWindowSize } from 'react-use'
import React, { useState } from 'react'

const {
  useAcknowledgeSentNotificationMutation,
  useGetOrganizationQuery,
  useGetChargerQuery,
  useGetDepotQuery,
  useGetVehicleQuery,
} = RootAPI.synopRootAPI

type NotificationOverlayProps = {
  onClose: (ids: string[]) => void
}

export const NotificationOverlay = ({ onClose }: NotificationOverlayProps) => {
  const {
    routes: { notifications: notificationsRoute },
    navigate,
  } = useRouting()
  const { location } = useRouting()
  const dispatch = useDispatch()
  const isOpen = useNotificationOverlayOpen()
  const { width } = useWindowSize()
  const { formatDateTime } = useFormat()

  const [acknowledgeNotification] = useAcknowledgeSentNotificationMutation()
  const notifications = useOverlayNotifications()
  const isMultiple = notifications.length > 1

  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0)
  const currentNotification = notifications[currentNotificationIndex]

  const onAcknowledge = () => {
    if (!currentNotification) return

    dispatch(removeOverlayNotifications([currentNotification.id]))
    acknowledgeNotification({
      acknowledgeNotificationsModel: {
        sentNotificationIds: [currentNotification.id],
      },
    })

    if (currentNotificationIndex >= notifications.length - 1) {
      setCurrentNotificationIndex(currentNotificationIndex - 1)
    }
  }
  const setNotificationOverlayOpen = (newState: boolean) =>
    dispatch(setOverlayOpen(newState))

  const ackDateTime = currentNotification?.acknowledgedDateTime
  const action = currentNotification?.acknowledged ? (
    <Typography sx={{ ml: 1 }} variant="caption">
      Acknowledged at {formatDateTime(ackDateTime).time} on{' '}
      {formatDateTime(ackDateTime).date}
    </Typography>
  ) : (
    <Button color="primary" onClick={onAcknowledge} variant="contained">
      ACKNOWLEDGE
    </Button>
  )
  const actions = [action]
  if (location.pathname !== notificationsRoute) {
    actions.push(
      <Box
        sx={{ pr: 2, flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          color="primary"
          onClick={() => {
            navigate(notificationsRoute)
            setNotificationOverlayOpen(false)
          }}
          variant="outlined"
        >
          VIEW ALL NOTIFICATIONS
        </Button>
      </Box>
    )
  }

  return (
    <OverlayDeprecated
      isOpen={isOpen}
      onClose={() => {
        onClose(notifications.map(({ id }) => id))
      }}
      OverlayActions={actions}
      overlayPixelWidth={width * 0.7}
      setIsOpen={setNotificationOverlayOpen}
      title={
        isMultiple
          ? `Critical Notifications (${notifications.length})`
          : notifications[0]?.alertName || ''
      }
      TitleIcon={Bell}
      titlePill={
        !isMultiple ? (
          <NotificationPriorityPill priority={notifications[0]?.priority} />
        ) : undefined
      }
    >
      <Grid container spacing={2}>
        {isMultiple && (
          <Grid item xs={12}>
            <Tabs
              onChange={(_, index) => setCurrentNotificationIndex(index)}
              scrollButtons="auto"
              value={currentNotificationIndex}
              variant="scrollable"
            >
              {notifications.map((notification) => (
                <Tab
                  key={notification.id}
                  label={
                    notification.alertName.length > 30
                      ? `${notification.alertName.slice(0, 27)}...`
                      : notification.alertName
                  }
                />
              ))}
            </Tabs>
          </Grid>
        )}
        <NotificationOverlayBody notification={currentNotification} />
      </Grid>
    </OverlayDeprecated>
  )
}

type NotificationOverlayBodyProps = {
  notification?: RootAPI.NotificationModel
}

const NotificationOverlayBody = ({
  notification,
}: NotificationOverlayBodyProps) => {
  const { data: organization } = useGetOrganizationQuery(
    { id: notification?.organizationId ?? '' },
    { skip: !notification?.organizationId }
  )

  if (!notification) return null

  const assetComponentMap: Record<AssetType, React.FC<AssetTileProps>> = {
    Charger: ChargerAssetTile,
    Vehicle: VehicleAssetTile,
  }

  const AssetTile = assetComponentMap[notification.assetType]

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: 'primary.100',
            p: 2,
          }}
        >
          <Typography variant="subtitle1">
            {notification.message ?? ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Stack>
          <Typography variant="caption">Status</Typography>
          <NotificationStatusTile notification={notification} />
        </Stack>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Stack>
          <Typography variant="caption">Organization</Typography>
          <Typography variant="subtitle1">
            {organization?.organizationNm ?? ''}
          </Typography>
        </Stack>
      </Grid>
      <AssetTile assetId={notification.assetId ?? ''} />
    </Grid>
  )
}

type AssetTileProps = {
  assetId: string
}

const ChargerAssetTile = ({ assetId }: AssetTileProps) => {
  const dispatch = useDispatch()
  const { chargers, siteDetails } = useRouting().routes
  const { data: charger } = useGetChargerQuery(
    {
      chargerId: assetId,
    },
    {
      skip: !assetId,
    }
  )
  const depotName = charger?.depotName
  return (
    <>
      {depotName && (
        <Grid item md={3} sm={6} xs={12}>
          <Stack>
            <Typography variant="caption">Site</Typography>
            <Typography variant="subtitle1">
              <Link
                href={siteDetails(charger?.depotId ?? '')}
                onClick={() => dispatch(setOverlayOpen(false))}
                sx={{ textDecoration: 'none' }}
              >
                {depotName}
              </Link>
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item md={depotName ? 3 : 6} sm={depotName ? 6 : 12} xs={12}>
        <Stack>
          <Typography variant="caption">Charger</Typography>
          <Typography noWrap variant="subtitle1">
            <Link
              href={chargers.details(charger?.chargerId ?? '')}
              onClick={() => dispatch(setOverlayOpen(false))}
              sx={{ textDecoration: 'none' }}
            >
              {charger?.chargerName}
            </Link>
          </Typography>
        </Stack>
      </Grid>
    </>
  )
}

const VehicleAssetTile = ({ assetId }: AssetTileProps) => {
  const dispatch = useDispatch()
  const { vehicles, siteDetails } = useRouting().routes
  const { data: vehicle } = useGetVehicleQuery(
    {
      id: assetId,
    },
    {
      skip: !assetId,
    }
  )
  const { data: depot } = useGetDepotQuery(
    {
      depotId: vehicle?.homeSiteId ?? '',
    },
    {
      skip: !vehicle?.homeSiteId,
    }
  )
  const depotName = depot?.depotNm
  return (
    <>
      {depotName && (
        <Grid item md={3} sm={6} xs={12}>
          <Stack>
            <Typography variant="caption">Home Site</Typography>
            <Typography variant="subtitle1">
              <Link
                href={siteDetails(vehicle?.homeSiteId ?? '')}
                onClick={() => dispatch(setOverlayOpen(false))}
                sx={{ textDecoration: 'none' }}
              >
                {depotName}
              </Link>
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item md={depotName ? 3 : 6} sm={depotName ? 6 : 12} xs={12}>
        <Stack>
          <Typography variant="caption">Vehicle</Typography>
          <Typography noWrap variant="subtitle1">
            <Link
              href={vehicles.details(vehicle?.id ?? '')}
              onClick={() => dispatch(setOverlayOpen(false))}
              sx={{ textDecoration: 'none' }}
            >
              {vehicle?.vehicleNm}
            </Link>
          </Typography>
        </Stack>
      </Grid>
    </>
  )
}
