import { Depot } from '../../@types'
import { EntityFilterer, EntitySearchInput } from '@synop-react/common'
import { EntityIdMap } from '@synop-react/types'

/* eslint-disable-next-line */
export interface DepotSearchInputProps {}

export function DepotSearchInput() {
  const filterVehicles: EntityFilterer = (
    searchString: string,
    depots: EntityIdMap<Depot>
  ) =>
    Object.keys(depots)
      .filter((depotId) =>
        depots[depotId]?.depotNm
          ?.toLowerCase()
          .includes(searchString.toLowerCase())
      )
      .reduce((acc, entityId) => {
        acc[entityId] = true
        return acc
      }, {} as Record<string, boolean>)

  return <EntitySearchInput filterFn={filterVehicles} placeholder="Search..." />
}

export default DepotSearchInput
