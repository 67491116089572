import { AlertStep, AlertStepCommonProps } from './AlertStep'
import {
  AssetType,
  assetTypes,
  priorities,
  Priority,
  RootAPI,
  useCurrentOrganization,
  useCurrentOrgId,
} from '@synop-react/api'
import { FormField, simpleSelectFactory } from '@synop-react/common'
import { Grid } from '@mui/material'
import { tooltipText } from './tooltipText'
import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

const {
  useGetDepotsQuery,
  useGetChargersQuery,
  useGetOrganizationCustomersQuery,
  useGetVehiclesForOrganizationQuery,
} = RootAPI

export const AlertContextStep = (props: AlertStepCommonProps) => {
  const alertId: string = useWatch({ name: 'alertId' })
  const alertName = useWatch({ name: 'alertName' })

  return (
    <AlertStep
      {...props}
      stepDescription="Adjust the alert context below."
      stepName="Context"
      stepNumber={0}
    >
      <Grid container item xs={12}>
        <Grid item sm={6} xs={12}>
          <FormField.WrappedTextFormField
            helperText={`${alertName.length} / 100 Characters`}
            id="alertName"
            label="Alert Name"
            tooltip={tooltipText.alertName}
          />
        </Grid>
      </Grid>

      <Grid item sm={6} xs={12}>
        <OrgMultiSelect />
      </Grid>

      <Grid item sm={6} xs={12}>
        <SiteMultiSelect />
      </Grid>

      <Grid item sm={6} xs={12}>
        <AssetTypeSelect
          disableClearable
          disabled={!!alertId}
          id="assetType"
          label="Asset Type"
          tooltip={tooltipText.assetType}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <AssetMultiSelect />
      </Grid>

      <Grid item sm={6} xs={12}>
        <PrioritySelect
          disableClearable
          id="priority"
          label="Priority"
          tooltip={tooltipText.priority}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <FormField.WrappedTextFormField
          id="lookbackPeriod"
          label="Auto-resolve Period (Days)"
          tooltip={tooltipText.lookbackPeriod}
        />
      </Grid>
    </AlertStep>
  )
}

const AssetTypeSelect = simpleSelectFactory<AssetType>([...assetTypes])
const PrioritySelect = simpleSelectFactory<Priority>([...priorities])

const OrgMultiSelect = () => {
  const { currentOrg } = useCurrentOrganization()
  const { data: organizations, isLoading } = useGetOrganizationCustomersQuery(
    { id: currentOrg?.id ?? '', skipEnhanceData: true },
    { skip: !currentOrg?.id }
  )

  const orgOptions = useMemo(() => {
    const options = organizations
      ?.filter((org) => org?.organizationNm)
      .map((org) => ({
        label: org?.organizationNm ?? '',
        value: org.id,
      }))
    if (currentOrg) {
      options?.push({
        label: currentOrg.organizationNm ?? '',
        value: currentOrg.id,
      })
    }
    return options
  }, [organizations, currentOrg])

  return (
    <FormField.WrappedAutocompleteMultiselectFormField
      id="organizations"
      label="Organizations"
      loading={isLoading}
      options={orgOptions ?? []}
      tooltip={tooltipText.organizations}
    />
  )
}

const SiteMultiSelect = () => {
  const orgId = useCurrentOrgId()
  const { data: sites, isLoading } = useGetDepotsQuery(
    { fleetId: orgId, count: 1000 },
    { skip: !orgId }
  )
  const organizations: string[] = useWatch({ name: 'organizations' })

  const siteOptions = useMemo(() => {
    return sites?.items
      ?.filter((site) => {
        if (organizations.length) {
          if (!site.fleetId || !organizations.includes(site.fleetId))
            return false
        }
        return site.depotNm
      })
      .map((site) => ({
        label: site?.depotNm || '',
        value: site.depotId,
      }))
  }, [sites, organizations])

  return (
    <FormField.WrappedAutocompleteMultiselectFormField
      id="sites"
      label="Sites"
      loading={isLoading}
      options={siteOptions ?? []}
      tooltip={tooltipText.sites}
    />
  )
}

const AssetMultiSelect = () => {
  const { currentOrg } = useCurrentOrganization()
  const organizations: string[] = useWatch({ name: 'organizations' })
  const sites: string[] = useWatch({ name: 'sites' })

  const assetType: AssetType = useWatch({ name: 'assetType' })

  const { data: orgVehicles, isLoading: isLoadingOrgVehicles } =
    useGetVehiclesForOrganizationQuery(
      {
        id: currentOrg?.id ?? '',
      },
      { skip: !currentOrg || assetType !== 'Vehicle' }
    )

  const vehicleOptions = useMemo(() => {
    const vehicles = !organizations.length
      ? orgVehicles?.filter((vehicle) => {
          if (!sites.length) return true
          return vehicle.homeSiteId && sites.includes(vehicle.homeSiteId)
        })
      : orgVehicles?.filter((vehicle) => {
          const { homeSiteId, organizationId } = vehicle

          const orgMatch =
            organizationId && organizations.includes(organizationId)

          const siteMatch = sites.length
            ? homeSiteId && sites.includes(homeSiteId)
            : true

          return orgMatch && siteMatch
        })

    return vehicles
      ?.filter((vehicle) => vehicle.vehicleNm)
      .map((vehicle) => ({
        label: vehicle?.vehicleNm || '',
        value: vehicle.id,
      }))
  }, [orgVehicles, sites, organizations])

  const { data: chargers, isLoading: isLoadingChargers } = useGetChargersQuery(
    {
      organizationId:
        Array.isArray(organizations) && organizations.length
          ? organizations
          : [currentOrg?.id ?? ''],
      siteIds: sites,
      count: 1000,
    },
    {
      skip:
        ((!Array.isArray(organizations) || !organizations.length) &&
          !currentOrg) ||
        assetType !== 'Charger',
    }
  )

  const chargerOptions = useMemo(() => {
    return chargers?.items
      ?.filter((charger) => charger.chargerName)
      .map((charger) => ({
        label: charger?.chargerName || '',
        value: charger.chargerId,
      }))
  }, [chargers])

  return (
    <FormField.WrappedAutocompleteMultiselectFormField
      id="assets"
      label="Assets"
      loading={isLoadingChargers || isLoadingOrgVehicles}
      options={
        assetType === 'Charger' ? chargerOptions ?? [] : vehicleOptions ?? []
      }
      tooltip={tooltipText.assets}
    />
  )
}
