import { Controller, useFormContext } from 'react-hook-form'
import { Dayjs } from 'dayjs'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers'
import { TextFieldProps } from '@mui/material'
import { useUserPrefs } from '@synop-react/api'

type DatePickerProps = {
  id: string
  label?: string
  variant?: TextFieldProps['variant']
  error?: boolean
} & Partial<MuiDatePickerProps<Dayjs>>

export const DatePicker = ({
  id,
  label,
  variant = 'standard',
  error = undefined,
  ...props
}: DatePickerProps) => {
  const { control } = useFormContext()
  const { tzDayjs, preferredDateFormat } = useUserPrefs()

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <MuiDatePicker
          format={preferredDateFormat}
          label={label}
          {...field}
          {...props}
          {...fieldState}
          slotProps={{
            textField: {
              helperText: fieldState.error?.message,
              variant: variant,
              color: 'primary',
              sx: { width: '100%' },
              error: error,
            },
          }}
          value={tzDayjs(field.value)}
        />
      )}
    />
  )
}

export default DatePicker
