import { Box } from '@mui/material'
import { featureCollection, Point, Polygon } from '@turf/helpers'
import { LocationMapLayers } from '../LocationLayers'
import { PropsWithChildren } from 'react'
import { Source } from 'react-map-gl'
import { SynopMap } from '@synop-react/common'

import { getLocationFeatures, LocationProperties } from '../getLocationFeatures'
import { isDefined, PolygonFeature } from '@synop-react/types'

type LocationMapProps = PropsWithChildren<{
  locationId?: string
  latitude?: number
  longitude?: number
  geofence?: string | PolygonFeature
}>

export const LocationMap = ({
  children,
  locationId = '',
  latitude,
  longitude,
  geofence,
}: LocationMapProps) => {
  const locationFeatures = getLocationFeatures({
    locationId,
    latitude,
    longitude,
    geofence,
  })

  const locationCollection = featureCollection<
    Point | Polygon,
    LocationProperties
  >(
    [locationFeatures.locationMarker, locationFeatures.locationGeofence].filter(
      isDefined
    )
  )

  return (
    <Box sx={{ width: '100%', height: '368px' }}>
      <SynopMap
        boundedData={locationCollection}
        defaultMaxZoom={16}
        defaultMinZoom={10}
        id="loiMap"
      >
        <Source
          data={locationCollection}
          id="loiSource"
          lineMetrics={true}
          type="geojson"
        >
          <LocationMapLayers mapId="loiMap" sourceId="loiSource" />
        </Source>
        {children}
      </SynopMap>
    </Box>
  )
}
