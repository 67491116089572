import * as yup from 'yup'
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FieldError, FieldValues, useForm, useWatch } from 'react-hook-form'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { yupResolver } from '@hookform/resolvers/yup'
import centroid from '@turf/centroid'

import {
  AddressAutocomplete,
  FormField,
  GoogleAutocomplete,
  isAutocompleteResponse,
  isGeofenceValid,
  OrgAutocomplete,
  OverlayDeprecated,
  PlusCircle,
  Settings,
  useGoogleAPI,
} from '@synop-react/common'
import { DepotMapTile } from '../Map/DepotMapTile'
import { EditGeofenceMap } from '../Map/EditGeofenceMap'
import { getDepotFeatures } from '../Map/LocationLayers'
import { LatLong, PolygonFeature, Tuple } from '@synop-react/types'
import {
  RootAPI,
  useCurrentOrganization,
  useCurrentOrgId,
  useDepotDetailsFromPoll,
} from '@synop-react/api'

const {
  useCreateDepotMutation,
  useUpdateDepotMutation,
  useGetOrganizationQuery,
} = RootAPI

export type EditDepotOverlayProps = {
  Trigger: ReactElement
  depotId?: string
  organizationId?: string
}

type SiteAddress = GoogleAutocomplete | string
type EditDepotFormProps = {
  depotName: string
  siteLimit: number
  siteAddress?: SiteAddress
  organization: RootAPI.OrganizationModel
  isLoadBalanced?: boolean
}

const schema = yup
  .object({
    depotName: yup.string().required('Site Name is required'),
    siteLimit: yup
      .number()
      .transform((val, original) => (original === '' ? null : val))
      .nullable()
      .required('Site Limit is required'),
    siteAddress: yup
      .mixed<SiteAddress>()
      .transform((val, original) => (original === '' ? null : val))
      .required('Site Address is required'),
    organization: yup.object().nullable().required('Organization is required'),
  })
  .required()

export function CreateOrEditDepotOverlay({
  depotId,
  Trigger,
}: EditDepotOverlayProps) {
  const { palette } = useTheme()
  const orgId = useCurrentOrgId()

  const [isOpen, setIsOpen] = useState(false)
  const [isEditingGeofence, setIsEditingGeofence] = useState(false)
  const [locationMarker, setLocationMarker] = useState<LatLong>()
  const [geofence, setGeofence] = useState<PolygonFeature>()
  const [prevGeofence, setPrevGeofence] = useState<PolygonFeature>()

  const {
    getDepot: { data: depot },
  } = useDepotDetailsFromPoll({
    depotId: depotId || '',
    disablePolling: true,
  })
  const { data: siteOrg } = useGetOrganizationQuery(
    { id: depot?.fleetId as string },
    { skip: !depot || !depot.fleetId }
  )
  const [updateDepot, updateDepotResponse] = useUpdateDepotMutation()
  const [createDepot, createDepotResponse] = useCreateDepotMutation()

  const defaultValues = useMemo(() => {
    return {
      depotName: depot?.depotNm,
      siteLimit: depot?.powerCeiling,
      organization: siteOrg,
      siteAddress: depot?.address,
      isLoadBalanced: depot?.isLoadBalanced,
    }
  }, [depot, siteOrg])

  const {
    control,
    formState: { errors, touchedFields, isValid },
    reset,
    handleSubmit,
  } = useForm<EditDepotFormProps>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  })
  const siteAddress = useWatch({
    control: control,
    name: 'siteAddress',
  })

  const { fetchGeoDetails, isLoading: isLoadingGeocoder } = useGoogleAPI()

  const updating = !!depotId
  const title = updating ? 'Configure Site' : 'New Site'
  const subtitle = updating
    ? 'Adjust the site information below.'
    : 'Provide the following information to add a new site to your organization.'
  const titleIcon = updating ? Settings : PlusCircle

  // Reset default values when they change (i.e. when the site or org changes)
  useEffect(() => reset(defaultValues), [defaultValues, reset])

  // Update the geofence when the site loads
  useEffect(() => setGeofence(getDepotFeatures(depot)?.depotGeofence), [depot])

  // Derive the site's lat/long. If there's a geofence, use its centroid. Otherwise, if the
  // user has entered an address in the form and the Google API recognizes it, use that.
  // Lastly, if the site already has a lat/long pair, use that.
  //
  // Note that the lat/long is not derived from `depot.address`. This is because
  // `depot.address` is a string, which is insufficient for inferring the site
  // location. Instead, we use the `siteAddress` from the form, which is
  // responsive to the user's edits. This does mean that sites with an address
  // but no lat/long or geofence will not have the location marker indicated on
  // the map, but this is a rare case.
  useEffect(() => {
    if (geofence) {
      try {
        const center = centroid(geofence)
        const [long, lat] = center.geometry.coordinates as Tuple<number>
        setLocationMarker([long, lat])
        return
      } catch (e) {
        console.error(e)
      }
    } else if (isAutocompleteResponse(siteAddress) && !isLoadingGeocoder) {
      fetchGeoDetails({ placeId: siteAddress.place_id }, ({ location }) =>
        setLocationMarker(location)
      )
    } else if (depot) {
      // Get the lat/long from the depot if it has one
      const { latitude, longitude } = depot
      setLocationMarker(
        typeof latitude === 'number' && typeof longitude === 'number'
          ? [longitude, latitude]
          : undefined
      )
    } else {
      // Otherwise, clear it. This is important for cases where the user has
      // entered an address or geofence but then deletes it.
      setLocationMarker(undefined)
    }
  }, [depot, fetchGeoDetails, geofence, isLoadingGeocoder, siteAddress])

  const onClose = useCallback(() => {
    setIsEditingGeofence(false)
    setGeofence(
      depot?.geofence ? getDepotFeatures(depot).depotGeofence : undefined
    )
    const { latitude, longitude } = depot ?? {}
    if (latitude && longitude) {
      setLocationMarker([longitude, latitude])
    } else {
      setLocationMarker(undefined)
    }
    reset()
  }, [depot, reset])

  // Close the overlay when the depot is updated or created
  useEffect(() => {
    if (
      updateDepotResponse.status === QueryStatus.fulfilled ||
      createDepotResponse.status === QueryStatus.fulfilled
    ) {
      setIsOpen(false)
      onClose()
    }
  }, [updateDepotResponse, createDepotResponse, setIsOpen, onClose])

  const [longitude, latitude] = locationMarker ?? []
  const onUpdateSubmit = ({
    depotName,
    siteLimit,
    isLoadBalanced,
    organization,
  }: EditDepotFormProps) => {
    const updatedGeofence = geofence?.geometry?.coordinates[0]
    const locationArgs =
      typeof latitude === 'number' && typeof longitude === 'number'
        ? { location: { type: 'point', coordinates: [longitude, latitude] } }
        : {}

    updateDepot({
      depotMetaDataUpdateModel: {
        depotId: depotId || '',
        depotNm: depotName,
        fleetId: organization.id,
        siteLimit, // NOTE: this needs to be siteLimit for update, but powerCeiling for create
        address: isAutocompleteResponse(siteAddress)
          ? siteAddress.description
          : siteAddress,
        ...locationArgs,
        isLoadBalanced: !!isLoadBalanced,
        geofence: updatedGeofence ? JSON.stringify(updatedGeofence) : undefined,
      },
    })
  }

  const onCreateSubmit = ({
    depotName,
    siteLimit,
    isLoadBalanced,
    organization,
    siteAddress,
  }: EditDepotFormProps) => {
    const updatedGeofence = geofence?.geometry?.coordinates[0]
    const locationArgs =
      typeof latitude === 'number' && typeof longitude === 'number'
        ? { location: { type: 'point', coordinates: [longitude, latitude] } }
        : {}

    createDepot({
      depotCreationModel: {
        fleetId: organization.id || '',
        depotNm: depotName,
        powerCeiling: siteLimit, // NOTE: this needs to be siteLimit for update, but powerCeiling for create
        address: isAutocompleteResponse(siteAddress)
          ? siteAddress.description
          : siteAddress,
        ...locationArgs,
        isLoadBalanced: !!isLoadBalanced,
        geofence: updatedGeofence ? JSON.stringify(updatedGeofence) : undefined,
      },
    })
  }

  const onSubmit = updating ? onUpdateSubmit : onCreateSubmit

  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => {
      setIsOpen(true)
    },
  })

  return (
    <>
      {OverlayTrigger}
      <OverlayDeprecated
        isOpen={isOpen}
        onClose={onClose}
        OverlayActions={[
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={!isValid || isEditingGeofence}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              {updating ? 'SAVE' : 'CREATE SITE'}
            </Button>
            {isEditingGeofence && (
              <Typography sx={{ ml: 2 }} variant="caption">
                Your geofence is not saved. Please save the geofence or cancel
                editing to continue.
              </Typography>
            )}
          </Grid>,
        ]}
        setIsOpen={setIsOpen}
        subtitle={subtitle}
        title={title}
        TitleIcon={titleIcon}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={4}>
              <FormField.TextFormField
                control={control}
                error={errors.depotName}
                fullWidth
                id="depotName"
                label="Site Name"
                touched={Boolean(touchedFields.depotName)}
                type="text"
              />
            </Grid>
            <Grid item xs={4}>
              <FormField.TextFormField
                control={control}
                error={errors.siteLimit}
                fullWidth
                id="siteLimit"
                label="Site Limit (kW)"
                touched={Boolean(touchedFields.siteLimit)}
                // Type tel should remove the up down arrows on the input
                type="tel"
              />
            </Grid>
            <Grid item xs={4}>
              <OrgAutocomplete.Select
                control={control}
                error={errors.organization as FieldError}
                id="organization"
                label="Organization"
                orgId={orgId}
                touchedField={Boolean(touchedFields.organization)}
              />
            </Grid>
            <Grid item xs={8}>
              <AddressAutocomplete.Select
                control={control}
                error={errors.siteAddress as FieldError}
                id="siteAddress"
                label="Site Address"
                touchedField={Boolean(touchedFields.siteAddress)}
              />
            </Grid>
            {isEditingGeofence ? (
              <Grid item xs={12}>
                <Box sx={{ position: 'relative', zIndex: 2 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      zIndex: 3,
                    }}
                  >
                    <Button
                      disabled={
                        !isGeofenceValid(geofence?.geometry?.coordinates[0])
                      }
                      onClick={() => setIsEditingGeofence(false)}
                      variant="contained"
                    >
                      SAVE GEOFENCE
                    </Button>
                    <Button
                      onClick={() => {
                        setGeofence(prevGeofence)
                        setIsEditingGeofence(false)
                      }}
                      sx={{
                        ml: 2,
                        backgroundColor: palette.background.paper,
                        '&:hover': {
                          backgroundColor: palette.background.paper,
                        },
                      }}
                      variant="outlined"
                    >
                      CANCEL
                    </Button>
                  </Stack>
                  <EditGeofenceMap
                    depotMarker={locationMarker}
                    geofence={geofence || undefined}
                    setDepotMarker={setLocationMarker}
                    setGeofence={setGeofence}
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Box
                  sx={{
                    minWidth: '420px',
                    position: 'relative',
                    zIndex: 2,
                  }}
                >
                  <Button
                    onClick={() => {
                      setPrevGeofence(geofence)
                      setGeofence(undefined)
                      setIsEditingGeofence(true)
                    }}
                    sx={{
                      maxWidth: '165px',
                      backgroundColor: palette.background.paper,
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      zIndex: 3,
                      '&:hover': {
                        backgroundColor: palette.background.paper,
                      },
                    }}
                    variant="outlined"
                  >
                    {updating ? 'EDIT GEOFENCE' : 'CREATE GEOFENCE'}
                  </Button>
                  <DepotMapTile
                    geofence={geofence || undefined}
                    latitude={latitude}
                    longitude={longitude}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <EMSToggle
                control={control}
                error={errors.isLoadBalanced as FieldError}
                id="isLoadBalanced"
              />
            </Grid>
          </Grid>
        </form>
      </OverlayDeprecated>
    </>
  )
}

export default CreateOrEditDepotOverlay

type EMSToggleProps<FormData extends FieldValues> = {} & Omit<
  FormField.SwitchFormFieldProps<FormData>,
  'label'
>

const EMSToggle = function <FormData extends FieldValues>({
  ...formControls
}: EMSToggleProps<FormData>) {
  const { currentOrg } = useCurrentOrganization()

  const { hasEnergyManagement } = currentOrg

  return hasEnergyManagement ? (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <Divider />
      <Stack alignItems="center" direction="row" spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h6">Energy Management Service</Typography>
          <Typography variant="body2">
            When enabled, Energy Management Service will automatically configure
            your site and charger to dynamically optimize power usage.
          </Typography>
        </Stack>
        <FormField.Switch {...formControls} />
      </Stack>
    </Stack>
  ) : null
}
