import { Alert, Container, Grid, useTheme } from '@mui/material'

import { CompletedTrips } from '../VehicleTabCask/CompletedTrips'
import { DailyVehicleSummaryCask } from '../DailyVehicleSummaryCask'
import { RootAPI } from '@synop-react/api'
import { VehicleDetailsCask } from '../VehicleDetailsCask'
import { VehicleMapTile } from '../VehicleMapTile'

import { TabCaskView } from '@synop-react/common'
import { VehicleTelemetryChart } from '../TelemetryChart'
import { VehicleTransactionHistory } from '@synop-react/depot'
import VehicleDetailPageHeader from '../VehicleDetailPageHeader'
import VehicleStatusCask from '../VehicleStatusCask'
import VehicleTimeline from '../VehicleTimeline'

export type VehicleDetailsProps = { vehicleId: string }

const { useGetVehicleQuery } = RootAPI.synopRootAPI

export function VehicleDetails({ vehicleId }: VehicleDetailsProps) {
  const theme = useTheme()
  const { data: vehicle } = useGetVehicleQuery({
    id: vehicleId,
  })

  return (
    <Container>
      <Grid container direction="row" rowSpacing={theme.spacing(4)}>
        {vehicle && !vehicle.telematicsTimestamp && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Telematics data may be limited or not available for this vehicle.
              Attributes such as status, state of charge, range and efficiency
              may be blank or out of date.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <VehicleDetailPageHeader vehicleId={vehicleId} />
        </Grid>
        <Grid columnSpacing={theme.spacing(4)} container item>
          <Grid item xs={9}>
            <VehicleStatusCask vehicleId={vehicleId} />
          </Grid>

          <Grid item xs={3}>
            <VehicleMapTile vehicleId={vehicleId} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DailyVehicleSummaryCask vehicleId={vehicleId} />
        </Grid>
        <Grid item xs={12}>
          <VehicleTelemetryChart vehicleId={vehicleId} />
        </Grid>

        <Grid item xs={12}>
          <VehicleTimeline vehicleId={vehicleId} />
        </Grid>

        <Grid item xs={12}>
          {vehicle?.macAddress ? (
            <TabCaskView
              tabItems={[
                {
                  label: 'Completed Trips',
                  content: <CompletedTrips vehicleId={vehicleId} />,
                },
                {
                  label: 'Charging History',
                  content: (
                    <VehicleTransactionHistory
                      vehicleOcppTag={vehicle.macAddress}
                    />
                  ),
                },
              ]}
            />
          ) : (
            <CompletedTrips vehicleId={vehicleId} />
          )}
        </Grid>

        <Grid item sx={{ mb: 6 }} xs={12}>
          <VehicleDetailsCask vehicleId={vehicleId} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default VehicleDetails
