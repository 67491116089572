import { Entity, EntityIdMap } from '@synop-react/types'
import { EntitySorter } from '../../../..'

export const parseEntitiesToIds = (entities: Entity[]) =>
  entities.map((entity) => entity.id)

export const sortEntities = (entities: Entity[], sorters: EntitySorter[]) => {
  let sortedEntities = [...entities]
  sorters.forEach(({ comparator, direction }) => {
    sortedEntities = comparator(sortedEntities, direction)
  })
  return sortedEntities
}

export function parseEntityMapToBoolMap(entities: EntityIdMap) {
  return Object.keys(entities).reduce<Record<string, boolean>>((acc, id) => {
    acc[id] = true
    return acc
  }, {})
}
