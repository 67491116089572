import { Stack } from '@mui/material'
import { useRouting } from '@synop-react/common'
import NavLink from './NavLink'

const NavMenu = () => {
  const { routes } = useRouting()
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <NavLink label="Dashboard" to={routes.dashboard.root} />
      <NavLink label="Vehicles" to={routes.vehicles.root} />
      <NavLink label="Sites" to={routes.sites} />
      <NavLink label="Chargers" to={routes.chargers.root} />
      <NavLink label="Reports" to={routes.reports} />
    </Stack>
  )
}

export default NavMenu
