import { AssetType } from '@synop-react/api'
import { Card, Grid, IconButton, Typography } from '@mui/material'
import { Property } from '../AlertSteps'
import { useAlertPropertyContext } from './AlertPropertyInitializedContext'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { X } from '@synop-react/common'

type AlertPropertyProps = {
  index: number
  totalProperties: number
  property: Property
  setProperty: (index: number, property: Property | null) => void
}

export const AlertProperty = ({
  index,
  totalProperties,
  property,
  setProperty,
}: AlertPropertyProps) => {
  const { propertyFieldMap, propertyDropdown, propertyControlMap } =
    useAlertPropertyContext()

  const assetType: AssetType = useWatch({ name: 'assetType' })
  const [field, setField] = useState(getTopLevelField(property))

  useEffect(() => {
    if (!field) return
    if (propertyFieldMap[field]?.assetType !== assetType) {
      const controlMap = propertyControlMap[assetType]
      const fields = Object.keys(controlMap)
      const newField = fields[0]
      if (newField) setField(newField)
    }
  }, [assetType, field, propertyControlMap, propertyFieldMap])

  const Control = useMemo(() => {
    const control = propertyControlMap[assetType][field]
    if (!control) return null

    if (getTopLevelField(property) !== field) {
      setProperty(index, control.defaultProperty)
    }
    return control.component

    //When I include index and setProperty in the dependency array, it causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  const FieldSelector = propertyDropdown[assetType]
  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Typography variant="subtitle2">Property {index + 1}</Typography>
        </Grid>

        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1}>
          {totalProperties > 1 && (
            <IconButton
              edge="start"
              onClick={() => setProperty(index, null)}
              size="small"
            >
              <X />
            </IconButton>
          )}
        </Grid>

        <Grid item xs={3}>
          <FieldSelector
            disableClearable
            label="Property"
            onChange={(newValue) => {
              if (!newValue) return
              setField(newValue)
            }}
            value={field}
          />
        </Grid>

        {Control && (
          <Control
            property={property}
            updateProperty={(newProperty) => setProperty(index, newProperty)}
          />
        )}
      </Grid>
    </Card>
  )
}

export function getTopLevelField(property: Property): string {
  if ('field' in property) return property.field
  return getTopLevelField(property.rules[0] as Property)
}
