import * as yup from 'yup'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { Cask, FormField, useFormat } from '@synop-react/common'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { useForm } from 'react-hook-form'
import { useRollbar } from '@rollbar/react'
import { yupResolver } from '@hookform/resolvers/yup'

const { useCreateTripNoteMutation } = RootAPI.synopRootAPI

const schema = yup
  .object({
    content: yup.string().required('Enter a valid note'),
  })
  .required()

export interface TripNotesCaskProps {
  notes: RootAPI.TripNote[]
  tripId: string
}

export function TripNotesCask({ notes, tripId }: TripNotesCaskProps) {
  const rollbar = useRollbar()
  const { user, synopUser } = useCurrentUser()

  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm<NoteForm>({
    defaultValues: {
      content: '',
    },
    resolver: yupResolver(schema),
  })

  type NoteForm = {
    content: string
  }

  const [saveNote] = useCreateTripNoteMutation()
  const onSubmit = async (note: RootAPI.TripNote) => {
    try {
      saveNote({
        tripNote: {
          ...note,
          tripId: tripId,
          fromUserId: user?.username,
          content: note.content,
          senderName: synopUser?.name,
        },
      }).unwrap()
    } catch (error) {
      if (error instanceof Error) {
        rollbar.error(error.message, error)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Cask title="Trip Notes" titleOnly>
        <Grid
          alignItems="flex-start"
          container
          direction="column"
          justifyContent="flex-start"
          sx={{ height: 350 }}
        >
          <Grid
            item
            sx={{
              width: '100%',
            }}
          >
            <FormField.TextFormField
              control={control}
              error={errors.content}
              fullWidth
              id="content"
              multiline={true}
              rows={3}
              touched={Boolean(touchedFields.content)}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              sx={{ mt: 1 }}
              type="submit"
              variant="outlined"
            >
              Post Note
            </Button>
          </Grid>
          <Grid item sx={{ mt: 3, width: '100%' }}>
            {notes &&
              notes.map((note) => {
                return <TripNote note={note} />
              })}
          </Grid>
        </Grid>
      </Cask>
    </form>
  )
}
interface TripNoteProps {
  note: RootAPI.TripNote
}

function TripNote({ note }: TripNoteProps) {
  const { formatDateTime } = useFormat()

  const timestamp = formatDateTime(note?.updated).atTimeOnDate
  return (
    <>
      <Typography sx={{ mb: 3 }} variant="body1">
        {note?.content}
      </Typography>
      <Typography sx={{ mb: 1 }} variant="overline">
        Posted by {note?.senderName} at {timestamp}
      </Typography>
      <Divider sx={{ mt: 2 }} />
    </>
  )
}
export default TripNotesCask
