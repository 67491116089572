import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { MuiTelInput } from 'mui-tel-input'

type PhoneInputProps<FormData extends FieldValues> = {
  id: Path<FormData>
  control: Control<FormData, unknown>
}
const PhoneInput = <FormData extends FieldValues>({
  control,
  id,
}: PhoneInputProps<FormData>) => {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <MuiTelInput
          defaultCountry="US"
          error={!!fieldState.error}
          forceCallingCode
          FormHelperTextProps={{ sx: { mb: -3 } }}
          helperText={fieldState.invalid ? 'Invalid Phone number' : ''}
          inputProps={{ maxLength: 12 }}
          onChange={field.onChange}
          sx={{ mt: 2 }}
          value={field.value}
          variant="standard"
        />
      )}
    />
  )
}

export default PhoneInput
