import { RootAPI } from '..'
import { useCurrentOrgId } from '../selectors/organization'

export function useCurrentOrganization() {
  const currentOrgId = useCurrentOrgId()
  const { isLoading, data: currentOrganization } =
    RootAPI.useGetOrganizationQuery(
      { id: currentOrgId },
      { skip: !currentOrgId }
    )

  // TODO: Type cast should be removed
  const currentOrg = currentOrganization as RootAPI.OrganizationModel
  return {
    currentOrg,
    isLoading,
  }
}
