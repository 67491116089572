import { useCurrentUser } from '@synop-react/api'

const userRoles = ['Admin', 'Fleet Operator', 'Driver'] as const
type UserRole = (typeof userRoles)[number]

type UserFeatureProps = {
  children: JSX.Element
  role: UserRole
}

const UserFeature = ({ children, role }: UserFeatureProps) => {
  const { synopUser } = useCurrentUser()
  const currentUserRoles = (synopUser?.roles ?? []) as UserRole[]

  return currentUserRoles.includes(role) ? children : null
}

type AdminFeatureProps = {
  children: JSX.Element
}

export const AdminFeature = ({ children }: AdminFeatureProps) => {
  return <UserFeature role="Admin">{children}</UserFeature>
}
