import { useTheme } from '@mui/material'

type SocColorBand = 'highCharge' | 'midCharge' | 'lowCharge' | 'unknown'

export const FULL_CHARGE = 100
export const HIGH_CHARGE_THRESHOLD = 80
export const HALF_CHARGE = 50
export const MID_CHARGE_THRESHOLD = 40
export const LOW_CHARGE_THRESHOLD = 20
export const ZERO_CHARGE = 0

export const useSocColor = (soc?: number | null) => {
  const { palette } = useTheme()

  const socColorMap: Record<SocColorBand, string> = {
    highCharge: palette.secondary.main,
    midCharge: palette.warning.main,
    lowCharge: palette.error.main,
    unknown: palette.text.secondary,
  }

  if (typeof soc !== 'number') return socColorMap.unknown

  if (soc >= HIGH_CHARGE_THRESHOLD) return socColorMap.highCharge
  else if (soc >= MID_CHARGE_THRESHOLD) return socColorMap.midCharge
  else return socColorMap.lowCharge
}
