import { Layer, LayerProps } from 'react-map-gl'
import { useMemo } from 'react'
import { useTheme } from '@mui/material'

export interface MapCircleLayerProps {
  /** 
  A unique id for this layer
*/
  layerId: string
  /** 
  The id for the Source layer that this layer should receive 
*/
  sourceId: string
  /** 
  An optional filter for selecting data from a Feature / FeatureCollection from the Source
*/
  filter?: unknown[]
  /** 
  The min map zoom level that this layer will be displayed at
*/
  minZoom?: number
  /** 
The max map zoom level that this layer will be displayed at
*/
  maxZoom?: number
}

export function MapCircleLayer({
  layerId,
  sourceId,
  minZoom,
  maxZoom,
  filter = ['all'],
}: MapCircleLayerProps) {
  const theme = useTheme()

  const circleLayer: LayerProps = useMemo(() => {
    const minzoom = minZoom !== undefined ? { minzoom: minZoom } : {}
    const maxzoom = maxZoom !== undefined ? { maxzoom: maxZoom } : {}

    return {
      id: layerId,
      type: 'circle',
      source: sourceId,
      ...{ ...minzoom },
      ...{ ...maxzoom },
      ...{ filter },
      paint: {
        'circle-opacity': 0,
        'circle-stroke-width': 2,
        'circle-stroke-color': theme.palette.secondary.main,
        'circle-radius': {
          base: 1.75,
          stops: [
            [12, 2],
            [22, 180],
          ],
        },
      },
    }
  }, [
    filter,
    layerId,
    minZoom,
    maxZoom,
    sourceId,
    theme.palette.secondary.main,
  ])

  return <Layer {...circleLayer} />
}

export default MapCircleLayer
