import { Copy, Info } from 'react-feather'
import { Grid, Stack, styled, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'

import { copyToClipboard, emDash } from '../../../utils'

type DetailsCaskItemProps = {
  value?: string | number | null
  label: string
  tooltip?: string
  info?: boolean
  copy?: boolean
}

const MISSING_DATA_VALUE = emDash

function DetailsCaskItem({
  value,
  label,
  tooltip = '',
  info = false,
  copy = false,
}: DetailsCaskItemProps) {
  const [tooltipText, setTooltipText] = useState(tooltip)

  const detailValue = value || MISSING_DATA_VALUE
  return (
    <Grid item sm={6} sx={{ minWidth: '250px', maxWidth: '548px' }} xs={12}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
        spacing={3}
      >
        <Stack direction="column" justifyContent="center">
          <Typography variant="subtitle2">{detailValue}</Typography>
          <Typography sx={{ textTransform: 'uppercase' }} variant="caption">
            {label}
          </Typography>
        </Stack>
        {info && (
          <Tooltip placement="top" title={tooltipText}>
            <StyledInfoIcon />
          </Tooltip>
        )}
        {copy && (
          <Tooltip placement="top" title={tooltipText}>
            <StyleCopyIcon
              onClick={() => {
                copyToClipboard(`${value}`)
                setTooltipText('Copied!')
                setTimeout(() => {
                  setTooltipText('Copy')
                }, 3000)
              }}
            />
          </Tooltip>
        )}
      </Stack>
    </Grid>
  )
}

const StyledInfoIcon = styled(Info)(() => ({
  strokeWidth: 1,
  width: '20px',
  height: '20px',
}))

const StyleCopyIcon = styled(Copy)(() => ({
  strokeWidth: 1,
  px: 1,
  width: '20px',
  height: '20px',
}))

export default DetailsCaskItem
