import { MapPolygonLayer } from '@synop-react/common'
import { useTheme } from '@mui/material'

type ServiceableAreaLayerProps = {
  sourceId: string
}

const ServiceableAreaLayer = ({ sourceId }: ServiceableAreaLayerProps) => {
  const { palette } = useTheme()
  return (
    <MapPolygonLayer
      fillColor={palette.info.main}
      layerId="serviceableAreas"
      sourceId={sourceId}
    />
  )
}

export default ServiceableAreaLayer
