import { ChargerFaultsTable, TabCaskView } from '@synop-react/common'
import { ChargerTimeline } from '@synop-react/depot'
import { ChargingTransactionsHistory } from '@synop-react/depot'

interface ChargingSessionTabeCaskViewProps {
  chargerId: string
  depotId: string
}

const ChargingSessionTabCaskView = ({
  chargerId,
  depotId,
}: ChargingSessionTabeCaskViewProps) => {
  return (
    <TabCaskView
      tabItems={[
        {
          label: 'SCHEDULE',
          content: <ChargerTimeline chargerId={chargerId} />,
        },
        {
          label: 'TRANSACTION HISTORY',
          content: (
            <ChargingTransactionsHistory
              chargerId={chargerId}
              depotId={depotId}
            />
          ),
        },
        {
          label: 'FAULTS & WARNINGS',
          content: (
            <ChargerFaultsTable chargerIds={[chargerId]} omitCharger omitSite />
          ),
        },
      ]}
    />
  )
}

export default ChargingSessionTabCaskView
