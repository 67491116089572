import {
  DefaultBodyType,
  PathParams,
  ResponseResolver,
  RestContext,
  RestRequest,
} from 'msw'

export const infiniteLoad: ResponseResolver = (req, res, ctx) =>
  res(ctx.delay('infinite'))

const DEFAULT_PAGINATED_OPTIONS = {
  first: true,
  last: true,
  page: 1,
  totalPages: 1,
}
export const paginatedResponse = (
  payload: readonly unknown[],
  options = DEFAULT_PAGINATED_OPTIONS
) => {
  const { first, last, page, totalPages } = Object.assign(
    {},
    DEFAULT_PAGINATED_OPTIONS,
    options
  )
  const totalItems = Array.isArray(payload)
    ? payload.length
    : Object.keys(payload).length
  return {
    first,
    last,
    pageSize: totalItems,
    page,
    totalPages,
    totalItems,
    items: payload,
  }
}

export const emptyArrayResponse: ResponseResolver<
  RestRequest<never, PathParams<string>>,
  RestContext,
  DefaultBodyType
> = (req, res, ctx) => res(ctx.json([]))
