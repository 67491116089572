import { FormControlLabel, Switch, SwitchProps } from '@mui/material'

type MfaStatusSwitchProps = {
  checked: boolean
  onChange: (isChecked: boolean) => void
} & SwitchProps
export const MfaStatusSwitch = ({
  checked,
  onChange,
  ...switchProps
}: MfaStatusSwitchProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={(e, isChecked) => onChange(isChecked)}
          {...switchProps}
        />
      }
      label="Enable Multi-factor Authentication for users."
    />
  )
}
