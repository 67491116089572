import { Auth } from 'aws-amplify'

import { PrepareHeadersFn } from '@synop-react/types'

export const prepareAuthHeader: PrepareHeadersFn = (headers) =>
  Auth.currentSession().then(
    (user) => {
      const accessToken = user.getAccessToken().getJwtToken()
      headers.set('authorization', `Bearer ${accessToken}`)
      return headers
    },
    () => {
      console.debug('Unable to resolve auth token for API Headers')
      return headers
    }
  )
