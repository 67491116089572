import { Grid } from '@mui/material'
import { useMeasure } from 'react-use'

import { AxisLabelFormatter } from '../OrdinalAxis/GroupedAxisLabel'
import {
  BOTTOM_AXIS_HEIGHT,
  EventGroupingKey,
} from '../useTimeline/useTimelineReducer/useTimelineReducer'
import { Cask, CaskProps } from '../../../Cask'
import { Dayjs } from 'dayjs'
import {
  DepotEvent,
  GenericEvent,
  ScheduledEvent,
  useUserPrefs,
} from '@synop-react/api'
import { EventOptions, TimelineProvider } from '../useTimeline/Provider'
import { useMemo } from 'react'
import DateScroll from '../DateScroll'
import SimpleTimeline from '../Simple/SimpleTimeline'

export type TimelineCaskProps = {
  title?: string
  hideCaptions?: boolean
  scheduledEvents?: ScheduledEvent[] | DepotEvent[] | GenericEvent[]
  groupingKey: EventGroupingKey
  eventIds: string[]
  minWidth?: number
  maxWidth?: number
  minHeight?: number
  maxHeight?: number
  eventOptions: EventOptions
  yAxisLabelFormatter?: AxisLabelFormatter
  displayedDate: Dayjs
  setDisplayedDate: (date: Dayjs) => void
} & Omit<CaskProps, 'children'>

export function TimelineCask({
  hideCaptions = false,
  scheduledEvents = [],
  groupingKey,
  eventIds,
  title = 'Schedule',
  minWidth = 800,
  eventOptions,
  yAxisLabelFormatter,
  displayedDate,
  setDisplayedDate,
  ...caskProps
}: TimelineCaskProps) {
  const { tzDayjs } = useUserPrefs()
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const boundedWidth = width <= minWidth ? minWidth : width

  const numRows = eventIds.length || 1

  const multiRowPadding = numRows > 1 ? 15 : 0
  const rowHeight = 68
  const totalHeight = rowHeight * numRows + BOTTOM_AXIS_HEIGHT + multiRowPadding

  const genericEvents: GenericEvent[] = scheduledEvents.map(
    (event) => event as GenericEvent
  )

  const filteredEvents: GenericEvent[] = useMemo(() => {
    return genericEvents.filter(
      ({ scheduledStart, scheduledEnd, eventType }) => {
        // TODO: Temporary.  Also need to filter this out because energy api running betas in prod
        if (eventType === 'PREDICTED') return false

        const MIN_DURATION_IN_MILLI = 60000
        const endTime = scheduledEnd || tzDayjs()
        const lengthOfSession = tzDayjs(endTime).diff(scheduledStart)
        return lengthOfSession >= MIN_DURATION_IN_MILLI
      }
    )
  }, [genericEvents, tzDayjs])

  const sortedEvents = [...filteredEvents].sort(
    (
      { scheduledStart: scheduledStartA },
      { scheduledStart: scheduledStartB }
    ) => tzDayjs(scheduledStartA).diff(scheduledStartB)
  )
  const firstEventStart = sortedEvents[0]?.scheduledStart ?? tzDayjs()
  const lastEventEnd = sortedEvents.at(-1)?.scheduledEnd ?? tzDayjs()

  const earliestDate = tzDayjs(firstEventStart).startOf('day')
  const latestKnownDate = tzDayjs(lastEventEnd).endOf('day')
  const latestDate = latestKnownDate.isBefore(tzDayjs())
    ? tzDayjs()
    : latestKnownDate

  return (
    <Cask
      Actions={
        <DateScroll
          displayedDate={displayedDate}
          earliestDate={earliestDate}
          latestDate={latestDate}
          setDisplayedDate={setDisplayedDate}
        />
      }
      title={title}
      {...caskProps}
    >
      <Grid container>
        <Grid ref={ref} item xs={12}>
          <TimelineProvider
            container={{ width: boundedWidth, height: totalHeight }}
            displayedDate={displayedDate}
            displayYAxis={!!yAxisLabelFormatter}
            eventIds={eventIds}
            eventOptions={eventOptions}
            groupingKey={groupingKey}
            hideCaptions={hideCaptions}
            scheduledEvents={sortedEvents}
          >
            <SimpleTimeline yAxisLabelFormatter={yAxisLabelFormatter} />
          </TimelineProvider>
        </Grid>
      </Grid>
    </Cask>
  )
}

export default TimelineCask
