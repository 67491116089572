import { Button, Typography } from '@mui/material'

import {
  Cask,
  CopyField,
  Crate,
  LoadingMessage,
  Server,
} from '@synop-react/common'
import { RootAPI, useCurrentOrgId } from '@synop-react/api'

const { useGetApiKeysByOrganizationIdQuery } = RootAPI.synopRootAPI

export function APIKeySettings() {
  const orgId = useCurrentOrgId()
  const { data: apiKeys, isLoading: isFleetUsersLoading } =
    useGetApiKeysByOrganizationIdQuery(
      {
        id: orgId,
      },
      {
        skip: !orgId,
      }
    )

  let apiView = isFleetUsersLoading ? <LoadingMessage /> : <ZeroStateAPICrate />

  if (apiKeys !== undefined && apiKeys.length > 0) {
    apiView = <CopyAPICrate apiKeyList={apiKeys} />
  }

  return (
    <Cask title={'API Keys'}>
      <Typography variant="body1">
        Add or adjust API Keys for your fleet.
      </Typography>
      {apiView}
    </Cask>
  )
}

interface CopyAPICrate {
  apiKeyList: RootAPI.OrganizationApiKeyModel[]
}

function CopyAPICrate({ apiKeyList }: CopyAPICrate) {
  return (
    <Crate
      bold
      description="Here is the API key for your organization"
      Icon={<Server />}
      width="50%"
    >
      {apiKeyList.map((key) => {
        return <CopyField key={key.apiKey} label="API Key" value={key.apiKey} />
      })}
    </Crate>
  )
}

function ZeroStateAPICrate() {
  return (
    <Crate
      description="Your organization does not have an API key. Request your API key here."
      Icon={<Server />}
    >
      <Button variant="outlined">Request API Key</Button>
    </Crate>
  )
}

export default APIKeySettings
