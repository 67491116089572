import { Auth } from 'aws-amplify'
import { Button, Grid, Stack, Typography } from '@mui/material'
import {
  FormField,
  LoadingMessage,
  useSnackbarControls,
} from '@synop-react/common'
import { FormProvider, useForm } from 'react-hook-form'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { useCallback, useEffect } from 'react'
import AuthPage from './AuthPage'
import QRCode from 'react-qr-code'

const {
  useInitiateMfaSetupQuery,
  useVerifySoftwareTokenMutation,
  useGetOrganizationQuery,
} = RootAPI

type MfaAuthFormData = {
  userCode: string
}

export const MfaSetup = () => {
  const { synopUser } = useCurrentUser()
  const { data: userOrg } = useGetOrganizationQuery(
    { id: synopUser?.organizationId ?? '' },
    { skip: !synopUser?.organizationId }
  )
  const { data: mfaSetup, isError } = useInitiateMfaSetupQuery()
  const [setupMfa, setupMfaResposne] = useVerifySoftwareTokenMutation()

  const formMethods = useForm<MfaAuthFormData>()

  const { openSnackbar } = useSnackbarControls()

  const handleMfaCode = useCallback(
    ({ userCode }: MfaAuthFormData) => {
      setupMfa({ userCode })
    },
    [setupMfa]
  )

  useEffect(() => {
    if (setupMfaResposne.status === QueryStatus.fulfilled)
      openSnackbar(
        'You will now need to use your authentication application each time you sign in.',
        {
          title: 'MFA Setup Successful',
        }
      )
  }, [openSnackbar, setupMfaResposne.status])

  // Log out a user if there is an error fetching the MFA Setup Key
  // This happens when users have their tokens revoked from MFA being required
  useEffect(() => {
    if (isError) Auth.signOut()
  }, [isError])

  const { handleSubmit } = formMethods
  const appLabel = userOrg?.organizationNm ?? 'Synop'
  const { secretCode } = mfaSetup || {}

  if (!synopUser || !secretCode) return <LoadingMessage />

  const authUrl = `otpauth://totp/${appLabel}:${synopUser?.email}?secret=${secretCode}&issuer=${appLabel}`

  return (
    <AuthPage
      actions={
        <>
          <Grid item>
            <Button onClick={handleSubmit(handleMfaCode)} variant="contained">
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                Auth.signOut()
              }}
            >
              Cancel
            </Button>
          </Grid>
        </>
      }
      fields={
        <>
          <Grid item>
            <Typography variant="body1">
              Scan this QR code with your authentication application then input
              the code provided by your authentication app to finish setup.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems="center" spacing={3}>
              <QRCode data-dd-privacy="hidden" value={authUrl} />
              <form onSubmit={handleSubmit(handleMfaCode)}>
                <FormProvider {...formMethods}>
                  <FormField.WrappedTextFormField
                    id="userCode"
                    inputProps={{ autocomplete: 'off', maxLength: 6 }}
                    placeholder="Authentication code"
                    type="tel" // Gives users a num pad to input on mobile
                  />
                </FormProvider>
              </form>
              {setupMfaResposne.status === QueryStatus.rejected && (
                <Typography color="error" variant="subtitle1">
                  Authentication setup failed. Please try again.
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              You will need to use your authentication app each time you sign
              in.
            </Typography>
          </Grid>
        </>
      }
      subtitle="Your organization now requires Multi-factor Authentication."
      sx={{ maxWidth: '760px', width: '90vw' }}
      title="Multi-factor Authentication"
    />
  )
}
