import {
  getStatusColorFromSourceAttribute,
  getStatusLabelFromSourceAttribute,
} from '../../utils/formatters/formatVehicleStatusDetail'
import { StatusCask } from '@synop-react/common'
import { VehicleStatusSourceAttribute } from '@synop-react/api'

export interface VehicleStatusDetailProps {
  status?: VehicleStatusSourceAttribute
}

export function VehicleStatusDetail({ status }: VehicleStatusDetailProps) {
  const color = getStatusColorFromSourceAttribute(status)
  const label = getStatusLabelFromSourceAttribute(status)

  return (
    <StatusCask.Item
      label="Status"
      progress={{ value: 100, color }}
      value={label}
    />
  )
}

export default VehicleStatusDetail
