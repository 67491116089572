import { Circle, Line } from '@visx/shape'
import { selectIsCurrentDisplayedDay } from '../useTimeline/useTimelineReducer/selectors'
import { selectWindow } from '../useTimeline/useTimelineReducer/selectors/window'
import { selectWindowXScale } from '../useTimeline/useTimelineReducer/selectors/scale'
import { styled, useTheme } from '@mui/material'
import { Text } from '@visx/text'
import { useFormat } from '../../../utils/hooks'
import { useMinuteTimer } from '../../../utils/date/useTimer'
import { useTimelineSelector } from '../useTimeline/useTimeline'

/* eslint-disable-next-line */
export interface TimelineTooltipLineProps {}

export function TimeIndicatorLine() {
  const { palette } = useTheme()
  const currentTime = useMinuteTimer()
  const { height: innerHeight } = useTimelineSelector(selectWindow)
  const windowScale = useTimelineSelector(selectWindowXScale)
  const isCurrentDay = useTimelineSelector(selectIsCurrentDisplayedDay)
  const { formatDateTime } = useFormat()

  const indicatorLineColor = palette.text['12p']

  const circleRadius = 5
  const circleOffset = 0.5 * circleRadius

  const timeLocation = windowScale(currentTime.toDate())
  //TODO Have this switch to the other side when it gets near the end of the day @wslater
  const timeIndicatorXOffset = 11
  const topPadding = 8
  const bottomOfLine = innerHeight - circleOffset

  return isCurrentDay ? (
    <g>
      <Circle
        cx={timeLocation}
        cy={topPadding - circleOffset}
        fill={indicatorLineColor}
        r={circleRadius}
      />
      <IndicatorTime
        dx={timeIndicatorXOffset}
        dy={circleOffset}
        x={timeLocation}
        y={topPadding}
      >
        {formatDateTime(currentTime).time}
      </IndicatorTime>
      <Line
        from={{ x: timeLocation, y: topPadding + 2 }}
        pointerEvents="none"
        stroke={indicatorLineColor}
        strokeWidth={2}
        to={{ x: timeLocation, y: bottomOfLine - 2 }}
      />

      <Circle
        cx={timeLocation}
        cy={bottomOfLine + circleOffset}
        fill={indicatorLineColor}
        r={circleRadius}
      />
      <IndicatorTime
        dx={timeIndicatorXOffset}
        dy={circleOffset}
        x={timeLocation}
        y={innerHeight}
      >
        {formatDateTime(currentTime).time}
      </IndicatorTime>
    </g>
  ) : null
}

export default TimeIndicatorLine

const IndicatorTime = styled(Text, {
  shouldForwardProp: (prop: string) => !['color', 'isLink'].includes(prop),
})<{ color?: string; isLink?: boolean }>(() => {
  const theme = useTheme()
  const overlineProps = theme.typography.overline
  return {
    ...overlineProps,
    fill: theme.palette.text.disabled,
  }
})
