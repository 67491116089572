import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { defaultEntityName, FormField } from '../../index'
import { RootAPI } from '@synop-react/api'

const { useGetChargerConnectorsQuery } = RootAPI.synopRootAPI

export type ConnectorAutocompleteSelectProps<FormData extends FieldValues> = {
  chargerId: string
} & AutocompleteFormControls<FormData, RootAPI.ConnectorModel> &
  AutocompleteTextFieldProps

export const ConnectorAutocompleteSelect = <FormData extends FieldValues>({
  chargerId,
  ...formControls
}: ConnectorAutocompleteSelectProps<FormData>) => {
  const connectorOptions = FormField.useOptions<
    RootAPI.ConnectorModel,
    RootAPI.GetChargerConnectorsApiArg,
    RootAPI.GetChargerConnectorsApiResponse
  >({
    queryArgs: { chargerId },
    useQuery: useGetChargerConnectorsQuery,
  })

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.ConnectorModel>
      label="Connector"
      {...connectorOptions}
      {...formControls}
      filterOptions={(options) =>
        options.filter((option) => option?.connectorId !== 0)
      }
      getOptionLabel={({ connectorId }: RootAPI.ConnectorModel = {}) =>
        connectorId
          ? `Connector ${connectorId}`
          : defaultEntityName('Connector', connectorId)
      }
      keyExtractor={(option: RootAPI.ConnectorModel = {}) =>
        option?.connectorId || ''
      }
    />
  )
}

const connectorSelectSchema = yup
  .object({ connectorId: yup.number().required() })
  .nullable()
  .required('Connector is required')

export {
  ConnectorAutocompleteSelect as Select,
  connectorSelectSchema as Schema,
}
