import { mockData } from '../'
import { paginatedResponse } from './utils'
import { rest } from 'msw'

export const chargerHandlers = [
  rest.get('/api/chargers', (req, res, ctx) => {
    return res(ctx.json(paginatedResponse(mockData.chargers.chargers)))
  }),
  rest.get('/api/chargers/:chargerId/connectors', (req, res, ctx) => {
    return res(ctx.json(paginatedResponse(mockData.chargers.connectors)))
  }),
]
