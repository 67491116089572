import { Dayjs } from 'dayjs'
import { FormProvider } from 'react-hook-form'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { useCallback, useMemo } from 'react'

import { RootAPI, useCurrentUser, useUserPrefs } from '@synop-react/api'
import { Tuple } from '@synop-react/types'

import {
  getFieldColumns,
  omitFalsey,
  ReportField,
  StatusChip,
  StatusColorProvider,
  Table,
  TableCask,
  useFormat,
  useRouting,
  useSelectedOrgIdOrCurrent,
  useSiteSelector,
  useTableCask,
} from '../..'

const { useCmsChargerFaultsGetFaultsQuery, useGetDepotsQuery } = RootAPI

type ChargerFaultsOptions = {
  chargerIds?: string[]
  omitCharger?: boolean
  omitSite?: boolean
  siteIds?: string[]
  updateInterval?: number
}

export function ChargerFaultsTable({
  omitCharger,
  omitSite,
  ...rest
}: ChargerFaultsOptions) {
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const { formMethods, from, to } = useTableCask()

  const { data: sitesData } = useSites()
  const useFaults = useFaultDataHookFactory(rest, [from, to])
  const { csvColumns, tableColumns } = useColumns(sitesData?.items, {
    omitCharger,
    omitSite,
  })

  const csvFilename = useMemo(() => {
    const dateFormat = preferredDateFormat.replaceAll('/', '-')
    const start = tzDayjs(from).format(dateFormat)
    const end = tzDayjs(to).format(dateFormat)
    return `CHARGER_FAULTS_${start}_${end}`
  }, [from, preferredDateFormat, to, tzDayjs])

  return (
    <StatusColorProvider>
      <FormProvider {...formMethods}>
        <TableCask.ServerSide
          columns={tableColumns}
          csvColumns={csvColumns}
          data-cy="charger-faults-table"
          downloadable
          downloadTitle={csvFilename}
          downloadTooltip="Note: Only one page of data may be downloaded at a time."
          showDateRange
          TableProps={{
            getRowId: (fault) => fault.connectorStatusId.toString(),
            initialSorting: ['timestamp', 'desc'],
            noRowsMessage: 'No connector faults',
            useData: useFaults,
          }}
          title="Charger Faults & Warnings"
        />
      </FormProvider>
    </StatusColorProvider>
  )
}

function useFaultDataHookFactory(
  options: Omit<ChargerFaultsOptions, 'omitSite'>,
  timeRange: Tuple<Dayjs | undefined>
): Table.UseDataHook<
  RootAPI.CmsChargerFaultsGetFaultsApiArg,
  RootAPI.CmsChargerFaultsGetFaultsApiResponse
> {
  const { synopUser, isAdmin } = useCurrentUser()

  return useCallback(
    function useDataHook(args) {
      const { selected: selectedSite } = useSiteSelector()
      const orgId = useSelectedOrgIdOrCurrent()
      const { updateInterval = 60 } = options
      const [from, to] = timeRange

      let siteIds = selectedSite?.id ? [selectedSite.id] : options.siteIds
      if (
        !isAdmin &&
        synopUser &&
        synopUser.sites &&
        synopUser.sites.length > 0
      ) {
        siteIds = synopUser.sites
      }

      return useCmsChargerFaultsGetFaultsQuery(
        {
          ...args,
          chargerIds: options.chargerIds,
          occurredAfter: from?.toISOString(),
          occurredBefore: to?.toISOString(),
          organizationId: orgId,
          siteIds: siteIds,
        },
        { skip: !orgId || !from || !to, pollingInterval: updateInterval * 1000 }
      )
    },
    [options, timeRange, synopUser, isAdmin]
  )
}

function useSites() {
  const orgId = useSelectedOrgIdOrCurrent()
  return useGetDepotsQuery({ fleetId: orgId, count: 1000 }, { skip: !orgId })
}

function useColumns(
  sites?: RootAPI.DepotModel[],
  ommissions?: Pick<ChargerFaultsOptions, 'omitCharger' | 'omitSite'>
) {
  const { formatDateTime } = useFormat()

  const chargerColumn: ReportField<RootAPI.ConnectorFaultModel>[] =
    ommissions?.omitCharger
      ? []
      : [
          {
            csv: [['chargerName'], ['chargerId']],
            column: {
              field: 'chargerName',
              filterable: false,
              flex: 1,
              headerName: 'Charger Name',
              minWidth: 220,
              renderCell: ({ row }) => <NameColumn row={row} />,
              sortable: false,
              type: 'string',
            },
          },
        ]

  const siteColumn: ReportField<RootAPI.ConnectorFaultModel>[] =
    ommissions?.omitSite
      ? []
      : [
          {
            csv: [
              'siteId',
              'siteName',
              (siteId: string) =>
                sites?.find((s) => s.depotId === siteId)?.depotNm,
            ],
            column: {
              field: 'siteName',
              filterable: false,
              flex: 1,
              headerName: 'Site',
              minWidth: 150,
              renderCell: (props: GridRenderCellParams) => (
                <SiteColumn {...props} sites={sites} />
              ),
              sortable: false,
              type: 'string',
              valueGetter: ({ row }) =>
                sites?.find((site) => site.depotId === row.siteId)?.depotNm,
            },
          },
        ]

  return getFieldColumns(
    {
      csv: [
        ['timestamp', 'date', (value: string) => formatDateTime(value).date],
        [
          'timestamp',
          'time',
          (value: string) =>
            formatDateTime(value, { displaySeconds: true }).time,
        ],
      ],
      column: {
        field: 'timestamp',
        filterable: false,
        headerName: 'Date/Time',
        flex: 1,
        minWidth: 160,
        sortingOrder: ['desc', 'asc'],
        type: 'dateTime',
        valueGetter: Table.dateValueGetter,
        valueFormatter: ({ value }) =>
          formatDateTime(value, { dropLeadingZero: true, invalidDateValue: '' })
            .dateDotTime,
      },
    },
    ...chargerColumn,
    ...siteColumn,
    {
      csv: ['connectorId'],
      column: {
        field: 'connectorId',
        filterable: false,
        flex: 1,
        headerName: 'Fault Location',
        minWidth: 150,
        sortable: false,
        valueFormatter: ({ value }) =>
          value === 0 ? 'Charger' : `Connector ${value}`,
        type: 'string',
      },
    },
    {
      csv: [
        ['connectorId', 'currentChargerStatus', 'currentConnectorStatus'],
        'currentStatus',
        (
          connectorId: number,
          currentChargerStatus: string,
          currentConnectorStatus: string
        ) =>
          connectorId === 0 ? currentChargerStatus : currentConnectorStatus,
      ],
      column: {
        field: 'status',
        filterable: false,
        flex: 1,
        headerName: 'Status',
        minWidth: 150,
        renderCell: ({ row }) => <StatusColumn row={row} />,
        sortable: false,
        type: 'singleSelect',
        valueGetter: ({ row }) =>
          row.connectorId === 0
            ? row.currentChargerStatus
            : row.currentConnectorStatus,
      },
    },
    {
      csv: [['vendor'], ['vendorErrorCode']],
      column: {
        field: 'vendor',
        filterable: false,
        flex: 1,
        headerName: 'Vendor',
        minWidth: 150,
        type: 'string',
      },
    },
    {
      csv: [['errorCode'], ['errorInfo']],
      column: {
        field: 'errorCode',
        filterable: false,
        flex: 1,
        headerName: 'Error Code',
        minWidth: 280,
        type: 'string',
        valueGetter: ({ row: { errorCode, vendorErrorCode, errorInfo } }) =>
          omitFalsey([errorCode, vendorErrorCode, errorInfo]).join(' | '),
      },
    }
  )
}

type ColumnProps = { row: RootAPI.ConnectorFaultModel }
type SiteColumnProps = ColumnProps & { sites?: RootAPI.DepotModel[] }

function NameColumn({ row }: ColumnProps) {
  const { chargerId, chargerName } = row
  const { routes } = useRouting()
  return (
    <Table.Link href={routes.chargers.details(chargerId)} weight={700}>
      {chargerName}
    </Table.Link>
  )
}

function SiteColumn({ row, sites }: SiteColumnProps) {
  const { siteId } = row
  const { routes } = useRouting()

  // Find the site's name from the list of sites
  const siteName = sites?.find((site) => site.depotId === siteId)?.depotNm
  return (
    <Table.Link href={routes.siteDetails(siteId)} weight={600}>
      {siteName}
    </Table.Link>
  )
}

function StatusColumn({ row }: ColumnProps) {
  const { connectorId, currentChargerStatus, currentConnectorStatus } = row
  return connectorId === 0 ? (
    <StatusChip.Charger status={currentChargerStatus} />
  ) : (
    <StatusChip.Connector status={currentConnectorStatus} />
  )
}
