export type UnitFormat = {
  long: string
  short: string
}
export type UnitsFormat<T extends string> = Record<T, UnitFormat>

type FormatUnits = <T extends string>(
  format: UnitsFormat<T>
) => (unit: T) => string

export const formatUnitDesc: FormatUnits = (unitsFormat) => (unit) =>
  `${formatUnits(unitsFormat)(unit)} (${formatUnitsAbbr(unitsFormat)(unit)})`

export const formatUnits: FormatUnits = (unitsFormat) => (unit) =>
  unitsFormat[unit].long

export const formatUnitsAbbr: FormatUnits = (unitsFormat) => (unit) =>
  unitsFormat[unit].short
