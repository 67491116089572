import { BillingCycleSelector } from './BillingCycleSelector'
import { Chip, ChipProps, Link, Typography } from '@mui/material'
import { Dayjs } from 'dayjs'
import {
  emDash,
  reduceWattHours,
  Table,
  TableCask,
  useFormat,
  useTableCask,
} from '@synop-react/common'
import { FormProvider } from 'react-hook-form'
import { getCSVColumns, titleCase } from './HomeChargingUsers'
import {
  RootAPI,
  useCurrentOrgId,
  useOrgChargers,
  useOrgCustomers,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { Tuple } from '@synop-react/types'
import { useCallback, useMemo, useState } from 'react'

const { useGetReimbursementsQuery } = RootAPI

export interface StatusObj {
  [key: string]: {
    title: string
    color: ChipProps['color']
  }
}

const statusObj: StatusObj = {
  FINALIZED: { title: 'finalized', color: 'success' },
  INCOMPLETE: { title: 'incomplete', color: 'warning' },
  REJECTED: { title: 'rejected', color: 'error' },
  PENDING: { title: 'pending', color: 'secondary' },
  DRAFT: { title: 'draft', color: 'info' },
}

export const renderStatusCell =
  (statusObj: StatusObj) =>
  ({ value = '' }) => {
    let status = statusObj[value]

    if (!status) {
      status = { title: 'unknown', color: 'secondary' }
    }

    return (
      <Chip
        color={status.color}
        label={status.title}
        size="small"
        sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
        variant="outlined"
      />
    )
  }

export function HomeChargingSettlements() {
  const { formMethods, from, to } = useTableCask()
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const [range, setRange] = useState<Tuple<Dayjs | null>>([null, null])
  const { customers: organizations } = useOrgCustomers({
    includeParentOrg: true,
  })
  const { orgChargers } = useOrgChargers()
  const { orgVehicles } = useOrgVehicles()
  const orgId = useCurrentOrgId()

  const getFormattedUsage = useCallback((usage?: number) => {
    return usage === undefined
      ? { num: emDash, unit: '' }
      : reduceWattHours(usage, 'kwh')
  }, [])

  const { formatDateTime } = useFormat()

  const columns = useMemo<Table.ColumnSpec<RootAPI.ReimbursementsModelObject>>(
    () => [
      {
        flex: 0.15,
        minWidth: 80,
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatusCell(statusObj),
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Timestamp',
        field: 'asOf',
        valueGetter: ({ value }) => formatDateTime(value).timeOnDate,
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Period Start',
        field: 'periodStart',
        valueGetter: ({ value }) => formatDateTime(value).date,
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Period End',
        field: 'periodEnd',
        valueGetter: ({ row: { periodEnd } }) => formatDateTime(periodEnd).date,
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'entityType',
        headerName: 'Asset Type',
        valueGetter: ({ value }) => titleCase(value),
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'entityId',
        headerName: 'Asset ID',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'entityName',
        headerName: 'Asset Name',
        valueGetter: ({ row: { entityId, entityType } }) =>
          (entityType === 'CHARGER'
            ? orgChargers[entityId ?? '']?.chargerName
            : orgVehicles[entityId ?? '']?.vehicleNm) ?? '',
        renderCell: ({ row: { entityId, entityType }, value }) => {
          const link =
            (entityType === 'CHARGER' ? '/chargers/' : '/vehicles/') + entityId

          return (
            <Typography variant="body2">
              {entityId ? (
                <Link href={link} underline="none">
                  {value}
                </Link>
              ) : (
                emDash
              )}
            </Typography>
          )
        },
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'organizationId',
        headerName: 'Organization',
        valueGetter: ({ value }) =>
          organizations[value]?.organizationNm ?? emDash,
      },
      {
        flex: 0.175,
        minWidth: 150,
        field: 'full_name',
        headerName: 'Payee Name',
        valueGetter: ({ row: { payee } }) =>
          `${payee?.firstNm ?? ''} ${payee?.lastNm ?? ''}`,
      },
      {
        flex: 0.15,
        field: 'email',
        minWidth: 80,
        headerName: 'Payee Email',
        valueGetter: ({ row: { payee } }) => payee?.email ?? '',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'externalId',
        headerName: 'External ID',
        valueGetter: ({ row: { payee } }) => payee?.externalId ?? '',
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'address',
        headerName: 'Address',
        valueGetter: ({ row: { payee } }) => payee?.address ?? '',
      },
      {
        flex: 0.15,
        minWidth: 70,
        field: 'amount',
        headerName: 'Amount to Reimburse',
        valueFormatter: ({ value }) => `$${value}`,
      },
      {
        flex: 0.15,
        field: 'usage',
        minWidth: 80,
        headerName: 'Total Energy',
        renderCell: (params) => {
          const usage = getFormattedUsage(params.row.usage ?? 0)
          return (
            <>
              {usage.num} {usage.unit}
            </>
          )
        },
      },
    ],
    [formatDateTime, getFormattedUsage, orgChargers, orgVehicles, organizations]
  )

  const { data } = useGetReimbursementsQuery({
    from: (range[0] ?? tzDayjs()).format('YYYY-MM-DD'),
    to: (range[1] ?? tzDayjs()).format('YYYY-MM-DD'),
    organizationId: orgId,
  })

  const csvColumns = useMemo(
    () => getCSVColumns<RootAPI.ReimbursementsModelObject>(columns),
    [columns]
  )

  const handleRangeChange = useCallback(
    (range: [Dayjs | null, Dayjs | null]) => {
      setRange(range)
    },
    []
  )

  const downloadTitle = useMemo(
    () =>
      `Home_Charging_Reimbursements_${(range[0] ?? tzDayjs(from)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}_${(range[1] ?? tzDayjs(to)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}`,
    [from, preferredDateFormat, range, to, tzDayjs]
  )

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        columns={columns}
        csvColumns={csvColumns}
        downloadable
        downloadTitle={downloadTitle}
        getRowId={(row) => row.reimbursementId + '_' + row.periodStart}
        initialState={{
          columns: {
            columnVisibilityModel: {
              asOf: false,
              entityId: false,
              email: false,
              externalId: false,
              address: false,
            },
          },
        }}
        leftOtherActions={
          <BillingCycleSelector handleRangeChange={handleRangeChange} />
        }
        searchable={true}
        tableData={data ? data : []}
        title={''}
      />
    </FormProvider>
  )
}

export default HomeChargingSettlements
