import {
  Autocomplete,
  CircularProgress,
  FilterOptionsState,
  TextField,
  TextFieldProps,
} from '@mui/material'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form'
import { HTMLAttributes, Key, ReactNode } from 'react'
import { InfoTooltip } from '../../Tooltip'
import { UseOptionsResponse } from './useOptions'

export type AutocompleteFilterOptions<OptionType> = (
  options: OptionType[],
  state: FilterOptionsState<OptionType>
) => OptionType[]

export const isAutocompleteOption = <OptionType,>(
  option: MaybeOption<OptionType>
): option is OptionType => typeof option !== 'string'
export type MaybeOption<OptionType> = OptionType | string

export type AutocompleteFormControls<
  FormData extends FieldValues,
  OptionType
> = {
  id: Path<FormData>
  control: Control<FormData, unknown>
  error?: FieldError | Record<keyof OptionType, FieldError>
  touchedField?: boolean
}

export type AutocompleteTextFieldProps = Omit<TextFieldProps, 'error' | 'label'>

export type AutocompleteSelectProps<
  FormData extends FieldValues,
  OptionType
> = Omit<UseOptionsResponse<OptionType>, 'isLoading'> &
  AutocompleteFormControls<FormData, OptionType> & {
    /* 
      Allow 'all' as an option for the dropdown
    */
    canSelectAll?: boolean
    label: string
    disableClearable?: boolean
    noOptionsText?: string
    getOptionLabel: (option: OptionType) => string
    keyExtractor: (option: OptionType) => Key
    filterOptions?: AutocompleteFilterOptions<OptionType>
    /* 
      Control rendering of the Option label in the dropdown <li>; defaults to using getOptionLabel
    */
    renderOptionLabel?: (option: OptionType) => ReactNode

    onInputChange?: (input: string) => void
    isLoading?: boolean
    tooltip?: string
  } & AutocompleteTextFieldProps

// TODO Type OptionType as MaybeOption<OptionType> so Autocompletes handle input string & selected option correctly @wslater
const AutocompleteSelect = function <FormData extends FieldValues, OptionType>({
  canSelectAll,
  control,
  error,
  disableClearable = false,
  getOptionLabel,
  keyExtractor,
  id,
  isLoading = false,
  isOpen = false,
  label,
  noOptionsText = 'None available',
  onInputChange,
  options,
  setIsOpen,
  touchedField,
  filterOptions,
  disabled = false,
  variant = 'standard',
  renderOptionLabel,
  tooltip,
  ...restProps
}: AutocompleteSelectProps<FormData, OptionType>) {
  const maybeFilterOptions = filterOptions ? { filterOptions } : {}

  const { onChange, ...restTextFieldProps } = restProps

  const isFieldError = (
    error: FieldError | Record<keyof OptionType, FieldError>
  ): error is FieldError =>
    Object.keys(error).some((field) => field === 'message')

  const getErrorMessage = (
    error?: FieldError | Record<keyof OptionType, FieldError>
  ) => {
    if (!error) return ''
    else if (isFieldError(error)) return error.message
    const errors: FieldError[] = Object.values(error)
    return errors[0]?.message
  }

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: OptionType
  ) => {
    return renderOptionLabel ? (
      <li {...props} key={keyExtractor(option)}>
        {renderOptionLabel(option)}
      </li>
    ) : (
      <li {...props} key={keyExtractor(option)}>
        {getOptionLabel(option)}
      </li>
    )
  }

  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete<OptionType, undefined, boolean, undefined>
          loading={isLoading}
          onChange={(e, data) => {
            onChange(data)
          }}
          {...props}
          clearOnBlur
          disableClearable={disableClearable}
          onClose={() => {
            setIsOpen(false)
          }}
          onOpen={() => {
            setIsOpen(true)
          }}
          open={isOpen}
          {...maybeFilterOptions}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            keyExtractor(value) === keyExtractor(option)
          }
          noOptionsText={noOptionsText}
          onInputChange={(e, data) => onInputChange?.(data)}
          options={options}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                {...restTextFieldProps}
                key={params.id}
                error={Boolean(error)}
                helperText={getErrorMessage(error)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      <InfoTooltip description={tooltip} />
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                label={label}
                variant={variant}
              />
            )
          }}
          renderOption={renderOption}
        />
      )}
    />
  )
}

export default AutocompleteSelect
