import React, { FC, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { MemoryRouter } from 'react-router-dom'
import Rollbar from 'rollbar'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { Provider as RollbarProvider } from '@rollbar/react'
import { render, RenderOptions } from '@testing-library/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Authenticator } from '@aws-amplify/ui-react'

import { currentOrganizationReducer, RootAPI } from '@synop-react/api'
import { MockThemeProvider } from '@synop-react/theme'
import { displayReducer } from '../store'

export const mockAPIReducer = {
  [RootAPI.synopRootAPI.reducerPath]: RootAPI.synopRootAPI.reducer,
}

const MOCK_ORG_ID = '8ac5611d7c2d6a07017c2d6a2e1c0000'

const initialMockState = {
  currentOrganization: {
    currentOrgId: MOCK_ORG_ID,
    cloakedOrgIds: [MOCK_ORG_ID],
  },
}

const mockStore = configureStore({
  preloadedState: initialMockState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(RootAPI.synopRootAPI.middleware),
  reducer: combineReducers({
    currentOrganization: currentOrganizationReducer,
    display: displayReducer,
    ...mockAPIReducer,
  }),
})

const mockRollbar: Rollbar.Configuration = {
  accessToken: 'mockToken',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: false,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
    environment: 'development',
  },
}

const AllTheProviders: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <RollbarProvider config={mockRollbar}>
      <Provider store={mockStore}>
        <MemoryRouter>
          <MockThemeProvider>
            <Authenticator hideSignUp={true}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {children}
              </LocalizationProvider>
            </Authenticator>
          </MockThemeProvider>
        </MemoryRouter>
      </Provider>
    </RollbarProvider>
  )
}

export const renderWithProviders = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>
) => render(ui, { wrapper: AllTheProviders, ...options })

export * from '@testing-library/react'
