import { mockData } from '../'
import { paginatedResponse } from './utils'
import { rest } from 'msw'

export const depotHandlers = [
  rest.get('/api/depots', (req, res, ctx) => {
    return res(ctx.json(paginatedResponse(mockData.depots.depots)))
  }),
  rest.get('/api/depots/:depotId', (req, res, ctx) => {
    return res(ctx.json(mockData.depots.depots[0]))
  }),
]
