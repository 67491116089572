import styled from '@emotion/styled'

const StyledBilling = styled.div`
  color: pink;
`

export function Billing() {
  return (
    <StyledBilling>
      <h1>Welcome to Billing!</h1>
    </StyledBilling>
  )
}
