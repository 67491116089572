import {
  formatDistanceSourceAttribute,
  formatDurationSourceAttribute,
  getIntFromSourceAttribute,
  StatusCask,
} from '@synop-react/common'
import { RootAPI, VehicleModel } from '@synop-react/api'

import VehicleEfficiencyDetail from './EfficiencyDetail'
import VehicleRangeDetail from './RangeDetail'
import VehicleSoCDetail from './SoCDetail'
import VehicleStatusDetail from './StatusDetail'

export type VehicleStatusCaskProps = {
  vehicleId: string
}

export function VehicleStatusCask({ vehicleId }: VehicleStatusCaskProps) {
  const { data: vehicle } = RootAPI.synopRootAPI.useGetVehicleQuery({
    id: vehicleId,
  })

  const {
    vehicleStatus,
    estimatedRange,
    stateOfCharge,
    telematicsTimestamp = '',
    distanceUntilHome,
    remainingRange,
    timeUntilHome,
    odometer,
  } = (vehicle || {}) as VehicleModel

  const soc = getIntFromSourceAttribute(stateOfCharge)

  return (
    <StatusCask lastUpdated={telematicsTimestamp} title="Status">
      <VehicleStatusDetail status={vehicleStatus} />
      <VehicleSoCDetail soc={soc} />
      <VehicleRangeDetail
        distanceUntilHome={distanceUntilHome}
        estimatedRange={estimatedRange}
        remainingRange={remainingRange}
      />
      <StatusCask.Item
        hideProgress
        label="Odometer"
        value={formatDistanceSourceAttribute(odometer)}
      />
      <VehicleEfficiencyDetail vehicleId={vehicleId} />
      <StatusCask.Item
        hideProgress
        label="Time to Home"
        value={formatDurationSourceAttribute(timeUntilHome)}
      />
      <StatusCask.Item
        hideProgress
        label="Distance to Home"
        value={formatDistanceSourceAttribute(distanceUntilHome)}
      />
    </StatusCask>
  )
}

export default VehicleStatusCask
