const chartPalette = {
  chart1: {
    main: '#5EACCC',
    dark: '#41788E',
    light: '#7EBCD6',
    contrastText: '#000000',
    '900': '#70B4D1',
    '800': '#81BDD7',
    '700': '#91C5DC',
    '600': '#A1CDE1',
    '500': '#B1D6E6',
    '400': '#C1DEEB',
    '300': '#D0E6F0',
    '200': '#E0EEF5',
    '100': '#EFF7FA',
    '4p': 'rgba(94, 172, 204, 0.04)',
    '8p': 'rgba(94, 172, 204, 0.08)',
    '12p': 'rgba(94, 172, 204, 0.12)',
    '30p': 'rgba(94, 172, 204, 0.3)',
    '50p': 'rgba(94, 172, 204, 0.5)',
  },
  chart2: {
    main: '#6689E7',
    dark: '#475FA1',
    light: '#84A0EB',
    contrastText: '#FFFFFF',
    '900': '#7495EA',
    '800': '#83A1EE',
    '700': '#92ADF1',
    '600': '#A1B9F3',
    '500': '#B0C5F6',
    '400': '#BFD0F8',
    '300': '#CFDCFA',
    '200': '#DFE8FC',
    '100': '#EFF3FE',
    '4p': 'rgba(102, 137, 231, 0.04)',
    '8p': 'rgba(102, 137, 231, 0.08)',
    '12p': 'rgba(102, 137, 231, 0.12)',
    '30p': 'rgba(102, 137, 231, 0.3)',
    '50p': 'rgba(102, 137, 231, 0.5)',
  },
  chart3: {
    main: '#00787F',
    dark: '#005458',
    light: '#339398',
    contrastText: '#FFFFFF',
    '900': '#31858B',
    '800': '#4C9297',
    '700': '#649FA4',
    '600': '#7BADB1',
    '500': '#91BABD',
    '400': '#A7C8CA',
    '300': '#BDD5D7',
    '200': '#D3E3E4',
    '100': '#E9F1F2',
    '4p': 'rgba(0, 120, 127, 0.04)',
    '8p': 'rgba(0, 120, 127, 0.08)',
    '12p': 'rgba(0, 120, 127, 0.12)',
    '30p': 'rgba(0, 120, 127, 0.3)',
    '50p': 'rgba(0, 120, 127, 0.5)',
  },
  chart4: {
    main: '#4A529E',
    dark: '#33396E',
    light: '#6E74B1',
    contrastText: '#FFFFFF',
    '900': '#5A63A8',
    '800': '#6A74B3',
    '700': '#7B85BD',
    '600': '#8C96C6',
    '500': '#9FA7D0',
    '400': '#B1B8DA',
    '300': '#C4C9E3',
    '200': '#D7DBED',
    '100': '#EBEDF6',
    '4p': 'rgba(74, 82, 158, 0.04)',
    '8p': 'rgba(74, 82, 158, 0.08)',
    '12p': 'rgba(74, 82, 158, 0.12)',
    '30p': 'rgba(74, 82, 158, 0.3)',
    '50p': 'rgba(74, 82, 158, 0.5)',
  },
  chart5: {
    main: '#FF8241',
    dark: '#B25B2D',
    light: '#FF9B67',
    contrastText: '#000000',
    '900': '#FF9058',
    '800': '#FF9D6D',
    '700': '#FFAA80',
    '600': '#FFB793',
    '500': '#FFC3A5',
    '400': '#FFCFB7',
    '300': '#FFDBC9',
    '200': '#FFE7DB',
    '100': '#FFF3ED',
    '4p': 'rgba(255, 130, 65, 0.04)',
    '8p': 'rgba(255, 130, 65, 0.08)',
    '12p': 'rgba(255, 130, 65, 0.12)',
    '30p': 'rgba(255, 130, 65, 0.3)',
    '50p': 'rgba(255, 130, 65, 0.5)',
  },
  chart6: {
    main: '#FF656A',
    dark: '#B2464A',
    light: '#FF8387',
    contrastText: '#000000',
    '900': '#FF7779',
    '800': '#FF8887',
    '700': '#FF9896',
    '600': '#FFA7A5',
    '500': '#FFB6B3',
    '400': '#FFC5C2',
    '300': '#FFD4D1',
    '200': '#FFE2E0',
    '100': '#FFF1F0',
    '4p': 'rgba(255, 101, 106, 0.04)',
    '8p': 'rgba(255, 101, 106, 0.08)',
    '12p': 'rgba(255, 101, 106, 0.12)',
    '30p': 'rgba(255, 101, 106, 0.3)',
    '50p': 'rgba(255, 101, 106, 0.5)',
  },
  chart7: {
    main: '#CA5AA8',
    dark: '#8D3E75',
    light: '#D47BB9',
    contrastText: '#FFFFFF',
    '900': '#D16CB1',
    '800': '#D77DB9',
    '700': '#DE8DC2',
    '600': '#E39ECB',
    '500': '#E9AED3',
    '400': '#EEBEDC',
    '300': '#F3CEE5',
    '200': '#F7DEED',
    '100': '#FBEFF6',
    '4p': 'rgba(202, 90, 168, 0.04)',
    '8p': 'rgba(202, 90, 168, 0.08)',
    '12p': 'rgba(202, 90, 168, 0.12)',
    '30p': 'rgba(202, 90, 168, 0.3)',
    '50p': 'rgba(202, 90, 168, 0.5)',
  },
  chart8: {
    main: '#6D3F9E',
    dark: '#4C2C6E',
    light: '#8A65B1',
    contrastText: '#000000',
    '900': '#7A53A8',
    '800': '#8866B3',
    '700': '#9678BD',
    '600': '#A48BC7',
    '500': '#B39ED0',
    '400': '#C2B1DA',
    '300': '#D1C4E4',
    '200': '#E0D8ED',
    '100': '#EFEBF6',
    '4p': 'rgba(109, 63, 158, 0.04)',
    '8p': 'rgba(109, 63, 158, 0.08)',
    '12p': 'rgba(109, 63, 158, 0.12)',
    '30p': 'rgba(109, 63, 158, 0.3)',
    '50p': 'rgba(109, 63, 158, 0.5)',
  },
}

export default chartPalette
