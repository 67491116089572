import { RootAPI, useCurrentOrgId, useCurrentUser } from '..'
import { useMemo } from 'react'

const { useGetChargersQuery } = RootAPI.synopRootAPI

type UseOrgChargersOptions = {
  /**
   * The organization id to fetch Chargers for
   */
  orgId?: string
  /**
   * Flag for whether the Chargers should be polled
   * @default false
   */
  poll?: boolean
  /**
   * Polling interval in milliseconds if poll flag is true
   * @default 60000
   */
  pollingInterval?: number
}

export const useOrgChargers = ({
  orgId,
  poll = false,
  pollingInterval = 60000,
}: UseOrgChargersOptions = {}) => {
  const currentOrgId = useCurrentOrgId()
  const { isSuccess, isLoading, data } = useGetChargersQuery(
    { organizationId: orgId ? [orgId] : [currentOrgId], count: 999 },
    {
      skip: !(orgId || currentOrgId),
      pollingInterval: poll ? pollingInterval : undefined,
    }
  )

  const { synopUser, isAdmin } = useCurrentUser()

  const parsedChargers = useMemo(() => {
    const entities: Record<string, RootAPI.ChargerModel> = {}
    const ids: string[] = []

    for (const item of data?.items ?? []) {
      if (
        item.depotId &&
        !isAdmin &&
        synopUser &&
        synopUser.sites &&
        synopUser.sites.length > 0 &&
        synopUser.sites.indexOf(item.depotId) === -1
      ) {
        continue
      }
      ids.push(item.chargerId.toUpperCase())
      entities[item.chargerId.toUpperCase()] = item
    }
    return { ids, entities }
  }, [data, isAdmin, synopUser])

  const orgChargers = parsedChargers.entities
  return {
    orgChargers,
    isLoading,
    isSuccess,
    lookup: (vehicleId?: string) => orgChargers[vehicleId ?? ''],
  }
}
