import {
  DayjsConstructor,
  formatEnergyFromSourceAttributes,
  Tile,
} from '@synop-react/common'
import { RootAPI, useUserPrefs, VehicleMetrics } from '@synop-react/api'
import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'

const { useGetMetricsForVehicleQuery } = RootAPI

export interface NetEnergyActivityProps {
  vehicleId: string
  from: DayjsConstructor
  to?: DayjsConstructor
}

export function NetEnergyActivity({ vehicleId, from }: NetEnergyActivityProps) {
  const { tzDayjs } = useUserPrefs()
  const currentDate = useMemo(
    () => tzDayjs(from).startOf('day').format('YYYY-MM-DD'),
    [from, tzDayjs]
  )

  const { data: dailyMetrics } = useGetMetricsForVehicleQuery({
    id: vehicleId,
    date: currentDate,
  })

  const { energyUsed } = (dailyMetrics || {}) as VehicleMetrics

  return (
    <Tile title="Daily Energy Used">
      <Stack
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <Typography variant="h4">
          {formatEnergyFromSourceAttributes(energyUsed)}
        </Typography>
        <Typography
          align="center"
          color="secondary.main"
          sx={{ textTransform: 'uppercase' }}
          variant="caption"
        >
          Energy Used
        </Typography>
      </Stack>
    </Tile>
  )
}

export default NetEnergyActivity
