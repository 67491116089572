import { RootAPI } from '../..'
import { unixDateFactory } from './utils'

export const depotUtilization: RootAPI.GetDepotUtilizationApiResponse = {
  activePowerImport: [
    [unixDateFactory(12, 0, 0), 0],
    [unixDateFactory(12, 5, 0), 15],
    [unixDateFactory(12, 10, 0), 15],
    [unixDateFactory(12, 15, 0), 25],
    [unixDateFactory(12, 20, 0), 30],
    [unixDateFactory(12, 25, 0), 15],
    [unixDateFactory(12, 30, 0), 50],
    [unixDateFactory(12, 40, 0), 50],
    [unixDateFactory(12, 45, 0), 55],
    [unixDateFactory(12, 50, 0), 75],
    [unixDateFactory(12, 55, 0), 30],
    [unixDateFactory(12, 60, 0), 30],
    [unixDateFactory(12, 60, 0) + 1, 15],
    [unixDateFactory(12, 60, 0) + 1, 0],
  ],
  activePowerExport: [
    [unixDateFactory(12, 0, 0), 0],
    [unixDateFactory(12, 5, 0), 0],
    [unixDateFactory(12, 10, 0), 0],
    [unixDateFactory(12, 15, 0), 0],
    [unixDateFactory(12, 20, 0), 0],
    [unixDateFactory(12, 25, 0), 0],
    [unixDateFactory(12, 30, 0), 0],
    [unixDateFactory(12, 40, 0), 0],
    [unixDateFactory(12, 45, 0), 0],
    [unixDateFactory(12, 50, 0), 0],
    [unixDateFactory(12, 55, 0), 0],
    [unixDateFactory(12, 60, 0), 0],
    [unixDateFactory(12, 60, 0) + 1, 0],
    [unixDateFactory(12, 60, 0) + 1, 0],
  ],
}
