import { LinearProgress } from '@mui/material'
import { RootAPI, useUserPrefs } from '@synop-react/api'
import { TripDetails } from '@synop-react/vehicle'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const { useGetTripQuery, useGetTripNotesQuery, useGetVehicleTelematicsQuery } =
  RootAPI.synopRootAPI

type TripDetailsRouteParams = {
  assetId: string
  tripId: string
}

const TripDetailsPage = () => {
  const { tzDayjs } = useUserPrefs()

  const { assetId: vehicleId, tripId } = useParams<
    keyof TripDetailsRouteParams
  >() as TripDetailsRouteParams

  const {
    data: tripDetails,
    isLoading: isTripDetailsLoading,
    isFetching: isTripDetailsFetching,
    isSuccess: isTripDetailsSuccess,
  } = useGetTripQuery(
    {
      id: vehicleId as string,
      tripId: tripId as string,
    },
    { skip: !vehicleId || !tripId }
  )

  const { data: telematics } = useGetVehicleTelematicsQuery(
    {
      id: vehicleId as string,
      from: tzDayjs(tripDetails?.routeStart).toISOString(),
      to: tzDayjs(tripDetails?.routeEnd).toISOString(),
    },
    { skip: !tripDetails || !vehicleId }
  )

  // This will filter out telematics data with null or 0 lat/long values.
  const filteredTelematics = useMemo(() => {
    return (telematics || []).filter(
      (telematic) => telematic.latitude && telematic.longitude
    )
  }, [telematics])

  const { data: tripNotes = [] } = useGetTripNotesQuery(
    {
      id: tripId as string,
    },
    { skip: !tripId }
  )

  if (isTripDetailsSuccess) {
    return (
      <TripDetails
        telematics={filteredTelematics}
        tripDetails={tripDetails}
        tripNotes={tripNotes}
      />
    )
  } else if (isTripDetailsLoading || isTripDetailsFetching) {
    return (
      <div>
        <LinearProgress />
        Loading trip details
      </div>
    )
  } else {
    return <div>Cannot find trip details</div>
  }
}

export default TripDetailsPage
