import { Entity } from '@synop-react/types'
import {
  PreferredDateFormat,
  PreferredDistanceUnit,
  PreferredEfficiencyUnit,
  PreferredTemperatureUnit,
  PreferredTimeFormat,
  PreferredTimeZone,
} from './preferredUnits'
import { RootAPI } from '..'

export const userStatuses = [
  'CONFIRMED',
  'FORCE_CHANGE_PASSWORD',
  'UNCONFIRMED',
  'ARCHIVED',
  'UNKNOWN',
  'RESET_REQUIRED',
  'UNKNOWN_TO_SDK_VERSION',
  'DISABLED',
] as const
export type UserStatus = (typeof userStatuses)[number]

export const mfaStatuses = [
  'COMPLETED',
  'INCOMPLETE',
  'NOT_APPLICABLE',
] as const
export type MfaStatus = (typeof mfaStatuses)[number]

export type SynopUser = Entity<
  Omit<Entity<RootAPI.UserModel>, 'preferredDateFormat' | 'status'>
> & {
  preferredDateFormat: PreferredDateFormat
  preferredDistanceUnits: PreferredDistanceUnit
  preferredEfficiencyUnits: PreferredEfficiencyUnit
  preferredTemperatureUnits: PreferredTemperatureUnit
  preferredTimeFormat: PreferredTimeFormat
  preferredTimeZone: PreferredTimeZone
  status: UserStatus
  mfaSetupStatus: MfaStatus
}
