import { AlertCircle } from '@synop-react/common'
import { Chip, useTheme } from '@mui/material'
import { Priority } from '@synop-react/api'
import { useMemo } from 'react'

type NotificationPriorityPillProps = {
  priority?: Priority
}

export const NotificationPriorityPill = ({
  priority,
}: NotificationPriorityPillProps) => {
  const { palette } = useTheme()

  const color = useMemo(() => {
    switch (priority) {
      case 'Critical':
        return palette.error.dark
      case 'High':
        return palette.error.main
      case 'Medium':
        return palette.warning.main
      case 'Low':
        return palette.secondary.main
      default:
        return palette.secondary['4p']
    }
  }, [priority, palette])

  return (
    <Chip
      icon={
        priority === 'Critical' ? (
          <AlertCircle
            sx={{
              hight: 18,
              width: 18,
              color: `${palette.error.contrastText} !important`,
            }}
          />
        ) : undefined
      }
      label={priority ?? 'Unknown Status'}
      size="small"
      sx={{
        color: palette.error.contrastText,
        borderColor: palette.error.contrastText,
        bgcolor: color,
      }}
    />
  )
}
