import * as yup from 'yup'
import { Button, FormGroup, Grid } from '@mui/material'
import {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { FormField, OverlayDeprecated, useOverlay } from '@synop-react/common'
import { FormProvider, useForm } from 'react-hook-form'
import { MfaDisabledOverlay } from '../MFA/MfaDisabledOverlay'
import { MfaRequiredCheckbox } from '../MFA/MfaRequiredCheckbox'
import { MfaRequiredOverlay } from '../MFA/MfaRequiredOverlay'
import { MfaStatusSwitch } from '../MFA/MfaStatusSwitch'
import { OrgMfaStatus, RootAPI, useCurrentOrgId } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup'

const {
  useGetOrganizationQuery,
  useCreateFleetMutation,
  useUpdateOrganizationMutation,
} = RootAPI.synopRootAPI

export type CreateOrEditOrganizationOverlayProps = {
  organizationId?: string
  defaultIsOpen?: boolean
  Trigger: ReactElement
}

const schema = yup
  .object({
    organizationNm: yup.string().nullable().required('Enter organization name'),
  })
  .required()

type CreateOrEditOrganizationFormData = Pick<
  RootAPI.Organization,
  'organizationNm'
>

export function CreateOrEditOrganizationOverlay({
  organizationId,
  defaultIsOpen = false,
  Trigger,
}: CreateOrEditOrganizationOverlayProps) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const [mfaStatus, setMfaStatus] = useState<OrgMfaStatus>('OFF')
  const requireMfaOverlay = useOverlay()
  const disableMfaOverlay = useOverlay()

  const currentOrgId = useCurrentOrgId()
  const { data: organization, isLoading: isLoadingOrg } =
    useGetOrganizationQuery(
      {
        id: organizationId as string,
      },
      {
        skip: !organizationId,
      }
    )

  const formMethods = useForm<CreateOrEditOrganizationFormData>({
    defaultValues: {
      organizationNm: '',
    },
    resolver: yupResolver(schema),
  })
  const {
    control,
    formState: { errors, dirtyFields, isSubmitted },
    handleSubmit,
    reset,
  } = formMethods

  const [createOrganization, newOrganizationResponse] = useCreateFleetMutation()
  const createOrgHandler = useCallback(
    ({ ...organizationFields }: CreateOrEditOrganizationFormData) => {
      const orgId = organizationId || currentOrgId

      createOrganization({
        id: orgId,
        organization: {
          ...organizationFields,
          organizationType: 'CUST',
          mfaStatus,
        },
      })
    },
    [createOrganization, currentOrgId, mfaStatus, organizationId]
  )

  const [updateOrganization, updateOrganizationResponse] =
    useUpdateOrganizationMutation()
  const updateOrgHandler = useCallback(
    ({ ...organizationFields }: CreateOrEditOrganizationFormData) => {
      updateOrganization({
        organization: {
          ...organization,
          ...organizationFields,
          mfaStatus,
        },
      })
    },
    [updateOrganization, organization, mfaStatus]
  )

  useEffect(() => {
    if (!isLoadingOrg) {
      const { organizationNm, mfaStatus } = organization || {}
      reset({
        organizationNm: organizationNm ?? '',
      })
      setMfaStatus(mfaStatus ?? 'OFF')
    }
  }, [isLoadingOrg, organization, reset])

  useEffect(() => {
    if (
      newOrganizationResponse.isSuccess ||
      updateOrganizationResponse.isSuccess
    ) {
      setIsOpen(false)
      reset()
      setMfaStatus(organization?.mfaStatus ?? 'OFF')
    }
  }, [
    newOrganizationResponse,
    organization?.mfaStatus,
    reset,
    updateOrganizationResponse.isSuccess,
  ])

  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => {
      setIsOpen(true)
    },
  })

  const newOrgConfig = {
    overlayTitle: 'New Organization',
    subtitle:
      'Provide the following information to add a new child organization.',
    submitHandler: createOrgHandler,
    actionText: 'CREATE ORGANIZATION',
  }

  const editOrgConfig = {
    overlayTitle: 'Configure Organization',
    subtitle: 'Adjust the following information for your child organization.',
    submitHandler: updateOrgHandler,
    actionText: 'SAVE',
  }

  const isEditing = !!organizationId
  const { overlayTitle, subtitle, submitHandler, actionText } = isEditing
    ? editOrgConfig
    : newOrgConfig

  const isMfaRequired = mfaStatus === 'ON_REQUIRED'
  const isMfaEnabled = mfaStatus === 'ON_NOT_REQUIRED' || isMfaRequired

  return (
    <>
      <MfaRequiredOverlay
        onConfirm={() => {
          setMfaStatus('ON_REQUIRED')
        }}
        orgId={organizationId}
        {...requireMfaOverlay}
      />
      <MfaDisabledOverlay
        onConfirm={() => setMfaStatus('OFF')}
        {...disableMfaOverlay}
      />
      {OverlayTrigger}
      <OverlayDeprecated
        isOpen={isOpen}
        onClose={() => {
          reset()
          setMfaStatus(organization?.mfaStatus ?? 'OFF')
        }}
        OverlayActions={[
          <Button
            color="primary"
            onClick={handleSubmit(submitHandler)}
            variant="contained"
          >
            {actionText}
          </Button>,
        ]}
        setIsOpen={setIsOpen}
        subtitle={subtitle}
        title={overlayTitle}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={6}>
                <FormField.TextFormField
                  control={control}
                  error={errors.organizationNm}
                  fullWidth
                  id="organizationNm"
                  isSubmitted={isSubmitted}
                  label="Organization Name"
                  touched={Boolean(dirtyFields.organizationNm)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <MfaStatusSwitch
                    checked={isMfaEnabled}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        setMfaStatus('ON_NOT_REQUIRED')
                      } else if (isEditing) disableMfaOverlay.openOverlay()
                      else setMfaStatus('OFF')
                    }}
                  />
                  <MfaRequiredCheckbox
                    mfaStatus={mfaStatus}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        if (isEditing) requireMfaOverlay.openOverlay()
                        else setMfaStatus('ON_REQUIRED')
                      } else setMfaStatus('ON_NOT_REQUIRED')
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </OverlayDeprecated>
    </>
  )
}
