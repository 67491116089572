import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import {
  Controller,
  FieldError,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form'

export type FreeformAutocompleteWithHintsTextFieldProps = Omit<
  TextFieldProps,
  'error' | 'value' | 'label'
>

export type FreeformAutocompleteWithHintsProps<
  FormData extends FieldValues,
  OptionType extends string | number
> = {
  id: Path<FormData>
  label: string
  hints?: OptionType[]
  getOptionLabel?: (option: OptionType) => string
} & FreeformAutocompleteWithHintsTextFieldProps

const FreeformAutocompleteWithHints = <
  FormData extends FieldValues,
  OptionType extends string | number
>({
  id,
  label,
  hints = [],
  disabled,
  getOptionLabel = (option: OptionType) => option?.toString() || '',
  ...textFieldProps
}: FreeformAutocompleteWithHintsProps<FormData, OptionType>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { onChange: controllerOnChange, ...props } }) => (
        <Autocomplete
          autoSelect
          disabled={disabled}
          freeSolo
          {...props}
          getOptionLabel={(option) => getOptionLabel(option as OptionType)}
          onChange={(e, data) => {
            controllerOnChange(data)
          }}
          options={hints.map((option) => option.toString())}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              error={Boolean(errors[id] as FieldError)}
              helperText={(errors[id] as FieldError)?.message}
              InputProps={{
                ...params.InputProps,
              }}
              label={label}
              type="number"
              variant="standard"
            />
          )}
        />
      )}
    />
  )
}

export default FreeformAutocompleteWithHints
