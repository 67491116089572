import { DepotUtilizationChart } from '../UtilizationChart'
import { UtilizationChartCask } from '@synop-react/common'

type DepotUtilizationChartPropsProps = {
  depotId: string
}

const DepotUtilizationChartCask = ({
  depotId,
}: DepotUtilizationChartPropsProps) => {
  return (
    <UtilizationChartCask
      options={{ useEndOfCurrentDay: true }}
      title="Site Utilization"
    >
      {({ from, to }) => (
        <DepotUtilizationChart height={325} {...{ from, to, depotId }} />
      )}
    </UtilizationChartCask>
  )
}

export default DepotUtilizationChartCask
