import { Button, Typography } from '@mui/material'
import { createOverlay, Overlay, useOverlayContext } from '@synop-react/common'
import { RootAPI } from '@synop-react/api'
import { sumBy } from 'lodash'
import { useCallback } from 'react'

const { useGetOrganizationUsersQuery } = RootAPI.synopRootAPI

type MfaRequiredOverlayProps = {
  onConfirm: () => void
  orgId?: string
}

export const MfaRequiredOverlay = createOverlay<MfaRequiredOverlayProps>(
  ({ onConfirm, orgId = '' }) => {
    const { closeOverlay } = useOverlayContext()

    const { data: orgUsers } = useGetOrganizationUsersQuery(
      {
        id: orgId,
      },
      {
        skip: !orgId,
      }
    )

    const onClick = useCallback(() => {
      onConfirm()
      closeOverlay()
    }, [closeOverlay, onConfirm])

    const numNoMfaUsers = sumBy(orgUsers, ({ mfaSetupStatus }) =>
      mfaSetupStatus !== 'COMPLETED' ? 1 : 0
    )

    const hasNonMfaUsers = numNoMfaUsers > 0

    return (
      <Overlay>
        <Overlay.Header title="Confim MFA Changes" />

        <Overlay.Content>
          <Typography fontWeight="bold" variant="body1">
            Are you sure you wish to require Multi-factor Authentication for all
            users?
          </Typography>

          {hasNonMfaUsers && (
            <Typography variant="body1">
              You currently have <b>{numNoMfaUsers} users</b> who have not set
              up MFA and will be logged out automatically.
            </Typography>
          )}
        </Overlay.Content>

        <Overlay.Actions>
          <Button color="primary" onClick={onClick} variant="contained">
            CHANGE MFA TO REQUIRED
          </Button>

          <Button onClick={closeOverlay} variant="outlined">
            Cancel
          </Button>
        </Overlay.Actions>
      </Overlay>
    )
  }
)
