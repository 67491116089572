import { Button, Link, Typography } from '@mui/material'
import {
  Cask,
  Edit3,
  emDash,
  LoadingMessage,
  PlusCircle,
  Table,
} from '@synop-react/common'
import { getMfaStatusLabel, MfaStatusChip } from '../MFA/MfaStatusChip'
import { GridComparatorFn } from '@mui/x-data-grid-premium'
import {
  mfaStatuses,
  RootAPI,
  SynopUser,
  useCurrentOrgId,
  useCurrentUser,
  useOrgSites,
} from '@synop-react/api'
import { uniq } from 'lodash'
import { UserStatusChip } from '../UserStatusChip'
import { useState } from 'react'
import UsersOverlay from '../UsersOverlay/UsersOverlay'

const { useGetOrganizationUsersQuery } = RootAPI.synopRootAPI

export function UsersSettings() {
  const orgId = useCurrentOrgId()
  const { isAdmin } = useCurrentUser()

  const [isUsersOverlayOpen, setUsersOverlayOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<SynopUser | null>(null)

  const { data: allUsers, refetch } = useGetOrganizationUsersQuery(
    {
      id: orgId,
    },
    {
      skip: !orgId,
    }
  )

  const { orgSites } = useOrgSites()

  const rolesComparator: GridComparatorFn<SynopUser> = (
    a: SynopUser,
    b: SynopUser
  ) => {
    const roles1 = a.roles?.length ? a.roles : []
    const roles2 = b.roles?.length ? b.roles : []
    return roles1.sort().join('').localeCompare(roles2.sort().join(''))
  }

  const userColumns: Table.ColumnSpec<RootAPI.UserModel> = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      renderCell: ({ value: status, row }) => {
        // TODO: Disabled should be a status from the back end instead
        if (!row.isActive) return <UserStatusChip status="DISABLED" />
        return status ? <UserStatusChip status={status} /> : emDash
      },
    },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'sites',
      headerName: 'Home Site',
      flex: 1,
      valueGetter: ({ row }) => {
        const { sites } = row
        return (sites ?? [])
          .map((siteId) => orgSites[siteId]?.depotNm ?? '')
          .filter(Boolean)
          .join(' ,')
      },
      renderCell: ({ row }) =>
        (row.sites ?? []).map((siteId) => {
          const { depotNm } = orgSites[siteId] || {}
          if (!depotNm) return ''

          const depotDetailsUrl = ['/sites', siteId].join('/')
          return (
            <Link href={depotDetailsUrl} underline="none">
              {depotNm}
            </Link>
          )
        }),
      groupingValueGetter: ({ row }) => {
        const { sites } = row || {}
        return (sites ?? [])
          .map((siteId: string) => {
            const { depotNm = '' } = orgSites[siteId] || {}
            return depotNm
          })
          .filter(Boolean)
          .join(' ,')
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 1,
      renderCell: (params) => <RoleCell roles={params.value} />,
      sortComparator: rolesComparator,
    },
    {
      field: 'mfaSetupStatus',
      headerName: 'MFA',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => <MfaStatusChip mfaStatus={row.mfaSetupStatus} />,
      type: 'singleSelect',
      valueOptions: uniq(
        [...mfaStatuses].map((status) => getMfaStatusLabel(status))
      ),
      valueGetter: ({ value }) => getMfaStatusLabel(value),
    },
    {
      field: 'rfidTags',
      headerName: 'RFID Tags',
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value = [] }) => value.join(' '),
      renderCell: ({ row }) => {
        const rfidTags = row.rfidTags ?? []
        return rfidTags ? (
          <Typography variant="body2">
            {rfidTags.map((tag) => tag.ocppTagValue ?? '').join(', ')}
          </Typography>
        ) : null
      },
    },
  ]

  if (isAdmin) {
    userColumns.push({
      field: 'edit',
      disableColumnMenu: true,
      disableReorder: true,
      resizable: false,
      sortable: false,
      filterable: false,
      headerName: '',
      width: 80,
      renderCell: (params) => (
        <EditCell
          onClickEdit={() => {
            setSelectedUser(params.row as SynopUser)
            setUsersOverlayOpen(!isUsersOverlayOpen)
          }}
        />
      ),
    })
  }

  return (
    <Cask
      Actions={
        isAdmin && (
          <Button
            onClick={() => {
              setSelectedUser(null)
              setUsersOverlayOpen(!isUsersOverlayOpen)
            }}
            startIcon={<PlusCircle />}
            variant="outlined"
          >
            New User
          </Button>
        )
      }
      title={'Users'}
    >
      {isUsersOverlayOpen && (
        <UsersOverlay
          isOpen={isUsersOverlayOpen}
          onClose={refetch}
          setIsOpen={setUsersOverlayOpen}
          userId={selectedUser?.id ? selectedUser.id : null}
        />
      )}
      <Typography variant="body1">
        Add or adjust the users of your organization.
      </Typography>
      {allUsers != undefined ? (
        <Table.ClientSide
          columns={userColumns}
          height="450px"
          initialSortColumn="name"
          tableData={allUsers}
        />
      ) : (
        <LoadingMessage />
      )}
    </Cask>
  )
}

type RoleCellProps = {
  roles?: Array<string>
}

export const RoleCell = ({ roles = [] }: RoleCellProps) => {
  const displayRoles = roles.join(', ')
  return <Typography variant="body2">{displayRoles}</Typography>
}

type EditCellProps = {
  onClickEdit: () => void
}

const EditCell = ({ onClickEdit }: EditCellProps) => {
  return (
    <Button
      onClick={() => {
        onClickEdit()
      }}
    >
      <Edit3 />
    </Button>
  )
}

export default UsersSettings
