import { Cask, CaskProps } from '../Cask'
import { CustomDateRange, CustomSingleDateRange } from '../TimeRange'
import { Grid, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { useFormat } from '../utils/hooks'
import useTimeRange, {
  TimeRangeButtons,
  TimeRangeConfig,
  TimeRangeDropdown,
  TimeRangeProvider,
} from '../TimeRange/useTimeRange'

type TimeControl = 'Dropdown' | 'Button' | 'CustomSingleDate' | 'CustomRange'

export type TimeRangeCaskProps = {
  /*
   * Controls what component will be provided for selecting time range
   */
  rangeControl?: TimeControl
  /*
   * Children that will be passed 'from' & 'to' as props
   */
  disableFromFuture?: boolean
  disableFromPast?: boolean
  disableToFuture?: boolean
  disableToPast?: boolean

  children: (props: TimeCaskChildrenProps) => ReactNode
} & Omit<CaskProps, 'children'> &
  TimeRangeConfig

export type TimeCaskChildrenProps = Pick<
  ReturnType<typeof useTimeRange>,
  'from' | 'to'
>

const TimeRangeCask = ({
  ranges,
  options,
  defaultTimeResolution,
  rangeControl,
  ...props
}: TimeRangeCaskProps) => {
  return (
    <TimeRangeProvider {...{ defaultTimeResolution, ranges, options }}>
      <TimeRangeCaskBody {...props} {...{ rangeControl }} />
    </TimeRangeProvider>
  )
}

export const TimeRangeCaskBody = ({
  Actions = [],
  children,
  disableFromFuture,
  disableFromPast,
  disableToFuture,
  disableToPast,
  ranges,
  options,
  rangeControl = 'Dropdown',
  defaultTimeResolution,
  title,
  ...restCaskProps
}: TimeRangeCaskProps) => {
  const { formatDateTime } = useFormat()
  const { from, to, selectedTimeResolution } = useTimeRange()

  const CustomRange = (
    <CustomDateRange
      {...{
        disableFromFuture,
        disableFromPast,
        disableToFuture,
        disableToPast,
      }}
    />
  )

  const rangeControls: Record<TimeControl, ReactNode> = {
    Dropdown: (
      <Stack direction="row" spacing={1}>
        {
          // Include the custom range in case CUSTOM is a dropdown option
          CustomRange
        }
        <TimeRangeDropdown />
      </Stack>
    ),
    Button: <TimeRangeButtons />,
    CustomSingleDate: (
      <CustomSingleDateRange
        disableFuture={disableToFuture}
        disablePast={disableFromPast}
      />
    ),
    CustomRange,
  }

  const customControls = ['CustomSingleDate', 'CustomRange']
  const shouldIncludeCustom =
    selectedTimeResolution === 'CUSTOM' && customControls.includes(rangeControl)

  const MaybeRangeControl = !customControls.includes(rangeControl) ? (
    <Grid item xs="auto">
      {rangeControls[rangeControl]}
    </Grid>
  ) : null

  const MaybeCustomControl = shouldIncludeCustom
    ? rangeControls[rangeControl]
    : null
  return (
    <Cask
      Actions={[Actions, MaybeRangeControl, MaybeCustomControl]}
      subtitle={formatDateTime(from, to).fromToDayOnDate}
      title={title}
      {...restCaskProps}
    >
      {children({ from, to })}
    </Cask>
  )
}

export default TimeRangeCask
