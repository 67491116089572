import * as Icons from '../Icons'
import { Control, Controller, FieldError } from 'react-hook-form'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'

interface PasswordFormFieldProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  control: Control<any, any>
  error?: FieldError | undefined
  fullWidth?: boolean
  label: string
  id: string
  touched: boolean
}

const PasswordFormField = ({
  control,
  error,
  fullWidth = false,
  id,
  label,
  touched,
}: PasswordFormFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => setShowPassword(!showPassword)

  return (
    <Controller
      control={control}
      data-dd-privacy="mask"
      name={id}
      render={({ field }) => (
        <TextField
          error={touched && Boolean(error)}
          fullWidth={fullWidth}
          helperText={touched && error?.message}
          id={id}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleShowPassword}
                  onMouseDown={handleShowPassword}
                >
                  {showPassword ? <Icons.Eye /> : <Icons.EyeOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={label}
          type={showPassword ? 'text' : 'password'}
          variant="standard"
          {...field}
        />
      )}
    />
  )
}

export default PasswordFormField
