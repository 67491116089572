import { Box, styled } from '@mui/material'
import { GeoJsonProperties } from 'geojson'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { useMemo } from 'react'

import Tooltip, { TooltipProps } from '../../Tooltip'

type MapTooltipProps = {
  x: number
  y: number
  features?: MapboxGeoJSONFeature[]
} & Omit<TooltipProps<GeoJsonProperties>, 'data'>

export function MapTooltip({ x, y, features = [], ...rest }: MapTooltipProps) {
  const tooltipData = useMemo(
    () =>
      features.reduce(
        (acc, feature) => ({ ...acc, ...feature.properties }),
        {} as GeoJsonProperties
      ),
    [features]
  )

  return (
    <TooltipBox sx={{ left: x, top: y }}>
      <Tooltip data={tooltipData} {...rest} />
    </TooltipBox>
  )
}

const TooltipBox = styled(Box)(() => ({
  position: 'absolute',
  zindex: 9,
}))
