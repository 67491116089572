import { AcknowledgeAllOverlay } from './AcknowledgeAllOverlay'
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import {
  Check,
  DetailPageHeader,
  TabCaskView,
  Table,
  TableCask,
  useFormat,
  useRouting,
} from '@synop-react/common'
import { NotificationPriorityPill } from '../NotificationPriorityPill'
import { NotificationStatusTile } from '../NotificationStatusTile'
import { orderBy } from 'lodash'
import { removeOverlayNotifications, setOverlayNotifications } from '../store'
import {
  RootAPI,
  useCurrentUser,
  useOrgChargers,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { useDispatch } from 'react-redux'
import { useMemo, useState } from 'react'

const { useGetNotificationsQuery, useAcknowledgeSentNotificationMutation } =
  RootAPI.synopRootAPI

export const NotificationsDashboard = () => {
  const { chargers, vehicles } = useRouting().routes
  const { tzDayjs } = useUserPrefs()
  const dispatch = useDispatch()
  const { formatDateTime } = useFormat()
  const { user } = useCurrentUser()
  const [displayActive, setDisplayActive] = useState(true)
  const [displayResolved, setDisplayResolved] = useState(false)
  const [acknowledgeAllOverlayOpen, setAcknowledgeAllOverlayOpen] =
    useState(false)

  const { data: notifications = [] } = useGetNotificationsQuery(
    {
      userId: user?.username ?? '',
    },
    {
      skip: !user?.username,
    }
  )
  const { orgChargers, isLoading: isChargersLoading } = useOrgChargers()
  const { orgVehicles, isLoading: isVehiclesLoading } = useOrgVehicles()

  const [acknowledgeNotification] = useAcknowledgeSentNotificationMutation()

  const onAcknowledge = (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id]
    acknowledgeNotification({
      acknowledgeNotificationsModel: {
        sentNotificationIds: ids,
      },
    })
    dispatch(removeOverlayNotifications(ids))
  }
  const { unreviewedNotifications, allNotifications } = useMemo(() => {
    const unreviewedNotifications = sortNotifications(
      notifications
        .filter((n) => !n.acknowledged)
        .filter((n) => {
          const isResolved = n.inactive
          return (
            (displayActive && !isResolved) || (displayResolved && isResolved)
          )
        })
    )

    const allNotifications = sortNotifications(notifications)

    return { unreviewedNotifications, allNotifications }
  }, [notifications, displayActive, displayResolved])

  const { activeCount, resolvedCount } = useMemo(() => {
    let activeCount = 0
    let resolvedCount = 0
    unreviewedNotifications.forEach((n) =>
      n.inactive ? resolvedCount++ : activeCount++
    )
    return { activeCount, resolvedCount }
  }, [unreviewedNotifications])

  const acknowledgeAllNotifications = () => {
    const notificationIds = unreviewedNotifications.map((n) => n.id)
    onAcknowledge(notificationIds)
    setAcknowledgeAllOverlayOpen(false)
  }

  const columns: Table.ColumnSpec<RootAPI.NotificationModel> = [
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      align: 'center',
      renderCell: ({ row: { priority } }) => (
        <NotificationPriorityPill priority={priority} />
      ),
      tooltip:
        'The priority associated with the notification (Critical, High, Medium, or Low).',
    },
    {
      field: 'alertName',
      headerName: 'Notification',
      flex: 3,
      renderCell: ({ row }) => (
        <Link
          href="#"
          onClick={() => dispatch(setOverlayNotifications([row]))}
          sx={{ textDecoration: 'none' }}
        >
          {row.alertName}
        </Link>
      ),
      tooltip: 'The name of the notification.',
    },
    {
      field: 'assetType',
      headerName: 'Type',
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['Charger', 'Vehicle'],
      tooltip: 'The type of notification (Charger or Vehicle).',
    },
    {
      field: 'asset',
      headerName: 'Asset',
      flex: 1,
      renderCell: ({ row: { assetType, assetId }, value }) => {
        const isCharger = assetType === 'Charger'
        const isLoading = isCharger ? isChargersLoading : isVehiclesLoading
        const href = isCharger
          ? chargers.details(assetId ?? '')
          : vehicles.details(assetId ?? '')
        return isLoading ? (
          'Loading...'
        ) : (
          <Link href={href} noWrap sx={{ textDecoration: 'none' }}>
            {value}
          </Link>
        )
      },
      tooltip: 'The asset (Charger or Vehicle) the notification triggered on.',
      valueGetter: ({ row: { assetType, assetId } }) => {
        return assetType === 'Charger'
          ? orgChargers[assetId.toUpperCase()]?.chargerName
          : orgVehicles[assetId]?.vehicleNm
      },
    },
    {
      field: 'active',
      headerName: 'Sent',
      type: 'date',
      valueFormatter: ({ value }) => formatDateTime(value).timeDotDate,
      valueGetter: ({ row }) => tzDayjs(row.active),
      flex: 1.5,
      tooltip: 'The date and time the notification was sent.',
    },
    {
      field: 'inactive',
      headerName: 'Status',
      flex: 1.5,
      renderCell: ({ row }) => <NotificationStatusTile notification={row} />,
      tooltip:
        'Indicates whether the notification is currently active or has been resolved. A notification is resolved either when the lookback period associated with the notification has been exceeded or when the rule which triggered the notification to fire is no longer true.',
    },
    {
      field: 'acknowledged',
      headerName: 'Acknowledged',
      flex: 1.5,
      disableColumnMenu: true,
      type: 'boolean',
      headerAlign: 'left',
      renderCell: ({ row }) => {
        return row.acknowledged ? (
          <Check />
        ) : (
          <Button
            color="primary"
            onClick={() => onAcknowledge(row.id)}
            variant="outlined"
          >
            ACKNOWLEDGE
          </Button>
        )
      },
      tooltip:
        'Indicates whether the notification has been acknowledged or not. Click on the notification name to view more details about when a notification was acknowledged.',
    },
  ]

  return (
    <>
      {acknowledgeAllOverlayOpen && (
        <AcknowledgeAllOverlay
          activeCount={activeCount}
          onConfirm={acknowledgeAllNotifications}
          resolvedCount={resolvedCount}
          setIsOpen={setAcknowledgeAllOverlayOpen}
        />
      )}
      <Grid container mb={4} spacing={4}>
        <Grid item xs={12}>
          <DetailPageHeader alignChildren="flex-end" title="Notifications">
            <Button
              disabled={unreviewedNotifications.length === 0}
              onClick={() => setAcknowledgeAllOverlayOpen(true)}
              startIcon={<Check sx={{ color: 'inherit' }} />}
              variant="contained"
            >
              ACKNOWLEDGE ALL
            </Button>
          </DetailPageHeader>
        </Grid>
        <Grid item xs={12}>
          <TabCaskView
            tabItems={[
              {
                label: 'NEEDS REVIEW',
                content: (
                  <Card sx={{ px: 2, py: 1 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography variant="subtitle2">Displaying: </Typography>
                      <FormControlLabel
                        checked={displayActive}
                        control={<Checkbox />}
                        label="Active"
                        onChange={() => setDisplayActive(!displayActive)}
                      />
                      <FormControlLabel
                        checked={displayResolved}
                        control={<Checkbox />}
                        label="Resolved"
                        onChange={() => setDisplayResolved(!displayResolved)}
                      />
                    </Stack>
                    <Table.ClientSide
                      columns={columns}
                      getRowId={(row) => row.id}
                      noRowsMessage="No notifications to review!"
                      tableData={unreviewedNotifications}
                    />
                  </Card>
                ),
              },
              {
                label: 'ALL',
                content: (
                  <TableCask.ClientSide
                    columns={columns}
                    getRowId={(row) => row.id}
                    noRowsMessage="No notifications to review!"
                    stackProps={{ spacing: 0 }}
                    tableData={allNotifications}
                  />
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export function sortNotifications(notifications: RootAPI.NotificationModel[]) {
  const priorityMap = {
    Critical: 0,
    High: 1,
    Medium: 2,
    Low: 3,
  }

  return orderBy(
    notifications ?? [],
    ['acknowledged', (n) => priorityMap[n.priority], 'active'],
    ['desc', 'asc', 'asc']
  )
}
