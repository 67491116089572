import { Button, Grid, Stack, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useWatch } from 'react-hook-form'

import { emDash } from '@synop-react/common'

export type AlertStepCommonProps = {
  stepNumber: number
  setActiveStep: (step: number) => void
  onCancel: () => void
  onSubmit: () => void
}

export type AlertStepProps = PropsWithChildren<
  AlertStepCommonProps & {
    stepDescription: string
    stepName: string
  }
>

export const AlertStep = ({
  children,
  stepDescription,
  stepName,
  stepNumber,
  onCancel,
  onSubmit,
  setActiveStep,
}: AlertStepProps) => {
  return (
    <Grid container spacing={2}>
      <Grid container item xs={6}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Step {stepNumber + 1}: {stepName}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">{stepDescription}</Typography>
        </Grid>
      </Grid>

      {stepNumber > 0 && <ReviewText />}
      {children}

      <Grid item xs={12}>
        {stepNumber < 2 ? (
          <Button
            onClick={() => setActiveStep(stepNumber + 1)}
            variant="contained"
          >
            Next
          </Button>
        ) : (
          <Button onClick={onSubmit} variant="contained">
            Finish
          </Button>
        )}

        {stepNumber > 0 && (
          <Button
            onClick={() => setActiveStep(stepNumber - 1)}
            sx={{ ml: 2 }}
            variant="outlined"
          >
            Back
          </Button>
        )}

        <Button onClick={onCancel} sx={{ ml: 2 }} variant="outlined">
          Cancel
        </Button>
      </Grid>
    </Grid>
  )
}

const ReviewText = () => {
  const alertName = useWatch({ name: 'alertName' })
  const assetType = useWatch({ name: 'assetType' })

  return (
    <Grid container item sx={{ alignSelf: 'flex-end' }} xs={6}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Typography sx={{ fontWeight: '700' }} variant="caption">
            Alert Name:
          </Typography>
          <Typography variant="caption">{alertName || emDash}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Typography sx={{ fontWeight: '700' }} variant="caption">
            Asset Type:
          </Typography>
          <Typography variant="caption">{assetType}</Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}
