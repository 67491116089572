import { Box, Collapse, Divider, Typography } from '@mui/material'
import { Components, Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { styled } from '@mui/system'

import {
  selectAllOrderedEntities,
  useBrowserSelector,
  useEntityBrowser,
} from '../Browser/useEntityBrowser'
import EntityListItem from './Item/EntityListItem'

export type StringOr<T> = string | T
export type StringOrReactNode = StringOr<ReactNode>

export function EntityList() {
  const virtuosoHandleRef = useRef<VirtuosoHandle>(null)

  const { renderListItem, visibleEntityIds, selectedEntityId } =
    useEntityBrowser()
  const sortedDisplayedEntities = useBrowserSelector(selectAllOrderedEntities)

  const entitiesToRender = useMemo(
    () => sortedDisplayedEntities.filter(({ id }) => id in visibleEntityIds),
    [sortedDisplayedEntities, visibleEntityIds]
  )

  // When the selected entity changes or the list is re-sorted, scroll to the selection
  useEffect(() => {
    if (selectedEntityId) {
      const listIndex = entitiesToRender.findIndex(
        ({ id }) => id === selectedEntityId
      )

      if (listIndex >= 0)
        virtuosoHandleRef.current?.scrollToIndex({
          index: listIndex,
          behavior: 'smooth',
          align: 'start',
        })
    }
  }, [entitiesToRender, selectedEntityId])

  return (
    <Virtuoso
      ref={virtuosoHandleRef}
      components={virtuosoComponents}
      data={entitiesToRender}
      itemContent={(index, entity) => {
        return (
          <Collapse key={entity.id} in>
            {index > 0 ? <Divider /> : null}
            <EntityListItem {...renderListItem(entity)} />
          </Collapse>
        )
      }}
    />
  )
}

const virtuosoComponents: Components = {
  EmptyPlaceholder: () => {
    return (
      <EmptyListMessageBox
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography>No Entities Found</Typography>
      </EmptyListMessageBox>
    )
  },
}

const EmptyListMessageBox = styled(Box)(() => ({
  height: '100%',
  width: 368,
}))

export default EntityList
