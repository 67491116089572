import { EntityFilterer, EntitySearchInput } from '@synop-react/common'
import { VehicleModel } from '@synop-react/api'

/* eslint-disable-next-line */
export interface VehicleSearchInputProps {}

export function VehicleSearchInput() {
  const filterVehicles: EntityFilterer<VehicleModel> = (
    searchString,
    vehicles
  ) =>
    Object.keys(vehicles)
      .filter((vehicleId) =>
        vehicles[vehicleId]?.vehicleNm
          ?.toLowerCase()
          .includes(searchString.toLowerCase())
      )
      .reduce((acc, entityId) => {
        acc[entityId] = true
        return acc
      }, {} as Record<string, boolean>)

  return <EntitySearchInput filterFn={filterVehicles} placeholder="Search..." />
}

export default VehicleSearchInput
