import { Button, Grid, Radio, Stack, Typography } from '@mui/material'
import {
  ChargerAutocomplete,
  createOverlay,
  DepotAutocomplete,
  FormField,
  Overlay,
  useOverlayContext,
} from '@synop-react/common'
import { CustomRatePeriodField } from '../rateTypes'
import {
  dayJsToLocalTime,
  isAllDayRate,
  parseRatePeriodToDayjs,
} from '../rateUtils'
import {
  Depot,
  EnhancedChargerChargingRate,
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
  useOrgChargers,
  useOrgSites,
} from '@synop-react/api'
import {
  FieldError,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { RateDeactivateNotice } from '../RateDeactivateNotice'
import { SetRateInput } from '../SetRateInput'
import { useState } from 'react'
import dayjs from 'dayjs'

type ChargerChargingRateForm = {
  allDayRate: string
  depot: Depot
  charger: RootAPI.ChargerModel
  rateName: string | null
  customRatePeriods: CustomRatePeriodField[]
}

type DeactivateChargerChargingRateOverlayProps = {
  chargingRate: EnhancedChargerChargingRate
}

export const DeactivateChargerChargingRateOverlay =
  createOverlay<DeactivateChargerChargingRateOverlayProps>(
    ({ chargingRate }) => {
      const currentOrgId = useCurrentOrgId()
      const { synopUser } = useCurrentUser()

      const { closeOverlay } = useOverlayContext()

      const [rateTimePeriod, setRateTimePeriod] = useState(
        isAllDayRate(chargingRate.rateStructure) ? 'allDay' : 'custom'
      )

      const { orgSites } = useOrgSites()
      const { orgChargers } = useOrgChargers()

      const { siteId, overrideTargetId } = chargingRate

      const formMethods = useForm<ChargerChargingRateForm>({
        defaultValues: {
          //TODO: revisit this when the API returns more explicit all day rates
          allDayRate:
            isAllDayRate(chargingRate.rateStructure) &&
            chargingRate.rateStructure[0]?.price
              ? `${chargingRate.rateStructure[0].price}`
              : '0.00',

          depot: orgSites[chargingRate.siteId],
          charger: orgChargers[overrideTargetId],
          rateName: chargingRate.rateNm,
          customRatePeriods: parseRatePeriodToDayjs(chargingRate.rateStructure),
        },
      })

      const {
        control,
        handleSubmit,
        formState: { errors, touchedFields },
      } = formMethods

      const { fields } = useFieldArray({
        control,
        name: 'customRatePeriods',
      })

      const [updateChargerRate] =
        RootAPI.useUpdateWorkplaceChargingRatesMutation()
      const onSubmit = handleSubmit(async (formData) => {
        const { rateName, allDayRate, customRatePeriods } = formData

        const rateStructure =
          rateTimePeriod === 'allDay'
            ? [
                {
                  from: dayJsToLocalTime(dayjs().startOf('day')),
                  to: dayJsToLocalTime(dayjs().endOf('day')),
                  price: parseFloat(allDayRate),
                },
              ]
            : customRatePeriods.map(({ from, to, price }) => ({
                from: dayJsToLocalTime(from),
                to: dayJsToLocalTime(to),
                price: parseFloat(price),
              }))

        try {
          await updateChargerRate({
            rateMappingId: chargingRate.rateMappingId,
            updateWorkplaceChargingRate: {
              rateMappingId: chargingRate.rateMappingId,
              organizationId: synopUser?.organizationId as string,
              rateStatus: chargingRate.rateStatus,
              defaultRateStructure: {
                rateNm: rateName as string,
                rateType: 'TIME_OF_USE',
                rateStructure,
                rateStatus: 'INACTIVE',
                rateMappingId: chargingRate.rateMappingId,
              },
            },
          }).unwrap()

          closeOverlay()
        } catch (error) {
          console.log('Error: ', error)
          //TODO: Set Error State to display to user
        }
      })

      return (
        <Overlay>
          <Overlay.Header title="Deactivate Charger Rate" />

          <Overlay.Content>
            <FormProvider {...formMethods}>
              <form>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <DepotAutocomplete.Select
                      control={control}
                      disabled
                      error={errors['depot'] as FieldError}
                      fleetId={currentOrgId}
                      id="depot"
                      label="Site"
                      touchedField={Boolean(touchedFields['depot'])}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormField.TextFormField
                      control={control}
                      disabled
                      error={errors.rateName}
                      fullWidth
                      id="rateName"
                      label="Rate Name"
                      touched={Boolean(touchedFields.rateName)}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ChargerAutocomplete.Select
                      control={control}
                      depotId={siteId}
                      disabled
                      error={errors.charger as FieldError}
                      id="charger"
                      orgId={currentOrgId}
                      touchedField={Boolean(touchedFields.charger)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack alignItems="center" direction="row">
                      <Radio
                        checked={rateTimePeriod === 'allDay'}
                        disabled
                        inputProps={{ 'aria-label': 'all day rate range' }}
                        name="rateTimeRange"
                        onChange={(e) => setRateTimePeriod(e.target.value)}
                        value="allDay"
                      />

                      <Typography sx={{ mr: '22px' }}>All Day: </Typography>

                      <SetRateInput disabled id="allDayRate" />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row">
                      <Radio
                        checked={rateTimePeriod === 'custom'}
                        disabled
                        inputProps={{ 'aria-label': 'custom rate range' }}
                        name="rateTimeRange"
                        onChange={(e) => setRateTimePeriod(e.target.value)}
                        sx={{ height: 48 }}
                        value="custom"
                      />

                      <Typography sx={{ mr: '16px', mt: '12px' }}>
                        Custom:
                      </Typography>

                      <Grid container rowGap={2}>
                        {fields.map((item, index) => (
                          <Grid key={item.id} item xs={12}>
                            <Stack
                              alignItems="center"
                              direction="row"
                              spacing={2}
                            >
                              <FormField.TimePicker
                                disabled
                                id={`customRatePeriods[${index}].from`}
                                label="Start Time"
                                variant="standard"
                              />

                              <FormField.TimePicker
                                disabled
                                id={`customRatePeriods[${index}].to`}
                                label="End Time"
                                variant="standard"
                              />

                              <SetRateInput
                                disabled
                                id={`customRatePeriods[${index}].price`}
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <RateDeactivateNotice
                      description="All inactive rates will be visible through the table filters."
                      title="By deactivating this rate it will no longer be effective at this charger."
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Overlay.Content>

          <Overlay.Actions>
            <Button color="error" onClick={onSubmit} variant="contained">
              Deactivate
            </Button>
            <Button onClick={closeOverlay}>Cancel</Button>
          </Overlay.Actions>
        </Overlay>
      )
    }
  )
