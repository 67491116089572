import {
  Control,
  Controller,
  FieldValues,
  Path,
  useWatch,
} from 'react-hook-form'
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Tooltip,
} from '@mui/material'

export interface CheckboxProps<FormData extends FieldValues> {
  control: Control<FormData, unknown>
  label: string
  id: Path<FormData>
  disabled?: boolean
  tooltip?: string
}

const Checkbox = function <FormData extends FieldValues>({
  control,
  id,
  label,
  disabled = false,
  tooltip,
}: CheckboxProps<FormData>) {
  const value = useWatch({ control, name: id })
  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => (
        <Tooltip title={tooltip}>
          <FormControlLabel
            checked={value}
            control={<MuiCheckbox id={id} {...field} />}
            disabled={disabled}
            label={label}
          />
        </Tooltip>
      )}
    />
  )
}

export default Checkbox
