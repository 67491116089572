import { identity } from 'lodash'
import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

type DayTransform = (day: Dayjs) => Dayjs

/**
 * Returns a memoized Dayjs object. Consecutive calls to the returned function will return the exact
 * same Dayjs object if the operation applied to the input date yields an output date that is the same
 * as the previous output date. This reference equality is valuable for preventing unnecessary
 * re-renders of components which might want to use the Dayjs object as a dependency in a hook.
 *
 * @param date - Input date that can be passed into the Dayjs constructor. Defaults to the current time.
 * @param operation - A function to transform the dayjs object.
 * @returns - The input date transformed by the provided function.
 */
export function useMemoizedDayjs(
  date?: dayjs.ConfigType,
  operation: DayTransform = identity
): Dayjs {
  const [memoDate, setMemoDate] = useState(() => operation(dayjs(date)))

  useEffect(() => {
    const newDate = operation(dayjs(date))
    if (!newDate.isSame(memoDate)) {
      setMemoDate(newDate)
    }
  }, [date, operation, memoDate])

  return memoDate
}
