import { Marker, Source } from 'react-map-gl'

import { MapPin, SynopMap } from '@synop-react/common'
import { RootAPI, VehicleModel } from '@synop-react/api'

import { Box } from '@mui/material'
import { getVehicleCollection } from '../Map/vehicleLocationCollection'
import { ServiceableAreaLayer } from '../Map/ServiceableAreaLayer'

const { useGetVehicleQuery, useGetServiceableRangeForVehicleQuery } =
  RootAPI.synopRootAPI

export type VehicleDetailMapProps = {
  vehicleId: string
}

export const VEHICLE_MARKER_TYPE = 'VEHICLE_MARKER_TYPE'

export function VehicleMapTile({ vehicleId }: VehicleDetailMapProps) {
  const { data: vehicle = {} as VehicleModel } = useGetVehicleQuery({
    id: vehicleId,
  })
  const { data: serviceableArea } = useGetServiceableRangeForVehicleQuery({
    id: vehicleId,
  })

  const vehicleCollection = getVehicleCollection(
    vehicle as VehicleModel,
    serviceableArea
  )

  const { latitude, longitude, stateOfCharge } = vehicle || {}

  const getSocColor = (
    stateOfCharge?: RootAPI.SourceAttributeValueBigDecimal
  ) => {
    const { value: soc = 0 } = stateOfCharge || {}
    if (soc >= 81) return 'secondary'
    else if (soc >= 41 && soc < 81) return 'warning'
    else return 'error'
  }

  const VehicleMarker =
    latitude && longitude ? (
      <Marker latitude={latitude?.value} longitude={longitude?.value}>
        <MapPin color={getSocColor(stateOfCharge)} cursor="grab" />
      </Marker>
    ) : null

  return (
    <Box sx={{ width: '100%', height: '368px' }}>
      <SynopMap boundedData={vehicleCollection} id="vehicleDetailMap">
        <Source
          data={vehicleCollection}
          id="vehicleDetailMap"
          lineMetrics={true}
          type="geojson"
        >
          {VehicleMarker}
          <ServiceableAreaLayer sourceId="vehicleDetailMap" />
        </Source>
      </SynopMap>
    </Box>
  )
}

export default VehicleMapTile
