import { useMemo } from 'react'

import { selectEventsWithIdle } from '../../useTimeline/useTimelineReducer/selectors'
import { selectWindowTransition } from '../../useTimeline/useTimelineReducer/selectors/scale'
import { useTimelineSelector } from '../../useTimeline/useTimeline'
import SlidingGroup from '../../../SlidingGroup'
import TimelineEvent from '../TimelineEvent'

export function TimelineEventGroup() {
  const scheduledEvents = useTimelineSelector(selectEventsWithIdle)
  const { prevX, nextX } = useTimelineSelector(selectWindowTransition)

  const Events = useMemo(() => {
    return Object.entries(scheduledEvents).flatMap(
      ([groupingKey, groupedEvents]) => {
        return groupedEvents.map((scheduledEvent) =>
          scheduledEvent ? (
            <TimelineEvent
              key={`${groupingKey}-${scheduledEvent.eventType}-${scheduledEvent.chargerId}-${scheduledEvent.assetId}-${scheduledEvent.scheduledStart}`}
              scheduledEvent={scheduledEvent}
            />
          ) : null
        )
      }
    )
  }, [scheduledEvents])

  return (
    <SlidingGroup from={prevX} to={nextX}>
      {Events}
    </SlidingGroup>
  )
}

export default TimelineEventGroup
