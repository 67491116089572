import { BillingCycleSelector } from '../HomeChargingSettings/BillingCycleSelector'
import { Button } from '@mui/material'
import { Dayjs } from 'dayjs'
import {
  emDash,
  reduceWattHours,
  Table,
  TableCask,
  useFormat,
  useSnackbarControls,
  useTableCask,
} from '@synop-react/common'
import { FormProvider } from 'react-hook-form'
import { getCSVColumns } from '../HomeChargingSettings/HomeChargingUsers'
import { GridRowId } from '@mui/x-data-grid-premium'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import {
  renderStatusCell,
  StatusObj,
} from '../HomeChargingSettings/HomeChargingSettlements'
import {
  RootAPI,
  useCurrentOrgId,
  useOrgCustomers,
  useUserPrefs,
} from '@synop-react/api'
import { Tuple } from '@synop-react/types'
import { useCallback, useEffect, useMemo, useState } from 'react'

const {
  useGetWorkplaceChargingRecordsQuery,
  useGetWorkplaceSummaryQuery,
  useUpdateWorkplaceChargingRecordStatusesMutation,
} = RootAPI

const statusObj: StatusObj = {
  DRAFT: { title: 'Draft', color: 'success' },
  READY_FOR_REVIEW: { title: 'Ready for Review', color: 'warning' },
  APPROVED: { title: 'Approved', color: 'error' },
  PAID: { title: 'Paid', color: 'secondary' },
  PAST_DUE: { title: 'Past Due', color: 'info' },
}

export function WorkplaceChargingInvoices() {
  const { formMethods, from, to } = useTableCask()
  const [selectedInvoices, setSelectedInvoices] = useState<GridRowId[]>([])
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const [range, setRange] = useState<Tuple<Dayjs | null>>([null, null])
  const { customers: organizations } = useOrgCustomers({
    includeParentOrg: true,
  })
  const orgId = useCurrentOrgId()

  const getFormattedUsage = useCallback((usage?: number) => {
    return usage === undefined
      ? { num: emDash, unit: '' }
      : reduceWattHours(usage, 'kwh')
  }, [])

  const { formatDateTime } = useFormat()

  const { data } = useGetWorkplaceChargingRecordsQuery({
    from: (range[0] ?? tzDayjs()).format('YYYY-MM-DD'),
    to: (range[1] ?? tzDayjs()).format('YYYY-MM-DD'),
    organizationId: orgId,
  })

  const [
    updateWorkplaceChargingRecordStatus,
    updateWorkplaceChargingRecordStatusResponse,
  ] = useUpdateWorkplaceChargingRecordStatusesMutation()
  const handleApproveInvoices = () => {
    const userIds = selectedInvoices.map(
      (invoiceId) =>
        data?.find(
          (invoice: RootAPI.WorkplaceChargingModel) =>
            invoice.invoiceId === invoiceId
        )?.workplaceUserId ?? ''
    )
    updateWorkplaceChargingRecordStatus({
      organizationId: orgId,
      updateWorkplaceChargingRecordStatus: {
        status: 'APPROVED',
        periodStart: (range[0] ?? tzDayjs(from)).format('YYYY-MM-DD'),
        workplaceChargingUserIds: userIds,
      },
    })
  }

  const { openSnackbar } = useSnackbarControls()

  useEffect(() => {
    if (
      updateWorkplaceChargingRecordStatusResponse.status ===
      QueryStatus.fulfilled
    ) {
      openSnackbar('Selected invoices are approved', {
        title: 'Invoices are approved',
      })
    } else if (
      updateWorkplaceChargingRecordStatusResponse.status ===
      QueryStatus.rejected
    ) {
      openSnackbar('Failed to approve selected invoices', {
        title: 'Failed to approve invoices',
      })
    }
  }, [openSnackbar, updateWorkplaceChargingRecordStatusResponse])

  const columns = useMemo<Table.ColumnSpec<RootAPI.WorkplaceChargingModel>>(
    () => [
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Timestamp',
        field: 'asOf',
        valueGetter: ({ value }) => formatDateTime(value).timeOnDate,
      },
      {
        flex: 0.15,
        minWidth: 80,
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatusCell(statusObj),
      },
      {
        flex: 0.15,
        minWidth: 100,
        headerName: 'Invoice',
        field: 'invoiceId',
        valueGetter: ({ value }) => value ?? '-',
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Period Start',
        field: 'periodStart',
        valueGetter: ({ value }) => formatDateTime(value).date,
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Period End',
        field: 'periodEnd',
        valueGetter: ({ value }) => formatDateTime(value).date,
      },
      {
        flex: 0.15,
        minWidth: 120,
        headerName: 'Approved By',
        field: 'approvedByName',
        valueGetter: ({ value }) => value,
      },
      {
        flex: 0.15,
        minWidth: 140,
        headerName: 'Approved Timestamp',
        field: 'approvedOn',
        valueGetter: ({ value }) => value,
      },
      {
        flex: 0.15,
        minWidth: 110,
        field: 'organizationId',
        headerName: 'Organization',
        valueGetter: ({ value }) =>
          organizations[value]?.organizationNm ?? emDash,
      },
      {
        flex: 0.175,
        minWidth: 120,
        field: 'payor_name',
        headerName: 'Payor Name',
        valueGetter: ({ row: { firstNm, lastNm } }) =>
          `${firstNm ?? ''} ${lastNm ?? ''}`,
      },
      {
        flex: 0.15,
        field: 'email',
        minWidth: 120,
        headerName: 'Payor Email',
      },
      {
        flex: 0.15,
        minWidth: 120,
        field: 'externalId',
        headerName: 'External ID',
      },
      {
        flex: 0.15,
        minWidth: 120,
        field: 'amount',
        headerName: 'Total Due',
        valueFormatter: ({ value }) => `$${value}`,
      },
      {
        flex: 0.15,
        field: 'usage',
        minWidth: 120,
        headerName: 'Total Energy',
        renderCell: (params) => {
          const usage = getFormattedUsage(params.row.usage ?? 0)
          return (
            <>
              {usage.num} {usage.unit}
            </>
          )
        },
      },
      {
        flex: 0.15,
        minWidth: 190,
        field: 'numTransactions',
        headerName: 'Number of Transactions',
      },
    ],
    [formatDateTime, getFormattedUsage, organizations]
  )

  const csvColumns = useMemo(() => getCSVColumns(columns), [columns])

  const handleRangeChange = useCallback(
    (range: [Dayjs | null, Dayjs | null]) => {
      setRange(range)
    },
    []
  )

  const downloadTitle = useMemo(
    () =>
      `Workplace_Charging_Invoices_${(range[0] ?? tzDayjs(from)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}_${(range[1] ?? tzDayjs(to)).format(
        preferredDateFormat.replaceAll('/', '-')
      )}`,
    [from, preferredDateFormat, range, to, tzDayjs]
  )

  const isApproveEnabled = useMemo(
    () =>
      ~selectedInvoices.findIndex((invoiceId) => {
        const invoice = data?.find(
          (invoice) => invoice.firstNm + '_' + invoice.periodStart === invoiceId
        )
        return invoice?.status === 'READY_FOR_REVIEW'
      }),
    [data, selectedInvoices]
  )

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        checkboxSelection
        columns={columns}
        csvColumns={csvColumns}
        downloadable
        downloadTitle={downloadTitle}
        getRowId={(row) => row.firstNm + '_' + row.periodStart}
        initialState={{
          columns: {
            columnVisibilityModel: {
              approvedOn: false,
              email: false,
              externalId: false,
            },
          },
        }}
        leftOtherActions={
          <BillingCycleSelector
            handleRangeChange={handleRangeChange}
            optionsToHide={['Previous Cycle']}
            useSummaryQuery={useGetWorkplaceSummaryQuery}
          />
        }
        onRowSelectionModelChange={setSelectedInvoices}
        rightOtherActions={
          <Button
            disabled={!isApproveEnabled}
            onClick={handleApproveInvoices}
            variant="contained"
          >
            APPROVE
          </Button>
        }
        searchable={true}
        tableData={data ? data : []}
        title={''}
      />
    </FormProvider>
  )
}

export default WorkplaceChargingInvoices
