import { useMemo } from 'react'

import { Depot } from '../@types'
import { EMPTY_ENTITY_MAP, parseToEntityMap } from '../api/utils'
import { EntityMap } from '@synop-react/types'
import { RootAPI, useCurrentOrgId, useCurrentUser } from '..'

const { useGetDepotsQuery } = RootAPI.synopRootAPI

type UseOrgSitesProps = { orgId?: string } & Omit<
  RootAPI.GetDepotsApiArg,
  'fleetId'
>

export const useOrgSites = ({ orgId, count = 999 }: UseOrgSitesProps = {}) => {
  const currentOrgId = useCurrentOrgId()
  const { synopUser, isAdmin } = useCurrentUser()

  const { isSuccess, isLoading, data } = useGetDepotsQuery(
    {
      fleetId: orgId || currentOrgId,
      count,
    },
    { skip: !(orgId || currentOrgId) }
  )

  const parsedSites = useMemo(() => {
    let sites = data && data.items ? data.items : []
    if (
      sites &&
      !isAdmin &&
      synopUser &&
      synopUser.sites &&
      synopUser.sites.length > 0
    ) {
      sites = sites.filter(
        (item) =>
          synopUser &&
          synopUser.sites &&
          item.depotId &&
          synopUser.sites.includes(item.depotId)
      )
    }

    return isSuccess && sites?.length
      ? parseToEntityMap<RootAPI.DepotModel, Depot>(sites, 'depotId')
      : (EMPTY_ENTITY_MAP as EntityMap<Depot>)
  }, [isSuccess, data, synopUser, isAdmin])

  return {
    orgSites: parsedSites.entities,
    isLoading,
    isSuccess,
  }
}
