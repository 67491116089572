import {
  AdminFeature,
  DetailsCask,
  Edit2,
  EditChargerOverlay,
} from '@synop-react/common'
import { IconButton } from '@mui/material'

export interface ChargerDetailsCaskProps {
  chargerId: string
  chargerName?: string
  description?: string
  firmwareVersion?: string
  model?: string
  ocppEndpoint?: string
  ocppVersion?: string
  oem?: string
}

export function ChargerDetailsCask({
  chargerId,
  chargerName,
  description,
  firmwareVersion,
  model,
  ocppEndpoint,
  ocppVersion,
  oem,
}: ChargerDetailsCaskProps) {
  return (
    <DetailsCask
      EditOverlay={
        <AdminFeature>
          <EditChargerOverlay
            chargerId={chargerId}
            Trigger={
              <IconButton data-cy="edit-charger-cask-button">
                <Edit2 />
              </IconButton>
            }
          />
        </AdminFeature>
      }
      title="Charger"
    >
      {chargerName && (
        <DetailsCask.Item label="Charger Name" value={chargerName} />
      )}
      <DetailsCask.Item label="Charger ID" value={chargerId} />
      {oem && <DetailsCask.Item label="OEM" value={oem} />}
      {model && <DetailsCask.Item label="Model" value={model} />}
      {description && (
        <DetailsCask.Item label="Description" value={description} />
      )}
      {ocppVersion && (
        <DetailsCask.Item label="OCPP Version" value={ocppVersion} />
      )}
      {ocppEndpoint && (
        <DetailsCask.Item label="OCPP Endpoint" value={ocppEndpoint} />
      )}
      {firmwareVersion && (
        <DetailsCask.Item label="Firmware" value={firmwareVersion} />
      )}
    </DetailsCask>
  )
}

export default ChargerDetailsCask
