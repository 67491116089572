import { Box, Container, styled } from '@mui/material'
import {
  Fallback,
  LoadingMessage,
  TimezoneAlertBar,
  useIsFullscreen,
} from '@synop-react/common'
import { MfaSetup } from '../../pages/Auth/MfaSetup'
import { Outlet } from 'react-router-dom'
import { ReactNode, useEffect } from 'react'
import {
  RootAPI,
  useCurrentOrganization,
  useCurrentUser,
} from '@synop-react/api'
import AppNav from '../../nav/AppNav'
import TermsOfService from '../../pages/Auth/TermsOfService'

import { ErrorBoundary } from 'react-error-boundary'

export type InterstitalStatus = 'SETUP_MFA' | 'ACCEPT_TERMS'

const { useGetOrganizationQuery } = RootAPI

export const useInterstitialStatus = (): InterstitalStatus | null => {
  const { synopUser } = useCurrentUser()
  const { data: userOrg } = useGetOrganizationQuery(
    { id: synopUser?.organizationId ?? '' },
    { skip: !synopUser?.organizationId }
  )

  const { termsAccepted, mfaSetupStatus } = synopUser ?? {}

  if (!synopUser || !userOrg) return null

  if (userOrg?.mfaStatus === 'ON_REQUIRED' && mfaSetupStatus !== 'COMPLETED')
    return 'SETUP_MFA'
  else if (!termsAccepted) return 'ACCEPT_TERMS'

  return null
}

const AuthenticatedLayout = () => {
  const { currentOrg: currentOrganization, isLoading: isLoadingCurrentOrg } =
    useCurrentOrganization()
  const isFullscreen = useIsFullscreen()
  const { isUserLoading } = useCurrentUser()
  const interstitialStatus = useInterstitialStatus()

  //Set brand theme styling
  useEffect(() => {
    if (currentOrganization) {
      const { styleOverrides } = currentOrganization
      if (styleOverrides) {
        const overrides = JSON.parse(styleOverrides)

        const root = document.documentElement
        root.style.setProperty('--navbarbg', overrides['--navbarbg'])
        root.style.setProperty('--primary-synop-color', overrides['--primary'])
        root.style.setProperty(
          '--secondary-synop-color',
          overrides['--secondary']
        )
      }
    }
  }, [currentOrganization])

  const isLoadingData = isUserLoading || isLoadingCurrentOrg

  if (isLoadingData) {
    return (
      <AppContainer>
        <LoadingMessage />
      </AppContainer>
    )
  }

  const maxWidth = isFullscreen ? false : 'xl'

  return interstitialStatus ? (
    <InterstitialPage status={interstitialStatus} />
  ) : (
    <AppContainer
      disableGutters={isFullscreen}
      maxWidth={maxWidth}
      sx={{ alignContent: 'center' }}
    >
      <AppNav />
      <TimezoneAlertBar />
      <InnerContainer isFullscreen={isFullscreen}>
        <ErrorBoundary FallbackComponent={Fallback}>
          <Outlet />
        </ErrorBoundary>
      </InnerContainer>
    </AppContainer>
  )
}

const AppContainer = styled(Container)({
  minHeight: '500px',
})

type InnerContainerProps = {
  isFullscreen?: boolean
  children: ReactNode
}

const InnerContainer = ({ isFullscreen, children }: InnerContainerProps) => {
  const navbarOffset = `18px`
  const cloakingNavOffset = `34px`
  const marginTop = isFullscreen ? navbarOffset : cloakingNavOffset

  return <Box sx={{ marginTop }}>{children}</Box>
}

type InterstitialPageProps = {
  status: InterstitalStatus
}

const InterstitialPage = ({ status }: InterstitialPageProps) => {
  const statusMap = {
    ACCEPT_TERMS: <TermsOfService />,
    SETUP_MFA: <MfaSetup />,
  }

  return (
    <AppContainer
      sx={{
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <InnerContainer>{statusMap[status]}</InnerContainer>
    </AppContainer>
  )
}

export default AuthenticatedLayout
