import { AuthPageHeader } from './AuthPageHeader'
import { AuthPageLogo } from './AuthPageLogo'
import { Grid } from '@mui/material'
import { I18n } from 'aws-amplify'
import { Icons } from '@synop-react/common'
import { translations } from '@aws-amplify/ui-react'

// This updates the TOTP (Time-based One-Time Password) language to be something users can understand
I18n.putVocabularies(translations)
I18n.putVocabularies({
  en: {
    'Confirm TOTP Code': 'Authentication Code',
  },
})

export const AuthCustomizations = {
  Header() {
    return <AuthPageLogo />
  },
  SignIn: {
    Header() {
      return (
        <Grid container spacing={2} sx={{ m: 2 }}>
          <AuthPageHeader
            Icon={Icons.User}
            subtitle="Provide your email address and password to continue."
            title="Sign In"
          />
        </Grid>
      )
    },
  },
  ResetPassword: {
    Header() {
      return (
        <Grid container spacing={2}>
          <AuthPageHeader
            Icon={Icons.Lock}
            subtitle="Provide your email address to reset your password."
            title="Forgot Password"
          />
        </Grid>
      )
    },
  },
}
