import {
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'

import { BaseTable } from './BaseTable'
import { TableProps } from './types'
import LoadingMessage from '../LoadingMessage/LoadingMessage'

type SortOrder = 'asc' | 'desc' | undefined
type ServerSortOrder = 'ASC' | 'DESC' | undefined
type SortField<T extends ApiArg> = Exclude<T['sortBy'], undefined>

type Data<T extends ApiResponse> = Datum<T>[]
type Datum<T extends ApiResponse> = Exclude<T['items'], undefined>[number]
type PagedResponse<T extends GridValidRowModel> = {
  data?: ApiResponse<Datum<T>>
  isLoading: boolean
}

export type DataHookArgs<T extends ApiArg> = {
  page: number
  count: number
  sortBy?: SortField<T>
  order?: ServerSortOrder
}

export type ApiArg = { sortBy?: string }
export type ApiResponse<T extends GridValidRowModel = GridValidRowModel> = {
  first?: boolean
  items?: T[]
  last?: boolean
  page?: number
  pageSize?: number
  totalItems?: number
  totalPages?: number
}

export type UseDataHook<T extends ApiArg, U extends ApiResponse> = (
  args: DataHookArgs<T>
) => PagedResponse<U>

type ServerSideTableProps<T extends ApiArg, U extends ApiResponse> = Omit<
  TableProps<Datum<U>>,
  'tableData' | 'initialSortColumn' | 'initialSortOrder'
> & {
  defaultPageSize?: number
  initialSorting: [SortField<T>, SortOrder]
  setData?: (data: Data<U>) => void
  useData: UseDataHook<T, U>
}

const DEFAULT_PAGE_SIZE = 25

function ServerSideTable<T extends ApiArg, U extends ApiResponse>({
  defaultPageSize = DEFAULT_PAGE_SIZE,
  initialSorting: [sortField, sortOrder],
  setData,
  useData,
  ...dataGridProps
}: ServerSideTableProps<T, U>) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  })

  const defaultSorting: GridSortItem = { field: sortField, sort: sortOrder }
  const [sorting, setSorting] = useState(defaultSorting)
  const { isLoading, data } = useData({
    page: paginationModel.page,
    count: paginationModel.pageSize,
    sortBy: sorting.field as SortField<T>,
    order: sorting.sort?.toUpperCase() as ServerSortOrder,
  })

  // When the data is updated, call the setData callback
  useEffect(() => {
    setData?.(data?.items ?? [])
  }, [data, setData])

  return isLoading ? (
    <LoadingMessage />
  ) : (
    <BaseTable
      autoHeight
      {...dataGridProps}
      onPaginationModelChange={setPaginationModel}
      onSortModelChange={handleSortModelChange}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={data?.totalItems ?? 0}
      sortingMode="server"
      sortModel={[sorting]}
      tableData={data?.items ?? []}
    />
  )

  function handleSortModelChange(model: GridSortModel) {
    setSorting(model[0] ?? defaultSorting)
  }
}

export {
  Data as ServerSideData,
  Datum as ServerSideDatum,
  ServerSideTable as ServerSide,
  ServerSideTableProps as ServerSideProps,
}
