import { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import { useUserPrefs } from '@synop-react/api'

/**
 * Hook that calls a callback every `ms` milliseconds. Note that the callback
 * can change between calls, and the interval will continue uninterrupted. The
 * interval will be cleared when the component unmounts.
 */
function useInterval(callback: () => void, ms: number) {
  const savedCallback = useRef<typeof callback>()

  // Update the callback ref when the callback changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    const tick = () => savedCallback.current?.()
    const id = setInterval(tick, ms)
    return () => clearInterval(id)
  }, [ms])
}

export function useMinuteTimer(refreshRateSecs = 15) {
  const { tzDayjs } = useUserPrefs()
  const [currentTime, setCurrentTime] = useState(tzDayjs().startOf('minute'))
  useInterval(() => setCurrentTime(tzDayjs()), refreshRateSecs * 1000)
  return currentTime
}

export function useDayTimer(refreshRateMins = 1) {
  const { tzDayjs } = useUserPrefs()
  const [currentTime, setCurrentTime] = useState(tzDayjs().startOf('day'))
  useInterval(() => setCurrentTime(tzDayjs()), refreshRateMins * 60_000)
  return currentTime
}

/**
 * Hook that calls a callback when the date in user-space changes. The callback will be called
 * immediately if the date has changed since the last render.
 *
 *  @param callback The callback to call when the date changes.
 */
export function useOnDateChange(callback: (day: Dayjs) => void) {
  const { tzDayjs } = useUserPrefs()
  const prevDay = useRef(tzDayjs().day())
  const currentDay = useDayTimer()
  const currentDate = currentDay.day()

  useEffect(() => {
    if (currentDate !== prevDay.current) {
      prevDay.current = currentDate
      callback(currentDay)
    }
    // We only want to run this effect when the date changes, so omit `currentDay`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate])
}
