import { Dayjs } from 'dayjs'
import { useForm, useWatch } from 'react-hook-form'
import { useMemo } from 'react'

import { useOnDateChange } from '../utils'
import { useUserPrefs } from '@synop-react/api'

type UseTableCaskProps = {
  defaultFrom?: Dayjs
  defaultTo?: Dayjs
}

export const useTableCask = ({
  defaultFrom,
  defaultTo,
}: UseTableCaskProps = {}) => {
  const { tzDayjs } = useUserPrefs()
  const defaultValues = useMemo(() => {
    return {
      from: defaultFrom || tzDayjs().subtract(1, 'week').startOf('day'),
      to: defaultTo || tzDayjs().endOf('day'),
    }
  }, [defaultFrom, defaultTo, tzDayjs])

  const formMethods = useForm({ defaultValues })
  const from = useWatch({ control: formMethods.control, name: 'from' })
  const to = useWatch({ control: formMethods.control, name: 'to' })

  // When the real-world date changes (i.e. the clock strikes midnight in the user's timezone),
  // we want to update the "to" date to be the new date, but only if the user hasn't changed
  // the "to" date themselves.
  useOnDateChange((day) => {
    if (to?.add(1, 'day').isSame(day, 'day')) {
      formMethods.setValue('to', day.endOf('day'))
    }
  })

  return {
    formMethods,
    from: from?.isValid() ? from.startOf('day') : undefined,
    to: to?.isValid() ? to.endOf('day') : undefined,
  }
}
