import { FieldValues } from 'react-hook-form'
import { TextFormField } from '.'
import { TextFormFieldProps } from './TextFormField'
import dayjs from 'dayjs'

type YearPickerProps<FormData extends FieldValues> = {
  maxYear?: number
  minYear?: number
} & Omit<TextFormFieldProps<FormData>, 'type' | 'InputProps'>

const defaultMaxYear = dayjs().year() + 1
const defaultMinYear = dayjs().year() - 25

export const YearPicker = function <FormData extends FieldValues>({
  maxYear = defaultMaxYear,
  minYear = defaultMinYear,
  ...textFieldProps
}: YearPickerProps<FormData>) {
  return (
    <TextFormField
      InputProps={{ inputProps: { min: minYear, max: maxYear } }}
      type="number"
      {...textFieldProps}
    />
  )
}
