import { ChevronLeft, ChevronRight } from '../../../Icons'
import { Dayjs } from 'dayjs'
import { IconButton, Stack, styled, Typography, useTheme } from '@mui/material'
import { useDayTimer } from '../../../utils/date/useTimer'
import { useEffect, useMemo, useRef } from 'react'
import { usePrevious } from 'react-use'
import { useUserPrefs } from '@synop-react/api'

export type DateScrollProps = {
  displayedDate: Dayjs
  setDisplayedDate: (date: Dayjs) => void
  earliestDate?: Dayjs
  latestDate?: Dayjs
}

// TODO Refactor this to use useTimeRange instead of directly accessing props @wslater
export function DateScroll({
  displayedDate,
  setDisplayedDate,
  earliestDate: maybeEarliestDate,
  latestDate: maybeLatestDate,
}: DateScrollProps) {
  const { tzDayjs } = useUserPrefs()
  const currentDay = useDayTimer()
  const previousCurrentDay = usePrevious(currentDay)
  const containerRef = useRef(null)
  const { palette } = useTheme()

  const { earliestDate, latestDate } = useMemo(
    () => ({
      earliestDate: maybeEarliestDate || tzDayjs(),
      latestDate: maybeLatestDate || tzDayjs(),
    }),
    [maybeEarliestDate, maybeLatestDate, tzDayjs]
  )

  // This allows the date to roll over to the next day at midnight
  useEffect(() => {
    // Previous current day is only undefined on first render
    if (!currentDay.startOf('day').isSame(previousCurrentDay?.startOf('day'))) {
      setDisplayedDate(currentDay)
    }
  }, [currentDay, previousCurrentDay, setDisplayedDate])

  const setNextDay = () => {
    setDisplayedDate(displayedDate.add(1, 'day'))
  }

  const setPrevDay = () => {
    setDisplayedDate(displayedDate.subtract(1, 'day'))
  }

  const hasPreviousDays = !tzDayjs(displayedDate).isSame(earliestDate, 'day')
  const hasRemainingDays = !tzDayjs(displayedDate).isSame(latestDate, 'day')

  // If we are outside of the earliest/latest after event modification, need to set it to earliest/latest
  useEffect(() => {
    if (displayedDate.isBefore(earliestDate)) {
      setDisplayedDate(earliestDate)
    } else if (displayedDate.isAfter(latestDate)) {
      setDisplayedDate(latestDate)
    }
  }, [displayedDate, earliestDate, latestDate, setDisplayedDate])

  return (
    <Stack
      ref={containerRef}
      direction="row"
      spacing={1}
      sx={{ alignItems: 'center' }}
    >
      <Typography fontSize="small" sx={{ minWidth: 100 }} variant="button">
        {displayedDate.format('MMMM D YYYY')}
      </Typography>
      <Stack direction="row" spacing={1}>
        <DateToggleButton disabled={!hasPreviousDays} onClick={setPrevDay}>
          <ChevronLeft
            sx={{
              color: hasPreviousDays
                ? palette.primary.main
                : palette.text.disabled,
            }}
          />
        </DateToggleButton>

        <DateToggleButton disabled={!hasRemainingDays} onClick={setNextDay}>
          <ChevronRight
            sx={{
              color: hasRemainingDays
                ? palette.primary.main
                : palette.text.disabled,
            }}
          />
        </DateToggleButton>
      </Stack>
    </Stack>
  )
}

export default DateScroll

const DateToggleButton = styled(IconButton)`
  cursor: pointer;
`
