import {
  Alert,
  AlertProps,
  AlertTitle,
  Button,
  Collapse,
  Stack,
} from '@mui/material'
import { ReactNode, useState } from 'react'
import { useIsCloaking } from '@synop-react/api'
import { X } from '../Icons'

export type AlertBarProps = {
  title?: string
  message?: ReactNode
  icon?: ReactNode
  barColor: string
  textColor: string
  action?: () => void
  actionText?: string
  onClose?: () => void
} & Omit<AlertProps, 'severity' | 'action'>

export const AlertBar = ({
  action,
  actionText,
  barColor,
  icon,
  message = null,
  onClose = () => ({}),
  textColor,
  title,
  ...restAlertProps
}: AlertBarProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const isCloaking = useIsCloaking()

  const MaybeTitle = title ? <AlertTitle>{title}</AlertTitle> : null
  const MaybeAction = action ? (
    <Button key="closeAlert" onClick={action} sx={{ color: 'secondary.main' }}>
      {actionText}
    </Button>
  ) : undefined

  const handleClose = () => {
    onClose()
    setIsOpen(false)
  }

  const CloseIcon = (
    <X
      onClick={handleClose}
      sx={{ color: 'secondary.main', cursor: 'pointer' }}
    />
  )

  const AlertAction = (
    <Stack alignItems="center" direction="row" justifyContent="center">
      {MaybeAction}
      {CloseIcon}
    </Stack>
  )

  const topMarginOffset = isCloaking ? 9 : 3

  return (
    <Collapse in={isOpen}>
      <Alert
        action={AlertAction}
        icon={icon}
        onClose={handleClose}
        sx={{
          color: textColor,
          backgroundColor: barColor,
          mt: topMarginOffset,
          '.MuiAlert-action': {
            alignItems: 'center',
          },
        }}
        {...restAlertProps}
      >
        {MaybeTitle}
        {message}
      </Alert>
    </Collapse>
  )
}

export default AlertBar
