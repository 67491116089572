export const tooltipText = {
  alertName:
    'The name of the alert. This name will be sent to users any time this alert is triggered.',
  assets:
    'Select an asset or group of assets against which this alert should be evaluated. Alternately, leave this field ' +
    'blank to evaluate at all assets given the organization/site filtering above.',
  assetType:
    'Select whether this alert is sent on a vehicle-by-vehicle basis, or charger-by-charger basis.',
  lookbackPeriod:
    'Select a value to enforce a period of time after which notifications will be resolved by the system. If a ' +
    'value is chosen, notifications will resolve when either the auto-resolve period associated with the notification has ' +
    'been exceeded or when the rule which triggered the notification to fire is no longer true. Alternatively, leave ' +
    'this field blank so that notifications resolve only when the rule which triggered the notification to fire is no ' +
    'longer true.',
  message: 'Write a custom message to be sent to users with this notification.',
  organizations:
    'Select an organization or group of organizations against which this alert should be evaluated.',
  priority:
    'The priority of the notification. Notifications with Critical priority will appear as a popup to users ' +
    'in the system. All lower priority notifications (High, Medium, and Low) will appear to users in the upper righthand ' +
    'corner next to the "Alarm" icon.',
  recipients: 'Select a group of users to whom notifications should be sent.',
  sites:
    'Select a site or group of sites against which this alert should be evaluated. Alternatively, leave this field ' +
    'blank to evaluate at the Organization-level only.',
}
