import { Alert, AlertTitle, Typography } from '@mui/material'
import { WarningAmberOutlined } from '@mui/icons-material'

type RateTimezoneNoticeProps = {
  title: string
  description: string
}

export const RateDeactivateNotice = ({
  title,
  description,
}: RateTimezoneNoticeProps) => {
  return (
    <Alert
      icon={
        <WarningAmberOutlined
          sx={{ color: 'error.dark', alignSelf: 'center' }}
        />
      }
      severity="error"
    >
      <AlertTitle>
        <Typography color="error.dark" fontWeight={600} variant="body2">
          {title}
        </Typography>
      </AlertTitle>
      <Typography color="error.dark" variant="body2">
        {description}
      </Typography>
    </Alert>
  )
}
