import { emptyRootAPI as api } from './emptyRootAPI'
export const addTagTypes = [
  'Vehicles',
  'Organizations',
  'Users',
  'Routes',
  'Location of Interest',
  'Integrations',
  'Fleets',
  'Alarms',
  'Trips',
  'EntityEvents',
  'tile-controller',
  'Notifications',
  'chart-controller',
  'Smart Charging',
  'Depots',
  'Chargers',
  'OCPP ID Tags',
  'Charging Transactions',
  'tile-api',
  'Reports',
  'Charger Capabilities',
  'Utilization API',
  'Timespan API',
  'Charger Faults',
  'Grid',
  'Schedule',
  'Load Balancer Events',
  'price-controller',
  'Alerts',
  'Workplace Charging',
  'Tariffs',
  'Stripe Events',
  'Reimbursements',
  'User',
  'Simulator manager API',
  'Simulator manager proxy API',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getVehicles: build.query<GetVehiclesApiResponse, GetVehiclesApiArg>({
        query: (queryArg) => ({
          url: `/api/vehicles`,
          params: { ocppTags: queryArg.ocppTags },
        }),
        providesTags: ['Vehicles'],
      }),
      updateVehicle: build.mutation<
        UpdateVehicleApiResponse,
        UpdateVehicleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles`,
          method: 'PUT',
          body: queryArg.vehicleModel,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      createVehicleWithMetadata: build.mutation<
        CreateVehicleWithMetadataApiResponse,
        CreateVehicleWithMetadataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles`,
          method: 'POST',
          body: queryArg.createVehicleModel,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users`,
          method: 'PUT',
          body: queryArg.userModel,
        }),
        invalidatesTags: ['Organizations', 'Users', 'Vehicles'],
      }),
      createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users`,
          method: 'POST',
          body: queryArg.userModel,
          params: { isFederated: queryArg.isFederated },
        }),
        invalidatesTags: ['Organizations', 'Users'],
      }),
      signOutUser: build.mutation<SignOutUserApiResponse, SignOutUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/signout`,
          method: 'PUT',
        }),
        invalidatesTags: ['Users'],
      }),
      resend: build.mutation<ResendApiResponse, ResendApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/resend`,
          method: 'PUT',
        }),
        invalidatesTags: ['Users'],
      }),
      resetPassword: build.mutation<
        ResetPasswordApiResponse,
        ResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/password`,
          method: 'PUT',
        }),
        invalidatesTags: ['Users'],
      }),
      enableUser: build.mutation<EnableUserApiResponse, EnableUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/enable`,
          method: 'PUT',
        }),
        invalidatesTags: ['Users'],
      }),
      disableUser: build.mutation<DisableUserApiResponse, DisableUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/disable`,
          method: 'PUT',
        }),
        invalidatesTags: ['Users'],
      }),
      getRoutesForFleet: build.query<
        GetRoutesForFleetApiResponse,
        GetRoutesForFleetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/routes`,
          params: { fleetId: queryArg.fleetId },
        }),
        providesTags: ['Routes'],
      }),
      updateRoute: build.mutation<UpdateRouteApiResponse, UpdateRouteApiArg>({
        query: (queryArg) => ({
          url: `/api/routes`,
          method: 'PUT',
          body: queryArg.route,
        }),
        invalidatesTags: ['Routes'],
      }),
      createRoute: build.mutation<CreateRouteApiResponse, CreateRouteApiArg>({
        query: (queryArg) => ({
          url: `/api/routes`,
          method: 'POST',
          body: queryArg.route,
        }),
        invalidatesTags: ['Routes'],
      }),
      updateOrganization: build.mutation<
        UpdateOrganizationApiResponse,
        UpdateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations`,
          method: 'PUT',
          body: queryArg.organization,
        }),
        invalidatesTags: ['Organizations', 'Users'],
      }),
      updateFleetTerms: build.mutation<
        UpdateFleetTermsApiResponse,
        UpdateFleetTermsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/terms`,
          method: 'PUT',
          body: queryArg.organizationTerms,
        }),
        invalidatesTags: ['Organizations'],
      }),
      saveFleetTerms: build.mutation<
        SaveFleetTermsApiResponse,
        SaveFleetTermsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/terms`,
          method: 'POST',
          body: queryArg.organizationTerms,
        }),
        invalidatesTags: ['Organizations'],
      }),
      getLocationsOfInterestForOrganization: build.query<
        GetLocationsOfInterestForOrganizationApiResponse,
        GetLocationsOfInterestForOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/locations`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Location of Interest'],
      }),
      updateLocationOfInterest: build.mutation<
        UpdateLocationOfInterestApiResponse,
        UpdateLocationOfInterestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/locations`,
          method: 'PUT',
          body: queryArg.locationOfInterestUpdateModel,
        }),
        invalidatesTags: ['Location of Interest'],
      }),
      createLocationOfInterest: build.mutation<
        CreateLocationOfInterestApiResponse,
        CreateLocationOfInterestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/locations`,
          method: 'POST',
          body: queryArg.locationOfInterestCreateModel,
        }),
        invalidatesTags: ['Location of Interest'],
      }),
      enableOrganizationIntegration: build.mutation<
        EnableOrganizationIntegrationApiResponse,
        EnableOrganizationIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/integrations/${queryArg.id}/enable`,
          method: 'PUT',
        }),
        invalidatesTags: ['Integrations'],
      }),
      disableIntegration: build.mutation<
        DisableIntegrationApiResponse,
        DisableIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/integrations/${queryArg.id}/disable`,
          method: 'PUT',
        }),
        invalidatesTags: ['Integrations'],
      }),
      updateFleet: build.mutation<UpdateFleetApiResponse, UpdateFleetApiArg>({
        query: (queryArg) => ({
          url: `/api/fleets`,
          method: 'PUT',
          body: queryArg.fleetModel,
        }),
        invalidatesTags: ['Fleets', 'Organizations'],
      }),
      createFleet1: build.mutation<CreateFleet1ApiResponse, CreateFleet1ApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/fleets`,
            method: 'POST',
            body: queryArg.fleetCreateModel,
          }),
          invalidatesTags: ['Fleets', 'Organizations'],
        }
      ),
      assignVehicleToFleet: build.mutation<
        AssignVehicleToFleetApiResponse,
        AssignVehicleToFleetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/fleets/${queryArg.fleetId}/vehicles/${queryArg.vehicleId}/assign`,
          method: 'PUT',
        }),
        invalidatesTags: ['Fleets', 'Organizations'],
      }),
      updateAlarm: build.mutation<UpdateAlarmApiResponse, UpdateAlarmApiArg>({
        query: (queryArg) => ({
          url: `/api/alarms/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.alarmModel,
        }),
        invalidatesTags: ['Alarms'],
      }),
      enableAlarmsForOrganization: build.mutation<
        EnableAlarmsForOrganizationApiResponse,
        EnableAlarmsForOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms/organization/${queryArg.id}/enable`,
          method: 'PUT',
          params: { defaultAlarmId: queryArg.defaultAlarmId },
        }),
        invalidatesTags: ['Alarms'],
      }),
      disableAlarmsForOrganization: build.mutation<
        DisableAlarmsForOrganizationApiResponse,
        DisableAlarmsForOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms/organization/${queryArg.id}/disable`,
          method: 'PUT',
          params: { defaultAlarmId: queryArg.defaultAlarmId },
        }),
        invalidatesTags: ['Alarms'],
      }),
      getVehiclesByOrganizationIntegration: build.query<
        GetVehiclesByOrganizationIntegrationApiResponse,
        GetVehiclesByOrganizationIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/onboard/${queryArg.organizationIntegrationId}`,
        }),
        providesTags: ['Vehicles'],
      }),
      onboardVehiclesByOrganizationIntegration: build.mutation<
        OnboardVehiclesByOrganizationIntegrationApiResponse,
        OnboardVehiclesByOrganizationIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/onboard/${queryArg.organizationIntegrationId}`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      addVehicleEvent: build.mutation<
        AddVehicleEventApiResponse,
        AddVehicleEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/events`,
          method: 'POST',
          body: queryArg.vehicleEvent,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      initiateMfaSetup: build.query<
        InitiateMfaSetupApiResponse,
        InitiateMfaSetupApiArg
      >({
        query: () => ({ url: `/api/users/setup-mfa` }),
        providesTags: ['Users'],
      }),
      verifySoftwareToken: build.mutation<
        VerifySoftwareTokenApiResponse,
        VerifySoftwareTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/setup-mfa`,
          method: 'POST',
          params: { userCode: queryArg.userCode },
        }),
        invalidatesTags: ['Users'],
      }),
      createTripNote: build.mutation<
        CreateTripNoteApiResponse,
        CreateTripNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/trips/notes`,
          method: 'POST',
          body: queryArg.tripNote,
        }),
        invalidatesTags: ['Trips'],
      }),
      assignVehicle: build.mutation<
        AssignVehicleApiResponse,
        AssignVehicleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/vehicles/${queryArg.vehicleId}/assign`,
          method: 'POST',
        }),
        invalidatesTags: ['Organizations'],
      }),
      onboardOrganizationToStripe: build.mutation<
        OnboardOrganizationToStripeApiResponse,
        OnboardOrganizationToStripeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/stripe/onboard`,
          method: 'POST',
          body: queryArg.urlRedirectModel,
        }),
        invalidatesTags: ['Organizations'],
      }),
      saveOrganizationLogo: build.mutation<
        SaveOrganizationLogoApiResponse,
        SaveOrganizationLogoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/logo`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Organizations'],
      }),
      getApiKeysByOrganizationId: build.query<
        GetApiKeysByOrganizationIdApiResponse,
        GetApiKeysByOrganizationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/keys`,
        }),
        providesTags: ['Organizations'],
      }),
      provisionApiKeyByOrganizationId: build.mutation<
        ProvisionApiKeyByOrganizationIdApiResponse,
        ProvisionApiKeyByOrganizationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/keys`,
          method: 'POST',
        }),
        invalidatesTags: ['Organizations'],
      }),
      getIntegrationsByOrganizationId: build.query<
        GetIntegrationsByOrganizationIdApiResponse,
        GetIntegrationsByOrganizationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/integrations`,
        }),
        providesTags: ['Organizations'],
      }),
      saveOrganizationIntegration: build.mutation<
        SaveOrganizationIntegrationApiResponse,
        SaveOrganizationIntegrationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/integrations`,
          method: 'POST',
          body: queryArg.organizationIntegration,
        }),
        invalidatesTags: ['Organizations'],
      }),
      getOrganizationCustomers: build.query<
        GetOrganizationCustomersApiResponse,
        GetOrganizationCustomersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/customers`,
          params: { skipEnhanceData: queryArg.skipEnhanceData },
        }),
        providesTags: ['Organizations'],
      }),
      createFleet: build.mutation<CreateFleetApiResponse, CreateFleetApiArg>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/customers`,
          method: 'POST',
          body: queryArg.organization,
        }),
        invalidatesTags: ['Organizations'],
      }),
      publishToSns: build.mutation<PublishToSnsApiResponse, PublishToSnsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/events`,
            method: 'POST',
            body: queryArg.body,
            params: { subEntity: queryArg.subEntity, event: queryArg.event },
          }),
          invalidatesTags: ['EntityEvents'],
        }
      ),
      assignVehicleToBillingOrganization: build.mutation<
        AssignVehicleToBillingOrganizationApiResponse,
        AssignVehicleToBillingOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/billing/organizations/${queryArg.id}/vehicles/${queryArg.vehicleId}/assign`,
          method: 'POST',
        }),
        invalidatesTags: ['Organizations'],
      }),
      saveNotificationMappingForAlarms: build.mutation<
        SaveNotificationMappingForAlarmsApiResponse,
        SaveNotificationMappingForAlarmsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms/notifications`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Alarms'],
      }),
      deleteNotificationMappingForAlarms: build.mutation<
        DeleteNotificationMappingForAlarmsApiResponse,
        DeleteNotificationMappingForAlarmsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms/notifications`,
          method: 'DELETE',
          body: queryArg.alarmNotificationMapping,
        }),
        invalidatesTags: ['Alarms'],
      }),
      getVehicle: build.query<GetVehicleApiResponse, GetVehicleApiArg>({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}` }),
        providesTags: ['Vehicles'],
      }),
      getLatestVehicleWeatherForecast: build.query<
        GetLatestVehicleWeatherForecastApiResponse,
        GetLatestVehicleWeatherForecastApiArg
      >({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}/weather` }),
        providesTags: ['Vehicles'],
      }),
      getTrips: build.query<GetTripsApiResponse, GetTripsApiArg>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/trips`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            source: queryArg.source,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getTrip: build.query<GetTripApiResponse, GetTripApiArg>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/trips/${queryArg.tripId}`,
        }),
        providesTags: ['Vehicles'],
      }),
      getVehicleTelematics: build.query<
        GetVehicleTelematicsApiResponse,
        GetVehicleTelematicsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/telematics`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Vehicles'],
      }),
      getDailySummaryForVehicle: build.query<
        GetDailySummaryForVehicleApiResponse,
        GetDailySummaryForVehicleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/summary`,
          params: {
            date: queryArg.date,
            isCurrentDate: queryArg.isCurrentDate,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getServiceableRangeForVehicle: build.query<
        GetServiceableRangeForVehicleApiResponse,
        GetServiceableRangeForVehicleApiArg
      >({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}/range` }),
        providesTags: ['Vehicles'],
      }),
      getMetricsForVehicle: build.query<
        GetMetricsForVehicleApiResponse,
        GetMetricsForVehicleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/metrics`,
          params: { date: queryArg.date },
        }),
        providesTags: ['Vehicles'],
      }),
      getAssetEvents: build.query<
        GetAssetEventsApiResponse,
        GetAssetEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/events/`,
          params: {
            type: queryArg['type'],
            from: queryArg['from'],
            to: queryArg.to,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getVehicleStatusBreakdownForVehicles: build.query<
        GetVehicleStatusBreakdownForVehiclesApiResponse,
        GetVehicleStatusBreakdownForVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/vehicle-status-timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getTimespanDataForVehicles: build.query<
        GetTimespanDataForVehiclesApiResponse,
        GetTimespanDataForVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getVehiclesBySite: build.query<
        GetVehiclesBySiteApiResponse,
        GetVehiclesBySiteApiArg
      >({
        query: (queryArg) => ({ url: `/api/vehicles/site/${queryArg.id}` }),
        providesTags: ['Vehicles'],
      }),
      getVehicleReport: build.query<
        GetVehicleReportApiResponse,
        GetVehicleReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/report`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getMetricsForVehicles: build.query<
        GetMetricsForVehiclesApiResponse,
        GetMetricsForVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/metrics`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getEnergyBreakdownDataForVehicles: build.query<
        GetEnergyBreakdownDataForVehiclesApiResponse,
        GetEnergyBreakdownDataForVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/energy-timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getMetricsForVehicles1: build.query<
        GetMetricsForVehicles1ApiResponse,
        GetMetricsForVehicles1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/aggregate-timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          params: { skipEnhanceUserData: queryArg.skipEnhanceUserData },
        }),
        providesTags: ['Users'],
      }),
      getUserTagMappings: build.query<
        GetUserTagMappingsApiResponse,
        GetUserTagMappingsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/tags`,
          params: { ocppTags: queryArg.ocppTags },
        }),
        providesTags: ['Users'],
      }),
      getDropdownOptions: build.query<
        GetDropdownOptionsApiResponse,
        GetDropdownOptionsApiArg
      >({
        query: () => ({ url: `/api/ui/options` }),
        providesTags: ['Users'],
      }),
      getTripNotes: build.query<GetTripNotesApiResponse, GetTripNotesApiArg>({
        query: (queryArg) => ({ url: `/api/trips/${queryArg.id}/notes` }),
        providesTags: ['Trips'],
      }),
      getTripReport: build.query<GetTripReportApiResponse, GetTripReportApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/trips/report`,
            params: {
              from: queryArg['from'],
              to: queryArg.to,
              organizationId: queryArg.organizationId,
              fleetId: queryArg.fleetId,
              siteId: queryArg.siteId,
            },
          }),
          providesTags: ['Trips'],
        }
      ),
      getVehicleUtilization: build.query<
        GetVehicleUtilizationApiResponse,
        GetVehicleUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tiles/vehicle-utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId,
          },
        }),
        providesTags: ['tile-controller'],
      }),
      getVehicleBreakdown: build.query<
        GetVehicleBreakdownApiResponse,
        GetVehicleBreakdownApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tiles/vehicle-breakdown`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId,
          },
        }),
        providesTags: ['tile-controller'],
      }),
      getRouteById: build.query<GetRouteByIdApiResponse, GetRouteByIdApiArg>({
        query: (queryArg) => ({ url: `/api/routes/${queryArg.id}` }),
        providesTags: ['Routes'],
      }),
      deleteRoute: build.mutation<DeleteRouteApiResponse, DeleteRouteApiArg>({
        query: (queryArg) => ({
          url: `/api/routes/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Routes'],
      }),
      getRouteSchedule: build.query<
        GetRouteScheduleApiResponse,
        GetRouteScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/routes/${queryArg.id}/schedule`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Routes'],
      }),
      getStatusOfGeneratedReport: build.query<
        GetStatusOfGeneratedReportApiResponse,
        GetStatusOfGeneratedReportApiArg
      >({
        query: (queryArg) => ({ url: `/api/report/${queryArg.id}` }),
        providesTags: ['Notifications'],
      }),
      getOrganization: build.query<
        GetOrganizationApiResponse,
        GetOrganizationApiArg
      >({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}` }),
        providesTags: ['Organizations'],
      }),
      getVehiclesForOrganization: build.query<
        GetVehiclesForOrganizationApiResponse,
        GetVehiclesForOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/vehicles`,
        }),
        providesTags: ['Organizations', 'Vehicles'],
      }),
      getOrganizationUsers: build.query<
        GetOrganizationUsersApiResponse,
        GetOrganizationUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/users`,
          params: { skipEnhanceData: queryArg.skipEnhanceData },
        }),
        providesTags: ['Organizations'],
      }),
      getTrips1: build.query<GetTrips1ApiResponse, GetTrips1ApiArg>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/trips`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            vehicleIds: queryArg.vehicleIds,
            source: queryArg.source,
          },
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationTerms: build.query<
        GetOrganizationTermsApiResponse,
        GetOrganizationTermsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/terms`,
        }),
        providesTags: ['Organizations'],
      }),
      loginToStripe: build.query<LoginToStripeApiResponse, LoginToStripeApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/organizations/${queryArg.id}/stripe/login`,
          }),
          providesTags: ['Organizations'],
        }
      ),
      getStripeIntentDetail: build.query<
        GetStripeIntentDetailApiResponse,
        GetStripeIntentDetailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/secret`,
        }),
        providesTags: ['Organizations'],
      }),
      getRoutesForOrganization: build.query<
        GetRoutesForOrganizationApiResponse,
        GetRoutesForOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/routes`,
        }),
        providesTags: ['Organizations'],
      }),
      getIntegrationsByOrganizationIdAndIntegrationType: build.query<
        GetIntegrationsByOrganizationIdAndIntegrationTypeApiResponse,
        GetIntegrationsByOrganizationIdAndIntegrationTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/integrations/${queryArg.integrationId}`,
        }),
        providesTags: ['Organizations'],
      }),
      getAllOcppTags: build.query<
        GetAllOcppTagsApiResponse,
        GetAllOcppTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/id-tags`,
        }),
        providesTags: ['Organizations'],
      }),
      getFleets: build.query<GetFleetsApiResponse, GetFleetsApiArg>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/fleets`,
        }),
        providesTags: ['Organizations'],
      }),
      getAllOrganizationCustomers: build.query<
        GetAllOrganizationCustomersApiResponse,
        GetAllOrganizationCustomersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/customers/all`,
          params: { depth: queryArg.depth },
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationConfig: build.query<
        GetOrganizationConfigApiResponse,
        GetOrganizationConfigApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/config`,
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationApiKeyByApiKey: build.query<
        GetOrganizationApiKeyByApiKeyApiResponse,
        GetOrganizationApiKeyByApiKeyApiArg
      >({
        query: (queryArg) => ({ url: `/api/organizations/key/${queryArg.id}` }),
        providesTags: ['Organizations'],
      }),
      getLocationOfInterest: build.query<
        GetLocationOfInterestApiResponse,
        GetLocationOfInterestApiArg
      >({
        query: (queryArg) => ({ url: `/api/locations/${queryArg.id}` }),
        providesTags: ['Location of Interest'],
      }),
      deleteLocationOfInterest: build.mutation<
        DeleteLocationOfInterestApiResponse,
        DeleteLocationOfInterestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/locations/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Location of Interest'],
      }),
      getIntegrations: build.query<
        GetIntegrationsApiResponse,
        GetIntegrationsApiArg
      >({
        query: () => ({ url: `/api/integrations` }),
        providesTags: ['Integrations'],
      }),
      getFleet: build.query<GetFleetApiResponse, GetFleetApiArg>({
        query: (queryArg) => ({ url: `/api/fleets/${queryArg.fleetId}` }),
        providesTags: ['Fleets'],
      }),
      getChartValues: build.query<
        GetChartValuesApiResponse,
        GetChartValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chart/${queryArg.id}`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            attributes: queryArg.attributes,
          },
        }),
        providesTags: ['chart-controller'],
      }),
      getAlarmsForFleet: build.query<
        GetAlarmsForFleetApiResponse,
        GetAlarmsForFleetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Alarms'],
      }),
      getNotificationMappingForAlarms: build.query<
        GetNotificationMappingForAlarmsApiResponse,
        GetNotificationMappingForAlarmsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms/${queryArg.id}/notifications`,
        }),
        providesTags: ['Alarms'],
      }),
      getTriggeredAlarms: build.query<
        GetTriggeredAlarmsApiResponse,
        GetTriggeredAlarmsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alarms/triggered`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Alarms'],
      }),
      getChargeProfiles: build.query<
        GetChargeProfilesApiResponse,
        GetChargeProfilesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/profiles`,
          params: {
            fleetId: queryArg.fleetId,
            depotId: queryArg.depotId,
            effectiveAfter: queryArg.effectiveAfter,
            orgDepth: queryArg.orgDepth,
            page: queryArg.page,
            count: queryArg.count,
            'pageRequest.sort': queryArg['pageRequest.sort'],
          },
        }),
        providesTags: ['Smart Charging'],
      }),
      updateChargeProfile: build.mutation<
        UpdateChargeProfileApiResponse,
        UpdateChargeProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/profiles`,
          method: 'PUT',
          body: queryArg.chargeProfileUpdateModel,
          params: { force: queryArg.force, fleetId: queryArg.fleetId },
        }),
        invalidatesTags: ['Smart Charging'],
      }),
      saveChargeProfile: build.mutation<
        SaveChargeProfileApiResponse,
        SaveChargeProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/profiles`,
          method: 'POST',
          body: queryArg.chargeProfileCreateModel,
          params: { fleetId: queryArg.fleetId },
        }),
        invalidatesTags: ['Smart Charging'],
      }),
      clearChargingProfile: build.mutation<
        ClearChargingProfileApiResponse,
        ClearChargingProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/profiles`,
          method: 'DELETE',
          body: queryArg.chargeProfileActionRequest,
          params: { fleetId: queryArg.fleetId },
        }),
        invalidatesTags: ['Smart Charging'],
      }),
      getDepots: build.query<GetDepotsApiResponse, GetDepotsApiArg>({
        query: (queryArg) => ({
          url: `/api/depots`,
          params: {
            fleetId: queryArg.fleetId,
            organizationId: queryArg.organizationId,
            createdSince: queryArg.createdSince,
            updatedSince: queryArg.updatedSince,
            page: queryArg.page,
            count: queryArg.count,
            'pageRequest.sort': queryArg['pageRequest.sort'],
          },
        }),
        providesTags: ['Depots'],
      }),
      updateDepot: build.mutation<UpdateDepotApiResponse, UpdateDepotApiArg>({
        query: (queryArg) => ({
          url: `/api/depots`,
          method: 'PUT',
          body: queryArg.depotMetaDataUpdateModel,
        }),
        invalidatesTags: ['Depots'],
      }),
      createDepot: build.mutation<CreateDepotApiResponse, CreateDepotApiArg>({
        query: (queryArg) => ({
          url: `/api/depots`,
          method: 'POST',
          body: queryArg.depotCreationModel,
        }),
        invalidatesTags: ['Depots'],
      }),
      getDepotEnergyLimits: build.query<
        GetDepotEnergyLimitsApiResponse,
        GetDepotEnergyLimitsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/limits`,
        }),
        providesTags: ['Depots'],
      }),
      updateDepotEnergyLimits: build.mutation<
        UpdateDepotEnergyLimitsApiResponse,
        UpdateDepotEnergyLimitsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/limits`,
          method: 'PUT',
          body: queryArg.depotEnergyLimitsUpdateModel,
        }),
        invalidatesTags: ['Depots'],
      }),
      getChargers: build.query<GetChargersApiResponse, GetChargersApiArg>({
        query: (queryArg) => ({
          url: `/api/chargers`,
          params: {
            fleetId: queryArg.fleetId,
            organizationId: queryArg.organizationId,
            depotId: queryArg.depotId,
            siteIds: queryArg.siteIds,
            minimumPower: queryArg.minimumPower,
            availableFrom: queryArg.availableFrom,
            availableTo: queryArg.availableTo,
            createdSince: queryArg.createdSince,
            updatedSince: queryArg.updatedSince,
            chargerStatus: queryArg.chargerStatus,
            operationalStatus: queryArg.operationalStatus,
            connectivityStatus: queryArg.connectivityStatus,
            hasConnectorsPreparing: queryArg.hasConnectorsPreparing,
            hasConnectorsCharging: queryArg.hasConnectorsCharging,
            hasConnectorsFaulted: queryArg.hasConnectorsFaulted,
            hasConnectorsUnavailable: queryArg.hasConnectorsUnavailable,
            hasConnectorsAvailable: queryArg.hasConnectorsAvailable,
            hasConnectorsFinishing: queryArg.hasConnectorsFinishing,
            hasConnectorsSuspendedEV: queryArg.hasConnectorsSuspendedEv,
            hasConnectorsSuspendedEVSE: queryArg.hasConnectorsSuspendedEvse,
            heardSince: queryArg.heardSince,
            notHeardSince: queryArg.notHeardSince,
            searchString: queryArg.searchString,
            orgDepth: queryArg.orgDepth,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order,
            'pageRequest.sort': queryArg['pageRequest.sort'],
          },
        }),
        providesTags: ['Chargers'],
      }),
      updateCharger: build.mutation<
        UpdateChargerApiResponse,
        UpdateChargerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers`,
          method: 'PUT',
          body: queryArg.chargerUpdateModel,
        }),
        invalidatesTags: ['Chargers'],
      }),
      createCharger: build.mutation<
        CreateChargerApiResponse,
        CreateChargerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers`,
          method: 'POST',
          body: queryArg.chargerCreationModel,
        }),
        invalidatesTags: ['Chargers'],
      }),
      setChargeProfile: build.mutation<
        SetChargeProfileApiResponse,
        SetChargeProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/profiles/set`,
          method: 'POST',
          body: queryArg.chargeProfileActionRequest,
          params: { fleetId: queryArg.fleetId },
        }),
        invalidatesTags: ['Smart Charging'],
      }),
      cmsOcppTagsGetTags: build.query<
        CmsOcppTagsGetTagsApiResponse,
        CmsOcppTagsGetTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/tags`,
          params: {
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            page: queryArg.page,
            count: queryArg.count,
            'pageRequest.sort': queryArg['pageRequest.sort'],
          },
        }),
        providesTags: ['OCPP ID Tags'],
      }),
      cmsOcppTagsAddTags: build.mutation<
        CmsOcppTagsAddTagsApiResponse,
        CmsOcppTagsAddTagsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/tags`,
          method: 'POST',
          body: queryArg.addTagsInputModel,
        }),
        invalidatesTags: ['OCPP ID Tags'],
      }),
      stopChargingTransaction: build.mutation<
        StopChargingTransactionApiResponse,
        StopChargingTransactionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/stop`,
          method: 'POST',
          params: {
            chargingSessionId: queryArg.chargingSessionId,
            chargingTransactionId: queryArg.chargingTransactionId,
          },
        }),
        invalidatesTags: ['Chargers'],
      }),
      startChargingTransaction: build.mutation<
        StartChargingTransactionApiResponse,
        StartChargingTransactionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/start`,
          method: 'POST',
          body: queryArg.chargingTransactionRequest,
        }),
        invalidatesTags: ['Chargers'],
      }),
      resetCharger: build.mutation<ResetChargerApiResponse, ResetChargerApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/chargers/${queryArg.chargerId}/reset`,
            method: 'POST',
            params: { type: queryArg['type'] },
          }),
          invalidatesTags: ['Chargers'],
        }
      ),
      cmsChargersGetConfigurationKeys: build.query<
        CmsChargersGetConfigurationKeysApiResponse,
        CmsChargersGetConfigurationKeysApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/configuration-keys`,
        }),
        providesTags: ['Chargers'],
      }),
      cmsChargersSetConfigurationKey: build.mutation<
        CmsChargersSetConfigurationKeyApiResponse,
        CmsChargersSetConfigurationKeyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/configuration-keys`,
          method: 'POST',
          params: { key: queryArg.key, value: queryArg.value },
        }),
        invalidatesTags: ['Chargers'],
      }),
      getChargingTransactions: build.query<
        GetChargingTransactionsApiResponse,
        GetChargingTransactionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/transactions`,
          params: {
            chargerId: queryArg.chargerId,
            depotId: queryArg.depotId,
            organizationId: queryArg.organizationId,
            tagId: queryArg.tagId,
            startTimeFrom: queryArg.startTimeFrom,
            startTimeTo: queryArg.startTimeTo,
            stopTimeFrom: queryArg.stopTimeFrom,
            stopTimeTo: queryArg.stopTimeTo,
            updatedSince: queryArg.updatedSince,
            page: queryArg.page,
            count: queryArg.count,
            order: queryArg.order,
            'pageRequest.sort': queryArg['pageRequest.sort'],
          },
        }),
        providesTags: ['Charging Transactions'],
      }),
      getTransactionById: build.query<
        GetTransactionByIdApiResponse,
        GetTransactionByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/transactions/${queryArg.id}` }),
        providesTags: ['Charging Transactions'],
      }),
      getTransactionMeterValues: build.query<
        GetTransactionMeterValuesApiResponse,
        GetTransactionMeterValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/transactions/${queryArg.id}/meter-values`,
        }),
        providesTags: ['Charging Transactions'],
      }),
      cmsChargingTransactionsGetMeterValueIntervalData: build.query<
        CmsChargingTransactionsGetMeterValueIntervalDataApiResponse,
        CmsChargingTransactionsGetMeterValueIntervalDataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/transactions/${queryArg.id}/meter-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
            measureNames: queryArg.measureNames,
            truncateToTxnBounds: queryArg.truncateToTxnBounds,
          },
        }),
        providesTags: ['Charging Transactions'],
      }),
      getPowerUtilization: build.query<
        GetPowerUtilizationApiResponse,
        GetPowerUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tiles/power-utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
            fleetId: queryArg.fleetId,
            orgDepth: queryArg.orgDepth,
          },
        }),
        providesTags: ['tile-api'],
      }),
      getChargingSummary: build.query<
        GetChargingSummaryApiResponse,
        GetChargingSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tiles/charging-summary`,
          params: {
            fleetId: queryArg.fleetId,
            depotIds: queryArg.depotIds,
            from: queryArg['from'],
            to: queryArg.to,
            orgDepth: queryArg.orgDepth,
          },
        }),
        providesTags: ['tile-api'],
      }),
      getTransactionsReport: build.query<
        GetTransactionsReportApiResponse,
        GetTransactionsReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/transactions`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            siteId: queryArg.siteId,
            chargerId: queryArg.chargerId,
            orgDepth: queryArg.orgDepth,
          },
        }),
        providesTags: ['Reports'],
      }),
      getStatusOfGeneratedReport1: build.query<
        GetStatusOfGeneratedReport1ApiResponse,
        GetStatusOfGeneratedReport1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reports/transactions/${queryArg.id}/status`,
        }),
        providesTags: ['Reports'],
      }),
      getChargeProfile: build.query<
        GetChargeProfileApiResponse,
        GetChargeProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/profiles/${queryArg.profileId}`,
          params: { fleetId: queryArg.fleetId },
        }),
        providesTags: ['Smart Charging'],
      }),
      getLiveUtilization: build.query<
        GetLiveUtilizationApiResponse,
        GetLiveUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/fleets/${queryArg.fleetId}/status`,
          params: { orgDepth: queryArg.orgDepth },
        }),
        providesTags: ['Fleets'],
      }),
      getDepot: build.query<GetDepotApiResponse, GetDepotApiArg>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}`,
          params: { include: queryArg.include },
        }),
        providesTags: ['Depots'],
      }),
      deleteDepot: build.mutation<DeleteDepotApiResponse, DeleteDepotApiArg>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Depots'],
      }),
      getDepotUtilization: build.query<
        GetDepotUtilizationApiResponse,
        GetDepotUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
          },
        }),
        providesTags: ['Depots'],
      }),
      getDepotUptime: build.query<
        GetDepotUptimeApiResponse,
        GetDepotUptimeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/uptime`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Depots'],
      }),
      getDepotStatus: build.query<
        GetDepotStatusApiResponse,
        GetDepotStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/status`,
        }),
        providesTags: ['Depots'],
      }),
      getCharger: build.query<GetChargerApiResponse, GetChargerApiArg>({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}` }),
        providesTags: ['Chargers'],
      }),
      deleteCharger: build.mutation<
        DeleteChargerApiResponse,
        DeleteChargerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Chargers'],
      }),
      getChargerUtilizationTimeline: build.query<
        GetChargerUtilizationTimelineApiResponse,
        GetChargerUtilizationTimelineApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
          },
        }),
        providesTags: ['Chargers'],
      }),
      getChargerUptime: build.query<
        GetChargerUptimeApiResponse,
        GetChargerUptimeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/uptime`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Chargers'],
      }),
      getMappedChargerProfiles: build.query<
        GetMappedChargerProfilesApiResponse,
        GetMappedChargerProfilesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/profiles`,
          params: { connectorId: queryArg.connectorId },
        }),
        providesTags: ['Chargers', 'Smart Charging'],
      }),
      getChargerFaults: build.query<
        GetChargerFaultsApiResponse,
        GetChargerFaultsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/faults`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            count: queryArg.count,
          },
        }),
        providesTags: ['Chargers'],
      }),
      getChargerConnectors: build.query<
        GetChargerConnectorsApiResponse,
        GetChargerConnectorsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/connectors`,
        }),
        providesTags: ['Chargers'],
      }),
      getCapabilitySet: build.query<
        GetCapabilitySetApiResponse,
        GetCapabilitySetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/capability-set`,
        }),
        providesTags: ['Charger Capabilities'],
      }),
      cmsUtilizationGetUtilization: build.query<
        CmsUtilizationGetUtilizationApiResponse,
        CmsUtilizationGetUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/utilization`,
          params: {
            organizationId: queryArg.organizationId,
            siteIds: queryArg.siteIds,
            orgDepth: queryArg.orgDepth,
          },
        }),
        providesTags: ['Utilization API'],
      }),
      getChargersTableData: build.query<
        GetChargersTableDataApiResponse,
        GetChargersTableDataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/timespan/table-data`,
          params: {
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            siteIds: queryArg.siteIds,
            fromTs: queryArg.fromTs,
            toTs: queryArg.toTs,
            page: queryArg.page,
            count: queryArg.count,
            order: queryArg.order,
            'pageRequest.sort': queryArg['pageRequest.sort'],
            sortBy: queryArg.sortBy,
            searchString: queryArg.searchString,
          },
        }),
        providesTags: ['Timespan API'],
      }),
      getSummaryData: build.query<
        GetSummaryDataApiResponse,
        GetSummaryDataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/timespan/summary-data`,
          params: {
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            siteIds: queryArg.siteIds,
            fromTs: queryArg.fromTs,
            toTs: queryArg.toTs,
          },
        }),
        providesTags: ['Timespan API'],
      }),
      cmsTimespanGetHistoricalStatuses: build.query<
        CmsTimespanGetHistoricalStatusesApiResponse,
        CmsTimespanGetHistoricalStatusesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/timespan/statuses`,
          params: {
            organizationId: queryArg.organizationId,
            siteIds: queryArg.siteIds,
            chargerIds: queryArg.chargerIds,
            orgDepth: queryArg.orgDepth,
            fromTs: queryArg.fromTs,
            toTs: queryArg.toTs,
          },
        }),
        providesTags: ['Timespan API'],
      }),
      getLatestStatusSummary: build.query<
        GetLatestStatusSummaryApiResponse,
        GetLatestStatusSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/latest-statuses`,
          params: {
            organizationId: queryArg.organizationId,
            siteIds: queryArg.siteIds,
            orgDepth: queryArg.orgDepth,
            includeConnectorsByCharger: queryArg.includeConnectorsByCharger,
          },
        }),
        providesTags: ['Chargers'],
      }),
      cmsChargerFaultsGetFaults: build.query<
        CmsChargerFaultsGetFaultsApiResponse,
        CmsChargerFaultsGetFaultsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/faults`,
          params: {
            chargerIds: queryArg.chargerIds,
            siteIds: queryArg.siteIds,
            organizationId: queryArg.organizationId,
            occurredAfter: queryArg.occurredAfter,
            occurredBefore: queryArg.occurredBefore,
            searchString: queryArg.searchString,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order,
            'pageRequest.sort': queryArg['pageRequest.sort'],
          },
        }),
        providesTags: ['Charger Faults'],
      }),
      getManufacturers: build.query<
        GetManufacturersApiResponse,
        GetManufacturersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-manufacturers`,
          params: { page: queryArg.page, count: queryArg.count },
        }),
        providesTags: ['Charger Capabilities'],
      }),
      getManufacturerMakeModels: build.query<
        GetManufacturerMakeModelsApiResponse,
        GetManufacturerMakeModelsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-manufacturers/${queryArg.manufacturerId}/make-models`,
          params: { page: queryArg.page, count: queryArg.count },
        }),
        providesTags: ['Charger Capabilities'],
      }),
      getDepotEventsByDepotId: build.query<
        GetDepotEventsByDepotIdApiResponse,
        GetDepotEventsByDepotIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Grid'],
      }),
      updateDepotEvent: build.mutation<
        UpdateDepotEventApiResponse,
        UpdateDepotEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events`,
          method: 'PUT',
          body: queryArg.depotEventModel,
          params: { isRecurrence: queryArg.isRecurrence },
        }),
        invalidatesTags: ['Grid'],
      }),
      saveDepotEvent: build.mutation<
        SaveDepotEventApiResponse,
        SaveDepotEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events`,
          method: 'POST',
          body: queryArg.depotEventModel,
        }),
        invalidatesTags: ['Grid'],
      }),
      getScheduledChargerSession: build.query<
        GetScheduledChargerSessionApiResponse,
        GetScheduledChargerSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}`,
        }),
        providesTags: ['Schedule'],
      }),
      updateScheduledChargerSession: build.mutation<
        UpdateScheduledChargerSessionApiResponse,
        UpdateScheduledChargerSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}`,
          method: 'PUT',
          body: queryArg.chargerEventUpdateModel,
          params: { isRecurrence: queryArg.isRecurrence },
        }),
        invalidatesTags: ['Schedule'],
      }),
      cancelScheduledChargerSession: build.mutation<
        CancelScheduledChargerSessionApiResponse,
        CancelScheduledChargerSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}`,
          method: 'DELETE',
          params: { isRecurrence: queryArg.isRecurrence },
        }),
        invalidatesTags: ['Schedule'],
      }),
      getLoadBalancerEvents: build.query<
        GetLoadBalancerEventsApiResponse,
        GetLoadBalancerEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/energy/events/load-balancer`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
          },
        }),
        providesTags: ['Load Balancer Events'],
      }),
      createLoadBalancerEvent: build.mutation<
        CreateLoadBalancerEventApiResponse,
        CreateLoadBalancerEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/energy/events/load-balancer`,
          method: 'POST',
          params: {
            origin: queryArg.origin,
            eventType: queryArg.eventType,
            chargerId: queryArg.chargerId,
            fleetId: queryArg.fleetId,
            depotId: queryArg.depotId,
            originTime: queryArg.originTime,
            executionTime: queryArg.executionTime,
          },
        }),
        invalidatesTags: ['Load Balancer Events'],
      }),
      getScheduledChargerSessions: build.query<
        GetScheduledChargerSessionsApiResponse,
        GetScheduledChargerSessionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            type: queryArg['type'],
            page: queryArg.page,
            count: queryArg.count,
          },
        }),
        providesTags: ['Schedule'],
      }),
      createScheduledChargerSession: build.mutation<
        CreateScheduledChargerSessionApiResponse,
        CreateScheduledChargerSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events`,
          method: 'POST',
          body: queryArg.scheduleRequestModel,
        }),
        invalidatesTags: ['Schedule'],
      }),
      getScheduledChargerSessionDetails: build.query<
        GetScheduledChargerSessionDetailsApiResponse,
        GetScheduledChargerSessionDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}/details`,
        }),
        providesTags: ['Schedule'],
      }),
      saveScheduledChargerSessionDetails: build.mutation<
        SaveScheduledChargerSessionDetailsApiResponse,
        SaveScheduledChargerSessionDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}/details`,
          method: 'POST',
          body: queryArg.chargerEventSessionModel,
        }),
        invalidatesTags: ['Schedule'],
      }),
      getLoadBalancerEvent: build.query<
        GetLoadBalancerEventApiResponse,
        GetLoadBalancerEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/energy/events/load-balancer/${queryArg.id}`,
        }),
        providesTags: ['Load Balancer Events'],
      }),
      getSmartPrices: build.query<
        GetSmartPricesApiResponse,
        GetSmartPricesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/smart-prices`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['price-controller'],
      }),
      getScheduledDepotPower: build.query<
        GetScheduledDepotPowerApiResponse,
        GetScheduledDepotPowerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/power-forecast`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Grid'],
      }),
      getDepotEventById: build.query<
        GetDepotEventByIdApiResponse,
        GetDepotEventByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events/${queryArg.eventId}`,
        }),
        providesTags: ['Grid'],
      }),
      cancelDepotEvent: build.mutation<
        CancelDepotEventApiResponse,
        CancelDepotEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events/${queryArg.eventId}`,
          method: 'DELETE',
          params: { isRecurrence: queryArg.isRecurrence },
        }),
        invalidatesTags: ['Grid'],
      }),
      getScheduledChargerSessionsByDepot: build.query<
        GetScheduledChargerSessionsByDepotApiResponse,
        GetScheduledChargerSessionsByDepotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/chargers/events`,
          params: {
            assetId: queryArg.assetId,
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            count: queryArg.count,
          },
        }),
        providesTags: ['Schedule'],
      }),
      getScheduledChargerSessionTelemetry: build.query<
        GetScheduledChargerSessionTelemetryApiResponse,
        GetScheduledChargerSessionTelemetryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}/telemetry`,
        }),
        providesTags: ['Schedule'],
      }),
      getApiVehiclesByIdTimeToSoc: build.query<
        GetApiVehiclesByIdTimeToSocApiResponse,
        GetApiVehiclesByIdTimeToSocApiArg
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/time-to-soc`,
          params: {
            targetSoc: queryArg.targetSoc,
            powerOffered: queryArg.powerOffered,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      getApiTransactionsTimeToSoc: build.query<
        GetApiTransactionsTimeToSocApiResponse,
        GetApiTransactionsTimeToSocApiArg
      >({
        query: (queryArg) => ({
          url: `/api/transactions/time-to-soc`,
          params: {
            ocppTag: queryArg.ocppTag,
            targetSoc: queryArg.targetSoc,
            powerOffered: queryArg.powerOffered,
          },
        }),
        providesTags: ['Charging Transactions'],
      }),
      acknowledgeSentNotification: build.mutation<
        AcknowledgeSentNotificationApiResponse,
        AcknowledgeSentNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/notifications/acknowledge`,
          method: 'PUT',
          body: queryArg.acknowledgeNotificationsModel,
        }),
        invalidatesTags: ['Notifications'],
      }),
      getAlertsByOrganizationId: build.query<
        GetAlertsByOrganizationIdApiResponse,
        GetAlertsByOrganizationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alerts`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Alerts'],
      }),
      updateAlertByOrganizations: build.mutation<
        UpdateAlertByOrganizationsApiResponse,
        UpdateAlertByOrganizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alerts`,
          method: 'PUT',
          body: queryArg.alertConfigurationModel,
        }),
        invalidatesTags: ['Alerts'],
      }),
      createAlertByOrganizations: build.mutation<
        CreateAlertByOrganizationsApiResponse,
        CreateAlertByOrganizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/alerts`,
          method: 'POST',
          body: queryArg.createAlertConfigurationModel,
        }),
        invalidatesTags: ['Alerts'],
      }),
      getNotifications: build.query<
        GetNotificationsApiResponse,
        GetNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/notifications`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ['Notifications'],
      }),
      getNotificationById: build.query<
        GetNotificationByIdApiResponse,
        GetNotificationByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/notifications/${queryArg.id}` }),
        providesTags: ['Notifications'],
      }),
      getAlertByAlertId: build.query<
        GetAlertByAlertIdApiResponse,
        GetAlertByAlertIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/alerts/${queryArg.id}` }),
        providesTags: ['Alerts'],
      }),
      getProperties: build.query<GetPropertiesApiResponse, GetPropertiesApiArg>(
        {
          query: () => ({ url: `/api/alerts/properties` }),
          providesTags: ['Alerts'],
        }
      ),
      getWorkplaceUser: build.query<
        GetWorkplaceUserApiResponse,
        GetWorkplaceUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-users/${queryArg.workplaceUserId}`,
        }),
        providesTags: ['Workplace Charging'],
      }),
      updateWorkplaceUser: build.mutation<
        UpdateWorkplaceUserApiResponse,
        UpdateWorkplaceUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-users/${queryArg.workplaceUserId}`,
          method: 'PUT',
          body: queryArg.workplaceChargingUserModel,
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      deleteWorkplaceUser: build.mutation<
        DeleteWorkplaceUserApiResponse,
        DeleteWorkplaceUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-users/${queryArg.workplaceUserId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      getWorkplaceChargingRateById: build.query<
        GetWorkplaceChargingRateByIdApiResponse,
        GetWorkplaceChargingRateByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates/${queryArg.rateMappingId}`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Workplace Charging'],
      }),
      updateWorkplaceChargingRates: build.mutation<
        UpdateWorkplaceChargingRatesApiResponse,
        UpdateWorkplaceChargingRatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates/${queryArg.rateMappingId}`,
          method: 'PUT',
          body: queryArg.updateWorkplaceChargingRate,
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      saveTariff: build.mutation<SaveTariffApiResponse, SaveTariffApiArg>({
        query: (queryArg) => ({
          url: `/api/tariffs/${queryArg.partyId}/${queryArg.tariffId}`,
          method: 'PUT',
          body: queryArg.tariff,
        }),
        invalidatesTags: ['Tariffs'],
      }),
      getWorkplaceUsers: build.query<
        GetWorkplaceUsersApiResponse,
        GetWorkplaceUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-users`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Workplace Charging'],
      }),
      saveWorkplaceUser: build.mutation<
        SaveWorkplaceUserApiResponse,
        SaveWorkplaceUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-users`,
          method: 'POST',
          body: queryArg.createWorkplaceChargingUser,
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      getWorkplaceChargingRates: build.query<
        GetWorkplaceChargingRatesApiResponse,
        GetWorkplaceChargingRatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Workplace Charging'],
      }),
      createWorkplaceChargingRates: build.mutation<
        CreateWorkplaceChargingRatesApiResponse,
        CreateWorkplaceChargingRatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates`,
          method: 'POST',
          body: queryArg.createWorkplaceChargingRate,
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      overrideWorkplaceChargingRate: build.mutation<
        OverrideWorkplaceChargingRateApiResponse,
        OverrideWorkplaceChargingRateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates/${queryArg.rateMappingId}/override`,
          method: 'POST',
          body: queryArg.overrideRateStructureModel,
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      getWorkplaceChargingConfiguration: build.query<
        GetWorkplaceChargingConfigurationApiResponse,
        GetWorkplaceChargingConfigurationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-configuration`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Workplace Charging'],
      }),
      saveWorkplaceChargingConfiguration: build.mutation<
        SaveWorkplaceChargingConfigurationApiResponse,
        SaveWorkplaceChargingConfigurationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-configuration`,
          method: 'POST',
          body: queryArg.workplaceConfiguration,
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      updateWorkplaceChargingRecordStatuses: build.mutation<
        UpdateWorkplaceChargingRecordStatusesApiResponse,
        UpdateWorkplaceChargingRecordStatusesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/update-workplace-charging-records-status`,
          method: 'POST',
          body: queryArg.updateWorkplaceChargingRecordStatus,
          params: { organizationId: queryArg.organizationId },
        }),
        invalidatesTags: ['Workplace Charging'],
      }),
      processStripeEvent: build.mutation<
        ProcessStripeEventApiResponse,
        ProcessStripeEventApiArg
      >({
        query: () => ({ url: `/api/stripe/event`, method: 'POST' }),
        invalidatesTags: ['Stripe Events'],
      }),
      getReimbursementUsers: build.query<
        GetReimbursementUsersApiResponse,
        GetReimbursementUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-users`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Reimbursements'],
      }),
      saveReimbursementUser: build.mutation<
        SaveReimbursementUserApiResponse,
        SaveReimbursementUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-users`,
          method: 'POST',
          body: queryArg.reimbursementUserModel,
        }),
        invalidatesTags: ['Reimbursements'],
      }),
      getReimbursementConfiguration: build.query<
        GetReimbursementConfigurationApiResponse,
        GetReimbursementConfigurationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-configuration`,
          params: { organizationId: queryArg.organizationId },
        }),
        providesTags: ['Reimbursements'],
      }),
      saveReimbursementConfiguration: build.mutation<
        SaveReimbursementConfigurationApiResponse,
        SaveReimbursementConfigurationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-configuration`,
          method: 'POST',
          body: queryArg.reimbursementConfiguration,
        }),
        invalidatesTags: ['Reimbursements'],
      }),
      bulkUpdateReimbursementUsers: build.mutation<
        BulkUpdateReimbursementUsersApiResponse,
        BulkUpdateReimbursementUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/bulk-reimbursement-users`,
          method: 'POST',
          body: queryArg.bulkReimbursementConfiguration,
        }),
        invalidatesTags: ['Reimbursements'],
      }),
      getWorkplaceChargingUsage: build.query<
        GetWorkplaceChargingUsageApiResponse,
        GetWorkplaceChargingUsageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-usage`,
          params: {
            billingMonth: queryArg.billingMonth,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ['Workplace Charging'],
      }),
      getWorkplaceSummary: build.query<
        GetWorkplaceSummaryApiResponse,
        GetWorkplaceSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-summary`,
          params: {
            billingMonth: queryArg.billingMonth,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ['Workplace Charging'],
      }),
      getWorkplaceChargingRecords: build.query<
        GetWorkplaceChargingRecordsApiResponse,
        GetWorkplaceChargingRecordsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-records`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ['Workplace Charging'],
      }),
      getStripeIntentDetail1: build.query<
        GetStripeIntentDetail1ApiResponse,
        GetStripeIntentDetail1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/stripe-secret`,
        }),
        providesTags: ['User'],
      }),
      getPaymentMethod: build.query<
        GetPaymentMethodApiResponse,
        GetPaymentMethodApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/payment-method`,
        }),
        providesTags: ['User'],
      }),
      getPortalLogin: build.query<
        GetPortalLoginApiResponse,
        GetPortalLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/manage-billing`,
        }),
        providesTags: ['User'],
      }),
      getUserInvoices: build.query<
        GetUserInvoicesApiResponse,
        GetUserInvoicesApiArg
      >({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/invoices` }),
        providesTags: ['User'],
      }),
      getCheckoutSession: build.query<
        GetCheckoutSessionApiResponse,
        GetCheckoutSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/checkout-session`,
        }),
        providesTags: ['User'],
      }),
      getTariffs: build.query<GetTariffsApiResponse, GetTariffsApiArg>({
        query: (queryArg) => ({
          url: `/api/tariffs`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            offset: queryArg.offset,
            limit: queryArg.limit,
          },
        }),
        providesTags: ['Tariffs'],
      }),
      getReimbursements: build.query<
        GetReimbursementsApiResponse,
        GetReimbursementsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursements`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ['Reimbursements'],
      }),
      getReimbursementUser: build.query<
        GetReimbursementUserApiResponse,
        GetReimbursementUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-users/${queryArg.reimbursementUserId}`,
        }),
        providesTags: ['Reimbursements'],
      }),
      deleteReimbursementUser: build.mutation<
        DeleteReimbursementUserApiResponse,
        DeleteReimbursementUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-users/${queryArg.reimbursementUserId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Reimbursements'],
      }),
      getReimbursementUsageSummary: build.query<
        GetReimbursementUsageSummaryApiResponse,
        GetReimbursementUsageSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-usage`,
          params: {
            billingMonth: queryArg.billingMonth,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ['Reimbursements'],
      }),
      getReimbursementSummary: build.query<
        GetReimbursementSummaryApiResponse,
        GetReimbursementSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-summary`,
          params: {
            billingMonth: queryArg.billingMonth,
            organizationId: queryArg.organizationId,
          },
        }),
        providesTags: ['Reimbursements'],
      }),
      getReimbursements1: build.query<
        GetReimbursements1ApiResponse,
        GetReimbursements1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/reimbursements`,
          params: { from: queryArg['from'], to: queryArg.to },
        }),
        providesTags: ['Organizations'],
      }),
      getPortal: build.query<GetPortalApiResponse, GetPortalApiArg>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/manage-billing`,
        }),
        providesTags: ['Organizations'],
      }),
      getLoginSession: build.query<
        GetLoginSessionApiResponse,
        GetLoginSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/manage-account`,
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationBalance: build.query<
        GetOrganizationBalanceApiResponse,
        GetOrganizationBalanceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/balance`,
        }),
        providesTags: ['Organizations'],
      }),
      listSimulators: build.query<
        ListSimulatorsApiResponse,
        ListSimulatorsApiArg
      >({
        query: () => ({ url: `/api/charger-simulator` }),
        providesTags: ['Simulator manager API'],
      }),
      createSimulator: build.mutation<
        CreateSimulatorApiResponse,
        CreateSimulatorApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator`,
          method: 'POST',
          body: queryArg.simulatorCreationModel,
        }),
        invalidatesTags: ['Simulator manager API'],
      }),
      getContainer: build.query<GetContainerApiResponse, GetContainerApiArg>({
        query: (queryArg) => ({ url: `/api/charger-simulator/${queryArg.id}` }),
        providesTags: ['Simulator manager API'],
      }),
      deleteContainer: build.mutation<
        DeleteContainerApiResponse,
        DeleteContainerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Simulator manager API'],
      }),
      ping: build.query<PingApiResponse, PingApiArg>({
        query: () => ({ url: `/api/charger-simulator/ping` }),
        providesTags: ['Simulator manager API'],
      }),
      simulatorProxyRequest: build.query<
        SimulatorProxyRequestApiResponse,
        SimulatorProxyRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          params: { body: queryArg.body, path: queryArg.path },
        }),
        providesTags: ['Simulator manager proxy API'],
      }),
      simulatorProxyRequest3: build.mutation<
        SimulatorProxyRequest3ApiResponse,
        SimulatorProxyRequest3ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'PUT',
          body: queryArg.body,
          params: { path: queryArg.path },
        }),
        invalidatesTags: ['Simulator manager proxy API'],
      }),
      simulatorProxyRequest2: build.mutation<
        SimulatorProxyRequest2ApiResponse,
        SimulatorProxyRequest2ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'POST',
          body: queryArg.body,
          params: { path: queryArg.path },
        }),
        invalidatesTags: ['Simulator manager proxy API'],
      }),
      simulatorProxyRequest5: build.mutation<
        SimulatorProxyRequest5ApiResponse,
        SimulatorProxyRequest5ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'DELETE',
          body: queryArg.body,
          params: { path: queryArg.path },
        }),
        invalidatesTags: ['Simulator manager proxy API'],
      }),
      simulatorProxyRequest6: build.mutation<
        SimulatorProxyRequest6ApiResponse,
        SimulatorProxyRequest6ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'OPTIONS',
          body: queryArg.body,
          params: { path: queryArg.path },
        }),
        invalidatesTags: ['Simulator manager proxy API'],
      }),
      simulatorProxyRequest1: build.mutation<
        SimulatorProxyRequest1ApiResponse,
        SimulatorProxyRequest1ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'HEAD',
          body: queryArg.body,
          params: { path: queryArg.path },
        }),
        invalidatesTags: ['Simulator manager proxy API'],
      }),
      simulatorProxyRequest4: build.mutation<
        SimulatorProxyRequest4ApiResponse,
        SimulatorProxyRequest4ApiArg
      >({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'PATCH',
          body: queryArg.body,
          params: { path: queryArg.path },
        }),
        invalidatesTags: ['Simulator manager proxy API'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as synopRootAPI }
export type GetVehiclesApiResponse = /** status 200 OK */ VehicleModel[]
export type GetVehiclesApiArg = {
  ocppTags?: string[]
}
export type UpdateVehicleApiResponse = /** status 200 OK */ VehicleModel
export type UpdateVehicleApiArg = {
  vehicleModel: VehicleModel
}
export type CreateVehicleWithMetadataApiResponse =
  /** status 200 OK */ VehicleModel
export type CreateVehicleWithMetadataApiArg = {
  createVehicleModel: CreateVehicleModel
}
export type UpdateUserApiResponse = /** status 200 OK */ UserModel
export type UpdateUserApiArg = {
  userModel: UserModel
}
export type CreateUserApiResponse = /** status 200 OK */ UserModel
export type CreateUserApiArg = {
  isFederated?: boolean
  userModel: UserModel
}
export type SignOutUserApiResponse = /** status 200 OK */ boolean
export type SignOutUserApiArg = {
  id: string
}
export type ResendApiResponse = /** status 200 OK */ boolean
export type ResendApiArg = {
  id: string
}
export type ResetPasswordApiResponse = /** status 200 OK */ boolean
export type ResetPasswordApiArg = {
  id: string
}
export type EnableUserApiResponse = /** status 200 OK */ boolean
export type EnableUserApiArg = {
  id: string
}
export type DisableUserApiResponse = /** status 200 OK */ boolean
export type DisableUserApiArg = {
  id: string
}
export type GetRoutesForFleetApiResponse = /** status 200 OK */ Route[]
export type GetRoutesForFleetApiArg = {
  fleetId?: string
}
export type UpdateRouteApiResponse = /** status 200 OK */ Route
export type UpdateRouteApiArg = {
  route: Route
}
export type CreateRouteApiResponse = /** status 200 OK */ Route
export type CreateRouteApiArg = {
  route: Route
}
export type UpdateOrganizationApiResponse = /** status 200 OK */ Organization
export type UpdateOrganizationApiArg = {
  organization: Organization
}
export type UpdateFleetTermsApiResponse = /** status 200 OK */ OrganizationTerms
export type UpdateFleetTermsApiArg = {
  organizationTerms: OrganizationTerms
}
export type SaveFleetTermsApiResponse = /** status 200 OK */ OrganizationTerms
export type SaveFleetTermsApiArg = {
  organizationTerms: OrganizationTerms
}
export type GetLocationsOfInterestForOrganizationApiResponse =
  /** status 200 OK */ LocationOfInterestResponseModel[]
export type GetLocationsOfInterestForOrganizationApiArg = {
  organizationId: string
}
export type UpdateLocationOfInterestApiResponse = unknown
export type UpdateLocationOfInterestApiArg = {
  locationOfInterestUpdateModel: LocationOfInterestUpdateModel
}
export type CreateLocationOfInterestApiResponse =
  /** status 200 OK */ LocationOfInterestResponseModel
export type CreateLocationOfInterestApiArg = {
  locationOfInterestCreateModel: LocationOfInterestCreateModel
}
export type EnableOrganizationIntegrationApiResponse =
  /** status 200 OK */ boolean
export type EnableOrganizationIntegrationApiArg = {
  id: string
}
export type DisableIntegrationApiResponse = /** status 200 OK */ boolean
export type DisableIntegrationApiArg = {
  id: string
}
export type UpdateFleetApiResponse = /** status 200 OK */ FleetModel
export type UpdateFleetApiArg = {
  fleetModel: FleetModel
}
export type CreateFleet1ApiResponse = /** status 200 OK */ FleetModel
export type CreateFleet1ApiArg = {
  fleetCreateModel: FleetCreateModel
}
export type AssignVehicleToFleetApiResponse = /** status 200 OK */ VehicleModel
export type AssignVehicleToFleetApiArg = {
  fleetId: string
  vehicleId: string
}
export type UpdateAlarmApiResponse = /** status 200 OK */ AlarmModel
export type UpdateAlarmApiArg = {
  id: string
  alarmModel: AlarmModel
}
export type EnableAlarmsForOrganizationApiResponse = unknown
export type EnableAlarmsForOrganizationApiArg = {
  id: string
  defaultAlarmId: string
}
export type DisableAlarmsForOrganizationApiResponse = unknown
export type DisableAlarmsForOrganizationApiArg = {
  id: string
  defaultAlarmId: string
}
export type GetVehiclesByOrganizationIntegrationApiResponse =
  /** status 200 OK */ ExternalVehicleModel[]
export type GetVehiclesByOrganizationIntegrationApiArg = {
  organizationIntegrationId: string
}
export type OnboardVehiclesByOrganizationIntegrationApiResponse =
  /** status 200 OK */ boolean
export type OnboardVehiclesByOrganizationIntegrationApiArg = {
  organizationIntegrationId: string
  body: ExternalVehicleModel[]
}
export type AddVehicleEventApiResponse = /** status 200 OK */ VehicleEvent
export type AddVehicleEventApiArg = {
  vehicleEvent: VehicleEvent
}
export type InitiateMfaSetupApiResponse = /** status 200 OK */ MfaModel
export type InitiateMfaSetupApiArg = void
export type VerifySoftwareTokenApiResponse = unknown
export type VerifySoftwareTokenApiArg = {
  userCode: string
}
export type CreateTripNoteApiResponse = /** status 200 OK */ TripNote
export type CreateTripNoteApiArg = {
  tripNote: TripNote
}
export type AssignVehicleApiResponse = /** status 200 OK */ VehicleModel
export type AssignVehicleApiArg = {
  id: string
  vehicleId: string
}
export type OnboardOrganizationToStripeApiResponse =
  /** status 200 OK */ StringResponseModel
export type OnboardOrganizationToStripeApiArg = {
  id: string
  urlRedirectModel: UrlRedirectModel
}
export type SaveOrganizationLogoApiResponse = /** status 200 OK */ Organization
export type SaveOrganizationLogoApiArg = {
  id: string
  body: {
    logo: Blob
  }
}
export type GetApiKeysByOrganizationIdApiResponse =
  /** status 200 OK */ OrganizationApiKeyModel[]
export type GetApiKeysByOrganizationIdApiArg = {
  id: string
}
export type ProvisionApiKeyByOrganizationIdApiResponse =
  /** status 200 OK */ OrganizationApiKeyModel
export type ProvisionApiKeyByOrganizationIdApiArg = {
  id: string
}
export type GetIntegrationsByOrganizationIdApiResponse =
  /** status 200 OK */ OrganizationIntegration[]
export type GetIntegrationsByOrganizationIdApiArg = {
  id: string
}
export type SaveOrganizationIntegrationApiResponse =
  /** status 200 OK */ OrganizationIntegration
export type SaveOrganizationIntegrationApiArg = {
  id: string
  organizationIntegration: OrganizationIntegration
}
export type GetOrganizationCustomersApiResponse =
  /** status 200 OK */ OrganizationModel[]
export type GetOrganizationCustomersApiArg = {
  id: string
  skipEnhanceData?: boolean
}
export type CreateFleetApiResponse = /** status 200 OK */ Organization
export type CreateFleetApiArg = {
  id: string
  organization: Organization
}
export type PublishToSnsApiResponse = unknown
export type PublishToSnsApiArg = {
  subEntity: 'TELEMETRY' | 'BASE' | 'INVALID'
  event:
    | 'NEW'
    | 'UPDATED'
    | 'DELETED'
    | 'TRIP_SUMMARY'
    | 'COMPLETED_ROUTE_SUMMARY'
    | 'CHARGING_SUMMARY'
    | 'INVALID'
  body: object
}
export type AssignVehicleToBillingOrganizationApiResponse =
  /** status 200 OK */ VehicleModel
export type AssignVehicleToBillingOrganizationApiArg = {
  id: string
  vehicleId: string
}
export type SaveNotificationMappingForAlarmsApiResponse = unknown
export type SaveNotificationMappingForAlarmsApiArg = {
  body: AlarmNotificationMapping[]
}
export type DeleteNotificationMappingForAlarmsApiResponse = unknown
export type DeleteNotificationMappingForAlarmsApiArg = {
  alarmNotificationMapping: AlarmNotificationMapping
}
export type GetVehicleApiResponse = /** status 200 OK */ VehicleModel
export type GetVehicleApiArg = {
  id: string
}
export type GetLatestVehicleWeatherForecastApiResponse =
  /** status 200 OK */ VehicleWeatherForecast
export type GetLatestVehicleWeatherForecastApiArg = {
  id: string
}
export type GetTripsApiResponse = /** status 200 OK */ Trip[]
export type GetTripsApiArg = {
  id: string
  from?: string
  to?: string
  source?: string
}
export type GetTripApiResponse = /** status 200 OK */ Trip
export type GetTripApiArg = {
  id: string
  tripId: string
}
export type GetVehicleTelematicsApiResponse =
  /** status 200 OK */ AssetTelematics[]
export type GetVehicleTelematicsApiArg = {
  id: string
  from?: string
  to?: string
}
export type GetDailySummaryForVehicleApiResponse =
  /** status 200 OK */ DailySummaryMetrics
export type GetDailySummaryForVehicleApiArg = {
  id: string
  date: string
  isCurrentDate: boolean
}
export type GetServiceableRangeForVehicleApiResponse =
  /** status 200 OK */ AzureServiceableRangeResponse
export type GetServiceableRangeForVehicleApiArg = {
  id: string
}
export type GetMetricsForVehicleApiResponse =
  /** status 200 OK */ VehicleMetricsModel
export type GetMetricsForVehicleApiArg = {
  id: string
  date: string
}
export type GetAssetEventsApiResponse = /** status 200 OK */ VehicleEvent[]
export type GetAssetEventsApiArg = {
  id: string
  type?: string
  from?: string
  to?: string
}
export type GetVehicleStatusBreakdownForVehiclesApiResponse =
  /** status 200 OK */ TimeUtilization
export type GetVehicleStatusBreakdownForVehiclesApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetTimespanDataForVehiclesApiResponse =
  /** status 200 OK */ VehicleMetrics[]
export type GetTimespanDataForVehiclesApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetVehiclesBySiteApiResponse = /** status 200 OK */ VehicleModel[]
export type GetVehiclesBySiteApiArg = {
  id: string
}
export type GetVehicleReportApiResponse =
  /** status 200 OK */ ReportGenerationStatus
export type GetVehicleReportApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetMetricsForVehiclesApiResponse =
  /** status 200 OK */ VehicleMetricsModel
export type GetMetricsForVehiclesApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetEnergyBreakdownDataForVehiclesApiResponse =
  /** status 200 OK */ EnergyUseBreakdown[]
export type GetEnergyBreakdownDataForVehiclesApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetMetricsForVehicles1ApiResponse =
  /** status 200 OK */ VehicleMetricsModel
export type GetMetricsForVehicles1ApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetUserApiResponse = /** status 200 OK */ UserModel
export type GetUserApiArg = {
  id: string
  skipEnhanceUserData?: boolean
}
export type GetUserTagMappingsApiResponse = /** status 200 OK */ {
  [key: string]: string
}
export type GetUserTagMappingsApiArg = {
  ocppTags?: string[]
}
export type GetDropdownOptionsApiResponse = /** status 200 OK */ OptionValue[]
export type GetDropdownOptionsApiArg = void
export type GetTripNotesApiResponse = /** status 200 OK */ TripNote[]
export type GetTripNotesApiArg = {
  id: string
}
export type GetTripReportApiResponse =
  /** status 200 OK */ ReportGenerationStatus
export type GetTripReportApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetVehicleUtilizationApiResponse =
  /** status 200 OK */ VehicleMetricsModel
export type GetVehicleUtilizationApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetVehicleBreakdownApiResponse =
  /** status 200 OK */ OrganizationSummaryModel
export type GetVehicleBreakdownApiArg = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetRouteByIdApiResponse = /** status 200 OK */ Route
export type GetRouteByIdApiArg = {
  id: string
}
export type DeleteRouteApiResponse =
  /** status 200 OK */
  | '100 CONTINUE'
  | '101 SWITCHING_PROTOCOLS'
  | '102 PROCESSING'
  | '103 EARLY_HINTS'
  | '103 CHECKPOINT'
  | '200 OK'
  | '201 CREATED'
  | '202 ACCEPTED'
  | '203 NON_AUTHORITATIVE_INFORMATION'
  | '204 NO_CONTENT'
  | '205 RESET_CONTENT'
  | '206 PARTIAL_CONTENT'
  | '207 MULTI_STATUS'
  | '208 ALREADY_REPORTED'
  | '226 IM_USED'
  | '300 MULTIPLE_CHOICES'
  | '301 MOVED_PERMANENTLY'
  | '302 FOUND'
  | '302 MOVED_TEMPORARILY'
  | '303 SEE_OTHER'
  | '304 NOT_MODIFIED'
  | '305 USE_PROXY'
  | '307 TEMPORARY_REDIRECT'
  | '308 PERMANENT_REDIRECT'
  | '400 BAD_REQUEST'
  | '401 UNAUTHORIZED'
  | '402 PAYMENT_REQUIRED'
  | '403 FORBIDDEN'
  | '404 NOT_FOUND'
  | '405 METHOD_NOT_ALLOWED'
  | '406 NOT_ACCEPTABLE'
  | '407 PROXY_AUTHENTICATION_REQUIRED'
  | '408 REQUEST_TIMEOUT'
  | '409 CONFLICT'
  | '410 GONE'
  | '411 LENGTH_REQUIRED'
  | '412 PRECONDITION_FAILED'
  | '413 PAYLOAD_TOO_LARGE'
  | '413 REQUEST_ENTITY_TOO_LARGE'
  | '414 URI_TOO_LONG'
  | '414 REQUEST_URI_TOO_LONG'
  | '415 UNSUPPORTED_MEDIA_TYPE'
  | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
  | '417 EXPECTATION_FAILED'
  | '418 I_AM_A_TEAPOT'
  | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
  | '420 METHOD_FAILURE'
  | '421 DESTINATION_LOCKED'
  | '422 UNPROCESSABLE_ENTITY'
  | '423 LOCKED'
  | '424 FAILED_DEPENDENCY'
  | '425 TOO_EARLY'
  | '426 UPGRADE_REQUIRED'
  | '428 PRECONDITION_REQUIRED'
  | '429 TOO_MANY_REQUESTS'
  | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
  | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
  | '500 INTERNAL_SERVER_ERROR'
  | '501 NOT_IMPLEMENTED'
  | '502 BAD_GATEWAY'
  | '503 SERVICE_UNAVAILABLE'
  | '504 GATEWAY_TIMEOUT'
  | '505 HTTP_VERSION_NOT_SUPPORTED'
  | '506 VARIANT_ALSO_NEGOTIATES'
  | '507 INSUFFICIENT_STORAGE'
  | '508 LOOP_DETECTED'
  | '509 BANDWIDTH_LIMIT_EXCEEDED'
  | '510 NOT_EXTENDED'
  | '511 NETWORK_AUTHENTICATION_REQUIRED'
export type DeleteRouteApiArg = {
  id: string
}
export type GetRouteScheduleApiResponse =
  /** status 200 OK */ VehicleScheduleModel[]
export type GetRouteScheduleApiArg = {
  id: string
  from?: string
  to?: string
}
export type GetStatusOfGeneratedReportApiResponse =
  /** status 200 OK */ ReportGenerationStatus
export type GetStatusOfGeneratedReportApiArg = {
  id: string
}
export type GetOrganizationApiResponse = /** status 200 OK */ OrganizationModel
export type GetOrganizationApiArg = {
  id: string
}
export type GetVehiclesForOrganizationApiResponse =
  /** status 200 OK */ VehicleModel[]
export type GetVehiclesForOrganizationApiArg = {
  id: string
}
export type GetOrganizationUsersApiResponse = /** status 200 OK */ UserModel[]
export type GetOrganizationUsersApiArg = {
  id: string
  skipEnhanceData?: boolean
}
export type GetTrips1ApiResponse = /** status 200 OK */ Trip[]
export type GetTrips1ApiArg = {
  id: string
  from?: string
  to?: string
  vehicleIds?: string[]
  source?: string
}
export type GetOrganizationTermsApiResponse =
  /** status 200 OK */ OrganizationTerms[]
export type GetOrganizationTermsApiArg = {
  id: string
}
export type LoginToStripeApiResponse = /** status 200 OK */ StringResponseModel
export type LoginToStripeApiArg = {
  id: string
}
export type GetStripeIntentDetailApiResponse =
  /** status 200 OK */ StripeIntentModel
export type GetStripeIntentDetailApiArg = {
  id: string
}
export type GetRoutesForOrganizationApiResponse = /** status 200 OK */ Route[]
export type GetRoutesForOrganizationApiArg = {
  id: string
}
export type GetIntegrationsByOrganizationIdAndIntegrationTypeApiResponse =
  /** status 200 OK */ OrganizationIntegration[]
export type GetIntegrationsByOrganizationIdAndIntegrationTypeApiArg = {
  id: string
  integrationId: string
}
export type GetAllOcppTagsApiResponse = /** status 200 OK */ OcppTag[]
export type GetAllOcppTagsApiArg = {
  id: string
}
export type GetFleetsApiResponse = /** status 200 OK */ Fleet[]
export type GetFleetsApiArg = {
  id: string
}
export type GetAllOrganizationCustomersApiResponse =
  /** status 200 OK */ string[]
export type GetAllOrganizationCustomersApiArg = {
  id: string
  depth?: number
}
export type GetOrganizationConfigApiResponse =
  /** status 200 OK */ OrganizationConfig[]
export type GetOrganizationConfigApiArg = {
  id: string
}
export type GetOrganizationApiKeyByApiKeyApiResponse =
  /** status 200 OK */ OrganizationApiKeyModel
export type GetOrganizationApiKeyByApiKeyApiArg = {
  id: string
}
export type GetLocationOfInterestApiResponse =
  /** status 200 OK */ LocationOfInterestResponseModel
export type GetLocationOfInterestApiArg = {
  id: string
}
export type DeleteLocationOfInterestApiResponse = unknown
export type DeleteLocationOfInterestApiArg = {
  id: string
}
export type GetIntegrationsApiResponse = /** status 200 OK */ Integration[]
export type GetIntegrationsApiArg = void
export type GetFleetApiResponse = /** status 200 OK */ FleetModel
export type GetFleetApiArg = {
  fleetId: string
}
export type GetChartValuesApiResponse =
  /** status 200 OK */ ChartDataResponseModel
export type GetChartValuesApiArg = {
  id: string
  /** Defaults to last 14 days if from are not supplied. */
  from?: string
  /** Defaults to current time if to is not supplied. */
  to?: string
  attributes: string[]
}
export type GetAlarmsForFleetApiResponse = /** status 200 OK */ AlarmModel[]
export type GetAlarmsForFleetApiArg = {
  organizationId?: string
}
export type GetNotificationMappingForAlarmsApiResponse =
  /** status 200 OK */ AlarmNotificationMapping[]
export type GetNotificationMappingForAlarmsApiArg = {
  id: string
}
export type GetTriggeredAlarmsApiResponse =
  /** status 200 OK */ TriggeredAlarm[]
export type GetTriggeredAlarmsApiArg = {
  from?: string
  to?: string
}
export type GetChargeProfilesApiResponse =
  /** status 200 OK */ PagedResponseChargeProfileModel
export type GetChargeProfilesApiArg = {
  /** An identifier of a fleet by which to filter charging profiles. */
  fleetId?: string
  /** An identifier of a site (depot) by which to filter charging profiles. */
  depotId?: string
  /** An optional time-bound to filter charging profiles that start after the provided time.
    
     The filter is performed on the field effectiveFrom in the chargingProfile model, and is different from validFrom and validTo. */
  effectiveAfter?: string
  /** The amount of levels of child organizations to search and include charging profiles for. */
  orgDepth?: number
  /** An index used to retrieve the next page of charging profiles. */
  page?: number
  /** The number of charging profiles to be returned in a page. */
  count?: number
  'pageRequest.sort'?: Sort
}
export type UpdateChargeProfileApiResponse =
  /** status 200 OK */ ChargeProfileModel
export type UpdateChargeProfileApiArg = {
  force?: boolean
  fleetId?: string
  chargeProfileUpdateModel: ChargeProfileUpdateModel
}
export type SaveChargeProfileApiResponse =
  /** status 200 OK */ ChargeProfileModel
export type SaveChargeProfileApiArg = {
  fleetId?: string
  chargeProfileCreateModel: ChargeProfileCreateModel
}
export type ClearChargingProfileApiResponse = /** status 200 OK */ BooleanModel
export type ClearChargingProfileApiArg = {
  fleetId?: string
  chargeProfileActionRequest: ChargeProfileActionRequest
}
export type GetDepotsApiResponse = /** status 200 OK */ PagedResponseDepotModel
export type GetDepotsApiArg = {
  /** [DEPRECATED] An identifier of an organization (formerly 'fleet') by which to filter sites. NOTE: this param is deprecated. Please use `organizationId` instead */
  fleetId?: string
  /** An identifier of an organization by which to filter sites. */
  organizationId?: string
  /** Filter for sites that have been created since the provided UTC timestamp. */
  createdSince?: string
  /** Filter for sites that have been updated since the provided UTC timestamp. */
  updatedSince?: string
  /** An index used to retrieve the next page of sites. */
  page?: number
  /** The number of chargers to be returned in a page. */
  count?: number
  'pageRequest.sort'?: Sort
}
export type UpdateDepotApiResponse = /** status 200 OK */ DepotModel
export type UpdateDepotApiArg = {
  depotMetaDataUpdateModel: DepotMetaDataUpdateModel
}
export type CreateDepotApiResponse = /** status 200 OK */ DepotModel
export type CreateDepotApiArg = {
  depotCreationModel: DepotCreationModel
}
export type GetDepotEnergyLimitsApiResponse =
  /** status 200 OK */ DepotEnergyLimitsModel
export type GetDepotEnergyLimitsApiArg = {
  depotId: string
}
export type UpdateDepotEnergyLimitsApiResponse =
  /** status 200 OK */ DepotEnergyLimitsModel
export type UpdateDepotEnergyLimitsApiArg = {
  depotId: string
  depotEnergyLimitsUpdateModel: DepotEnergyLimitsUpdateModel
}
export type GetChargersApiResponse =
  /** status 200 OK */ PagedResponseChargerModel
export type GetChargersApiArg = {
  /** [DEPRECATED] An identifier of an organization (formerly 'fleet') by which to filter chargers. NOTE: this param is deprecated. Please use `organizationId` instead */
  fleetId?: string
  /** Identifier(s) of an organization (or organizations) by which to filter chargers. If passing multiple organization IDs, separate with commas. */
  organizationId?: string[]
  /** [DEPRECATED] An identifier of a site (formerly 'depot') by which to filter chargers. NOTE: this param is deprecated. Please use `siteIds` instead */
  depotId?: string
  /** Filter chargers by a list of provided site ids, if provided, the depotId parameter will be ignored */
  siteIds?: string[]
  /** Filter chargers with a power rating greater than or equal to the value provided */
  minimumPower?: number
  /** Filter for chargers that are available from the provided UTC timestamp. */
  availableFrom?: string
  /** Filter for chargers that are available to the provided UTC timestamp. */
  availableTo?: string
  /** Filter for chargers that have been created since the provided UTC timestamp. */
  createdSince?: string
  /** Filter for chargers that have been updated since the provided UTC timestamp. */
  updatedSince?: string
  /** Filter chargers by their charger status. Status values can be separated by a comma. */
  chargerStatus?: (
    | 'Operative'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  )[]
  /** Filter chargers by their operational status. */
  operationalStatus?: ('Operative' | 'Inoperative' | 'Unknown')[]
  /** Filter chargers by their connectivity status. */
  connectivityStatus?: 'Online' | 'Offline'
  /** Filter chargers on whether or not there are any connectors currently in the `Preparing` state. */
  hasConnectorsPreparing?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Charging` state. */
  hasConnectorsCharging?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Faulted` state. */
  hasConnectorsFaulted?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Unavailable` state. */
  hasConnectorsUnavailable?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Available` state. */
  hasConnectorsAvailable?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Finishing` state. */
  hasConnectorsFinishing?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `SuspendedEV` state. */
  hasConnectorsSuspendedEv?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `SuspendedEVSE` state. */
  hasConnectorsSuspendedEvse?: boolean
  /** Filter for chargers that have communicated with the OCPP server since the provided timestamp. */
  heardSince?: string
  /** Filter for chargers that have *not* communicated with the OCPP server since the provided timestamp. */
  notHeardSince?: string
  /** Search (case insensitive) for chargers by their chargerID, siteName, or chargerName. */
  searchString?: string
  /** Depth of 'child organizations' to include in the search for chargers. Pass 0 to exclude all child organizations, and only include chargers of the specific organization being queried with organizationId. */
  orgDepth?: number
  /** An index used to retrieve the next page of chargers. */
  page?: number
  /** The number of chargers to be returned in a page. */
  count?: number
  /** A value to sort your collection of chargers by */
  sortBy?:
    | 'depotId'
    | 'chargerName'
    | 'created'
    | 'updated'
    | 'lastHeartbeatTimestamp'
    | 'externalId'
    | 'diagnosticsTimestamp'
  /** The order in which to sort your collection of chargers by. */
  order?: 'ASC' | 'DESC'
  'pageRequest.sort'?: Sort
}
export type UpdateChargerApiResponse = /** status 200 OK */ ChargerDetailModel
export type UpdateChargerApiArg = {
  chargerUpdateModel: ChargerUpdateModel
}
export type CreateChargerApiResponse = /** status 200 OK */ ChargerDetailModel
export type CreateChargerApiArg = {
  chargerCreationModel: ChargerCreationModel
}
export type SetChargeProfileApiResponse = /** status 200 OK */ BooleanModel
export type SetChargeProfileApiArg = {
  fleetId?: string
  chargeProfileActionRequest: ChargeProfileActionRequest
}
export type CmsOcppTagsGetTagsApiResponse =
  /** status 200 OK */ PagedResponseTagResponseModel
export type CmsOcppTagsGetTagsApiArg = {
  /** An identifier of a fleet by which to filter OCPP tags by, use this to filter down to one organization. */
  organizationId?: string
  /** The amount of levels of child organizations to search and include when searching for OCPP tags. */
  orgDepth?: number
  /** An index used to retrieve the next page of OCPP tags. */
  page?: number
  /** The number OCPP tags to be returned in a page. */
  count?: number
  'pageRequest.sort'?: Sort
}
export type CmsOcppTagsAddTagsApiResponse =
  /** status 201 Created */ TagResponseModel[]
export type CmsOcppTagsAddTagsApiArg = {
  addTagsInputModel: AddTagsInputModel
}
export type StopChargingTransactionApiResponse =
  /** status 200 OK */ BooleanModel
export type StopChargingTransactionApiArg = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  chargingSessionId?: string
  chargingTransactionId?: string
}
export type StartChargingTransactionApiResponse =
  /** status 200 OK */ TransactionStartResponseModel
export type StartChargingTransactionApiArg = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  chargingTransactionRequest: ChargingTransactionRequest
}
export type ResetChargerApiResponse = /** status 200 OK */ BooleanModel
export type ResetChargerApiArg = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  /** Type of reset to do. A hard reset may lead to more downtime as a full power cycle is performed whereas a soft resetwill perform a software reset. */
  type?: string
}
export type CmsChargersGetConfigurationKeysApiResponse =
  /** status 200 OK */ ConfigurationKeyResponseModel
export type CmsChargersGetConfigurationKeysApiArg = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
}
export type CmsChargersSetConfigurationKeyApiResponse =
  /** status 200 OK */ ChangeConfigurationKeyResponseModel
export type CmsChargersSetConfigurationKeyApiArg = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  key: string
  value: string
}
export type GetChargingTransactionsApiResponse =
  /** status 200 Success */ PagedResponseTransactionSummaryModel
export type GetChargingTransactionsApiArg = {
  /** The identifier of a charger by which to filter charging transactions.
    
    NOTE: If a chargerId is passed, the depotId and organizationId params will be ignored. */
  chargerId?: string
  /** An identifier of a site (depot) by which to filter charging transactions. */
  depotId?: string
  /** An identifier of a organization by which to filter charging transactions. */
  organizationId?: string
  /** An identifier of an OCPP ID tag by which to filter charging transactions. */
  tagId?: string
  /** An optional lower time-bound to filter on the transaction's start_timestamp. */
  startTimeFrom?: string
  /** An optional upper time-bound to filter on the transaction's start_timestamp. */
  startTimeTo?: string
  /** An optional lower time-bound to filter on the transaction's stop_timestamp. */
  stopTimeFrom?: string
  /** An optional upper time-bound to filter on the transaction's stop_timestamp. */
  stopTimeTo?: string
  /** An optional upper time-bound to filter on the last time the transaction was updated in Synop.
    
    For example, a charger can go offline and backfill transactions at a later date, leading to a more recent updated_timestamp than stop_timestamp */
  updatedSince?: string
  /** An index used to retrieve the next page of charging transactions. */
  page?: number
  /** The number of charging transactions to be returned in a page. */
  count?: number
  /** An order direction of the charging transactions to be returned (uses start_timestamp) */
  order?: 'ASC' | 'DESC'
  'pageRequest.sort'?: Sort
}
export type GetTransactionByIdApiResponse =
  /** status 200 Success */ TransactionSummaryModel
export type GetTransactionByIdApiArg = {
  /** Identifier of the charging session. Cannot be empty. */
  id: string
}
export type GetTransactionMeterValuesApiResponse =
  /** status 200 Success */ TransactionMeterValuesModel[]
export type GetTransactionMeterValuesApiArg = {
  /** Identifier of the transaction. Cannot be empty. */
  id: string
}
export type CmsChargingTransactionsGetMeterValueIntervalDataApiResponse =
  /** status 200 OK */ JsonApiObjectMeterValueCollectionIntervalModelMeterValueCollectionMetaAttributeModel
export type CmsChargingTransactionsGetMeterValueIntervalDataApiArg = {
  /** Identifier of the transaction. Cannot be empty. */
  id: string
  /** A optional lower time-bound to filter on transaction-based meter value interval data from and including the provided UTC timestamp.
    If not provided, it will default to the first interval period that contains the start timestamp of the transaction.
    For example, with a 15 minute interval period, if the transaction start timestamp is 2020-01-01T12:07:00Z, the `from` timestamp will be rounded down and defaulted to 2020-01-01T12:00:00Z */
  from?: string
  /** An optional upper time-bound to filter on transaction-based meter value interval data ending at provided UTC timestamp.
     If not provided, it will default to the current timestamp of the request if a transaction is in progress, or to the stop timestamp of a transaction if it has completed.
    The `to` timestamp will be rounded up to the closest supported interval period to be inclusive of the interval in your request.
    For example, with a 15 minute interval period, if the `to` timestamp is 2020-01-01T12:07:00Z, the `to` timestamp will be rounded up to and defaulted to 2020-01-01T12:15:00Z */
  to?: string
  /** A time interval to group meter value data by. */
  intervalPeriod?: '15m'
  /** A list of measure names to include in the response. If not provided, all default measures will be included  */
  measureNames?: ('maxPowerImport' | 'meanPowerImport' | 'totalEnergyImport')[]
  /** A boolean to truncate the first and last intervals of the response, respectively, to the transaction's start and stop timestamps.
     If not provided, the response will include the full interval period from the 'from' timestamp to the last interval period of the 'to' timestamp.
    For example, if truncateToTxnBounds is true, and the transaction start timestamp is 2020-01-01T12:07:00Z and the stop timestamp is 2020-01-01T12:22:00Z,
     the response will include first interval period will be [2020-01-01T12:07:00Z, 2020-01-01T12:15:00Z] and the last interval will be [2020-01-01T12:15:00Z, 2020-01-01T12:22:00Z].
    If truncateToTxnBounds if false (the default behavior), the first interval period will be [2020-01-01T12:00:00Z, 2020-01-01T12:15:00Z] and the last interval will be [2020-01-01T12:15:00Z, 2020-01-01T12:30:00Z]. */
  truncateToTxnBounds?: boolean
}
export type GetPowerUtilizationApiResponse =
  /** status 200 OK */ PowerUtilizationModel
export type GetPowerUtilizationApiArg = {
  /** Lower time-bound for the time-series data (inclusive). */
  from?: string
  /** Upper time-bound for the time-series data (non-inclusive). */
  to?: string
  /** A time interval to group meter value data by (optional; default='1m') */
  intervalPeriod?: '1m' | '5m' | '15m'
  /** The ID of the organization over which to query utilization data. If not specified, the organization of the authenticated user will be used. */
  fleetId?: string
  /** Depth of 'child organizations' to include in the search for chargers. Pass 0 to exclude all child organizations, and only include chargers of the specific organization being queried with organizationId. */
  orgDepth?: number
}
export type GetChargingSummaryApiResponse =
  /** status 200 OK */ ChargingSummaryModel
export type GetChargingSummaryApiArg = {
  fleetId?: string
  depotIds?: string[]
  from: string
  to: string
  orgDepth?: number
}
export type GetTransactionsReportApiResponse =
  /** status 200 OK */ ReportGenerationStatusModel
export type GetTransactionsReportApiArg = {
  from: string
  to?: string
  organizationId?: string
  siteId?: string
  chargerId?: string
  orgDepth?: number
}
export type GetStatusOfGeneratedReport1ApiResponse =
  /** status 200 OK */ ReportGenerationStatusModel
export type GetStatusOfGeneratedReport1ApiArg = {
  id: string
}
export type GetChargeProfileApiResponse =
  /** status 200 OK */ ChargeProfileModel
export type GetChargeProfileApiArg = {
  /** Profile identifier. Cannot be empty. */
  profileId: string
  fleetId?: string
}
export type GetLiveUtilizationApiResponse = /** status 200 OK */ {
  [key: string]: LiveDepotUtilizationModel
}
export type GetLiveUtilizationApiArg = {
  /** Fleet identifier. Cannot be empty. */
  fleetId: string
  /** Depth of 'child fleets' to include in the search for depots. Pass 0 to exclude all child fleets, and only include the specific fleet being queried with fleetId. */
  orgDepth?: number
}
export type GetDepotApiResponse = /** status 200 OK */ DepotModel
export type GetDepotApiArg = {
  depotId: string
  include?: 'importKwhLast24h'[]
}
export type DeleteDepotApiResponse = /** status 200 OK */ BooleanModel
export type DeleteDepotApiArg = {
  depotId: string
}
export type GetDepotUtilizationApiResponse =
  /** status 200 OK */ PowerUtilizationModel
export type GetDepotUtilizationApiArg = {
  depotId: string
  /** Lower time-bound for the time-series data (inclusive). */
  from?: string
  /** Upper time-bound for the time-series data (non-inclusive). */
  to?: string
  /** A time interval to group meter value data by (optional; default='1m') */
  intervalPeriod?: '1m' | '5m' | '15m'
}
export type GetDepotUptimeApiResponse = /** status 200 OK */ UptimeResponse
export type GetDepotUptimeApiArg = {
  /** The identifier for the depot you wish to query */
  depotId: string
  from: string
  to: string
}
export type GetDepotStatusApiResponse =
  /** status 200 OK */ LiveDepotUtilizationModel
export type GetDepotStatusApiArg = {
  depotId: string
}
export type GetChargerApiResponse = /** status 200 OK */ ChargerDetailModel
export type GetChargerApiArg = {
  /** Identifier of the charger. Cannot be empty. */
  chargerId: string
}
export type DeleteChargerApiResponse = /** status 200 OK */ BooleanModel
export type DeleteChargerApiArg = {
  chargerId: string
}
export type GetChargerUtilizationTimelineApiResponse =
  /** status 200 OK */ PowerUtilizationModel
export type GetChargerUtilizationTimelineApiArg = {
  chargerId: string
  /** Lower time-bound for the time-series data (inclusive). */
  from?: string
  /** Upper time-bound for the time-series data (non-inclusive). */
  to?: string
  /** A time interval to group meter value data by (optional; default='1m') */
  intervalPeriod?: '1m' | '5m' | '15m'
}
export type GetChargerUptimeApiResponse = /** status 200 OK */ UptimeResponse
export type GetChargerUptimeApiArg = {
  /** The identifier for the charger you wish to query */
  chargerId: string
  from: string
  to: string
}
export type GetMappedChargerProfilesApiResponse =
  /** status 200 OK */ ChargeProfileModel[]
export type GetMappedChargerProfilesApiArg = {
  chargerId: string
  connectorId?: number
}
export type GetChargerFaultsApiResponse =
  /** status 200 OK */ ChargerFaultsModel
export type GetChargerFaultsApiArg = {
  chargerId: string
  from?: string
  to?: string
  page?: number
  count?: number
}
export type GetChargerConnectorsApiResponse =
  /** status 200 OK */ ConnectorModel[]
export type GetChargerConnectorsApiArg = {
  chargerId: string
}
export type GetCapabilitySetApiResponse =
  /** status 200 Success */ ChargerCapabilitySetResponseModel
export type GetCapabilitySetApiArg = {
  /** The identifier for the charger. */
  chargerId: string
}
export type CmsUtilizationGetUtilizationApiResponse =
  /** status 200 OK */ UtilizationResponseModel
export type CmsUtilizationGetUtilizationApiArg = {
  /** The identifier of an organization by which to filter data. */
  organizationId?: string
  /** Identifiers of sites belonging to particular organizations to filter utilization data by. */
  siteIds?: string[]
  /** The amount of levels of child organizations to search and include data for. */
  orgDepth?: number
}
export type GetChargersTableDataApiResponse =
  /** status 200 OK */ ChargersTimespanTableModel
export type GetChargersTableDataApiArg = {
  /** The identifier of an organization by which to filter data by. */
  organizationId?: string
  /** The amount of levels of child organizations to search and include data for. */
  orgDepth?: number
  /** Identifiers of sites belonging to particular organizations to filter dashboard table data by. */
  siteIds?: string[]
  /** A lower time-bound to filter on dashboard summary data starting from and including the provided UTC timestamp. */
  fromTs: string
  /** An optional upper time-bound to filter on dashboard summary data ending at including the provided UTC timestamp. If not provided, defaults to the current timestamp of the request. */
  toTs?: string
  /** An index used to retrieve the next page of charger summaries. */
  page?: number
  /** The number of chargers summaries to be returned in a page. */
  count?: number
  /** An order direction of the charger summary data to be returned, uses either the default of chargerName, or the parameter provided in sortBy) */
  order?: 'ASC' | 'DESC'
  'pageRequest.sort'?: Sort
  /** A parameter to sort charger table data by. */
  sortBy?: 'chargerName' | 'chargerId'
  /** Search for chargers by their name or ID (case insensitive). */
  searchString?: string
}
export type GetSummaryDataApiResponse =
  /** status 200 OK */ ChargersTimespanSummaryModel
export type GetSummaryDataApiArg = {
  /** The identifier of an organization by which to filter data by. */
  organizationId?: string
  /** The amount of levels of child organizations to search and include data for. */
  orgDepth?: number
  /** Identifiers of sites belonging to particular organizations to filter dashboard table data by. */
  siteIds?: string[]
  /** A lower time-bound to filter on dashboard summary data starting from and including the provided UTC timestamp. */
  fromTs: string
  /** An optional upper time-bound to filter on dashboard summary data ending at including the provided UTC timestamp. If not provided, defaults to the current timestamp of the request. */
  toTs?: string
}
export type CmsTimespanGetHistoricalStatusesApiResponse =
  /** status 200 OK */ HistoricalStatusSummaryModel
export type CmsTimespanGetHistoricalStatusesApiArg = {
  /** The identifier of an organization by which to filter historical statuses. */
  organizationId?: string
  /** A list of site identifiers by which to filter historical statuses. */
  siteIds?: string[]
  /** A list of charger identifiers by which to filter historical statuses.
    
    NOTE: If any charger IDs are passed, the siteIds and organizationId params will be ignored. */
  chargerIds?: string[]
  /** The number of levels of child organizations to search and include historical charger and connector status summary summary data for. */
  orgDepth?: number
  /** An inclusive lower time-bound to filter on status data. */
  fromTs: string
  /** An optional non-inclusive upper time-bound to filter on status data. If not provided, defaults to the current timestamp of the request, rounded down to the nearest 15 minutes. */
  toTs?: string
}
export type GetLatestStatusSummaryApiResponse =
  /** status 200 OK */ LatestStatusSummaryModel
export type GetLatestStatusSummaryApiArg = {
  /** An identifier of a fleet by which to filter charger statuses by, use this to filter down to one organization. */
  organizationId?: string
  /** An identifier of a site to filter charger and connector statuses by, use this to filter down to particular sites belonging to particular organizations. */
  siteIds?: string[]
  /** The amount of levels of child organizations to search and include when searching for latest charger and connector status statuses. */
  orgDepth?: number
  /** Whether to include charger-level breakdowns of connector statuses in the response (in the `connectorsByCharger` field). */
  includeConnectorsByCharger?: boolean
}
export type CmsChargerFaultsGetFaultsApiResponse =
  /** status 200 Success */ PagedResponseConnectorFaultModel
export type CmsChargerFaultsGetFaultsApiArg = {
  /** The identifiers of a set of chargers by which to filter charger faults.
    
    NOTE: If any charger IDs are passed, the siteIds and organizationId params will be ignored. */
  chargerIds?: string[]
  /** A list of site identifiers by which to filter charger faults. */
  siteIds?: string[]
  /** An identifier of a organization by which to filter charger faults. */
  organizationId?: string
  /** An optional lower time-bound to filter on the fault's timestamp. */
  occurredAfter?: string
  /** An optional upper time-bound to filter on the fault's timestamp. */
  occurredBefore?: string
  /** An optional string to filter by fuzzy-matching on charger names. */
  searchString?: string
  /** An index used to retrieve the next page of charger faults. */
  page: number
  /** The number of charger faults to be returned in a page. */
  count: number
  /** A value to sort charger faults by. */
  sortBy?:
    | 'chargerName'
    | 'currentStatus'
    | 'errorCode'
    | 'siteName'
    | 'timestamp'
    | 'vendor'
  /** A sort direction for the results. */
  order?: 'ASC' | 'DESC'
  'pageRequest.sort'?: Sort
}
export type GetManufacturersApiResponse =
  /** status 200 OK */ PagedResponseChargerManufacturerModel
export type GetManufacturersApiArg = {
  page?: number
  count?: number
}
export type GetManufacturerMakeModelsApiResponse =
  /** status 200 OK */ PagedResponseChargerMakeModelResponseModel
export type GetManufacturerMakeModelsApiArg = {
  /** The identifier for a charger make model, generated by Synop. */
  manufacturerId: string
  page?: number
  count?: number
}
export type GetDepotEventsByDepotIdApiResponse =
  /** status 200 Success */ DepotEventModel[]
export type GetDepotEventsByDepotIdApiArg = {
  depotId: string
  from?: string
  to?: string
}
export type UpdateDepotEventApiResponse = unknown
export type UpdateDepotEventApiArg = {
  depotId: string
  isRecurrence?: boolean
  depotEventModel: DepotEventModel
}
export type SaveDepotEventApiResponse = unknown
export type SaveDepotEventApiArg = {
  depotId: string
  depotEventModel: DepotEventModel
}
export type GetScheduledChargerSessionApiResponse =
  /** status 200 Success */ ChargerEventModel
export type GetScheduledChargerSessionApiArg = {
  chargerId: string
  eventId: string
}
export type UpdateScheduledChargerSessionApiResponse =
  /** status 200 Success */ ChargerEventModel
export type UpdateScheduledChargerSessionApiArg = {
  chargerId: string
  eventId: string
  isRecurrence?: boolean
  chargerEventUpdateModel: ChargerEventUpdateModel
}
export type CancelScheduledChargerSessionApiResponse =
  /** status 200 Success */ ChargerEventModel
export type CancelScheduledChargerSessionApiArg = {
  chargerId: string
  eventId: string
  isRecurrence?: boolean
}
export type GetLoadBalancerEventsApiResponse =
  /** status 200 Success */ PagedResponseLoadBalancerEventModel
export type GetLoadBalancerEventsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number
  /** The size of the page to be returned */
  size?: number
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[]
}
export type CreateLoadBalancerEventApiResponse =
  /** status 200 Success */ LoadBalancerEventModel
export type CreateLoadBalancerEventApiArg = {
  origin?: string
  eventType?:
    | 'DEPOT_EVENT'
    | 'CHARGER_EVENT'
    | 'SCHEDULED_EVENT_START'
    | 'HEARTBEAT'
    | 'AFTER_START'
    | 'AFTER_STOP'
    | 'EMS_ON'
    | 'EMS_OFF'
    | 'SITE_LIMIT'
  chargerId?: string
  fleetId?: string
  depotId?: string
  originTime?: string
  executionTime?: string
}
export type GetScheduledChargerSessionsApiResponse =
  /** status 200 Success */ ChargerEventModel[]
export type GetScheduledChargerSessionsApiArg = {
  chargerId: string
  from?: string
  to?: string
  type?: string
  page?: number
  count?: number
}
export type CreateScheduledChargerSessionApiResponse =
  /** status 200 Success */ BooleanModel
export type CreateScheduledChargerSessionApiArg = {
  chargerId: string
  scheduleRequestModel: ScheduleRequestModel
}
export type GetScheduledChargerSessionDetailsApiResponse =
  /** status 200 Success */ ChargerEventSessionModel
export type GetScheduledChargerSessionDetailsApiArg = {
  chargerId: string
  eventId: string
}
export type SaveScheduledChargerSessionDetailsApiResponse =
  /** status 200 Success */ ChargerEventSessionModel
export type SaveScheduledChargerSessionDetailsApiArg = {
  chargerId: string
  eventId: string
  chargerEventSessionModel: ChargerEventSessionModel
}
export type GetLoadBalancerEventApiResponse =
  /** status 200 Success */ LoadBalancerEventModel
export type GetLoadBalancerEventApiArg = {
  id: string
}
export type GetSmartPricesApiResponse = /** status 200 Success */ Price
export type GetSmartPricesApiArg = {
  depotId: string
  from?: string
  to?: string
}
export type GetScheduledDepotPowerApiResponse =
  /** status 200 Success */ PowerTimeSeriesModel
export type GetScheduledDepotPowerApiArg = {
  depotId: string
  from: string
  to: string
}
export type GetDepotEventByIdApiResponse =
  /** status 200 Success */ DepotEventModel
export type GetDepotEventByIdApiArg = {
  depotId: string
  eventId: string
}
export type CancelDepotEventApiResponse = unknown
export type CancelDepotEventApiArg = {
  depotId: string
  eventId: string
  isRecurrence?: boolean
}
export type GetScheduledChargerSessionsByDepotApiResponse =
  /** status 200 Success */ ChargerEventModel[]
export type GetScheduledChargerSessionsByDepotApiArg = {
  depotId: string
  assetId?: string
  from?: string
  to?: string
  page?: number
  count?: number
}
export type GetScheduledChargerSessionTelemetryApiResponse =
  /** status 200 Success */ ChargerEventSessionTelemetryModel
export type GetScheduledChargerSessionTelemetryApiArg = {
  chargerId: string
  eventId: string
}
export type GetApiVehiclesByIdTimeToSocApiResponse =
  /** status 200 Success */ SourceAttributeValue
export type GetApiVehiclesByIdTimeToSocApiArg = {
  /** vehicle id */
  id: string
  /** target soc in percentage */
  targetSoc: number
  /** power offered from charger in Watts */
  powerOffered: number
}
export type GetApiTransactionsTimeToSocApiResponse =
  /** status 200 Success */ SourceAttributeValue
export type GetApiTransactionsTimeToSocApiArg = {
  /** ocpp tag */
  ocppTag: string
  /** target soc in percentage */
  targetSoc: number
  /** power offered from charger in Watts */
  powerOffered: number
}
export type AcknowledgeSentNotificationApiResponse = unknown
export type AcknowledgeSentNotificationApiArg = {
  acknowledgeNotificationsModel: AcknowledgeNotificationsModel
}
export type GetAlertsByOrganizationIdApiResponse =
  /** status 200 OK */ AlertConfigurationModel[]
export type GetAlertsByOrganizationIdApiArg = {
  organizationId: string
}
export type UpdateAlertByOrganizationsApiResponse = unknown
export type UpdateAlertByOrganizationsApiArg = {
  alertConfigurationModel: AlertConfigurationModel
}
export type CreateAlertByOrganizationsApiResponse = unknown
export type CreateAlertByOrganizationsApiArg = {
  createAlertConfigurationModel: CreateAlertConfigurationModel
}
export type GetNotificationsApiResponse =
  /** status 200 OK */ NotificationModel[]
export type GetNotificationsApiArg = {
  userId: string
}
export type GetNotificationByIdApiResponse =
  /** status 200 OK */ NotificationModel
export type GetNotificationByIdApiArg = {
  id: string
}
export type GetAlertByAlertIdApiResponse =
  /** status 200 OK */ AlertConfigurationModel
export type GetAlertByAlertIdApiArg = {
  id: string
}
export type GetPropertiesApiResponse = /** status 200 OK */ PropertyModel[]
export type GetPropertiesApiArg = void
export type GetWorkplaceUserApiResponse =
  /** status 200 Workplace user retrieved. */ WorkplaceChargingUserModel
export type GetWorkplaceUserApiArg = {
  workplaceUserId: string
}
export type UpdateWorkplaceUserApiResponse =
  /** status 200 Workplace Users updated. */ WorkplaceChargingUserModel
export type UpdateWorkplaceUserApiArg = {
  workplaceUserId: string
  workplaceChargingUserModel: WorkplaceChargingUserModel
}
export type DeleteWorkplaceUserApiResponse =
  /** status 200 Workplace user deleted. */ boolean
export type DeleteWorkplaceUserApiArg = {
  workplaceUserId: string
}
export type GetWorkplaceChargingRateByIdApiResponse =
  /** status 200 Workplace charging rates retrieved. */ WorkplaceChargingRateModel
export type GetWorkplaceChargingRateByIdApiArg = {
  organizationId?: string
  rateMappingId: string
}
export type UpdateWorkplaceChargingRatesApiResponse =
  /** status 200 Workplace charging rates updated. */ WorkplaceChargingRateModel
export type UpdateWorkplaceChargingRatesApiArg = {
  rateMappingId: string
  updateWorkplaceChargingRate: UpdateWorkplaceChargingRate
}
export type SaveTariffApiResponse = unknown
export type SaveTariffApiArg = {
  partyId: string
  tariffId: string
  tariff: Tariff
}
export type GetWorkplaceUsersApiResponse =
  /** status 200 Workplace users retrieved. */ WorkplaceChargingUserModel[]
export type GetWorkplaceUsersApiArg = {
  organizationId?: string
}
export type SaveWorkplaceUserApiResponse =
  /** status 200 Workplace Users configured. */ WorkplaceChargingUserModel
export type SaveWorkplaceUserApiArg = {
  createWorkplaceChargingUser: CreateWorkplaceChargingUser
}
export type GetWorkplaceChargingRatesApiResponse =
  /** status 200 Workplace charging rates retrieved. */ WorkplaceChargingRateModelRateStructureModelListRatePeriod[]
export type GetWorkplaceChargingRatesApiArg = {
  organizationId?: string
}
export type CreateWorkplaceChargingRatesApiResponse =
  /** status 200 Workplace charging rates created. */ WorkplaceChargingRateModel
export type CreateWorkplaceChargingRatesApiArg = {
  createWorkplaceChargingRate: CreateWorkplaceChargingRate
}
export type OverrideWorkplaceChargingRateApiResponse =
  /** status 200 Workplace charging rates retrieved. */ OverrideRateStructureModel
export type OverrideWorkplaceChargingRateApiArg = {
  rateMappingId: string
  overrideRateStructureModel: OverrideRateStructureModel
}
export type GetWorkplaceChargingConfigurationApiResponse =
  /** status 200 Workplace Charging retrieved. */ WorkplaceConfiguration
export type GetWorkplaceChargingConfigurationApiArg = {
  organizationId?: string
}
export type SaveWorkplaceChargingConfigurationApiResponse =
  /** status 200 Workplace Charging configured. */ WorkplaceConfiguration
export type SaveWorkplaceChargingConfigurationApiArg = {
  workplaceConfiguration: WorkplaceConfiguration
}
export type UpdateWorkplaceChargingRecordStatusesApiResponse =
  /** status 200 OK */ UpdateWorkplaceChargingRecordStatus
export type UpdateWorkplaceChargingRecordStatusesApiArg = {
  organizationId?: string
  updateWorkplaceChargingRecordStatus: UpdateWorkplaceChargingRecordStatus
}
export type ProcessStripeEventApiResponse = unknown
export type ProcessStripeEventApiArg = void
export type GetReimbursementUsersApiResponse =
  /** status 200 Reimbursement users retrieved. */ ReimbursementUserModel[]
export type GetReimbursementUsersApiArg = {
  organizationId?: string
}
export type SaveReimbursementUserApiResponse =
  /** status 200 Reimbursement configured. */ ReimbursementUser
export type SaveReimbursementUserApiArg = {
  reimbursementUserModel: ReimbursementUserModel
}
export type GetReimbursementConfigurationApiResponse =
  /** status 200 Reimbursement configured. */ ReimbursementConfiguration
export type GetReimbursementConfigurationApiArg = {
  organizationId?: string
}
export type SaveReimbursementConfigurationApiResponse =
  /** status 200 Reimbursement configured. */ ReimbursementConfiguration
export type SaveReimbursementConfigurationApiArg = {
  reimbursementConfiguration: ReimbursementConfiguration
}
export type BulkUpdateReimbursementUsersApiResponse =
  /** status 200 User rate configurations updated. */ BulkReimbursementConfiguration
export type BulkUpdateReimbursementUsersApiArg = {
  bulkReimbursementConfiguration: BulkReimbursementConfiguration
}
export type GetWorkplaceChargingUsageApiResponse =
  /** status 200 Workplace charging usage retrieved. */ ChargeRecordModelWorkplaceChargingUserOrganization[]
export type GetWorkplaceChargingUsageApiArg = {
  billingMonth?: string
  organizationId?: string
}
export type GetWorkplaceSummaryApiResponse =
  /** status 200 Workplace Charging summary retrieved. */ WorkplaceChargingSummaryModel
export type GetWorkplaceSummaryApiArg = {
  billingMonth?: string
  organizationId?: string
}
export type GetWorkplaceChargingRecordsApiResponse =
  /** status 200 OK */ WorkplaceChargingModel[]
export type GetWorkplaceChargingRecordsApiArg = {
  from: string
  to: string
  organizationId?: string
}
export type GetStripeIntentDetail1ApiResponse =
  /** status 200 OK */ StripeIntentModel1
export type GetStripeIntentDetail1ApiArg = {
  id: string
}
export type GetPaymentMethodApiResponse =
  /** status 200 OK */ BillingDetailsModel
export type GetPaymentMethodApiArg = {
  id: string
}
export type GetPortalLoginApiResponse = /** status 200 OK */ StripeRedirectModel
export type GetPortalLoginApiArg = {
  id: string
}
export type GetUserInvoicesApiResponse =
  /** status 200 OK */ StripeRedirectModel
export type GetUserInvoicesApiArg = {
  id: string
}
export type GetCheckoutSessionApiResponse =
  /** status 200 OK */ StripeRedirectModel
export type GetCheckoutSessionApiArg = {
  id: string
}
export type GetTariffsApiResponse = unknown
export type GetTariffsApiArg = {
  from: string
  to: string
  offset?: number
  limit?: number
}
export type GetReimbursementsApiResponse =
  /** status 200 OK */ ReimbursementsModelObject[]
export type GetReimbursementsApiArg = {
  from: string
  to: string
  organizationId?: string
}
export type GetReimbursementUserApiResponse =
  /** status 200 Reimbursement configured. */ ReimbursementUserModel
export type GetReimbursementUserApiArg = {
  reimbursementUserId: string
}
export type DeleteReimbursementUserApiResponse =
  /** status 200 Reimbursement user deleted. */ boolean
export type DeleteReimbursementUserApiArg = {
  reimbursementUserId: string
}
export type GetReimbursementUsageSummaryApiResponse =
  /** status 200 Reimbursement usage retrieved. */ ChargeRecordModelOrganizationReimbursementUser[]
export type GetReimbursementUsageSummaryApiArg = {
  billingMonth?: string
  organizationId?: string
}
export type GetReimbursementSummaryApiResponse =
  /** status 200 Reimbursement summary retrieved. */ ReimbursementSummaryModel
export type GetReimbursementSummaryApiArg = {
  billingMonth?: string
  organizationId?: string
}
export type GetReimbursements1ApiResponse =
  /** status 200 OK */ ReimbursementsModelObject[]
export type GetReimbursements1ApiArg = {
  id: string
  from: string
  to: string
}
export type GetPortalApiResponse = /** status 200 OK */ Session
export type GetPortalApiArg = {
  id: string
}
export type GetLoginSessionApiResponse = /** status 200 OK */ LoginLink
export type GetLoginSessionApiArg = {
  id: string
}
export type GetOrganizationBalanceApiResponse = /** status 200 OK */ Balance
export type GetOrganizationBalanceApiArg = {
  id: string
}
export type ListSimulatorsApiResponse =
  /** status 200 OK */ SimulatorDetailModel[]
export type ListSimulatorsApiArg = void
export type CreateSimulatorApiResponse =
  /** status 200 OK */ SimulatorDetailModel
export type CreateSimulatorApiArg = {
  simulatorCreationModel: SimulatorCreationModel
}
export type GetContainerApiResponse = /** status 200 OK */ SimulatorDetailModel
export type GetContainerApiArg = {
  id: string
}
export type DeleteContainerApiResponse = unknown
export type DeleteContainerApiArg = {
  id: string
}
export type PingApiResponse = /** status 200 OK */ PingPong
export type PingApiArg = void
export type SimulatorProxyRequestApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequestApiArg = {
  body: string
  path: string
  id: string
}
export type SimulatorProxyRequest3ApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequest3ApiArg = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest2ApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequest2ApiArg = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest5ApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequest5ApiArg = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest6ApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequest6ApiArg = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest1ApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequest1ApiArg = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest4ApiResponse = /** status 200 OK */ string
export type SimulatorProxyRequest4ApiArg = {
  path: string
  id: string
  body: string
}
export type LocationOfInterestModel = {
  id?: string
  type?: string
}
export type SourceAttributeValueBigDecimal = {
  units?: string
  lastUpdated?: number
  source?: string
  value?: number
}
export type SourceAttributeValueString = {
  units?: string
  lastUpdated?: number
  source?: string
  value?: string
}
export type CurrentLocation = {
  locationOfInterestModels?: LocationOfInterestModel[]
  bearing?: SourceAttributeValueBigDecimal
  cardinalDirection?: SourceAttributeValueString
  street?: SourceAttributeValueString
}
export type Subscription = {
  isSubscriptionActive?: boolean
  subscriptionType?: string
  subscriptionTerm?: string
  startDate?: string
  endDate?: string
  autoRenewal?: boolean
}
export type Fault = {
  code?: string
  name?: string
  description?: string
  latitude?: number
  longitude?: number
  sourceAddress?: string
  parameterIdentifier?: string
  failureModeIndicator?: string
  occurrenceCount?: number
  lastUpdated?: number
  provider?: string
  faultType?: number
  mil?: string[]
}
export type VehicleModel = {
  id: string
  vehicleNm?: string
  vin?: string
  batterySize?: number
  model?: string
  make?: string
  modelYear?: number
  vehicleType?: string
  vehicleClass?: number
  estimatedRange?: number
  macAddress?: string
  active?: boolean
  logicalDeleteIn?: boolean
  providerId?: string
  vehicleToGridCapable?: boolean
  efficiency?: number
  lifetimeEfficiency?: number
  homeSiteId?: string
  fleetId?: string
  fleetNm?: string
  organizationId?: string
  organizationNm?: string
  billingOrganizationId?: string
  currentLocation?: CurrentLocation
  vehicleStatus?: SourceAttributeValueString
  latitude?: SourceAttributeValueBigDecimal
  longitude?: SourceAttributeValueBigDecimal
  remainingChargeTime?: SourceAttributeValueBigDecimal
  remainingRange?: SourceAttributeValueBigDecimal
  stateOfCharge?: SourceAttributeValueBigDecimal
  odometer?: SourceAttributeValueBigDecimal
  speed?: SourceAttributeValueBigDecimal
  batteryPotential?: SourceAttributeValueBigDecimal
  distanceUntilHome?: SourceAttributeValueBigDecimal
  timeUntilHome?: SourceAttributeValueBigDecimal
  engineOnLifetime?: SourceAttributeValueBigDecimal
  drivingTimeLifetime?: SourceAttributeValueBigDecimal
  eptoUseLifetime?: SourceAttributeValueBigDecimal
  batteryDischargeLifetime?: SourceAttributeValueBigDecimal
  batteryRegenLifetime?: SourceAttributeValueBigDecimal
  tracInverterMotorLifetime?: SourceAttributeValueBigDecimal
  tracInverterRegenLifetime?: SourceAttributeValueBigDecimal
  batteryManagementLifetime?: SourceAttributeValueBigDecimal
  dcdcLifetime?: SourceAttributeValueBigDecimal
  hvacLifetime?: SourceAttributeValueBigDecimal
  chargerPowerSupplied?: SourceAttributeValueBigDecimal
  subscriptions?: Subscription[]
  faults?: Fault[]
  telematicsTimestamp?: string
  telematicsTimestampEpochMillisecond?: number
  ambientAirTemperature?: SourceAttributeValueBigDecimal
  inDepot?: boolean
}
export type CreateVehicleModel = {
  vehicleNm?: string
  vin?: string
  batterySize?: number
  model?: string
  make?: string
  modelYear?: number
  estimatedRange?: number
  macAddress?: string
  homeSiteId?: string
  efficiency?: number
  organizationId?: string
}
export type MfaOptionType = object
export type UserTag = {
  created?: string
  updated?: string
  userId?: string
  ocppTagValue?: string
  ocppTagUseCase:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'REIMBURSEMENTS'
    | 'OTHER'
  ocppTagType: 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER'
}
export type UserModel = {
  id?: string
  name?: string
  email?: string
  phoneNumber?: string
  organizationId?: string
  status?: string
  mfaEnabled?: boolean
  isActive?: boolean
  preferredDistanceUnits?: string
  preferredTemperatureUnits?: string
  preferredEfficiencyUnits?: string
  preferredTimeFormat?: string
  preferredDateFormat?: string
  preferredTimeZone?: string
  receiveEmailNotifications?: boolean
  receiveSMSNotifications?: boolean
  roles?: string[]
  sites?: string[]
  mfaOptionTypes?: MfaOptionType[]
  termsAccepted?: boolean
  termsAcceptedDt?: string
  rfidTags?: UserTag[]
  mfaSetupStatus?: 'COMPLETED' | 'INCOMPLETE' | 'NOT_APPLICABLE'
}
export type RouteComponent = {
  created?: string
  updated?: string
  id?: string
  routeId?: string
  routeComponentNm?: string
  isSite?: boolean
  siteId?: string
  precedence?: number
  latitude?: number
  longitude?: number
  componentType?: string
  componentDesc?: string
  distance?: number
  duration?: number
  bearingBefore?: number
  bearingAfter?: number
}
export type Route = {
  id?: string
  routeNm?: string
  routeType?: string
  distance?: number
  distanceUnits?: string
  duration?: number
  durationUnits?: string
  organizationId?: string
  fleetId?: string
  components?: RouteComponent[]
}
export type Organization = {
  created?: string
  updated?: string
  id?: string
  organizationNm?: string
  organizationType?: string
  logoUrl?: string
  styleOverrides?: string
  hasOpsCenter?: boolean
  hasReporting?: boolean
  hasBilling?: boolean
  hasAlarms?: boolean
  mfaStatus?: string
  connectedAccountId?: string
  connectedBillingEnabled?: boolean
  customerId?: string
  setupIntentId?: string
  hasCompeletedOnboarding?: boolean
  hasMarketplace?: boolean
  hasEnergyManagement?: boolean
  hasChargeManagement?: boolean
  hasFleetManagement?: boolean
  hasReimbursements?: boolean
  reimbursementsStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  hasWorkplaceCharging?: boolean
  workplaceChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  connectedAccountStatus?:
    | 'RESTRICTED'
    | 'RESTRICTED_SOON'
    | 'PENDING'
    | 'ENABLED'
    | 'COMPLETE'
    | 'REJECTED'
    | 'UNKNOWN'
  loginUrl?: string
  active?: boolean
}
export type OrganizationTerms = {
  created?: string
  updated?: string
  id?: string
  organizationId?: string
  documentTitle?: string
  effectiveDt?: string
  terms?: string
}
export type LocationOfInterestResponseModel = {
  loiNm: string
  loiType: 'Maintenance' | 'Port' | 'Residence' | 'Other'
  address: string
  latitude: number
  longitude: number
  organizationId: string
  geofence: string
  id: string
}
export type LocationOfInterestUpdateModel = {
  id: string
  loiNm: string
  loiType: 'Maintenance' | 'Port' | 'Residence' | 'Other'
  address: string
  latitude: number
  longitude: number
  organizationId: string
  geofence: string
}
export type LocationOfInterestCreateModel = {
  loiNm: string
  loiType: 'Maintenance' | 'Port' | 'Residence' | 'Other'
  address: string
  latitude: number
  longitude: number
  organizationId: string
  geofence: string
}
export type FleetModel = {
  fleetNm?: string
  fleetType?: 'DEFAULT' | 'CUSTOM'
  organizationId: string
  active?: boolean
  id: string
}
export type FleetCreateModel = {
  fleetNm?: string
  fleetType?: 'DEFAULT' | 'CUSTOM'
  organizationId: string
  active?: boolean
}
export type Recipient = {
  deliveryType?: string
  deliveryValue?: string
  userId?: string
  alarmId?: string
}
export type Alarm = {
  created?: string
  updated?: string
  id?: string
  defaultAlarmId?: string
  alarmDesc?: string
  alarmMessage?: string
  alarmNm?: string
  cron?: string
  definition?: string
  entityType?: string
  entityValue?: string
  eventBridgeArn?: string
  organizationId?: string
  nextAlarmDelaySeconds?: number
  nextAlarmId?: string
  active?: boolean
}
export type AlarmModel = {
  id: string
  alarmNm?: string
  alarmDesc?: string
  notificationText?: string
  isEnabled?: boolean
  recipients?: Recipient[]
  alarms?: Alarm[]
}
export type ExternalVehicleModel = {
  vehicleName?: string
  vin?: string
  purchaseDate?: string
  batteryCapacity?: number
  estimatedRange?: number
  model?: string
  make?: string
  year?: number
  serialNumber?: string
  deviceId?: string
  deviceName?: string
  activeDateTime?: string
  expirationDateTime?: string
  userVin?: string
  siteId?: string
  fleetId?: string
  mpgEstimate?: number
  conventionalFuelType?: string
}
export type VehicleEvent = {
  created?: string
  updated?: string
  id?: string
  vehicleId?: string
  eventType?: string
  scheduledStart?: string
  scheduledEnd?: string
  status?: string
  eventTargetNm?: string
  routeId?: string
  actorId?: string
}
export type MfaModel = {
  secretCode: string
}
export type TripNote = {
  created?: string
  updated?: string
  id?: string
  tripId?: string
  fromUserId?: string
  content?: string
  senderName?: string
}
export type StringResponseModel = {
  value?: string
}
export type UrlRedirectModel = {
  baseUrl?: string
  path?: string
  parameters?: string
}
export type OrganizationApiKeyModel = {
  organizationId: string
  apiKey: string
  active?: boolean
  usagePlanId?: string
  created?: string
  updated?: string
}
export type OrganizationIntegration = {
  created?: string
  updated?: string
  id?: string
  organizationId?: string
  integrationId?: string
  externalId?: string
  autoOnboard?: boolean
  ingestVehicles?: boolean
  ingestTelemetry?: boolean
  active?: boolean
  metadata?: string
}
export type OrganizationModel = {
  id: string
  organizationNm?: string
  organizationType?: string
  logoUrl?: string
  styleOverrides?: string
  hasOpsCenter?: boolean
  hasReporting?: boolean
  hasBilling?: boolean
  hasAlarms?: boolean
  connectedAccountId?: string
  connectedBillingEnabled?: boolean
  customerId?: string
  setupIntentId?: string
  hasCompletedOnboarding?: boolean
  hasMarketplace?: boolean
  hasEnergyManagement?: boolean
  hasChargeManagement?: boolean
  hasFleetManagement?: boolean
  hasReimbursements?: boolean
  reimbursementsStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  hasWorkplaceCharging?: boolean
  workplaceChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  connectedAccountStatus?:
    | 'RESTRICTED'
    | 'RESTRICTED_SOON'
    | 'PENDING'
    | 'ENABLED'
    | 'COMPLETE'
    | 'REJECTED'
    | 'UNKNOWN'
  loginUrl?: string
  active?: boolean
  fleetsCount?: number
  chargersCount?: number
  vehiclesCount?: number
  usersCount?: number
  mfaStatus?: 'OFF' | 'ON_REQUIRED' | 'ON_NOT_REQUIRED'
}
export type AlarmNotificationMapping = {
  created?: string
  updated?: string
  id?: string
  alarmId?: string
  notificationType?: string
  notificationValue?: string
  userId?: string
  active?: boolean
}
export type WeatherForecast = {
  ambientAirTemperature?: SourceAttributeValueBigDecimal
  humidity?: SourceAttributeValueBigDecimal
  pressure?: SourceAttributeValueBigDecimal
  windSpeed?: SourceAttributeValueBigDecimal
  windDirectionDegrees?: SourceAttributeValueBigDecimal
  windGust?: SourceAttributeValueBigDecimal
  firstWeatherConditionId?: SourceAttributeValueBigDecimal
  firstWeatherParameter?: SourceAttributeValueString
  secondWeatherConditionId?: SourceAttributeValueBigDecimal
  secondWeatherParameter?: SourceAttributeValueString
  thirdWeatherConditionId?: SourceAttributeValueBigDecimal
  thirdWeatherParameter?: SourceAttributeValueString
  cloudiness?: SourceAttributeValueBigDecimal
  visibility?: SourceAttributeValueBigDecimal
  partOfDay?: SourceAttributeValueString
  precipitationProbability?: SourceAttributeValueBigDecimal
}
export type VehicleWeatherForecast = {
  current?: WeatherForecast
  oneHourLead?: WeatherForecast
  twoHourLead?: WeatherForecast
  threeHourLead?: WeatherForecast
  fourHourLead?: WeatherForecast
  fiveHourLead?: WeatherForecast
}
export type VehicleFault = {
  created?: string
  updated?: string
  code?: string
  vehicleId?: string
  sourceAddress?: string
  parameterIdentifier?: string
  failureModeIndicator?: string
  occurrenceCount?: number
  name?: string
  description?: string
  latitude?: number
  longitude?: number
  provider?: string
}
export type Trip = {
  created?: string
  updated?: string
  id?: string
  tripStatus?: string
  vehicleId?: string
  fleetId?: string
  averageSpeed?: number
  speedUnit?: string
  distance?: number
  distanceUnit?: string
  emissionsSave?: number
  emissionsUnit?: string
  routeId?: string
  routeStart?: string
  routeEnd?: string
  efficiency?: number
  efficiencyUnit?: string
  startSoc?: number
  endSoc?: number
  startLatitude?: number
  endLatitude?: number
  startLongitude?: number
  endLongitude?: number
  driverId?: string
  originNm?: string
  destinationNm?: string
  source?: string
  energyUsed?: number
  faults?: VehicleFault[]
}
export type AssetTelematics = {
  assetId?: string
  stateOfCharge?: number
  telematicsTimestamp?: string
  latitude?: number
  longitude?: number
  speed?: number
  speedUnit?: string
  odometer?: number
  odometerUnit?: string
  ambientAirTemperatureDegc?: number
  hvacLifetimeWh?: number
  batteryRegenLifetimeWh?: number
  batteryDischargeLifetimeWh?: number
  tracInverterRegenLifetimeWh?: number
  tracInverterMotorLifetimeWh?: number
  batteryManagementLifetimeWh?: number
  dcdcLifetimeWh?: number
  batteryPotential?: number
  keySwitchState?: string
  gearSelect?: string
  evseState?: string
  evsePowerKw?: number
  chargeTimeEstimateMin?: number
  gpsHdop?: number
  rangeRemainingEstimateKm?: number
  keyOnTimeHr?: number
  drivingTimeHr?: number
  vehicleStatus?: string
  date?: string
  milesDriven?: number
  totalEnergyConsumedKwh?: number
  hvacEnergyKwh?: number
  motorEnergyConsumedKwh?: number
  regenEnergyKwh?: number
  dcdcEnergyKwh?: number
  batteryManagementEnergyKwh?: number
  airAmbientAvgTempC?: number
  chargeEnergyKwh?: number
}
export type DailySummaryMetrics = {
  created?: string
  updated?: string
  vehicleId?: string
  date?: string
  distanceDriven?: number
  totalEnergyConsumed?: number
  hvacEnergy?: number
  motorEnergyConsumed?: number
  regenEnergy?: number
  dcdcEnergy?: number
  batteryManagementEnergy?: number
  airAmbientAverageTemperature?: number
  chargeEnergy?: number
  engineOnTime?: number
  drivingTime?: number
  elevationGain?: number
}
export type LatLngPair = {
  latitude?: number
  longitude?: number
}
export type Boundary = {
  latitude?: number
  longitude?: number
}
export type ReachableRange = {
  center?: LatLngPair
  boundary?: Boundary[]
}
export type AzureServiceableRangeResponse = {
  formatVersion?: string
  reachableRange?: ReachableRange
}
export type TimeUtilization = {
  totalDrivingTime?: SourceAttributeValueBigDecimal
  totalIdleTime?: SourceAttributeValueBigDecimal
  totalChargingTime?: SourceAttributeValueBigDecimal
}
export type EnergyUtilization = {
  totalMotorEnergyConsumed?: SourceAttributeValueBigDecimal
  totalRegenEnergyConsumed?: SourceAttributeValueBigDecimal
  totalDcdcEnergyConsumed?: SourceAttributeValueBigDecimal
  totalHvacEnergyConsumed?: SourceAttributeValueBigDecimal
  totalBatteryManagementEnergyConsumed?: SourceAttributeValueBigDecimal
}
export type VehicleMetricsModel = {
  vehicleId?: string
  distanceDriven?: SourceAttributeValueBigDecimal
  energyUsed?: SourceAttributeValueBigDecimal
  efficiency?: SourceAttributeValueBigDecimal
  carbonEmissionsSaved?: SourceAttributeValueBigDecimal
  averageTemperature?: SourceAttributeValueBigDecimal
  timeUtilization?: TimeUtilization
  energyUtilization?: EnergyUtilization
  fuelSaved?: SourceAttributeValueBigDecimal
  numVehicles?: number
  numDrivingDays?: number
  numTrips?: number
}
export type VehicleMetrics = {
  id?: string
  name?: string
  totalTrips?: number
  numDrivingDays?: number
  fuelSaved?: SourceAttributeValueBigDecimal
  energyUsed?: SourceAttributeValueBigDecimal
  efficiency?: SourceAttributeValueBigDecimal
  distanceDriven?: SourceAttributeValueBigDecimal
  carbonEmissionsSaved?: SourceAttributeValueBigDecimal
}
export type ReportGenerationStatus = {
  created?: string
  updated?: string
  id?: string
  status?: string
  reportUrl?: string
  requestedBy?: string
}
export type EnergyUseBreakdown = {
  vehicleIds?: string[]
  telematicsProvider?: string
  energyUseBreakdowns?: {
    [key: string]: SourceAttributeValueBigDecimal
  }
}
export type OptionValue = {
  created?: string
  updated?: string
  optionType?: string
  value?: string
}
export type VehicleSummaryModel = {
  vehicleId?: string
  vehicleNm?: string
  year?: number
  make?: string
  model?: string
  vin?: string
  batterySize?: number
  distanceDriven?: number
  distanceUnit?: string
  kwhUsed?: number
  efficiency?: number
  efficiencyUnit?: string
  aggregateCarbonSave?: number
  numTrips?: number
  mpge?: number
  gallons?: number
  chargeTimeHrs?: number
  idleTimeHrs?: number
  driveTimeHrs?: number
}
export type FiveNumberSummaryModel = {
  upperQuartile?: number
  lowerQuartile?: number
  max?: number
  min?: number
  median?: number
  mean?: number
  summaryOf?: string
  unit?: string
}
export type OrganizationSummaryModel = {
  from?: string
  to?: string
  vehicleSummaries?: VehicleSummaryModel[]
  distanceDrivenSummary?: FiveNumberSummaryModel
  energyConsumedSummary?: FiveNumberSummaryModel
  efficiencySummary?: FiveNumberSummaryModel
}
export type VehicleScheduleModel = {
  depotId?: string
  assetNm?: string
  model?: string
  eventId?: string
  vehicleId?: string
  chargerId?: string
  dispenserId?: string
  status?: string
  scheduledStart?: string
  scheduledEnd?: string
  actualStart?: string
  actualEnd?: string
  eventType?: string
  isBlocking?: boolean
  powerOffered?: number
  targetSoc?: number
  eventTargetNm?: string
  distance?: number
  distanceUnit?: string
  driverId?: string
  driverNm?: string
}
export type StripeIntentModel = {
  organizationId?: string
  organizationNm?: string
  customerId?: string
  intentId?: string
  clientSecret?: string
}
export type OcppTag = {
  id?: string
  name?: string
  type?: 'VEHICLE' | 'USER'
  externalId?: string
  tagId?: string
}
export type Fleet = {
  created?: string
  updated?: string
  id: string // manually edited to be not optional
  fleetNm?: string
  fleetType?: string
  active?: boolean
  organizationId?: string
}
export type OrganizationConfig = {
  created?: string
  updated?: string
  organizationId?: string
  configKey?: string
  configValue?: string
}
export type Integration = {
  created?: string
  updated?: string
  id?: string
  integrationNm?: string
  integrationOrganizationId?: string
  description?: string
  requiredFields?: string
  defaultValues?: string
  logoUrl?: string
}
export type ChartDataResponseModel = {
  units?: object[]
  data?: object[][]
}
export type TriggeredAlarm = {
  created?: string
  updated?: string
  alarmId?: string
  triggeredDateTime?: string
  alarmNm?: string
  entityType?: string
  entityValue?: string
  entityName?: string
  currentEntityAttributes?: string
}
export type ChargeSchedulePeriodModel = {
  limitDischarge?: number
  desiredMode?: string
  startPeriodInSeconds: number
  powerLimit: number
  numberPhases: number
}
export type ChargeProfileModel = {
  chargerId?: string
  connectorId?: number
  profileId?: string
  stackLevel?: number
  chargeProfilePurpose?: string
  chargeProfileKind?: string
  recurrencyKind?: string
  validFrom?: string
  validTo?: string
  durationInSeconds?: number
  effectiveFrom?: string
  chargingRateUnit?: string
  minChargingRate?: number
  description?: string
  depotId?: string
  fleetId?: string
  currentlyActive?: boolean
  schedulePeriods?: ChargeSchedulePeriodModel[]
}
export type PagedResponseChargeProfileModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargeProfileModel[]
}
export type ErrorWrapper = {
  httpStatus?:
    | '100 CONTINUE'
    | '101 SWITCHING_PROTOCOLS'
    | '102 PROCESSING'
    | '103 CHECKPOINT'
    | '200 OK'
    | '201 CREATED'
    | '202 ACCEPTED'
    | '203 NON_AUTHORITATIVE_INFORMATION'
    | '204 NO_CONTENT'
    | '205 RESET_CONTENT'
    | '206 PARTIAL_CONTENT'
    | '207 MULTI_STATUS'
    | '208 ALREADY_REPORTED'
    | '226 IM_USED'
    | '300 MULTIPLE_CHOICES'
    | '301 MOVED_PERMANENTLY'
    | '302 FOUND'
    | '302 MOVED_TEMPORARILY'
    | '303 SEE_OTHER'
    | '304 NOT_MODIFIED'
    | '305 USE_PROXY'
    | '307 TEMPORARY_REDIRECT'
    | '308 PERMANENT_REDIRECT'
    | '400 BAD_REQUEST'
    | '401 UNAUTHORIZED'
    | '402 PAYMENT_REQUIRED'
    | '403 FORBIDDEN'
    | '404 NOT_FOUND'
    | '405 METHOD_NOT_ALLOWED'
    | '406 NOT_ACCEPTABLE'
    | '407 PROXY_AUTHENTICATION_REQUIRED'
    | '408 REQUEST_TIMEOUT'
    | '409 CONFLICT'
    | '410 GONE'
    | '411 LENGTH_REQUIRED'
    | '412 PRECONDITION_FAILED'
    | '413 PAYLOAD_TOO_LARGE'
    | '413 REQUEST_ENTITY_TOO_LARGE'
    | '414 URI_TOO_LONG'
    | '414 REQUEST_URI_TOO_LONG'
    | '415 UNSUPPORTED_MEDIA_TYPE'
    | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
    | '417 EXPECTATION_FAILED'
    | '418 I_AM_A_TEAPOT'
    | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
    | '420 METHOD_FAILURE'
    | '421 DESTINATION_LOCKED'
    | '422 UNPROCESSABLE_ENTITY'
    | '423 LOCKED'
    | '424 FAILED_DEPENDENCY'
    | '425 TOO_EARLY'
    | '426 UPGRADE_REQUIRED'
    | '428 PRECONDITION_REQUIRED'
    | '429 TOO_MANY_REQUESTS'
    | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
    | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
    | '500 INTERNAL_SERVER_ERROR'
    | '501 NOT_IMPLEMENTED'
    | '502 BAD_GATEWAY'
    | '503 SERVICE_UNAVAILABLE'
    | '504 GATEWAY_TIMEOUT'
    | '505 HTTP_VERSION_NOT_SUPPORTED'
    | '506 VARIANT_ALSO_NEGOTIATES'
    | '507 INSUFFICIENT_STORAGE'
    | '508 LOOP_DETECTED'
    | '509 BANDWIDTH_LIMIT_EXCEEDED'
    | '510 NOT_EXTENDED'
    | '511 NETWORK_AUTHENTICATION_REQUIRED'
  message?: string
}
export type ExternalServiceErrorMetaAttribute = {
  serviceCode?: 'FLEET_MANAGEMENT'
  path?: string
  statusCode?: number
  body?: string
}
export type JsonApiErrorObjectExternalServiceErrorMetaAttribute = {
  id: string
  title: string
  status: '200' | '400' | '401' | '500'
  source?: string
  detail: string
  code?: string
  meta?: ExternalServiceErrorMetaAttribute
}
export type JsonApiErrorWrapperExternalServiceErrorMetaAttribute = {
  errors?: JsonApiErrorObjectExternalServiceErrorMetaAttribute[]
}
export type Sort = {
  sorted?: boolean
  unsorted?: boolean
  empty?: boolean
}
export type V2GCompatibleSchedulePeriod = {
  startPeriodInSeconds: number
  limitDischarge?: number
  desiredMode?: string
  powerLimit?: number
  numberPhases?: number
}
export type ChargeProfileUpdateModel = {
  depotId?: string
  description?: string
  stackLevel?: number
  chargingProfilePurpose: string
  chargingProfileKind: string
  recurrencyKind?: string
  validFrom?: string
  validTo?: string
  durationInSeconds: number
  startSchedule: string
  chargingRateUnit: string
  minChargingRate?: number
  schedulePeriodMap: {
    [key: string]: V2GCompatibleSchedulePeriod
  }
  chargerId?: string
  connectorId?: number
  profileId: string
}
export type ChargeProfileCreateModel = {
  depotId?: string
  description?: string
  stackLevel?: number
  chargingProfilePurpose: string
  chargingProfileKind: string
  recurrencyKind?: string
  validFrom?: string
  validTo?: string
  durationInSeconds: number
  startSchedule: string
  chargingRateUnit: string
  minChargingRate?: number
  schedulePeriodMap: {
    [key: string]: V2GCompatibleSchedulePeriod
  }
}
export type BooleanModel = {
  result?: boolean
}
export type ChargeProfileActionRequest = {
  chargerId: string
  connectorId: number
  profileId: string
}
export type DepotModel = {
  depotId: string
  fleetId?: string
  depotNm?: string
  externalId?: string
  powerCeiling?: number
  powerCeilingUnit?: string
  currentCeiling?: number
  currentCeilingUnit?: string
  voltageCeiling?: number
  voltageCeilingUnits?: string
  latitude?: number
  longitude?: number
  address?: string
  v2gEnrolled?: boolean
  geofence?: string
  numChargers?: number
  numVehicles?: number
  activeChargers?: number
  connectedChargers?: number
  rateCardId?: string
  rateCardTierType?: string
  created?: string
  updated?: string
  isLoadBalanced?: boolean
  timezone?: string
  importKwhLast24h?: number
}
export type PagedResponseDepotModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: DepotModel[]
}
export type GeometryBigDecimal = {
  type: string
  coordinates: number[]
}
export type DepotMetaDataUpdateModel = {
  depotId: string
  fleetId?: string
  depotNm?: string
  geofence?: string
  location?: GeometryBigDecimal
  address?: string
  isLoadBalanced?: boolean
  siteLimit?: number
  rateCardId?: string
  rateCardTierType?: string
  externalId?: string
}
export type DepotCreationModel = {
  fleetId: string
  depotNm: string
  externalId?: string
  powerCeiling?: number
  currentCeiling?: number
  voltageCeiling?: number
  location?: GeometryBigDecimal
  v2gEnrolled?: boolean
  address?: string
  geofence?: string
  rateCardId?: string
  rateCardTierType?: string
  isLoadBalanced?: boolean
}
export type DepotEnergyLimitsModel = {
  depotId?: string
  fleetId?: string
  externalId?: string
  powerCeiling?: number
  voltageCeiling?: number
  currentCeiling?: number
  isLoadBalanced?: boolean
}
export type DepotEnergyLimitsUpdateModel = {
  powerCeiling?: number
  currentCeiling?: number
  voltageCeiling?: number
  isLoadBalanced?: boolean
}
export type ConnectorStatusModel = {
  chargerId?: string
  chargerNm?: string
  connectorId?: number
  status?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
  currentCombinedStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  legacyStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
  operationalStatus?: 'Operative' | 'Inoperative' | 'Unknown'
  occupiedStatus?: 'Occupied' | 'Unoccupied' | 'Unknown'
  simplifiedStatus?:
    | 'Unoccupied'
    | 'PreparingOrCharging'
    | 'FinishedCharging'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
  errorCode?: string
  errorInfo?: string
  vendorId?: string
  vendorErrorCode?: string
  statusTimestamp?: string
}
export type ConnectorUtilizationModel = {
  lastKnownPowerImportKw?: number
  livePowerImportKw?: number
  lastKnownPowerExportKw?: number
  livePowerExportKw?: number
}
export type LastMeterValuesModel = {
  currentImportAmps?: number
  currentImportAmpsTimestamp?: string
  voltage?: number
  voltageTimestamp?: string
  currentOfferedAmps?: number
  currentOfferedAmpsTimestamp?: string
  energyActiveImportRegisterKwh?: number
  energyActiveImportRegisterKwhTimestamp?: string
  powerActiveImportKw?: number
  powerActiveImportKwTimestamp?: string
  powerOfferedKw?: number
  powerOfferedKwTimestamp?: string
  powerActiveExportKw?: number
  powerActiveExportKwTimestamp?: string
  powerFactor?: number
  powerFactorTimestamp?: string
  temperature?: number
  temperatureTimestamp?: string
  soc?: number
  socTimestamp?: string
  currentExportAmps?: number
  currentExportAmpsTimestamp?: string
  energyActiveExportRegisterKwh?: number
  energyActiveExportRegisterKwhTimestamp?: string
}
export type TransactionSummaryModel = {
  id: string
  transactionPk: number
  connectorId: number
  chargerPk: number
  chargerId: string
  chargerName: string
  depotId: string
  organizationId: string
  ocppTag: string
  stopReason?: string
  stopEventActor?: string
  startTimestamp: string
  stopTimestamp?: string
  updatedTimestamp: string
  totalEnergyImported?: number
  totalEnergyExported?: number
  meanPowerImport?: number
  maxPowerImport?: number
  latestPowerImport?: number
  meanPowerExport?: number
  maxPowerExport?: number
  latestPowerExport?: number
  meanPowerOffered?: number
  maxPowerOffered?: number
  latestPowerOffered?: number
  meanAmperageImport?: number
  maxAmperageImport?: number
  latestAmperageImport?: number
  meanAmperageOffered?: number
  maxAmperageOffered?: number
  latestAmperageOffered?: number
  minVoltage?: number
  meanVoltage?: number
  maxVoltage?: number
  latestVoltage?: number
  minFrequency?: number
  meanFrequency?: number
  maxFrequency?: number
  latestFrequency?: number
  startingTemperature?: number
  minTemperature?: number
  meanTemperature?: number
  maxTemperature?: number
  latestTemperature?: number
  startingSoc?: number
  latestSoc?: number
  timezone?: string
}
export type ChargerStatusModel = {
  status?: 'Operative' | 'Faulted' | 'Unavailable' | 'Unknown'
  currentCombinedStatus?:
    | 'Operative'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  statusTimestamp?: string
  operationalStatus?: 'Operative' | 'Inoperative' | 'Unknown'
  connectivityStatus?: 'Online' | 'Offline'
}
export type ConnectorSimplifiedStatusCountsModel = {
  numConnectorsFaulted?: number
  numConnectorsUnavailable?: number
  numConnectorsPreparingOrCharging?: number
  numConnectorsFinishedCharging?: number
  numConnectorsUnoccupied?: number
}
export type ChargingSessionTelemetryModel = {
  deprecationMessage?: string
  chargerId?: string
  connectorId?: number
  chargingSessionId?: string
  measuredAt?: string
  currentImport?: number
  currentImportUnit?: string
  currentOffered?: number
  currentOfferedUnit?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
  powerActiveImport?: number
  powerActiveImportUnit?: string
  livePowerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  voltage?: number
  voltageUnit?: string
  temperature?: number
  temperatureUnit?: string
  soc?: number
  socUnit?: string
  currentExport?: number
  currentExportUnit?: string
  powerActiveExport?: number
  powerActiveExportUnit?: string
  energyActiveExportRegister?: number
  energyActiveExportRegisterUnit?: string
  energyActiveImportInterval?: number
  energyActiveImportIntervalUnit?: string
  powerFactor?: number
  powerFactorUnit?: string
  context?: string
  livePowerImport?: number
  livePowerUnit?: string
}
export type ChargingSessionModel = {
  deprecationNotice?: string
  transactionId?: number
  sessionId?: string
  connectorId?: number
  chargerId?: string
  ocppTag?: string
  vehicleId?: string
  vin?: string
  userId?: string
  startTimestamp?: string
  stopTimestamp?: string
  stopReason?: string
  depotId?: string
  fleetId?: string
  chargerName?: string
  depotName?: string
  kwhConsumed?: number
  kwhConsumedUnit?: string
  powerImported?: number
  powerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  startSocValue?: number
  endSocValue?: number
}
export type ConnectorModel = {
  connectorId?: number
  chargerId?: string
  connectorType?: 'J1772' | 'CHAdeMO' | 'CCS1' | 'CCS2' | 'NACS' | 'Other'
  maxPower?: number
  voltage?: number
  currentType?: string
  powerFactor?: number
}
export type ChargerModel = {
  chargerId: string
  chargerName?: string
  endpointAddress?: string
  ocppProtocol?: string
  registrationStatus?: string
  chargePointYear?: number
  chargePointVendor?: string
  chargePointModel?: string
  chargePointSerialNumber?: string
  chargeBoxSerialNumber?: string
  firmwareVersion?: string
  firmwareUpdateStatus?: string
  firmwareUpdateTimestamp?: string
  iccid?: string
  imsi?: string
  location?: GeometryBigDecimal
  meterType?: string
  meterSerialNumber?: string
  diagnosticsStatus?: string
  diagnosticsTimestamp?: string
  lastHeartbeatTimestamp?: string
  lastHeardTimestamp?: string
  description?: string
  note?: string
  autoRegisterTags?: boolean
  groupId?: string
  maxPower: number
  maxCurrent?: number
  voltage?: number
  currentType?: 'AC' | 'DC'
  depotId?: string
  depotName?: string
  fleetId?: string
  externalId?: string
  latitude?: number
  longitude?: number
  created?: string
  updated?: string
  connectorIds: number[]
  activeConnectorIds: number[]
  connectorStatus: {
    [key: string]: ConnectorStatusModel
  }
  connectorUtilization: {
    [key: string]: ConnectorUtilizationModel
  }
  lastMeterValues: {
    [key: string]: LastMeterValuesModel
  }
  lastTransactions: {
    [key: string]: TransactionSummaryModel
  }
  latestChargerStatus: ChargerStatusModel
  connectorSimplifiedStatusCounts: ConnectorSimplifiedStatusCountsModel
  lastTelemetry?: {
    [key: string]: ChargingSessionTelemetryModel
  }
  lastSession?: {
    [key: string]: ChargingSessionModel
  }
  v2gEnabled?: boolean
  connectors: {
    [key: string]: ConnectorModel
  }
  livePowerImport?: number
  livePowerImportKw?: number
  lastKnownPowerImportKw: number
  livePowerImportUnit?: string
  livePowerExport?: number
  livePowerExportKw?: number
  lastKnownPowerExportKw: number
  livePowerExportUnit?: string
  powerUnit?: string
  commissioningDate?: string
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN'
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: boolean
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other'
  preferredProfileNumPhases: number
}
export type PagedResponseChargerModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerModel[]
}
export type ChargerMakeModelKnownOperatingParameters = {
  knownPowerRatingsKw?: number[]
  knownOperatingCurrentsAmps?: number[]
  knownOperatingVoltages?: number[]
}
export type ChargerMakeModelResponseModel = {
  id?: string
  vendorName?: string
  chargerModel?: string
  modelHelperText?: string
  currentType?: string
  chargerMakeModelKnownOperatingParameters?: ChargerMakeModelKnownOperatingParameters
}
export type ChargerManufacturerModel = {
  id?: string
  name?: string
}
export type ChargerCapabilitiesOcppIdTagTypes = {
  values?: string[]
}
export type ChargerCapabilitySetResponseModel = {
  id?: string
  version?: string
  ocppIdTagTypes?: ChargerCapabilitiesOcppIdTagTypes
  ocppProtocol?: string
  chargingProfilesAnySupport?: boolean
  chargingProfilesSupportsDefaultTxProfile?: boolean
  chargingProfilesSupportsChargepointMax?: boolean
  chargingProfilesSupportsTxProfile?: boolean
  chargingProfilesStackLevelsSupported?: boolean
  chargingProfilesMaxStackLevels?: number
  chargingProfilesMaxSimultaneouslySupported?: number
  chargingProfilesMaxSchedulePeriods?: number
  chargingProfilesZeroPowerSupported?: boolean
  chargingProfilesAcceptsAmps?: boolean
  chargingProfilesAcceptsWatts?: boolean
  chargingProfilesSupportsDischarge?: boolean
  chargingProfilesMaxDischargePower?: number
  knownQuirks?: {
    [key: string]: object
  }
  remoteStartSupport?: boolean
  remoteStopSupport?: boolean
  sendsTransactionStart?: boolean
  sendsTransactionStop?: boolean
  sendsPlugInEvent?: boolean
  sendsPlugOutEvent?: boolean
  connectorStatusNotifSupport?: boolean
  meterValuesAnySupport?: boolean
  meterValuesContextStart?: boolean
  meterValuesContextPeriodic?: boolean
  meterValuesContextStop?: boolean
  meterValuesSupportedMeasurands?: {
    [key: string]: string[]
  }
  hardRebootSupported?: boolean
  softRebootSupported?: boolean
  configKeysGetAllSupported?: boolean
  configKeysGetSpecificSupported?: boolean
  configKeysSetSupported?: boolean
}
export type ChargerInsightsModel = {
  chargerHealth: 'Unhealthy' | 'Marginal' | 'Unknown' | 'Healthy'
}
export type ChargerDetailModel = {
  chargerId: string
  chargerName?: string
  endpointAddress?: string
  ocppProtocol?: string
  registrationStatus?: string
  chargePointYear?: number
  chargePointVendor?: string
  chargePointModel?: string
  chargePointSerialNumber?: string
  chargeBoxSerialNumber?: string
  firmwareVersion?: string
  firmwareUpdateStatus?: string
  firmwareUpdateTimestamp?: string
  iccid?: string
  imsi?: string
  location?: GeometryBigDecimal
  meterType?: string
  meterSerialNumber?: string
  diagnosticsStatus?: string
  diagnosticsTimestamp?: string
  lastHeartbeatTimestamp?: string
  lastHeardTimestamp?: string
  description?: string
  note?: string
  autoRegisterTags?: boolean
  groupId?: string
  maxPower: number
  maxCurrent?: number
  voltage?: number
  currentType?: 'AC' | 'DC'
  depotId?: string
  depotName?: string
  fleetId?: string
  externalId?: string
  latitude?: number
  longitude?: number
  created?: string
  updated?: string
  connectorIds: number[]
  activeConnectorIds: number[]
  connectorStatus: {
    [key: string]: ConnectorStatusModel
  }
  connectorUtilization: {
    [key: string]: ConnectorUtilizationModel
  }
  lastMeterValues: {
    [key: string]: LastMeterValuesModel
  }
  lastTransactions: {
    [key: string]: TransactionSummaryModel
  }
  latestChargerStatus: ChargerStatusModel
  connectorSimplifiedStatusCounts: ConnectorSimplifiedStatusCountsModel
  lastTelemetry?: {
    [key: string]: ChargingSessionTelemetryModel
  }
  lastSession?: {
    [key: string]: ChargingSessionModel
  }
  v2gEnabled?: boolean
  connectors: {
    [key: string]: ConnectorModel
  }
  livePowerImport?: number
  livePowerImportKw?: number
  lastKnownPowerImportKw: number
  livePowerImportUnit?: string
  livePowerExport?: number
  livePowerExportKw?: number
  lastKnownPowerExportKw: number
  livePowerExportUnit?: string
  powerUnit?: string
  commissioningDate?: string
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN'
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: boolean
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other'
  preferredProfileNumPhases: number
  chargerMakeModel?: ChargerMakeModelResponseModel
  chargerManufacturerModel?: ChargerManufacturerModel
  chargerCapabilitySet?: ChargerCapabilitySetResponseModel
  insights: ChargerInsightsModel
  importKwhLast24h: number
}
export type ConnectorUpdateModel = {
  connectorType?: 'J1772' | 'CHAdeMO' | 'CCS1' | 'CCS2' | 'NACS' | 'Other'
}
export type ChargerUpdateModel = {
  chargerId: string
  depotId?: string
  externalId?: string
  chargerName?: string
  autoRegisterTags?: boolean
  description?: string
  latitude?: number
  longitude?: number
  maxPower?: number
  maxCurrent?: number
  voltage?: number
  currentType?: string
  year?: number
  v2gEnabled?: boolean
  makeModelId?: string
  commissioningDate?: string
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: 'Yes' | 'No' | 'unset'
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other' | 'unset'
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN' | 'unset'
  connectors?: {
    [key: string]: ConnectorUpdateModel
  }
  preferredProfileNumPhases?: number
}
export type ChargerCreationModel = {
  chargerId: string
  depotId: string
  externalId?: string
  chargerName?: string
  autoRegisterTags?: boolean
  description?: string
  latitude: number
  longitude: number
  maxPower?: number
  maxCurrent?: number
  voltage?: number
  currentType: string
  year?: number
  v2gEnabled?: boolean
  makeModelId?: string
  commissioningDate?: string
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN'
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: boolean
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other'
  preferredProfileNumPhases?: number
}
export type TagResponseModel = {
  idTag: string
  organizationId: string
}
export type PagedResponseTagResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: TagResponseModel[]
}
export type AddTagsInputModel = {
  idTags: TagResponseModel[]
}
export type TransactionStartResponseModel = {
  id?: string
  transactionPk?: number
}
export type ChargingTransactionRequest = {
  connectorId?: number
  tagId: string
}
export type ConfigurationKey = {
  value?: string
  readOnly?: boolean
}
export type ConfigurationKeyResponseModel = {
  ocppStandardConfigurationKeys?: {
    [key: string]: ConfigurationKey
  }
  customConfigurationKeys?: {
    [key: string]: ConfigurationKey
  }
  unknownKeys?: string
}
export type JsonNode = object
export type CentralSystemResponseModel = {
  response?: string
  errorMessage?: string
  details?: JsonNode
}
export type ChangeConfigurationKeyResponseModel = {
  result: boolean
  chargerResponse: CentralSystemResponseModel
}
export type PagedResponseTransactionSummaryModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: TransactionSummaryModel[]
}
export type TransactionMeterValuesModel = {
  id?: string
  chargerId?: string
  connectorId?: number
  transactionPk?: number
  measuredAt?: string
  currentImport?: number
  currentImportUnit?: string
  currentOffered?: number
  currentOfferedUnit?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
  powerActiveImport?: number
  powerActiveImportUnit?: string
  livePowerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  voltage?: number
  voltageUnit?: string
  temperature?: number
  temperatureUnit?: string
  soc?: number
  socUnit?: string
  currentExport?: number
  currentExportUnit?: string
  powerActiveExport?: number
  powerActiveExportUnit?: string
  energyActiveExportRegister?: number
  energyActiveExportRegisterUnit?: string
  energyActiveImportInterval?: number
  energyActiveImportIntervalUnit?: string
  powerFactor?: number
  powerFactorUnit?: string
  context?: string
  livePowerImport?: number
  livePowerUnit?: string
}
export type MeterValueCollectionIntervalModel = {
  intervals?: string[][]
  meanPowerImport?: number[]
  maxPowerImport?: number[]
  totalEnergyImport?: number[]
}
export type MeterValueCollectionMetaAttributeModel = {
  intervalPeriod: '15m'
  transactionId?: string
}
export type JsonApiObjectMeterValueCollectionIntervalModelMeterValueCollectionMetaAttributeModel =
  {
    data?: MeterValueCollectionIntervalModel
    meta?: MeterValueCollectionMetaAttributeModel
  }
export type PowerUtilizationModel = {
  activePowerImport?: number[][]
  activePowerExport?: number[][]
  powerOffered?: number[][]
}
export type ChargingSummaryModel = {
  from?: string
  to?: string
  numChargingSessions?: number
  numVehiclesCharged?: number
  avgSessionChargedKwh?: number
  totalSessionChargedKwh?: number
  avgSessionLengthMin?: number
  totalSessionLengthMin?: number
}
export type ReportGenerationStatusModel = {
  id?: string
  status?: string
  reportUrl?: string
  requestedBy?: string
  created?: string
  updated?: string
}
export type ActiveChargerModel = {
  chargeBoxId?: string
  sessionId?: number
  connectorId?: number
  depotId?: string
  fleetId?: string
  firstEnergyImportValue?: number
  lastEnergyImportValue?: number
  latestPowerImport?: number
  latestCurrentImport?: number
  latestVoltage?: number
  isEnergyMeasurandMissing?: boolean
  isPowerEstimated?: boolean
  isVoltageMeasurandMissing?: boolean
  isCurrentMeasurandMissing?: boolean
  powerOffered?: number
  currentOffered?: number
  kwhConsumed?: number
  currentType?: string
  maxChargingSchedulePeriods?: number
  maxChargingProfileStackLevels?: number
  maxChargingProfilesInstalled?: number
}
export type LiveDepotUtilizationModel = {
  externalId?: string
  depotId?: string
  kwhConsumed?: number
  kwhConsumedUnit?: string
  totalVoltage?: number
  voltageUnit?: string
  totalCurrent?: number
  currentUnit?: string
  totalPower?: number
  powerUnit?: string
  powerCalculationEstimated?: boolean
  isPartialEnergyTotal?: boolean
  isPartialCurrentTotal?: boolean
  isPartialVoltageTotal?: boolean
  chargersPowerEstimatedOn?: string[]
  chargersMissingPowerMeasurand?: string[]
  chargersMissingEnergyMeasurand?: string[]
  chargersMissingCurrentMeasurand?: string[]
  chargersMissingVoltageMeasurand?: string[]
  activeChargers?: ActiveChargerModel[]
}
export type UptimeResponse = {
  from?: string
  to?: string
  statusAllocation?: {
    [key: string]: number
  }
  uptimePc?: number
}
export type ChargerFaultsModel = {
  connectorFaults?: {
    [key: string]: ConnectorStatusModel[]
  }
}
export type CollectionUtilizationResponseModel = {
  lastKnownPowerImportKw: number
  livePowerImportKw: number
  livePowerExportKw: number
  lastKnownPowerExportKw: number
  chargersNotRecentlyHeardFrom: string[]
}
export type ChargerUtilizationResponseModel = {
  lastKnownPowerImportKw: number
  livePowerImportKw: number
  livePowerExportKw: number
  lastKnownPowerExportKw: number
}
export type UtilizationResponseModel = {
  total?: CollectionUtilizationResponseModel
  sites?: {
    [key: string]: CollectionUtilizationResponseModel
  }
  chargers?: {
    [key: string]: ChargerUtilizationResponseModel
  }
}
export type ChargersTimespanTableElementModel = {
  chargerPk: number
  chargerId: string
  chargerName?: string
  siteId?: string
  totalTransactions?: number
  totalUniqueTags?: number
  totalEnergyDispensedWh?: number
  totalChargeTime?: number
  averageTransactionLength?: number
  totalFaults?: number
  totalFaultedStatuses?: number
  totalWarnings?: number
  uptimeSeconds?: number
  uptimePercentage?: number
  numConnectors: number
}
export type ChargersTimespanTableModel = {
  fromTs?: string
  toTs?: string
  chargerSummary?: ChargersTimespanTableElementModel[]
}
export type ChargersTimespanSummaryModel = {
  fromTs?: string
  toTs?: string
  totalChargers?: number
  totalTransactions?: number
  totalUniqueTags?: number
  totalEnergyDispensedWh?: number
  totalChargeTime?: number
  averageTransactionLength?: number
  totalFaults?: number
  totalFaultedStatuses?: number
  totalWarnings?: number
  chargerUptimeSeconds?: number
  chargerUptimePercentage?: number
}
export type ChargerStatusHistoryModel = {
  numChargers: number
  statuses: {
    [key: string]: number
  }
}
export type ConnectorStatusHistoryModel = {
  numConnectors: number
  statuses: {
    [key: string]: number
  }
  uptimeMs?: number
  uptimePercentage?: number
}
export type HistoricalStatusSummaryModel = {
  fromTs?: string
  toTs?: string
  chargerStatusHistory?: ChargerStatusHistoryModel
  connectorStatusHistory?: ConnectorStatusHistoryModel
}
export type LatestConnectorStatusSummaryModel = {
  total: number
  currentStatuses: {
    [key: string]: number
  }
  lastKnownStatuses: {
    [key: string]: number
  }
  operationalStatuses: {
    [key: string]: number
  }
  simplifiedStatuses: {
    [key: string]: number
  }
  occupiedStatuses: {
    [key: string]: number
  }
}
export type LatestChargerStatusSummaryModel = {
  total: number
  currentStatuses: {
    [key: string]: number
  }
  lastKnownStatuses: {
    [key: string]: number
  }
  operationalStatuses: {
    [key: string]: number
  }
  connectivityStatuses: {
    [key: string]: number
  }
}
export type LatestStatusSummaryModel = {
  connectors: LatestConnectorStatusSummaryModel
  chargers: LatestChargerStatusSummaryModel
  connectorsByCharger?: {
    [key: string]: LatestConnectorStatusSummaryModel
  }
}
export type ConnectorFaultModel = {
  siteId: string
  chargerId: string
  chargerName?: string
  connectorId: number
  connectorStatusId: number
  currentChargerStatus?: 'Operative' | 'Faulted' | 'Unavailable' | 'Unknown'
  currentConnectorStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  faultStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  errorCode: string
  errorInfo?: string
  vendor?: string
  vendorErrorCode?: string
  timestamp: string
}
export type PagedResponseConnectorFaultModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ConnectorFaultModel[]
}
export type PagedResponseChargerManufacturerModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerManufacturerModel[]
}
export type PagedResponseChargerMakeModelResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerMakeModelResponseModel[]
}
export type DepotEventIntervalModel = {
  intervalId?: string
  controlSignalValue?: string
  startDateTime: string
  endDateTime: string
  curtailedSiteLimit?: number
}
export type DepotEventScheduleModel = {
  depotEventScheduleId?: string
  intervals: DepotEventIntervalModel[]
}
export type EventRecurrenceModel = {
  eventRecurrenceId?: string
  recurrenceRule?: string
  startDate?: string
  validUntilDate?: string
  recurrenceName?: string
  recurrenceType?: 'DAILY' | 'WEEKLY' | 'WEEKDAYS' | 'CUSTOM'
}
export type DepotEventModel = {
  eventId?: string
  providerId?: string
  externalId?: string
  depotGridProfileId?: string
  depotId: string
  eventStartDate: string
  eventEndDate: string
  eventType: string
  eventDetails?: string
  schedule: DepotEventScheduleModel
  eventRecurrence?: EventRecurrenceModel
  isCommitted?: boolean
  isConfirmed?: boolean
}
export type ChargerEventModel = {
  depotId: string
  assetNm?: string
  eventId?: string
  assetId?: string
  chargerId?: string
  dispenserId?: string
  fleetId?: string
  status?: string
  scheduledStart?: string
  scheduledEnd?: string
  actualStart?: string
  actualEnd?: string
  eventType?: string
  powerOffered?: number
  targetSoc?: number
  eventTargetNm?: string
  chargerTagId?: string
  executionArn?: string
  profileId?: string
  eventRecurrence?: EventRecurrenceModel
}
export type ChargerEventUpdateModel = {
  eventId: string
  status?: string
  eventType?: string
  targetSoc?: number
  profileId?: string
  powerOffered?: number
  actualStart?: string
  actualEnd?: string
  scheduledStart?: string
  scheduledEnd?: string
}
export type LoadBalancerEventModel = {
  id?: string
  origin?: string
  eventType?:
    | 'DEPOT_EVENT'
    | 'CHARGER_EVENT'
    | 'SCHEDULED_EVENT_START'
    | 'HEARTBEAT'
    | 'AFTER_START'
    | 'AFTER_STOP'
    | 'EMS_ON'
    | 'EMS_OFF'
    | 'SITE_LIMIT'
  chargerId?: string
  fleetId?: string
  depotId?: string
  originTime?: string
  executionTime?: string
}
export type PagedResponseLoadBalancerEventModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: LoadBalancerEventModel[]
}
export type ScheduleRequestModel = {
  assetId?: string
  chargerId: string
  dispenserId: string
  powerOffered?: number
  targetSoc?: number
  profileId?: string
  eventType: string
  eventTargetNm?: string
  chargerTagId?: string
  depotId: string
  fleetId: string
  sessionLengthMinutes?: number
  validUntilDate?: string
  scheduledStart: string
  scheduledEnd?: string
  eventRecurrence?: EventRecurrenceModel
}
export type ChargerEventSessionModel = {
  chargerEventSessionId?: string
  eventId?: string
  sessionId?: string
}
export type ChargingSessionModel1 = {
  sessionId?: string
  connectorId?: number
  chargerId?: string
  ocppTag?: string
  vehicleId?: string
  vin?: string
  userId?: string
  startTimestamp?: string
  stopTimestamp?: string
  stopReason?: string
  depotId?: string
  fleetId?: string
  chargerName?: string
  depotName?: string
  kwhConsumed?: number
  kwhConsumedUnit?: string
  powerImported?: number
  powerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  startSocValue?: number
  endSocValue?: number
}
export type ChargerEventSessionDetailsModel = {
  eventId?: string
  sessions?: ChargingSessionModel1[]
}
export type PriceChange = {
  name?: string
  from?: string
  to?: string
  rateAmount?: number
  rateMeanDelta?: number
}
export type Price = {
  description?: string
  from?: string
  to?: string
  currency?: string
  rateMean?: number
  rateStandardDeviation?: number
  priceChanges?: PriceChange[]
}
export type PowerTimeSeriesPeriod = {
  startTimestamp: string
  startEpochSeconds: number
  powerLevel: number
}
export type PowerTimeSeriesModel = {
  startTimestamp: string
  endTimestamp: string
  powerLevels?: PowerTimeSeriesPeriod[]
}
export type ChargingSessionTelemetryModel1 = {
  chargerId?: string
  connectorId?: number
  chargingSessionId?: string
  measuredAt?: string
  currentImport?: number
  currentImportUnit?: string
  currentOffered?: number
  currentOfferedUnit?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
  powerActiveImport?: number
  powerActiveImportUnit?: string
  livePowerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  voltage?: number
  voltageUnit?: string
  temperature?: number
  temperatureUnit?: string
  soc?: number
  socUnit?: string
  currentExport?: number
  currentExportUnit?: string
  powerActiveExport?: number
  powerActiveExportUnit?: string
  energyActiveExportRegister?: number
  energyActiveExportRegisterUnit?: string
  energyActiveImportInterval?: number
  energyActiveImportIntervalUnit?: string
  powerFactor?: number
  powerFactorUnit?: string
  context?: string
  livePowerImport?: number
  livePowerUnit?: string
}
export type ChargerEventSessionTelemetryModel = {
  chargerEventId?: string
  sessionTelemetry?: {
    [key: string]: ChargingSessionTelemetryModel1[]
  }
}
export type SourceAttributeValue = {
  units: string
  value: number
  source: string
  confidence?: number
  lastUpdated: number
}
export type JsonApiErrorModel = {
  status: number
  title: string
  detail: string
}
export type AcknowledgeNotificationsModel = {
  sentNotificationIds: string[]
}
export type AlertUiRule = object
export type AlertUiRuleSet = {
  condition?: string
  rules?: AlertUiRule[]
}
export type NotificationRecipientModel = {
  message: string
  recipients: string[]
}
export type AlertConfigurationModel = {
  createdOrganizationId?: string
  alertName: string
  assetType: 'Vehicle' | 'Charger'
  lookBackPeriod?: number
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  assets?: string[]
  sites?: string[]
  organizations?: string[]
  rules?: AlertUiRuleSet
  notification: NotificationRecipientModel
  id: string
  createdBy: string
  createdOn: string
  enabled: boolean
}
export type CreateAlertConfigurationModel = {
  createdOrganizationId?: string
  alertName: string
  assetType: 'Vehicle' | 'Charger'
  lookBackPeriod?: number
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  assets?: string[]
  sites?: string[]
  organizations?: string[]
  rules?: AlertUiRuleSet
  notification: NotificationRecipientModel
}
export type NotificationModel = {
  id: string
  alertName: string
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  message: string
  assetType: 'Vehicle' | 'Charger'
  assetId: string
  organizationId?: string
  siteId?: string
  recipients: string[]
  acknowledged?: boolean
  acknowledgedDateTime?: string
  active?: string
  inactive?: string
}
export type AlertOptionModel = {
  id: string
  name: string
  value: string
}
export type PropertyModel = {
  id: string
  displayName: string
  propertyName: string
  propertyType: 'string' | 'number' | 'geospatial'
  assetType: 'Vehicle' | 'Charger'
  options: AlertOptionModel[]
  operators: AlertOptionModel[]
  chainableProperties: string[]
  units?: string
}
export type UserTagModel = {
  userId?: string
  userType?:
    | 'SYNOP_USER'
    | 'EXTERNAL_WORKPLACE_CHARGING_USER'
    | 'EXTERNAL_REIMBURSEMENT_CHARGING_USER'
    | 'EXTERNAL_OTHER_USER'
  ocppTagValue?: string
  ocppTagType?: 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER'
  useCase?: 'WORKPLACE_CHARGING' | 'FLEET_CHARGING' | 'REIMBURSEMENTS' | 'OTHER'
  created?: string
  updated?: string
  modifiedBy?: string
  createdBy?: string
}
export type WorkplaceChargingUserModel = {
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  userId?: string
  organizationId?: string
  status?: 'CURRENT' | 'PAST_DUE' | 'PENDING'
  onboarded?: string
  ocppTags?: UserTagModel[]
  id: string
}
export type RatePeriod = {
  from: string
  to: string
  price: number
}
export type OverrideRateStructureByOrganizationModelListRatePeriod = {
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  organizationOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  rateStatus: 'ACTIVE' | 'INACTIVE'
  rateMappingId: string
  rateId?: string
  overrideTargetType:
    | 'ORGANIZATION'
    | 'USER'
    | 'FLEET'
    | 'SITE'
    | 'CHARGER'
    | 'VEHICLE'
  overrideTargetId: string
  overridesMappingId: string
}
export type RateStructureModelListRatePeriod = {
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  organizationOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  rateStatus: 'ACTIVE' | 'INACTIVE'
  rateMappingId: string
  rateId?: string
}
export type OverrideRateStructureModelListRatePeriod = {
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  organizationOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  rateStatus: 'ACTIVE' | 'INACTIVE'
  rateMappingId: string
  rateId?: string
  overrideTargetType:
    | 'ORGANIZATION'
    | 'USER'
    | 'FLEET'
    | 'SITE'
    | 'CHARGER'
    | 'VEHICLE'
  overrideTargetId: string
  overridesMappingId: string
}
export type WorkplaceChargingRateModel = {
  administeringOrganizationId?: string
  siteId: string
  defaultRateStructure: RateStructureModelListRatePeriod
  rateMappingId?: string
  overrides?: OverrideRateStructureModelListRatePeriod[]
}
export type UpdateWorkplaceChargingRate = {
  rateMappingId: string
  organizationId: string
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  defaultRateStructure: RateStructureModelListRatePeriod
}
export type DisplayText = {
  language?: string
  text?: string
}
export type Price1 = {
  exl_vat?: number
  incl_vat?: number
}
export type PriceComponent = {
  type?: 'ENERGY' | 'FLAT' | 'PARKING_TIME' | 'TIME'
  number?: number
  vat?: number
  step_size?: number
}
export type LocalTime = {
  hour?: number
  minute?: number
  second?: number
  nano?: number
}
export type TariffRestrictions = {
  start_time?: LocalTime
  end_time?: LocalTime
  start_date?: string
  end_date?: string
  min_kwh?: number
  max_kwh?: number
  min_current?: number
  max_current?: number
  min_power?: number
  max_power?: number
  min_duration?: number
  max_duration?: number
  day_of_week?: (
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'
  )[]
  reservation?: 'RESERVATION' | 'RESERVATION_EXPIRES'
}
export type TariffElement = {
  price_components?: PriceComponent[]
  restrictions?: TariffRestrictions
}
export type Tariff = {
  country_code?: string
  party_id?: string
  id?: string
  currency?: string
  type?:
    | 'AD_HOC_PAYMENT'
    | 'PROFILE_CHEAP'
    | 'PROFILE_FAST'
    | 'PROFILE_GREEN'
    | 'REGULAR'
  tariff_alt_text?: DisplayText
  tariff_alt_url?: string
  min_price?: Price1
  max_price?: Price1
  elements?: TariffElement[]
  start_date_time?: string
  end_date_time?: string
  last_updated?: string
}
export type CreateWorkplaceChargingUser = {
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  userId?: string
  organizationId?: string
  status?: 'CURRENT' | 'PAST_DUE' | 'PENDING'
  onboarded?: string
  ocppTags?: UserTagModel[]
}
export type WorkplaceChargingRateModelRateStructureModelListRatePeriod = {
  administeringOrganizationId?: string
  siteId: string
  defaultRateStructure: RateStructureModelListRatePeriod
  rateMappingId?: string
  overrides?: OverrideRateStructureModelListRatePeriod[]
}
export type CreateRateStructureModelListRatePeriod = {
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  organizationOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
}
export type CreateWorkplaceChargingRate = {
  administeringOrganizationId?: string
  siteId: string
  defaultRateStructure: CreateRateStructureModelListRatePeriod
}
export type OverrideRateStructureModel = {
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  organizationOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  rateStatus: 'ACTIVE' | 'INACTIVE'
  rateMappingId: string
  rateId?: string
  overrideTargetType:
    | 'ORGANIZATION'
    | 'USER'
    | 'FLEET'
    | 'SITE'
    | 'CHARGER'
    | 'VEHICLE'
  overrideTargetId: string
  overridesMappingId: string
}
export type WorkplaceConfiguration = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  organizationId?: string
  billingAnchor?: number
  billingCycle?: string
  administeredBy?: string
}
export type UpdateWorkplaceChargingRecordStatus = {
  status:
    | 'DRAFT'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  periodStart: string
  workplaceChargingUserIds: string[]
}
export type ReimbursementUserModel = {
  id?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  zipCode?: string
  latitude?: number
  longitude?: number
  address?: string
  reimbursementRate?: number
  reimbursementMetric?: string
  reimbursementUnit?: string
  entityType: string
  entityId: string
  userId?: string
  organizationId: string
  status?: string
  onboarded?: string
}
export type ReimbursementUser = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  id?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  zipCode?: string
  address?: string
  latitude?: number
  longitude?: number
  reimbursementRate?: number
  reimbursementMetric?: string
  reimbursementUnit?: string
  entityId?: string
  entityType?: string
  userId?: string
  status?: string
  organizationId?: string
}
export type ReimbursementConfiguration = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  organizationId?: string
  billingAnchor?: number
  billingCycle?: string
  administeredBy?: string
}
export type BulkReimbursementConfiguration = {
  rate?: number
  reimbursementUserIds?: string[]
}
export type WorkplaceChargingUser = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  workplaceChargingUserId?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  rfidTag?: string
  userId?: string
  status?: 'CURRENT' | 'PAST_DUE' | 'PENDING'
  customerId?: string
  paymentIntentId?: string
  organizationId?: string
}
export type Organization1 = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  id?: string
  organizationNm?: string
  organizationType?: string
  logoUrl?: string
  styleOverrides?: string
  hasOpsCenter?: boolean
  hasReporting?: boolean
  hasBilling?: boolean
  connectedAccountId?: string
  connectedBillingEnabled?: boolean
  customerId?: string
  setupIntentId?: string
  hasCompeletedOnboarding?: boolean
  hasMarketplace?: boolean
  hasEnergyManagement?: boolean
  hasChargeManagement?: boolean
  hasFleetManagement?: boolean
  hasReimbursements?: boolean
  hasWorkplaceCharging?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  loginUrl?: string
  active?: boolean
  isCurrent?: boolean
}
export type ChargeRecordModelWorkplaceChargingUserOrganization = {
  chargeRecordId?: string
  usageRecordId?: string
  chargeType?: string
  usage?: number
  usageType?: string
  appliedRate?: number
  chargeCost?: number
  currency?: string
  organizationId?: string
  usageStart?: string
  usageEnd?: string
  assetType?: string
  assetId?: string
  usageSourceId?: string
  usageSource?: string
  measurand?: string
  payer?: WorkplaceChargingUser
  payee?: Organization1
}
export type WorkplaceChargingSummaryModel = {
  workplaceUsers?: number
  organizationId?: string
  currency?: string
  periodStart?: string
  periodEnd?: string
  currentPeriodWorkplaceChargingRevenue?: number
  workplaceChargingRevenueMoMChange?: number
  currentPeriodWorkplaceChargingEnergyUse?: number
  energyUseMoMChange?: number
  asOf?: string
  lastBillingPeriod?: WorkplaceChargingSummaryModel
  status?:
    | 'RESTRICTED'
    | 'RESTRICTED_SOON'
    | 'PENDING'
    | 'ENABLED'
    | 'COMPLETE'
    | 'REJECTED'
    | 'UNKNOWN'
}
export type WorkplaceChargingModel = {
  workplaceUserId?: string
  organizationId?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  currency?: string
  periodStart?: string
  periodEnd?: string
  status?:
    | 'DRAFT'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  statusAsOf?: string
  amount?: number
  usage?: number
  numberTransactions?: number
  asOf?: string
  approvedById?: string
  approvedByName?: string
  approvedOn?: string
  invoiceId?: string
}
export type StripeIntentModel1 = {
  id?: string
  type?: string
  intentId?: string
  clientSecret?: string
}
export type JsonArray = {
  empty?: boolean
  asBoolean?: boolean
  asDouble?: number
  asInt?: number
  asLong?: number
  asNumber?: number
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asString?: string
  asFloat?: number
  asByte?: string
  asShort?: number
  jsonObject?: boolean
  jsonArray?: boolean
  jsonPrimitive?: boolean
  asJsonPrimitive?: JsonPrimitive
  asJsonNull?: JsonNull
  asJsonArray?: JsonArray
  asJsonObject?: JsonObject
  jsonNull?: boolean
}
export type JsonNull = {
  asBoolean?: boolean
  asDouble?: number
  asInt?: number
  asLong?: number
  jsonObject?: boolean
  jsonArray?: boolean
  jsonPrimitive?: boolean
  asJsonPrimitive?: JsonPrimitive
  asJsonNull?: JsonNull
  asNumber?: number
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asJsonArray?: JsonArray
  asJsonObject?: JsonObject
  asString?: string
  jsonNull?: boolean
  asFloat?: number
  asByte?: string
  asShort?: number
}
export type JsonPrimitive = {
  asBoolean?: boolean
  asDouble?: number
  asInt?: number
  asLong?: number
  asNumber?: number
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  boolean?: boolean
  number?: boolean
  asString?: string
  string?: boolean
  asFloat?: number
  asByte?: string
  asShort?: number
  jsonObject?: boolean
  jsonArray?: boolean
  jsonPrimitive?: boolean
  asJsonPrimitive?: JsonPrimitive
  asJsonNull?: JsonNull
  asJsonArray?: JsonArray
  asJsonObject?: JsonObject
  jsonNull?: boolean
}
export type JsonObject = {
  asBoolean?: boolean
  asDouble?: number
  asInt?: number
  asLong?: number
  jsonObject?: boolean
  jsonArray?: boolean
  jsonPrimitive?: boolean
  asJsonPrimitive?: JsonPrimitive
  asJsonNull?: JsonNull
  asNumber?: number
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asJsonArray?: JsonArray
  asJsonObject?: JsonObject
  asString?: string
  jsonNull?: boolean
  asFloat?: number
  asByte?: string
  asShort?: number
}
export type StripeResponse = object
export type Address = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postalCode?: string
  state?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type MandateOptions = {
  transactionType?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AcssDebit = {
  mandateOptions?: MandateOptions
  verificationMethod?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Affirm = {
  captureMethod?: string
  preferredLocale?: string
  setupFutureUsage?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AfterpayClearpay = {
  captureMethod?: string
  reference?: string
  setupFutureUsage?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Alipay = {
  setupFutureUsage?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AuBecsDebit = {
  setupFutureUsage?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BacsDebit = {
  setupFutureUsage?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Offline = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Online = {
  ipAddress?: string
  userAgent?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CustomerAcceptance = {
  acceptedAt?: number
  offline?: Offline
  online?: Online
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type MultiUse = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AchCreditTransfer = {
  accountNumber?: string
  bankName?: string
  routingNumber?: string
  swiftCode?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AchDebit = {
  accountHolderType?: string
  bankName?: string
  country?: string
  fingerprint?: string
  last4?: string
  routingNumber?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Blik = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Boleto = {
  taxId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Checks = {
  addressLine1Check?: string
  addressPostalCodeCheck?: string
  cvcCheck?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Plan = {
  count?: number
  interval?: string
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Installments = {
  plan?: Plan
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type NetworkToken = {
  used?: boolean
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ThreeDSecure = {
  authenticationFlow?: string
  result?: string
  resultReason?: string
  version?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AmexExpressCheckout = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ApplePay = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type GooglePay = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Link = {
  country?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Masterpass = {
  billingAddress?: Address
  email?: string
  name?: string
  shippingAddress?: Address
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SamsungPay = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type VisaCheckout = {
  billingAddress?: Address
  email?: string
  name?: string
  shippingAddress?: Address
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Wallet = {
  amexExpressCheckout?: AmexExpressCheckout
  applePay?: ApplePay
  dynamicLast4?: string
  googlePay?: GooglePay
  link?: Link
  masterpass?: Masterpass
  samsungPay?: SamsungPay
  type?: string
  visaCheckout?: VisaCheckout
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Card = {
  brand?: string
  checks?: Checks
  country?: string
  description?: string
  expMonth?: number
  expYear?: number
  fingerprint?: string
  funding?: string
  iin?: string
  installments?: Installments
  issuer?: string
  last4?: string
  mandate?: string
  moto?: boolean
  network?: string
  networkToken?: NetworkToken
  threeDSecure?: ThreeDSecure
  wallet?: Wallet
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Receipt = {
  accountType?: string
  applicationCryptogram?: string
  applicationPreferredName?: string
  authorizationCode?: string
  authorizationResponseCode?: string
  cardholderVerificationMethod?: string
  dedicatedFileName?: string
  terminalVerificationResults?: string
  transactionStatusInformation?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CardPresent = {
  amountAuthorized?: number
  brand?: string
  captureBefore?: number
  cardholderName?: string
  country?: string
  description?: string
  emvAuthData?: string
  expMonth?: number
  expYear?: number
  fingerprint?: string
  funding?: string
  generatedCard?: string
  iin?: string
  incrementalAuthorizationSupported?: boolean
  issuer?: string
  last4?: string
  network?: string
  overcaptureSupported?: boolean
  readMethod?: string
  receipt?: Receipt
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Cashapp = {
  buyerId?: string
  cashtag?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CustomerBalance = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Eps = {
  bank?: string
  verifiedName?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Fpx = {
  accountHolderType?: string
  bank?: string
  transactionId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Giropay = {
  bankCode?: string
  bankName?: string
  bic?: string
  verifiedName?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Grabpay = {
  transactionId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Ideal = {
  bank?: string
  bic?: string
  generatedSepaDebit?: string
  generatedSepaDebitMandate?: string
  ibanLast4?: string
  verifiedName?: string
  generatedSepaDebitObject?: PaymentMethod
  generatedSepaDebitMandateObject?: Mandate
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type InteracPresent = {
  brand?: string
  cardholderName?: string
  country?: string
  description?: string
  emvAuthData?: string
  expMonth?: number
  expYear?: number
  fingerprint?: string
  funding?: string
  generatedCard?: string
  iin?: string
  issuer?: string
  last4?: string
  network?: string
  preferredLocales?: string[]
  readMethod?: string
  receipt?: Receipt
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Klarna = {
  paymentMethodCategory?: string
  preferredLocale?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Store = {
  chain?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Konbini = {
  store?: Store
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Multibanco = {
  entity?: string
  reference?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Oxxo = {
  number?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type P24 = {
  bank?: string
  reference?: string
  verifiedName?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Paynow = {
  reference?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SellerProtection = {
  disputeCategories?: string[]
  status?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Paypal = {
  payerEmail?: string
  payerId?: string
  payerName?: string
  sellerProtection?: SellerProtection
  transactionId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Pix = {
  bankTransactionId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Promptpay = {
  reference?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SepaCreditTransfer = {
  bankName?: string
  bic?: string
  iban?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AlternateStatementDescriptors = {
  kana?: string
  kanji?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BillingDetails = {
  address?: Address
  email?: string
  name?: string
  phone?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FraudDetails = {
  stripeReport?: string
  userReport?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Discount = {
  amount?: number
  discount?: Discount
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Location = {
  country?: string
  source?: string
  state?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Tax = {
  automaticTax?: string
  ipAddress?: string
  location?: Location
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type LineItem = {
  amountDiscount?: number
  amountSubtotal?: number
  amountTax?: number
  amountTotal?: number
  currency?: string
  description?: string
  discounts?: Discount[]
  id?: string
  object?: string
  price?: Price1
  quantity?: number
  taxes?: Tax[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Level3 = {
  customerReference?: string
  lineItems?: LineItem[]
  merchantReference?: string
  shippingAddressZip?: string
  shippingAmount?: number
  shippingFromZip?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Rule = {
  action?: string
  id?: string
  predicate?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Outcome = {
  networkStatus?: string
  reason?: string
  riskLevel?: string
  riskScore?: number
  rule?: string
  sellerMessage?: string
  type?: string
  ruleObject?: Rule
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type RadarOptions = {
  session?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type EmailSent = {
  emailSentAt?: number
  emailSentTo?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type DisplayDetails = {
  emailSent?: EmailSent
  expiresAt?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type NextAction = {
  displayDetails?: DisplayDetails
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FeeDetail = {
  amount?: number
  application?: string
  currency?: string
  description?: string
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BalanceTransactionSource = {
  lastResponse?: StripeResponse
  id?: string
}
export type BalanceTransaction = {
  amount?: number
  availableOn?: number
  created?: number
  currency?: string
  description?: string
  exchangeRate?: number
  fee?: number
  feeDetails?: FeeDetail[]
  id?: string
  net?: number
  object?: string
  reportingCategory?: string
  source?: string
  status?: string
  type?: string
  sourceObject?: BalanceTransactionSource
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type RequestOptions = {
  apiKey?: string
  clientId?: string
  idempotencyKey?: string
  stripeAccount?: string
  baseUrl?: string
  stripeVersion?: string
  connectTimeout?: number
  readTimeout?: number
  maxNetworkRetries?: number
  connectionProxy?: object
  proxyCredential?: {
    userName?: string
    password?: string[]
  }
}
export type TransferReversalCollection = {
  object?: string
  data?: TransferReversal[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MonthlyEstimatedRevenue = {
  amount?: number
  currency?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BusinessProfile = {
  mcc?: string
  monthlyEstimatedRevenue?: MonthlyEstimatedRevenue
  name?: string
  productDescription?: string
  supportAddress?: Address
  supportEmail?: string
  supportPhone?: string
  supportUrl?: string
  url?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Capabilities = {
  acssDebitPayments?: string
  affirmPayments?: string
  afterpayClearpayPayments?: string
  auBecsDebitPayments?: string
  bacsDebitPayments?: string
  bancontactPayments?: string
  bankTransferPayments?: string
  blikPayments?: string
  boletoPayments?: string
  cardIssuing?: string
  cardPayments?: string
  cartesBancairesPayments?: string
  cashappPayments?: string
  epsPayments?: string
  fpxPayments?: string
  giropayPayments?: string
  grabpayPayments?: string
  idealPayments?: string
  indiaInternationalPayments?: string
  jcbPayments?: string
  klarnaPayments?: string
  konbiniPayments?: string
  legacyPayments?: string
  linkPayments?: string
  oxxoPayments?: string
  p24Payments?: string
  paynowPayments?: string
  promptpayPayments?: string
  sepaDebitPayments?: string
  sofortPayments?: string
  taxReportingUs1099K?: string
  taxReportingUs1099Misc?: string
  transfers?: string
  treasury?: string
  usBankAccountAchPayments?: string
  zipPayments?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AddressKana = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postalCode?: string
  state?: string
  town?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AddressKanji = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postalCode?: string
  state?: string
  town?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type OwnershipDeclaration = {
  date?: number
  ip?: string
  userAgent?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Verification = {
  status?: string
  verifiedAddress?: string
  verifiedName?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Company = {
  address?: Address
  addressKana?: AddressKana
  addressKanji?: AddressKanji
  directorsProvided?: boolean
  executivesProvided?: boolean
  exportLicenseId?: string
  exportPurposeCode?: string
  name?: string
  nameKana?: string
  nameKanji?: string
  ownersProvided?: boolean
  ownershipDeclaration?: OwnershipDeclaration
  phone?: string
  structure?: string
  taxIdProvided?: boolean
  taxIdRegistrar?: string
  vatIdProvided?: boolean
  verification?: Verification
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Controller = {
  isController?: boolean
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ExternalAccount = {
  lastResponse?: StripeResponse
  id?: string
}
export type ExternalAccountCollection = {
  object?: string
  data?: ExternalAccount[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Alternative = {
  alternativeFieldsDue?: string[]
  originalFieldsDue?: string[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Errors = {
  code?: string
  reason?: string
  requirement?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FutureRequirements = {
  alternatives?: Alternative[]
  currentlyDue?: string[]
  errors?: Errors[]
  eventuallyDue?: string[]
  pastDue?: string[]
  pendingVerification?: string[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Dob = {
  day?: number
  month?: number
  year?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Relationship = {
  director?: boolean
  executive?: boolean
  owner?: boolean
  percentOwnership?: number
  representative?: boolean
  title?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Requirements = {
  alternatives?: Alternative[]
  currentDeadline?: number
  currentlyDue?: string[]
  disabledReason?: string
  errors?: Errors[]
  eventuallyDue?: string[]
  pastDue?: string[]
  pendingVerification?: string[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Person = {
  account?: string
  address?: Address
  addressKana?: AddressKana
  addressKanji?: AddressKanji
  created?: number
  deleted?: boolean
  dob?: Dob
  email?: string
  firstName?: string
  firstNameKana?: string
  firstNameKanji?: string
  fullNameAliases?: string[]
  futureRequirements?: FutureRequirements
  gender?: string
  id?: string
  idNumberProvided?: boolean
  idNumberSecondaryProvided?: boolean
  lastName?: string
  lastNameKana?: string
  lastNameKanji?: string
  maidenName?: string
  metadata?: {
    [key: string]: string
  }
  nationality?: string
  object?: string
  phone?: string
  politicalExposure?: string
  registeredAddress?: Address
  relationship?: Relationship
  requirements?: Requirements
  ssnLast4Provided?: boolean
  verification?: Verification
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BacsDebitPayments = {
  displayName?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FileLink = {
  created?: number
  expired?: boolean
  expiresAt?: number
  file?: string
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  url?: string
  fileObject?: File
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FileLinkCollection = {
  object?: string
  data?: FileLink[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type File = {
  created?: number
  expiresAt?: number
  filename?: string
  id?: string
  links?: FileLinkCollection
  object?: string
  purpose?: string
  size?: number
  title?: string
  type?: string
  url?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Branding = {
  icon?: string
  logo?: string
  primaryColor?: string
  secondaryColor?: string
  iconObject?: File
  logoObject?: File
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TosAcceptance = {
  date?: number
  ip?: string
  serviceAgreement?: string
  userAgent?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CardIssuing = {
  tosAcceptance?: TosAcceptance
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type DeclineOn = {
  avsFailure?: boolean
  cvcFailure?: boolean
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CardPayments = {
  declineOn?: DeclineOn
  statementDescriptorPrefix?: string
  statementDescriptorPrefixKana?: string
  statementDescriptorPrefixKanji?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Dashboard = {
  displayName?: string
  timezone?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Payments = {
  statementDescriptor?: string
  statementDescriptorKana?: string
  statementDescriptorKanji?: string
  statementDescriptorPrefixKana?: string
  statementDescriptorPrefixKanji?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Schedule = {
  delayDays?: number
  interval?: string
  monthlyAnchor?: number
  weeklyAnchor?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Payouts = {
  debitNegativeBalances?: boolean
  schedule?: Schedule
  statementDescriptor?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SepaDebitPayments = {
  creditorId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Treasury = {
  tosAcceptance?: TosAcceptance
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Settings = {
  bacsDebitPayments?: BacsDebitPayments
  branding?: Branding
  cardIssuing?: CardIssuing
  cardPayments?: CardPayments
  dashboard?: Dashboard
  payments?: Payments
  payouts?: Payouts
  sepaDebitPayments?: SepaDebitPayments
  treasury?: Treasury
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Account = {
  businessProfile?: BusinessProfile
  businessType?: string
  capabilities?: Capabilities
  chargesEnabled?: boolean
  company?: Company
  controller?: Controller
  country?: string
  created?: number
  defaultCurrency?: string
  deleted?: boolean
  detailsSubmitted?: boolean
  email?: string
  externalAccounts?: ExternalAccountCollection
  futureRequirements?: FutureRequirements
  id?: string
  individual?: Person
  metadata?: {
    [key: string]: string
  }
  object?: string
  payoutsEnabled?: boolean
  requirements?: Requirements
  settings?: Settings
  tosAcceptance?: TosAcceptance
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Transfer = {
  amount?: number
  amountReversed?: number
  balanceTransaction?: string
  created?: number
  currency?: string
  description?: string
  destination?: string
  destinationPayment?: string
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  reversals?: TransferReversalCollection
  reversed?: boolean
  sourceTransaction?: string
  sourceType?: string
  transferGroup?: string
  balanceTransactionObject?: BalanceTransaction
  destinationPaymentObject?: Charge
  destinationObject?: Account
  sourceTransactionObject?: Charge
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TransferReversal = {
  amount?: number
  balanceTransaction?: string
  created?: number
  currency?: string
  destinationPaymentRefund?: string
  id?: string
  metadata?: {
    [key: string]: string
  }
  object?: string
  sourceRefund?: string
  transfer?: string
  balanceTransactionObject?: BalanceTransaction
  destinationPaymentRefundObject?: Refund
  sourceRefundObject?: Refund
  transferObject?: Transfer
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Tip = {
  amount?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AmountDetails = {
  tip?: Tip
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AutomaticPaymentMethods = {
  allowRedirects?: string
  enabled?: boolean
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type UsBankAccount = {
  accountHolderType?: string
  accountType?: string
  bankName?: string
  fingerprint?: string
  last4?: string
  routingNumber?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PaymentMethodOptions = {
  acssDebit?: AcssDebit
  bancontact?: Bancontact
  card?: Card
  customerBalance?: CustomerBalance
  konbini?: Konbini
  usBankAccount?: UsBankAccount
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CashBalance = {
  available?: {
    [key: string]: number
  }
  customer?: string
  livemode?: boolean
  object?: string
  settings?: Settings
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type InvoiceSettings = {
  daysUntilDue?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ShippingDetails = {
  address?: Address
  carrier?: string
  name?: string
  phone?: string
  trackingNumber?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PaymentSource = {
  lastResponse?: StripeResponse
  id?: string
}
export type PaymentSourceCollection = {
  object?: string
  data?: PaymentSource[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AutomaticTax = {
  enabled?: boolean
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BillingThresholds = {
  usageGte?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CancellationDetails = {
  comment?: string
  feedback?: string
  reason?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TaxRate = {
  active?: boolean
  country?: string
  created?: number
  description?: string
  displayName?: string
  effectivePercentage?: number
  id?: string
  inclusive?: boolean
  jurisdiction?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  percentage?: number
  state?: string
  taxType?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SubscriptionItem = {
  billingThresholds?: BillingThresholds
  created?: number
  deleted?: boolean
  id?: string
  metadata?: {
    [key: string]: string
  }
  object?: string
  plan?: Plan
  price?: Price1
  quantity?: number
  subscription?: string
  taxRates?: TaxRate[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SubscriptionItemCollection = {
  object?: string
  data?: SubscriptionItem[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PauseCollection = {
  behavior?: string
  resumesAt?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PaymentSettings = {
  defaultMandate?: string
  paymentMethodOptions?: PaymentMethodOptions
  paymentMethodTypes?: string[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PendingInvoiceItemInterval = {
  interval?: string
  intervalCount?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PendingUpdate = {
  billingCycleAnchor?: number
  expiresAt?: number
  subscriptionItems?: SubscriptionItem[]
  trialEnd?: number
  trialFromPlan?: boolean
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TransferData = {
  amount?: number
  destination?: string
  destinationObject?: Account
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type EndBehavior = {
  missingPaymentMethod?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TrialSettings = {
  endBehavior?: EndBehavior
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Application = {
  deleted?: boolean
  id?: string
  name?: string
  object?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CustomField = {
  name?: string
  value?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CustomerTaxId = {
  type?: string
  value?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FromInvoice = {
  action?: string
  invoice?: string
  invoiceObject?: Invoice
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type DiscountAmount = {
  amount?: number
  discount?: string
  discountObject?: Discount
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Period = {
  end?: number
  start?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CreditedItems = {
  invoice?: string
  invoiceLineItems?: string[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ProrationDetails = {
  creditedItems?: CreditedItems
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TaxAmount = {
  amount?: number
  inclusive?: boolean
  taxRate?: string
  taxabilityReason?: string
  taxableAmount?: number
  taxRateObject?: TaxRate
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type InvoiceLineItem = {
  amount?: number
  amountExcludingTax?: number
  currency?: string
  description?: string
  discountAmounts?: DiscountAmount[]
  discountable?: boolean
  discounts?: string[]
  id?: string
  invoiceItem?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  period?: Period
  plan?: Plan
  price?: Price1
  proration?: boolean
  prorationDetails?: ProrationDetails
  quantity?: number
  subscription?: string
  subscriptionItem?: string
  taxAmounts?: TaxAmount[]
  taxRates?: TaxRate[]
  type?: string
  unitAmountExcludingTax?: number
  discountObjects?: Discount[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type InvoiceLineItemCollection = {
  object?: string
  data?: InvoiceLineItem[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RenderingOptions = {
  amountTaxDisplay?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Maximum = {
  unit?: string
  value?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Minimum = {
  unit?: string
  value?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type DeliveryEstimate = {
  maximum?: Maximum
  minimum?: Minimum
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CurrencyOption = {
  amount?: number
  taxBehavior?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FixedAmount = {
  amount?: number
  currency?: string
  currencyOptions?: {
    [key: string]: CurrencyOption
  }
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TaxCode = {
  description?: string
  id?: string
  name?: string
  object?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ShippingRate = {
  active?: boolean
  created?: number
  deliveryEstimate?: DeliveryEstimate
  displayName?: string
  fixedAmount?: FixedAmount
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  taxBehavior?: string
  taxCode?: string
  type?: string
  taxCodeObject?: TaxCode
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ShippingCost = {
  amountSubtotal?: number
  amountTax?: number
  amountTotal?: number
  shippingRate?: string
  taxes?: Tax[]
  shippingRateObject?: ShippingRate
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type StatusTransitions = {
  acceptedAt?: number
  canceledAt?: number
  finalizedAt?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SubscriptionDetails = {
  metadata?: {
    [key: string]: string
  }
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ItemReason = {
  lineItemIds?: string[]
  usageGte?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ThresholdReason = {
  amountGte?: number
  itemReasons?: ItemReason[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TotalDiscountAmount = {
  amount?: number
  discount?: string
  discountObject?: Discount
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TotalTaxAmount = {
  amount?: number
  inclusive?: boolean
  taxRate?: string
  taxabilityReason?: string
  taxableAmount?: number
  taxRateObject?: TaxRate
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Breakdown = {
  discounts?: Discount[]
  taxes?: Tax[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TotalDetails = {
  amountDiscount?: number
  amountShipping?: number
  amountTax?: number
  breakdown?: Breakdown
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Recurring = {
  amountSubtotal?: number
  amountTotal?: number
  interval?: string
  intervalCount?: number
  totalDetails?: TotalDetails
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type LineItemCollection = {
  object?: string
  data?: LineItem[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Upfront = {
  amountSubtotal?: number
  amountTotal?: number
  lineItems?: LineItemCollection
  totalDetails?: TotalDetails
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Computed = {
  recurring?: Recurring
  upfront?: Upfront
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type FromQuote = {
  isRevision?: boolean
  quote?: string
  quoteObject?: Quote
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SubscriptionData = {
  description?: string
  effectiveDate?: number
  trialPeriodDays?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type CurrentPhase = {
  endDate?: number
  startDate?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type DefaultSettings = {
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  billingCycleAnchor?: string
  billingThresholds?: BillingThresholds
  collectionMethod?: string
  defaultPaymentMethod?: string
  description?: string
  invoiceSettings?: InvoiceSettings
  onBehalfOf?: string
  transferData?: TransferData
  defaultPaymentMethodObject?: PaymentMethod
  onBehalfOfObject?: Account
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AddInvoiceItem = {
  price?: string
  quantity?: number
  taxRates?: TaxRate[]
  priceObject?: Price1
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Item = {
  billingThresholds?: BillingThresholds
  metadata?: {
    [key: string]: string
  }
  plan?: string
  price?: string
  quantity?: number
  taxRates?: TaxRate[]
  planObject?: Plan
  priceObject?: Price1
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type AppliesTo = {
  products?: string[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Coupon = {
  amountOff?: number
  appliesTo?: AppliesTo
  created?: number
  currency?: string
  currencyOptions?: {
    [key: string]: CurrencyOption
  }
  deleted?: boolean
  duration?: string
  durationInMonths?: number
  id?: string
  livemode?: boolean
  maxRedemptions?: number
  metadata?: {
    [key: string]: string
  }
  name?: string
  object?: string
  percentOff?: number
  redeemBy?: number
  timesRedeemed?: number
  valid?: boolean
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Phase = {
  addInvoiceItems?: AddInvoiceItem[]
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  billingCycleAnchor?: string
  billingThresholds?: BillingThresholds
  collectionMethod?: string
  coupon?: string
  currency?: string
  defaultPaymentMethod?: string
  defaultTaxRates?: TaxRate[]
  description?: string
  endDate?: number
  invoiceSettings?: InvoiceSettings
  items?: Item[]
  metadata?: {
    [key: string]: string
  }
  onBehalfOf?: string
  prorationBehavior?: string
  startDate?: number
  transferData?: TransferData
  trialEnd?: number
  defaultPaymentMethodObject?: PaymentMethod
  couponObject?: Coupon
  onBehalfOfObject?: Account
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TestClock = {
  created?: number
  deleted?: boolean
  deletesAfter?: number
  frozenTime?: number
  id?: string
  livemode?: boolean
  name?: string
  object?: string
  status?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SubscriptionSchedule = {
  application?: string
  canceledAt?: number
  completedAt?: number
  created?: number
  currentPhase?: CurrentPhase
  customer?: string
  defaultSettings?: DefaultSettings
  endBehavior?: string
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  phases?: Phase[]
  releasedAt?: number
  releasedSubscription?: string
  status?: string
  subscription?: string
  testClock?: string
  customerObject?: Customer
  applicationObject?: Application
  subscriptionObject?: Subscription1
  testClockObject?: TestClock
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Quote = {
  amountSubtotal?: number
  amountTotal?: number
  application?: string
  applicationFeeAmount?: number
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  collectionMethod?: string
  computed?: Computed
  created?: number
  currency?: string
  customer?: string
  defaultTaxRates?: string[]
  description?: string
  discounts?: string[]
  expiresAt?: number
  footer?: string
  fromQuote?: FromQuote
  header?: string
  id?: string
  invoice?: string
  invoiceSettings?: InvoiceSettings
  lineItems?: LineItemCollection
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  number?: string
  object?: string
  onBehalfOf?: string
  status?: string
  statusTransitions?: StatusTransitions
  subscription?: string
  subscriptionData?: SubscriptionData
  subscriptionSchedule?: string
  testClock?: string
  totalDetails?: TotalDetails
  transferData?: TransferData
  subscriptionScheduleObject?: SubscriptionSchedule
  defaultTaxRateObjects?: TaxRate[]
  customerObject?: Customer
  applicationObject?: Application
  invoiceObject?: Invoice
  onBehalfOfObject?: Account
  subscriptionObject?: Subscription1
  discountObjects?: Discount[]
  testClockObject?: TestClock
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type TaxId = {
  country?: string
  created?: number
  customer?: string
  deleted?: boolean
  id?: string
  livemode?: boolean
  object?: string
  type?: string
  value?: string
  verification?: Verification
  customerObject?: Customer
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Invoice = {
  accountCountry?: string
  accountName?: string
  accountTaxIds?: string[]
  amountDue?: number
  amountPaid?: number
  amountRemaining?: number
  amountShipping?: number
  application?: string
  applicationFeeAmount?: number
  attemptCount?: number
  attempted?: boolean
  autoAdvance?: boolean
  automaticTax?: AutomaticTax
  billingReason?: string
  charge?: string
  collectionMethod?: string
  created?: number
  currency?: string
  customFields?: CustomField[]
  customer?: string
  customerAddress?: Address
  customerEmail?: string
  customerName?: string
  customerPhone?: string
  customerShipping?: ShippingDetails
  customerTaxExempt?: string
  customerTaxIds?: CustomerTaxId[]
  defaultPaymentMethod?: string
  defaultSource?: string
  defaultTaxRates?: TaxRate[]
  deleted?: boolean
  description?: string
  discount?: Discount
  discounts?: string[]
  dueDate?: number
  effectiveAt?: number
  endingBalance?: number
  footer?: string
  fromInvoice?: FromInvoice
  hostedInvoiceUrl?: string
  id?: string
  invoicePdf?: string
  lastFinalizationError?: StripeError
  latestRevision?: string
  lines?: InvoiceLineItemCollection
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  nextPaymentAttempt?: number
  number?: string
  object?: string
  onBehalfOf?: string
  paid?: boolean
  paidOutOfBand?: boolean
  paymentIntent?: string
  paymentSettings?: PaymentSettings
  periodEnd?: number
  periodStart?: number
  postPaymentCreditNotesAmount?: number
  prePaymentCreditNotesAmount?: number
  quote?: string
  receiptNumber?: string
  renderingOptions?: RenderingOptions
  shippingCost?: ShippingCost
  shippingDetails?: ShippingDetails
  startingBalance?: number
  statementDescriptor?: string
  status?: string
  statusTransitions?: StatusTransitions
  subscription?: string
  subscriptionDetails?: SubscriptionDetails
  subscriptionProrationDate?: number
  subtotal?: number
  subtotalExcludingTax?: number
  tax?: number
  testClock?: string
  thresholdReason?: ThresholdReason
  total?: number
  totalDiscountAmounts?: TotalDiscountAmount[]
  totalExcludingTax?: number
  totalTaxAmounts?: TotalTaxAmount[]
  transferData?: TransferData
  webhooksDeliveredAt?: number
  defaultPaymentMethodObject?: PaymentMethod
  customerObject?: Customer
  chargeObject?: Charge
  applicationObject?: Application
  onBehalfOfObject?: Account
  paymentIntentObject?: PaymentIntent
  latestRevisionObject?: Invoice
  quoteObject?: Quote
  subscriptionObject?: Subscription1
  accountTaxIdObjects?: TaxId[]
  discountObjects?: Discount[]
  defaultSourceObject?: PaymentSource
  testClockObject?: TestClock
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Subscription1 = {
  application?: string
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  billingCycleAnchor?: number
  billingThresholds?: BillingThresholds
  cancelAt?: number
  cancelAtPeriodEnd?: boolean
  canceledAt?: number
  cancellationDetails?: CancellationDetails
  collectionMethod?: string
  created?: number
  currency?: string
  currentPeriodEnd?: number
  currentPeriodStart?: number
  customer?: string
  daysUntilDue?: number
  defaultPaymentMethod?: string
  defaultSource?: string
  defaultTaxRates?: TaxRate[]
  description?: string
  discount?: Discount
  endedAt?: number
  id?: string
  items?: SubscriptionItemCollection
  latestInvoice?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  nextPendingInvoiceItemInvoice?: number
  object?: string
  onBehalfOf?: string
  pauseCollection?: PauseCollection
  paymentSettings?: PaymentSettings
  pendingInvoiceItemInterval?: PendingInvoiceItemInterval
  pendingSetupIntent?: string
  pendingUpdate?: PendingUpdate
  schedule?: string
  startDate?: number
  status?: string
  testClock?: string
  transferData?: TransferData
  trialEnd?: number
  trialSettings?: TrialSettings
  trialStart?: number
  pendingSetupIntentObject?: SetupIntent
  defaultPaymentMethodObject?: PaymentMethod
  customerObject?: Customer
  applicationObject?: Application
  onBehalfOfObject?: Account
  latestInvoiceObject?: Invoice
  scheduleObject?: SubscriptionSchedule
  defaultSourceObject?: PaymentSource
  testClockObject?: TestClock
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SubscriptionCollection = {
  object?: string
  data?: Subscription1[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TaxIdCollection = {
  object?: string
  data?: TaxId[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TestHelpers = object
export type Customer = {
  address?: Address
  balance?: number
  cashBalance?: CashBalance
  created?: number
  currency?: string
  defaultSource?: string
  deleted?: boolean
  delinquent?: boolean
  description?: string
  discount?: Discount
  email?: string
  id?: string
  invoiceCreditBalance?: {
    [key: string]: number
  }
  invoicePrefix?: string
  invoiceSettings?: InvoiceSettings
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  name?: string
  nextInvoiceSequence?: number
  object?: string
  phone?: string
  preferredLocales?: string[]
  shipping?: ShippingDetails
  sources?: PaymentSourceCollection
  subscriptions?: SubscriptionCollection
  tax?: Tax
  taxExempt?: string
  taxIds?: TaxIdCollection
  testClock?: string
  defaultSourceObject?: PaymentSource
  testClockObject?: TestClock
  testHelpers?: TestHelpers
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SetupAttempt = {
  application?: string
  attachToSelf?: boolean
  created?: number
  customer?: string
  flowDirections?: string[]
  id?: string
  livemode?: boolean
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodDetails?: PaymentMethodDetails
  setupError?: StripeError
  setupIntent?: string
  status?: string
  usage?: string
  customerObject?: Customer
  applicationObject?: Application
  onBehalfOfObject?: Account
  paymentMethodObject?: PaymentMethod
  setupIntentObject?: SetupIntent
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SetupIntent = {
  application?: string
  attachToSelf?: boolean
  automaticPaymentMethods?: AutomaticPaymentMethods
  cancellationReason?: string
  clientSecret?: string
  created?: number
  customer?: string
  description?: string
  flowDirections?: string[]
  id?: string
  lastSetupError?: StripeError
  latestAttempt?: string
  livemode?: boolean
  mandate?: string
  metadata?: {
    [key: string]: string
  }
  nextAction?: NextAction
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodOptions?: PaymentMethodOptions
  paymentMethodTypes?: string[]
  singleUseMandate?: string
  status?: string
  usage?: string
  latestAttemptObject?: SetupAttempt
  mandateObject?: Mandate
  singleUseMandateObject?: Mandate
  customerObject?: Customer
  applicationObject?: Application
  onBehalfOfObject?: Account
  paymentMethodObject?: PaymentMethod
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type StripeError = {
  charge?: string
  code?: string
  declineCode?: string
  docUrl?: string
  message?: string
  param?: string
  paymentIntent?: PaymentIntent
  paymentMethod?: PaymentMethod
  paymentMethodType?: string
  requestLogUrl?: string
  setupIntent?: SetupIntent
  source?: PaymentSource
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Processing = {
  card?: Card
  type?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type IpAddressLocation = {
  city?: string
  country?: string
  latitude?: number
  longitude?: number
  region?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Session = {
  browser?: string
  device?: string
  platform?: string
  version?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Review = {
  billingZip?: string
  charge?: string
  closedReason?: string
  created?: number
  id?: string
  ipAddress?: string
  ipAddressLocation?: IpAddressLocation
  livemode?: boolean
  object?: string
  open?: boolean
  openedReason?: string
  paymentIntent?: string
  reason?: string
  session?: Session
  chargeObject?: Charge
  paymentIntentObject?: PaymentIntent
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PaymentIntent = {
  amount?: number
  amountCapturable?: number
  amountDetails?: AmountDetails
  amountReceived?: number
  application?: string
  applicationFeeAmount?: number
  automaticPaymentMethods?: AutomaticPaymentMethods
  canceledAt?: number
  cancellationReason?: string
  captureMethod?: string
  clientSecret?: string
  confirmationMethod?: string
  created?: number
  currency?: string
  customer?: string
  description?: string
  id?: string
  invoice?: string
  lastPaymentError?: StripeError
  latestCharge?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  nextAction?: NextAction
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodOptions?: PaymentMethodOptions
  paymentMethodTypes?: string[]
  processing?: Processing
  receiptEmail?: string
  review?: string
  setupFutureUsage?: string
  shipping?: ShippingDetails
  source?: string
  statementDescriptor?: string
  statementDescriptorSuffix?: string
  status?: string
  transferData?: TransferData
  transferGroup?: string
  latestChargeObject?: Charge
  customerObject?: Customer
  applicationObject?: Application
  invoiceObject?: Invoice
  onBehalfOfObject?: Account
  reviewObject?: Review
  paymentMethodObject?: PaymentMethod
  sourceObject?: PaymentSource
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Refund = {
  amount?: number
  balanceTransaction?: string
  charge?: string
  created?: number
  currency?: string
  description?: string
  failureBalanceTransaction?: string
  failureReason?: string
  id?: string
  instructionsEmail?: string
  metadata?: {
    [key: string]: string
  }
  nextAction?: NextAction
  object?: string
  paymentIntent?: string
  reason?: string
  receiptNumber?: string
  sourceTransferReversal?: string
  status?: string
  transferReversal?: string
  balanceTransactionObject?: BalanceTransaction
  failureBalanceTransactionObject?: BalanceTransaction
  sourceTransferReversalObject?: TransferReversal
  transferReversalObject?: TransferReversal
  chargeObject?: Charge
  paymentIntentObject?: PaymentIntent
  testHelpers?: TestHelpers
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RefundCollection = {
  object?: string
  data?: Refund[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FeeRefund = {
  amount?: number
  balanceTransaction?: string
  created?: number
  currency?: string
  fee?: string
  id?: string
  metadata?: {
    [key: string]: string
  }
  object?: string
  balanceTransactionObject?: BalanceTransaction
  feeObject?: ApplicationFee
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FeeRefundCollection = {
  object?: string
  data?: FeeRefund[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ApplicationFee = {
  account?: string
  amount?: number
  amountRefunded?: number
  application?: string
  balanceTransaction?: string
  charge?: string
  created?: number
  currency?: string
  id?: string
  livemode?: boolean
  object?: string
  originatingTransaction?: string
  refunded?: boolean
  refunds?: FeeRefundCollection
  balanceTransactionObject?: BalanceTransaction
  originatingTransactionObject?: Charge
  chargeObject?: Charge
  applicationObject?: Application
  accountObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Evidence = {
  accessActivityLog?: string
  billingAddress?: string
  cancellationPolicy?: string
  cancellationPolicyDisclosure?: string
  cancellationRebuttal?: string
  customerCommunication?: string
  customerEmailAddress?: string
  customerName?: string
  customerPurchaseIp?: string
  customerSignature?: string
  duplicateChargeDocumentation?: string
  duplicateChargeExplanation?: string
  duplicateChargeId?: string
  productDescription?: string
  receipt?: string
  refundPolicy?: string
  refundPolicyDisclosure?: string
  refundRefusalExplanation?: string
  serviceDate?: string
  serviceDocumentation?: string
  shippingAddress?: string
  shippingCarrier?: string
  shippingDate?: string
  shippingDocumentation?: string
  shippingTrackingNumber?: string
  uncategorizedFile?: string
  uncategorizedText?: string
  cancellationPolicyObject?: File
  customerCommunicationObject?: File
  duplicateChargeDocumentationObject?: File
  serviceDocumentationObject?: File
  shippingDocumentationObject?: File
  customerSignatureObject?: File
  receiptObject?: File
  refundPolicyObject?: File
  uncategorizedFileObject?: File
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type EvidenceDetails = {
  dueBy?: number
  hasEvidence?: boolean
  pastDue?: boolean
  submissionCount?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Dispute = {
  amount?: number
  balanceTransactions?: BalanceTransaction[]
  charge?: string
  created?: number
  currency?: string
  evidence?: Evidence
  evidenceDetails?: EvidenceDetails
  id?: string
  isChargeRefundable?: boolean
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  networkReasonCode?: string
  object?: string
  paymentIntent?: string
  reason?: string
  status?: string
  chargeObject?: Charge
  paymentIntentObject?: PaymentIntent
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Charge = {
  alternateStatementDescriptors?: AlternateStatementDescriptors
  amount?: number
  amountCaptured?: number
  amountRefunded?: number
  application?: string
  applicationFee?: string
  applicationFeeAmount?: number
  authorizationCode?: string
  balanceTransaction?: string
  billingDetails?: BillingDetails
  calculatedStatementDescriptor?: string
  captured?: boolean
  created?: number
  currency?: string
  customer?: string
  description?: string
  destination?: string
  dispute?: string
  disputed?: boolean
  failureBalanceTransaction?: string
  failureCode?: string
  failureMessage?: string
  fraudDetails?: FraudDetails
  id?: string
  invoice?: string
  level3?: Level3
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  onBehalfOf?: string
  outcome?: Outcome
  paid?: boolean
  paymentIntent?: string
  paymentMethod?: string
  paymentMethodDetails?: PaymentMethodDetails
  radarOptions?: RadarOptions
  receiptEmail?: string
  receiptNumber?: string
  receiptUrl?: string
  refunded?: boolean
  refunds?: RefundCollection
  review?: string
  shipping?: ShippingDetails
  source?: PaymentSource
  sourceTransfer?: string
  statementDescriptor?: string
  statementDescriptorSuffix?: string
  status?: string
  transfer?: string
  transferData?: TransferData
  transferGroup?: string
  balanceTransactionObject?: BalanceTransaction
  failureBalanceTransactionObject?: BalanceTransaction
  customerObject?: Customer
  applicationObject?: Application
  applicationFeeObject?: ApplicationFee
  disputeObject?: Dispute
  invoiceObject?: Invoice
  onBehalfOfObject?: Account
  paymentIntentObject?: PaymentIntent
  reviewObject?: Review
  sourceTransferObject?: Transfer
  transferObject?: Transfer
  destinationObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type GeneratedFrom = {
  charge?: string
  setupAttempt?: string
  chargeObject?: Charge
  setupAttemptObject?: SetupAttempt
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SepaDebit = {
  bankCode?: string
  branchCode?: string
  country?: string
  fingerprint?: string
  generatedFrom?: GeneratedFrom
  last4?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Sofort = {
  bankCode?: string
  bankName?: string
  bic?: string
  country?: string
  generatedSepaDebit?: string
  generatedSepaDebitMandate?: string
  ibanLast4?: string
  preferredLanguage?: string
  verifiedName?: string
  generatedSepaDebitObject?: PaymentMethod
  generatedSepaDebitMandateObject?: Mandate
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type StripeAccount = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Wechat = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type WechatPay = {
  fingerprint?: string
  transactionId?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Zip = {
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PaymentMethodDetails = {
  achCreditTransfer?: AchCreditTransfer
  achDebit?: AchDebit
  acssDebit?: AcssDebit
  affirm?: Affirm
  afterpayClearpay?: AfterpayClearpay
  alipay?: Alipay
  auBecsDebit?: AuBecsDebit
  bacsDebit?: BacsDebit
  bancontact?: Bancontact
  blik?: Blik
  boleto?: Boleto
  card?: Card
  cardPresent?: CardPresent
  cashapp?: Cashapp
  customerBalance?: CustomerBalance
  eps?: Eps
  fpx?: Fpx
  giropay?: Giropay
  grabpay?: Grabpay
  ideal?: Ideal
  interacPresent?: InteracPresent
  klarna?: Klarna
  konbini?: Konbini
  link?: Link
  multibanco?: Multibanco
  oxxo?: Oxxo
  p24?: P24
  paynow?: Paynow
  paypal?: Paypal
  pix?: Pix
  promptpay?: Promptpay
  sepaCreditTransfer?: SepaCreditTransfer
  sepaDebit?: SepaDebit
  sofort?: Sofort
  stripeAccount?: StripeAccount
  type?: string
  usBankAccount?: UsBankAccount
  wechat?: Wechat
  wechatPay?: WechatPay
  zip?: Zip
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SingleUse = {
  amount?: number
  currency?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Mandate = {
  customerAcceptance?: CustomerAcceptance
  id?: string
  livemode?: boolean
  multiUse?: MultiUse
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodDetails?: PaymentMethodDetails
  singleUse?: SingleUse
  status?: string
  type?: string
  paymentMethodObject?: PaymentMethod
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Bancontact = {
  bankCode?: string
  bankName?: string
  bic?: string
  generatedSepaDebit?: string
  generatedSepaDebitMandate?: string
  ibanLast4?: string
  preferredLanguage?: string
  verifiedName?: string
  generatedSepaDebitObject?: PaymentMethod
  generatedSepaDebitMandateObject?: Mandate
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type PaymentMethod = {
  acssDebit?: AcssDebit
  affirm?: Affirm
  afterpayClearpay?: AfterpayClearpay
  alipay?: Alipay
  auBecsDebit?: AuBecsDebit
  bacsDebit?: BacsDebit
  bancontact?: Bancontact
  billingDetails?: BillingDetails
  blik?: Blik
  boleto?: Boleto
  card?: Card
  cardPresent?: CardPresent
  cashapp?: Cashapp
  created?: number
  customer?: string
  customerBalance?: CustomerBalance
  eps?: Eps
  fpx?: Fpx
  giropay?: Giropay
  grabpay?: Grabpay
  id?: string
  ideal?: Ideal
  interacPresent?: InteracPresent
  klarna?: Klarna
  konbini?: Konbini
  link?: Link
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  oxxo?: Oxxo
  p24?: P24
  paynow?: Paynow
  paypal?: Paypal
  pix?: Pix
  promptpay?: Promptpay
  radarOptions?: RadarOptions
  sepaDebit?: SepaDebit
  sofort?: Sofort
  type?: string
  usBankAccount?: UsBankAccount
  wechatPay?: WechatPay
  zip?: Zip
  customerObject?: Customer
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type BillingDetailsModel = {
  name?: string
  email?: string
  address?: Address
  paymentMethods?: PaymentMethod[]
  balance?: number
  currency?: string
}
export type StripeRedirectModel = {
  url?: string
  expires?: string
}
export type ReimbursementsModelObject = {
  reimbursementId?: string
  reimbursementUserId?: string
  organizationId?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  currency?: string
  periodStart?: string
  periodEnd?: string
  settled?: string
  status?: string
  amount?: number
  usage?: number
  entityId?: string
  entityType?: string
  payee?: ReimbursementUser
  payer?: Organization1
  asOf?: string
}
export type ChargeRecordModelOrganizationReimbursementUser = {
  chargeRecordId?: string
  usageRecordId?: string
  chargeType?: string
  usage?: number
  usageType?: string
  appliedRate?: number
  chargeCost?: number
  currency?: string
  organizationId?: string
  usageStart?: string
  usageEnd?: string
  assetType?: string
  assetId?: string
  usageSourceId?: string
  usageSource?: string
  measurand?: string
  payer?: Organization1
  payee?: ReimbursementUser
}
export type ReimbursementSummaryModel = {
  reimbursementUsers?: number
  organizationId?: string
  reimbursementAmount?: number
  reimbursementAmountMoMChange?: number
  currency?: string
  energyUseKwh?: number
  energyUseMoMChange?: number
  periodStart?: string
  periodEnd?: string
  asOf?: string
  lastBillingPeriod?: ReimbursementSummaryModel
}
export type LoginLink = {
  created?: number
  object?: string
  url?: string
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SourceTypes = {
  bankAccount?: number
  card?: number
  fpx?: number
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Available = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type ConnectReserved = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type InstantAvailable = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Issuing = {
  available?: Available[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Pending = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type Balance = {
  available?: Available[]
  connectReserved?: ConnectReserved[]
  instantAvailable?: InstantAvailable[]
  issuing?: Issuing
  livemode?: boolean
  object?: string
  pending?: Pending[]
  rawJsonObject?: JsonObject
  lastResponse?: StripeResponse
}
export type SimulatorDetailModel = {
  chargerId?: string
  ocppUrl?: string
  namespace?: string
  creationTime?: string
}
export type SimulatorCreationModel = {
  chargerId: string
  ocppUrl: string
  namespace?: string
}
export type PingPong = {
  ping?: string
}
export const {
  useGetVehiclesQuery,
  useUpdateVehicleMutation,
  useCreateVehicleWithMetadataMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useSignOutUserMutation,
  useResendMutation,
  useResetPasswordMutation,
  useEnableUserMutation,
  useDisableUserMutation,
  useGetRoutesForFleetQuery,
  useUpdateRouteMutation,
  useCreateRouteMutation,
  useUpdateOrganizationMutation,
  useUpdateFleetTermsMutation,
  useSaveFleetTermsMutation,
  useGetLocationsOfInterestForOrganizationQuery,
  useUpdateLocationOfInterestMutation,
  useCreateLocationOfInterestMutation,
  useEnableOrganizationIntegrationMutation,
  useDisableIntegrationMutation,
  useUpdateFleetMutation,
  useCreateFleet1Mutation,
  useAssignVehicleToFleetMutation,
  useUpdateAlarmMutation,
  useEnableAlarmsForOrganizationMutation,
  useDisableAlarmsForOrganizationMutation,
  useGetVehiclesByOrganizationIntegrationQuery,
  useOnboardVehiclesByOrganizationIntegrationMutation,
  useAddVehicleEventMutation,
  useInitiateMfaSetupQuery,
  useVerifySoftwareTokenMutation,
  useCreateTripNoteMutation,
  useAssignVehicleMutation,
  useOnboardOrganizationToStripeMutation,
  useSaveOrganizationLogoMutation,
  useGetApiKeysByOrganizationIdQuery,
  useProvisionApiKeyByOrganizationIdMutation,
  useGetIntegrationsByOrganizationIdQuery,
  useSaveOrganizationIntegrationMutation,
  useGetOrganizationCustomersQuery,
  useCreateFleetMutation,
  usePublishToSnsMutation,
  useAssignVehicleToBillingOrganizationMutation,
  useSaveNotificationMappingForAlarmsMutation,
  useDeleteNotificationMappingForAlarmsMutation,
  useGetVehicleQuery,
  useGetLatestVehicleWeatherForecastQuery,
  useGetTripsQuery,
  useGetTripQuery,
  useGetVehicleTelematicsQuery,
  useGetDailySummaryForVehicleQuery,
  useGetServiceableRangeForVehicleQuery,
  useGetMetricsForVehicleQuery,
  useGetAssetEventsQuery,
  useGetVehicleStatusBreakdownForVehiclesQuery,
  useGetTimespanDataForVehiclesQuery,
  useGetVehiclesBySiteQuery,
  useGetVehicleReportQuery,
  useGetMetricsForVehiclesQuery,
  useGetEnergyBreakdownDataForVehiclesQuery,
  useGetMetricsForVehicles1Query,
  useGetUserQuery,
  useGetUserTagMappingsQuery,
  useGetDropdownOptionsQuery,
  useGetTripNotesQuery,
  useGetTripReportQuery,
  useGetVehicleUtilizationQuery,
  useGetVehicleBreakdownQuery,
  useGetRouteByIdQuery,
  useDeleteRouteMutation,
  useGetRouteScheduleQuery,
  useGetStatusOfGeneratedReportQuery,
  useGetOrganizationQuery,
  useGetVehiclesForOrganizationQuery,
  useGetOrganizationUsersQuery,
  useGetTrips1Query,
  useGetOrganizationTermsQuery,
  useLoginToStripeQuery,
  useGetStripeIntentDetailQuery,
  useGetRoutesForOrganizationQuery,
  useGetIntegrationsByOrganizationIdAndIntegrationTypeQuery,
  useGetAllOcppTagsQuery,
  useGetFleetsQuery,
  useGetAllOrganizationCustomersQuery,
  useGetOrganizationConfigQuery,
  useGetOrganizationApiKeyByApiKeyQuery,
  useGetLocationOfInterestQuery,
  useDeleteLocationOfInterestMutation,
  useGetIntegrationsQuery,
  useGetFleetQuery,
  useGetChartValuesQuery,
  useGetAlarmsForFleetQuery,
  useGetNotificationMappingForAlarmsQuery,
  useGetTriggeredAlarmsQuery,
  useGetChargeProfilesQuery,
  useUpdateChargeProfileMutation,
  useSaveChargeProfileMutation,
  useClearChargingProfileMutation,
  useGetDepotsQuery,
  useUpdateDepotMutation,
  useCreateDepotMutation,
  useGetDepotEnergyLimitsQuery,
  useUpdateDepotEnergyLimitsMutation,
  useGetChargersQuery,
  useUpdateChargerMutation,
  useCreateChargerMutation,
  useSetChargeProfileMutation,
  useCmsOcppTagsGetTagsQuery,
  useCmsOcppTagsAddTagsMutation,
  useStopChargingTransactionMutation,
  useStartChargingTransactionMutation,
  useResetChargerMutation,
  useCmsChargersGetConfigurationKeysQuery,
  useCmsChargersSetConfigurationKeyMutation,
  useGetChargingTransactionsQuery,
  useGetTransactionByIdQuery,
  useGetTransactionMeterValuesQuery,
  useCmsChargingTransactionsGetMeterValueIntervalDataQuery,
  useGetPowerUtilizationQuery,
  useGetChargingSummaryQuery,
  useGetTransactionsReportQuery,
  useGetStatusOfGeneratedReport1Query,
  useGetChargeProfileQuery,
  useGetLiveUtilizationQuery,
  useGetDepotQuery,
  useDeleteDepotMutation,
  useGetDepotUtilizationQuery,
  useGetDepotUptimeQuery,
  useGetDepotStatusQuery,
  useGetChargerQuery,
  useDeleteChargerMutation,
  useGetChargerUtilizationTimelineQuery,
  useGetChargerUptimeQuery,
  useGetMappedChargerProfilesQuery,
  useGetChargerFaultsQuery,
  useGetChargerConnectorsQuery,
  useGetCapabilitySetQuery,
  useCmsUtilizationGetUtilizationQuery,
  useGetChargersTableDataQuery,
  useGetSummaryDataQuery,
  useCmsTimespanGetHistoricalStatusesQuery,
  useGetLatestStatusSummaryQuery,
  useCmsChargerFaultsGetFaultsQuery,
  useGetManufacturersQuery,
  useGetManufacturerMakeModelsQuery,
  useGetDepotEventsByDepotIdQuery,
  useUpdateDepotEventMutation,
  useSaveDepotEventMutation,
  useGetScheduledChargerSessionQuery,
  useUpdateScheduledChargerSessionMutation,
  useCancelScheduledChargerSessionMutation,
  useGetLoadBalancerEventsQuery,
  useCreateLoadBalancerEventMutation,
  useGetScheduledChargerSessionsQuery,
  useCreateScheduledChargerSessionMutation,
  useGetScheduledChargerSessionDetailsQuery,
  useSaveScheduledChargerSessionDetailsMutation,
  useGetLoadBalancerEventQuery,
  useGetSmartPricesQuery,
  useGetScheduledDepotPowerQuery,
  useGetDepotEventByIdQuery,
  useCancelDepotEventMutation,
  useGetScheduledChargerSessionsByDepotQuery,
  useGetScheduledChargerSessionTelemetryQuery,
  useGetApiVehiclesByIdTimeToSocQuery,
  useGetApiTransactionsTimeToSocQuery,
  useAcknowledgeSentNotificationMutation,
  useGetAlertsByOrganizationIdQuery,
  useUpdateAlertByOrganizationsMutation,
  useCreateAlertByOrganizationsMutation,
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useGetAlertByAlertIdQuery,
  useGetPropertiesQuery,
  useGetWorkplaceUserQuery,
  useUpdateWorkplaceUserMutation,
  useDeleteWorkplaceUserMutation,
  useGetWorkplaceChargingRateByIdQuery,
  useUpdateWorkplaceChargingRatesMutation,
  useSaveTariffMutation,
  useGetWorkplaceUsersQuery,
  useSaveWorkplaceUserMutation,
  useGetWorkplaceChargingRatesQuery,
  useCreateWorkplaceChargingRatesMutation,
  useOverrideWorkplaceChargingRateMutation,
  useGetWorkplaceChargingConfigurationQuery,
  useSaveWorkplaceChargingConfigurationMutation,
  useUpdateWorkplaceChargingRecordStatusesMutation,
  useProcessStripeEventMutation,
  useGetReimbursementUsersQuery,
  useSaveReimbursementUserMutation,
  useGetReimbursementConfigurationQuery,
  useSaveReimbursementConfigurationMutation,
  useBulkUpdateReimbursementUsersMutation,
  useGetWorkplaceChargingUsageQuery,
  useGetWorkplaceSummaryQuery,
  useGetWorkplaceChargingRecordsQuery,
  useGetStripeIntentDetail1Query,
  useGetPaymentMethodQuery,
  useGetPortalLoginQuery,
  useGetUserInvoicesQuery,
  useGetCheckoutSessionQuery,
  useGetTariffsQuery,
  useGetReimbursementsQuery,
  useGetReimbursementUserQuery,
  useDeleteReimbursementUserMutation,
  useGetReimbursementUsageSummaryQuery,
  useGetReimbursementSummaryQuery,
  useGetReimbursements1Query,
  useGetPortalQuery,
  useGetLoginSessionQuery,
  useGetOrganizationBalanceQuery,
  useListSimulatorsQuery,
  useCreateSimulatorMutation,
  useGetContainerQuery,
  useDeleteContainerMutation,
  usePingQuery,
  useSimulatorProxyRequestQuery,
  useSimulatorProxyRequest3Mutation,
  useSimulatorProxyRequest2Mutation,
  useSimulatorProxyRequest5Mutation,
  useSimulatorProxyRequest6Mutation,
  useSimulatorProxyRequest1Mutation,
  useSimulatorProxyRequest4Mutation,
} = injectedRtkApi
