import { Dayjs } from 'dayjs'
import { formatEmissionsFromSourceAttributes, Tile } from '@synop-react/common'
import { RootAPI, useUserPrefs, VehicleMetrics } from '@synop-react/api'
import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'

const { useGetMetricsForVehicleQuery } = RootAPI.synopRootAPI

export type CarbonSavedTileProps = {
  vehicleId: string
  from: Dayjs
  to?: Dayjs
}

export const CarbonSavedTile = ({ vehicleId, from }: CarbonSavedTileProps) => {
  const { tzDayjs } = useUserPrefs()
  const currentDate = useMemo(
    () => tzDayjs(from).format('YYYY-MM-DD'),
    [from, tzDayjs]
  )

  const { data: dailyMetrics } = useGetMetricsForVehicleQuery({
    id: vehicleId,
    date: currentDate,
  })

  const carbonSaved = formatEmissionsFromSourceAttributes(
    (dailyMetrics as VehicleMetrics)?.carbonEmissionsSaved
  )
  return (
    <Tile title="Carbon Saved">
      <Stack
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <Typography variant="h4">{carbonSaved}</Typography>
      </Stack>
    </Tile>
  )
}

export default CarbonSavedTile
