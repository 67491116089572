import dayjs from 'dayjs'

type ValidateTimeRangeProps = {
  from: dayjs.Dayjs
  to: dayjs.Dayjs
}

export const validateTimeRange =
  ({ from, to }: ValidateTimeRangeProps) =>
  (maxRange?: number) => {
    return {
      get isFromBeforeTo() {
        return from.isSameOrBefore(to, 'day')
      },
      get isWithinMaxRange() {
        if (!maxRange) return true
        return to.diff(from, 'day') <= maxRange
      },
      get isValid() {
        return this.isFromBeforeTo && this.isWithinMaxRange
      },
      get timeRangeErrorMessages() {
        const messages = []
        if (!this.isFromBeforeTo) {
          messages.push('From date must be before To date.')
        }
        if (!this.isWithinMaxRange) {
          messages.push(`Date range must be within ${maxRange} days.`)
        }
        return messages
      },
    }
  }
