import { ApexOptions } from 'apexcharts'
import { Box, Grid } from '@mui/material'
import {
  Cask,
  CustomSingleDateRange,
  emDash,
  TimeRangeProvider,
  useFormat,
  useTimeRange,
} from '@synop-react/common'
import { isNumber } from 'lodash'
import { usePalette } from '@synop-react/theme'
import { useUserPrefs } from '@synop-react/api'
import Chart from 'react-apexcharts'
import useVehicleAirTemp from './hooks/useVehicleAirTemp'
import useVehicleSoc from './hooks/useVehicleSoc'
import useVehicleSpeed from './hooks/useVehicleSpeed'

export type VehicleTelemetryChartProps = {
  vehicleId: string
}

export const VehicleTelemetryChart = (props: VehicleTelemetryChartProps) => {
  return (
    <TimeRangeProvider
      defaultTimeResolution="CUSTOM"
      options={{ useEndOfCurrentDay: true }}
      ranges={['CUSTOM']}
    >
      <VehicleTelemetryChartCask {...props} />
    </TimeRangeProvider>
  )
}

const VehicleTelemetryChartCask = ({
  vehicleId,
}: VehicleTelemetryChartProps) => {
  const { from, to } = useTimeRange()
  const { formatDateTime } = useFormat()
  const { preferredTimeFormat } = useUserPrefs()
  const { charting, palette } = usePalette()

  const timeFormat =
    preferredTimeFormat === 'hh:mm' ? 'hh:mm tt' : preferredTimeFormat

  const vehicleSpeed = useVehicleSpeed({ vehicleId, from, to })
  const vehicleSoc = useVehicleSoc({ vehicleId, from, to })
  const vehicleTemperature = useVehicleAirTemp({ vehicleId, from, to })

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      type: 'line',
      selection: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: 'SOC',
        },
        max: 100,
        min: 0,
        labels: {
          formatter: function (val) {
            return val?.toFixed(0) || emDash
          },
        },
      },
      {
        title: {
          text: 'Speed (' + vehicleSpeed.speedUnit + ')',
        },
        labels: {
          formatter: function (val) {
            return val?.toFixed(0) || emDash
          },
        },
      },
    ],
    xaxis: {
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        format: timeFormat,
      },
      min: from.toDate().getTime(),
      max: to.toDate().getTime(),
    },

    stroke: {
      curve: 'smooth',
      width: [1, 1, 2],
    },
    fill: {
      opacity: [0.25, 0.25, 1],
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        bottom: 0,
        left: 30,
        right: 30,
      },
    },
    legend: {
      horizontalAlign: 'center',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return val?.toFixed(0) || emDash
        },
      },
      x: {
        format: 'dd MMM yyyy ' + timeFormat,
        show: true,
      },
    },
  }

  const speed = vehicleSpeed.speedOverTime.map((elem) => [
    new Date(elem.x).getTime(),
    elem.y,
  ])

  const soc = vehicleSoc.socOverTime.map((elem) => [
    new Date(elem.x).getTime(),
    elem.y,
  ])

  const temperature = vehicleTemperature.airTempOverTime.map((elem) => [
    new Date(elem.x).getTime(),
    elem.y,
  ])

  // Forward-propagate temperature values to fill in nulls
  let lastTemp: number
  temperature.forEach((temp, index) => {
    const [, value] = temp

    if (index === 0 || isNumber(value)) {
      lastTemp = value as number
    } else {
      temp[1] = lastTemp
    }
  })

  const series = [
    {
      name: 'SoC (%)',
      color: palette.success.main,
      type: 'area',
      data: soc,
    },
    {
      name: 'Speed (' + vehicleSpeed.speedUnit + ')',
      type: 'area',
      color: charting[1].main,
      data: speed,
    },
    {
      name: 'Temperature (' + vehicleTemperature.airTempUnit + ')',
      type: 'line',
      color: palette.error.main,
      data: temperature,
    },
  ]

  return (
    <Cask
      Actions={[
        <Grid item>
          <CustomSingleDateRange disableFuture />
        </Grid>,
      ]}
      subtitle={formatDateTime(from, to).fromToDayOnDate}
      title="Daily Details"
    >
      <Box sx={{ maxHeight: '380px' }}>
        <Chart height={320} options={options} series={series} type="line" />
      </Box>
    </Cask>
  )
}
