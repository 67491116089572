import { Calendar } from '../Icons'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { TextFieldProps } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useUserPrefs } from '@synop-react/api'

export type DateSelectorProps = {
  label?: string
  handleSelectedDate: (date: Date) => void
  variant?: TextFieldProps['variant']
  disableFuture?: boolean
  disablePast?: boolean
  minDate?: Date
  maxDate?: Date
}

export function DateSelector({
  disableFuture = false,
  disablePast = false,
  label,
  handleSelectedDate,
  variant = 'standard',
  minDate,
  maxDate,
}: DateSelectorProps) {
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const [value, setValue] = useState(tzDayjs())
  const updateDate = useCallback(
    (date: Dayjs | null) => {
      if (!date) return
      setValue(date)
      handleSelectedDate(date.toDate())
    },
    [handleSelectedDate]
  )

  useEffect(() => {
    if (maxDate && value && value.isAfter(tzDayjs(maxDate)))
      updateDate(tzDayjs(maxDate))
  }, [tzDayjs, updateDate, maxDate, value])

  return (
    <DatePicker
      maxDate={maxDate && tzDayjs(maxDate)}
      minDate={minDate && tzDayjs(minDate)}
      {...{ disableFuture, disablePast }}
      format={preferredDateFormat}
      label={label}
      onChange={(newValue) => updateDate(newValue)}
      slotProps={{
        textField: {
          variant,
          color: 'primary',
          size: 'small',
          sx: {
            maxWidth: '160px',
            input: { color: 'primary.main', fontWeight: 600 },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'primary.main',
              },
            },
          },
        },
      }}
      slots={{ openPickerIcon: Calendar }}
      value={tzDayjs(value)}
    />
  )
}

export default DateSelector
