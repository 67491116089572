import { isObject } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useLocalStorage, useUpdateEffect } from 'react-use'
import { useLocation } from 'react-router-dom'
import { useLogger } from './useDebug'

type StoredValue<T, C> = { context: C; value: T }
type UseLocalStorageStateReturn<T, C> = [T, UpdateCallback<T, C>]

// The context argument must be omitted if the stored value isn't tracking one
type UpdateCallback<T, C> = C extends undefined
  ? (value: T) => void
  : (value: T, context: C) => void

type InvariantFn<T, C> = (value: StoredValue<T, C>) => string | null
type Options<T, C> = OptionsWithDefault<T> | OptionsWithoutDefault<T, C>
type OptionsWithDefault<T> = { defaultValue: T; skip?: boolean }
type OptionsWithoutDefault<T, C> = {
  invariantFn?: InvariantFn<T, C>
  skip?: boolean
}

// If no initial value is provided, the return type is `T | null`
export function useLocalStorageState<T, C = undefined>(
  key: string,
  options?: OptionsWithoutDefault<T, C>
): UseLocalStorageStateReturn<T | null, C>

// If an initial value is provided, the return type is `T`
export function useLocalStorageState<T, C = undefined>(
  key: string,
  options: OptionsWithDefault<T>
): UseLocalStorageStateReturn<T, C>

export function useLocalStorageState<T, C>(
  key: string,
  options: Options<T, C> = {}
) {
  const logger = useLogger('useLocalStorageState')

  // Parse options
  const [defaultValue, invariantFn, skip] = useMemo(() => {
    const skip = options.skip ?? false
    if ('defaultValue' in options) {
      return [options.defaultValue, null, skip]
    } else {
      return [null, options.invariantFn, skip]
    }
  }, [options])

  const [value, setValue] = useState(defaultValue)
  const fullKey = [useLocation().pathname, key].join('/')

  // A `defaultValue` may not be provided alongside an `invariantFn`, so
  // if a default value is provided we know the context is `undefined`. Thus
  // this is a safe cast.
  const initialValue = defaultValue
    ? ({ value: defaultValue } as StoredValue<T, C>)
    : null

  // If `skip` is true, `storedValue` will always be null. This ensures that the
  // key remains outside of LocalStorage.
  const [storedValue, setStoredValue, removeValue] =
    useLocalStorage<StoredValue<T, C> | null>(
      fullKey,
      skip ? null : initialValue
    )

  const evictKey = useCallback(
    (...reasons: string[]) => {
      const msg = `evicting key "${fullKey}" from LocalStorage`
      logger.info([msg, ...reasons].join(': '))
      removeValue()
    },
    [fullKey, logger, removeValue]
  )

  const evictIfViolatesInvariant = useCallback(
    (value?: StoredValue<T, C> | null) => {
      const evictionReason = value && invariantFn?.(value)
      if (evictionReason) {
        evictKey('invariant violated', evictionReason)
      }
    },
    [evictKey, invariantFn]
  )

  const update = useCallback(
    (newValue: T, context: C) => {
      setValue(newValue)
      if (!skip) setStoredValue({ value: newValue, context })
    },
    [setStoredValue, skip]
  )

  // On the first render, check if the stored value is valid and evict it if not. On top
  // of that, check if it has the expected format (i.e. a `value` key). If not evict.
  const checkedValidity = useRef(false)
  if (!checkedValidity.current && storedValue) {
    checkedValidity.current = true

    const unvalidated = storedValue as unknown
    if (isObject(unvalidated) && 'value' in unvalidated) {
      evictIfViolatesInvariant(storedValue)
    } else {
      evictKey('invalid format')

      // If there's a default value, set it
      if (defaultValue) {
        update(defaultValue, undefined as unknown as C)
      }
    }
  }

  // Whenever the eviction function changes (i.e. the invariant function changes)
  // call it to see if the value in LocalStorage is still valid
  useUpdateEffect(() => {
    evictIfViolatesInvariant(storedValue)
  }, [evictIfViolatesInvariant])

  const returnValue = useMemo(() => {
    // Not using local storage. Return the value from React state
    if (skip) return value
    // A (validated) value is in local storage
    if (storedValue?.value) return storedValue.value
    // A default value was provided. This can happen during the first render when a key
    // had to be evicted. The `update` function was called with the default value, but
    // the `storedValue` variable still has the initial value pulled from local storage.
    if (defaultValue) return defaultValue
    // No value in local storage and no default value. Return null
    return null
  }, [skip, defaultValue, storedValue, value])

  return [returnValue, update]
}
