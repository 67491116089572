import * as yup from 'yup'
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import {
  DepotAutocomplete,
  FormField,
  OverlayDeprecated,
  PlusCircle,
  RoleAutocomplete,
  User,
} from '@synop-react/common'
import { FieldError, useForm, useWatch } from 'react-hook-form'
import { KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  RootAPI,
  useCurrentOrgId,
  useCurrentUser,
  useDepotDetailsFromPoll,
} from '@synop-react/api'
import { useRollbar } from '@rollbar/react'
import { yupResolver } from '@hookform/resolvers/yup'

const {
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useResetPasswordMutation,
  useResendMutation,
  useDisableUserMutation,
  useEnableUserMutation,
} = RootAPI.synopRootAPI
/* Todo: Add the useSignOutUserMutation to the list of admin
 * actions that can be performed on a user. The current endpoint
 * returns a successfull response but I do not believe it currently
 * inhibits the user from acting on the site. There is likely additional
 * work to be done here to receive logout messages on the front end or
 * invalidate the user's jwt-token on the backend */

const schema = yup
  .object({
    name: yup.string().required('Required field'),
    email: FormField.emailValidator().required('Required field'),
  })
  .required()

export interface UsersOverlayProps {
  isOpen: boolean
  userId: string | null
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => void
}

type AdminMessage = {
  severity: 'success' | 'error'
  message: string
}

type UserFormData = {
  name: string
  email: string
  phoneNumber: string
  organizationId: string
  rfidTag?: string
  sites?: RootAPI.DepotModel | null
  roles?: RoleAutocomplete.RoleOption | null
}

export function UsersOverlay({
  isOpen,
  userId = null,
  setIsOpen,
  onClose = () => ({}),
}: UsersOverlayProps) {
  const theme = useTheme()
  const orgId = useCurrentOrgId()
  const rollbar = useRollbar()
  const { isAdmin } = useCurrentUser()

  const [rfidTags, setRFIDTags] = useState<string[]>([])

  const { data: editUser, isLoading: isLoadingUser } = useGetUserQuery(
    {
      id: userId as string,
    },
    {
      skip: !userId,
    }
  )
  const isUserForceChangePassword = useMemo(() => {
    return editUser?.status === 'FORCE_CHANGE_PASSWORD'
  }, [editUser])

  const userSiteId = editUser && editUser?.sites ? editUser?.sites[0] : ''
  const {
    getDepot: { data: defaultDepot, isLoading: isLoadingSite },
  } = useDepotDetailsFromPoll({
    depotId: userSiteId,
    disablePolling: true,
  })

  const {
    control,
    formState: { errors, touchedFields, isValid },
    handleSubmit,
    setValue,
    reset,
    trigger,
  } = useForm<UserFormData>({
    defaultValues: {
      name: editUser?.name,
      email: editUser?.email,
      phoneNumber: editUser?.phoneNumber,
      organizationId: editUser?.organizationId,
      roles: editUser?.roles?.length
        ? { id: 0, name: editUser.roles[0] }
        : null,
      sites: null,
      rfidTag: '',
    },
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const [saveUser, saveUserResponse] = useCreateUserMutation()
  const [updateUser, updateUserResponse] = useUpdateUserMutation()
  const [resendPassword, resendPasswordResponse] = useResendMutation()
  const [resetPassword, resetPasswordResponse] = useResetPasswordMutation()
  const [disableUser, disableUserResponse] = useDisableUserMutation()

  const [enableUser, enableUserResponse] = useEnableUserMutation()
  const [adminMessage, setAdminMessage] = useState<AdminMessage>()

  useEffect(() => {
    if (resetPasswordResponse.isSuccess || resendPasswordResponse.isSuccess) {
      setAdminMessage({
        severity: 'success',
        message: 'Password reset email sent.',
      })
    } else if (
      resetPasswordResponse.isError ||
      resendPasswordResponse.isError
    ) {
      setAdminMessage({
        severity: 'error',
        message: 'Error resetting password.',
      })
    }
  }, [resetPasswordResponse, resendPasswordResponse])

  useEffect(() => {
    if (disableUserResponse.isSuccess) {
      setAdminMessage({ severity: 'success', message: 'User disabled.' })
    } else if (disableUserResponse.isError) {
      setAdminMessage({ severity: 'error', message: 'Error disabling user.' })
    }
  }, [disableUserResponse])

  useEffect(() => {
    if (enableUserResponse.isSuccess) {
      setAdminMessage({ severity: 'success', message: 'User enabled.' })
    } else if (enableUserResponse.isError) {
      setAdminMessage({ severity: 'error', message: 'Error enabling user.' })
    }
  }, [enableUserResponse])

  const newRFIDTag = useWatch({ control, name: 'rfidTag' })

  useEffect(() => {
    if (!isLoadingSite && editUser) {
      const rfidTagValues = editUser?.rfidTags?.map(
        (tag) => tag?.ocppTagValue ?? ''
      )
      setRFIDTags(rfidTagValues ?? [])
      reset({
        name: editUser?.name,
        email: editUser?.email,
        phoneNumber: editUser?.phoneNumber,
        organizationId: editUser?.organizationId,
        roles: editUser?.roles?.length
          ? { id: 0, name: editUser.roles[0] }
          : null,
        sites: defaultDepot ? defaultDepot : null,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser, isLoadingSite, defaultDepot])

  useEffect(() => {
    if (updateUserResponse.isSuccess || saveUserResponse.isSuccess) {
      setIsOpen(false)
    }
  }, [saveUserResponse, setIsOpen, updateUserResponse])

  const onSubmit = async (formData: UserFormData) => {
    const siteId = formData.sites?.depotId
    const userTags: RootAPI.UserTag[] = rfidTags.map((tag) => {
      const userTag = editUser?.rfidTags?.find((t) => t.ocppTagValue === tag)
      if (userTag) return userTag
      return {
        ocppTagType: 'RFID',
        ocppTagValue: tag,
        ocppTagUseCase: 'FLEET_CHARGING',
        created: new Date().toISOString(),
        userId: editUser?.id ?? '',
      }
    })
    try {
      if (orgId && editUser && editUser.id) {
        await updateUser({
          userModel: {
            ...editUser,
            name: formData.name,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            organizationId: orgId as string,
            roles: formData.roles?.name ? [formData.roles?.name] : [],
            rfidTags: userTags,
            sites: siteId ? [siteId] : [],
          },
        }).unwrap()
      } else {
        await saveUser({
          userModel: {
            id: 'NOT REQUIRED',
            name: formData.name,
            phoneNumber: formData.phoneNumber,
            email: formData.email,
            organizationId: orgId as string,
            roles: formData.roles?.name ? [formData.roles?.name] : [],
            rfidTags: userTags,
            sites: siteId ? [siteId] : [],
            isActive: true,
          },
        }).unwrap()
      }
      setIsOpen(false)
    } catch (error) {
      if (error instanceof Error) {
        rollbar.error(error.message, error)
      }
    }
  }

  const addRFIDTag = async () => {
    const isValid = await trigger(['rfidTag'])

    if (isValid && newRFIDTag) {
      setRFIDTags([...rfidTags, newRFIDTag])
      setValue('rfidTag', '')
    }
  }

  const removeRFIDTag =
    (removeTag = '') =>
    () => {
      setRFIDTags(rfidTags.filter((rfidTag) => rfidTag !== removeTag))
    }

  const RFIDChips = rfidTags.length ? (
    <Grid item xs={12}>
      <Stack direction="row" spacing={2}>
        {rfidTags.map((rfidTag) => {
          return (
            <Chip
              key={rfidTag}
              color="primary"
              label={rfidTag}
              onDelete={removeRFIDTag(rfidTag)}
              variant="outlined"
            />
          )
        })}
      </Stack>
    </Grid>
  ) : null

  const overlayTitle = userId ? 'Edit User' : 'New User'
  const overlayIcon = userId ? User : PlusCircle
  const overlayButton = userId ? 'Save' : 'Create'

  const overlayActions = [
    <LoadingButton
      color="primary"
      disabled={!isAdmin || !isValid}
      loading={saveUserResponse.isLoading || updateUserResponse.isLoading}
      onClick={handleSubmit(onSubmit)}
      variant="contained"
    >
      {overlayButton}
    </LoadingButton>,
  ]

  const adminActions = [
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <LoadingButton
        color="primary"
        loading={
          resetPasswordResponse.isLoading || resendPasswordResponse.isLoading
        }
        onClick={() => {
          if (editUser?.id) {
            if (isUserForceChangePassword) {
              resendPassword({ id: editUser.id })
            } else {
              resetPassword({ id: editUser.id })
            }
          }
        }}
        sx={{ m: 1 }}
        variant="outlined"
      >
        Reset Password
      </LoadingButton>
      <LoadingButton
        color={editUser?.isActive ? 'error' : 'primary'}
        loading={enableUserResponse.isLoading || disableUserResponse.isLoading}
        onClick={() => {
          if (editUser?.id) {
            if (editUser?.isActive) {
              disableUser({ id: editUser.id })
            } else {
              enableUser({ id: editUser.id })
            }
          }
        }}
        sx={{ m: 1 }}
        variant="outlined"
      >
        {editUser && editUser.isActive ? 'Disable User' : 'Enable User'}
      </LoadingButton>
    </Box>,
  ]
  if (isAdmin && userId) overlayActions.push(...adminActions)

  return (
    <>
      <OverlayDeprecated
        isLoading={isLoadingUser}
        isOpen={isOpen}
        onClose={onClose}
        OverlayActions={overlayActions}
        setIsOpen={setIsOpen}
        title={overlayTitle}
        TitleIcon={overlayIcon}
      >
        {adminMessage && (
          <Alert
            onClose={() => setAdminMessage(undefined)}
            severity={adminMessage.severity}
          >
            {adminMessage.message}
          </Alert>
        )}
        <form>
          <Grid container spacing={theme.spacing(2)}>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.name}
                fullWidth
                id="name"
                label="Name"
                touched={Boolean(touchedFields.name)}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField.TextFormField
                control={control}
                error={errors.email}
                fullWidth
                id="email"
                label="Email Address"
                touched={Boolean(touchedFields.email)}
                type="email"
              />
            </Grid>
            <Grid item xs={6}>
              <RoleAutocomplete.Select
                control={control}
                error={errors.roles as FieldError}
                fullWidth
                id="roles"
                isAdmin={isAdmin}
                label="Roles"
                touchedField={Boolean(touchedFields.roles)}
              />
            </Grid>
            <Grid item xs={6}>
              <DepotAutocomplete.Select
                control={control}
                error={errors.sites as FieldError}
                fleetId={orgId || ''}
                fullWidth
                id="sites"
                touchedField={Boolean(touchedFields.sites)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">RFID Tags</Typography>
            </Grid>
            {RFIDChips}
            <Grid item xs={3}>
              <FormField.TextFormField
                control={control}
                id="rfidTag"
                label="RFID Tag"
                onKeyDown={(e: KeyboardEvent) => {
                  if (e.code === 'Enter') addRFIDTag()
                }}
                placeholder="Enter RFID Tag"
                touched={Boolean(touchedFields.rfidTag)}
                type="text"
              />
            </Grid>
            <Grid alignSelf="center" item xs={3}>
              <Button onClick={addRFIDTag} size="small" variant="outlined">
                ADD NEW
              </Button>
            </Grid>
          </Grid>
        </form>
      </OverlayDeprecated>
    </>
  )
}

export default UsersOverlay
