import { MapProps } from '@synop-react/common'
import { RootAPI, useDepotDetailsFromPoll } from '@synop-react/api'

import { Box, Skeleton } from '@mui/material'
import { DepotMap } from '../DepotMap'
import { Feature, Polygon } from '@turf/helpers'
import { GeofenceProperties } from '@synop-react/types'

export type DepotlMapTileProps = {
  depotId?: string
  geofence?: Feature<Polygon, GeofenceProperties>
  latitude?: number
  longitude?: number
} & Omit<MapProps, 'id' | 'defaultMaxZoom' | 'defaultMinZoom' | 'boundedData'>

export const DEPOT_MARKER_TYPE = 'DEPOT_MARKER_TYPE'

export function DepotMapTile({
  defaultCursor: cursor = 'default',
  geofence,
  depotId = '',
  longitude,
  latitude,
  children,
  canZoom = false,
  ...mapProps
}: DepotlMapTileProps) {
  const {
    getDepot: { data: depot, isLoading: isLoadingDepot },
  } = useDepotDetailsFromPoll({ depotId })

  const {
    latitude: depotLat,
    longitude: depotLng,
    geofence: depotGeofence,
  } = (depot || {}) as RootAPI.DepotModel & {
    latitude: number
    longitude: number
  }
  const displayedGeofence = geofence || depotGeofence
  const markerLocation =
    latitude && longitude
      ? { latitude, longitude }
      : { latitude: depotLat, longitude: depotLng }

  return !isLoadingDepot ? (
    <Box sx={{ width: '100%', height: '368px' }}>
      <DepotMap
        canZoom={canZoom}
        cursor={cursor}
        defaultCursor={cursor}
        depotId={depotId}
        geofence={displayedGeofence}
        {...markerLocation}
        maxMarkerZoom={20}
        minGeofenceZoom={1}
        {...mapProps}
      >
        {children}
      </DepotMap>
    </Box>
  ) : (
    <Skeleton sx={{ height: '368px', width: '100%' }} />
  )
}

export default DepotMapTile
