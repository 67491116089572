import {
  CreateChargingSessionOverlayProps,
  CreateOrEditDepotEventOverlay,
  EventGroupingKey,
  EventOption,
  TimelineCask,
  useAvailableOption,
  useDepotEventOption,
  useDisplayedDateWithTimeRange,
  ViewDepotEventOverlay,
} from '@synop-react/common'
import {
  GenericEvent,
  ScheduledEvent,
  useDepotDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'
import useScheduledChargeOption from '../ChargingTimeline/useScheduledChargeOption'
import useUnscheduleChargerOption from '../ChargingTimeline/useUnscheduledChargerOption'
export interface DepotEventTimelineProps {
  depotId: string
}
type CreateOverlayProps = {} & Pick<
  CreateChargingSessionOverlayProps,
  'setIsOpen' | 'isOpen'
> &
  Partial<ScheduledEvent>
export function DepotEventTimeline({ depotId }: DepotEventTimelineProps) {
  const { tzDayjs } = useUserPrefs()
  const { displayedDate, setDisplayedDate, to, from } =
    useDisplayedDateWithTimeRange()
  const {
    getDepotEvents: {
      isLoading: isLoadingEvents,
      isError: error,
      data: depotEvents = [],
    },
    getDepot: { data: depot },
  } = useDepotDetailsFromPoll({
    depotId,
    from,
    to,
  })

  const scheduledChargeOptions = useScheduledChargeOption({ depotId })
  const unscheduledChargeOption = useUnscheduleChargerOption({ depotId })
  const availableChargeOption = useAvailableOption({
    renderOverlay: ({
      setIsOpen,
      isOpen,
      scheduledEnd,
    }: CreateOverlayProps) => {
      return tzDayjs(scheduledEnd).isAfter(tzDayjs()) ? (
        <CreateOrEditDepotEventOverlay
          defaultDepot={depot}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : null
    },
  })
  const depotEventOptions = useDepotEventOption({
    depotId,
    subtitle: ({ curtailedSiteLimit }) => {
      return `${Math.abs(curtailedSiteLimit as number)} kW ${
        curtailedSiteLimit != undefined && curtailedSiteLimit >= 0.0
          ? 'Import'
          : 'Export'
      }`
    },
    renderOverlay: ({ eventId, isOpen, setIsOpen, scheduledEnd }) => {
      return tzDayjs().isAfter(scheduledEnd) ? (
        <ViewDepotEventOverlay
          depotId={depotId}
          eventId={eventId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <CreateOrEditDepotEventOverlay
          defaultDepot={depot}
          eventId={eventId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )
    },
  })
  const isFirstSessionLoad = isLoadingEvents && error === undefined
  const isLoading = isFirstSessionLoad || isLoadingEvents
  const options = {
    ...availableChargeOption,
    ...scheduledChargeOptions,
    ...unscheduledChargeOption,
    ...depotEventOptions,
    PREDICTED: {
      barColor: 'green',
      caption: { title: 'noop' },
      // tooltip: { title: 'noop', details: [{ data: 'hello' } ],
    } as EventOption,
  }
  const getSiteKey: EventGroupingKey = (event: GenericEvent) => {
    const { depotId } = event
    return `${depotId}`
  }
  const spoofedEvents = depotEvents.map((event) => {
    return {
      eventId: event.eventId,
      depotId: event.depotId,
      eventType: 'SITE_LIMIT',
      scheduledStart: event.eventStartDate,
      scheduledEnd: event.eventEndDate,
      curtailedSiteLimit:
        event.schedule.intervals[0]?.curtailedSiteLimit != undefined &&
        event.schedule.intervals[0]?.curtailedSiteLimit != null
          ? event.schedule.intervals[0]?.curtailedSiteLimit
          : null,
      eventRecurrence: event.eventRecurrence,
    }
  }) as GenericEvent[]

  return (
    <TimelineCask
      displayedDate={displayedDate}
      eventIds={[depotId]}
      eventOptions={options}
      groupingKey={getSiteKey}
      isLoading={isLoading}
      scheduledEvents={spoofedEvents}
      setDisplayedDate={setDisplayedDate}
      title={'Site Schedule'}
      tooltip={
        'Schedule site-wide power level limits here. The system will load balance all unscheduled power demand underneath the limits configured. This will affect all charging sessions that do not have reserved power or an optimized session scheduled.'
      }
    />
  )
}
export default DepotEventTimeline
