import {
  Feature,
  featureCollection,
  Point,
  point,
  Polygon,
  polygon,
  Position,
} from '@turf/helpers'
import { getFloatFromSourceAttribute } from '@synop-react/common'
import { RootAPI, VehicleModel } from '@synop-react/api'

export const VEHICLE_MARKER_TYPE = 'VEHICLE_MARKER_TYPE'
export const VEHICLE_SERVICEABLE_AREA = 'VEHICLE_SERVICEABLE_AREA'

export type VehicleServiceableAreaProperties = {
  type: string
}

export type VehicleAreaPolygon = Feature<
  Polygon,
  VehicleServiceableAreaProperties
>

export const getVehicleServiceableAreaPolygon = (
  serviceableArea: RootAPI.AzureServiceableRangeResponse = {}
): VehicleAreaPolygon | null => {
  const { reachableRange = {} } = serviceableArea
  const boundary = (reachableRange.boundary || []) as RootAPI.LatLngPair[]
  const boundaryPoints = boundary.reduce<Position[]>(
    (acc, { latitude, longitude }) => {
      if (latitude && longitude) acc.push([longitude, latitude] as Position)
      return acc
    },
    []
  )

  //Handle bad Azure Polygon
  const firstPoint = boundaryPoints[0] || []
  const lastPoint = boundaryPoints[boundaryPoints.length - 1] || []
  const isCompletePolygon = firstPoint.every((location) =>
    lastPoint.includes(location)
  )
  if (!isCompletePolygon) boundaryPoints.push(firstPoint)

  return boundaryPoints.length
    ? polygon([boundaryPoints], { type: VEHICLE_SERVICEABLE_AREA })
    : null
}

export type VehicleLocationProperties = {
  type: string
  vehicleId: string
  vehicleNm: string
  soc: number
  id: number
}
export type VehicleLocationPoint = Feature<Point, VehicleLocationProperties>

export const getVehicleLocationPoint = (
  vehicle?: VehicleModel
): VehicleLocationPoint | null => {
  const {
    stateOfCharge,
    longitude,
    latitude,
    id = '',
    vehicleNm = '',
  } = vehicle || {}
  const soc = getFloatFromSourceAttribute(stateOfCharge)
  const lastLongitude = getFloatFromSourceAttribute(longitude)
  const lastLatitude = getFloatFromSourceAttribute(latitude)

  const hasLatLong = Boolean(lastLatitude) && Boolean(lastLongitude)
  return hasLatLong
    ? point([lastLongitude, lastLatitude], {
        type: VEHICLE_MARKER_TYPE,
        vehicleId: id,
        vehicleNm,
        soc: Math.floor(soc),
        id: 1,
      })
    : null
}
type VehicleGeometry = (VehicleLocationPoint | VehicleAreaPolygon)[]

export const getVehicleGeometry = (
  vehicle?: VehicleModel,
  serviceableArea?: RootAPI.AzureServiceableRangeResponse
) => {
  const vehicleLocation = getVehicleLocationPoint(vehicle)
  const vehicleServiceableArea =
    getVehicleServiceableAreaPolygon(serviceableArea)
  const filteredGeometry = [vehicleLocation, vehicleServiceableArea].filter(
    Boolean
  ) as VehicleGeometry
  return filteredGeometry
}

export const getVehicleCollection = (
  vehicle?: VehicleModel,
  serviceableArea?: RootAPI.AzureServiceableRangeResponse
) => {
  return featureCollection<
    Point | Polygon,
    VehicleLocationProperties | VehicleServiceableAreaProperties
  >(getVehicleGeometry(vehicle, serviceableArea))
}
