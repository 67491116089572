import { Button, Grid, Stack, Typography } from '@mui/material'
import {
  createOverlay,
  FormField,
  Overlay,
  useOverlayContext,
  useSnackbarControls,
} from '@synop-react/common'
import { FormProvider, useForm } from 'react-hook-form'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { useCallback, useEffect } from 'react'
import QRCode from 'react-qr-code'

const {
  useGetOrganizationQuery,
  useInitiateMfaSetupQuery,
  useVerifySoftwareTokenMutation,
} = RootAPI

type MfaAuthFormData = {
  userCode: string
}

type MfaQrCodeProps = {
  openSuccessOverlay: () => void
}

const MfaQrCode = ({ openSuccessOverlay }: MfaQrCodeProps) => {
  const { synopUser } = useCurrentUser()
  const { data: userOrg } = useGetOrganizationQuery(
    { id: synopUser?.organizationId as string },
    { skip: !synopUser?.organizationId }
  )
  const { data: mfaSetup } = useInitiateMfaSetupQuery()
  const [setupMfa, setupMfaResponse] = useVerifySoftwareTokenMutation()

  const formMethods = useForm<MfaAuthFormData>()
  const { handleSubmit } = formMethods

  const { closeOverlay } = useOverlayContext()

  const appLabel = userOrg?.organizationNm ?? 'Synop'
  const { secretCode } = mfaSetup ?? {}
  const authUrl = `otpauth://totp/${appLabel}:${synopUser?.email}?secret=${secretCode}&issuer=${appLabel}`

  useEffect(() => {
    if (setupMfaResponse.status === QueryStatus.fulfilled) openSuccessOverlay()
  }, [openSuccessOverlay, setupMfaResponse.status])

  return (
    <Overlay>
      <Overlay.Header title="Multi-factor Authentication" />

      <Overlay.Content>
        <Grid item>
          <Stack spacing={2}>
            <Typography variant="body1">
              Your organization now requires Multi-factor Authentication.
            </Typography>
            <Typography variant="body1">
              Scan this QR code with your authentication application then input
              the code provided by your authentication app to finish setup.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack alignItems="center" spacing={3}>
            <Grid>
              <QRCode data-dd-privacy="hidden" value={authUrl} />
            </Grid>
            <Grid item>
              <form onSubmit={handleSubmit(setupMfa)}>
                <FormProvider {...formMethods}>
                  <FormField.WrappedTextFormField
                    id="userCode"
                    inputProps={{ maxLength: 6 }}
                    placeholder="Authentication code"
                    type="tel"
                  />
                </FormProvider>
              </form>
            </Grid>
            {setupMfaResponse.status === QueryStatus.rejected && (
              <Grid item>
                <Typography color="error" variant="subtitle1">
                  Authentication setup failed. Please try again.
                </Typography>
              </Grid>
            )}
          </Stack>
        </Grid>
      </Overlay.Content>

      <Overlay.Actions>
        <Stack direction="row" spacing={2}>
          <Button onClick={handleSubmit(setupMfa)} variant="contained">
            Save
          </Button>
          <Button onClick={closeOverlay}>Cancel</Button>
        </Stack>
      </Overlay.Actions>
    </Overlay>
  )
}

export const MfaQrOverlay = createOverlay(() => {
  const { closeOverlay } = useOverlayContext()
  const { openSnackbar } = useSnackbarControls()
  const handleSuccess = useCallback(() => {
    closeOverlay()
    openSnackbar(
      `You have successfully set up Multi-factor authentication. You will now
    need to use your authentication application each time you sign in.`,
      {
        title: 'MFA Update Succeeded.',
      }
    )
  }, [closeOverlay, openSnackbar])

  return <MfaQrCode openSuccessOverlay={handleSuccess} />
})
