import { PaletteColorOptions } from '@mui/material'
import chartPalette from './chartPalette'

declare module '@mui/material/styles' {
  interface Palette {
    brand: PaletteColor
    charging: ChargingPalette
    charting: {
      chart1: PaletteColor
      chart2: PaletteColor
      chart3: PaletteColor
      chart4: PaletteColor
      chart5: PaletteColor
      chart6: PaletteColor
      chart7: PaletteColor
      chart8: PaletteColor
    }
  }
  interface PaletteOptions {
    brand: PaletteColorOptions
    charging: ChargingPalette
  }

  interface PaletteColor {
    '4p': string
    '8p': string
    '12p': string
    '30p': string
    '50p': string
    '50': string
    '60'?: string
    '100'?: string
    '200'?: string
    '300'?: string
    '400'?: string
    '500'?: string
    '600'?: string
    '700'?: string
    '800'?: string
    '900'?: string
  }

  interface TypeText {
    '4p': string
    '8p': string
    '12p': string
    '30p': string
    '50p': string
  }
}

type ChargingPalette = {
  active: string
}

const customPalette = {
  brand: {
    main: 'white',
  },

  charging: {
    active: '#42DC2E',
  },

  charting: chartPalette,
}

export default customPalette
