import { Button, Typography } from '@mui/material'
import { OverlayDeprecated, RotateCcw } from '@synop-react/common'

type CancelOverlayProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onConfirm: () => void
  onCancel: () => void
}

export const CancelOverlay = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
}: CancelOverlayProps) => {
  return (
    <OverlayDeprecated
      isOpen={isOpen}
      OverlayActions={[
        <Button
          key="confirm"
          color="primary"
          onClick={onConfirm}
          variant="contained"
        >
          Confirm
        </Button>,
        <Button
          key="cancel"
          color="primary"
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>,
      ]}
      setIsOpen={setIsOpen}
      title="Cancel Editing Alert"
      TitleIcon={RotateCcw}
    >
      <Typography variant="body1">
        Are you sure you want to cancel? You will lose any unsaved changes to
        this alert.
      </Typography>
    </OverlayDeprecated>
  )
}
