import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import {
  formatPower,
  ProgressPill,
  ProgressPillColorFilter,
} from '@synop-react/common'

interface UtilizationPillProps {
  liveUtilization: number
  powerCeiling: number
}

const UtilizationPill = ({
  liveUtilization,
  powerCeiling,
}: UtilizationPillProps) => {
  const getInnerBackgroundColor: ProgressPillColorFilter = ({ percent }) => {
    if (percent < 40) {
      return 'success'
    }

    if (percent > 80) {
      return 'error'
    }

    return 'warning'
  }

  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Box sx={{ width: '130px' }}>
        <ProgressPill
          colorFilterFn={getInnerBackgroundColor}
          lg
          upperBound={powerCeiling}
          value={liveUtilization}
        />
      </Box>
      <Typography variant="body2">{formatPower(liveUtilization)}</Typography>
    </Stack>
  )
}

export default UtilizationPill
