import { rest } from 'msw'
import mockData from '../data'

export const fleetsHandlers = [
  rest.get('/api/fleets/:fleetId', (_req, res, ctx) => {
    return res(ctx.json(mockData.fleets.fleet))
  }),
  rest.get('/api/fleets/:fleetId/assets', (_req, res, ctx) => {
    return res(ctx.json(mockData.vehicles.vehicles))
  }),
  rest.get('/api/fleets/:fleetId/terms', (_req, res, ctx) => {
    return res(ctx.json(mockData.fleets.terms))
  }),
  rest.get('/api/fleets/:fleetId/users', (_req, res, ctx) => {
    return res(ctx.json(mockData.fleets.users))
  }),
]
