import { EfficiencyUnit } from '@synop-react/api'
import { emDash } from './constants'
import { formatUnits, formatUnitsAbbr, UnitsFormat } from './units'

const efficiencyFormats: UnitsFormat<EfficiencyUnit> = {
  'km/kwh': {
    long: 'Kilometers per Kilowatt Hour',
    short: 'km/kWh',
  },
  'kwh/km': {
    long: 'Kilowatt Hours per Kilometer',
    short: 'kWh/km',
  },
  'mi/kwh': {
    long: 'Miles per per Kilowatt Hour',
    short: 'mi/kWh',
  },
  'kwh/mi': {
    long: 'Kilowatt Hours per Mile',
    short: 'kWh/mi',
  },
}

export const formatEfficiencyUnits = formatUnits(efficiencyFormats)
export const formatEfficiencyUnitsAbbr = formatUnitsAbbr(efficiencyFormats)

type EfficiencyOptions = {
  longForm: boolean
  sigFigs: number
}

const defaultEfficiencyOptions: EfficiencyOptions = {
  longForm: false,
  sigFigs: 1,
}

const DEFAULT_EFFICIENCY_UNITS = 'kwh/mi'

export const formatEfficiency = (
  efficiency?: number | string | null,
  efficiencyUnit: EfficiencyUnit | null = DEFAULT_EFFICIENCY_UNITS,
  options = defaultEfficiencyOptions
) => {
  efficiencyUnit = (
    efficiencyUnit ?? DEFAULT_EFFICIENCY_UNITS
  ).toLowerCase() as EfficiencyUnit

  const { longForm, sigFigs } = { ...defaultEfficiencyOptions, ...options }
  const units = longForm
    ? formatEfficiencyUnits(efficiencyUnit)
    : formatEfficiencyUnitsAbbr(efficiencyUnit)

  if (typeof efficiency !== 'string' && typeof efficiency !== 'number') {
    return emDash
  }

  const efficiencyValue =
    sigFigs > 0
      ? parseFloat(`${efficiency}`).toFixed(sigFigs)
      : parseInt(`${efficiency}`)

  return efficiency ? `${efficiencyValue} ${units}` : emDash
}
