import * as yup from 'yup'
import {
  AddressAutocomplete,
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  EditGeofenceMap,
  FormField,
  GeofenceProvider,
  GoogleAutocomplete,
  Icons,
  isAutocompleteResponse,
  isGooglePlace,
  OrgAutocomplete,
  OverlayDeprecated,
  useEditGeofenceMap,
  useGoogleAPI,
} from '@synop-react/common'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useForm,
  useWatch,
} from 'react-hook-form'
import { getLocationFeatures } from '../Map'
import { LoadingButton } from '@mui/lab'
import { LocationMap } from '../Map/LocationMap/LocationMap'
import {
  LocationOfInterestType,
  RootAPI,
  useCurrentOrgId,
  useOrgCustomers,
} from '@synop-react/api'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { Tuple } from '@synop-react/types'
import { yupResolver } from '@hookform/resolvers/yup'
import centroid from '@turf/centroid'

const {
  useCreateLocationOfInterestMutation,
  useGetLocationOfInterestQuery,
  useUpdateLocationOfInterestMutation,
  useDeleteLocationOfInterestMutation,
} = RootAPI.synopRootAPI

type CreateOrEditLocationOverlayProps = {
  locationId?: string
  Trigger: ReactElement
}

type LocationForm = {
  loiNm: string
  loiType: LocationTypeOption | null
  organization: RootAPI.Organization | null
  address: GoogleAutocomplete | string
}

const schema = yup
  .object({
    loiNm: yup.string().required('Location name is required'),
    loiType: yup.object().nullable().required('Location type is required'),
    address: AddressAutocomplete.Schema,
    organization: yup.object().nullable().required('Organization is required'),
  })
  .required()

// This component is wrapped by the GeofenceProvider to allow it to leverage useEditGeofenceMap
const CreateOrEditLocationOverlayComponent = ({
  locationId,
  Trigger,
}: CreateOrEditLocationOverlayProps) => {
  const currentOrgId = useCurrentOrgId()

  const [isOpen, setIsOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const {
    isEditingGeofence,
    setIsEditingGeofence,
    geofence,
    setGeofence,
    prevGeofence,
    setPrevGeofence,
    locationMarker,
    setLocationMarker,
  } = useEditGeofenceMap()

  const { fetchGeoDetails, isLoading: isLoadingGeocoder } = useGoogleAPI()

  const { data: location } = useGetLocationOfInterestQuery(
    { id: locationId ?? '' },
    { skip: !locationId }
  )
  const { customers } = useOrgCustomers({ includeParentOrg: true })

  const [createLoi, createLoiResponse] = useCreateLocationOfInterestMutation()
  const [updateLoi, updateLoiResponse] = useUpdateLocationOfInterestMutation()

  const defaultFormValues = useMemo(
    () => ({
      loiNm: location?.loiNm ?? '',
      loiType: getLocationOption(location?.loiType),
      organization: customers[location?.organizationId ?? ''] || null,
      address: location?.address ?? '',
    }),
    [customers, location]
  )

  const {
    control,
    formState: { errors, touchedFields, isValid },
    reset,
    handleSubmit,
  } = useForm<LocationForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: defaultFormValues,
  })

  const onClose = useCallback(() => {
    if (!geofence && prevGeofence) {
      setGeofence(prevGeofence)
      setPrevGeofence(undefined)
    }

    setIsOpen(false)
    setIsEditingGeofence(false)
    setIsDeleting(false)
    reset()
  }, [
    geofence,
    prevGeofence,
    reset,
    setGeofence,
    setIsEditingGeofence,
    setPrevGeofence,
  ])

  const locationAddress = useWatch({
    control: control,
    name: 'address',
  })

  // Derive the site's lat/long. If there's a geofence, use its centroid. Otherwise, if the
  // user has entered an address in the form and the Google API recognizes it, use that.
  // Lastly, if the site already has a lat/long pair, use that.
  //
  // Note that the lat/long is not derived from `depot.address`. This is because
  // `depot.address` is a string, which is insufficient for inferring the site
  // location. Instead, we use the `siteAddress` from the form, which is
  // responsive to the user's edits. This does mean that sites with an address
  // but no lat/long or geofence will not have the location marker indicated on
  // the map, but this is a rare case.
  useEffect(() => {
    if (geofence) {
      try {
        const center = centroid(geofence)
        const [long, lat] = center.geometry.coordinates as Tuple<number>
        setLocationMarker([long, lat])
        return
      } catch (e) {
        console.error(e)
      }
    } else if (isAutocompleteResponse(locationAddress) && !isLoadingGeocoder) {
      fetchGeoDetails({ placeId: locationAddress.place_id }, ({ location }) =>
        setLocationMarker(location)
      )
    } else if (location) {
      // Get the lat/long from the depot if it has one
      const { latitude, longitude } = location
      setLocationMarker(
        typeof latitude === 'number' && typeof longitude === 'number'
          ? [longitude, latitude]
          : undefined
      )
    } else if (locationMarker) {
      // Otherwise, clear it. This is important for cases where the user has
      // entered an address or geofence but then deletes it.
      setLocationMarker(undefined)
    }
    // We don't want to update this every time locationMarker changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, fetchGeoDetails, geofence, isLoadingGeocoder, locationAddress])

  // Update Form Fields if editing once the API loads
  useEffect(() => {
    if (location) {
      const { latitude, longitude } = location || {}
      const { locationGeofence } = getLocationFeatures({
        geofence: location.geofence,
        latitude,
        longitude,
        locationId: location.id,
      })

      setGeofence(locationGeofence)
    }

    reset(defaultFormValues, {
      keepIsValid: true,
    })
  }, [
    customers,
    defaultFormValues,
    location,
    reset,
    setGeofence,
    setLocationMarker,
  ])

  // On successful creation
  useEffect(() => {
    if (createLoiResponse.status === QueryStatus.fulfilled) {
      setGeofence(undefined)
      setPrevGeofence(undefined)
      onClose()
      createLoiResponse.reset() // Must reset the mutation state to allow subsequent edits
    }
  }, [createLoiResponse, onClose, setGeofence, setPrevGeofence])

  // On successful edit
  useEffect(() => {
    if (updateLoiResponse.status === QueryStatus.fulfilled) {
      onClose()
      updateLoiResponse.reset() // Must reset the mutation state to allow subsequent edits
    }
  }, [onClose, updateLoiResponse])

  /* ~~~~~~~~~ End of Hooks ~~~~~~~~~  */

  const onCreateSubmit = ({
    loiNm,
    loiType,
    organization,
    address,
  }: LocationForm) => {
    const geofenceCoords = geofence?.geometry?.coordinates[0] ?? []

    const addressString = isGooglePlace(address) ? address.description : address
    const [longitude, latitude] = locationMarker ?? []

    if (
      loiNm &&
      loiType &&
      organization?.id &&
      geofenceCoords.length &&
      addressString &&
      latitude &&
      longitude
    ) {
      createLoi({
        locationOfInterestCreateModel: {
          loiNm,
          loiType: loiType?.name,
          address: addressString,
          longitude,
          latitude,
          organizationId: organization?.id,
          geofence: JSON.stringify(geofenceCoords),
        },
      })
    }
  }

  const onUpdateSubmit = ({
    loiNm,
    loiType,
    organization,
    address,
  }: LocationForm) => {
    const geofenceCoords = geofence?.geometry?.coordinates[0] ?? []

    const addressString = isGooglePlace(address) ? address.description : address
    const [longitude, latitude] = locationMarker ?? []

    if (
      location &&
      loiNm &&
      loiType &&
      organization?.id &&
      geofenceCoords.length &&
      addressString &&
      latitude &&
      longitude
    ) {
      updateLoi({
        locationOfInterestUpdateModel: {
          id: location.id,
          loiNm,
          loiType: loiType?.name,
          address: addressString,
          longitude,
          latitude,
          organizationId: organization?.id,
          geofence: JSON.stringify(geofenceCoords),
        },
      })
    }
  }

  const isUpdating = !!locationId

  const onSubmit = isUpdating ? onUpdateSubmit : onCreateSubmit

  const OverlayTrigger = cloneElement(Trigger, {
    onClick: () => {
      setIsOpen(true)
    },
  })

  const canSave = isValid && !isEditingGeofence && geofence
  const isSaving = createLoiResponse.isLoading || updateLoiResponse.isLoading

  return (
    <>
      {OverlayTrigger}
      {isOpen && !isDeleting && (
        <OverlayDeprecated
          OverlayActions={[
            <Grid key="saveButton" container sx={{ px: 2 }}>
              <Grid item xs={7}>
                <LoadingButton
                  color="primary"
                  disabled={!canSave}
                  loading={isSaving}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                >
                  {isUpdating ? 'SAVE' : 'CREATE LOCATION OF INTEREST'}
                </LoadingButton>
                {isEditingGeofence && (
                  <Typography sx={{ ml: 2 }} variant="caption">
                    Your geofence is not saved. Please save the geofence or
                    cancel editing to continue.
                  </Typography>
                )}
              </Grid>
              {isUpdating && (
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="error"
                      onClick={() => setIsDeleting(true)}
                      variant="outlined"
                    >
                      DELETE LOCATION OF INTEREST
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>,
          ]}
          title={`${isUpdating ? 'Edit' : 'Create'} Location of Interest`}
          TitleIcon={isUpdating ? Icons.Settings : Icons.PlusCircle}
          {...{ isOpen, setIsOpen, onClose }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={4}>
                <FormField.TextFormField
                  control={control}
                  error={errors.loiNm}
                  fullWidth
                  id="loiNm"
                  label="Name"
                  touched={Boolean(touchedFields.loiNm)}
                  type="text"
                />
              </Grid>
              <Grid item xs={4}>
                <LocationAutocompleteSelect
                  control={control}
                  error={errors.loiType as FieldError}
                  id="loiType"
                  label="Type"
                />
              </Grid>
              <Grid item xs={4}>
                <OrgAutocomplete.Select
                  control={control}
                  error={errors.organization as FieldError}
                  id="organization"
                  label="Organization"
                  orgId={currentOrgId}
                  touchedField={Boolean(touchedFields.organization)}
                />
              </Grid>
              <Grid item xs={12}>
                <AddressAutocomplete.Select
                  control={control}
                  error={errors.address as FieldError}
                  id="address"
                  label="Address"
                  touchedField={Boolean(touchedFields.address)}
                />
              </Grid>
              <Grid item xs={12}>
                <EditGeofenceMap mapId="" MapTile={LocationMap} />
              </Grid>
            </Grid>
          </form>
        </OverlayDeprecated>
      )}
      {isOpen && isDeleting && (
        <DeleteLocationOverlay
          isOpen={isDeleting}
          locationId={locationId}
          onClose={onClose}
          setIsDeleting={setIsDeleting}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  )
}

export const CreateOrEditLocationOverlay = (
  overlayProps: CreateOrEditLocationOverlayProps
) => {
  return (
    <GeofenceProvider>
      <CreateOrEditLocationOverlayComponent {...overlayProps} />
    </GeofenceProvider>
  )
}

type LocationTypeOption = {
  id: number
  name: LocationOfInterestType
}

type LocationAutocompleteSelectProps<FormData extends FieldValues> = {
  id: Path<FormData>
  label: string
  control: Control<FormData, unknown>
  isAdmin?: boolean
} & AutocompleteFormControls<FormData, LocationTypeOption> &
  AutocompleteTextFieldProps

export const LocationAutocompleteSelect = <FormData extends FieldValues>({
  id,
  label,
  control,
  ...rest
}: LocationAutocompleteSelectProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <FormField.AutocompleteSelect<FormData, LocationTypeOption>
      control={control}
      getOptionLabel={(option: LocationTypeOption) => `${option.name}`}
      id={id}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: LocationTypeOption) => option.id}
      label={label}
      options={locationOptions}
      setIsOpen={setIsOpen}
      {...rest}
    />
  )
}

const locationOptions: LocationTypeOption[] = [
  { id: 1, name: 'Port' },
  { id: 2, name: 'Maintenance' },
  { id: 3, name: 'Residence' },
  { id: 4, name: 'Other' },
]

const getLocationOption = (role?: LocationOfInterestType) => {
  const roleOption = locationOptions.find(({ name }) => name === role)
  return roleOption ?? null
}

type DeleteLocationOverlayProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setIsDeleting: (isOpen: boolean) => void
  locationId?: string
  onClose: () => void
}

const DeleteLocationOverlay = ({
  locationId = '',
  onClose,
  isOpen,
  setIsOpen,
  setIsDeleting,
}: DeleteLocationOverlayProps) => {
  const [deleteLoi, deleteLoiResponse] = useDeleteLocationOfInterestMutation()

  const handleDelete = () => {
    deleteLoi({ id: locationId })
  }

  return (
    <OverlayDeprecated
      OverlayActions={[
        <Grid key="deleteButton" container justifyContent="space-between">
          <Grid item>
            <LoadingButton
              color="error"
              loading={deleteLoiResponse.isLoading}
              onClick={handleDelete}
              variant="contained"
            >
              DELETE LOCATION OF INTEREST
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => setIsDeleting(false)}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>,
      ]}
      title="Delete Location of Interest?"
      TitleIcon={Icons.XCircle}
      {...{ isOpen, setIsOpen, onClose }}
    >
      Are you sure you wish to delete this location of interest?
    </OverlayDeprecated>
  )
}
