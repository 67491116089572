export const defaultEntityName = (
  entityType: string,
  entityId?: string | number
) =>
  entityId
    ? `${entityType} ${
        typeof entityId === 'string' ? entityId.slice(-5) : entityId
      }`
    : `Unknown ${entityType}`

export const chargerDotConnector = (
  chargerName: string,
  connectorName: string
) => `${chargerName}  • ${connectorName}`

export const pluralize = (unit: string) => (num: number) =>
  num >= 2 || num === 0 ? ` ${num} ${unit}s` : `${num} ${unit}`

export const asFraction = (
  numerator: number,
  denominator: number | null | undefined
) =>
  numerator.toString() +
  (typeof denominator === 'number' ? ` / ${denominator}` : '')
