import { Dayjs } from 'dayjs'
import { RootAPI, useWindowFocus } from '..'
import { useEffect, useState } from 'react'

const { useGetChargingTransactionsQuery } = RootAPI.synopRootAPI

const POLLING_INTERVAL_IN_SECONDS = 60

interface useVehicleDetailsFromPollProps {
  tagId: string
  from?: Dayjs
  to?: Dayjs
  disablePolling?: boolean
}

export const useVehicleDetailsFromPoll = ({
  tagId,
  from,
  to,
  disablePolling = false,
}: useVehicleDetailsFromPollProps) => {
  const isWindowFocused = useWindowFocus()
  const [pollingInterval, setPollingInterval] = useState(
    isWindowFocused ? POLLING_INTERVAL_IN_SECONDS * 1000 : 0
  )

  useEffect(() => {
    setPollingInterval(isWindowFocused ? POLLING_INTERVAL_IN_SECONDS * 1000 : 0)
  }, [isWindowFocused])

  const validFrom = from?.isValid()
  const validTo = to?.isValid()
  const roundedFrom = (validFrom && from?.startOf('minute').toISOString()) || ''
  const roundedTo = (validTo && to?.startOf('minute').toISOString()) || ''
  const makeOptions = (requireTimeRange: boolean) => {
    return {
      pollingInterval: disablePolling ? 0 : pollingInterval,
      skip: requireTimeRange && (!validFrom || !validTo),
    }
  }

  return {
    getChargerTransactions: useGetChargingTransactionsQuery(
      {
        tagId: tagId,
        startTimeFrom: roundedFrom,
        startTimeTo: roundedTo,
        count: 1000,
      },
      makeOptions(false)
    ),
  }
}
