import { Button, Grid, Typography } from '@mui/material'
import { Cask, ImageTile, ThemeTileRow, useLogoUrl } from '@synop-react/common'

export function ThemeSettings() {
  const { logoUrl } = useLogoUrl()
  return (
    <Cask title="Theme">
      <Typography variant="body1">
        Select which theme to be applied to your fleet.
      </Typography>
      <ThemeTileRow />
      <Typography variant="h6">Logos</Typography>

      <Typography variant="body1">
        Upload a logo to be displayed across your organization
      </Typography>
      <ImageTile
        description="Organization Logo"
        imageUrl={logoUrl || ''}
        width="400px"
      >
        <Button variant="text">Upload</Button>
      </ImageTile>
      <Grid container item>
        <Grid item>
          <Button color="primary" type="submit" variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
    </Cask>
  )
}

export default ThemeSettings
