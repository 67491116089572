import {
  defaultEntityName,
  EditChargingSessionOverlay,
  emDash,
  EventOptions,
  extractFrequency,
  extractRecurrenceName,
  formatPower,
  formatSoC,
  PowerTargetType,
  TooltipDetail,
  useFormat,
  ViewChargingSessionOverlay,
} from '@synop-react/common'
import {
  useDepotChargers,
  useDepotDetailsFromPoll,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { usePalette } from '@synop-react/theme'

type UseScheduleChargeProps = {
  depotId: string
}

const useScheduleChargeOption = ({
  depotId,
}: UseScheduleChargeProps): Pick<EventOptions, 'TIMED_CHARGE'> => {
  const { charting } = usePalette()
  const { tzDayjs } = useUserPrefs()
  const { formatDateTime } = useFormat()

  const {
    getDepot: { data: depot },
  } = useDepotDetailsFromPoll({ depotId })
  const { orgVehicles } = useOrgVehicles()
  const { depotChargers } = useDepotChargers(depotId)

  return {
    TIMED_CHARGE: {
      barColor: ({ eventRecurrence }) =>
        eventRecurrence ? charting[3].main : charting[4].main,
      caption: {
        title: ({ eventRecurrence }) => {
          return `Session` + (eventRecurrence ? ' (Repeating)' : ' (Scheduled)')
        },
        titleDescription: ({ assetId }) =>
          orgVehicles[assetId ?? '']?.vehicleNm ?? emDash,
      },
      tooltip: {
        title: 'Scheduled Charge',
        details: [
          (e) => extractRecurrenceName(e.eventRecurrence),
          (e) => extractFrequency(e.eventRecurrence),
          ({ assetId }) => ({
            label: 'Vehicle',
            detail: orgVehicles[assetId ?? '']?.vehicleNm ?? 'Unknown',
          }),
          ({ chargerId }) => ({
            label: 'Charger',
            detail:
              depotChargers.entities[chargerId ?? '']?.chargerName ??
              defaultEntityName('Charger', chargerId),
          }),
          ({ dispenserId }) => ({
            label: 'Connector',
            detail: defaultEntityName('Connector', dispenserId),
          }),
          ({ scheduledStart }) => ({
            label: 'Start',
            detail: formatDateTime(scheduledStart).timeDotDate,
          }),
          ({ scheduledEnd }) => ({
            label: 'End',
            detail: formatDateTime(scheduledEnd).timeDotDate,
          }),
          ({ targetSoc = undefined, powerOffered = undefined }) => {
            const targetType: PowerTargetType =
              targetSoc && targetSoc >= 0 ? 'SOC' : 'MAX_POWER'
            const targetDetailMap: Record<PowerTargetType, TooltipDetail> = {
              MAX_POWER: {
                label: 'Target Power',
                detail: `${formatPower(powerOffered)}`,
              },
              SOC: { label: 'Target SoC', detail: formatSoC(targetSoc) },
            }
            return targetDetailMap[targetType]
          },
        ],
      },
      renderOverlay: ({
        assetId = '',
        dispenserId,
        setIsOpen,
        isOpen,
        eventId,
        scheduledStart,
        scheduledEnd,
        chargerId = '',
        targetSoc,
        powerOffered,
        eventRecurrence,
      }) => {
        const eventHasEnded = tzDayjs().isAfter(scheduledEnd)

        const eventCharger = depotChargers.entities[chargerId]
        const eventVehicle = orgVehicles[assetId]

        // Should never happen
        if (!eventCharger) return null

        return !eventHasEnded ? (
          <EditChargingSessionOverlay
            defaultCharger={eventCharger}
            defaultConnector={{ connectorId: Number(dispenserId) }}
            defaultDepot={depot}
            defaultScheduledEnd={scheduledEnd}
            defaultScheduledStart={scheduledStart}
            defaultSoc={targetSoc}
            defaultTargetPower={powerOffered}
            defaultVehicle={eventVehicle}
            eventId={eventId as string}
            eventRecurrence={eventRecurrence}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        ) : (
          <ViewChargingSessionOverlay
            charger={eventCharger}
            eventId={eventId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            vehicle={eventVehicle}
          />
        )
      },
    },
  }
}

export default useScheduleChargeOption
