import { CaptionFormatter } from '../../Event/Caption/TimelineEventCaption'
import {
  TimelineContextProvider as ContextProvider,
  TimelineContextState,
  TimelineSelector,
} from '../useTimeline'
import { Dayjs } from 'dayjs'
import {
  dispatchCloseEventOverlay,
  dispatchDisplayEventOverlay,
  dispatchSetDisplayedDate,
  dispatchSetInnerWindow,
  dispatchSetTimelineEvents,
  useTimelineReducer,
} from '../useTimelineReducer'
import {
  EventGroupingKey,
  TimelineOptions,
} from '../useTimelineReducer/useTimelineReducer'
import {
  GenericEvent,
  ScheduledEventType,
  useUserPrefs,
} from '@synop-react/api'
import { OverlayDeprecatedProps } from '../../../../Overlay'
import { ReactElement, ReactNode, useEffect, useMemo } from 'react'
import { TooltipData } from '../../Tooltip/TimelineTooltip'
import { TooltipFormatter } from '../../../../Tooltip'
import { useMinuteTimer } from '../../../../utils/date/useTimer'
import { useTooltip } from '@visx/tooltip'

export type EventOptions = Record<ScheduledEventType, EventOption>
export type OverlayRendererProps = Pick<
  OverlayDeprecatedProps,
  'isOpen' | 'setIsOpen'
> &
  GenericEvent
export type EventOverlayRenderer = <T extends OverlayRendererProps>(
  props: T
) => ReactElement<T> | null
export type BarColorFormatter = (event: GenericEvent) => string
export type EventOption = {
  barColor: string | BarColorFormatter
  fillOpacity?: string
  caption: CaptionFormatter
  tooltip: TooltipFormatter<GenericEvent>
  renderOverlay?: EventOverlayRenderer
}

type TimelineContainer = {
  width: number
  height: number
}

export type TimelineProviderProps = {
  scheduledEvents: GenericEvent[]
  hideCaptions: boolean
  displayedDate: Dayjs
  groupingKey: EventGroupingKey
  eventIds: string[]
  eventOptions: EventOptions
  container: TimelineContainer
  children: ReactNode
} & TimelineOptions

export function TimelineProvider({
  scheduledEvents,
  eventIds,
  displayedDate: maybeDisplayedDate,
  displayYAxis = false,
  groupingKey = 'assetId',
  hideCaptions,
  eventOptions,
  container,
  children,
}: TimelineProviderProps) {
  const { tzDayjs } = useUserPrefs()
  const tooltip = useTooltip<TooltipData>()

  const displayedDate = useMemo(
    () => maybeDisplayedDate || tzDayjs().startOf('day'),
    [maybeDisplayedDate, tzDayjs]
  )

  const [reducerState, dispatch] = useTimelineReducer({
    groupingKey,
    eventIds,
    displayedDate,
    prevDisplayedDate: tzDayjs().startOf('day'),
    window: container,
    displayYAxis,
  })

  const currentTime = useMinuteTimer()
  useEffect(() => {
    dispatchSetTimelineEvents(dispatch)(scheduledEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledEvents, currentTime])

  useEffect(() => {
    dispatchSetInnerWindow(dispatch)(container)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  useEffect(() => {
    dispatchSetDisplayedDate(dispatch)(displayedDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedDate])

  const selectState = <R,>(selector: TimelineSelector<R>) =>
    selector(Object.assign({}, reducerState))

  const timelineState: TimelineContextState = Object.assign({}, reducerState, {
    selectState,
    tooltip,
    hideCaptions,
    eventOptions: eventOptions,
    setDisplayedDate: dispatchSetDisplayedDate(dispatch),
    setInnerWindow: dispatchSetInnerWindow(dispatch),
    displayEventOverlay: dispatchDisplayEventOverlay(dispatch),
    closeEventOverlay: dispatchCloseEventOverlay(dispatch),
  })

  return <ContextProvider value={timelineState}>{children}</ContextProvider>
}

export default TimelineProvider
