import {
  Button,
  FormControlLabel,
  Grid,
  Checkbox as MuiCheckbox,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { capitalize } from 'lodash'
import {
  Cask,
  formatTimezone,
  FormField,
  Icons,
  LoadingMessage,
  UserPreferenceAutocompleteSelect,
} from '@synop-react/common'
import { Control, FieldValues, Path, useForm, useWatch } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { MfaSwitch } from '../MfaAuth'
import {
  PreferredTimeZone,
  RootAPI,
  timezones,
  useCurrentUser,
} from '@synop-react/api'
import { useEffect, useState } from 'react'
import ChangePasswordOverlay from '../ChangePasswordOverlay/ChangePasswordOverlay'

type GeneralSettingsFormData = {
  name: string
  email: string
  phoneNumber: string
  preferredDateFormat: RootAPI.OptionValue
  preferredDistanceUnits: RootAPI.OptionValue
  preferredEfficiencyUnits: RootAPI.OptionValue
  preferredTemperatureUnits: RootAPI.OptionValue
  preferredTimeFormat: RootAPI.OptionValue
  preferredTimeZone: PreferredTimeZone
  receiveEmailNotifications: boolean
  receiveSMSNotifications: boolean
  rfidTags: string[]
}

const { useGetOrganizationQuery } = RootAPI
const fullPhoneNumberLength = 10

export function GeneralSettings() {
  const theme = useTheme()

  // Get user info
  const { synopUser, isUserLoading } = useCurrentUser()
  const { data: userOrg } = useGetOrganizationQuery(
    { id: synopUser?.organizationId ?? '' },
    { skip: !synopUser?.organizationId }
  )

  // Default form input
  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
    setValue,
  } = useForm<GeneralSettingsFormData>({
    defaultValues: {
      name: synopUser?.name,
      email: synopUser?.email,
      phoneNumber: synopUser?.phoneNumber ? synopUser.phoneNumber : '',
      preferredDateFormat: { value: synopUser?.preferredDateFormat || '' },
      preferredDistanceUnits: {
        value: synopUser?.preferredDistanceUnits || '',
      },
      preferredEfficiencyUnits: {
        value: synopUser?.preferredEfficiencyUnits || '',
      },
      preferredTemperatureUnits: {
        value: synopUser?.preferredTemperatureUnits || '',
      },
      preferredTimeFormat: { value: synopUser?.preferredTimeFormat || '' },
      preferredTimeZone: (synopUser?.preferredTimeZone ||
        '') as PreferredTimeZone,
      receiveEmailNotifications: synopUser?.receiveEmailNotifications ?? false,
      receiveSMSNotifications: synopUser?.receiveSMSNotifications ?? false,
    },
  })
  const phoneNumber = scrubPhoneNumber(
    useWatch({ control, name: 'phoneNumber' })
  )

  useEffect(() => {
    if (phoneNumber.length < fullPhoneNumberLength) {
      setValue('receiveSMSNotifications', false)
    }
  }, [phoneNumber, setValue])

  const [updateUser] = RootAPI.useUpdateUserMutation()
  const onSubmit = ({
    preferredDateFormat,
    preferredDistanceUnits,
    preferredEfficiencyUnits,
    preferredTemperatureUnits,
    preferredTimeFormat,
    preferredTimeZone,
    receiveSMSNotifications,
    ...userInfo
  }: GeneralSettingsFormData) => {
    const userPrefs = {
      preferredDateFormat: preferredDateFormat.value,
      preferredDistanceUnits: preferredDistanceUnits.value,
      preferredEfficiencyUnits: preferredEfficiencyUnits.value,
      preferredTemperatureUnits: preferredTemperatureUnits.value,
      preferredTimeFormat: preferredTimeFormat.value,
      preferredTimeZone: preferredTimeZone,
    }
    const updatedUser = Object.assign({}, synopUser, {
      ...userInfo,
      ...userPrefs,
      receiveSMSNotifications:
        scrubPhoneNumber(phoneNumber).length < fullPhoneNumberLength
          ? false
          : receiveSMSNotifications,
    })

    updateUser({
      userModel: updatedUser,
    })
  }

  if (isUserLoading) {
    return <LoadingMessage loadingMessage="Loading user setting details" />
  } else {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Cask title="General Settings" titleOnly>
            <Grid container rowSpacing={theme.spacing(2)}>
              <Grid container item>
                <Grid item md={3}>
                  <Typography variant="h6">Account</Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={theme.spacing(2)}>
                <Grid item md={3}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.name}
                    fullWidth
                    id="name"
                    label="Name"
                    touched={Boolean(touchedFields.name)}
                    type="text"
                  />
                </Grid>
                <Grid item md={3}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.email}
                    fullWidth
                    id="email"
                    label="Email"
                    touched={Boolean(touchedFields.email)}
                    type="text"
                  />
                </Grid>
                <Grid item md={3}>
                  <ChangePasswordOverlay
                    Trigger={
                      <Button
                        color="primary"
                        size="small"
                        type="submit"
                        variant="outlined"
                      >
                        Change Password
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={theme.spacing(2)}>
                <Grid item md={3}>
                  <FormField.PhoneInput control={control} id="phoneNumber" />
                </Grid>
                <Grid item md={3} pb={theme.spacing(4)}>
                  <TimezoneSelect control={control} id="preferredTimeZone" />
                </Grid>
              </Grid>
              <MfaSwitch />
              <Grid item spacing={theme.spacing(2)}>
                <Typography variant="h6">Measurements</Typography>
              </Grid>
              <Grid container item spacing={theme.spacing(2)}>
                <Grid item md={3}>
                  <UserPreferenceAutocompleteSelect.Select
                    control={control}
                    id="preferredDistanceUnits"
                    label="Distance"
                    type={'distance_units'}
                  />
                </Grid>
                <Grid item md={3}>
                  <UserPreferenceAutocompleteSelect.Select
                    control={control}
                    id="preferredTemperatureUnits"
                    label="Temperature"
                    type={'temperature_units'}
                  />
                </Grid>
                <Grid item md={3}>
                  <UserPreferenceAutocompleteSelect.Select
                    control={control}
                    id="preferredEfficiencyUnits"
                    label="Efficiency"
                    type={'efficiency_units'}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={theme.spacing(2)}>
                <Grid item md={3}>
                  <UserPreferenceAutocompleteSelect.Select
                    control={control}
                    id="preferredTimeFormat"
                    label="Time Format"
                    type={'time_format'}
                  />
                </Grid>
                <Grid item md={3} pb={theme.spacing(4)}>
                  <UserPreferenceAutocompleteSelect.Select
                    control={control}
                    id="preferredDateFormat"
                    label="Date Format"
                    type={'date_format'}
                  />
                </Grid>
              </Grid>
              {userOrg?.workplaceChargingStatus === 'ENABLED' && (
                <PaymentMethodSection />
              )}
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">Notifications</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Select how you would like to receive notifications.
                  </Typography>
                </Grid>
                <Grid container item lg={6} md={9} xs={12}>
                  <Grid item sm={4} xs={12}>
                    <FormControlLabel
                      checked={true}
                      control={<MuiCheckbox />}
                      disabled={true}
                      label="Platform"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormField.Checkbox
                      control={control}
                      id="receiveEmailNotifications"
                      label="Email"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormField.Checkbox
                      control={control}
                      disabled={phoneNumber.length < fullPhoneNumberLength}
                      id="receiveSMSNotifications"
                      label="Text/SMS"
                      tooltip={
                        phoneNumber.length < fullPhoneNumberLength
                          ? 'You must have a valid phone number to enable SMS notifications'
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item spacing={theme.spacing(2)}>
                <Grid item>
                  <Button color="primary" type="submit" variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Cask>
        </form>
      </>
    )
  }
}

export default GeneralSettings

type TimezoneSelectProps<FormData extends FieldValues> = {
  id: Path<FormData>
  control: Control<FormData, unknown>
  label?: string
}

// TODO Replace this when the backend starts returning timezones @wslater
const TimezoneSelect = <FormData extends FieldValues>({
  control,
  id,
  label = 'Time Zone',
}: TimezoneSelectProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <FormField.AutocompleteSelect<FormData, PreferredTimeZone>
      disableClearable={true}
      getOptionLabel={(option: PreferredTimeZone) =>
        formatTimezone(option) || ''
      }
      isLoading={false}
      keyExtractor={(option: PreferredTimeZone) => option || ''}
      options={[...timezones]}
      {...{ id, control, label, isOpen, setIsOpen }}
    />
  )
}

function scrubPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\s/g, '').replace('+1', '')
}

type PaymentCardDetailProps = {
  paymentMethod?: RootAPI.PaymentMethod
}

const PaymentCardDetail = ({ paymentMethod = {} }: PaymentCardDetailProps) => {
  const { card } = paymentMethod
  if (!card) return null

  const cardBrand = `${capitalize(card.brand)} `
  const properCaseCredit = cardBrand.length ? 'credit' : 'Credit'

  return (
    <Typography variant="caption">{`${cardBrand}${properCaseCredit} card ending ${card.last4}`}</Typography>
  )
}

const PaymentMethodSection = () => {
  const { synopUser } = useCurrentUser()

  const { data: paymentMethod } = RootAPI.useGetPaymentMethodQuery(
    { id: synopUser?.id ?? '' },
    { skip: !synopUser?.id }
  )

  const { data: stripeLogin, isLoading: isLoadingStripeLogin } =
    RootAPI.useGetPortalLoginQuery(
      { id: synopUser?.id || '' },
      { skip: !synopUser?.id }
    )

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6">Manage Payment Methods</Typography>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Icons.CreditCard color="grey" />
          <Stack>
            <Typography variant="subtitle2">Payment Method</Typography>
            <PaymentCardDetail
              paymentMethod={paymentMethod?.paymentMethods?.[0]}
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item sm={6} xs={12}>
        <LoadingButton
          loading={isLoadingStripeLogin}
          onClick={() => {
            if (stripeLogin?.url) {
              window.location.assign(stripeLogin?.url)
            }
          }}
          variant="outlined"
        >
          MANAGE PAYMENTS
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
