import {
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useTheme,
} from '@mui/material'
import { MfaQrOverlay } from './MfaQrCode'
import { RootAPI, useCurrentUser } from '@synop-react/api'
import { useCallback, useMemo } from 'react'
import { useOverlay } from '@synop-react/common'

const { useGetOrganizationQuery } = RootAPI

export const MfaSwitch = () => {
  const { synopUser } = useCurrentUser()
  const { data: userOrg } = useGetOrganizationQuery(
    { id: synopUser?.organizationId ?? '' },
    { skip: !synopUser?.organizationId }
  )
  const { mfaSetupStatus } = synopUser ?? {}
  const mfaQROverlay = useOverlay()
  const theme = useTheme()
  const [updateUser] = RootAPI.useUpdateUserMutation()

  const showMFAToggle = useMemo(
    () =>
      userOrg?.mfaStatus === 'ON_NOT_REQUIRED' ||
      userOrg?.mfaStatus === 'ON_REQUIRED' ||
      userOrg?.mfaStatus === undefined,
    [userOrg?.mfaStatus]
  )

  const handleChange = useCallback(() => {
    if (mfaSetupStatus === 'COMPLETED') {
      if (synopUser && userOrg?.mfaStatus === 'ON_NOT_REQUIRED')
        updateUser({
          userModel: { ...synopUser, mfaSetupStatus: 'INCOMPLETE' },
        })
    } else {
      mfaQROverlay.openOverlay()
    }
  }, [mfaQROverlay, mfaSetupStatus, synopUser, updateUser, userOrg?.mfaStatus])

  if (!showMFAToggle) return null
  return (
    <>
      <Grid container item spacing={theme.spacing(2)}>
        <Grid item>
          <Typography variant="h6">Multi-factor Authentication</Typography>
        </Grid>
      </Grid>
      <Grid container item spacing={theme.spacing(2)}>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={mfaSetupStatus === 'COMPLETED'}
                disabled={
                  mfaSetupStatus === 'COMPLETED' &&
                  userOrg?.mfaStatus === 'ON_REQUIRED'
                }
                onChange={handleChange}
              />
            }
            label={`Multi-factor Authentication has been ${
              mfaSetupStatus === 'COMPLETED' ? 'enabled' : 'disabled'
            }.`}
          />
        </Grid>
      </Grid>
      <MfaQrOverlay {...mfaQROverlay} />
    </>
  )
}
