import { Group } from '@visx/group'
import { styled } from '@mui/material'
import { useTooltipInPortal } from '@visx/tooltip'

import DayHourAxis from '../../Axis/DayHour'
import TimeIndicatorLine from '../TimeIndicatorLine'
import TimelineEventGroup from '../Event/Group'
import TimelineTooltip from '../Tooltip'
import useTimeline, { useTimelineSelector } from '../useTimeline/useTimeline'

import { AxisLabelFormatter } from '../OrdinalAxis/GroupedAxisLabel'

import {
  selectBottomAxisArea,
  selectChartArea,
} from '../useTimeline/useTimelineReducer/selectors/window'
import { Text } from '@visx/text'
import OrdinalAxis from '../OrdinalAxis/OrdinalAxis'

export interface SimpleTimelineProps {
  yAxisLabelFormatter?: AxisLabelFormatter
}

export function SimpleTimeline({ yAxisLabelFormatter }: SimpleTimelineProps) {
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ scroll: true })
  const {
    isOverlayOpen,
    closeEventOverlay,
    overlayEvent,
    window: { width: windowWidth, height: windowHeight },
    eventOptions,
    eventIds,
    scheduledEvents,
  } = useTimeline()
  const chartArea = useTimelineSelector(selectChartArea)
  const bottomAxis = useTimelineSelector(selectBottomAxisArea)

  const backgroundHeight = chartArea.height + 0.5 * bottomAxis.height

  const { eventType } = overlayEvent || {}

  const { renderOverlay = () => null } =
    (eventType && eventOptions[eventType]) || {}

  const EventOverlay =
    isOverlayOpen && overlayEvent && eventType
      ? renderOverlay({
          setIsOpen: closeEventOverlay,
          isOpen: isOverlayOpen,
          ...overlayEvent,
        })
      : null

  const topPadding = 16
  return scheduledEvents.length || eventIds.length ? (
    <>
      <svg
        ref={containerRef}
        height={windowHeight + topPadding}
        width={windowWidth}
      >
        <Group top={topPadding}>
          <TimelineBackground height={backgroundHeight} width={windowWidth} />
          <TimelineEventGroup />
          <DayHourAxis />
          <OrdinalAxis labelFormatter={yAxisLabelFormatter} />
        </Group>
        <TimeIndicatorLine />
      </svg>
      <TimelineTooltip Component={TooltipInPortal} />
      {EventOverlay}
    </>
  ) : (
    <svg height={windowHeight} width={windowWidth}>
      <Group>
        <TimelineBackground height={windowHeight} width={windowWidth} />

        <Text textAnchor="middle" verticalAnchor="middle" x="50%" y="50%">
          No Data to Display
        </Text>
      </Group>
    </svg>
  )
}

export default SimpleTimeline

const TimelineBackground = styled('rect')(({ theme }) => {
  return {
    fill: theme.palette.secondary['4p'],
  }
})
