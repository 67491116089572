import {
  ChargingTransactionReportRow,
  useDepotDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'
import { FormProvider } from 'react-hook-form'
import { getFieldColumns } from '@synop-react/common'
import { TableCask, useTableCask } from '@synop-react/common'
import { TransactionOverlay } from '../TransactionOverlay'
import { useChargingSessions } from './useChargingSessions'
import { useChargingTransactionsReportFields } from '../Reports/fields'
import { useMemo, useState } from 'react'

interface SiteChargingTransactionsProps {
  depotId: string
}

const SiteChargingTransactionsHistory = ({
  depotId,
}: SiteChargingTransactionsProps) => {
  const { tzDayjs, preferredDateFormat } = useUserPrefs()
  const { formMethods, from, to } = useTableCask()
  const [overlayTransaction, setOverlayTransaction] =
    useState<ChargingTransactionReportRow>()

  const {
    getChargerTransactions: { data, isLoading: isChargingSessionsLoading },
  } = useDepotDetailsFromPoll({
    depotId,
    from: from,
    to: to,
  })

  const chargingSessions = useChargingSessions(data)

  const {
    transactionIdWithOverlayAction,
    chargerName,
    connectorId,
    ocppTag,
    vin,
    startTimestamp,
    stopTimestamp,
    energyImported,
    maxPower,
    averagePower,
    startSoc,
    endSoc,
    duration,
    stopReason,
    stopEventActor,
  } = useChargingTransactionsReportFields()

  const { tableColumns, csvColumns } = useMemo(
    () =>
      getFieldColumns(
        transactionIdWithOverlayAction((transactionId: string | undefined) => {
          setOverlayTransaction(
            chargingSessions.find((session) => session.id === transactionId)
          )
        }),
        chargerName,
        connectorId,
        ocppTag,
        vin,
        startTimestamp,
        stopTimestamp,
        duration,
        energyImported,
        maxPower,
        averagePower,
        startSoc,
        endSoc,
        stopReason,
        stopEventActor
      ),
    [
      transactionIdWithOverlayAction,
      chargerName,
      connectorId,
      ocppTag,
      vin,
      startTimestamp,
      stopTimestamp,
      duration,
      energyImported,
      maxPower,
      averagePower,
      startSoc,
      endSoc,
      stopReason,
      stopEventActor,
      chargingSessions,
    ]
  )

  return (
    <>
      {overlayTransaction && (
        <TransactionOverlay
          chargerId={overlayTransaction.chargerId}
          connectorId={overlayTransaction.connectorId}
          endSoc={overlayTransaction.latestSoc}
          endTimestamp={overlayTransaction.stopTimestamp}
          onClose={() => {
            setOverlayTransaction(undefined)
          }}
          startSoc={overlayTransaction.startingSoc}
          startTimestamp={overlayTransaction.startTimestamp}
          totalEnergyExported={overlayTransaction.totalEnergyExported}
          totalEnergyImported={overlayTransaction.totalEnergyImported}
          transactionId={overlayTransaction.id}
          vehicleId={overlayTransaction.vehicleId}
        />
      )}
      <FormProvider {...formMethods}>
        <TableCask.ClientSide
          columns={tableColumns}
          csvColumns={csvColumns}
          downloadable
          downloadTitle={`CHARGING_TRANSACTIONS_${tzDayjs(from).format(
            preferredDateFormat.replaceAll('/', '-')
          )}_${tzDayjs(to).format(preferredDateFormat.replaceAll('/', '-'))}`}
          getRowId={(row) => row.id}
          isLoading={isChargingSessionsLoading}
          searchable
          showDateRange
          tableData={chargingSessions}
          title="Transaction History"
        />
      </FormProvider>
    </>
  )
}

export default SiteChargingTransactionsHistory
