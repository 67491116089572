import { createSelector } from '@reduxjs/toolkit'
import { ScaleOrdinal, ScaleTime } from 'd3-scale'
import { scaleOrdinal, scaleTime } from '@visx/scale'
import dayjs from 'dayjs'

import { selectChartArea, selectLeftAxisArea, selectWindow } from './window'
import { selectDisplayedDates, selectEventDateRange } from './eventDates'
import { selectEventIds } from './eventsWithIdle'
import { selectUserTimezone } from './prefs'
import { TimelineSelector } from '../../useTimeline'
import { TzDayjs } from '@synop-react/api'

// This allows users to see a little bit of the previous day in the timeline
export const prevDayBuffer = (date: dayjs.ConfigType, tzDayjs: TzDayjs) =>
  tzDayjs(date).startOf('day').subtract(30, 'minutes')

// Scale for positioning events on the timeline
export const selectXScale: TimelineSelector<ScaleTime<number, number, never>> =
  createSelector(
    selectEventDateRange,
    selectChartArea,
    selectUserTimezone,
    ([earliestDate, latestDate], { width: chartWidth }, tzDayjs) => {
      const numDays = latestDate.diff(earliestDate, 'day') + 1
      const bufferedEarliest = prevDayBuffer(earliestDate, tzDayjs)

      return scaleTime({
        domain: [bufferedEarliest.toDate(), latestDate.toDate()],
        range: [0, numDays * chartWidth],
      })
    }
  )

export const selectYScale: TimelineSelector<
  ScaleOrdinal<string, number, never>
> = createSelector(selectEventIds, (eventIds) => {
  const rowHeight = 68
  const topPadding = 50

  const yRange = eventIds.map((foo, i) => i * rowHeight + topPadding)

  return scaleOrdinal({
    domain: eventIds,
    range: yRange,
  })
})

// Scale for positioning elements in the 'sliding window' aka the displayed day
export const selectWindowXScale: TimelineSelector<
  ScaleTime<number, number, never>
> = createSelector(
  selectLeftAxisArea,
  selectWindow,
  selectDisplayedDates,
  selectUserTimezone,
  ({ width: leftAxisWidth }, { width }, { displayedDate }, tzDayjs) => {
    const dayStart = prevDayBuffer(displayedDate, tzDayjs).toDate()
    const dayEnd = displayedDate.endOf('day').toDate()

    return scaleTime({
      domain: [dayStart, dayEnd],
      range: [leftAxisWidth, width],
    })
  }
)

export const selectWindowTransition: TimelineSelector<{
  prevX: number
  nextX: number
}> = createSelector(
  selectXScale,
  selectDisplayedDates,
  selectUserTimezone,
  (xScale, { prevDisplayedDate, displayedDate }, tzDayjs) => {
    // TODO FIGURE OUT WHY THIS IS NEEDED @wslater
    const MAGIC_SCALE_OFFSET = 10
    return {
      prevX:
        xScale(prevDayBuffer(prevDisplayedDate, tzDayjs)) + MAGIC_SCALE_OFFSET,
      nextX: xScale(prevDayBuffer(displayedDate, tzDayjs)) + MAGIC_SCALE_OFFSET,
    }
  }
)
