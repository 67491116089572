import { Navigate, useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'

import { useChargerDetailsFromPoll } from '@synop-react/api'
import { useRouting } from '@synop-react/common'
import ChargerDetailsPage from './ChargerDetailsPage'

type ChargerDetailsRouteParams = {
  chargerId: string
}

const ChargerDetailsContainer = () => {
  const { routes } = useRouting()
  const { chargerId } = useParams<
    keyof ChargerDetailsRouteParams
  >() as ChargerDetailsRouteParams

  const {
    getCharger: {
      data: charger,
      isLoading: isChargerLoading,
      isSuccess: isChargerSuccess,
      isError: isChargerError,
    },
  } = useChargerDetailsFromPoll({
    chargerId,
    pollingIntervalInSeconds: 5,
  })

  if (isChargerLoading) {
    return <Skeleton variant="rectangular" />
  }

  if (isChargerError || !isChargerSuccess || !charger) {
    return <Navigate to={routes.sites} />
  }

  return (
    <ChargerDetailsPage charger={charger} isChargerSuccess={isChargerSuccess} />
  )
}

export default ChargerDetailsContainer
