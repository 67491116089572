import { useCurrentOrganization } from '@synop-react/api'

export function useLogoUrl(): { isLoading: boolean; logoUrl?: string } {
  const { currentOrg, isLoading } = useCurrentOrganization()

  return {
    isLoading,
    logoUrl: currentOrg?.logoUrl,
  }
}
