import { Grid, GridProps, Stack } from '@mui/material'
import { ReactNode } from 'react'

import { Cask, CaskProps } from '../../Cask'
import DetailsCaskItem from './Item/DetailsCaskItem'

export type DetailsCaskProps = {
  title: string
  EditOverlay?: ReactNode
  children: ReactNode
  Map?: ReactNode
} & Omit<CaskProps, 'title' | 'Actions'>

export function DetailsCask({
  title,
  EditOverlay,
  children,
  Map,
  ...caskProps
}: DetailsCaskProps) {
  const detailColumnsProps: Partial<GridProps> = Map
    ? {
        direction: 'column',
        flexWrap: 'nowrap',
      }
    : { direction: 'row', rowSpacing: 3 }
  const DetailColumns = (
    <Grid container {...detailColumnsProps}>
      {children}
    </Grid>
  )

  return (
    <Cask Actions={[EditOverlay]} title={`${title} Details`} {...caskProps}>
      <Stack direction="row">
        {DetailColumns}
        {Map}
      </Stack>
    </Cask>
  )
}

DetailsCask.Item = DetailsCaskItem

export default DetailsCask
