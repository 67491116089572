import { ApexOptions } from 'apexcharts'
import { DayjsConstructor, emDash, Tile } from '@synop-react/common'
import { RootAPI, useUserPrefs } from '@synop-react/api'
import { useMemo } from 'react'
import { usePalette } from '@synop-react/theme'
import Chart from 'react-apexcharts'

const { useGetMetricsForVehicleQuery } = RootAPI

type DailyEnergyUseProps = {
  vehicleId: string
  from: DayjsConstructor
  to?: DayjsConstructor
}

export const DailyEnergyUse = ({ from, vehicleId }: DailyEnergyUseProps) => {
  const { palette, charting } = usePalette()
  const { tzDayjs } = useUserPrefs()
  const currentDate = useMemo(
    () => tzDayjs(from).format('YYYY-MM-DD'),
    [from, tzDayjs]
  )

  const { data: dailySummary } = useGetMetricsForVehicleQuery({
    id: vehicleId,
    date: currentDate,
  })

  const { energyUtilization } = dailySummary || {}

  const {
    totalBatteryManagementEnergyConsumed,
    totalDcdcEnergyConsumed,
    totalMotorEnergyConsumed,
    totalRegenEnergyConsumed,
    totalHvacEnergyConsumed,
  } = energyUtilization || {}

  const { value: motorEnergy = 0 } = totalMotorEnergyConsumed || {}
  const { value: motorRegenEnergy = 0 } = totalRegenEnergyConsumed || {}

  // TODO Hook up to correct theme colors @wslater
  const energyUse = {
    BATTERY: {
      value: totalBatteryManagementEnergyConsumed?.value,
      subtitle: 'Battery Management',
      color: charting[1].main,
    },
    DCDC: {
      value: totalDcdcEnergyConsumed?.value,
      subtitle: 'DC/DC',
      color: charting[4].main,
    },
    HVAC: {
      value: totalHvacEnergyConsumed?.value,
      subtitle: 'HVAC',
      color: charting[5].main,
    },
    MOTOR: {
      value: motorEnergy + motorRegenEnergy,
      subtitle: 'Motor',
      color: palette.info.main,
    },
  }

  const hasData = !!(
    energyUse.BATTERY.value ||
    energyUse.DCDC.value ||
    energyUse.HVAC.value ||
    energyUse.MOTOR.value
  )

  const series = hasData
    ? [
        energyUse.BATTERY.value ?? 0,
        energyUse.DCDC.value ?? 0,
        energyUse.HVAC.value ?? 0,
        energyUse.MOTOR.value ?? 0,
      ]
    : []

  const options: ApexOptions = {
    labels: [
      energyUse.BATTERY.subtitle,
      energyUse.DCDC.subtitle,
      energyUse.HVAC.subtitle,
      energyUse.MOTOR.subtitle,
    ],
    noData: {
      text: 'No data available',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'bottom',
      show: hasData,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val ? val.toFixed(1) + ' kWh' : emDash
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.8,
        },
      },
    },
    colors: [
      energyUse.BATTERY.color,
      energyUse.DCDC.color,
      energyUse.HVAC.color,
      energyUse.MOTOR.color,
    ],
  }

  return (
    <Tile title="Energy Use by System">
      <Chart options={options} series={series} type="pie" />
    </Tile>
  )
}
