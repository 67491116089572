import {
  CoordinateSourceAttribute,
  PercentSourceAttribute,
  useUserPrefs,
} from '@synop-react/api'
import {
  formatSocFromSourceAttributes,
  getFloatFromSourceAttribute,
  isSourceAttributeValue,
  ReportField,
  Table,
} from '@synop-react/common'
import { Link } from '@mui/material'
import { TripReport } from '../useTripReport'
import { useMemo } from 'react'

type UseTripFieldsProps = {
  sampleRecord?: Record<string, unknown>
}

export const useTripFields = ({ sampleRecord = {} }: UseTripFieldsProps) => {
  const { tzDayjs } = useUserPrefs()
  const units = useMemo(
    () =>
      Object.entries(sampleRecord).reduce((acc, [key, reportAttribute]) => {
        if (isSourceAttributeValue(reportAttribute)) {
          acc[key] = reportAttribute.units
        }
        return acc
      }, {} as Record<string, unknown>),
    [sampleRecord]
  )

  const tripStart: ReportField<TripReport> = {
    csv: [
      [
        'tripStart',
        'trip_start_date',
        (timestamp: string) => tzDayjs(timestamp).format('YYYY-MM-DD'),
      ],
      [
        'tripStart',
        'trip_start_time',
        (timestamp: string) => tzDayjs(timestamp).format('HH:mm:ss'),
      ],
    ],
    column: {
      align: 'center',
      field: 'tripStart',
      headerAlign: 'center',
      headerName: 'Start Time',
      sortable: true,
      flex: 1,
      valueFormatter: ({ value }) =>
        tzDayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      type: 'dateTime',
      valueGetter: Table.dateValueGetter,
    },
  }

  const tripEnd: ReportField<TripReport> = {
    csv: [
      [
        'tripEnd',
        'trip_end_date',
        (timestamp: string) => tzDayjs(timestamp).format('YYYY-MM-DD'),
      ],
      [
        'tripEnd',
        'trip_end_time',
        (timestamp: string) => tzDayjs(timestamp).format('HH:mm:ss'),
      ],
    ],
    column: {
      align: 'center',
      field: 'tripEnd',
      headerAlign: 'center',
      headerName: 'End Time',
      sortable: true,
      flex: 1,
      valueFormatter: ({ value }) =>
        tzDayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      type: 'dateTime',
      valueGetter: Table.dateValueGetter,
    },
  }

  const startLocation: ReportField<TripReport> = {
    csv: [
      [
        ['startLatitude'],
        `start_lat`,
        (lat: CoordinateSourceAttribute) =>
          getFloatFromSourceAttribute(lat) || '',
      ],
      [
        ['startLongitude'],
        `start_long`,
        (long: CoordinateSourceAttribute) =>
          getFloatFromSourceAttribute(long) || '',
      ],
    ],
    column: {
      field: 'startLocation',
      headerName: 'Start Location (Lat / Lng)',
      flex: 1,
      renderCell: ({ row }) => {
        const {
          startLatitude: { value: startLatitude },
          startLongitude: { value: startLongitude },
        } = row

        return startLatitude && startLongitude ? (
          <Link
            component={'a'}
            href={`https://www.google.com/maps/?q=${startLatitude},${startLongitude}`}
            target="_blank"
            underline="none"
          >
            {`${startLatitude} , ${startLongitude}`}
          </Link>
        ) : null
      },
    },
  }

  const endLocation: ReportField<TripReport> = {
    csv: [
      [
        ['endLatitude'],
        `end_lat`,
        (lat: CoordinateSourceAttribute) =>
          getFloatFromSourceAttribute(lat) || '',
      ],
      [
        ['endLongitude'],
        `end_long`,
        (long: CoordinateSourceAttribute) =>
          getFloatFromSourceAttribute(long) || '',
      ],
    ],
    column: {
      field: 'endLocation',
      headerName: 'End Location (Lat / Lng)',
      flex: 1,
      renderCell: ({ row }) => {
        const {
          endLatitude: { value: endLatitude },
          endLongitude: { value: endLongitude },
        } = row

        return endLatitude && endLongitude ? (
          <Link
            component={'a'}
            href={`https://www.google.com/maps/?q=${endLatitude},${endLongitude}`}
            target="_blank"
            underline="none"
          >
            {`${endLatitude} , ${endLongitude}`}
          </Link>
        ) : null
      },
    },
  }

  const startStateOfCharge: ReportField<TripReport> = {
    csv: [
      ['startStateOfCharge'],
      `start_soc_in_${units['startStateOfCharge']}`,
      (soc: PercentSourceAttribute) => `${getFloatFromSourceAttribute(soc)}`,
    ],
    column: {
      align: 'center',
      field: 'startStateOfCharge',
      headerAlign: 'center',
      headerName: `Start SoC`,
      sortable: true,
      flex: 1,
      renderCell: ({ row }) =>
        formatSocFromSourceAttributes(row.startStateOfCharge),
      valueGetter: ({ value: sourceAttribute }) => sourceAttribute?.value,
      type: 'number',
    },
  }

  const endStateOfCharge: ReportField<TripReport> = {
    csv: [
      ['endStateOfCharge'],
      `end_soc_in_${units['endStateOfCharge']}`,
      (soc: PercentSourceAttribute) => `${getFloatFromSourceAttribute(soc)}`,
    ],
    column: {
      align: 'center',
      field: 'endStateOfCharge',
      headerAlign: 'center',
      headerName: `End SoC`,
      sortable: true,
      flex: 1,
      renderCell: ({ row }) =>
        formatSocFromSourceAttributes(row.endStateOfCharge),
      valueGetter: ({ value: sourceAttribute }) => sourceAttribute?.value,
      type: 'number',
    },
  }

  return {
    startLocation,
    endLocation,
    startStateOfCharge,
    endStateOfCharge,
    tripStart,
    tripEnd,
  }
}
