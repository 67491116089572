type Key<T> = keyof T
type OutputName = string

// TODO: It would be nice if this could be typed to expect the right number and type of arguments.
// It seems technically feasible, but I'm not sure how to do it.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataFormatter = (...arg: Array<any>) => FormatterReturnValue

type FormatterReturnValue = string | null | number | undefined
type ColumnSelector<T> = Key<T> | Key<T>[]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormatterComponents<T> = [(Key<T> | ((data: any) => any))[], DataFormatter]
type FormatRule<T> =
  | [Key<T>, DataFormatter]
  | [ColumnSelector<T>, OutputName, DataFormatter]

export type CsvColumnSet<T> = CsvColumn<T>[]
export type CsvColumn<T> =
  // If just a string is passed, it must be a key on the object
  | Key<T>
  | [Key<T>]
  // Simple renaming: first element is the key, second is the output name

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | [Key<T> | ((data: any) => any), string]
  // If a string and a formatter is passed
  | FormatRule<T>

export function collectionToCSV<T>(csvColumns: CsvColumnSet<T>) {
  return function (collection: T[] = []) {
    const headers = csvColumns
      .map(extractHeaderName)
      .map(wrapInQuotes)
      .join(',')

    const colFormatters = csvColumns
      .map(extractFormatterComponents)
      .map(createColFormatter)

    function values(record: T) {
      return colFormatters
        .map((formatter) => formatter(record))
        .map(wrapInQuotes)
        .join(',')
    }

    return collection.reduce(
      (priorCsvRows, record) => priorCsvRows + '\n' + values(record).trim(),
      headers
    )
  }
}

function extractHeaderName<T>(exporter: CsvColumn<T>): string {
  if (!Array.isArray(exporter)) return exporter.toString()
  if (exporter.length === 3) return exporter[1]
  if (typeof exporter[1] === 'string') return exporter[1]
  return exporter[0].toString()
}

function wrapInQuotes(value: FormatterReturnValue) {
  return `"${value}"`
}

/**
 * Extracts the two import components for generating the value to render in a CSV column.
 * The first component is the selector, which is either a string or an array of strings
 * specifying the path(s) to the value in the record. The second component is the formatter,
 * which is a function that takes the value(s) from the record and returns a string to insert
 * into the CSV.
 */
function extractFormatterComponents<T>(
  columnSpec: CsvColumn<T>
): FormatterComponents<T> {
  const defaultFormatter: DataFormatter = (v) => (v === null ? '' : String(v))

  if (!Array.isArray(columnSpec)) return [[columnSpec], defaultFormatter]
  else if (columnSpec.length === 1) return [columnSpec, defaultFormatter]

  if (columnSpec.length === 3) {
    const [selector, , formatter] = columnSpec
    if (Array.isArray(selector)) {
      return [selector, formatter]
    } else {
      return [[selector], formatter]
    }
  } else if (typeof columnSpec[1] === 'string') {
    return [[columnSpec[0]], defaultFormatter]
  } else {
    const [selector, formatter] = columnSpec
    return [[selector], formatter]
  }
}

/** Creates a formatter function for a single column, using the selector and formatter */
function createColFormatter<T>([selector, formatter]: FormatterComponents<T>) {
  return function formatCol(record: T) {
    const values = Array.isArray(selector)
      ? selector.map((key) =>
          typeof key === 'function' ? key(record) : record[key] ?? null
        )
      : [record[selector] ?? null]

    // Replace `null` or `undefined` with empty string
    return formatter(...values) ?? ''
  }
}

export function getCSVFieldName(headerName: string) {
  return headerName.replace(/ /g, '_').toLowerCase()
}
