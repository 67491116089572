import { DurationUnitType } from 'dayjs/plugin/duration'
import { formatNumber } from './number'
import { pluralize } from './entity'
import { simpleDropdownFactory, SimpleDropdownOption } from '../../Dropdown'
import { TimeUnits, TzDayjs } from '@synop-react/api'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const formatTimeUnitsLong = (timeUnits: TimeUnits) => {
  switch (timeUnits) {
    case TimeUnits.SECONDS:
      return 'Seconds'
    case TimeUnits.MINUTES:
      return 'Minutes'
    case TimeUnits.HOURS:
      return 'Hours'
    case TimeUnits.DAYS:
      return 'Days'
    default:
      return timeUnits
  }
}

export const formatTimeUnitsAbbr = (timeUnits: TimeUnits) => {
  switch (timeUnits) {
    case TimeUnits.MINUTES:
      return 'min'
    case TimeUnits.HOURS:
      return 'h'
    case TimeUnits.DAYS:
      return 'd'
    default:
      return timeUnits
  }
}

type TimeOptions = {
  longForm?: boolean
}

export const formatTimeUnits = (
  time: number,
  timeUnits: TimeUnits,
  options?: TimeOptions
) => {
  const units = options?.longForm
    ? formatTimeUnitsLong(timeUnits)
    : formatTimeUnitsAbbr(timeUnits)
  return time ? `${time} ${units}` : 'n/a'
}

export const formatTimezone = (timezone: string) =>
  timezone.replace(/\//, ': ').replaceAll(/_/g, ' ')

export const pluralizeDays = pluralize('Day')
export const pluralizeHours = pluralize('Hour')
export const pluralizeMinutes = pluralize('Minute')

type SupportedDayjsDurations = (
  | 'hours'
  | 'minutes'
  | 'seconds'
  | 'milliseconds'
  | 'days'
) &
  DurationUnitType

export const attributeDurationToDayJsDuration: Record<
  TimeUnits,
  SupportedDayjsDurations
> = {
  [TimeUnits.MILLISECONDS]: 'milliseconds',
  [TimeUnits.SECONDS]: 'seconds',
  [TimeUnits.MINUTES]: 'minutes',
  [TimeUnits.HOURS]: 'hours',
  [TimeUnits.DAYS]: 'days',
}

type HumanizeDurationOptions = {
  abbreviate?: boolean
}

const DEFAULT_OPTIONS: HumanizeDurationOptions = {
  abbreviate: false,
}

export const humanizeAccurateDuration = (
  duration: number,
  durationUnits: TimeUnits
) => {
  const dayjsDurationUnit = attributeDurationToDayJsDuration[durationUnits]

  const { floor } = Math

  const rawDuration = dayjs.duration(duration, dayjsDurationUnit)
  const numDays = floor(rawDuration.asDays())
  const numHours = floor(rawDuration.asHours())

  const daysString = numDays > 0 ? `${pluralizeDays(numDays)} ` : ''
  const remainingHours = rawDuration.subtract(numDays, 'day')
  const hoursString =
    remainingHours.asHours() >= 1
      ? `${pluralizeHours(floor(remainingHours.asHours()))} `
      : ''
  const remainingMinutes = rawDuration.subtract(numHours, 'hour')
  const minutesString =
    remainingMinutes.asMinutes() >= 1
      ? pluralizeMinutes(floor(remainingMinutes.asMinutes()))
      : ''

  return `${daysString}${hoursString}${minutesString}`
}

export const humanizeScheduledDuration = (
  scheduledStart: string,
  scheduledEnd: string | Dayjs,
  tzDayjs: TzDayjs,
  options = DEFAULT_OPTIONS
) => {
  const startTime = tzDayjs(scheduledStart)
  const endTime = tzDayjs(scheduledEnd)
  const duration = endTime.diff(startTime)
  return humanizeDuration(duration, TimeUnits.MILLISECONDS, options)
}

export const humanizeDuration = (
  duration: number,
  durationUnits: TimeUnits,
  options = DEFAULT_OPTIONS
) => {
  const mergedOptions = Object.assign({}, DEFAULT_OPTIONS, options)
  const dayjsDurationUnit = attributeDurationToDayJsDuration[durationUnits]

  const { round, floor } = Math

  const rawDuration = dayjs.duration(duration, dayjsDurationUnit)
  const numDays = rawDuration.asDays()
  const numHours = rawDuration.asHours()
  const numMinutes = round(rawDuration.asMinutes())

  if (floor(numDays) >= 2) return pluralizeDays(round(numDays))
  if (numMinutes < 60) return formatMinutes(numMinutes, mergedOptions)
  const hours = numHours > 3 ? Math.round(numHours) : +numHours.toFixed(1)
  return formatHours(hours, mergedOptions)
}

const formatHours = (hours: number, options: HumanizeDurationOptions) => {
  const { abbreviate: shouldAbbreviate } = options
  return shouldAbbreviate ? `${hours} Hr.` : pluralizeHours(hours)
}

const formatMinutes = (minutes: number, options: HumanizeDurationOptions) => {
  const { abbreviate: shouldAbbreviate } = options

  return shouldAbbreviate ? `${minutes} Min.` : pluralizeMinutes(minutes)
}

export function reduceTime(seconds: number): {
  value: number | string
  suffix: string
} {
  const formatOptions = { maximumFractionDigits: 0 }
  if (seconds < 60) {
    const value = formatNumber(seconds, formatOptions)
    return {
      value,
      suffix: `Sec${+value === 1 ? '' : 's'}`,
    }
  } else if (seconds < 3600) {
    const minutes = seconds / 60
    const value = formatNumber(minutes, formatOptions)
    return {
      value,
      suffix: `Min${+value === 1 ? '' : 's'}`,
    }
  } else if (seconds < 86400) {
    const hours = seconds / 3600
    const value = formatNumber(hours, formatOptions)
    return {
      value,
      suffix: `Hour${+value === 1 ? '' : 's'}`,
    }
  } else {
    const days = seconds / 86400
    const value = formatNumber(days, formatOptions)
    return {
      value,
      suffix: `Day${+value === 1 ? '' : 's'}`,
    }
  }
}

export function convertTime(
  value: number,
  startUnit: TimeUnits,
  endUnit: TimeUnits
): number {
  return dayjs
    .duration(value, attributeDurationToDayJsDuration[startUnit])
    .as(attributeDurationToDayJsDuration[endUnit])
}

const timeDropdownOptions: SimpleDropdownOption<TimeUnits>[] = [
  { name: formatTimeUnitsLong(TimeUnits.SECONDS), id: TimeUnits.SECONDS },
  { name: formatTimeUnitsLong(TimeUnits.MINUTES), id: TimeUnits.MINUTES },
  { name: formatTimeUnitsLong(TimeUnits.HOURS), id: TimeUnits.HOURS },
  { name: formatTimeUnitsLong(TimeUnits.DAYS), id: TimeUnits.DAYS },
]
type TimeDropdownOptions = (typeof timeDropdownOptions)[number]
export const TimeDropdown = simpleDropdownFactory<
  TimeUnits,
  TimeDropdownOptions
>(timeDropdownOptions)
