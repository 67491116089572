import { MapAssets, MapMarkerLayer, MapPolygonLayer } from '@synop-react/common'
import { useTheme } from '@mui/material'

type LocationLayersProps = {
  mapId: string
  sourceId: string
}

const showAllMarkers = ['all', ['==', ['geometry-type'], 'Point']]

export const LocationMapLayers = ({ mapId, sourceId }: LocationLayersProps) => {
  const { palette } = useTheme()
  const layerAssets = {
    locationPin: '/assets/images/map_pin_sdf.png',
  }

  return (
    <>
      <MapPolygonLayer
        fillColor={palette.info.main}
        layerId="locationGeofence"
        sourceId={sourceId}
      />
      <MapAssets layerAssets={layerAssets} mapId={mapId}>
        <MapMarkerLayer
          assetName="locationPin"
          filter={showAllMarkers}
          iconColor={palette.secondary.main}
          layerId="locationMarkers"
          sourceId={sourceId}
        />
      </MapAssets>
    </>
  )
}
