import { ChangeEvent, useRef, useState } from 'react'
import { IconButton, TextField, useTheme } from '@mui/material'
import { Search } from '../../Icons'

type TableSearchProps = {
  onChange: (str: string | undefined) => void
}

function TableSearch({ onChange }: TableSearchProps) {
  const [expanded, setExpanded] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const theme = useTheme()

  /** Collapse the search field when it loses focus and is empty */
  function handleBlur() {
    if (!inputRef.current?.value) setExpanded(false)
  }

  /** Expand the search field and focus it when the search icon is clicked */
  function handleClick() {
    setExpanded(true)
    inputRef.current?.focus()
  }

  /**
   * Update the search string when the input changes. Empty string is converted
   * to undefined
   * */
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    onChange(value === '' ? undefined : value)
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Search data-cy="table-search-icon" />
      </IconButton>
      <TextField
        data-cy="table-search-input"
        inputRef={inputRef}
        onBlur={handleBlur}
        onChange={handleChange}
        sx={{
          marginLeft: 0.5,
          transition: theme.transitions.create('width'),
          width: expanded ? '20ch' : 0,
        }}
        variant="standard"
      />
    </div>
  )
}

export default TableSearch
