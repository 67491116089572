import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-use'

type FallbackProps = {
  error: Error
  resetErrorBoundary: () => void
  hasReturnToDashboard?: boolean
}

export const Fallback = ({ resetErrorBoundary }: FallbackProps) => {
  const location = useLocation()
  const errorLocation = useRef(location.pathname)
  useEffect(() => {
    // Reset the error state if the user navigates to a new page
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary()
    }
  }, [location.pathname, resetErrorBoundary])

  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <Box
        alt="Synop Error Image"
        component="img"
        src="/assets/images/synop-sww-plug.svg"
        sx={{ my: 2, maxHeight: '350px', maxWidth: '300px' }}
      />
      <Typography variant="h4">Ooops! Something went wrong here...</Typography>
      <Typography variant="body1">
        You may try refreshing the page or try again later.
      </Typography>
    </Stack>
  )
}
