import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { FormField } from '../../index'
import { RootAPI } from '@synop-react/api'

const { useGetOrganizationCustomersQuery, useGetOrganizationQuery } =
  RootAPI.synopRootAPI

export type OrgAutocompleteSelectProps<FormData extends FieldValues> = {
  label?: string
  orgId: string
} & AutocompleteFormControls<FormData, RootAPI.OrganizationModel> &
  AutocompleteTextFieldProps

export const OrgAutocompleteSelect = <FormData extends FieldValues>({
  orgId,
  label = 'Org',
  ...autocompleteProps
}: OrgAutocompleteSelectProps<FormData>) => {
  const { data: currentOrg } = useGetOrganizationQuery(
    { id: orgId },
    { skip: !orgId }
  )
  const OrgOptions = FormField.useOptions<
    RootAPI.OrganizationModel,
    RootAPI.GetOrganizationCustomersApiArg,
    RootAPI.GetOrganizationCustomersApiResponse
  >({
    queryArgs: { id: orgId },
    useQuery: useGetOrganizationCustomersQuery,
  })

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.OrganizationModel>
      isLoading={OrgOptions.isLoading}
      isOpen={OrgOptions.isOpen}
      label={label}
      options={
        // Add in current org if we have it
        currentOrg ? [currentOrg, ...OrgOptions.options] : OrgOptions.options
      }
      setIsOpen={OrgOptions.setIsOpen}
      {...autocompleteProps}
      filterOptions={(options, { inputValue }) =>
        options
          .filter(({ organizationNm }) => {
            const OrgName = organizationNm?.toLowerCase() || ''
            const searchTerm = inputValue.toLowerCase()
            return OrgName.includes(searchTerm)
          })
          .sort((a, b) => {
            if (!a.organizationNm) return 1
            if (!b.organizationNm) return -1
            return a.organizationNm.localeCompare(b.organizationNm)
          })
      }
      getOptionLabel={({ organizationNm }: RootAPI.OrganizationModel) =>
        organizationNm || ''
      }
      keyExtractor={({ id }: RootAPI.OrganizationModel) => id || ''}
    />
  )
}

const OrgSelectSchema = yup
  .object({ id: yup.string().required() })
  .nullable()
  .required('Org must be selected')

export { OrgAutocompleteSelect as Select, OrgSelectSchema as Schema }
