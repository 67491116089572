import { Chart } from '@synop-react/common'
import { Dayjs } from 'dayjs'
import { SpeedChartData, useChartData, useUserPrefs } from '@synop-react/api'
import { useMemo } from 'react'

type UseVehicleSpeedProps = {
  vehicleId: string
  from: Dayjs
  to: Dayjs
}

const useVehicleSpeed = ({ from, to, vehicleId }: UseVehicleSpeedProps) => {
  const { tzDayjs } = useUserPrefs()
  const { data: speedData, units: speedUnits } = useChartData({
    vehicleId,
    from,
    to,
    attributes: ['speed'],
  }) as unknown as SpeedChartData

  const speedOverTime = useMemo(
    () =>
      speedData.reduce<Chart.AreaDatum[]>((acc, [timestamp, speed]) => {
        const sampleTime = tzDayjs(Number(timestamp)).toISOString()

        if (speed >= 0) acc.push({ x: sampleTime, y: speed })

        return acc
      }, []),
    [speedData, tzDayjs]
  )

  return {
    speedOverTime,
    speedUnit: speedUnits[1],
  }
}

export default useVehicleSpeed
