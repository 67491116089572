import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Dropdown,
  Overlay,
  useOverlayContext,
  useSnackbarControls,
} from '@synop-react/common'
import { LoadingButton } from '@mui/lab'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { RootAPI } from '@synop-react/api'
import { useEffect, useMemo, useState } from 'react'
import range from 'lodash/range'

const orgAnchorDays = range(1, 29)

export type BillingCycleOverlayProps = {
  configuration?:
    | RootAPI.ReimbursementConfiguration
    | RootAPI.WorkplaceConfiguration
    | undefined
  setupBillingCycle?: (anchorDay: number | undefined) => void
  apiResponseStatus?: QueryStatus
}

export const BillingCycleOverlay = ({
  configuration,
  setupBillingCycle,
  apiResponseStatus,
}: BillingCycleOverlayProps) => {
  const { closeOverlay } = useOverlayContext()
  const { openSnackbar } = useSnackbarControls()

  const isEditing = useMemo(
    () => !!configuration?.billingAnchor,
    [configuration]
  )

  const [anchorDay, setAnchorDay] = useState<number | undefined>(
    configuration?.billingAnchor
  )

  useEffect(() => {
    switch (apiResponseStatus) {
      case 'rejected':
        openSnackbar('Update was not successful.', {
          title: 'Update Failed',
          severity: 'error',
        })
        break
      case 'fulfilled':
        openSnackbar('Update was successful.', {
          title: 'Update Succeeded',
        })
        closeOverlay()
        break
    }
  }, [closeOverlay, apiResponseStatus, openSnackbar])

  const selectColor = useTheme().palette.text.secondary

  return (
    <Overlay>
      <Overlay.Header
        title={isEditing ? 'Edit Anchor Day' : 'Select Anchor Day'}
      />

      <Overlay.Content>
        <Typography variant="body1">
          {isEditing
            ? 'Modifying your anchor day will impact your billing cycle. Proceed with caution to avoid any unintended changes to your billing schedule.'
            : `The billing cycle will begin on the anchor day you select. If no anchor day is selected, the billing cycle will begin by default on the first of the month.`}
        </Typography>

        <Box>
          <Dropdown
            menuMaxHeight="190px"
            onChange={(e) => {
              const value = e.target.value
              setAnchorDay(value === '' ? undefined : +value)
            }}
            selectColor={selectColor}
            unselectedOption={
              isEditing ? 'Edit Anchor Day' : 'Select Anchor Day'
            }
            value={anchorDay?.toString()}
          >
            {orgAnchorDays.map((day) => (
              <MenuItem key={day} value={day}>
                <ListItemText primary={day} />
              </MenuItem>
            ))}
          </Dropdown>
        </Box>
      </Overlay.Content>

      <Overlay.Actions>
        <LoadingButton
          disabled={!anchorDay}
          loading={apiResponseStatus === 'pending'}
          onClick={() => setupBillingCycle?.(anchorDay)}
          variant="contained"
        >
          Save
        </LoadingButton>
        <Button onClick={closeOverlay}>Cancel</Button>
      </Overlay.Actions>
    </Overlay>
  )
}
