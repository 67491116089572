import { Box } from '@mui/material'
import { featureCollection, lineString, Position } from '@turf/helpers'
import { Source } from 'react-map-gl'

import {
  DataState,
  MapBadgeMarker,
  MapBadgeMarkerProps,
  SynopMap,
} from '@synop-react/common'
import { RootAPI } from '@synop-react/api'

import { MINIMUM_TRIP_DATA_POINTS } from '../tripUtils'
import { TripSocGradientLayer } from '../../Map/SocGradientLayer'
import { useSocColor } from '../../utils/hooks/useSocColor'

export interface TripMapTileProps {
  telematics: RootAPI.AssetTelematics[]
  height?: string
}

const DEFAULT_HEIGHT = '421.8px'

export function TripMapTile({
  telematics,
  height = DEFAULT_HEIGHT,
}: TripMapTileProps) {
  let telematicsLineCollection = null
  if (telematics) {
    const telematicsLinePoints: Position[] = telematics.map((tm) => {
      return [tm.longitude, tm.latitude] as Position
    })

    if (telematicsLinePoints.length > MINIMUM_TRIP_DATA_POINTS) {
      telematicsLineCollection = featureCollection([
        lineString(telematicsLinePoints),
      ])
    }
  }

  if (telematicsLineCollection && telematics?.length) {
    const start = telematics[0] as RootAPI.AssetTelematics
    const end = telematics.at(-1) as RootAPI.AssetTelematics
    return (
      <Box sx={{ width: '100%', height: height }}>
        <SynopMap boundedData={telematicsLineCollection} id="tripDetailMap">
          <Source
            data={telematicsLineCollection}
            id="tripDetailMap"
            lineMetrics={true}
            type="geojson"
          >
            <TripSocGradientLayer
              sourceId="tripDetailMap"
              telematics={telematics}
            />
          </Source>
          <SocMapBadgeMarker
            latitude={start.latitude}
            longitude={start.longitude}
            soc={start.stateOfCharge}
          >
            Start
          </SocMapBadgeMarker>
          <SocMapBadgeMarker
            latitude={end.latitude}
            longitude={end.longitude}
            soc={end.stateOfCharge}
          >
            End
          </SocMapBadgeMarker>
        </SynopMap>
      </Box>
    )
  } else {
    return (
      <DataState
        hasData={!!telematicsLineCollection}
        noDataMessage="Trip too short to display"
      />
    )
  }
}

export default TripMapTile

type SocMapBadgeMarkerProps = {
  soc?: number
} & Omit<MapBadgeMarkerProps, 'badgeColor'>

const SocMapBadgeMarker = ({ soc, ...badgeProps }: SocMapBadgeMarkerProps) => {
  const badgeColor = useSocColor(soc)

  return <MapBadgeMarker badgeColor={badgeColor} {...badgeProps} />
}
