import { Box, Skeleton } from '@mui/material'
import { useCurrentOrganization } from '@synop-react/api'
import { useIsFullscreen, useRouting } from '@synop-react/common'
import SynopLogo from '../assets/images/logo.svg'

const NavLogo = () => {
  const { navigate, routes } = useRouting()
  const { currentOrg, isLoading } = useCurrentOrganization()
  const isFullscreen = useIsFullscreen()

  const logoUrl = currentOrg?.logoUrl || SynopLogo

  return isLoading ? (
    <Skeleton height="50px" width="150px" />
  ) : (
    <Box
      alt={`${currentOrg?.organizationNm} Logo`}
      component="img"
      height="50px"
      onClick={() => {
        if (!isFullscreen) navigate(routes.dashboard.root)
      }}
      src={logoUrl}
      sx={{ my: 2, px: 3, cursor: isFullscreen ? 'auto' : 'pointer' }}
    />
  )
}

export default NavLogo
