import { GridComparatorFn } from '@mui/x-data-grid-premium'
import { isUndefined } from 'lodash'
import { Link, Skeleton } from '@mui/material'

import {
  AlertTriangle,
  asFraction,
  emDash,
  EntityBrowser,
  EntityListItemRenderer,
  EntityMapConfig,
  EntityTableRowRenderer,
  formatPower,
  LoadingMessage,
  roundValueForFormatting,
  SelectedPin,
  Table,
  ToolbarControls,
  useFormat,
  useRouting,
} from '@synop-react/common'
import {
  Depot,
  mapEntities,
  RootAPI,
  useCurrentOrgId,
  useOrgSites,
  usePolling,
} from '@synop-react/api'
import {
  DepotMapLocationLayers,
  getDepotLocationCollection,
} from '../Map/LocationLayers'
import { Maybe } from '@synop-react/types'
import DepotActiveChargerDetail from '../DepotActiveChargerDetail'
import DepotSearchInput from './DepotSearchInput'
import UtilizationPill from './UtilizationPill'

const { useCmsUtilizationGetUtilizationQuery } = RootAPI

type SiteDatum = Depot & {
  utilization: Maybe<number>
  utilizationLoaded: boolean
}

const tableColumns: Table.ColumnSpec<SiteDatum> = [
  {
    align: 'center',
    field: 'isOverCapacity',
    headerName: '',
    groupable: false,
    renderCell: ({ value }) => {
      return value ? (
        <AlertTriangle sx={{ color: ({ palette }) => palette.error.main }} />
      ) : (
        ''
      )
    },
    valueGetter: ({ row }) => {
      const { powerCeiling = 0, utilization = 0 } = row
      const badData = powerCeiling < 0 || utilization < 0
      return badData || utilization > powerCeiling
    },
    flex: 1,
    type: 'boolean',
  },
  {
    field: 'depotNm',
    headerName: 'Site Name',
    groupable: false,
    renderCell: ({ row }) => <SiteLink site={row} />,
    sortable: true,
    flex: 3,
  },
  {
    field: 'utilization',
    flex: 2,
    headerAlign: 'left',
    headerName: 'Live Utilization',
    sortingOrder: ['desc', 'asc'],
    groupable: false,
    renderCell: ({ row }) => {
      const { powerCeiling = 0, utilization, utilizationLoaded } = row

      if (!utilizationLoaded) return <Skeleton width="100%" />

      return !isUndefined(utilization) && utilization >= 0 ? (
        <UtilizationPill
          liveUtilization={utilization}
          powerCeiling={powerCeiling}
        />
      ) : (
        emDash
      )
    },
    groupingValueGetter: ({ row }) => {
      return isUndefined(row.utilization)
        ? null
        : roundValueForFormatting(row.utilization)
    },
    type: 'number',
  },
  {
    field: 'powerCeiling',
    flex: 2,
    headerName: 'Site Limit',
    sortable: true,
    // TODO: Use the user's unit preference to determine the unit to display
    renderCell: ({ row }) => formatPower(row.powerCeiling),
    type: 'number',
  },
  {
    field: 'activeChargers',
    filterable: false,
    flex: 2,
    groupable: false,
    headerName: 'Active Chargers',
    sortable: true,
    sortComparator: sortChargerFractions('activeChargers'),
    sortingOrder: ['desc', 'asc'],
    type: 'number',
    // The column value is the entire row so that both `activeChargers` and `numChargers` are available in the
    // sorting function
    valueGetter: ({ row }) => row,
    valueFormatter: ({ value }) => {
      const { activeChargers = 0, numChargers = 0 } = value
      const hasChargers = activeChargers || numChargers
      return hasChargers ? asFraction(activeChargers, numChargers) : emDash
    },
  },
  {
    field: 'connectedChargers',
    filterable: false,
    flex: 2,
    groupable: false,
    headerName: 'Connected Chargers',
    sortable: true,
    sortComparator: sortChargerFractions('connectedChargers'),
    sortingOrder: ['desc', 'asc'],
    type: 'number',
    // The column value is the entire row so that both `activeChargers` and `numChargers` are available in the
    // sorting function
    valueGetter: ({ row }) => row,
    valueFormatter: ({ value }) => {
      const { connectedChargers = 0, numChargers = 0 } = value
      const hasChargers = connectedChargers || numChargers
      return hasChargers ? asFraction(connectedChargers, numChargers) : emDash
    },
  },
]

export function DepotBrowser() {
  const { formatDateTime } = useFormat()
  const currentOrgId = useCurrentOrgId()
  const { orgSites, isLoading } = useOrgSites()

  const { data: utilization } = usePolling(
    useCmsUtilizationGetUtilizationQuery,
    { organizationId: currentOrgId },
    { skip: !currentOrgId, pollingIntervalSeconds: 30 }
  )

  // Mix the utilization data in with the site data
  const utilizationLoaded = !isUndefined(utilization)
  const siteUtilization = utilization?.sites ?? {}
  const orgSitesWithUtilization = mapEntities(orgSites, (site): SiteDatum => {
    return {
      ...site,
      utilization: siteUtilization[site.id]?.livePowerImportKw,
      utilizationLoaded,
    }
  })

  const renderListItem: EntityListItemRenderer<SiteDatum> = (depot) => {
    const { id, depotNm = 'Unnamed Site' } = depot
    const liveImport = siteUtilization[id]?.livePowerImportKw ?? 0
    return {
      id,
      titleText: depotNm,
      descriptionText: '',
      details: [
        {
          title: 'Active Chargers',
          value: (
            <DepotActiveChargerDetail
              color="textSecondary"
              variant="subtitle2"
              {...depot}
            />
          ),
        },
        {
          title: 'Live Utilization',
          color: 'secondary.main',
          fontWeight: 'bold',
          value: utilizationLoaded ? formatPower(liveImport) : null,
        },
      ],
      detailsUrl: `/sites/${id}`,
    }
  }

  const renderTableRow: EntityTableRowRenderer<SiteDatum> = (depot) => {
    const { updated } = depot
    const timestamp = formatDateTime(updated).timeDotDate
    return {
      ...depot,
      timestamp,
    }
  }

  const toolbarControls: ToolbarControls = [
    { key: 'search', Component: DepotSearchInput },
  ]

  const depotMapConfig: EntityMapConfig<Depot> = {
    layerIds: ['defaultDepots'],
    OverviewMapLayers: (
      <>
        <SelectedPin />
        <DepotMapLocationLayers mapId="entityMap" sourceId="entityMapSource" />
      </>
    ),
    getCoords: getDepotLocationCollection,
  }

  if (isLoading) {
    return <LoadingMessage />
  } else {
    return (
      <EntityBrowser
        entities={orgSitesWithUtilization}
        mapConfig={depotMapConfig}
        renderListItem={renderListItem}
        renderTableRow={renderTableRow}
        tableColumns={tableColumns}
        toolbarControls={toolbarControls}
      />
    )
  }
}

type SiteLinkProps = {
  site: SiteDatum
}

function SiteLink({ site }: SiteLinkProps) {
  const { siteDetails } = useRouting().routes
  const { depotNm, id } = site
  return depotNm ? (
    <Link href={siteDetails(id)} underline="none">
      {depotNm}
    </Link>
  ) : null
}

/**
 * Sorts the chargers by the fraction of active/connected chargers to total chargers. If the numerators are equal,
 * then the total number of chargers is used as a tiebreaker.
 */
function sortChargerFractions(
  key: 'activeChargers' | 'connectedChargers'
): GridComparatorFn<SiteDatum> {
  return (a, b) => {
    const numeratorA = a[key]
    const numeratorB = b[key]
    if (numeratorA === numeratorB) {
      return sortMaybeNumber(a.numChargers, b.numChargers)
    } else {
      return sortMaybeNumber(numeratorA, numeratorB)
    }
  }
}

function sortMaybeNumber(a: number | undefined, b: number | undefined): number {
  if (a === undefined && b === undefined) return 0
  if (a === undefined) return -1
  if (b === undefined) return 1
  return a - b
}
