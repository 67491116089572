import { flatten, isUndefined, map } from 'lodash'
import { Skeleton } from '@mui/material'
import { useMemo } from 'react'
import dayjs from 'dayjs'

import {
  Chart,
  useMemoizedDayjs,
  useUTCToLocalOffset,
} from '@synop-react/common'
import {
  GenericEvent,
  RootAPI,
  useDepotDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'
import { Tuple } from '@synop-react/types'
import { usePalette } from '@synop-react/theme'

type Quad<T> = [T, T, T, T]
type DepotUtilizationChartProps = { depotId: string } & Pick<
  Chart.ApexUtilizationChartProps,
  'height' | 'from' | 'to'
>

export const DepotUtilizationChart = ({
  depotId,
  height = 325,
  from: fromProp,
  to,
}: DepotUtilizationChartProps) => {
  // Round `from` down to the nearest minute so it's not constantly triggering `useMemo`
  const from = useMemoizedDayjs(fromProp, (d) => d.startOf('minute'))

  const {
    getDepotEvents: { data: depotEvents = [] },
    getDepot: { data: depot },
    getDepotSmartPrices: { data: smartPrices },
    getDepotForecast: { data: depotForecast },
    getDepotUtilization: { data: depotUtilization, isLoading, isError },
  } = useDepotDetailsFromPoll({
    depotId,
    from,
    to,
    skipUtilization: false,
  })

  const events: GenericEvent[] = depotEvents.map((event) => ({
    eventId: event.eventId,
    depotId: event.depotId,
    eventType: 'SITE_LIMIT',
    scheduledStart: event.eventStartDate,
    scheduledEnd: event.eventEndDate,
    curtailedSiteLimit: event.schedule.intervals[0]?.curtailedSiteLimit,
  }))

  const xAnnotations = useAnnotations(events, smartPrices?.priceChanges)
  const [importData, exportData, limitData, forecastData] = useAlignedData(
    from,
    events,
    depotForecast,
    depotUtilization,
    depot?.powerCeiling
  )

  if (isLoading) {
    return <Skeleton height={height - 20} variant="rectangular" width="100%" />
  } else if (isError && !depotUtilization) {
    return <div>Unable to load data</div>
  }

  return (
    <Chart.Utilization
      {...{ from, to, height }}
      annotations={{ xaxis: xAnnotations }}
      limitData={{ data: limitData, dataKey: 'Site Limit' }}
      powerData={[
        { data: importData, dataKey: 'Power Import (Charge)' },
        { data: exportData, dataKey: 'Power Export (Discharge)' },
        {
          data: depot?.isLoadBalanced ? forecastData : [],
          dataKey: 'Power Forecast',
        },
      ]}
      yAxisLabel="Utilization (kW)"
    />
  )
}

function useAnnotations(
  siteEvents: GenericEvent[],
  priceChanges?: RootAPI.PriceChange[]
) {
  const { charting, palette } = usePalette()
  const { tzDayjs } = useUserPrefs()
  const utcToLocalOffset = useUTCToLocalOffset()

  return useMemo(() => {
    const priceChangeAnnotations = (priceChanges ?? []).flatMap((change) => {
      const { from, rateAmount, rateMeanDelta, to } = change
      if (!rateMeanDelta || !from || !to) return []

      const onPeak = rateMeanDelta > 0
      return [
        {
          x: utcToLocalOffset(from),
          x2: utcToLocalOffset(to),
          borderColor: palette.common.black,
          fillColor: palette.primary.light,
          opacity: onPeak ? 0.25 : 0.1,
          label: {
            text:
              (onPeak ? 'On-Peak' : 'Off-Peak') +
              (rateAmount ? ' @ $' + rateAmount.toFixed(2) + ' / kWh' : ''),
            offsetX: 18,
            orientation: 'vertical',
          },
        },
      ]
    })

    const siteEventAnnotations = siteEvents.flatMap((event) => {
      const { curtailedSiteLimit, scheduledEnd, scheduledStart } = event
      if (!scheduledEnd) return []

      return [
        {
          x: utcToLocalOffset(tzDayjs(scheduledStart)),
          x2: utcToLocalOffset(tzDayjs(scheduledEnd)),
          borderColor: palette.common.black,
          strokeDashArray: 5,
          width: 2,
          opacity: 0.1,
          fillColor:
            curtailedSiteLimit && curtailedSiteLimit < 0
              ? palette.success.main
              : charting[7].main,
        },
      ]
    })

    return [...priceChangeAnnotations, ...siteEventAnnotations]
  }, [charting, palette, priceChanges, siteEvents, tzDayjs, utcToLocalOffset])
}

function useForecast(forecast?: RootAPI.PowerTimeSeriesModel) {
  const { tzDayjs } = useUserPrefs()

  return useMemo<Chart.UtilizationChartDatum[]>(() => {
    if (!forecast?.powerLevels) return []

    const data = forecast.powerLevels.map(
      ({ powerLevel, startEpochSeconds: start }) => ({
        x: tzDayjs(start !== undefined ? start * 1000 : null).toISOString(),
        y: (powerLevel ?? 0) / 1000,
      })
    )

    const lastDatum = data.at(-1)
    if (!lastDatum) return []

    // If the forecast period ends before the last data point, truncate the last data point
    if (forecast.endTimestamp) {
      const lastTime = new Date(lastDatum.x)
      const forecastEnd = new Date(forecast.endTimestamp)
      const truncatedEnd = Math.min(lastTime.getTime(), forecastEnd.getTime())
      lastDatum.x = tzDayjs(truncatedEnd).toISOString()
    }

    return data
  }, [forecast, tzDayjs])
}

function usePowerData(
  from: dayjs.Dayjs,
  utilization?: RootAPI.PowerUtilizationModel
): Tuple<Chart.ApexUtilizationChartDatum[]> {
  const data = Chart.usePowerData(from, utilization)
  return useMemo(() => {
    const [chargeData, dischargeData] = data

    const lastCharge = chargeData.at(-1)
    const lastDischarge = dischargeData.at(-1)
    if (!lastCharge || !lastDischarge) return [[], []]
    return [
      [...chargeData, { x: lastCharge.x, y: 0 }],
      [...dischargeData, { x: lastDischarge.x, y: 0 }],
    ]
  }, [data])
}

function useSiteLimit(
  from: dayjs.Dayjs,
  events: GenericEvent[],
  powerCeiling?: number
) {
  const { tzDayjs } = useUserPrefs()

  return useMemo(() => {
    const data = events.reduce<Chart.UtilizationChartDatum[]>((acc, event) => {
      const { curtailedSiteLimit, scheduledEnd, scheduledStart } = event
      if (isUndefined(curtailedSiteLimit) || isUndefined(powerCeiling))
        return acc

      const eventStart = tzDayjs(scheduledStart).toISOString()
      const eventEnd = tzDayjs(scheduledEnd).toISOString()

      return [
        ...acc,
        { x: eventStart, y: curtailedSiteLimit },
        { x: eventEnd, y: curtailedSiteLimit },
        { x: eventEnd, y: powerCeiling },
      ]
    }, [])

    data.sort((a, b) => tzDayjs(a.x).diff(b.x))
    data.unshift({ x: from.toISOString(), y: powerCeiling ?? 0 })
    return data
  }, [powerCeiling, from, events, tzDayjs])
}

function useAlignedData(
  from: dayjs.Dayjs,
  events: GenericEvent[],
  depotForecast?: RootAPI.PowerTimeSeriesModel,
  depotUtilization?: RootAPI.PowerUtilizationModel,
  powerCeiling?: number
) {
  const forecast = useForecast(depotForecast)
  const siteLimitData = useSiteLimit(from, events, powerCeiling)
  const [imports, exports] = usePowerData(from, depotUtilization)

  return useMemo(() => {
    const arrays = [imports, exports, siteLimitData, forecast]

    // Merge all arrays and extract unique timestamps
    const timestamps = new Set(map(flatten(arrays), 'x'))
    const sortedTimestamps = [...timestamps].sort((a, b) => dayjs(a).diff(b))

    // For each original array, create a new array with objects for all timestamps
    return arrays.map((array): Chart.UtilizationChartDatum[] => {
      // Create a map for faster lookup
      const map = new Map(array.map((obj) => [obj.x, obj.y]))

      // Return new array with objects for all timestamps
      let mostRecentY = 0
      return sortedTimestamps.map((timestamp) => {
        // If y value exists for the timestamp, update mostRecentY
        const mapValue = map.get(timestamp)
        mostRecentY = typeof mapValue === 'number' ? mapValue : mostRecentY

        return { x: timestamp, y: mostRecentY }
      })
    }) as Quad<Chart.ApexUtilizationChartDatum[]>
  }, [imports, exports, siteLimitData, forecast])
}
