import {
  ChargingTransactionReportRow,
  RootAPI,
  useOrgVehicles,
  useUserPrefs,
} from '@synop-react/api'
import { useMemo } from 'react'
import dayjs from 'dayjs'

export const useChargingSessions = (
  data?: RootAPI.PagedResponseTransactionSummaryModel
) => {
  const { tzDayjs } = useUserPrefs()
  const { orgVehicles } = useOrgVehicles()

  return useMemo(() => {
    const chargingSessions = data?.items || []

    return chargingSessions.reduce<ChargingTransactionReportRow[]>(
      (acc, transaction) => {
        const {
          id,
          transactionPk,
          connectorId,
          chargerId,
          chargerName,
          depotId,
          organizationId,
          startTimestamp,
          stopTimestamp,
          ocppTag,
        } = transaction

        if (
          id &&
          transactionPk &&
          connectorId &&
          chargerId &&
          chargerName &&
          depotId &&
          organizationId &&
          startTimestamp
        ) {
          // Add duration in seconds as well as other info for csv
          const startTime = tzDayjs(startTimestamp)
          const endTime = tzDayjs(stopTimestamp || tzDayjs())
          const durationSeconds = dayjs
            .duration(endTime.diff(startTime))
            .asSeconds()
          const vehicleFound = Object.values(orgVehicles).find(
            (vehicle) => vehicle.macAddress === ocppTag
          )
          const vin = vehicleFound?.vin || ''
          const vehicleId = vehicleFound?.id || ''

          if (durationSeconds >= 0) {
            acc.push({
              ...transaction,
              id,
              transactionPk,
              connectorId,
              chargerId,
              chargerName,
              depotId,
              organizationId,
              startTimestamp,
              vin,
              vehicleId,
              durationSeconds,
            })
          }
        }
        return acc
      },
      []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, orgVehicles])
}
