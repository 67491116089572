export const alarms = [
  {
    id: '7196799974bc11ed85f102170c054f71',
    alarmNm: 'Vehicle Range Check',
    alarmDesc: 'Check to see if vehicle has enough range to get home.',
    alarmMessage: 'Vehicle does not have enough range to get home.',
  },
  {
    id: '72966b3a74bc11ed85f102170c054f71',
    alarmNm: 'Vehicle Return Home Check',
    alarmDesc: 'Check to see if vehicle has returned home.',
    alarmMessage: 'Vehicle is at home.',
  },
  {
    id: '72fdba6174bc11ed85f102170c054f71',
    alarmNm: 'Vehicle SoC Check',
    alarmDesc: "Check to see if vehicle's state of charge is lower than 30%.",
    alarmMessage: "Vehicle's SoC is below 30%.",
  },
  {
    id: '73615a4174bc11ed85f102170c054f71',
    alarmNm: 'Vehicle Charging Check',
    alarmDesc: 'Check to see if vehicle is charging.',
    alarmMessage: 'Vehicle is not charging.',
  },
]

export const notifications = [
  {
    id: '1',
    created: '2022-12-06T13:25:02.890Z',
    updated: '2022-12-06T13:25:02.890Z',
    logicalDeleteIn: false,
    alarmId: '4028d60b7e400a84017e40162fca0003',
    triggeredDateTime: '2022-12-06T13:25:02.890Z',
    alarmNm: 'Vehicle Range Check',
    entityType: 'Vehicle',
    entityValue: '2ca0857a81490d850181490eeeab0002',
    entityName: 'Bus 64',
  },
  {
    id: '2',
    created: '2022-12-06T13:25:02.890Z',
    updated: '2022-12-06T13:25:02.890Z',
    logicalDeleteIn: false,
    alarmId: '4028d60b7e400a84017e40162fca0003',
    triggeredDateTime: '2022-12-06T13:25:02.890Z',
    alarmNm: 'Vehicle SoC Check',
    entityType: 'Vehicle',
    entityValue: '2ca0857a81490d850181490eeeab0002',
    entityName: 'Bus 64',
  },
  {
    id: '3',
    created: '2022-12-06T13:25:02.890Z',
    updated: '2022-12-06T13:25:02.890Z',
    logicalDeleteIn: false,
    alarmId: '4028d60b7e400a84017e40162fca0003',
    triggeredDateTime: '2022-12-06T13:25:02.890Z',
    alarmNm: 'Vehicle SoC Check',
    entityType: 'Vehicle',
    entityValue: '2ca0857a81490d850181490eeeab0002',
    entityName: 'Bus 64',
  },
  {
    id: '4',
    created: '2022-12-06T13:25:02.890Z',
    updated: '2022-12-06T13:25:02.890Z',
    logicalDeleteIn: false,
    alarmId: '4028d60b7e400a84017e40162fca0003',
    triggeredDateTime: '2022-12-06T13:25:02.890Z',
    alarmNm: 'Vehicle Range Check',
    entityType: 'Vehicle',
    entityValue: '2ca0857a81490d850181490eeeab0002',
    entityName: 'Bus 64',
  },
]

export const notificationsByAlarmId = [
  {
    created: '2022-07-29T14:43:10.308Z',
    updated: '2022-07-29T14:43:10.309Z',
    logicalDeleteIn: false,
    alarmId: '4028d60b7e400a84017e40162fca0000',
    notificationType: 'EMAIL',
    notificationValue: 'andrew@synop.ai',
    active: true,
  },
  {
    created: '2022-03-02T14:54:00.000Z',
    updated: '2022-03-02T14:54:00.000Z',
    logicalDeleteIn: false,
    alarmId: '4028d60b7e400a84017e40162fca0000',
    notificationType: 'EMAIL',
    notificationValue: 'kevin@synop.ai',
    active: true,
  },
]
