import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form'
import { Stack, Switch, SwitchProps, Typography } from '@mui/material'

type SwitchFormControls<FormData extends FieldValues> = {
  id: Path<FormData>
  control: Control<FormData, unknown>
  error?: FieldError
}

export type SwitchFormFieldProps<FormData extends FieldValues> = {
  label?: string
} & SwitchProps &
  SwitchFormControls<FormData>

const SwitchFormField = function <FormData extends FieldValues>({
  control,
  error,
  label,
  id,
  ...SwitchProps
}: SwitchFormFieldProps<FormData>) {
  const SwitchLabel = label ? <InputLabelText label={label} /> : null

  return (
    <Stack>
      {SwitchLabel}
      <Controller
        control={control}
        name={id}
        render={({ field: { ref, value, ...field } }) => {
          return (
            <Switch
              checked={Boolean(value)}
              inputRef={ref}
              {...field}
              {...SwitchProps}
            />
          )
        }}
      />
    </Stack>
  )
}

export default SwitchFormField

type InputLabelTextProps = {
  label: string
}
export const InputLabelText = ({ label }: InputLabelTextProps) => {
  return (
    <Typography
      sx={{
        color: 'text.secondary',
      }}
      variant="caption"
    >
      {label}
    </Typography>
  )
}
