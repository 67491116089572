import { Components } from '@mui/material'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/titillium-web'

const bodyFontFamily = 'Open Sans'
const headerFontFamily = 'Titillium Web'

export const typography = {
  fontFamily: [headerFontFamily, bodyFontFamily].join(','),

  h1: {
    fontFamily: headerFontFamily,
    fontWeight: 600,
    fontSize: '96px',
    lineHeight: '112.03px',
    letterSpacing: '-1.5px',
  },

  h2: {
    fontFamily: headerFontFamily,
    fontWeight: 300,
    fontSize: '60px',
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },

  h3: {
    fontFamily: headerFontFamily,
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '56.02px',
  },

  h4: {
    fontFamily: headerFontFamily,
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: '41.99px',
    letterSpacing: '0.25px',
  },

  h5: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32.02px',
  },

  h6: {
    fontFamily: bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },

  subtitle1: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },

  subtitle2: {
    fontFamily: bodyFontFamily,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21.98px',
    letterSpacing: '0.1px',
  },

  body1: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },

  body2: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
  },

  caption: {
    fontFamily: bodyFontFamily,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '19.92px',
    letterSpacing: '0.4px',
  },

  overline: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '32px',
    letterSpacing: '1px',
    textTransform: 'uppercase' as const,
  },
}

export const typographyComponents: Components = {
  MuiAvatar: {
    styleOverrides: {
      root: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20.02px',
        letterSpacing: '0.17px',
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: bodyFontFamily,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        textTransform: 'uppercase',
      },
      sizeLarge: {
        fontFamily: bodyFontFamily,
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '26px',
        letterSpacing: '0.46px',
      },
      sizeSmall: {
        fontFamily: bodyFontFamily,
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.46px',
      },
    },
  },

  MuiDialog: {
    defaultProps: {
      PaperProps: {
        sx: {
          borderRadius: '16px',
        },
      },
    },
  },

  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: 'flex-start',
        paddingLeft: '16px',
        paddingBottom: '16px',
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20.02px',
        letterSpacing: '0.17px',
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      input: {
        '&::placeholder': {
          color: 'black',
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
      inputAdornedEnd: {
        color: 'primary',
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20.02px',
        letterSpacing: '0.17px',
      },
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      bar: {
        borderRadius: '8px',
      },
      root: {
        borderRadius: '8px',
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20.02px',
        letterSpacing: '0.17px',
      },
    },
  },
}
