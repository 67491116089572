import { useLocation, useNavigate } from 'react-router-dom'

// =============================================================================
// Route constants
// =============================================================================

const ALERTS_ROUTE = '/alerts'
const CHARGERS_ROUTE = '/chargers'
const DASHBOARD_ROUTE = '/dashboard'
const SETTINGS_ROUTE = '/settings'
const SITES_ROUTE = '/sites'
const VEHICLES_ROUTE = '/vehicles'
const TRIPS_ROUTE = 'trips'

const makeRoute = (...components: Array<string | number>) =>
  components.join('/')
const entityRouteFn = (entityRoute: string) => (entityId: string) =>
  [entityRoute, entityId].join('/')

const dashboardPage = entityRouteFn(DASHBOARD_ROUTE)
const settingsPage = entityRouteFn(SETTINGS_ROUTE)

export type RouteDirectory = ReturnType<typeof useRouting>['routes']
export function useRouting() {
  return {
    location: useLocation(),
    navigate: useNavigate(),
    routes: {
      alerts: {
        root: ALERTS_ROUTE,
        new: makeRoute(ALERTS_ROUTE, 'new'),
        edit: (alertId: string) => makeRoute(ALERTS_ROUTE, alertId, 'edit'),
      },
      chargers: {
        root: CHARGERS_ROUTE,
        details: entityRouteFn(CHARGERS_ROUTE),
      },
      dashboard: {
        root: DASHBOARD_ROUTE,
        chargers: dashboardPage('chargers'),
        vehicles: dashboardPage('vehicles'),
      },
      login: '/login',
      notifications: '/notifications',
      reports: '/reports',
      resetPassword: '/password/reset',
      root: '/',
      settings: {
        root: SETTINGS_ROUTE,
        alerts: settingsPage('alerts'),
        apiKeys: settingsPage('api-keys'),
        billing: settingsPage('billing'),
        homeCharging: settingsPage('home-charging'),
        workplaceCharging: settingsPage('workplace-charging'),
        chargers: settingsPage('chargers'),
        dataSources: settingsPage('data-sources'),
        fleets: settingsPage('fleets'),
        orgManagement: settingsPage('org-mgmt'),
        locations: settingsPage('locations'),
        orgs: settingsPage('organizations'),
        sites: settingsPage('sites'),
        themes: settingsPage('themes'),
        users: settingsPage('users'),
        vehicles: settingsPage('vehicles'),
      },
      signUp: '/signup',
      siteDetails: entityRouteFn(SITES_ROUTE),
      sites: SITES_ROUTE,
      vehicles: {
        root: VEHICLES_ROUTE,
        details: entityRouteFn(VEHICLES_ROUTE),
        trips: {
          root: (vehicleId: string) =>
            makeRoute(VEHICLES_ROUTE, vehicleId, TRIPS_ROUTE),
          details: (vehicleId: string, tripId: string) =>
            makeRoute(VEHICLES_ROUTE, vehicleId, TRIPS_ROUTE, tripId),
        },
      },
    },
  }
}
