import { AxisLeft, TickLabelProps, TickRendererProps } from '@visx/axis'
import { TextProps } from '@visx/text'
import { useTheme } from '@mui/material'

import {
  selectDisplayYAxis,
  selectEventIds,
  selectLeftAxisArea,
  selectYScale,
} from '../useTimeline/useTimelineReducer/selectors'
import { useTimelineSelector } from '../useTimeline/useTimeline'
import GroupedAxisLabel, { AxisLabelFormatter } from './GroupedAxisLabel'

type OrdinalAxisProps = {
  labelFormatter?: AxisLabelFormatter
}

export default function OrdinalAxis({ labelFormatter }: OrdinalAxisProps) {
  const theme = useTheme()
  const { width: leftAxisWidth } = useTimelineSelector(selectLeftAxisArea)
  const shouldDisplayAxis = useTimelineSelector(selectDisplayYAxis)
  const yScale = useTimelineSelector(selectYScale)
  const eventIds = useTimelineSelector(selectEventIds)

  const { textTransform, ...labelStyle } = theme.typography.overline
  const labelThemeStyle: TickLabelProps<string> = () =>
    labelStyle as Partial<TextProps>

  const tickComponent = labelFormatter
    ? {
        tickComponent: (tickProps: TickRendererProps) => (
          <GroupedAxisLabel
            labelFormatter={labelFormatter}
            maxWidth={leftAxisWidth}
            {...tickProps}
          />
        ),
      }
    : {}

  const leftPadding = 16

  return shouldDisplayAxis ? (
    <AxisLeft
      hideAxisLine={true}
      hideTicks={true}
      left={leftPadding}
      numTicks={eventIds.length}
      scale={yScale}
      tickLabelProps={labelThemeStyle}
      {...tickComponent}
    />
  ) : null
}
