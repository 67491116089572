import * as yup from 'yup'
import { AutocompleteSelect } from '../../FormField'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { Cask } from '../../Cask'
import { DepotAutocomplete } from '../../Autocomplete/DepotAutocompleteSelect'
import {
  FieldError,
  FieldValues,
  FormProvider,
  Path,
  useForm,
  useFormContext,
  UseFormReturn,
  useWatch,
} from 'react-hook-form'
import { FleetAutocomplete, OrgAutocomplete } from '../../Autocomplete'
import { FormField } from '../../'
import { LoadingButton } from '@mui/lab'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { ReportArgs } from '..'
import { RootAPI, useCurrentOrgId, useUserPrefs } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs, { Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(minMax)

export type SelectedReport =
  | ({
      from: Dayjs
      to: Dayjs
      type: ReportType
    } & Omit<ReportArgs, 'from' | 'to'>)
  | null

type ReportSelectorForm = {
  organizations: RootAPI.OrganizationModel | null
  sites: RootAPI.DepotModel | null
  fleets: RootAPI.Fleet | null
  from: Dayjs
  to: Dayjs
  reportType: ReportOption
}

export type ReportSelectorCaskProps = {
  isLoading?: boolean
  setSelectedReport: (report: SelectedReport) => void
}

const reportValidation = yup
  .object({
    from: yup.date().required(),
    to: yup
      .date()
      .min(yup.ref('from'), 'The end time must be after the start time.')
      .required(),
  })
  .required()

export const ReportSelectorCask = ({
  isLoading = false,
  setSelectedReport,
}: ReportSelectorCaskProps) => {
  const { tzDayjs } = useUserPrefs()
  const { palette } = useTheme()
  const orgId = useCurrentOrgId()
  const formControls = useForm<ReportSelectorForm>({
    defaultValues: {
      reportType: reportOptions.VEHICLE_ACTIVITY,
      organizations: null,
      sites: null,
      fleets: null,
      from: tzDayjs().subtract(1, 'week').startOf('day'),
      to: tzDayjs(),
    },
    resolver: yupResolver(reportValidation),
  })

  const orgSelect = useWatch({
    control: formControls.control,
    name: 'organizations',
  })

  const reportOrgId = useMemo(() => orgSelect?.id || orgId, [orgSelect, orgId])

  useEffect(() => {
    formControls.setValue('sites', null)
    formControls.setValue('fleets', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSelect])

  const handleSubmit = ({
    reportType,
    from,
    to,
    organizations,
    sites,
    fleets,
  }: ReportSelectorForm) => {
    const organizationId = organizations ? organizations.id : undefined
    const siteId = sites ? sites.depotId : undefined
    const fleetId = fleets ? fleets.id : undefined

    setSelectedReport({
      type: reportType.type,
      from: tzDayjs(from),
      to: tzDayjs(to),
      organizationId,
      siteId,
      fleetId,
    })
  }

  const reportType = useWatch({
    control: formControls.control,
    name: 'reportType',
  })

  const selectedFrom = useWatch({ control: formControls.control, name: 'from' })

  const shouldShowFleetSelector = useMemo(
    () => (reportType?.type === 'CHARGING_TRANSACTIONS' ? false : true),
    [reportType]
  )

  const {
    control,
    formState: { errors, touchedFields },
  } = formControls
  const minFromTime = dayjs.min(tzDayjs(), tzDayjs(selectedFrom))

  return (
    <Cask
      subtitle="Select from the following options to view a report."
      title="Reports"
    >
      <Stack
        spacing={2}
        sx={{ backgroundColor: palette.secondary['4p'], p: 3 }}
      >
        <FormBox {...{ formControls, handleSubmit }}>
          <FormItemBox>
            <ReportTypeDropdown id="reportType" />
          </FormItemBox>
          <FormItemBox>
            <OrgAutocomplete.Select
              control={control}
              error={errors.organizations as FieldError}
              id="organizations"
              label="Organization Selector"
              orgId={orgId}
              touchedField={Boolean(touchedFields.organizations)}
            />
          </FormItemBox>
          <FormItemBox>
            <DepotAutocomplete.Select
              control={control}
              error={errors.sites as FieldError}
              fleetId={reportOrgId}
              id="sites"
              label="Site Selector"
              touchedField={Boolean(touchedFields.sites)}
            />
          </FormItemBox>

          <FormItemBox>
            {shouldShowFleetSelector && (
              <FleetAutocomplete.Select
                control={control}
                error={errors.fleets as FieldError}
                id="fleets"
                label="Fleet Selector"
                orgId={reportOrgId}
                touchedField={Boolean(touchedFields.fleets)}
              />
            )}
          </FormItemBox>

          <FormItemBox>
            <FormField.DateTimePicker
              control={control}
              disableFuture
              id="from"
              label="Report Start Time"
              variant="standard"
            />
          </FormItemBox>

          <FormItemBox>
            <FormField.DateTimePicker
              control={control}
              disableFuture
              id="to"
              label="Report End Time"
              minDateTime={minFromTime}
              variant="standard"
            />
          </FormItemBox>
        </FormBox>

        <Box>
          <LoadingButton
            loading={isLoading}
            onClick={formControls.handleSubmit(handleSubmit)}
            size="medium"
            variant="contained"
          >
            VIEW REPORT
          </LoadingButton>
        </Box>
      </Stack>
    </Cask>
  )
}

export default ReportSelectorCask

type FormBoxProps<FormData extends FieldValues> = {
  formControls: UseFormReturn<FormData>
  handleSubmit: (formData: FormData) => void
  children: ReactNode
}

const FormBox = <FormData extends FieldValues>({
  children,
  formControls,
  handleSubmit,
}: FormBoxProps<FormData>) => {
  return (
    <FormProvider {...formControls}>
      <form onSubmit={formControls.handleSubmit(handleSubmit)}>
        <Grid container spacing={3}>
          {children}
        </Grid>
      </form>
    </FormProvider>
  )
}
type FormBoxItemProps = { children: ReactNode }
const FormItemBox = ({ children }: FormBoxItemProps) => {
  return (
    <Grid item md={3}>
      {children}
    </Grid>
  )
}

export type ReportType = 'VEHICLE_ACTIVITY' | 'TRIP' | 'CHARGING_TRANSACTIONS'
export type ReportOption = {
  label: string
  type: ReportType
}

export const reportOptions: Record<ReportType, ReportOption> = {
  CHARGING_TRANSACTIONS: {
    label: 'Charging Transactions',
    type: 'CHARGING_TRANSACTIONS',
  },
  VEHICLE_ACTIVITY: {
    label: 'Vehicle Activity',
    type: 'VEHICLE_ACTIVITY',
  },
  TRIP: {
    label: 'Trips',
    type: 'TRIP',
  },
}

type ReportTypeDropdownProps<FormData extends FieldValues> = {
  id: Path<FormData>
}
const ReportTypeDropdown = <FormData extends FieldValues>({
  id,
}: ReportTypeDropdownProps<FormData>) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext<FormData>()
  const error = errors[id] as FieldError
  const touchedField = touchedFields[id]

  return (
    <AutocompleteSelect<FormData, ReportOption>
      getOptionLabel={(option) => `${option.label}`}
      isOpen={isOpen}
      keyExtractor={(option) => option.type}
      label="Report Type"
      options={Object.values(reportOptions)}
      setIsOpen={setIsOpen}
      {...{ id, error, control, touchedField }}
    />
  )
}
