import {
  AxisLabelFormatter,
  CreateChargingSessionOverlay,
  CreateChargingSessionOverlayProps,
  EventGroupingKey,
  EventOption,
  EventOptions,
  TimelineCask,
  useAvailableOption,
  useDisplayedDateWithTimeRange,
  usePredictedEventOption,
} from '@synop-react/common'
import {
  ScheduledEvent,
  useChargerDetailsFromPoll,
  useDepotChargers,
  useDepotDetailsFromPoll,
  useUserPrefs,
} from '@synop-react/api'
import { useMemo } from 'react'
import useScheduledChargeOption from '../ChargingTimeline/useScheduledChargeOption'
import useUnscheduleChargerOption from '../ChargingTimeline/useUnscheduledChargerOption'

export interface ChargerTimelineProps {
  chargerId: string
}

type CreateOverlayProps = {} & Pick<
  CreateChargingSessionOverlayProps,
  'setIsOpen' | 'isOpen'
> &
  Partial<ScheduledEvent>

export function ChargerTimeline({ chargerId }: ChargerTimelineProps) {
  const { tzDayjs } = useUserPrefs()

  const {
    getCharger: { data: charger },
  } = useChargerDetailsFromPoll({
    chargerId,
    pollingIntervalInSeconds: 5,
  })

  const { displayedDate, setDisplayedDate, to, from } =
    useDisplayedDateWithTimeRange()

  const {
    getDepot: { data: depot },
    getScheduledChargerSessions: {
      isLoading: isLoadingSessions,
      isError: error,
      data: depotEvents = [],
    },
  } = useDepotDetailsFromPoll({
    depotId: charger?.depotId || '',
    from,
    to,
  })

  const { isLoading: isLoadingChargers, depotChargers } =
    useDepotChargers(chargerId)

  const scheduledChargeOptions = useScheduledChargeOption({
    depotId: charger?.depotId || '',
  })
  const unscheduledChargeOption = useUnscheduleChargerOption({
    depotId: charger?.depotId || '',
  })
  const availableChargeOption = useAvailableOption({
    renderOverlay: ({
      dispenserId,
      setIsOpen,
      isOpen,
      scheduledEnd,
      chargerId = '',
    }: CreateOverlayProps) => {
      return tzDayjs(scheduledEnd).isAfter(tzDayjs()) ? (
        <CreateChargingSessionOverlay
          defaultCharger={depotChargers.entities[chargerId]}
          defaultConnector={{ connectorId: Number(dispenserId) }}
          defaultDepot={depot}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : null
    },
  })
  const predictedEventOption = usePredictedEventOption({
    subtitle: 'Predicted',
    renderOverlay: undefined,
  })

  const chargerEvents = useMemo(
    () =>
      depotEvents.filter(
        (event) =>
          chargerId === event.chargerId && event.dispenserId && event.chargerId
      ),
    [depotEvents, chargerId]
  )

  const isFirstSessionLoad = isLoadingSessions && error === undefined
  const isLoading = isFirstSessionLoad || isLoadingChargers

  const chargerEventOptions: EventOptions = {
    ...availableChargeOption,
    ...scheduledChargeOptions,
    ...unscheduledChargeOption,
    ...predictedEventOption,
    SITE_LIMIT: {
      barColor: 'green',
      caption: { title: 'noop' },
      // tooltip: { title: 'noop', details: [{ data: 'hello' } ],
    } as EventOption,
  }

  const connectorIds =
    charger?.connectorIds?.flatMap((id) => (id ? [`${id}`] : [])) || []

  const formatChargerLabel: AxisLabelFormatter = (connectorId = '') => {
    const connectorStatuses = charger?.connectorStatus || {}
    const connector = connectorStatuses[connectorId] || { status: 'Unknown' }
    return {
      primary: `Connector ${connectorId}`,
      secondary: connector.status,
    }
  }

  // TODO Update this with connectorId when the API types / mock data is updated @wslater
  const getConnectorKey: EventGroupingKey = (event) => {
    const { dispenserId, id } = event
    if (dispenserId) return dispenserId
    else return `${id}`
  }

  return (
    <TimelineCask
      displayedDate={displayedDate}
      eventIds={connectorIds}
      eventOptions={chargerEventOptions}
      groupingKey={getConnectorKey}
      isLoading={isLoading}
      scheduledEvents={chargerEvents as ScheduledEvent[]}
      setDisplayedDate={setDisplayedDate}
      title={'Charger Schedule'}
      tooltip={'Schedule power limits or optimized charging for this charger.'}
      yAxisLabelFormatter={formatChargerLabel}
    />
  )
}

export default ChargerTimeline
