import { CircularProgress, Link, Stack, Typography } from '@mui/material'
import { emDash, formatVehicleName } from '../../utils'
import { useOrgVehicles } from '@synop-react/api'

type VehicleLinkProps = { id?: string }

const VehicleLink = ({ id = '' }: VehicleLinkProps) => {
  const { orgVehicles, isLoading } = useOrgVehicles()

  const vehicle = orgVehicles[id]
  const vehicleName = vehicle ? formatVehicleName(vehicle) : emDash

  if (vehicle) {
    return (
      <Stack direction="row" spacing={1}>
        {isLoading && <CircularProgress size="1rem"></CircularProgress>}
        <Link href={`/vehicles/${id}`} underline="none">
          {vehicleName}
        </Link>
      </Stack>
    )
  }

  return <Typography>{vehicleName}</Typography>
}

export default VehicleLink
