import { Box, Stack } from '@mui/material'
import { DepotMapTile } from '../DepotMapTile'
import { DrawControl } from '@synop-react/common'
import {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw'
import { Feature, Polygon } from '@turf/helpers'
import { GeofenceProperties } from '@synop-react/types'
import { useCallback } from 'react'

export type EditGeofenceMapProps = {
  depotMarker?: [number, number] | []
  setDepotMarker?: (newMarker: [number, number]) => void
  geofence?: Feature<Polygon, GeofenceProperties>
  setGeofence: (
    geofence: Feature<Polygon, GeofenceProperties> | undefined
  ) => void
}

export const EditGeofenceMap = ({
  depotMarker = [],
  geofence,
  setGeofence,
}: EditGeofenceMapProps) => {
  const onCreate = useCallback<OnDrawCreateCallback>((e) => {
    const mapGeofences = e.features.filter(
      (feature) => feature.geometry.type === 'Polygon'
    ) as Feature<Polygon, GeofenceProperties>[]
    const newGeofence = mapGeofences[0]
    setGeofence(newGeofence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUpdate = useCallback<OnDrawUpdateCallback>((e) => {
    const mapGeofences = e.features.filter(
      (feature) => feature.geometry.type === 'Polygon'
    ) as Feature<Polygon, GeofenceProperties>[]
    const newGeofence = mapGeofences[0]
    setGeofence(newGeofence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDelete = useCallback<OnDrawDeleteCallback>(() => {
    /**
     * Because we dont show controls for deleting and delete and
     * backspace key commands do not flow through to the map
     * correctly, I dont know that there is anyway for this to
     * actually trigger.
     */
    setGeofence(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack spacing={1}>
      <Box sx={{ width: '100%', height: '250px' }}>
        <DepotMapTile
          canZoom={true}
          geofence={geofence}
          latitude={depotMarker[1]}
          longitude={depotMarker[0]}
        >
          <DrawControl
            defaultMode="draw_polygon"
            displayControlsDefault={false}
            onCreate={onCreate}
            onDelete={onDelete}
            onUpdate={onUpdate}
            position="top-left"
          />
        </DepotMapTile>
      </Box>
    </Stack>
  )
}

export default EditGeofenceMap

type OnDrawCreateCallback = (evt: DrawCreateEvent) => void
type OnDrawUpdateCallback = (evt: DrawUpdateEvent) => void
type OnDrawDeleteCallback = (evt: DrawDeleteEvent) => void
