import { AssetType, RootAPI } from '@synop-react/api'
import {
  chainControlFactory,
  numberControlFactory,
  stringControlFactory,
} from './controls'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import {
  formlessSimpleAutocompleteFactory,
  FormlessSimpleAutocompleteOption,
  FormlessSimpleAutocompleteProps,
} from '@synop-react/common'
import { isNotEmpty } from '@synop-react/types'
import { Property, Rule } from '../AlertSteps'

const { useGetPropertiesQuery } = RootAPI

export type ControlProps = {
  property: Property
  updateProperty: (updatedProperty: Property) => void
  isSecondary?: boolean
}

type Control = {
  model: RootAPI.PropertyModel
  defaultProperty: Rule
  component: FC<ControlProps>
}

type ControlMap = Record<string, Control>
type AssetTypeControlMaps = Record<AssetType, ControlMap>

type AlertPropertyContextType = {
  propertyFieldMap: Record<string, RootAPI.PropertyModel>
  propertyDropdown: Record<AssetType, FC<FormlessSimpleAutocompleteProps>>
  propertyControlMap: AssetTypeControlMaps
  propertyIdControlMap: ControlMap
}

const defaultAlertPropertyState = {
  propertyFieldMap: {},
  propertyDropdown: {
    Charger: () => null,
    Vehicle: () => null,
  },
  propertyControlMap: { Charger: {}, Vehicle: {} },
  propertyIdControlMap: {},
}

const AlertPropertyContext = createContext<AlertPropertyContextType>(
  defaultAlertPropertyState
)

export const useAlertPropertyContext = () => useContext(AlertPropertyContext)
export const AlertPropertyProvider = ({ children }: PropsWithChildren) => {
  const { data: properties } = useGetPropertiesQuery()

  const controls = useMemo(() => {
    if (!properties) return defaultAlertPropertyState

    const propertyFieldMap: Record<string, RootAPI.PropertyModel> = {}
    const propertyDropdownOptions: Record<
      AssetType,
      FormlessSimpleAutocompleteOption<string>[]
    > = {
      Charger: [],
      Vehicle: [],
    }

    const propertyIdControlMap: ControlMap = {}
    const propertyControlMap: AssetTypeControlMaps = {
      Charger: {},
      Vehicle: {},
    }

    properties.forEach((property) => {
      const { assetType, chainableProperties, displayName, id, propertyName } =
        property

      propertyFieldMap[propertyName] = property
      propertyDropdownOptions[assetType].push({
        name: displayName,
        id: propertyName,
      })

      const controlComponent = isNotEmpty(chainableProperties)
        ? chainControlFactory({
            chainableProperties,
            MainControl: getControlComponent(property),
          })
        : getControlComponent(property)

      const control = {
        model: property,
        defaultProperty: getDefaultProperty(property),
        component: controlComponent,
      }

      propertyControlMap[assetType][propertyName] = control
      propertyIdControlMap[id] = control
    })

    const propertyDropdown = {
      Charger: formlessSimpleAutocompleteFactory(
        propertyDropdownOptions.Charger
      ),
      Vehicle: formlessSimpleAutocompleteFactory(
        propertyDropdownOptions.Vehicle
      ),
    }

    return {
      propertyFieldMap,
      propertyDropdown,
      propertyControlMap,
      propertyIdControlMap,
    }
  }, [properties])

  return (
    <AlertPropertyContext.Provider value={controls}>
      {children}
    </AlertPropertyContext.Provider>
  )
}

function getDefaultProperty(property: RootAPI.PropertyModel): Rule {
  const { operators, options, propertyName, propertyType } = property

  const value = (() => {
    switch (propertyType) {
      case 'number':
        return '0'
      case 'string':
      case 'geospatial':
        return options[0]?.value ?? ''
      default:
        return ''
    }
  })()

  return {
    field: propertyName,
    operator: operators[0]?.value ?? '=',
    value,
  }
}

function getControlComponent(property: RootAPI.PropertyModel) {
  const { operators, options, propertyName, propertyType, units } = property
  switch (propertyType) {
    case 'number':
      return numberControlFactory({
        min: 0,
        max: units === 'km' ? 1000 : 100,
        unit: units ?? '',
        operators: operators.map((o) => ({ name: o.name, id: o.value })),
        withSlider: units === '%',
      })
    case 'string':
    case 'geospatial':
      return stringControlFactory({
        label: propertyName.charAt(0).toUpperCase() + propertyName.slice(1),
        operators: operators.map((o) => ({ name: o.name, id: o.value })),
        options: options.map((o) => ({ name: o.name, id: o.value })),
      })
    default:
      return () => null
  }
}
