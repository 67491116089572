import { apiReducers } from './apis'
import { combineReducers } from '@reduxjs/toolkit'
import { currentOrganizationReducer } from '@synop-react/api'
import { displayReducer } from '@synop-react/common'
import { notificationOverlayReducer } from '@synop-react/notification'
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
  currentOrganization: currentOrganizationReducer,
  display: displayReducer,
  notificationOverlay: notificationOverlayReducer,
  ...apiReducers,
})

const rootReducer: typeof appReducer = (state, action) => {
  // Reset Redux state if a 'USER_LOGOUT' action is dispatched
  if (action.type === 'USER_LOGOUT') {
    // Remove persisted state from localstorage
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
