const featureFlags = [
  'apiKeys',
  'billing',
  'chargerRecalibration',
  'createVehicles',
  'customThemes',
  'dataSources',
  'notifications',
] as const
type FeatureKey = (typeof featureFlags)[number]

const isFeatureFlag = (maybeFlag: string): maybeFlag is FeatureKey =>
  featureFlags.includes(maybeFlag as FeatureKey)

// Features defined with the environment variable 'NX_FEATURES'
// e.g. $NX_FEATURES="notifications billing apiKeys"
// Make sure to wrap the flags with double quotes
const envFeatures = (process.env['NX_FEATURES'] || '')
  .split(' ')
  .filter(isFeatureFlag)

// Features to be used locally for development
// DON'T FORGET TO CLEAN THESE UP BEFORE PUSHING OR THEY'LL
// BE AVAILABLE IN ALL ENVIRONMENTS
const localFeatures: FeatureKey[] = []

const enabledFeatures: FeatureKey[] = [...localFeatures, ...envFeatures]

export const hasFeature = (featureKey: FeatureKey): boolean => {
  return enabledFeatures.includes(featureKey)
}

export default hasFeature

type HasFeatureProps = {
  children: JSX.Element
  feature: FeatureKey
}

export const HasFeature = ({ children, feature }: HasFeatureProps) => {
  return hasFeature(feature) ? children : null
}
