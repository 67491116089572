import {
  AdminFeature,
  Cask,
  hasFeature,
  LoadingMessage,
  PlusCircle,
  Table,
} from '@synop-react/common'
import { Button, Typography } from '@mui/material'
import { CreateOrEditVehicleOverlay } from '../CreateOrEditVehicleOverlay'
import { useOrgSites, useOrgVehicles, VehicleModel } from '@synop-react/api'
import { useVehicleModelFields } from '../Reports/fields'

export function VehicleSettings() {
  const { orgVehicles, isLoading: isLoadingVehicles } = useOrgVehicles()
  const { orgSites, isLoading: isLoadingSites } = useOrgSites()
  const { vehicleName, vin, modelYear, make, model } = useVehicleModelFields()

  const vehicleColumns: Table.ColumnSpec<VehicleModel> = [
    vehicleName.column,
    make.column,
    model.column,
    modelYear.column,
    vin.column,
    {
      field: 'homeSiteId',
      headerName: 'Home Site',
      width: 200,
      valueGetter: (params) => orgSites[params.value]?.depotNm,
    },
  ]
  const isLoading = isLoadingSites || isLoadingVehicles
  const vehicles = Object.values(orgVehicles)

  const descriptionText = hasFeature('createVehicles')
    ? 'Add or adjust the vehicles of your organization.'
    : 'View the vehicles of your organization.'

  return (
    <Cask
      Actions={
        <AdminFeature>
          <CreateOrEditVehicleOverlay
            Trigger={
              <Button startIcon={<PlusCircle />} variant="outlined">
                New Vehicle
              </Button>
            }
          />
        </AdminFeature>
      }
      title={'Vehicles'}
    >
      <Typography variant="body1">{descriptionText}</Typography>
      {isLoading ? (
        <LoadingMessage />
      ) : (
        <Table.ClientSide
          columns={vehicleColumns}
          height="450px"
          initialSortColumn="id" // 'id' defaults sort order to Vehicle Name
          tableData={vehicles}
        />
      )}
    </Cask>
  )
}

export default VehicleSettings
