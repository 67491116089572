import * as yup from 'yup'
import { matchIsValidTel } from 'mui-tel-input'

export type ValidatorOptions = {
  isRequired: boolean
  isRequiredMessage: string
}

const defaultEmailValidatorOptions: ValidatorOptions = {
  isRequired: true,
  isRequiredMessage: 'Enter your email',
}

export const emailValidator = (
  options: Partial<ValidatorOptions> = defaultEmailValidatorOptions
) => {
  const { isRequired, isRequiredMessage } = Object.assign(
    {},
    defaultPhoneValidatorOptions,
    options
  )
  const emailStringValidator = yup.string().email('Enter a valid email')

  return isRequired
    ? emailStringValidator.required(isRequiredMessage)
    : emailStringValidator
}

export const passwordValidator = yup
  .string()
  .min(
    8,
    'Your password must be at least 8 characters. Include multiple words and phrases to make it more secure.'
  )
  .required('Enter your password')

const defaultPhoneValidatorOptions: ValidatorOptions = {
  isRequired: true,
  isRequiredMessage: 'Phone number is required',
}

export const phoneNumberValidator = (
  options: Partial<ValidatorOptions> = defaultPhoneValidatorOptions
) => {
  const { isRequired, isRequiredMessage } = Object.assign(
    {},
    defaultPhoneValidatorOptions,
    options
  )

  const phoneValidator = yup
    .string()
    .test('phoneNumberValidator', 'Invalid phone number', (maybePhone = '') => {
      const isEmpty = maybePhone === ''
      return isRequired || !isEmpty ? matchIsValidTel(maybePhone) : isEmpty
    })
  return isRequired
    ? phoneValidator.required(isRequiredMessage)
    : phoneValidator
}
