import { emDash } from './constants'
import {
  formatUnitDesc,
  formatUnits,
  formatUnitsAbbr,
  UnitsFormat,
} from './units'
import { FuelUnit } from '@synop-react/api'

const fuelFormats: UnitsFormat<FuelUnit> = {
  gal: {
    long: 'Gallons',
    short: 'gal',
  },
  L: {
    long: 'Liters',
    short: 'L',
  },
}

export const formatFuelUnits = formatUnits(fuelFormats)
export const formatFuelUnitsAbbr = formatUnitsAbbr(fuelFormats)
export const formatFuelUnitsDesc = formatUnitDesc(fuelFormats)

type FuelOptions = {
  longForm: boolean
  sigFigs: number
}
const defaultFuelOptions: FuelOptions = {
  longForm: false,
  sigFigs: 0,
}

export const formatFuel = (
  value?: number | string,
  fuelUnits: FuelUnit = 'gal',
  options: Partial<FuelOptions> = defaultFuelOptions
) => {
  const { longForm, sigFigs } = { ...defaultFuelOptions, ...options }

  const units = longForm
    ? formatFuelUnits(fuelUnits)
    : formatFuelUnitsAbbr(fuelUnits)
  if (typeof value !== 'string' && typeof value !== 'number') return emDash

  const fuelValue =
    sigFigs > 0 ? parseFloat(`${value}`).toFixed(sigFigs) : parseInt(`${value}`)

  return `${fuelValue} ${units}`
}
