import {
  Box,
  Card,
  CircularProgress,
  Grid,
  GridProps,
  PaperProps,
  Skeleton,
  Stack,
  StackProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { DistributiveOmit } from '@mui/types'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from '../Fallback'
import { Info as InfoIcon } from '../Icons'
import { ReactNode } from 'react'
import merge from 'ts-deepmerge'

type CardProps = DistributiveOmit<PaperProps, 'classes' | 'title'> & {
  sx?: SxProps<Theme>
}

export type CaskProps = {
  title: ReactNode
  children: ReactNode
  isFetching?: boolean
  isLoading?: boolean
  titleOnly?: boolean
  stackProps?: StackProps
  subtitle?: ReactNode
  subtitleGridProps?: GridProps
  tooltip?: string
  /*
   * Slot for right aligned components *Must include a key*
   */
  Actions?: ReactNode
  Controls?: ReactNode
  Footer?: ReactNode
} & CardProps

export function Cask({
  title,
  children,
  isFetching: isFetchingProp,
  isLoading = false,
  stackProps = {},
  titleOnly = false,
  subtitle,
  subtitleGridProps = {},
  tooltip = '',
  Actions,
  Controls,
  Footer,
  ...rest
}: CaskProps) {
  const hasIsFetchingProp = isFetchingProp !== undefined
  const isFetching = isFetchingProp ?? false
  const cardProps = merge(rest, { elevation: 2, sx: { p: 2 } })

  // Evenly divide space on sm screen sizes based on content
  const numColumns = 12
  const numContentSlots = [title, Actions, Controls].filter(Boolean).length
  const columnSizes = {
    xs: numColumns,
    sm: numColumns / numContentSlots,
  }
  return (
    <Card {...cardProps}>
      <Stack spacing={2} {...stackProps}>
        <Grid
          container
          direction="row"
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Grid
            item
            {...subtitleGridProps}
            {...columnSizes}
            sx={{ width: '100%' }}
          >
            <Stack>
              <Stack direction="row" spacing={2}>
                {typeof title === 'string' ? (
                  <Typography variant="h5">{title}</Typography>
                ) : (
                  title
                )}

                {tooltip && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', minWidth: 24 }}
                  >
                    <Tooltip title={tooltip}>
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                )}

                {/* If no `isFetching` prop is provided we don't need to leave space for the spinner */}
                {hasIsFetchingProp && isFetching && (
                  <Box alignItems="center" display="flex" minWidth={24}>
                    <CircularProgress color="secondary" size={24} />
                  </Box>
                )}
              </Stack>
              {subtitle && (
                <Typography color="text.secondary" variant="caption">
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Grid>
          {Controls && !titleOnly && (
            <Grid item sx={{ width: '100%' }} {...columnSizes}>
              {Controls}
            </Grid>
          )}
          {Actions && !titleOnly && (
            <Grid item sx={{ width: '100%' }} {...columnSizes}>
              <Grid
                columnGap={2}
                container
                sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
              >
                {Actions}
              </Grid>
            </Grid>
          )}
        </Grid>
        <ErrorBoundary FallbackComponent={Fallback}>
          {isLoading ? <Skeleton /> : children}
          {Footer}
        </ErrorBoundary>
      </Stack>
    </Card>
  )
}
