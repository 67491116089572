import {
  FULL_CHARGE,
  HALF_CHARGE,
  ZERO_CHARGE,
} from '../../utils/hooks/useSocColor'
import { MapGradientLineLayer } from '@synop-react/common'
import { RootAPI } from '@synop-react/api'
import { useTheme } from '@mui/material'

export interface TripSocGradientLayerProps {
  sourceId: string
  telematics: RootAPI.AssetTelematics[]
}

const DEFAULT_MIN_DOMAIN = 0
const DEFAULT_MAX_DOMAIN = 100

export function TripSocGradientLayer({
  sourceId,
  telematics,
}: TripSocGradientLayerProps) {
  const { palette } = useTheme()

  const min = telematics.reduce((prev, curr) => {
    return prev.stateOfCharge &&
      curr.stateOfCharge &&
      prev.stateOfCharge < curr.stateOfCharge
      ? prev
      : curr
  })

  const max = telematics.reduce((prev, curr) => {
    return prev.stateOfCharge &&
      curr.stateOfCharge &&
      prev.stateOfCharge > curr.stateOfCharge
      ? prev
      : curr
  })

  const gradientDomain = [
    min.stateOfCharge || DEFAULT_MIN_DOMAIN,
    max.stateOfCharge || DEFAULT_MAX_DOMAIN,
  ]

  const gradientRange = [
    palette.error.main,
    palette.warning.main,
    palette.secondary.main,
  ]

  const gradientStops = [ZERO_CHARGE, HALF_CHARGE, FULL_CHARGE]

  return (
    <MapGradientLineLayer
      gradientDomain={gradientDomain}
      gradientRange={gradientRange}
      gradientStops={gradientStops}
      layerId="tripSocGradientLayer"
      sourceId={sourceId}
    />
  )
}

export default TripSocGradientLayer
