import { Control, FieldValues, Path } from 'react-hook-form'
import {
  formatTemperatureUnitsDesc,
  formatTimezone,
  FormField,
} from '../../index'
import {
  RootAPI,
  TimeZone,
  timezones,
  UserPreference,
  UserPreferenceOption,
  UserPreferenceType,
} from '@synop-react/api'

const { useGetDropdownOptionsQuery } = RootAPI.synopRootAPI

export type UserPreferenceAutocompleteSelectProps<
  FormData extends FieldValues
> = {
  type: UserPreferenceType
  id: Path<FormData>
  label: string
  control: Control<FormData, unknown>
}

export const UserPreferenceAutocompleteSelect = <FormData extends FieldValues>({
  type,
  id,
  label,
  control,
}: UserPreferenceAutocompleteSelectProps<FormData>) => {
  const { options, ...preferenceOptions } = FormField.useOptions<
    RootAPI.OptionValue,
    RootAPI.GetDropdownOptionsApiArg,
    RootAPI.GetDropdownOptionsApiResponse
  >({ useQuery: useGetDropdownOptionsQuery, queryArgs: undefined })

  const typeOptions = options?.filter((option) => option?.optionType === type)

  const timezoneOptions = timezones.reduce<Record<TimeZone, string>>(
    (acc, timezone) => {
      acc[timezone] = formatTimezone(timezone)
      return acc
    },
    {} as Record<TimeZone, string>
  )

  const getOptionLabel = (userOption: RootAPI.OptionValue) => {
    const optionLabelMap: Record<UserPreference, string | null> = {
      '12 Hour Clock (01:00 - 12:00)': null,
      '24 Hour Clock (00:00 - 23:59)': null,
      'YY/MM/DD': null,
      'MM/DD/YY': null,
      'DD/MM/YY': null,
      'Imperial (Feet / Miles)': null,
      'Metric (Meter / Kilometer)': null,
      'km/kWh': null,
      'kWh/km': null,
      'kWh/mi': null,
      'mi/kWh': null,
      celsius: formatTemperatureUnitsDesc('celsius'),
      fahrenheit: formatTemperatureUnitsDesc('fahrenheit'),
      ...timezoneOptions,
    }

    const { value } = userOption as UserPreferenceOption

    return optionLabelMap[value] || value
  }

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.OptionValue>
      disableClearable={true}
      keyExtractor={(option: RootAPI.OptionValue) => option.value || ''}
      options={typeOptions}
      {...preferenceOptions}
      {...{ getOptionLabel, id, control, label }}
    />
  )
}

export { UserPreferenceAutocompleteSelect as Select }
