import { Box, Grid, Stack, Typography } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'

export const MapLegend = ({ children }: PropsWithChildren) => {
  return (
    <Grid
      alignItems="flex-end"
      container
      justifyContent="center"
      sx={{ height: '100%' }}
    >
      <Grid
        container
        data-cy="mapLegend"
        item
        justifyContent="center"
        rowGap={0.5}
        xs="auto"
      >
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '8px',
            mb: 4,
            px: 1,
            py: 0.5,
            zIndex: 10000, // Make sure it's above the map markers
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

const MapLegendRow = ({ children }: PropsWithChildren) => {
  return (
    <Grid
      alignItems="center"
      columnGap={2}
      container
      item
      justifyContent="center"
    >
      {children}
    </Grid>
  )
}

type MapLegendItemProps = { legendKey: ReactNode; label: string }

const MapLegendItem = ({ legendKey, label }: MapLegendItemProps) => {
  return (
    <Grid item>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={1}
      >
        {legendKey}
        <Typography variant="caption">{label}</Typography>
      </Stack>
    </Grid>
  )
}

type MapLegendKeyProps = {
  color: string
}

const MapLegendKey = ({ color }: MapLegendKeyProps) => {
  return (
    <Box
      sx={{
        width: '10px',
        height: '10px',
        backgroundColor: color,
        borderRadius: 0.5,
      }}
    />
  )
}

MapLegend.Row = MapLegendRow
MapLegend.Item = MapLegendItem
MapLegend.Key = MapLegendKey
