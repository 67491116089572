import {
  formatCO2,
  formatDistance,
  formatEfficiency,
  formatEnergy,
  formatSoC,
  Table,
  TableCask,
  useFormat,
  useRouting,
  useTableCask,
} from '@synop-react/common'
import { FormProvider } from 'react-hook-form'
import { Link } from '@mui/material'
import { RootAPI, Trip } from '@synop-react/api'

const { useGetTripsQuery } = RootAPI.synopRootAPI
export interface CompletedTripsProps {
  vehicleId: string
}

export function CompletedTrips({ vehicleId }: CompletedTripsProps) {
  const { formatDateTime } = useFormat()
  const { routes } = useRouting()
  const { formMethods, from, to } = useTableCask()

  const { data: trips = [] } = useGetTripsQuery(
    {
      id: vehicleId,
      from: from?.toISOString(),
      to: to?.toISOString(),
    },
    { skip: !vehicleId || !from || !to }
  )

  const completedTrips = trips.filter(
    ({ tripStatus, id }) => id !== undefined && tripStatus === 'COMPLETED'
  ) as Trip[]

  const completedTripsColumns: Table.ColumnSpec<Trip> = [
    {
      field: 'id',
      headerName: 'Trip ID',
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Link
            href={routes.vehicles.trips.details(vehicleId, row.id)}
            underline="none"
          >
            {`Trip ${row.id.slice(-5)}`}
          </Link>
        )
      },
    },
    {
      field: 'destinationNm',
      headerName: 'Route',
      isDefaultVisible: false,
      width: 200,
    },
    {
      field: 'routeStart',
      headerName: 'Departure Time',
      valueFormatter: ({ value }) => formatDateTime(value).timeDotDate,
      width: 200,
      type: 'dateTime',
      valueGetter: Table.dateValueGetter,
    },
    {
      field: 'routeEnd',
      headerName: 'Arrival Time',
      valueFormatter: ({ value }) => formatDateTime(value).timeDotDate,
      width: 200,
      type: 'dateTime',
      valueGetter: Table.dateValueGetter,
    },
    {
      field: 'energyUsed',
      headerName: 'Energy Used',
      valueFormatter: (params) => formatEnergy(params.value),
      width: 200,
      type: 'number',
    },
    {
      field: 'distance',
      headerName: 'Distance',
      renderCell: ({ row }) => formatDistance(row.distance, row.distanceUnit),
      width: 200,
      type: 'number',
    },
    {
      field: 'emissionsSave',
      headerName: 'Carbon Saved',
      renderCell: ({ row }) => formatCO2(row.emissionsSave, row.emissionsUnit),
      width: 200,
      type: 'number',
    },
    {
      field: 'efficiency',
      headerName: 'Efficiency',
      renderCell: ({ row }) =>
        formatEfficiency(row.efficiency, row.efficiencyUnit),
      width: 200,
      type: 'number',
    },
    {
      field: 'startSoc',
      headerName: 'Start SOC',
      valueFormatter: (params) => formatSoC(params.value),
      width: 200,
      type: 'number',
    },
    {
      field: 'endSoc',
      headerName: 'End SOC',
      valueFormatter: (params) => formatSoC(params.value),
      width: 200,
      type: 'number',
    },
  ]

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        columns={completedTripsColumns}
        downloadable
        editableColumns
        getRowId={(row) => row.id}
        initialSortColumn="routeStart"
        initialSortOrder="desc"
        searchable
        showDateRange
        tableData={completedTrips}
        title="Completed Trips"
      />
    </FormProvider>
  )
}

export default CompletedTrips
