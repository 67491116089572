import * as yup from 'yup'
import {
  AlertCommunicationStep,
  AlertContextStep,
  AlertRuleStep,
} from '../AlertSteps'
import { AlertPropertyProvider } from '../AlertProperty'
import {
  AssetType,
  Priority,
  RootAPI,
  TimeUnits,
  useCurrentOrgId,
  useCurrentUser,
} from '@synop-react/api'
import {
  Box,
  Card,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { convertTime, useRouting } from '@synop-react/common'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

const {
  useGetAlertByAlertIdQuery,
  useCreateAlertByOrganizationsMutation,
  useUpdateAlertByOrganizationsMutation,
} = RootAPI

type AlertFormValues = {
  alertId: string
  alertName: string
  assetType: AssetType
  organizations: string[]
  sites: string[]
  assets: string[]
  priority: Priority
  lookbackPeriod: number | null
  message: string
  recipients: string[]
  rules: RootAPI.AlertUiRuleSet
}

const schema = yup
  .object({
    alertName: yup
      .string()
      .max(100, 'Alert name must be 100 characters or less')
      .required('Alert name is required'),
    assetType: yup.string().required(),
    organizations: yup.array().of(yup.string()).required(),
    sites: yup.array().of(yup.string()).required(),
    assets: yup.array().of(yup.string()).required(),
    priority: yup.string().required('Priority is required'),
    lookbackPeriod: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .min(0.001, 'Lookback period must be greater than 0')
      .max(30, 'Lookback period must be 30 days or less')
      .nullable()
      .optional(),
    message: yup
      .string()
      .max(500, 'Alert message must be 500 characters or less')
      .optional(),
    recipients: yup.array().of(yup.string()).required(),
    rules: yup.object().nullable().required(),
  })
  .required()

type AlertWizardProps = {
  alertId?: string
  onCancel: () => void
}

export const AlertWizard = ({ alertId, onCancel }: AlertWizardProps) => {
  const { navigate, routes } = useRouting()
  const { isAdmin } = useCurrentUser()
  const orgId = useCurrentOrgId()

  const [activeStep, setActiveStep] = useState(0)

  const { data: alert, isLoading } = useGetAlertByAlertIdQuery(
    { id: alertId ?? '' },
    { skip: !alertId || !isAdmin }
  )
  const [updateAlert] = useUpdateAlertByOrganizationsMutation()
  const [createAlert] = useCreateAlertByOrganizationsMutation()

  const defaultValues: AlertFormValues = useMemo(
    () => ({
      alertId: alert?.id || '',
      alertName: alert?.alertName || '',
      assetType: alert?.assetType || 'Charger',
      organizations: alert?.organizations || [],
      sites: alert?.sites || [],
      assets: alert?.assets || [],
      priority: alert?.priority || 'Low',
      lookbackPeriod: alert?.lookBackPeriod
        ? Math.max(
            Number(
              convertTime(
                alert.lookBackPeriod,
                TimeUnits.SECONDS,
                TimeUnits.DAYS
              ).toFixed(3)
            ),
            0.001
          )
        : null,
      message: alert?.notification?.message || '',
      recipients: alert?.notification?.recipients || [],
      rules: alert?.rules || { condition: 'and', rules: [] },
    }),
    [alert]
  )

  const methods = useForm<AlertFormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  })

  // Reset form when default values change (e.g. when alert is loaded)
  const { handleSubmit, reset } = methods
  useEffect(() => reset(defaultValues), [defaultValues, reset])

  const onSubmit = handleSubmit(
    ({ message, recipients, lookbackPeriod, alertId, ...rest }) => {
      const data = {
        notification: { message, recipients },
        lookBackPeriod: lookbackPeriod
          ? convertTime(lookbackPeriod, TimeUnits.DAYS, TimeUnits.SECONDS)
          : undefined,
        createdOrganizationId: orgId,
        ...rest,
      }

      if (alert) {
        updateAlert({
          alertConfigurationModel: {
            id: alertId || '',
            createdBy: alert.createdBy,
            createdOn: alert.createdOn,
            enabled: alert.enabled,
            ...data,
          },
        })
      } else {
        createAlert({ createAlertConfigurationModel: data })
      }

      // Return to the alerts settings page
      navigate(routes.settings.alerts)
    }
  )

  if (!isAdmin) {
    return (
      <Typography variant="body1">
        You do not have permission to view this page.
      </Typography>
    )
  } else if (isLoading) {
    return <Skeleton height="500px" />
  }

  const stepProps = {
    onCancel,
    onSubmit,
    setActiveStep,
    stepNumber: activeStep,
  }

  return (
    <AlertPropertyProvider>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="center">
            <Stepper activeStep={activeStep} sx={{ width: '40%' }}>
              <AlertStepIndicator name="Context" />
              <AlertStepIndicator name="Rule" />
              <AlertStepIndicator name="Communication" />
            </Stepper>
          </Stack>

          <FormProvider {...methods}>
            <Box paddingX={2}>
              {activeStep === 0 && <AlertContextStep {...stepProps} />}
              {activeStep === 1 && <AlertRuleStep {...stepProps} />}
              {activeStep === 2 && <AlertCommunicationStep {...stepProps} />}
            </Box>
          </FormProvider>
        </Stack>
      </Card>
    </AlertPropertyProvider>
  )
}

type AlertStepIndicatorProps = { name: string }
const AlertStepIndicator = ({ name, ...rest }: AlertStepIndicatorProps) => (
  // Pipe along props from the parent <Stepper> component
  <Step {...rest}>
    <StepLabel>
      <Typography variant="subtitle2">{name}</Typography>
    </StepLabel>
  </Step>
)
