import { Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'

import { Table, useFormat } from '@synop-react/common'

import { ChargerTableColDef, EntityProp } from '../common'

type LastHeardProps = EntityProp & { now: Dayjs }

function LastHeard({ now, entity }: LastHeardProps) {
  const { isCharger, lastHeard } = entity
  const { formatDateTime } = useFormat()
  if (!isCharger || !lastHeard) return null

  // Determine how long ago the charger was last heard from
  const date = dayjs(lastHeard)
  const diff = now.diff(date, 'minute')

  const color = (() => {
    if (diff > 60) return 'error.main'
    if (diff > 15) return 'warning.main'
    return 'text.disabled'
  })()

  return (
    <Typography color={color} variant="body2">
      {
        formatDateTime(lastHeard, {
          dropLeadingZero: true,
          invalidDateValue: '',
        }).timeDotDate
      }
    </Typography>
  )
}

export default (now: Dayjs) =>
  ({
    field: 'lastHeard',
    headerName: 'Last Heard',
    width: 160,
    renderCell: ({ row }) => <LastHeard entity={row} now={now} />,
    sortingOrder: ['desc', 'asc'],
    type: 'dateTime',
    valueGetter: Table.dateValueGetter,
  } as ChargerTableColDef<'lastHeard'>)
