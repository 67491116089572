import {
  emDash,
  formatEnergy,
  humanizeScheduledDuration,
  Icons,
  OverlayDeprecated,
  OverlayTitlePill,
} from '../../index'
import { freqLookupToDropdownMap } from '../../FormField'
import { ReactNode } from 'react'
import { RootAPI, useUserPrefs } from '@synop-react/api'
import { Stack, StackProps, Typography } from '@mui/material'
import { useFormat } from '../../utils/hooks'

const { useGetChargerFaultsQuery } = RootAPI.synopRootAPI

type VehicleChargingSessionOverlayProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  eventId?: string
  charger: RootAPI.ChargerModel
  vehicle?: RootAPI.VehicleModel
}

export function ViewChargingSessionOverlay({
  isOpen,
  setIsOpen,
  charger,
  eventId = '',
  vehicle,
}: VehicleChargingSessionOverlayProps) {
  const { tzDayjs } = useUserPrefs()
  const { formatDateTime } = useFormat()

  const { data: event } =
    RootAPI.synopRootAPI.useGetScheduledChargerSessionQuery(
      {
        eventId,
        chargerId: charger?.chargerId || '',
      },
      { skip: !charger.chargerId || !eventId }
    )
  const { data: eventDetails } =
    RootAPI.synopRootAPI.useGetScheduledChargerSessionDetailsQuery(
      {
        chargerId: charger.chargerId || '',
        eventId,
      },
      { skip: !charger.chargerId || !eventId }
    )

  const { sessions = [] } = (eventDetails ||
    {}) as RootAPI.ChargerEventSessionDetailsModel
  const { eventRecurrence } = event || {}

  const { startTimestamp, startSocValue } = sessions[0] || {}
  const { stopTimestamp, endSocValue } = sessions.slice(-1)[0] || {}

  const totalEnergy = sessions.reduce((acc, session = {}) => {
    const { kwhConsumed } = session || {} //Backend Bug returning null in session array
    return acc + Number(kwhConsumed)
  }, 0)

  const sessionDuration =
    startTimestamp && stopTimestamp
      ? humanizeScheduledDuration(startTimestamp, stopTimestamp, tzDayjs, {
          abbreviate: true,
        })
      : emDash

  return (
    <OverlayDeprecated
      fullWidth
      isOpen={isOpen}
      maxWidth="lg"
      setIsOpen={setIsOpen}
      title="Charging Session Details"
      TitleIcon={Icons.FileText}
      titlePill={
        eventRecurrence && (
          <OverlayTitlePill
            primaryText="Recurring Event"
            secondaryText={eventRecurrence.recurrenceName}
            TitlePillIcon={Icons.Repeat}
          />
        )
      }
    >
      <Stack spacing={3} sx={{ px: 2 }}>
        <Stack direction="row" spacing={2}>
          <ChargingSessionMainDetail
            label="TOTAL ENERGY DISPENSED"
            value={formatEnergy(totalEnergy)}
          />
          <ChargingSessionMainDetail
            label="TOTAL CHARGE TIME"
            value={`${sessionDuration}`}
          />
        </Stack>
        {eventRecurrence && (
          <Stack>
            <Typography color="secondary.main" variant="h6">
              {eventRecurrence.recurrenceType
                ? freqLookupToDropdownMap.get(eventRecurrence.recurrenceType)
                : emDash}
            </Typography>
            <Typography color="secondary.main" variant="caption">
              Frequency
            </Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: 'space-between' }}
        >
          <ChargerSessionDetails
            charger={charger}
            endTime={formatDateTime(stopTimestamp).time}
            startTime={formatDateTime(startTimestamp).time}
          />
          <VehicleSessionDetails
            endSoc={endSocValue}
            startSoc={startSocValue}
            vehicle={vehicle}
          />
          <SessionFaultDetails
            chargerId={charger.chargerId || ''}
            endTime={stopTimestamp}
            eventId={eventId}
            startTime={startTimestamp}
          />
        </Stack>
      </Stack>
    </OverlayDeprecated>
  )
}

export default ViewChargingSessionOverlay

type SessionDetail = {
  label: string
  value: string
}

const ChargingSessionMainDetail = ({ label, value }: SessionDetail) => {
  return (
    <Stack>
      <Typography color="secondary.main" variant="h3">
        {value}
      </Typography>
      <Typography color="secondary.main" variant="caption">
        {label}
      </Typography>
    </Stack>
  )
}

type ChargerSessionDetailSectionProps = {
  title: string
  subtitle: (string | ReactNode)[]
  details: SessionDetail[]
} & StackProps

const ChargingSessionDetailSection = ({
  title,
  subtitle,
  details,
  ...stackProps
}: ChargerSessionDetailSectionProps) => {
  const sessionDetails = details.map(({ label, value }) => (
    <Stack key={label} {...stackProps}>
      <Typography variant="subtitle2">{value}</Typography>
      <Typography color="text.secondary" variant="caption">
        {label}
      </Typography>
    </Stack>
  ))

  const formattedSubtitle = subtitle.filter(Boolean).join(' • ')

  return (
    <Stack sx={{ minWidth: '200px' }}>
      <Typography variant="h6">{title}</Typography>
      <Stack spacing={2}>
        <Typography color="text.secondary" sx={{ textTransform: 'capitalize' }}>
          {formattedSubtitle}
        </Typography>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          {sessionDetails}
        </Stack>
      </Stack>
    </Stack>
  )
}

type ChargerSessionDetailsProps = {
  charger: RootAPI.ChargerModel
  startTime: string
  endTime: string
}
const ChargerSessionDetails = ({
  charger,
  startTime,
  endTime,
}: ChargerSessionDetailsProps) => {
  return (
    <ChargingSessionDetailSection
      details={[
        { label: 'STARTING TIME', value: startTime },
        { label: 'ENDING TIME', value: endTime },
      ]}
      subtitle={[
        charger.chargerName,
        charger.chargePointVendor,
        charger.chargePointModel,
      ]}
      title="Charger"
    />
  )
}

type VehicleSessionDetailsProps = {
  vehicle?: RootAPI.VehicleModel
  startSoc?: number
  endSoc?: number
}
const VehicleSessionDetails = ({
  vehicle = {} as RootAPI.VehicleModel,
  startSoc,
  endSoc,
}: VehicleSessionDetailsProps) => {
  const { make, modelYear, model, vin } = vehicle

  const formattedSoc = (soc = -1) =>
    soc >= 0 && soc !== null ? `${soc}%` : emDash
  const formattedVin = vin ? `VIN: ${vin}` : ''

  return (
    <ChargingSessionDetailSection
      details={[
        { label: 'STARTING SOC', value: formattedSoc(startSoc) },
        { label: 'ENDING SOC', value: formattedSoc(endSoc) },
      ]}
      subtitle={[modelYear, make, model, formattedVin]}
      title="Vehicle"
    />
  )
}

type SessionFaultDetailsProps = {
  chargerId: string
  eventId: string
  startTime?: string
  endTime?: string
}

const SessionFaultDetails = ({
  chargerId,
  endTime = '',
  eventId,
  startTime = '',
}: SessionFaultDetailsProps) => {
  const { data: faults } = useGetChargerFaultsQuery(
    {
      chargerId,
      from: startTime,
      to: endTime,
      page: 1,
      count: 100,
    },
    {
      skip: !startTime || !endTime,
    }
  )
  const { connectorFaults = {} } = faults || {}
  const numFaults = Object.values(connectorFaults).length

  const pluralize = (unit: string) => (num: number) =>
    num >= 2 || num === 0 ? ` ${num} ${unit}s` : `${num} ${unit}`

  const pluralFaults = pluralize('Fault')(numFaults)

  return (
    <ChargingSessionDetailSection
      details={[{ label: 'SESSION ID', value: eventId }]}
      subtitle={[pluralFaults]}
      title="Faults"
    />
  )
}
