import { Dayjs } from 'dayjs'
import { Skeleton } from '@mui/material'
import { useMemo } from 'react'

import { Chart, useMemoizedDayjs } from '@synop-react/common'
import { RootAPI, useChargerDetailsFromPoll } from '@synop-react/api'
import { Tuple } from '@synop-react/types'

type ChargerUtilizationChartProps = {
  chargerId: string
  powerCeiling: number
  from: Dayjs
  to: Dayjs
  height: number
}

export const ChargerUtilizationChart = ({
  chargerId,
  powerCeiling,
  to: toProp,
  from: fromProp,
  height,
}: ChargerUtilizationChartProps) => {
  // Round `from` and `to` down to the nearest minute so it's not constantly triggering `useMemo`
  const from = useMemoizedDayjs(fromProp, (d) => d.startOf('minute'))
  const to = useMemoizedDayjs(toProp, (d) => d.startOf('minute'))

  const powerCeilingData = useMemo(
    () => [
      { x: from.toISOString(), y: powerCeiling },
      { x: to.toISOString(), y: powerCeiling },
    ],
    [powerCeiling, from, to]
  )

  const {
    getChargerUtilizationTimeline: {
      data: liveUtilization,
      isError,
      isLoading,
    },
  } = useChargerDetailsFromPoll({
    chargerId,
    from,
    to,
    pollingIntervalInSeconds: 5,
  })

  const [imports, exports] = usePowerData(from, liveUtilization)

  if (isLoading) {
    return <Skeleton height={height - 20} variant="rectangular" width="100%" />
  } else if (isError && !liveUtilization) {
    return <div>Unable to load data</div>
  }
  return (
    <Chart.Utilization
      {...{ from, to, height }}
      limitData={{ data: powerCeilingData, dataKey: 'Charger Limit' }}
      powerData={[
        { data: imports, dataKey: 'Power Import (Charge)' },
        { data: exports, dataKey: 'Power Export (Discharge)' },
        { data: [], dataKey: 'Forecast' },
      ]}
      yAxisLabel="Utilization (kW)"
    />
  )
}

function usePowerData(
  from: Dayjs,
  utilization?: RootAPI.PowerUtilizationModel
): Tuple<Chart.ApexUtilizationChartDatum[]> {
  const data = Chart.usePowerData(from, utilization)
  return useMemo(() => {
    const [chargeData, dischargeData] = data

    // The API always contains the end time, which usually extends the data series
    // in an awkward way if it's not truncated. If neither the last charge nor
    // discharge data point is non-zero, we can safely truncate them.
    const lastCharge = chargeData.at(-1)
    const lastDischarge = dischargeData.at(-1)
    if (!lastCharge || !lastDischarge) return [[], []]
    return lastCharge.y === 0 && lastDischarge.y === 0
      ? [chargeData.slice(0, -1), dischargeData.slice(0, -1)]
      : [chargeData, dischargeData]
  }, [data])
}
