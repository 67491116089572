import { useState } from 'react'

const libraries = ['places', 'geocoding']
const GOOGLE_MAPS_API_KEY = process.env['NX_GOOGLE_PLACES_API_TOKEN'] || ''
export const useGoogleLibraries = () => {
  const scriptId = `google-${libraries.join('-')}`
  const hasScript = !!document.querySelector('#' + scriptId)
  const [librariesLoaded, setLibrariesLoaded] = useState(hasScript)
  if (librariesLoaded) return true

  const allLibraries = libraries.join(',')
  const src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=${allLibraries}`

  // Create the script object
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', scriptId)
  script.src = src

  // Attach the script to the document head and set the state when loaded
  const head = document.querySelector('head')
  head?.appendChild(script)
  script.onload = () => setLibrariesLoaded(true)

  return librariesLoaded
}
