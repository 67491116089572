import { IconProps } from 'react-feather'
import { Icons } from '@synop-react/common'
import { Tooltip } from '@mui/material'
import { VehicleStatus, VehicleStatusSourceAttribute } from '@synop-react/api'

type VehicleStatusIconProps = {
  status: VehicleStatusSourceAttribute | null
} & IconProps

type TooltipIcon = {
  Icon: Icons.TIcon
  tooltip: string
}

export const VehicleStatusIcon = ({
  status,
  ...iconProps
}: VehicleStatusIconProps) => {
  const statusIconMap: Record<VehicleStatus, TooltipIcon> = {
    Charging: {
      Icon: Icons.Zap,
      tooltip: 'Vehicle Charging',
    },
    Off: { Icon: Icons.Slash, tooltip: 'Vehicle Off' },
    Idle: { Icon: Icons.Moon, tooltip: 'Vehicle Idle' },
    'In Motion': { Icon: Icons.Navigation, tooltip: 'Vehicle in Motion' },
  }

  const unknownStatusIcon = {
    Icon: Icons.AlertOctagon,
    tooltip: 'Unknown Vehicle Status',
  }

  const { Icon, tooltip } = status
    ? statusIconMap[status.value]
    : unknownStatusIcon

  return (
    <Tooltip title={tooltip}>
      <Icon {...iconProps} />
    </Tooltip>
  )
}
