import { Auth } from 'aws-amplify'
import { datadogRum } from '@datadog/browser-rum'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Clears the Redux cache and logs out the user from cognito
export const useUserLogout = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch({ type: 'USER_LOGOUT' })
    Auth.signOut()
    datadogRum.stopSessionReplayRecording()
  }, [dispatch])
}
