import { Autocomplete, Chip, TextField } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { InfoTooltip } from '../../Tooltip'
import { useEffect, useMemo } from 'react'
import { useSort } from '../../utils'
import CancelIcon from '@mui/icons-material/Cancel'

export type WrappedAutocompleteMultiselectOption = {
  label: string
  value: string
}

type WrappedAutocompleteMultiselectFormFieldProps = {
  id: string
  label: string
  options: WrappedAutocompleteMultiselectOption[]
  loading: boolean
  tooltip?: string
  freeSolo?: boolean
}

export const WrappedAutocompleteMultiselectFormField = ({
  id,
  label,
  options,
  loading,
  tooltip,
  freeSolo = false,
}: WrappedAutocompleteMultiselectFormFieldProps) => {
  const { control, setValue } = useFormContext()
  const sortedOptions = useSort(options, 'label')

  const value: string[] = useWatch({ control, name: id }) ?? []

  const { optionValues, optionsMap } = useMemo(() => {
    const optionsMap: Record<string, string> = {}
    const optionValues: string[] = []
    sortedOptions.forEach((option) => {
      optionsMap[option.value] = option.label
      optionValues.push(option.value)
    })
    return { optionValues, optionsMap }
  }, [sortedOptions])

  useEffect(() => {
    if (loading || freeSolo) return
    const newValues = value.filter((v: string) => optionValues.includes(v))
    if (newValues.length !== value.length) {
      setValue(id, newValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <Controller
      control={control}
      name={id}
      render={() => (
        <Autocomplete
          disabled={loading}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              (optionsMap[option] ?? option)
                ?.toLowerCase()
                .includes(inputValue.toLowerCase())
            )
          }
          freeSolo={freeSolo}
          fullWidth
          loading={loading}
          loadingText="Loading Options..."
          multiple
          onChange={(event, newValue) => setValue(id, newValue)}
          options={optionValues}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <InfoTooltip description={tooltip} />
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label={loading ? 'Loading...' : label}
              variant="standard"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {optionsMap[option] ?? option}
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={optionsMap[option] ?? option}
                {...getTagProps({ index })}
                deleteIcon={
                  <CancelIcon
                    sx={(theme) => ({
                      // This is the only way I got this color to actually change
                      color: `${theme.palette.primary.main} !important`,
                    })}
                  />
                }
                size="small"
                variant="outlined"
              />
            ))
          }
          value={!loading ? value : []}
        />
      )}
    />
  )
}
