import dayjs from 'dayjs'

import { ScheduledEvent } from '@synop-react/api'
import { Table } from '@synop-react/common'

const eventDateFactory = (hour: number, minute = 0, relativeDays = 0) =>
  dayjs()
    .add(relativeDays, 'day')
    .set('hour', hour)
    .set('minute', minute)
    .toISOString()

export const singleScheduledCharges: ScheduledEvent[] = [
  {
    id: '2ca0894381a5db560181bbe772010000',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(1, 0, -1),
    scheduledEnd: eventDateFactory(6, 30, -1),
    eventId: '1',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 64',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010001',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(10, 0, -1),
    scheduledEnd: eventDateFactory(12, 0, -1),
    eventId: '2',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 64',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010003',
    depotId: 'mockDepot1',

    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(11),
    scheduledEnd: eventDateFactory(16),
    eventId: '3',
    eventType: 'AD_HOC_CHARGE',
    eventTargetNm: 'Bus 64',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
]

export const multipleScheduledCharges: ScheduledEvent[] = [
  {
    id: '2ca0894381a5db560181bbe772010003',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(9, 0, -1),
    scheduledEnd: eventDateFactory(14, 30, -1),
    eventId: '4',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 2',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010004',
    depotId: 'mockDepot1',

    assetId: '2ca0857a81490d850181490eeeab0003',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(1, 0, -1),
    scheduledEnd: eventDateFactory(8, 45, -1),
    eventId: '5',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 3',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010005',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0002',
    chargerId: 'Charger 2',
    dispenserId: '4028d6f17cdc7b8e_3',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(17, 0, -1),
    scheduledEnd: eventDateFactory(21, 30, -1),
    eventId: '6',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 2',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010006',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0003',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_3',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(16, 45, -1),
    scheduledEnd: eventDateFactory(23, 0, -1),
    eventId: '7',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 3',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010007',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0004',
    chargerId: 'Charger 1',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(12, 0),
    scheduledEnd: eventDateFactory(15, 30),
    eventId: '8',
    eventType: 'TIMED_CHARGE',
    eventTargetNm: 'Bus 4',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
  {
    id: '2ca0894381a5db560181bbe772010087',
    depotId: 'mockDepot1',
    assetId: '2ca0857a81490d850181490eeeab0055',
    chargerId: 'Charger 3',
    dispenserId: '4028d6f17cdc7b8e_1',
    status: 'SCHEDULED',
    scheduledStart: eventDateFactory(13),
    scheduledEnd: eventDateFactory(13, 45),
    eventId: '9',
    eventType: 'AD_HOC_CHARGE',
    eventTargetNm: 'Bus 66',
    powerOffered: 25.0,
    targetSoc: 90.0,
    executionArn:
      'arn:aws:states:us-east-1:563141200221:execution:Executor-State-Machine-Gamma:8f324809-f24a-4619-b0e5-38a8f04b8336',
  },
]

export const sessionColumns: Table.ColumnSpec<any> = [
  { field: 'id', headerName: 'Session ID', width: 200 },
  { field: 'powerOffered', headerName: 'Power', width: 200 },
  {
    field: 'scheduledStart',
    headerName: 'Starting Time',
    width: 200,
    valueFormatter: () => 'Test time value ',
  },
  {
    field: 'scheduledEnd',
    headerName: 'Completion Time',
    width: 200,
    valueFormatter: () => 'Test time value ',
  },
]
