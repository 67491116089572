import {
  AdminFeature,
  CsvColumnSet,
  Edit3,
  emDash,
  getCSVFieldName,
  Table,
  TableCask,
  useFormat,
  useOverlay,
  useTableCask,
} from '@synop-react/common'
import {
  Button,
  Chip,
  ChipProps,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material'
import {
  Check,
  ErrorOutline,
  KeyboardArrowDown,
  VerifiedOutlined,
} from '@mui/icons-material'
import { CreateOrEditWorkplaceChargingPayorOverlay } from '../common/overlays'
import { CreatePayorFromExistingUsersOverlay } from '../common/overlays/CreatePayorFromExistingUsersOverlay'
import { FormProvider } from 'react-hook-form'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { RootAPI, useCurrentOrgId, useOrgCustomers } from '@synop-react/api'
import { useMemo, useState } from 'react'

const { useGetWorkplaceUsersQuery } = RootAPI

interface StatusObj {
  [key: string]: {
    title: string
    color: ChipProps['color']
    icon: JSX.Element
  }
}

const statusObj: StatusObj = {
  CURRENT: { title: 'current', color: 'success', icon: <Check /> },
  PAST_DUE: { title: 'past due', color: 'warning', icon: <ErrorOutline /> },
  PENDING: { title: 'pending', color: 'secondary', icon: <Check /> },
}

function getCSVColumns<T extends GridValidRowModel>(
  columns: Table.ColumnSpec<T>
): CsvColumnSet<T> {
  return columns.map(({ valueGetter, field, headerName }) => {
    const valueSetter = (() => {
      if (!valueGetter) return field as keyof T
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (row: any) => {
        const value = row[field]
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return valueGetter({ row, value } as any)
        } catch (e) {
          return value
        }
      }
    })()

    return [valueSetter, getCSVFieldName(headerName ?? '')]
  })
}

const WorkplaceChargingUsers = () => {
  const orgId = useCurrentOrgId()
  const { data } = useGetWorkplaceUsersQuery({ organizationId: orgId })
  const [selectedWorkplaceUser, setSelectedWorkplaceUser] = useState<
    string | null
  >(null)
  const { customers: organizations } = useOrgCustomers({
    includeParentOrg: true,
  })

  const createOrEditWorkplaceChargingPayorOverlay = useOverlay()
  const createPayorFromExistingUsersOverlay = useOverlay()
  const { formatDateTime } = useFormat()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const columns = useMemo<Table.ColumnSpec<RootAPI.WorkplaceChargingUserModel>>(
    () => [
      {
        flex: 0.15,
        minWidth: 160,
        field: 'payeeName',
        headerName: 'Payor Name',
        valueGetter: ({ row: { firstNm, lastNm } }) => `${firstNm} ${lastNm}`,
        renderCell: ({ value, row: { userId } }) =>
          userId ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              width="100%"
            >
              {value}
              <Tooltip
                placement="bottom"
                title="Payor is a verified Synop user."
              >
                <VerifiedOutlined color="primary" />
              </Tooltip>
            </Stack>
          ) : (
            value
          ),
      },
      {
        flex: 0.15,
        minWidth: 160,
        field: 'status',
        headerName: 'Status',
        renderCell: ({ value }) => {
          let status = statusObj[value]

          if (!status) {
            status = { title: 'unknown', color: 'secondary', icon: <Check /> }
          }

          return (
            <Chip
              color={status.color}
              deleteIcon={status.icon}
              label={status.title}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onDelete={() => {}}
              size="small"
              sx={{
                '.MuiChip-label': { textTransform: 'capitalize' },
                '.MuiChip-deleteIcon': { cursor: 'default' },
              }}
              variant="outlined"
            />
          )
        },
      },
      {
        flex: 0.125,
        field: 'email',
        minWidth: 120,
        headerName: 'Email',
      },
      {
        flex: 0.125,
        field: 'organization',
        minWidth: 80,
        headerName: 'Organization',
        valueGetter: ({ row: { organizationId } }) =>
          organizations?.[organizationId ?? '']?.organizationNm ?? emDash,
      },
      {
        flex: 0.125,
        field: 'onboarded',
        minWidth: 80,
        headerName: 'Onboarded Date',
        valueGetter: ({ row: { onboarded } }) => formatDateTime(onboarded).date,
      },
      {
        flex: 0.125,
        field: 'externalId',
        minWidth: 80,
        headerName: 'External ID',
      },
      {
        field: 'edit',
        disableColumnMenu: true,
        disableReorder: true,
        resizable: false,
        sortable: false,
        filterable: false,
        headerName: '',
        width: 100,
        renderCell: (params) => (
          <EditCell
            onClickEdit={() => {
              setSelectedWorkplaceUser(params.row.id ?? null)
              createOrEditWorkplaceChargingPayorOverlay.openOverlay()
            }}
          />
        ),
      },
    ],
    [createOrEditWorkplaceChargingPayorOverlay, formatDateTime, organizations]
  )

  const { formMethods } = useTableCask()
  const csvColumns = useMemo(() => getCSVColumns(columns), [columns])

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        columns={columns}
        csvColumns={csvColumns}
        downloadable
        downloadTitle="Workplace_Charging_Payors"
        getRowId={(row) => row.id ?? ''}
        initialState={{
          columns: {
            columnVisibilityModel: {
              externalId: false,
            },
          },
        }}
        rightOtherActions={
          <>
            <CreateOrEditWorkplaceChargingPayorOverlay
              onClose={() => setSelectedWorkplaceUser(null)}
              workplaceUserId={selectedWorkplaceUser}
              {...createOrEditWorkplaceChargingPayorOverlay}
            />
            <CreatePayorFromExistingUsersOverlay
              {...createPayorFromExistingUsersOverlay}
            />
            <AdminFeature>
              <>
                <Button
                  endIcon={<KeyboardArrowDown />}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  variant="outlined"
                >
                  ADD PAYOR
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  open={Boolean(anchorEl)}
                >
                  <MenuItem
                    onClick={createPayorFromExistingUsersOverlay.openOverlay}
                  >
                    Select from existing users
                  </MenuItem>
                  <MenuItem
                    onClick={
                      createOrEditWorkplaceChargingPayorOverlay.openOverlay
                    }
                  >
                    Add new payor
                  </MenuItem>
                </Menu>
              </>
            </AdminFeature>
          </>
        }
        searchable
        tableData={data ?? []}
      />
    </FormProvider>
  )
}

type EditCellProps = {
  onClickEdit: () => void
}

const EditCell = ({ onClickEdit }: EditCellProps) => {
  return (
    <IconButton onClick={onClickEdit}>
      <Edit3 />
    </IconButton>
  )
}

export default WorkplaceChargingUsers
