import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { UseQuery as UseQueryRtk } from '@reduxjs/toolkit/dist/query/react/buildHooks'

import { BaseQuery } from '../api/emptyRootAPI'
import { useWindowFocus } from '..'

const DEFAULT_POLLING_INTERVAL = 60

type UsePollingOptions = {
  pollingIntervalSeconds?: number
  skip?: boolean
}

type UseQuery<ApiArg, ApiResponse> = UseQueryRtk<
  QueryDefinition<ApiArg, BaseQuery, never, ApiResponse, 'api'>
>

/**
 * Hook used to poll an API endpoint while the user is focused on the page.
 */
export function usePolling<ApiArgs, ApiResponse>(
  useQuery: UseQuery<ApiArgs, ApiResponse>,
  args: ApiArgs,
  options: UsePollingOptions = {}
) {
  const { pollingIntervalSeconds = DEFAULT_POLLING_INTERVAL, ...rest } = options

  const isWindowFocused = useWindowFocus()
  const [pollingInterval, setPollingInterval] = useState(
    isWindowFocused ? pollingIntervalSeconds * 1000 : 0
  )

  // When the window loses focus, stop polling. When it regains focus, start polling again.
  useEffect(() => {
    setPollingInterval(isWindowFocused ? pollingIntervalSeconds * 1000 : 0)
  }, [isWindowFocused, pollingIntervalSeconds])

  return useQuery(args, { pollingInterval, ...rest })
}
