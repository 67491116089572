import { Button, styled } from '@mui/material'
import { Cask } from '../../Cask'
import { Download } from '../../Icons'
import { DownloadLink } from '../../DownloadLink'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { NoData } from '../../NoData'
import { Table } from '../../Table'
import { TableCask } from '../../TableCask'
import LoadingMessage from '../../LoadingMessage'

type DownloadExtension = `.${string}`
type DownloadOption = {
  blob: Blob
  extension: DownloadExtension
  filename: string
}

export type ReportTableCaskProps<T extends GridValidRowModel> = Omit<
  TableCask.ClientSideProps<T>,
  'downloadable'
> &
  Table.TableProps<T> & { downloadOptions?: DownloadOption }

export const ReportTableCask = <T extends GridValidRowModel>({
  downloadOptions,
  title = '',
  tableData,
  isLoading,
  ...props
}: ReportTableCaskProps<T>) => {
  const CaskActions =
    tableData.length && downloadOptions ? (
      <DownloadLink {...downloadOptions}>
        <Button startIcon={<DonwloadIcon />} variant="contained">
          Download Report
        </Button>
      </DownloadLink>
    ) : null

  const CaskBody = tableData.length ? (
    <Table.ClientSide density="compact" {...props} {...{ tableData }} />
  ) : (
    <NoData
      message="There are no matching rows for this report. Please adjust your parameters and try again."
      variant="container"
    />
  )

  const CaskContent = isLoading ? (
    <LoadingMessage height="40vh" loadingMessage="Generating Report" />
  ) : (
    CaskBody
  )

  return (
    <Cask Actions={CaskActions} title={title}>
      {CaskContent}
    </Cask>
  )
}

export default ReportTableCask

const DonwloadIcon = styled(Download)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))
