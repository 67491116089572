import { ApexOptions } from 'apexcharts'
import { Dayjs } from 'dayjs'
import { Stack, useTheme } from '@mui/material'
import { useUTCToLocalOffset } from '../../utils/date/useUTCToLocalOffset'
import Chart from 'react-apexcharts'

import { emDash } from '../../utils'
import { isNumber } from 'lodash'
import { RootAPI, useUserPrefs } from '@synop-react/api'
import { Tuple } from '@synop-react/types'
import { useCallback, useMemo } from 'react'

export type ApexUtilizationChartDatum = {
  x: string
  y: number
}

type SeriesData = {
  data: ApexUtilizationChartDatum[]
  dataKey: string
}

export type ApexUtilizationChartProps = {
  powerData: [SeriesData, SeriesData, SeriesData]
  annotations?: ApexAnnotations
  limitData?: SeriesData
  height: number
  from: Dayjs
  to: Dayjs
  xAxisNumTicks?: number
  yAxisLabel?: string
  yAxisNumTicks?: number
  showLegend?: boolean
}

export const UtilizationChart = ({
  powerData: [chargeSeries, dischargeSeries, forecastSeries],
  annotations = {},
  limitData,
  height,
  from,
  to,
  yAxisLabel,
  showLegend = true,
}: ApexUtilizationChartProps) => {
  const { palette } = useTheme()

  const { preferredTimeFormat } = useUserPrefs()
  const timeFormat =
    preferredTimeFormat === 'hh:mm' ? 'hh:mm tt' : preferredTimeFormat

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      type: 'line',
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    annotations,
    xaxis: {
      tooltip: {
        enabled: false,
      },
      type: 'datetime',
      labels: {
        format:
          Math.abs(from.diff(to, 'day')) > 1
            ? 'dd MMM yyyy ' + timeFormat
            : timeFormat,
      },
    },
    yaxis: {
      show: true,
      title: {
        text: yAxisLabel,
      },
      labels: {
        formatter: function (val) {
          return val?.toFixed(0) || emDash
        },
      },
    },
    stroke: {
      curve: 'stepline',
      width: [2, 2, 2, 2],
      dashArray: [0, 0, 0, 5],
    },
    fill: {
      opacity: [0.25, 0.25, 1],
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        bottom: 0,
        left: 30,
      },
    },
    legend: {
      show: showLegend,
      horizontalAlign: 'center',
    },
    tooltip: {
      y: {
        formatter: (val) => (isNumber(val) ? val.toFixed(2) + ' kW' : emDash),
      },
      x: {
        format: 'dd MMM yyyy ' + timeFormat,
        show: true,
      },
    },
  }

  const charge = useSeriesData(chargeSeries)
  const discharge = useSeriesData(dischargeSeries)
  const limit = useSeriesData(limitData)
  const forecast = useSeriesData(forecastSeries)

  const series: ApexAxisChartSeries = [
    {
      name: chargeSeries.dataKey,
      color: palette.charting.chart1.main,
      type: 'area',
      data: charge,
    },
    {
      name: dischargeSeries.dataKey,
      color: palette.success.main,
      type: 'area',
      data: discharge,
    },
  ]

  if (limitData && limit.length > 0) {
    series.push({
      name: limitData.dataKey,
      color: palette.error.main,
      type: 'line',
      data: limit,
    })
  }

  if (forecast.length > 0 && forecastSeries?.data?.length > 0) {
    series.push({
      name: forecastSeries.dataKey,
      color: palette.charting.chart5.main,
      type: 'line',
      data: forecast,
    })
  }

  return (
    <Stack>
      <Chart height={height} options={options} series={series} type="line" />
    </Stack>
  )
}

function useSeriesData(seriesData?: SeriesData) {
  const utcToLocalOffset = useUTCToLocalOffset()
  const { tzDayjs } = useUserPrefs()
  return (
    seriesData?.data.map((elem) => [
      utcToLocalOffset(tzDayjs(elem.x)),
      elem.y,
    ]) ?? []
  )
}

/** Parses a utilization API response into separate charge and discharge data series */
export function usePowerData(
  from: Dayjs,
  utilization?: RootAPI.PowerUtilizationModel
) {
  const { tzDayjs } = useUserPrefs()

  const transformPowerData = useCallback(
    (powerData: number[][] | undefined, ensurePositive: boolean) => {
      const transform = (power: number) =>
        ensurePositive ? Math.abs(power) : -Math.abs(power)

      const data = (powerData ?? []) as Tuple<number>[]
      return data.flatMap(([timestamp, power]) => {
        const time = tzDayjs(timestamp)
        if (time.isBefore(from)) return []
        return [{ x: time.toISOString(), y: transform(power) }]
      })
    },
    [from, tzDayjs]
  )

  return useMemo<Tuple<ApexUtilizationChartDatum[]>>(() => {
    return [
      transformPowerData(utilization?.activePowerImport, true),
      transformPowerData(utilization?.activePowerExport, false),
    ]
  }, [utilization, transformPowerData])
}
