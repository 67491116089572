import { ReactElement, ReactNode } from 'react'
import {
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material'

import { Center } from '../Center'

export type CaskDatumProps = {
  centered?: boolean
  label: string
  tooltip?: ReactNode
  value: ReactElement | string | number
}

/**
 * Simple two-line data presentation. Used in the "Charger Status" and "Site Status" casks.
 */
const CaskDatumComponent = ({
  centered = false,
  label,
  tooltip,
  value,
}: CaskDatumProps) => (
  <CaskDatumTooltip title={tooltip}>
    <Stack alignItems={centered ? 'center' : 'stretch'}>
      <Center.Vertical height={useTheme().typography.h6.lineHeight}>
        {typeof value === 'string' || typeof value === 'number' ? (
          <Typography fontWeight={600} variant="h6">
            {value}
          </Typography>
        ) : (
          value
        )}
      </Center.Vertical>
      <Typography variant="caption">{label}</Typography>
    </Stack>
  </CaskDatumTooltip>
)

type CaskDatumTooltipProps = Pick<TooltipProps, 'children' | 'title'>

const CaskDatumTooltip = (props: CaskDatumTooltipProps) => (
  <Tooltip
    // Position the tooltip left-aligned beneath the label, with half the default margin
    placement="bottom-start"
    slotProps={{ tooltip: { style: { marginTop: 4, marginLeft: -4 } } }}
    {...props}
  />
)

export const CaskDatum = Object.assign(CaskDatumComponent, {
  Tooltip: CaskDatumTooltip,
})
