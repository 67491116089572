import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import { ReactElement } from 'react'

export interface ImageTileProps {
  children?: ReactElement
  description: string
  imageUrl: string
  width?: string
}

export function ImageTile({
  description,
  imageUrl,
  children,
  width = '100%',
}: ImageTileProps) {
  return (
    <Card sx={{ width: width }}>
      <CardMedia
        component="img"
        height="100"
        image={imageUrl}
        sx={{ padding: '1em 1em 0 1em', objectFit: 'contain' }}
        width="200"
      />
      <CardContent>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions>{children}</CardActions>
    </Card>
  )
}

export default ImageTile
