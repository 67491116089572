import { ApexOptions } from 'apexcharts'
import { DayjsConstructor, emDash, Tile } from '@synop-react/common'
import { RootAPI, useUserPrefs, VehicleStateSummary } from '@synop-react/api'
import { useMemo } from 'react'
import { usePalette } from '@synop-react/theme'
import Chart from 'react-apexcharts'

const { useGetMetricsForVehicleQuery } = RootAPI

type PieChartDatum = {
  color: string
  text: string
  value?: number
}

type VehicleStatePieChartProps = {
  vehicleId: string
  from: DayjsConstructor
  to?: DayjsConstructor
}

export const VehicleStatePieChart = ({
  vehicleId,
  from,
}: VehicleStatePieChartProps) => {
  const { charting, palette } = usePalette()
  const { tzDayjs } = useUserPrefs()
  const currentDate = useMemo(
    () => tzDayjs(from).format('YYYY-MM-DD'),
    [from, tzDayjs]
  )

  const { data: dailyMetrics } = useGetMetricsForVehicleQuery({
    id: vehicleId,
    date: currentDate,
  })

  const { timeUtilization = {} } = dailyMetrics || {}

  const vehicleStateSegments: Record<
    VehicleStateSummary,
    Omit<PieChartDatum, 'value'>
  > = {
    totalDrivingTime: {
      text: 'Driving',
      color: palette.info.main,
    },
    totalIdleTime: {
      text: 'Idle / Off',
      color: charting[5].main,
    },
    totalChargingTime: {
      text: 'Charging',
      color: palette.success.main,
    },
  }

  const dailyStateData = timeUtilization
    ? Object.entries(timeUtilization).map<PieChartDatum>(
        ([vehicleState, { value }]) => ({
          ...vehicleStateSegments[vehicleState as VehicleStateSummary],
          value,
        })
      )
    : []

  const options: ApexOptions = {
    labels: dailyStateData.map((item) => item.text),
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val ? val.toFixed(1) + ' hours' : emDash
        },
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },
    colors: dailyStateData.map((item) => item.color),
    legend: {
      position: 'bottom',
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.8,
        },
      },
    },
    noData: {
      text: 'No Data Available',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  }

  const series = dailyStateData.map((item) => (item.value ? item.value : 0))

  return (
    <Tile title="Vehicle State">
      <Chart options={options} series={series} type="pie" />
    </Tile>
  )
}
