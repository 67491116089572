import * as yup from 'yup'
import { Button, Grid, InputAdornment, Typography } from '@mui/material'
import { FieldError, useForm, useWatch } from 'react-hook-form'
import { RootAPI, useCurrentOrgId } from '@synop-react/api'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import {
  AddressAutocomplete,
  ChargerAutocomplete,
  createOverlay,
  FormField,
  GoogleAutocomplete,
  isAutocompleteResponse,
  OrgAutocomplete,
  Overlay,
  useGoogleAPI,
  useOverlayContext,
  useSnackbarControls,
  VehicleAutocomplete,
} from '@synop-react/common'
import {
  EntityType,
  EntityTypeDropdown,
  EntityTypeOption,
} from '../EntityTypeDropdown'
import { isEmpty } from 'lodash'
import { LatLong } from '@synop-react/types'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useEffect, useState } from 'react'

const {
  useGetReimbursementUserQuery,
  useGetOrganizationQuery,
  useGetVehicleQuery,
  useGetChargerQuery,
  useSaveReimbursementUserMutation,
} = RootAPI

type CreateOrUpdateReimbursementUserForm = {
  charger: RootAPI.ChargerModel | null // why
  vehicle: RootAPI.VehicleModel | null // why
  entityType: EntityTypeOption
  organization: RootAPI.OrganizationModel
} & Pick<
  RootAPI.ReimbursementUserModel,
  | 'id'
  | 'firstNm'
  | 'lastNm'
  | 'email'
  | 'externalId'
  | 'address'
  | 'reimbursementRate'
  | 'reimbursementMetric'
  | 'reimbursementUnit'
  | 'entityId'
  | 'userId'
>

export type AddHomeChargingUserProps = {
  reimbursementConfigurationId: string | null
}

type Address = GoogleAutocomplete | string

const createHomeChargingUser = yup
  .object({
    firstNm: yup.string().required('First name is required'),
    lastNm: yup.string().required('Last name is required'),
    email: yup.string(),
    vehicle: yup.object().when('entityType', {
      is: ({ type }: EntityTypeOption) => type === 'VEHICLE',
      then: yup
        .object({
          id: yup.string().required('Vehicle is required'),
        })
        .typeError('Vehicle is required'),
      otherwise: yup.object({ id: yup.string().notRequired() }),
    }),
    charger: yup.object().when('entityType', {
      is: ({ type }: EntityTypeOption) => type === 'CHARGER',
      then: yup
        .object({
          chargerId: yup.string().required('Charger is required'),
        })
        .typeError('Charger is required'),
      otherwise: yup.object({ chargerId: yup.string().notRequired() }),
    }),
    reimbursementRate: yup
      .number()
      .required('Rate is required')
      .positive('Rate must be positive'),
    address: yup
      .mixed<Address>()
      .transform((val, original) => (original === '' ? null : val))
      .required('Home Address is required'),
    organization: yup.object().nullable().required('Organization is required'),
    externalId: yup.string(),
  })
  .required()

export const CreateOrEditHomeChargingUserOverlay =
  createOverlay<AddHomeChargingUserProps>(
    ({ reimbursementConfigurationId }) => {
      const curOrganizationId = useCurrentOrgId()
      const [location, setLocation] = useState<LatLong>()

      const { closeOverlay } = useOverlayContext()
      const { openSnackbar } = useSnackbarControls()

      const {
        data: reimbursementConfiguration,
        isLoading: isLoadingConfiguration,
      } = useGetReimbursementUserQuery(
        {
          reimbursementUserId: reimbursementConfigurationId ?? '',
        },
        {
          skip: !reimbursementConfigurationId,
        }
      )

      const orgId = useCurrentOrgId()

      const { data: userOrg } = useGetOrganizationQuery(
        { id: (reimbursementConfiguration?.organizationId ?? orgId) as string },
        { skip: !reimbursementConfiguration?.organizationId && !orgId }
      )

      const [configureReimbursementUser, configureReimbursementUserResponse] =
        useSaveReimbursementUserMutation()

      const titleCase = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      }

      const defaultEntityType: EntityType = 'CHARGER'
      const {
        control,
        formState: { errors, touchedFields },
        handleSubmit,
        reset,
      } = useForm<CreateOrUpdateReimbursementUserForm>({
        mode: 'all',
        defaultValues: {
          firstNm: '',
          lastNm: '',
          email: '',
          reimbursementRate: 0,
          externalId: '',
          address: '',
          entityType: {
            type: (reimbursementConfiguration?.entityType ??
              defaultEntityType) as EntityType,
            label: titleCase(
              reimbursementConfiguration?.entityType ?? defaultEntityType
            ),
          },
          entityId: '',
          vehicle: {},
          charger: {},
          organization: userOrg,
        },
        resolver: yupResolver(createHomeChargingUser),
      })

      const address = useWatch({
        control,
        name: 'address',
      })

      const selectedOrganization = useWatch({
        control,
        name: 'organization',
      })
      const organizationId = selectedOrganization?.id ?? curOrganizationId

      const { fetchGeoDetails, isLoading: isLoadingGeocoder } = useGoogleAPI()

      useEffect(() => {
        if (isAutocompleteResponse(address) && !isLoadingGeocoder) {
          fetchGeoDetails({ placeId: address.place_id }, ({ location }) =>
            setLocation(location)
          )
        }
      }, [address, fetchGeoDetails, isLoadingGeocoder])

      const entityType = useWatch({
        control,
        name: 'entityType',
      })

      const { data: vehicle } = useGetVehicleQuery(
        {
          id: reimbursementConfiguration?.entityId || '',
        },
        {
          skip:
            reimbursementConfiguration?.entityType !== 'VEHICLE' ||
            !reimbursementConfiguration.entityId,
        }
      )

      const { data: charger } = useGetChargerQuery(
        {
          chargerId: reimbursementConfiguration?.entityId || '',
        },
        {
          skip:
            reimbursementConfiguration?.entityType !== 'CHARGER' ||
            !reimbursementConfiguration.entityId,
        }
      )

      useEffect(() => {
        if (
          configureReimbursementUserResponse.status === QueryStatus.fulfilled
        ) {
          closeOverlay()
          openSnackbar('Reimbursement user has been configured.')
          reset()
        }
      }, [
        reset,
        configureReimbursementUserResponse,
        openSnackbar,
        closeOverlay,
      ])

      useEffect(() => {
        if (!isLoadingConfiguration) {
          const {
            id,
            firstNm,
            lastNm,
            email,
            externalId,
            address,
            reimbursementRate,
            reimbursementUnit,
            entityId,
            entityType,
            userId,
          } = reimbursementConfiguration || {}
          reset({
            id,
            firstNm,
            lastNm,
            email: email ?? '',
            externalId: externalId ?? '',
            address,
            reimbursementRate,
            reimbursementUnit,
            entityId,
            entityType: {
              type: (entityType ?? defaultEntityType) as EntityType,
              label: titleCase(entityType ?? defaultEntityType),
            },
            charger: charger ?? {},
            vehicle: vehicle ?? {},
            userId,
            organization: userOrg,
          })
        }
      }, [
        charger,
        vehicle,
        reset,
        reimbursementConfiguration,
        isLoadingConfiguration,
        userOrg,
      ])

      const [longitude, latitude] = location ?? []
      const onCreateSubmit = ({
        vehicle,
        charger,
        address,
        entityType: { type: selectedEntityType },
        organization: { id: organizationId },
        ...reimbursementFields
      }: CreateOrUpdateReimbursementUserForm) => {
        reimbursementFields.entityId =
          (selectedEntityType === 'VEHICLE'
            ? vehicle?.id
            : charger?.chargerId) ?? ''
        configureReimbursementUser({
          reimbursementUserModel: {
            reimbursementMetric: 'ENERGY_ADDED',
            reimbursementUnit: 'KWH',
            entityType: selectedEntityType,
            organizationId,
            address: isAutocompleteResponse(address)
              ? address.description
              : address,
            longitude,
            latitude,
            ...reimbursementFields,
          },
        })
      }

      const onUpdateSubmit = ({
        vehicle,
        charger,
        address,
        entityType: { type: selectedEntityType },
        organization: { id: organizationId },
        ...reimbursementFields
      }: CreateOrUpdateReimbursementUserForm) => {
        reimbursementFields.entityId =
          (selectedEntityType === 'VEHICLE'
            ? vehicle?.id
            : charger?.chargerId) ?? ''
        configureReimbursementUser({
          reimbursementUserModel: {
            id: reimbursementConfiguration?.id,
            reimbursementMetric: 'ENERGY_ADDED',
            reimbursementUnit: 'KWH',
            entityType: selectedEntityType,
            organizationId,
            latitude,
            longitude,
            address: isAutocompleteResponse(address)
              ? address.description
              : address,
            ...reimbursementFields,
          },
        })
      }

      const updating = reimbursementConfiguration?.id !== undefined
      const onSubmit = handleSubmit(updating ? onUpdateSubmit : onCreateSubmit)

      useEffect(() => {
        if (entityType.type === 'CHARGER') {
          delete errors.vehicle
        } else if (entityType.type === 'VEHICLE') {
          delete errors.charger
        }
      }, [errors, entityType])

      return (
        <Overlay>
          <Overlay.Header
            title={
              updating ? 'Edit Home Charging Payee' : 'Add Home Charging Payee'
            }
          />

          <Overlay.Content>
            <form onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">1. Payee Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.firstNm}
                    fullWidth
                    id="firstNm"
                    label="First Name"
                    touched={Boolean(touchedFields.firstNm)}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.lastNm}
                    fullWidth
                    id="lastNm"
                    label="Last Name"
                    touched={Boolean(touchedFields.lastNm)}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressAutocomplete.Select
                    control={control}
                    error={errors.address}
                    id="address"
                    label="Home Address"
                    touchedField={Boolean(touchedFields.address)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField.TextFormField
                    control={control}
                    fullWidth
                    id="email"
                    label="Email"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField.TextFormField
                    control={control}
                    fullWidth
                    id="externalId"
                    label="External ID"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <OrgAutocomplete.Select
                    control={control}
                    error={errors.organization as FieldError}
                    id="organization"
                    label="Organization"
                    orgId={curOrganizationId}
                    touchedField={Boolean(touchedFields.organization)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    2. Rate Information
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormField.TextFormField
                    control={control}
                    error={errors.reimbursementRate}
                    fullWidth
                    id="reimbursementRate"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">/ kWh</InputAdornment>
                      ),
                    }}
                    label="Rate"
                    touched={Boolean(touchedFields.reimbursementRate)}
                    type="number"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">3. Attach Asset</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <EntityTypeDropdown control={control} id="entityType" />
                </Grid>

                {entityType.type === 'CHARGER' && (
                  <Grid item sm={6} xs={12}>
                    <ChargerAutocomplete.Select
                      control={control}
                      error={errors.charger as FieldError}
                      id="charger"
                      orgId={organizationId}
                      touchedField={Boolean(touchedFields.charger)}
                    />
                  </Grid>
                )}
                {entityType.type === 'VEHICLE' && (
                  <Grid item sm={6} xs={12}>
                    <VehicleAutocomplete.Select
                      control={control}
                      error={errors.vehicle as FieldError}
                      fleetId={organizationId}
                      id="vehicle"
                      touchedField={Boolean(touchedFields.vehicle)}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </Overlay.Content>

          <Overlay.Actions>
            <Button
              color="primary"
              disabled={!isEmpty(errors)}
              onClick={onSubmit}
              variant="contained"
            >
              {updating ? 'Save Changes' : 'Add Payee'}
            </Button>
            <Button onClick={closeOverlay} variant="outlined">
              Cancel
            </Button>
          </Overlay.Actions>
        </Overlay>
      )
    }
  )
