import * as yup from 'yup'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useState } from 'react'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
  FormField,
} from '../../index'

export type DepotEventTypeOption = { id: number | string; name: string }
export type DepotEventTypeAutocompleteSelectProps = {
  label: string
} & AutocompleteTextFieldProps &
  Omit<AutocompleteFormControls<FieldValues, DepotEventTypeOption>, 'control'>

export const DepotEventTypeAutocompleteSelect = (
  props: DepotEventTypeAutocompleteSelectProps
) => {
  const [isOpen, setIsOpen] = useState(false)
  const depotEventTypeOptions: DepotEventTypeOption[] = [
    { id: 1, name: 'Max Power Import' },
    { id: 2, name: 'Max Power Export' },
  ]

  return (
    <FormField.AutocompleteSelect
      control={useFormContext().control}
      defaultValue={props.defaultValue}
      getOptionLabel={(option: DepotEventTypeOption) => `${option.name}`}
      isLoading={false}
      isOpen={isOpen}
      keyExtractor={(option: DepotEventTypeOption) => option.id}
      options={depotEventTypeOptions}
      setIsOpen={setIsOpen}
      {...props}
    />
  )
}

const depotEventTypeSelectSchema = yup
  .object({ name: yup.string().required() })
  .nullable()
  .required('Event Type is required')

export {
  DepotEventTypeAutocompleteSelect as Select,
  depotEventTypeSelectSchema as Schema,
}
