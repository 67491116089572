import { ReactElement } from 'react'
import {
  RootAPI,
  useChargerDetailsFromPoll,
  useDepotDetailsFromPoll,
} from '@synop-react/api'
import ConfigureChargerOverlay, {
  ConfigureChargerFormData,
} from '../ConfigureChargerOverlay'

const { useUpdateChargerMutation } = RootAPI.synopRootAPI

export type EditChargerOverlayProps = {
  Trigger: ReactElement
  chargerId: string
}

const EditChargerOverlay = ({
  Trigger,
  chargerId,
}: EditChargerOverlayProps) => {
  const {
    getCharger: { data: charger },
  } = useChargerDetailsFromPoll({
    chargerId,
    disablePolling: true,
  })

  const {
    getDepot: { data: chargerDepot, isLoading: isLoadingChargerDepot },
  } = useDepotDetailsFromPoll({
    depotId: charger?.depotId || '',
  })

  const isAdaBoolean = charger?.isAdaAccessible ? 'Yes' : 'No'
  const defaultCharger: ConfigureChargerFormData = {
    depot: chargerDepot || null,
    manufacturer: charger?.chargerManufacturerModel || null,
    makeModel: charger?.chargerMakeModel || null,
    chargerName: charger?.chargerName || '',
    chargerId: chargerId || '',
    maxPower: charger?.maxPower,
    maxCurrent: charger?.maxCurrent,
    voltage: charger?.voltage,
    autoRegisterTags: charger?.autoRegisterTags || false,
    networkConnectionMethod: charger?.networkConnectionMethod || null,
    networkConnectionDetails: charger?.networkConnectionDetails,
    externalId: charger?.externalId,
    utilityLocationId: charger?.utilityLocationId,
    commissioningDate: charger?.commissioningDate,
    evseInstallationType: charger?.evseInstallationType || null,
    isAdaAccessible: charger?.isAdaAccessible !== null ? isAdaBoolean : null,
    latitude: charger?.latitude,
    longitude: charger?.longitude,
  }

  Object.keys(charger?.connectors || {}).forEach((connectorId) => {
    defaultCharger[`connector${connectorId}`] =
      charger?.connectors[connectorId]?.connectorType ?? null
  })

  const mutation = useUpdateChargerMutation()
  return !isLoadingChargerDepot ? (
    <ConfigureChargerOverlay
      editingExisting={true}
      editMutation={mutation}
      formDefaults={defaultCharger}
      showConnectionStatus
      Trigger={Trigger}
    />
  ) : null
}

export default EditChargerOverlay
