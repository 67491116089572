import { Box, Tooltip, Typography } from '@mui/material'
import { InfoOutlined, WarningAmberOutlined } from '@mui/icons-material'

import {
  CellWithIcon,
  ChargerTableColDef,
  EntityProp,
  HeaderWithMargin,
} from '../common'
import {
  ChargerOrConnectorStatuses,
  getStatusOrder,
  StatusChip,
  useStatusColorMaps,
} from '@synop-react/common'

// The width of the space reserved for the status icon to the left of the chip.
// This is not actually the width of the icon itself.
const ICON_WIDTH = 56

function StatusColumn({ entity }: EntityProp) {
  const { currentStatus, isCharger, lastKnownStatus } = entity
  const statusColors = useStatusColorMaps()
  const color = isCharger
    ? statusColors.charger[currentStatus]
    : statusColors.connector[currentStatus]

  const Icon = (() => {
    if (currentStatus === 'Unavailable') return InfoOutlined
    else if (currentStatus === 'Faulted') return WarningAmberOutlined
    else return null
  })()

  return (
    <CellWithIcon icon={Icon && <Icon sx={{ color }} />} width={ICON_WIDTH}>
      <Tooltip
        placement="right"
        title={
          currentStatus === 'Offline' && (
            <TooltipText lastKnownStatus={lastKnownStatus} />
          )
        }
      >
        {isCharger ? (
          <StatusChip.Charger status={currentStatus} variant="outlined" />
        ) : (
          <StatusChip.Connector status={currentStatus} variant="outlined" />
        )}
      </Tooltip>
    </CellWithIcon>
  )
}

type TooltipTextProps = { lastKnownStatus: string }

function TooltipText({ lastKnownStatus }: TooltipTextProps) {
  return (
    <Box>
      <Typography variant="subtitle2">Charger Offline</Typography>
      <Typography sx={{ mr: 2 }} variant="caption">
        Last Known Status:
      </Typography>
      <Typography variant="caption">{lastKnownStatus}</Typography>
    </Box>
  )
}

export default {
  field: 'currentStatus',
  headerName: 'Status',
  sortComparator: (s1, s2) => getStatusOrder(s1) - getStatusOrder(s2),
  sortingOrder: ['desc', 'asc'],
  width: 140 + ICON_WIDTH,
  renderCell: ({ row }) => <StatusColumn entity={row} />,
  renderHeader: () => <HeaderWithMargin colName="Status" margin={ICON_WIDTH} />,
  type: 'singleSelect',
  valueOptions: ChargerOrConnectorStatuses,
} as ChargerTableColDef<'currentStatus'>
