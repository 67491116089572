import { BillingCycleOverlay } from '../common/overlays'
import { createOverlay } from '@synop-react/common'
import { RootAPI, useCurrentOrgId, useCurrentUser } from '@synop-react/api'
import { useCallback } from 'react'

const {
  useSaveReimbursementConfigurationMutation,
  useGetReimbursementConfigurationQuery,
} = RootAPI

export const HomeChargingBillingCycleOverlay = createOverlay(() => {
  const { synopUser } = useCurrentUser()

  const orgId = useCurrentOrgId()
  const { data: reimbursementConfiguration } =
    useGetReimbursementConfigurationQuery(
      {
        organizationId: orgId,
      },
      { skip: !orgId }
    )

  const [configureReimbursement, configureReimbursementResponse] =
    useSaveReimbursementConfigurationMutation()

  const setupBillingCycle = useCallback(
    (anchorDay: number | undefined) => {
      configureReimbursement({
        reimbursementConfiguration: {
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          createdBy: synopUser?.id as string,
          modifiedBy: synopUser?.id as string,
          organizationId: orgId as string,
          billingAnchor: anchorDay,
          billingCycle: 'monthly',
          administeredBy: synopUser?.organizationId ?? '',
        },
      })
    },
    [configureReimbursement, synopUser?.id, synopUser?.organizationId, orgId]
  )

  return (
    <BillingCycleOverlay
      apiResponseStatus={configureReimbursementResponse.status}
      configuration={reimbursementConfiguration}
      setupBillingCycle={setupBillingCycle}
    />
  )
})
