import {
  AdminFeature,
  CsvColumnSet,
  Edit3,
  emDash,
  getCSVFieldName,
  Table,
  TableCask,
  useOverlay,
  useTableCask,
} from '@synop-react/common'
import {
  BulkEditReimbursementRateOverlay,
  CreateOrEditHomeChargingUserOverlay,
} from '../common/overlays'
import { Button, Link, Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'
import {
  RootAPI,
  useCurrentOrgId,
  useOrgChargers,
  useOrgCustomers,
  useOrgVehicles,
} from '@synop-react/api'
import { useMemo, useState } from 'react'

const { useGetReimbursementUsersQuery } = RootAPI

function getCSVColumns<T extends GridValidRowModel>(
  columns: Table.ColumnSpec<T>
): CsvColumnSet<T> {
  return columns.map(({ valueGetter, field, headerName }) => {
    const valueSetter = (() => {
      if (!valueGetter) return field as keyof T
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (row: any) => {
        const value = row[field]
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return valueGetter({ row, value } as any)
        } catch (e) {
          return value
        }
      }
    })()

    return [valueSetter, getCSVFieldName(headerName ?? '')]
  })
}

export const titleCase = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : ''

const HomeChargingUsers = () => {
  const [isApplyUpdateOpen, setApplyUpdateOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<GridRowId[]>([])

  const orgId = useCurrentOrgId()
  const { data } = useGetReimbursementUsersQuery(
    {
      organizationId: orgId,
    },
    { skip: !orgId }
  )
  const [
    selectedReimbursementConfiguration,
    setSelectedReimbursementConfiguration,
  ] = useState<string | null>(null)
  const { customers: organizations } = useOrgCustomers({
    includeParentOrg: true,
  })
  const { orgChargers } = useOrgChargers()
  const { orgVehicles } = useOrgVehicles()

  const createOrEditHomeChargingUserOverlay = useOverlay()

  const columns = useMemo<Table.ColumnSpec<RootAPI.ReimbursementUserModel>>(
    () => [
      {
        flex: 0.15,
        minWidth: 160,
        field: 'payeeName',
        headerName: 'Payee Name',
        valueGetter: ({ row: { firstNm, lastNm } }) => `${firstNm} ${lastNm}`,
      },
      {
        flex: 0.125,
        field: 'email',
        minWidth: 120,
        headerName: 'Payee Email',
      },
      {
        flex: 0.125,
        field: 'reimbursementRate',
        minWidth: 50,
        headerName: 'Rate',
        valueGetter: ({ value }) => `$${value} kWh`,
      },
      {
        flex: 0.125,
        field: 'organization',
        minWidth: 80,
        headerName: 'Organization',
        valueGetter: ({ row: { organizationId } }) =>
          organizations[organizationId]?.organizationNm ?? emDash,
      },
      {
        flex: 0.125,
        field: 'entityType',
        minWidth: 80,
        headerName: 'Asset Type',
        valueFormatter: ({ value }) => titleCase(value),
        tooltip:
          'This value will be the type of asset that was onboarded. Either Charger or Vehicle',
      },
      {
        flex: 0.15,
        field: 'entityId',
        minWidth: 120,
        headerName: 'Asset Name',
        valueGetter: ({ row: { entityId, entityType } }) =>
          (entityType === 'CHARGER'
            ? orgChargers[entityId]?.chargerName
            : orgVehicles[entityId]?.vehicleNm) ?? '',
        renderCell: ({ row: { entityId, entityType }, value }) => {
          const link =
            (entityType === 'CHARGER' ? '/chargers/' : '/vehicles/') + entityId

          return (
            <Typography variant="body2">
              {entityId ? (
                <Link href={link} underline="none">
                  {value}
                </Link>
              ) : (
                emDash
              )}
            </Typography>
          )
        },
        tooltip:
          'This value will be the name entered as either Charger Name or Vehicle Name',
      },
      {
        field: 'edit',
        disableColumnMenu: true,
        disableReorder: true,
        resizable: false,
        sortable: false,
        filterable: false,
        headerName: '',
        width: 100,
        renderCell: (params) => (
          <EditCell
            onClickEdit={() => {
              setSelectedReimbursementConfiguration(params.row.id ?? null)
              createOrEditHomeChargingUserOverlay.openOverlay()
            }}
          />
        ),
      },
    ],
    [
      createOrEditHomeChargingUserOverlay,
      orgChargers,
      orgVehicles,
      organizations,
    ]
  )

  const { formMethods } = useTableCask()
  const csvColumns = useMemo(() => getCSVColumns(columns), [columns])

  return (
    <FormProvider {...formMethods}>
      <TableCask.ClientSide
        checkboxSelection
        columns={columns}
        csvColumns={csvColumns}
        downloadable
        downloadTitle="Home_Charging_Payees"
        getRowId={(row) => row.id ?? ''}
        onRowSelectionModelChange={setSelectedUsers}
        rightOtherActions={
          <>
            {isApplyUpdateOpen ? (
              <BulkEditReimbursementRateOverlay
                isOpen
                selectedUsers={selectedUsers as string[]}
                setIsOpen={setApplyUpdateOpen}
              />
            ) : null}
            <Button
              disabled={selectedUsers.length === 0}
              onClick={() => setApplyUpdateOpen(true)}
              variant="outlined"
            >
              Adjust Rate
            </Button>
            <CreateOrEditHomeChargingUserOverlay
              onClose={() => setSelectedReimbursementConfiguration(null)}
              reimbursementConfigurationId={selectedReimbursementConfiguration}
              {...createOrEditHomeChargingUserOverlay}
            />
            <AdminFeature>
              <Button
                onClick={createOrEditHomeChargingUserOverlay.openOverlay}
                variant="contained"
              >
                Add Payee
              </Button>
            </AdminFeature>
          </>
        }
        searchable
        tableData={data ?? []}
      />
    </FormProvider>
  )
}

export const VehicleCell = ({ vehicleId }: { vehicleId: string }) => {
  const { orgVehicles } = useOrgVehicles()

  const vehicleFound = useMemo(
    () =>
      Object.values(orgVehicles).find((vehicle) => vehicle?.id === vehicleId),
    [vehicleId, orgVehicles]
  )

  if (vehicleFound && orgVehicles) {
    return <Typography variant="body2">{vehicleFound.vehicleNm}</Typography>
  }
  if (orgVehicles) {
    return <Typography variant="body2">{emDash}</Typography>
  }
  return <Typography variant="body2">Loading</Typography>
}

export const ChargerCell = ({ chargerId }: { chargerId: string }) => {
  const { orgChargers } = useOrgChargers()

  const chargerFound = useMemo(
    () =>
      Object.values(orgChargers).find(
        (charger) => charger?.chargerId === chargerId
      ),
    [chargerId, orgChargers]
  )

  if (chargerFound && orgChargers) {
    return <Typography variant="body2">{chargerFound.chargerName}</Typography>
  }
  if (orgChargers) {
    return <Typography variant="body2">{emDash}</Typography>
  }
  return <Typography variant="body2">Loading</Typography>
}

type EditCellProps = {
  onClickEdit: () => void
}

const EditCell = ({ onClickEdit }: EditCellProps) => {
  return (
    <Button onClick={onClickEdit}>
      <Edit3 style={{ width: '22px', height: '22px' }} />
    </Button>
  )
}

export { getCSVColumns }
export default HomeChargingUsers
