export const depots = [
  {
    depotId: '1657633797df484a9af9ef6ecfab0f4d',
    fleetId: '2ca0ae187e8d1f29017eb58c23610000',
    depotNm: 'Denver Site',
    externalId: '9010',
    powerCeiling: 100.0,
    powerCeilingUnit: 'kW',
    currentCeiling: 200.0,
    currentCeilingUnit: 'A',
    voltageCeiling: 380.0,
    voltageCeilingUnits: 'V',
    latitude: 33.676381,
    longitude: -117.655494,
    location: null,
    v2gEnrolled: null,
    geofence:
      '[[-117.65579331264583,33.67670958623117],[-117.65600228018289,33.67621107569471],[-117.65530572172605,33.67604876931502],[-117.65499923600501,33.6764777212249],[-117.65579331264583,33.67670958623117]]',
    numChargers: 3,
    numVehicles: null,
    activeChargers: 0,
    connectedChargers: 0,
    rateCardId: null,
    rateCardTierType: null,
    created: '2022-04-26T20:20:19.897Z',
    updated: '2022-07-13T15:27:43.108Z',
    isLoadBalanced: false,
    timezone: 'America/Los_Angeles',
  },
  {
    depotId: '80afae31252442fd84a65421233b0133',
    fleetId: '8ac5611d7c2d6a07017c2d6a2e1c0000',
    depotNm: 'EWR-EV1',
    externalId: null,
    powerCeiling: 100.0,
    powerCeilingUnit: 'kW',
    currentCeiling: null,
    currentCeilingUnit: 'A',
    voltageCeiling: null,
    voltageCeilingUnits: 'V',
    latitude: 40.728811,
    longitude: -74.035298,
    location: null,
    v2gEnrolled: null,
    geofence: null,
    numChargers: 3,
    numVehicles: null,
    activeChargers: 8,
    connectedChargers: 3,
    rateCardId: null,
    rateCardTierType: null,
    created: '2022-03-01T00:00:00.000Z',
    updated: '2022-11-23T19:54:56.542Z',
    isLoadBalanced: false,
    timezone: 'America/New_York',
  },
]
