import { TimeZone } from '@synop-react/api'
import dayjs, { Dayjs } from 'dayjs'

export const hourlyResolutions = [
  'ONE_HOUR',
  'SIX_HOURS',
  'TWELVE_HOURS',
  'TWENTY_FOUR_HOURS',
  'SEVENTY_TWO_HOURS',
] as const
export type HourlyResolution = (typeof hourlyResolutions)[number]
export const dailyResolutions = ['TODAY', 'YESTERDAY', 'TWO_DAYS_AGO'] as const
export type DailyResolution = (typeof dailyResolutions)[number]
export const weeklyResolutions = ['THIS_WEEK', 'LAST_WEEK'] as const
export type WeeklyResolution = (typeof weeklyResolutions)[number]
export const monthlyResolutions = ['THIS_MONTH', 'LAST_MONTH'] as const
export type MonthlyResolution = (typeof monthlyResolutions)[number]
export const pastTimeResolutions = ['DAY', 'WEEK', 'MONTH'] as const
export type PastTimeResolution = (typeof pastTimeResolutions)[number]
export const currentTimeResolutions = [
  'DAY',
  'WEEK',
  'MONTH',
  'ONE_HOUR',
  'SIX_HOURS',
  'TWELVE_HOURS',
  'TWENTY_FOUR_HOURS',
  'SEVENTY_TWO_HOURS',
] as const
export type CurrentTimeResolution = (typeof currentTimeResolutions)[number]

const isPresentResolution = (
  resolution: RelativeTimeResolution
): resolution is CurrentTimeResolution =>
  (currentTimeResolutions as unknown as RelativeTimeResolution[]).includes(
    resolution
  )

export const timeResolutions = [
  ...hourlyResolutions,
  ...dailyResolutions,
  ...weeklyResolutions,
  ...monthlyResolutions,
  ...pastTimeResolutions,
]
export type RelativeTimeResolution = (typeof timeResolutions)[number] | 'CUSTOM'

export type GetTimeRangeOptions = {
  /**
   * Flag for whether to end current time resolution range at current time or end of the relative resolution
   * */
  useEndOfCurrentDay?: boolean
  timezone?: TimeZone
}

export const defaultRangeOptions: GetTimeRangeOptions = {
  useEndOfCurrentDay: false,
}

type DefinedTimeResolution = Exclude<RelativeTimeResolution, 'CUSTOM'>

export const getTimeRange = (
  resolution: DefinedTimeResolution,
  options = defaultRangeOptions
): [Dayjs, Dayjs] => {
  const { useEndOfCurrentDay, timezone } = {
    ...defaultRangeOptions,
    ...options,
  }
  const currentTime = dayjs().tz(timezone)
  const { relativeTime, relativeTimeUnits } = timeResolutionMap[resolution]

  const baseRangeStart = currentTime.add(relativeTime, relativeTimeUnits)
  const rangeStart = isPresentResolution(resolution)
    ? baseRangeStart
    : baseRangeStart.startOf(relativeTimeUnits)

  const relativeEnd = isPresentResolution(resolution)
    ? currentTime
    : rangeStart.endOf(relativeTimeUnits)
  const optionalCurrentDayEnd =
    useEndOfCurrentDay || !isPresentResolution(resolution)
      ? currentTime.endOf(relativeTimeUnits)
      : currentTime
  const rangeEnd = relativeEnd.isAfter(currentTime)
    ? optionalCurrentDayEnd
    : relativeEnd

  return [rangeStart, rangeEnd]
}

export type RelativeTimeUnit = 'days' | 'weeks' | 'months' | 'hours'
export type TimeResolutionConfig = {
  label: string
  shortLabel: string
  relativeTime: number
  relativeTimeUnits: RelativeTimeUnit
}

export const timeResolutionMap: Record<
  RelativeTimeResolution,
  TimeResolutionConfig
> = {
  CUSTOM: {
    label: 'CUSTOM',
    shortLabel: 'C',
    relativeTime: -10,
    relativeTimeUnits: 'days',
  },
  TODAY: {
    label: 'TODAY',
    shortLabel: 'D',
    relativeTime: 0,
    relativeTimeUnits: 'days',
  },
  YESTERDAY: {
    label: 'YESTERDAY',
    shortLabel: '-1D',
    relativeTime: -1,
    relativeTimeUnits: 'days',
  },
  TWO_DAYS_AGO: {
    label: 'PREVIOUS DAY',
    shortLabel: '-2D',
    relativeTime: -2,
    relativeTimeUnits: 'days',
  },
  THIS_WEEK: {
    label: 'THIS WEEK',
    shortLabel: 'W',
    relativeTime: 0,
    relativeTimeUnits: 'weeks',
  },
  LAST_WEEK: {
    label: 'LAST WEEK',
    shortLabel: '-1W',
    relativeTime: -1,
    relativeTimeUnits: 'weeks',
  },
  THIS_MONTH: {
    label: 'THIS MONTH',
    shortLabel: 'M',
    relativeTime: 0,
    relativeTimeUnits: 'months',
  },
  LAST_MONTH: {
    label: 'LAST MONTH',
    shortLabel: '-1M',
    relativeTime: -1,
    relativeTimeUnits: 'months',
  },
  DAY: {
    label: 'DAY',
    shortLabel: '-24H',
    relativeTime: -24,
    relativeTimeUnits: 'hours',
  },
  WEEK: {
    label: 'WEEK',
    shortLabel: '-7D',
    relativeTime: -7,
    relativeTimeUnits: 'days',
  },
  MONTH: {
    label: 'MONTH',
    shortLabel: '-30D',
    relativeTime: -30,
    relativeTimeUnits: 'days',
  },
  ONE_HOUR: {
    label: '1 HOUR',
    shortLabel: '1H',
    relativeTime: -1,
    relativeTimeUnits: 'hours',
  },
  SIX_HOURS: {
    label: '6 HOURS',
    shortLabel: '6H',
    relativeTime: -6,
    relativeTimeUnits: 'hours',
  },
  TWELVE_HOURS: {
    label: '12 HOURS',
    shortLabel: '12H',
    relativeTime: -12,
    relativeTimeUnits: 'hours',
  },
  TWENTY_FOUR_HOURS: {
    label: '1 DAY',
    shortLabel: '1D',
    relativeTime: -1,
    relativeTimeUnits: 'days',
  },
  SEVENTY_TWO_HOURS: {
    label: '3 DAYS',
    shortLabel: '3D',
    relativeTime: -3,
    relativeTimeUnits: 'days',
  },
}
