import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'

import {
  AutocompleteFormControls,
  AutocompleteTextFieldProps,
} from '../../FormField'
import { defaultEntityName, FormField } from '../../index'
import { RootAPI } from '@synop-react/api'

const { useGetChargersQuery } = RootAPI.synopRootAPI

const DEFAULT_CHARGER_COUNT = 1000

export type ChargerAutocompleteSelectProps<FormData extends FieldValues> = {
  depotId?: string
  orgId?: string
} & AutocompleteFormControls<FormData, RootAPI.ChargerModel> &
  AutocompleteTextFieldProps
export const ChargerAutocompleteSelect = <FormData extends FieldValues>({
  depotId,
  orgId,
  ...formControls
}: ChargerAutocompleteSelectProps<FormData>) => {
  const chargerOptions = FormField.useOptions<
    RootAPI.ChargerModel,
    RootAPI.GetChargersApiArg,
    RootAPI.PagedResponseChargerModel
  >({
    queryArgs: {
      siteIds: depotId ? [depotId] : undefined,
      count: DEFAULT_CHARGER_COUNT,
      organizationId: orgId ? [orgId] : undefined,
    },
    useQuery: useGetChargersQuery,
  })

  return (
    <FormField.AutocompleteSelect<FormData, RootAPI.ChargerModel>
      label="Charger"
      {...chargerOptions}
      {...formControls}
      filterOptions={(options, { inputValue }) =>
        options
          .filter(({ chargerName }) => {
            const chargerNm = chargerName?.toLowerCase() || ''
            const searchTerm = inputValue.toLowerCase()
            return chargerNm.includes(searchTerm)
          })
          .sort((a, b) => {
            if (!a.chargerName) return 1
            if (!b.chargerName) return -1
            return a.chargerName.localeCompare(b.chargerName)
          })
      }
      getOptionLabel={({ chargerId, chargerName }: RootAPI.ChargerModel) =>
        chargerName || defaultEntityName('Charger', chargerId)
      }
      keyExtractor={(option: RootAPI.ChargerModel) => option?.chargerId || ''}
    />
  )
}

const chargerSelectSchema = yup
  .object({ chargerId: yup.string().required() })
  .nullable()
  .required('Charger is required')

export { ChargerAutocompleteSelect as Select, chargerSelectSchema as Schema }
