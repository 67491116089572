/* Given a type T, this utility type returns the keys of T that have a value of type V */
export type KeyOfType<T, V> = FieldsOfType<T, V>[keyof T]
export type KeyOfMaybeType<T, V> = KeyOfType<T, V | undefined>

/* Given a type T, this utility type returns the fields of T that have a value of type V */
export type FieldsOfType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}

// Common uses of the above types
export type StringKeyOf<T> = KeyOfType<T, string>
export type NumericKeyOf<T> = KeyOfType<T, number>
export type MaybeStringKeyOf<T> = KeyOfMaybeType<T, string>
export type MaybeNumericKeyOf<T> = KeyOfMaybeType<T, number>

/**
 * Helper function for accessing a key of an object with a type-safe return value. This
 * is useful when using `KeyOfType` (or a derivative) in a generic context. Consider the
 * following function:
 *
 * ```ts
 * function getField<T>(obj: T, key: StringKeyOf<T>): string {
 *   return obj[key]
 * }
 * ```
 *
 * Given the relationship between the `T` type and the `StringKeyOf<T>` type, it should be
 * clear to TypeScript that `obj[key]` has type string. However, limitations in the
 * type system prevent TypeScript from making this inference. This function provides a
 * workaround.
 *
 * Note that this function is not necessary when using `KeyOfType` in a non-generic context.
 * The following code will work as expected:
 *
 * ```ts
 * type Foo = { bar: string; baz: number }
 * const foo: Foo = { bar: 'baz', baz: 1 }
 *
 * // Works
 * getField(foo, 'bar')
 *
 * // Fails
 * getField(foo, 'baz')
 * ```
 */
export function getKeyOf<O, T>(obj: O, key: KeyOfType<O, T>): T {
  return obj[key] as unknown as T
}
