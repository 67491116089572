import { rest } from 'msw'
import mockData from '../data'

export const assetsHandlers = [
  rest.get('/api/fleets/:fleetId/assets', (req, res, ctx) => {
    return res(ctx.json(mockData.vehicles.vehicles))
  }),
  rest.get('/api/assets', (_req, res, ctx) => {
    return res(ctx.json(mockData.vehicles.vehicles))
  }),
  rest.get('/api/assets/:assetId', (_req, res, ctx) => {
    // Todo add simple id lookup or default vehicle
    return res(ctx.json(mockData.vehicles.vehicles[0]))
  }),
  rest.get('/api/assets/:assetId/events', (req, res, ctx) => {
    return res(ctx.json(mockData.chargingSession.singleScheduledCharges))
  }),
]
