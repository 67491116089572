import { Components } from '@mui/material'
import customPalette from './customPalette'

const lightPalette = {
  ...customPalette,

  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    '4p': 'rgba(0, 0, 0, 0.04)',
    '8p': 'rgba(0, 0, 0, 0.08)',
    '12p': 'rgba(0, 0, 0, 0.12)',
    '30p': 'rgba(0, 0, 0, 0.3)',
    '50p': 'rgba(0, 0, 0, 0.5)',
  },

  primary: {
    main: '#3E77FE',
    dark: '#2B53B1',
    light: '#6492FE',
    contrastText: '#FFFFFF',
    '900': '#5186FF',
    '800': '#6395FF',
    '700': '#76A3FF',
    '600': '#89B1FF',
    '500': '#9CBEFF',
    '400': '#B0CCFF',
    '300': '#C3D9FF',
    '200': '#D7E6FF',
    '100': '#EBF2FF',
    '4p': 'rgba(62, 119, 254, 0.04)',
    '8p': 'rgba(62, 119, 254, 0.08)',
    '12p': 'rgba(62, 119, 254, 0.12)',
    '30p': 'rgba(62, 119, 254, 0.3)',
    '50p': 'rgba(62, 119, 254, 0.5)',
  },

  secondary: {
    main: '#222E65',
    dark: '#172046',
    light: '#4E5783',
    contrastText: '#FFFFFF',
    '900': '#344174',
    '800': '#475584',
    '700': '#5C6993',
    '600': '#717DA2',
    '500': '#8792B2',
    '400': '#9EA7C1',
    '300': '#B6BCD0',
    '200': '#CDD2E0',
    '100': '#E6E8EF',
    '4p': 'rgba(34, 46, 101, 0.04)',
    '8p': 'rgba(34, 46, 101, 0.08)',
    '12p': 'rgba(34, 46, 101, 0.12)',
    '30p': 'rgba(34, 46, 101, 0.3)',
    '50p': 'rgba(34, 46, 101, 0.5)',
  },

  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
    // TODO Action Shades
  },

  info: {
    main: '#00A0FF',
    dark: '#0070B2',
    light: '#33B3FF',
    contrastText: '#000000',
    '900': '#3BAAFF',
    '800': '#58B4FF',
    '700': '#70BEFF',
    '600': '#86C8FF',
    '500': '#9BD2FF',
    '400': '#B0DBFF',
    '300': '#C4E4FF',
    '200': '#D8EDFF',
    '100': '#EBF6FF',
    '4p': 'rgba(0, 160, 255, 0.04)',
    '8p': 'rgba(0, 160, 255, 0.08)',
    '12p': 'rgba(0, 160, 255, 0.12)',
    '30p': 'rgba(0, 160, 255, 0.3)',
    '50p': 'rgba(0, 160, 255, 0.5)',
  },

  success: {
    main: '#03AC1E',
    dark: '#027815',
    light: '#35BC4B',
    contrastText: '#FFFFFF',
    '900': '#3BB53F',
    '800': '#58BE58',
    '700': '#70C66F',
    '600': '#86CF84',
    '500': '#9BD799',
    '400': '#B0DFAD',
    '300': '#C4E8C2',
    '200': '#D8F0D6',
    '100': '#EBF7EA',
    '4p': 'rgba(3, 172, 30, 0.04)',
    '8p': 'rgba(3, 172, 30, 0.08)',
    '12p': 'rgba(3, 172, 30, 0.12)',
    '30p': 'rgba(3, 172, 30, 0.3)',
    '50p': 'rgba(3, 172, 30, 0.5)',
  },

  warning: {
    main: '#FFA600',
    dark: '#b27400',
    light: '#ffb733',
    contrastText: '#000000',
    '900': '#ffb03e',
    '800': '#ffb95c',
    '700': '#ffc274',
    '600': '#ffcb8a',
    '500': '#ffd49f',
    '400': '#ffddb3',
    '300': '#ffe6c6',
    '200': '#ffeed9',
    '100': '#fff7ec',
    '4p': 'rgba(255, 166, 0, 0.04)',
    '8p': 'rgba(255, 166, 0, 0.08)',
    '12p': 'rgba(255, 166, 0, 0.12)',
    '30p': 'rgba(255, 166, 0, 0.3)',
    '50p': 'rgba(255, 166, 0, 0.5)',
  },

  error: {
    main: '#FE5636',
    dark: '#B13C25',
    light: '#FE775E',
    contrastText: '#FFFFFF',
    '900': '#FF6B4E',
    '800': '#FF7E63',
    '700': '#FF9077',
    '600': '#FFA18B',
    '500': '#FFB19E',
    '400': '#FFC1B2',
    '300': '#FFD1C5',
    '200': '#FFE0D8',
    '100': '#FFF0EC',
    '4p': 'rgba(254, 86, 54, 0.04)',
    '8p': 'rgba(254, 86, 54, 0.08)',
    '12p': 'rgba(254, 86, 54, 0.12)',
    '30p': 'rgba(254, 86, 54, 0.3)',
    '50p': 'rgba(254, 86, 54, 0.5)',
  },

  background: {
    paper: '#FFFFFF',
    default: '#FAFAFA',
  },

  divider: 'rgba(0, 0, 0, 0.12)',

  common: {
    black: '#000000',
    white: '#FFFFFF',
  },

  // TODO Update Grey values
  grey: {
    '50': '#F3F6F9',
    '100': '#E7EBF0',
    '200': '#E0E3E7',
    '300': '#CDD2D7',
    '400': '#B2BAC2',
    '500': '#A0AAB4',
    '600': '#6F7E8C',
    '700': '#3E5060',
    '800': '#2D3843',
    '900': '#1A2027',
    A100: '#f5f5f5',
    A200: '#eeeeee',
    A400: '#bdbdbd',
    A700: '#616161',
  },
}

export const lightPaletteComponents: Components = {
  MuiDivider: {
    styleOverrides: {
      root: {
        color: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      actions: {
        color: lightPalette.primary?.main,
      },
    },
  },

  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: lightPalette.text?.secondary,
        '&.Mui-selected': {
          color: 'white',
          backgroundColor: lightPalette.primary?.main,
          '&:hover': {
            color: lightPalette.text?.secondary,
          },
        },
      },
    },
  },
}

export default lightPalette
