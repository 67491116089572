import {
  CoordinateSourceAttribute,
  DistanceSourceAttribute,
  EfficiencySourceAttribute,
  EmissionsSourceAttribute,
  EnergySourceAttribute,
  FuelSourceAttribute,
  PercentSourceAttribute,
  RootAPI,
  TimeSourceAttribute,
} from '@synop-react/api'
import { DistanceOptions, formatDistance } from './distance'
import { emDash } from './constants'
import { formatCO2 } from './emissions'
import { formatEfficiency } from './efficiency'
import { formatEnergy, reduceWattHours } from './energy'
import { formatFuel } from './fuel'
import { formatSoC, SoCOptions } from './stateOfCharge'
import { humanizeDuration } from './time'

export type SourceAttributeValue =
  | RootAPI.SourceAttributeValueString
  | RootAPI.SourceAttributeValueBigDecimal
export const isNumericSourceAttribute = (
  attribute: SourceAttributeValue
): attribute is RootAPI.SourceAttributeValueBigDecimal =>
  typeof attribute === 'number'
export const isStringSourceAttribute = (
  attribute: SourceAttributeValue
): attribute is RootAPI.SourceAttributeValueString =>
  typeof attribute === 'string'

export type SourceAttributeFields<
  AttributeType extends SourceAttributeValue = SourceAttributeValue
> = [AttributeType['value'], AttributeType['units']]
export type SourceAttributeFormatter<
  AttributeType extends SourceAttributeValue,
  R = unknown
> = (...props: SourceAttributeFields<AttributeType>) => R

export const isSourceAttributeValue = (
  value: unknown
): value is SourceAttributeValue => {
  if (!value || typeof value !== 'object') return false
  const sourceAttributeKeys = ['source', 'units', 'value']
  return sourceAttributeKeys.reduce<boolean>(
    (isSourceAttribute, key) =>
      isSourceAttribute && Object.keys(value).includes(key),
    true
  )
}

export const isPercentSourceAttribute = (
  value: unknown
): value is PercentSourceAttribute => {
  if (!isSourceAttributeValue(value)) return false
  return value.units === 'percent'
}

export const getFieldsFromSourceAttribute = function <
  AttributeType extends SourceAttributeValue
>(
  sourceAttribute?: AttributeType
): [AttributeType['value'], AttributeType['units']] {
  const { value, units } = sourceAttribute || {}
  return [value, units]
}

export const getFormattedValueFromSourceAttribute =
  <AttributeType extends SourceAttributeValue, R>(
    formatter: SourceAttributeFormatter<AttributeType, R>
  ) =>
  (sourceAttribute?: AttributeType): R => {
    return formatter(...getFieldsFromSourceAttribute(sourceAttribute))
  }

export const getIntFromSourceAttribute = getFormattedValueFromSourceAttribute(
  (value = '') => (typeof value === 'string' ? parseInt(value) : value)
)

export const getFloatFromSourceAttribute = getFormattedValueFromSourceAttribute(
  (value = '') => (typeof value === 'string' ? parseFloat(value) : value)
)

export const getStringFromSourceAttribute =
  getFormattedValueFromSourceAttribute((value = '') => String(value))

export const formatDistanceSourceAttribute = (
  distanceAttribute?: DistanceSourceAttribute,
  distanceOptions?: Partial<DistanceOptions>
) => {
  const { value = '', units } = distanceAttribute || {}
  return typeof value === 'number' && value >= 0
    ? formatDistance(value, units, distanceOptions)
    : emDash
}

export const formatDurationSourceAttribute = (
  durationAttribute: TimeSourceAttribute
) => {
  const { value, units } = durationAttribute || {}
  return typeof value === 'number' && value >= 0
    ? humanizeDuration(value, units)
    : emDash
}

export const formatLocationFromSourceAttributes = (
  latitudeAttribute: CoordinateSourceAttribute,
  longitudeAttribute: CoordinateSourceAttribute
) => {
  const latitude = getFloatFromSourceAttribute(latitudeAttribute)
  const longitude = getFloatFromSourceAttribute(longitudeAttribute)
  return latitude && longitude ? [longitude, latitude] : null
}

export const formatEfficiencyFromSourceAttributes = (
  efficiencyAttribute?: EfficiencySourceAttribute
) => {
  const { value, units } = efficiencyAttribute || {}
  return typeof value === 'number' && value >= 0
    ? formatEfficiency(value, units)
    : emDash
}

export const formatEmissionsFromSourceAttributes = (
  emissionsAttribute?: EmissionsSourceAttribute
) => {
  const { value, units } = emissionsAttribute || {}
  return typeof value === 'number' && value >= 0
    ? formatCO2(value, units)
    : emDash
}

export const formatEnergyFromSourceAttributes = (
  energyAttribute?: EnergySourceAttribute
) => {
  const reducedUnits = energyAttribute
    ? reduceWattHours(energyAttribute?.value, energyAttribute?.units)
    : undefined
  const { num, unit } = reducedUnits ?? {}
  return typeof num === 'number' && num >= 0 ? formatEnergy(num, unit) : emDash
}

export const formatFuelFromSourceAttributes = (
  fuelAttribute?: FuelSourceAttribute
) => {
  const { value, units } = fuelAttribute || {}
  return typeof value === 'number' && value >= 0
    ? formatFuel(value, units)
    : emDash
}

export const formatSocFromSourceAttributes = (
  percentAttribute?: SourceAttributeValue,
  options?: SoCOptions
) => {
  return isPercentSourceAttribute(percentAttribute)
    ? formatSoC(percentAttribute.value, percentAttribute.units, options)
    : emDash
}

export const getMostRecentTimestamp = (
  obj: Record<string, unknown>
): number => {
  return Object.values(obj).reduce<number>((acc, maybeSourceAttribute) => {
    if (isSourceAttributeValue(maybeSourceAttribute)) {
      return maybeSourceAttribute?.lastUpdated || acc
    }
    return acc
  }, 0)
}
