import {
  AdminFeature,
  CreateChargingSessionOverlay,
  CreateOrEditDepotEventOverlay,
  DetailPageHeader,
  Icons,
  Settings,
  useRouting,
  useSnackbarControls,
} from '@synop-react/common'
import { Button, Grid, IconButton, useTheme } from '@mui/material'
import {
  ChargersPanel,
  CreateOrEditDepotOverlay,
  DepotEventTimeline,
  DepotUtilizationChartCask,
} from '@synop-react/depot'
import { DepotDetailsCask, SiteStatusCask } from './components'
import { RootAPI, useDepotDetailsFromPoll, usePolling } from '@synop-react/api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ChargingSessionTabCaskView from './components/ChargingSessions/ChargingSessionTabCaskView'

const { useCmsUtilizationGetUtilizationQuery } = RootAPI

type DepotDetailsRouteParams = {
  depotId: string
}

const DepotDetailsPage = () => {
  const { navigate, routes } = useRouting()
  const theme = useTheme()
  const { openSnackbar } = useSnackbarControls()
  // React Router 6's useParams implementation allows parameters to be undefined (why???), so we need to force the type
  // @rbright
  const { depotId } = useParams<
    keyof DepotDetailsRouteParams
  >() as DepotDetailsRouteParams

  const [isChargingSessionOverlayOpen, setChargingSessionOverlayOpen] =
    useState(false)

  const [isEditDepotEventOverlayOpen, setEditDepotEventOverlayOpen] =
    useState(false)

  const {
    getDepot: { data: depot, isError },
  } = useDepotDetailsFromPoll({ depotId })
  const { depotNm = '' } = depot || {}

  const { data: utilization } = usePolling(
    useCmsUtilizationGetUtilizationQuery,
    { siteIds: [depotId] },
    { pollingIntervalSeconds: 60 }
  )

  useEffect(() => {
    // If the site query is unsuccessful, navigate the user back to site overview page
    if (isError) {
      openSnackbar('The specified site does not exist.', {
        title: 'Site Not Found',
        severity: 'error',
      })
      navigate(routes.sites, { replace: true })
    }
  }, [depot, isError, navigate, openSnackbar, routes.sites])

  return (
    <>
      {isChargingSessionOverlayOpen ? (
        <CreateChargingSessionOverlay
          defaultDepot={depot}
          isOpen={isChargingSessionOverlayOpen}
          setIsOpen={setChargingSessionOverlayOpen}
        />
      ) : null}
      {depot?.isLoadBalanced ? (
        <CreateOrEditDepotEventOverlay
          defaultDepot={depot}
          isOpen={isEditDepotEventOverlayOpen}
          setIsOpen={setEditDepotEventOverlayOpen}
        />
      ) : null}

      <Grid container rowSpacing={theme.spacing(2)} sx={{ pb: 8 }}>
        <Grid item xs={12}>
          <DetailPageHeader
            breadcrumbLink={routes.sites}
            breadcrumbTitle="Sites"
            title={depotNm}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="flex-end"
              spacing={theme.spacing(1)}
            >
              <AdminFeature>
                <Grid item>
                  <CreateOrEditDepotOverlay
                    depotId={depotId}
                    Trigger={
                      <IconButton size="large">
                        <Settings fontSize="medium" />
                      </IconButton>
                    }
                  />
                </Grid>
              </AdminFeature>
              {depot?.isLoadBalanced ? (
                <Grid item>
                  <Button
                    onClick={() => setEditDepotEventOverlayOpen(true)}
                    startIcon={
                      <Icons.Plus
                        strokeWidth={2}
                        sx={{ color: theme.palette.primary.main }}
                      />
                    }
                    variant="outlined"
                  >
                    New Site Event
                  </Button>
                </Grid>
              ) : null}

              <Grid item>
                <Button
                  onClick={() => setChargingSessionOverlayOpen(true)}
                  startIcon={
                    <Icons.Plus
                      strokeWidth={2}
                      sx={{ color: theme.palette.primary.contrastText }}
                    />
                  }
                  variant="contained"
                >
                  New Charging Session
                </Button>
              </Grid>
            </Grid>
          </DetailPageHeader>
        </Grid>

        <Grid container item spacing={theme.spacing(2)} xs={12}>
          <Grid
            container
            item
            justifyContent="space-between"
            rowSpacing={theme.spacing(2)}
            xs={8}
          >
            <Grid item xs={12}>
              <SiteStatusCask site={depot} siteId={depotId} />
            </Grid>

            <Grid item xs={12}>
              <DepotUtilizationChartCask depotId={depotId} />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <ChargersPanel depotId={depotId} utilization={utilization} />
          </Grid>
        </Grid>

        {depot?.isLoadBalanced ? (
          <Grid item xs={12}>
            <DepotEventTimeline depotId={depotId} />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <ChargingSessionTabCaskView depotId={depotId} />
        </Grid>

        <Grid item xs={12}>
          <DepotDetailsCask depotId={depotId} />
        </Grid>
      </Grid>
    </>
  )
}

export default DepotDetailsPage
