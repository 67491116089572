import {
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { NotificationPriorityPill } from './NotificationPriorityPill'
import {
  Priority,
  RootAPI,
  useOrgChargers,
  useOrgSites,
  useOrgVehicles,
} from '@synop-react/api'
import { sortNotifications } from './NotificationsDashboard'
import { useRouting } from '@synop-react/common'

type NotificationsDrawerProps = {
  anchorEl: null | HTMLElement
  closeDrawer: () => void
  notifications: RootAPI.NotificationModel[]
}

export const NotificationsDrawer = ({
  anchorEl,
  closeDrawer,
  notifications,
}: NotificationsDrawerProps) => {
  const {
    chargers,
    notifications: notificationsRoute,
    siteDetails,
    vehicles,
  } = useRouting().routes
  const { orgChargers } = useOrgChargers()
  const { orgVehicles } = useOrgVehicles()
  const { orgSites } = useOrgSites()

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      MenuListProps={{ onMouseLeave: closeDrawer }}
      onClose={closeDrawer}
      open={Boolean(anchorEl)}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {sortNotifications(notifications)
        .slice(0, 5)
        .map((notification) => {
          const { id, assetId, alertName, priority, assetType } = notification
          const { assetName, depotId, route } = (() => {
            if (assetType === 'Charger') {
              const charger = orgChargers[assetId]
              return {
                assetName: charger?.chargerName ?? '',
                depotId: charger?.depotId ?? '',
                route: chargers,
              }
            } else {
              const vehicle = orgVehicles[assetId]
              return {
                assetName: vehicle?.vehicleNm ?? '',
                depotId: vehicle?.homeSiteId ?? '',
                route: vehicles,
              }
            }
          })()
          return (
            <NotificationMenuItem
              key={id}
              alertName={alertName}
              assetHref={route.details(assetId)}
              assetName={assetName}
              depotHref={depotId ? siteDetails(depotId) : ''}
              depotName={depotId ? orgSites[depotId]?.depotNm ?? '' : ''}
              priority={priority}
            />
          )
        })}
      <MenuItem>
        <ListItemText>
          <Link href={notificationsRoute} sx={{ textDecoration: 'none' }}>
            <Typography align="center" variant="body1">
              View All Notifications
            </Typography>
          </Link>
        </ListItemText>
      </MenuItem>
    </Menu>
  )
}

type NotificationMenuItemProps = {
  priority: Priority
  alertName: string
  assetHref?: string
  assetName?: string
  depotHref?: string
  depotName?: string
}

const NotificationMenuItem = ({
  priority,
  alertName,
  assetHref,
  assetName,
  depotHref,
  depotName,
}: NotificationMenuItemProps) => {
  return (
    <MenuItem divider>
      <ListItemIcon sx={{ mr: 1 }}>
        <NotificationPriorityPill priority={priority} />
      </ListItemIcon>
      <ListItemText>
        <Stack>
          <Typography variant="body1">{alertName}</Typography>
          <Typography variant="caption">
            <Link href={assetHref} sx={{ textDecoration: 'none' }}>
              {assetName}
            </Link>
            {assetName && depotName && ' • '}
            <Link href={depotHref} sx={{ textDecoration: 'none' }}>
              {depotName}
            </Link>
          </Typography>
        </Stack>
      </ListItemText>
    </MenuItem>
  )
}
