import { Box, styled, Typography } from '@mui/material'
import { Icon } from 'react-feather'
import { usePalette } from '@synop-react/theme'

export type OverlayTitlePillProps = {
  TitlePillIcon?: Icon
  primaryText?: string
  secondaryText?: string
}

export function OverlayTitlePill({
  TitlePillIcon,
  primaryText,
  secondaryText,
}: OverlayTitlePillProps) {
  const { charting } = usePalette()
  return (
    <>
      <StyledTitlePillIcon Icon={TitlePillIcon} />
      <Typography component="div" variant="body1">
        {primaryText}
        {secondaryText && (
          <Box display="inline" sx={{ color: charting[3].main }}>
            {` - ${secondaryText}`}
          </Box>
        )}
      </Typography>
    </>
  )
}

function StyledTitlePillIcon({ Icon }: { Icon: Icon | undefined }) {
  const { charting } = usePalette()
  if (!Icon) return null

  const StyledTitlePillIcon = styled(Icon)(() => ({
    color: charting[3].main,
    strokeWidth: 2,
  }))

  return <StyledTitlePillIcon />
}

export default OverlayTitlePill
